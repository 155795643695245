const TopHeader = () => {
  return (
    <section className="landing_banner">
      <div className="container">
        <h1 className="mt-0">{"Notifications"}</h1>
      </div>
    </section>
  );
};

export default TopHeader;
