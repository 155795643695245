import { useSelector } from "react-redux";
import SimpleInput from "../SimpleInput";

const LabelInputBox = ({
  label,
  labelDescription,
  inType,
  style,
  name,
  event,
  error,
  placeholder,
  availableIcon,
  available,
  onChange,
  message,
  disabled,
  prefix,
  slug,
  city,
  parentCol,
  childCol,
  editable,
  editAction,
}) => {
  return (
    <div className="row mt-4">
      <div className={parentCol ? parentCol : "col-md-4"}>
        <h5>
          {label}{" "}
          {editable && (
            <i
              class="bi bi-pencil-square"
              onClick={editAction && editAction}
            ></i>
          )}
        </h5>
        <p className="label">{labelDescription}</p>
      </div>
      <div
        className={`${childCol ? childCol : "col-md-8"} ${
          prefix ? "prefix" : ""
        }`}
      >
        {prefix ? (
          <div className="form-control prefix">
            {availableIcon ? (
              <>
                {prefix && <span>{prefix}</span>}
                <SimpleInput
                  style={style}
                  event={event}
                  name={name}
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                />
                {available && message && (
                  <span className="sendotp">
                    <i className="bi bi-check-circle"></i>
                  </span>
                )}
              </>
            ) : (
              <>
                {prefix && <span>{prefix}</span>}
                <SimpleInput
                  style={style}
                  inType={inType}
                  event={event}
                  name={name}
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                />
              </>
            )}
          </div>
        ) : availableIcon ? (
          <>
            {prefix && <span>{prefix}</span>}
            <SimpleInput
              style={style}
              event={event}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
            />
            {available && message && (
              <span className="sendotp">
                <i className="bi bi-check-circle"></i>
              </span>
            )}
          </>
        ) : (
          <>
            {prefix && <span>{prefix}</span>}
            <SimpleInput
              style={style}
              inType={inType}
              event={event}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
            />
          </>
        )}

        {error && error[name] && (
          <p className="arr" style={{ color: "red" }}>
            <i>
              <i className="bi bi-info-circle-fill"></i> {error[name].message}
            </i>
          </p>
        )}
        {slug && city && (
          <p>{`Profile Link: https://www.chowk.com/${city.replace(
            / /g,
            "-"
          )}/${slug}`}</p>
        )}
        {/* {!error[name] && message && <p>{message + "/"}</p>} */}
      </div>
    </div>
  );
};
export default LabelInputBox;
