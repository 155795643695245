import "react-toastify/dist/ReactToastify.css";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProfileViewGraphData,
  addMoneyToWallet,
  bussinessAccountDetailApi,
  dashBoardGraphs,
  firstVerificationApi,
  getActiveBoostPlans,
  getAllNotification,
  getCallHistory,
  getTopTenNotification,
  getUpdatedDataApi,
  getWalletBalance,
  kycVerificationApi,
  studentOtpVerificationApi,
  studentPhoneVerificationApi,
  studentProfileUpdateApi,
  updateFirstVerificationApi,
} from "../services/api";
import { toast } from "react-toastify";

export const phoneVerification = createAsyncThunk(
  "phoneverification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentPhoneVerificationApi(formData);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      return rejectWithValue(error.response);
    }
  }
);

export const accountProfileUpdatePhoneVerification = createAsyncThunk(
  "accountprofileupdatephoneverification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentPhoneVerificationApi(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const joinPhoneVerification = createAsyncThunk(
  "joinphoneverification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentPhoneVerificationApi(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const branchPhoneVerification = createAsyncThunk(
  "branchphoneverification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentPhoneVerificationApi(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const otpVerification = createAsyncThunk(
  "otpvrification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentOtpVerificationApi(formData);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const studentProfileUpdate = createAsyncThunk(
  "studentProfileUpdate",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentProfileUpdateApi(formData);
      localStorage.setItem("user", JSON.stringify(response.data.detail));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const firstVerificationFun = createAsyncThunk(
  "firstVerification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await firstVerificationApi(formData);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const kycVerificationFun = createAsyncThunk(
  "kycVerification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await kycVerificationApi(formData);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          status: response.data.up_status,
        })
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateFirstVerification = createAsyncThunk(
  "updateFirstVerification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await updateFirstVerificationApi(formData);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const bussinessAccountDetailFun = createAsyncThunk(
  "bussinessAccountDetail",
  async (_, { rejectWithValue }) => {
    try {
      const response = await bussinessAccountDetailApi();
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/";
      }
      return rejectWithValue(error.response);
    }
  }
);

export const getActiveBoosts = createAsyncThunk(
  "getBoostPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getActiveBoostPlans();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addMoney = createAsyncThunk(
  "addMoney",
  async (body, { rejectWithValue }) => {
    try {
      const response = await addMoneyToWallet(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBalance = createAsyncThunk(
  "getBalance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWalletBalance();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const toptenNotification = createAsyncThunk(
  "getNotification",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTopTenNotification();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const allNotifications = createAsyncThunk(
  "allNotifications",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getAllNotification(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ProfileViews = createAsyncThunk(
  "profile_views",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfileViewGraphData();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fetChCallHistory = createAsyncThunk(
  "callHistory",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getCallHistory(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getdashBoardGraphs = createAsyncThunk(
  "graphs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashBoardGraphs();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getUpdateData = createAsyncThunk(
  "updatedData",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getUpdatedDataApi(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    actionType: "header",
    otpSuccess: false,
    joinSuccess: false,
    updateFirstVerificationSuccess: false,
    accountSettingSuccess: false,
    firstVerificationSuccess: false,
    kycVerificationSuccess: false,
    profileUpdateSuccess: false,
    loading: false,
    otpLoading: false,
    deviceToken: "",
    auth: {},
    userDetails: {},
    otp: "",
    phoneNumber: "",
    token: "",
    redireaction: "",
    message: "",
    firstVerificationMessage: "",
    kycVerificationMessage: "",
    addBranchData: {},
    oldPhoneOtp: "",
    oldPhoneNumber: "",
    otpError: null,
    owner_name: "",
    errorPosition: null,
    branchSuccess: false,
    addBranchOtpSuccess: false,
    chats: [],
    immigration: "",
    institute: "",
    both: "",
    activeBoosts: [],
    funds: 0,
    notification: [],
    allNotifications: [],
    profileViews: [],
    boostProfileViews: [],
    callHistory: [],
    activeUsers: 0,
    allCall: 0,
    alluser: 0,
    call_price: 10,
    call_min: 50,
    call_warn: 2,
    monthsViews: 0,
    monthlyEngagement: 0,
    notiCount: 0,
    monthsViewsData: 0,
    monthsEngagementData: 0,
  },
  reducers: {
    changeNumber: (state, action) => {
      state.redireaction = "";
      state.actionType = "";
    },
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    setOldPhoneOtp: (state, action) => {
      state.oldPhoneOtp = action.payload;
    },
    setOldPhoneNumber: (state, action) => {
      state.oldPhoneNumber = action.payload;
    },
    setKycVerificationSuccess: (state, action) => {
      state.kycVerificationSuccess = action.payload;
    },
    setDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
    setAction: (state, action) => {
      state.actionType = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
    },
    setAddBranchData: (state, action) => {
      state.addBranchData = {
        addhaar_number: action.payload.addhaar_number,
        pan_number: action.payload.pan_number,
        owner_name: action.payload.owner_name,
        company_name: action.payload.company_name,
        gst_number: action.payload.gst_number,
        permanent_address: action.payload.permanent_address,
        facebook: action.payload.facebook,
        instagram: action.payload.instagram,
        linkdin: action.payload.linkdin,
        type: action.payload.type,
        phone: action.payload.phone,
        addhaar_front_image: action.payload.addhaar_front_image,
        addhaar_back_image: action.payload.addhaar_back_image,
        pan_number_front_image: action.payload.pan_number_front_image,
        gst_number_certificate_image:
          action.payload.gst_number_certificate_image,
        goverment_authorize_image: action.payload.goverment_authorize_image,
      };
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    clearUser: (state) => {
      state.auth = null;
      state.success = false;
      state.success = false;
      state.otpSuccess = false;
      localStorage.removeItem("user");
    },
    clearToken: (state) => {
      state.token = null;
      state.success = false;
      localStorage.removeItem("token");
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("cl", action.payload);
    },
    clearStatus: (state) => {
      state.success = false;
      state.error = null;
    },
    setLogoOnHeader: (state, action) => {
      state.logo = action.payload;
    },
    setProfileUpdateSuccess: (state, action) => {
      state.profileUpdateSuccess = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setImmigration: (state, action) => {
      state.immigration = action.payload;
    },
    setInstitute: (state, action) => {
      state.institute = action.payload;
    },
    setBoth: (state, action) => {
      state.both = action.payload;
    },
    setOnlineStatus: (state, action) => {
      state.auth.video_status = action.payload;
      localStorage.setItem("user", JSON.stringify(state.auth));
    },
    setOtpError: (state, action) => {
      state.otpError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(phoneVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
        state.loading = true;
      })
      .addCase(phoneVerification.fulfilled, (state, action) => {
        state.submitting = false;
        state.otp = action.payload.otp;
        state.loading = false;
        state.success = true;
      })
      .addCase(phoneVerification.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.error;
        state.success = false;
        state.loading = false;
      })

      .addCase(otpVerification.pending, (state) => {
        state.submitting = true;
        state.otpError = null;
        state.otpSuccess = false;
        state.loading = true;
      })
      .addCase(otpVerification.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload.user;
        state.message = action.payload.message;
        state.redireaction = action.payload.redireaction;
        state.token = action.payload.token;
        state.loading = false;
        state.success = false;
        state.otpSuccess = true;
        state.owner_name = action.payload.owner_name;
      })
      .addCase(otpVerification.rejected, (state, action) => {
        state.submitting = false;
        state.otpError = action.payload.data.message;
        state.redireaction = action.payload.data.redireaction;
        state.otpSuccess = false;
        state.loading = false;
      })
      .addCase(studentProfileUpdate.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.profileUpdateSuccess = false;
        state.loading = true;
      })

      .addCase(studentProfileUpdate.fulfilled, (state, action) => {
        state.submitting = false;
        state.message = action.payload.message;
        state.setUserDetails = {
          latitude: action.payload.detail.latitude,
          longitude: action.payload.detail.longitude,
          ip_address: action.payload.detail.ip_address,
          address: action.payload.detail.current_address,
        };
        state.auth = action.payload.detail;
        state.loading = false;
        state.profileUpdateSuccess = true;
      })
      .addCase(studentProfileUpdate.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.profileUpdateSuccess = false;
        state.loading = false;
      })
      .addCase(firstVerificationFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.firstVerificationSuccess = false;
        state.loading = true;
      })
      .addCase(firstVerificationFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload.data;
        state.firstVerificationMessage = action.payload.message;
        state.redireaction = action.payload.redireaction;
        state.token = action.payload.token;
        state.loading = false;
        state.firstVerificationSuccess = true;
      })
      .addCase(firstVerificationFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.errorPosition = action.payload.data.position;
        state.redireaction = action.payload.data.redireaction;
        state.firstVerificationSuccess = false;
        state.loading = false;
      })
      .addCase(kycVerificationFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.kycVerificationSuccess = false;
        state.loading = true;
      })
      .addCase(kycVerificationFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = {
          ...JSON.parse(localStorage.getItem("user")),
          status: action.payload.up_status,
        };
        state.kycVerificationMessage = action.payload.message;
        state.redireaction = action.payload.redireaction;
        state.token = action.payload.token;
        state.loading = false;
        state.kycVerificationSuccess = true;
        state.firstVerificationSuccess = false;
      })
      .addCase(kycVerificationFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.redireaction = action.payload.data.redireaction;
        state.kycVerificationSuccess = false;
        state.firstVerificationSuccess = true;
        state.loading = false;
      })
      .addCase(updateFirstVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.updateFirstVerificationSuccess = false;
        state.loading = true;
        state.otpSuccess = false;
      })
      .addCase(updateFirstVerification.fulfilled, (state, action) => {
        state.updateFirstVerificationSuccess = true;
        state.submitting = false;
        state.auth = action.payload.data;
        state.message = action.payload.message;
        state.redireaction = action.payload.redireaction;
        state.token = action.payload.token;
        state.loading = false;
        state.otpSuccess = true;
      })
      .addCase(updateFirstVerification.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.redireaction = action.payload.data.redireaction;
        state.updateFirstVerificationSuccess = false;
        state.loading = false;
        state.otpSuccess = false;
      })
      .addCase(bussinessAccountDetailFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.profileUpdateSuccess = false;
        state.loading = true;
      })
      .addCase(bussinessAccountDetailFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload.data;
        state.message = action.payload.message;
        state.token = action.payload.token;
        state.loading = false;
        state.profileUpdateSuccess = true;
      })
      .addCase(bussinessAccountDetailFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.profileUpdateSuccess = true;
        state.loading = false;
      })
      .addCase(joinPhoneVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.joinSuccess = false;
        state.loading = true;
      })
      .addCase(joinPhoneVerification.fulfilled, (state, action) => {
        state.submitting = false;
        state.otp = action.payload.otp;
        state.loading = false;
        state.joinSuccess = true;
      })
      .addCase(joinPhoneVerification.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.error;
        state.joinSuccess = false;
        state.loading = false;
      })
      .addCase(branchPhoneVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.branchSuccess = false;
        state.loading = true;
      })
      .addCase(branchPhoneVerification.fulfilled, (state, action) => {
        state.submitting = false;
        state.loading = false;
        state.branchSuccess = true;
      })
      .addCase(branchPhoneVerification.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.error;
        state.branchSuccess = false;
        state.loading = false;
      })
      .addCase(accountProfileUpdatePhoneVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.profileUpdateSuccess = false;
        state.loading = true;
      })
      .addCase(
        accountProfileUpdatePhoneVerification.fulfilled,
        (state, action) => {
          state.submitting = false;
          state.loading = false;
          state.profileUpdateSuccess = true;
        }
      )
      .addCase(
        accountProfileUpdatePhoneVerification.rejected,
        (state, action) => {
          state.submitting = false;
          state.error = action.payload.data.error;
          state.profileUpdateSuccess = false;
          state.loading = false;
        }
      )
      .addCase(getActiveBoosts.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getActiveBoosts.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.activeBoosts = action.payload;
      })
      .addCase(getActiveBoosts.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(addMoney.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addMoney.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.funds = action.payload.data;
        state.error = null;
      })
      .addCase(addMoney.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getBalance.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.funds = action.payload.data;
        state.call_price = action.payload.call;
        state.call_min = action.payload.callMinBalance;
        state.call_warn = action.payload.call_warn;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(toptenNotification.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(toptenNotification.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.notification = action.payload.data;
        state.notiCount = action.payload.count[0].count;
      })
      .addCase(toptenNotification.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(allNotifications.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(allNotifications.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.allNotifications = action.payload.data;
      })
      .addCase(allNotifications.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(ProfileViews.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(ProfileViews.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.profileViews = action.payload.data.normal;
        state.boostProfileViews = action.payload.data.boost;
        state.alluser = action.payload.data.alluser;
        state.activeUsers = action.payload.data.activeUser;
        state.allCall = action.payload.data.allCall;
      })
      .addCase(ProfileViews.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(fetChCallHistory.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(fetChCallHistory.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.callHistory = action.payload.data;
      })
      .addCase(fetChCallHistory.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getdashBoardGraphs.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getdashBoardGraphs.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.monthsViews = action.payload.data.v;
        state.monthlyEngagement = action.payload.data.e;
        state.monthsEngagementData = action.payload.data.engage;
        state.monthsViewsData = action.payload.data.view;
      })
      .addCase(getdashBoardGraphs.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getUpdateData.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getUpdateData.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.auth = action.payload.data[0];
      })
      .addCase(getUpdateData.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});
export const {
  changeNumber,
  setUserDetails,
  setUser,
  setDeviceToken,
  setToken,
  clearStatus,
  clearUser,
  clearToken,
  changeLanguage,
  setPhoneNumber,
  setAddBranchData,
  setOldPhoneOtp,
  setOldPhoneNumber,
  setOtp,
  setAction,
  setKycVerificationSuccess,
  setProfileUpdateSuccess,
  setChats,
  setImmigration,
  setInstitute,
  setBoth,
  setOnlineStatus,
  setOtpError,
} = authSlice.actions;
export default authSlice.reducer;
