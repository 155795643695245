import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import Table from "../../../Tools/Table";
import { Link } from "react-router-dom";
import BranchForm from "../../../Layouts/Form/branchForm";
import { branchColumns } from "../../../../services/AccountSettingData";
import { getBranchFun } from "../../../../Reducer/accountSettingSlice";
import { mediaUrl, secondUrl } from "../../../../services/network";
import { updateStaffStatusApi } from "../../../../services/api";

const BranchesSettingSection = ({ active }) => {
  const [branchFormShow, setBranchFormSHow] = React.useState(false);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.accountSetting);
  React.useEffect(() => {
    dispatch(getBranchFun());
  }, [branchFormShow]);

  const branchData =
    selector &&
    selector.branch &&
    selector.branch.map((item) => {
      return {
        ...item,
        date: moment(new Date(Number(item.date))).format("DD/MM/YYYY"),
        company_logo: (
          <img
            style={{ width: "100px" }}
            src={`${mediaUrl + item.company_logo}`}
          />
        ),
      };
    });

  const actions = [
    {
      label: "Status",
      option: [
        {
          title: "Block",
          value: "block",
        },
        {
          title: "Active",
          value: "active",
        },
        {
          title: "Hold",
          value: "hold",
          disabled: true,
        },
      ],
      onchange: (e, id) => {
        updateStaffStatusApi({ status: e.target.value, id: id, type: "branch" })
          .then((response) => {
            if (response.data.status) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_LEFT,
              });
            }
          })
          .catch((e) => {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_LEFT,
            });
          });
      },
    },
    {
      type: "button",
      name: <img src="../../assets/images/trash.png" />,
      onClick: (id) => {
        console.log("branch idd", id);
      },
    },
  ];
  // console.log(selector.branch, "BRANCH");
  return (
    <div
      className={`tab-pane fade ${active}`}
      id="Settingstab2-tab-pane"
      role="tabpanel"
      aria-labelledby="Settingstab2-tab"
      tabIndex="0"
    >
      <div className={`Branches_main`}>
        <div className={`Branches ${branchFormShow && "d-none"}`}>
          <h4>
            <img src="../../assets/images/Branches.png" alt="Branches" />{" "}
            {"Branches"}
            <Link
              className="Add_New"
              onClick={() => setBranchFormSHow(!branchFormShow)}
            >
              <i className="bi bi-plus-square-fill"></i> {"Add"}
            </Link>
          </h4>
          <p className="Lorem">{"EXPLORE DIVERSE IMMIGRATION BRANCHES"}</p>
          <Table
            columns={branchColumns}
            // actionCloumn={BranchAcionColumns}
            // action={action}
            rows={branchData}
            action={actions}
            actionCloumn={actions}
            actionDropdown={true}
            // actionDropdown={false}
          />
        </div>
        <BranchForm show={branchFormShow} setShow={setBranchFormSHow} />
      </div>
    </div>
  );
};

export default BranchesSettingSection;
