import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs, Nav } from "react-bootstrap";
import { agentTrainingVideos } from "../../../../services/VideosData";
import StudentHeader from "../../../Layouts/Student/Header";
import { useSelector } from "react-redux";

const TrainingVideos = () => {
  const [sortedVideos, setSortedVideos] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const selector = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      (selector?.auth?.gst_status === "Approved" ||
        selector?.auth?.pan_status === "Approved") &&
      selector?.auth?.authorize_certificate_status === "Approved"
    ) {
      const sort_videos = agentTrainingVideos.sort(
        (a, b) => a.secondary_order - b.secondary_order
      );
      setSortedVideos(sort_videos);
      setActiveKey(sort_videos[0]?.category || "");
    } else {
      const sort_videos = agentTrainingVideos.sort(
        (a, b) => a.primamry_order - b.primamry_order
      );
      setSortedVideos(sort_videos);
      setActiveKey(sort_videos[0]?.category || "");
    }
  }, [selector]);

  const searchVideos = (e) => {
    const result = [];
    agentTrainingVideos.forEach((item) => {
      const matchedVideos = item.videos.filter((el) =>
        el?.title?.toLowerCase()?.includes(e.target.value.toLowerCase())
      );
      if (matchedVideos.length > 0) {
        result.push({ category: item.category, videos: matchedVideos });
      }
    });
    setSortedVideos(result);
    if (result.length > 0) {
      setActiveKey(result[0].category);
    }
  };

  return (
    <>
      <StudentHeader formDisplay={true} />
      <section className="training">
        <div className="container">
          <h1>
            <img
              src="../../assets/images/chownk_training.png"
              alt="Chownk training"
            />
            Training Videos
          </h1>
          <div className="ulmain">
            <Nav
              variant="tabs"
              className="mb-3 mt-3"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
            >
              {sortedVideos &&
                sortedVideos.length > 0 &&
                sortedVideos.map((item, i) => (
                  <Nav.Item key={i}>
                    <Nav.Link eventKey={item.category}>
                      {item.category}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </div>
          <form>
            <i className="bi bi-search"></i>
            <input
              type="text"
              placeholder="Search Training Videos"
              className="form-control mb-3"
              onChange={searchVideos}
            />
          </form>
          <Tab.Content>
            {sortedVideos &&
              sortedVideos.length > 0 &&
              sortedVideos.map((item, i) => (
                <Tab.Pane
                  eventKey={item.category}
                  active={item.category === activeKey}
                  key={i}
                >
                  <Row>
                    {item.videos.map((el, index) => (
                      <Col sm={6} md={4} xl={3} className="mb-3" key={index}>
                        <div className="set">
                          <iframe
                            width={"100%"}
                            height="150"
                            src={el.url}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>
                          <h6>{el.title}</h6>
                          <p>{el.description}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab.Pane>
              ))}
          </Tab.Content>
        </div>
      </section>
    </>
  );
};

export default TrainingVideos;
