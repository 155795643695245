import React from "react";

const HelpTabs = ({ user, tabHandle }) => {
  return (
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class={`nav-link ${user === "customers" ? "active" : ""}`}
          id="Customers-tab"
          data-bs-toggle="tab"
          data-bs-target="#Customers"
          type="button"
          role="tab"
          aria-controls="Customers"
          aria-selected="true"
          onClick={() => tabHandle("user")}
        >
          For Customers
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class={`nav-link ${user === "partners" ? "active" : ""}`}
          id="Partners-tab"
          data-bs-toggle="tab"
          data-bs-target="#Partners"
          type="button"
          role="tab"
          aria-controls="Partners"
          aria-selected="false"
          tabindex="-1"
          onClick={() => tabHandle("business")}
        >
          For Partners
        </button>
      </li>
    </ul>
  );
};

export default HelpTabs;
