import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { encode, decode, Base64 } from "js-base64";

const Faq = ({ data }) => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    if (!data || data == undefined) {
    } else {
      try {
        const string = JSON.parse(data);
        setFaqs(string);
      } catch (error) {}
    }
  }, [data]);

  return (
    <>
      {faqs.length > 0 && <h2 className="mt-5">Frequently asked question</h2>}

      <div className="accordion" id="accordionExample">
        {faqs &&
          faqs.length > 0 &&
          faqs.map((item, i) => (
            <div className="accordion-item" key={i}>
              <h3 className="accordion-header">
                <button
                  className={`accordion-button`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${i}`}
                  aria-expanded="true"
                  aria-controls={`collapse${i}`}
                >
                  {item.que}
                </button>
              </h3>
              <div
                id={`collapse${i}`}
                className={`accordion-collapse collapse ${i == 0 && "show"}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Base64.decode(item.ans),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Faq;
