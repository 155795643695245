import React from "react";

const RecentactivityApp = () => {
  return (
    <div className="main_s main_right">
      <h6>
        Recent Activity
        <select>
          <option>Today</option>
          <option>Today</option>
        </select>
      </h6>

      <div className="st">
        <div className="img">
          <img src="../../../assets/images/ac1.png" alt="ac1" />{" "}
        </div>
        <h5>Lorem Ipsum is simply dummy ttext</h5>
        <p>12:00 PM</p>
      </div>
      <div className="st">
        <div className="img">
          <img src="../../../assets/images/ac2.png" alt="ac1" />{" "}
        </div>
        <h5>Lorem Ipsum is simply dummy text</h5>
        <p>12:00 PM</p>
      </div>
      <div className="st">
        <div className="img">
          <img src="../../../assets/images/ac3.png" alt="ac1" />{" "}
        </div>
        <h5>Lorem Ipsum is simply dummy text</h5>
        <p>12:00 PM</p>
      </div>
      <div className="st">
        <div className="img">
          <img src="../../../assets/images/ac1.png" alt="ac1" />{" "}
        </div>
        <h5>Lorem Ipsum is simply dummy text</h5>
        <p>12:00 PM</p>
      </div>
      <a className="view_all" href="#">
        View All
      </a>
    </div>
  );
};

export default RecentactivityApp;
