import Button from "../../Tools/Button";

const ReplyShareModel = ({ onHide }) => {
  return (
    <>
      <div className="modal-content">
        <Button inType={"button"} style={"btn-close"} onClick={onHide} />
        <div className="modal-body">
          <h4>{"Share us"}</h4>
          <ul>
            <li>
              <a href="#">
                <i className="bi bi-facebook"></i>
              </a>
              <br></br>
              {"Facebook"}
            </li>
            <li>
              <a href="#">
                <i className="bi bi-whatsapp"></i>
              </a>
              <br></br>
              {"Whatsapp"}
            </li>
            <li>
              <a href="#">
                <i className="bi bi-link-45deg"></i>
              </a>
              <br></br>
              {"Link"}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default ReplyShareModel;
