import PlayerHeader from "./PlayerHeader";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SocketContext } from "../../../../Context";
import RightBar from "./RightBar";
import $ from "jquery";
import ConfirmationBox from "../../../Tools/ConfirmationBox";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useSelector } from "react-redux";
import Example from "../../../Tools/ReactBoostrap";
import AddFundsModal from "../../../Layouts/Model/addFunds";
import { Tooltip } from "react-tooltip";
let limit = 0;
let warn = 0;
let acpt = false;

const UserInterface = ({ style }) => {
  const [callDurations, setCallDurations] = useState([]);
  const [warning, setWarning] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const {
    expendMode,
    setExpendMode,
    callTimerInterval,
    video,
    startTime,
    setVideo,
    setVideoOffer,
    currentUser,
    ans,
    answerCall,
    callAccepted,
    callMode,
    stream,
    switchToVideo,
    call,
    userVideoStatus,
    handleMute,
    userView,
    leaveCall,
    mute,
    setMute,
    videoOffer,
    type,
  } = useContext(SocketContext);
  const { funds, call_price, call_warn } = useSelector((state) => state.auth);
  const userRef = useRef(null);
  useEffect(() => {
    if (userRef.current && stream && userView) {
      userRef.current.srcObject = userView;
    }
  }, [userView]);

  useEffect(() => {
    validations();
  }, [funds]);

  useEffect(() => {
    if (
      ans &&
      call.isReceivingCall &&
      !callAccepted &&
      stream &&
      stream !== null
      && !acpt
    ) {
      answerCall();
      acpt=true;
      setTimeout(() => {
        acpt=false;
      }, 2000);
    }
    if (callAccepted) {
      startCall();
    }
  }, [callAccepted]);

  const startCall = () => {
    callTimerInterval.current = setInterval(updateCallTimer, 1000);
    // callTimerIntervalApi.current = setInterval(updateApi, 5000);
  };

  const validations = async () => {
    if (funds && call_price && call_warn) {
      const sec = (funds / call_price) * 60;
      const warningSecond = call_warn * 60;
      const warning = sec - warningSecond;
      limit = Math.round(sec);
      warn = Math.round(warning);
      let pending = limit - callDurations.slice(0, -1);
      if (pending > warn) {
        setWarning(false);
      }
    }
  };

  // const cutCall = () => {
  //   stopRecording();
  //   setTimeout(() => {
  //     mybtn.current.click();
  //     endCall();
  //   }, 600);
  // };

  const muteFun = () => {
    stream.getAudioTracks().forEach(async function (track) {
      if (!mute) {
        track.enabled = true;
        setMute(true);
        handleMute(video, true);
      } else {
        track.enabled = false;
        setMute(false);
        handleMute(video, false);
      }
    });
  };

  const ChangeView = () => {
    stream.getVideoTracks().forEach(async function (track) {
      if (!video) {
        track.enabled = true;
        setVideo(true);
        handleMute(true, mute);
      } else {
        track.enabled = false;
        setVideo(false);
        handleMute(false, mute);
      }
    });
  };

  // const updateApi = async () => {
  //   if (startTime !== null) {
  //     const endTime = new Date().getTime();
  //     const durationInSeconds = Math.floor((endTime - startTime) / 1000);
  //     try {
  //       if (durationInSeconds) {
  //         const up = await Calling({
  //           id:
  //             connectId && connectId !== null
  //               ? connectId
  //               : call.connectionId[0],
  //           unix_update: endTime,
  //           duration: durationInSeconds,
  //         });
  //       }
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   }
  // };

  function endCall(type) {
    clearInterval(callTimerInterval.current);
    // clearInterval(callTimerIntervalApi.current);
    if (startTime !== null) {
      const endTime = new Date().getTime();
      const durationInSeconds = Math.floor((endTime - startTime) / 1000);
      setCallDurations([...callDurations.slice(0, -1), durationInSeconds]);
      leaveCall(durationInSeconds, type);
    } else {
      leaveCall("4", type);
    }
    //setStartTime(null);
  }

  const updateCallTimer = async () => {
    const currentTime = new Date().getTime();
    const st = startTime !== null ? startTime : currentTime;
    const elapsedTimeInSeconds = Math.floor((currentTime - st) / 1000);
    if (currentUser.permission && currentUser.permission == 1) {
    } else {
      if (elapsedTimeInSeconds === limit && type === "own") {
        endCall("2");
      }
      if (warn <= elapsedTimeInSeconds && type === "own") {
        setWarning(true);
      }
    }
    setCallDurations([...callDurations.slice(0, -1), elapsedTimeInSeconds]);
  };

  const formatDuration = (seconds) => {
    if (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      if (hours > 0) {
        return `${hours} hrs ${minutes} min ${remainingSeconds}sec`;
      } else if (minutes > 0) {
        return `${minutes} min ${remainingSeconds}sec`;
      } else {
        return `${remainingSeconds} sec`;
      }
    }
  };

  const currentCallDuration = useMemo(
    () =>
      startTime !== null &&
      callDurations[0] > 0 &&
      formatDuration(callDurations.slice(-1)[0]),
    [startTime, callDurations]
  );

  const confirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationBox cutt={() => endCall("4")} onClose={onClose} />;
      },
    });
  };

  const handleAccept = () => {
    // if(!acpt){
    switchToVideo();
    //   acpt=true;
    // }
  };

  const switchMode = () => {
    confirmAlert({
      title: "Switch to video call",
      message: "Are you sure you want to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => switchToVideo(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
    $("#react-confirm-alert").addClass("bhoka");
  };

  return (
    <div className={style}>
      <PlayerHeader
        callCut={() => confirm()}
        direactCut={(id) => endCall(id)}
        currentUser={currentUser}
        call={call}
        time={currentCallDuration}
        muteFun={muteFun}
        mute={mute}
        expendMode={expendMode}
        setExpendMode={setExpendMode}
      />
      <section className="video_call_interface text-center">
        <div className="container-fluid p-0">
          <div className="set_main">
            {stream && (
              <div className="set">
                {warning && (
                  <h6>
                    Your Balance is low
                    <button
                      className="btn float-end"
                      onClick={() => setUserModel(true)}
                    >
                      Add Fund
                    </button>
                  </h6>
                )}

                <div
                  className={
                    !userVideoStatus || callMode === "audio"
                      ? "user_content"
                      : "d-none"
                  }
                >
                  <div
                    className="chatuser"
                    style={{
                      backgroundImage: `url(../../assets/images/modelLogo.png)`,
                    }}
                  ></div>
                  {/* {currentUser.dynamic_image === 1 ? (
                    <div className="chatuser">
                      {currentUser.name.toUpperCase().charAt(0)}
                    </div>
                  ) : (
                    <div
                      className="chatuser"
                      onClick={() => {
                        if (
                          currentUser.userRole == "staff" ||
                          currentUser.userRole == "branch"
                        ) {
                          navigate(`/${currentUser.city}/${currentUser.slug}`);
                        }
                      }}
                      style={{
                        backgroundImage: `url(${
                          mediaUrl +
                          `${
                            currentUser.userRole === "student"
                              ? currentUser.image
                              : currentUser.company_logo
                          }`
                        })`,
                      }}
                    ></div>
                  )} */}
                </div>
                {/* <Visualizer audio={userView} mode={'continuous'}>
                {({ canvasRef,start}) => (
                  <>
                    <canvas ref={canvasRef} width={500} height={100} />
                    <button onClick={()=>start()}>Start</button>
                  </>
                )}
                </Visualizer> */}
                <video
                  playsInline
                  ref={userRef}
                  autoPlay
                  className="w-100 h-100"
                />
                <ul>
                  <li onClick={() => muteFun()} data-tooltip-id={"mice"}>
                    <a>
                      <i
                        className={
                          mute == true ? "bi bi-mic" : "bi bi-mic-mute-fill"
                        }
                      ></i>
                    </a>
                  </li>
                  {callMode === "video" && (
                    <li onClick={() => ChangeView()} data-tooltip-id={"vmode"}>
                      <a>
                        <i
                          className={
                            video
                              ? "bi bi-camera-video-fill"
                              : "bi bi-camera-video-off-fill"
                          }
                        ></i>
                      </a>
                    </li>
                  )}
                  <li
                    className="d-sm-none d-inline-block"
                    onClick={() => {
                      $(".chatsall").addClass("main");
                    }}
                    data-tooltip-id={"openChat"}
                  >
                    <a>
                      <i className={"bi bi-chat-dots-fill"}></i>
                    </a>
                  </li>

                  {callMode === "audio" && (
                    <li
                      className=""
                      onClick={() => switchMode()}
                      data-tooltip-id={"SwitchToVideo"}
                    >
                      <a>
                        <i class="bi bi-camera-video-fill"></i>
                      </a>
                    </li>
                  )}

                  <li onClick={() => confirm()} data-tooltip-id={"end"}>
                    <a className="telephone">
                      <i className="bi bi-telephone-fill"></i>
                    </a>
                  </li>
                  <li
                    className="d-none"
                    onClick={() => endCall("0")}
                    data-tooltip-id={"end"}
                  >
                    <a className="telephone">
                      <i className="bi bi-telephone-fill"></i>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <RightBar />
        </div>
        <Example
          show={videoOffer}
          style={"switchdiv"}
          onHide={() => setVideoOffer(!videoOffer)}
          component={
            <div className="video_accept">
              <h4>{`${currentUser.name} is requesting to switch to video call`}</h4>
              <div className="row">
                <div className="col-6">
                  <button onClick={() => handleAccept()}>Accept</button>
                </div>
                <div className="col-6">
                  <button
                    className="cancel"
                    onClick={() => setVideoOffer(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
          backDrop={"static"}
        />
        <Example
          show={userModel}
          style={"loginmodal add_money_wallet"}
          onHide={() => setUserModel(!userModel)}
          component={
            <AddFundsModal
              onHide={() => {
                setUserModel(!userModel);
                validations();
              }}
            />
          }
          backDrop={"static"}
        />
      </section>
      <Tooltip id="end" content="End call" style={{ zIndex: 999 }} />
      <Tooltip
        id="SwitchToVideo"
        content="Switch to video"
        style={{ zIndex: 999 }}
      />
      <Tooltip
        id="openChat"
        content="Open the chat"
        place="li"
        style={{ zIndex: 999 }}
      />
      <Tooltip
        id="vmode"
        content={video ? "camera off" : "camera on"}
        style={{ zIndex: 999 }}
      />
      <Tooltip
        id="mice"
        content={mute ? "Mute" : "Unmute"}
        style={{ zIndex: 999 }}
      />
    </div>
  );
};

export default UserInterface;
