import React from "react";

const RightBar = () => {
  return (
    <div className="col-lg-4 col-xl-3 d-none d-md-block">
      <div className="user mb-3">
        <span
          style={{
            backgroundImage: "url(./../../assets/images/review_user.jpg)",
          }}
        ></span>
        <p>
          Leads have been increased by 3x using Chownk and Conversion ration
          also increased too
        </p>
        <h6>Manu Singh , Chandigarh</h6>
      </div>

      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="../../assets/images/Slide_1.png"
              alt="choose"
              className="w-100"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../assets/images/Slide_2.png"
              alt="choose"
              className="w-100"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../assets/images/Slide_3.png"
              alt="choose"
              className="w-100"
            />
          </div>
        </div>
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
            className=""
          ></button>
        </div>
      </div>
    </div>
  );
};

export default RightBar;
