import { useEffect, useState } from "react";

const NotificationBox = ({ type, description, message, time }) => {
  const [path, setPath] = useState("");

  useEffect(() => {
    switch (type) {
      case "payment":
        setPath("../../assets/images/noti_plus.svg");
        return;
      case "call":
        setPath("../../assets/images/noti_call.svg");
        return;
      default:
        setPath("../../assets/images/noti_send.svg");
        return;
    }
  }, [type]);

  return (
    <div className="set">
      <div className="img">
        <img src={path} alt="percent" />
      </div>
      <h6>
        {message} <span className="time">{time}</span>
      </h6>
      {description && description !== "" && <p>{description}</p>}
    </div>
  );
};
export default NotificationBox;
