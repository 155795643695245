import { googleMapApi } from "../../../../data/data";
import SimpleInput from "../../../Tools/SimpleInput";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useSelector } from "react-redux";

const CompanyAddress = ({
  label,
  labelDescription,
  errors,
  event,
  address,
  control,
  googleHandleChange,
  userData,
  setValue,
  dispatch,
  clearErrors,
  setAddress,
  slug,
  getCity,
  slugMessage,
}) => {
  const selector = useSelector((state) => state.auth);
  return (
    <div className="row mt-4">
      <div className="col-md-4">
        <h5>{label}</h5>
        <p className="label">{labelDescription}</p>
      </div>
      <div className="col-md-8">
        <div className="set mb-2">
          <ReactGoogleAutocomplete
            apiKey={googleMapApi}
            onPlaceSelected={googleHandleChange}
            inputAutocompleteValue={userData && userData.permanent_address}
            options={{
              types: ["geocode"],
              componentRestrictions: { country: "in" },
            }}
          />
          <SimpleInput
            inType={"hidden"}
            name={"permanent_address"}
            event={event}
          />
          {errors && errors.permanent_address && (
            <p className="arr">
              <i>
                <i className="bi bi-info-circle-fill"></i>{" "}
                {errors.permanent_address.message}
              </i>
            </p>
          )}
          {slug && getCity && slugMessage?.style?.color == "green" && (
            <p>{`Profile Link: https://www.chownk.com/${getCity.replace(
              / /g,
              "-"
            )}/${slug}`}</p>
          )}
        </div>
        <SimpleInput
          style={"form-control mb-2"}
          event={event}
          name={"city"}
          placeholder={"City/Town"}
          readOnly={true}
          disabled={true}
          inType={"hidden"}
        />
        {errors && errors.city && (
          <p className="arr">
            <i>
              <i className="bi bi-info-circle-fill"></i> {errors.city.message}
            </i>
          </p>
        )}
        <div className="row">
          <div className="col-sm-6">
            <SimpleInput
              style={"form-control mb-2"}
              event={event}
              name={"state"}
              placeholder={"State/Region"}
              inType={"hidden"}
              readOnly={true}
              disabled={true}
            />
            {errors && errors.state && (
              <p className="arr">
                <i>
                  <i className="bi bi-info-circle-fill"></i>{" "}
                  {errors.state.message}
                </i>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyAddress;
