const FollwerHighlight = ({ data }) => {
  return (
    <div className="wap views_per mb-3">
      <h4>
        <img
          src="../../assets/images/follower_highlights.png"
          alt="Call_History"
        />{" "}
        Follower Highlights
      </h4>
      <p>
        Stay updated with your follower trends to enhance your engagement
        strategy and connect with more clients
      </p>
      <div className="row mb-3 mt-4">
        <div className="col-sm-6">
          <h5>{data != null && data.total ? data.total : 0}</h5>
          <p>Total followers</p>
        </div>
        <div className="col-sm-6">
          <h5>{data != null && data.last && data.total > 0 ? data.last : 0}</h5>
          <p className="mb-0">New followers in the last 30 days</p>
          <p className="text-danger">
            <b>{data != null && data.ratting ? data.ratting : 0}%</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FollwerHighlight;
