import React, { useState } from "react";
import Meta from "../../Common/Meta";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import NavTabs from "../../../Tools/NavTabs";
import Followers from "./followers";
import Competitors from "./competitors";
import Visitors from "./visitors";
import { navTabsData } from "../../../../services/Analytics";

const Analytics = () => {
  const [activeTab, setActiveTab] = React.useState("visitors");
  const dt = localStorage.getItem("dt");

  return (
    <>
      <Meta title={"Partner Dashboard : Chownk"} />
      <StudentHeader
        style={"header_white"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />
      <section
        className="dashboard"
        // style={{
        //   backgroundImage: "url(../assets/images/login_screen.png)",
        // }}
      >
        <div
          className="dashboard_in"
          // style={{
          //   backgroundImage: "url(../../../assets/images/login_screen2.png)",
          // }}
        >
          <div className="container">
            <div className="latest_dashboard analytics">
              <h2 className="ht">
                <i className="bi bi-pie-chart-fill"></i> Analytics
              </h2>
              {/* <p>
                You do not qualify for Reports and Analytics. Kindly obtain a
                minimum of 5 reviews and 10 followers to access this feature.
              </p> */}
              <NavTabs
                id={"myTab"}
                active={activeTab}
                data={navTabsData}
                setActiveTab={setActiveTab}
              />
              <div className="tab-content" id="myTabContent">
                <Visitors activeTab={activeTab} />
                <Followers activeTab={activeTab} />
                <Competitors activeTab={activeTab} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {!dt && <StudentFooter />}
    </>
  );
};
export default Analytics;
