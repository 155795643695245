import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const VerificationSection = ({
  city,
  joinedOn,
  followers,
  addressStatus,
  businessProofStatus,
  activeStatus,
  gstStatus,
  panStatus,
  selector,
  companyOwner,
  loading,
  handleUserNavigate,
}) => {
  const navigate = useNavigate();
  const [day, setDay] = useState("");
  const ordinalSuffixes = ["th", "st", "nd", "rd"];
  const date = new Date(joinedOn);

  const suffix = ordinalSuffixes[day % 10] || ordinalSuffixes[0];

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th"; // exceptions for 11th to 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  useEffect(() => {
    setDay(new Date(joinedOn).getUTCDate());
  }, [joinedOn]);

  return (
    <>
      <div class="st px-4">
        <h2>Verifications</h2>
        <div class="Verified mb-4">
          {loading ? (
            <Skeleton />
          ) : (
            <h3 data-tooltip-id="active">
              <i class="bi bi-person-vcard-fill"></i> Identity Verified{" "}
              {activeStatus ? (
                <span>
                  <i class="bi bi-check-circle-fill"></i>
                </span>
              ) : selector?.uid == companyOwner ? (
                <span>Verify</span>
              ) : (
                <span>
                  {" "}
                  <i class="bi bi-x-circle-fill"></i>
                </span>
              )}
            </h3>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <h3 data-tooltip-id="address">
              <i class="bi bi-geo-alt-fill"></i> Company Address Verified{" "}
              {addressStatus === "Approved" ? (
                <span>
                  <i class="bi bi-check-circle-fill"></i>
                </span>
              ) : selector?.uid == companyOwner ? (
                <span
                  onClick={() => {
                    navigate(`/account-settings`, {
                      state: { active: "kyc_documents" },
                    });
                    handleUserNavigate(
                      "navigate",
                      "/account-settings",
                      "Update Company Address"
                    );
                  }}
                >
                  Verify
                </span>
              ) : (
                <span>
                  {" "}
                  <i class="bi bi-x-circle-fill"></i>
                </span>
              )}
            </h3>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <h3 data-tooltip-id="business">
              <i class="bi bi-file-earmark-medical-fill"></i> Business Proof
              Verified{" "}
              {businessProofStatus === "Approved" ? (
                <span>
                  <i class="bi bi-check-circle-fill"></i>
                </span>
              ) : selector?.uid == companyOwner ? (
                <span
                  onClick={() => {
                    navigate(`/account-settings`, {
                      state: { active: "kyc_documents" },
                    });
                    handleUserNavigate(
                      "navigate",
                      "/account-settings",
                      "Update Business Proof"
                    );
                  }}
                >
                  Verify
                </span>
              ) : (
                <span>
                  {" "}
                  <i class="bi bi-x-circle-fill"></i>
                </span>
              )}
            </h3>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <h3 data-tooltip-id="phone">
              <i class="bi bi-telephone-fill"></i> Phone Verified{" "}
              <span>
                <i class="bi bi-check-circle-fill"></i>
              </span>
            </h3>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <h3 data-tooltip-id="gst">
              <i class="bi bi-currency-rupee"></i>
              GST/PAN Verified{" "}
              {gstStatus === "Approved" || panStatus === "Approved" ? (
                <span>
                  <i class="bi bi-check-circle-fill"></i>
                </span>
              ) : selector?.uid == companyOwner ? (
                <span
                  onClick={() => {
                    navigate(`/account-settings`, {
                      state: { active: "kyc_documents" },
                    });
                    handleUserNavigate(
                      "navigate",
                      "/account-settings",
                      "Update GST/PAN"
                    );
                  }}
                >
                  Verify
                </span>
              ) : (
                <span>
                  {" "}
                  <i class="bi bi-x-circle-fill"></i>
                </span>
              )}
            </h3>
          )}
        </div>

        <h3>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {" "}
              <span>
                <i class="bi bi-geo-alt-fill"></i>
              </span>
              {`${city}, India`}
            </>
          )}
        </h3>
        <h3>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <span>
                <i class="bi bi-calendar2-check-fill"></i>
              </span>{" "}
              Joined on <br />
              {`${day + getOrdinalSuffix(day)} ${date.toLocaleDateString(
                "default",
                {
                  month: "long",
                }
              )} ${date.getFullYear()} `}
            </>
          )}
        </h3>
        <h3 class="mb-3 mb-xxl-5 ">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {" "}
              <span>
                <i class="bi bi-people-fill"></i>
              </span>{" "}
              {followers} <br />
              Followers
            </>
          )}
        </h3>
      </div>
      <Tooltip
        id="active"
        content={
          activeStatus
            ? "Identity Verified with Government Issued ID Card"
            : "Identity of this Business is not Verified "
        }
        place="bottom"
      />
      <Tooltip
        id="address"
        content={
          addressStatus === "Approved"
            ? "Address of this Business is verified and its Valid"
            : "Address of this Business is not Verified "
        }
        place="bottom"
      />
      <Tooltip
        id="business"
        content={
          businessProofStatus === "Approved"
            ? "Business Proof is Verified by Government Issued Business Proof"
            : "Business Proof of this Business is not Verified "
        }
        place="bottom"
      />
      <Tooltip
        id="phone"
        content={"Phone number  is verified and its valid"}
        place="bottom"
      />
      <Tooltip
        id="gst"
        content={
          gstStatus === "Approved" || panStatus === "Approved"
            ? "Gst/Pan is verified and its valid"
            : "Gst/Pan is not verified of this Business"
        }
        place="bottom"
      />
    </>
  );
};

export default VerificationSection;
