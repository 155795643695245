import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { useState } from "react";
import { getDocs } from "../../../services/api";
import { claimBusiness } from "../../../Reducer/claimAndReportSlice";
import FormInputField from "../../Tools/Forminput";

const ClaimModel = ({ show, setShow, id }) => {
  const [docs, setDocs] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(businessClaimSchema),
  });
  const { success, submitting, error } = useSelector(
    (state) => state.claimAndReport
  );
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDocs();
  }, []);

  const fetchDocs = async () => {
    const response = await getDocs();
    setDocs(response);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("branch_id", id);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("detail", data.detail);
    formData.append("selection_document_id", data.selection_document_id);
    formData.append("document_image", data.document_image[0]);
    dispatch(claimBusiness(formData));
    setShow(false);
  };

  return (
    <div className="Account_Settings mt-0">
      <h4 className="mb-0">Claim Business</h4>
      <p className="mb-3">Fill all your details to claim business</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-2">
          <div className="col-md-5">
            <h5>Full Name</h5>
            <p className="label">
              Customize your security profile name and URL to share with
              vendors.
            </p>
          </div>

          <FormInputField
            parentStyle={"col-md-7"}
            childStyle={"form-control"}
            type={"text"}
            name={"name"}
            placeholder={"Enter full name"}
            event={register}
            errors={errors}
          />
        </div>
        <div className="row mt-2">
          <div className="col-md-5">
            <h5>Mobile Number</h5>
            <p className="label">
              Customize your security profile name and URL to share with
              vendors.
            </p>
          </div>
          <FormInputField
            parentStyle={"col-md-7"}
            childStyle={"form-control"}
            type={"text"}
            name={"phone"}
            placeholder={"Enter mobile number"}
            event={register}
            errors={errors}
          />
        </div>
        <div className="row mt-2">
          <div className="col-md-5">
            <h5>ID Proof</h5>
            <p className="label">
              Upload any proof like, Aadhaar Card, Pan Card, GST ect
            </p>
          </div>
          <div className="col-md-7">
            <select
              className="form-control"
              name="selection_document_id"
              {...register("selection_document_id")}
            >
              <option value="">Select Document</option>
              {docs &&
                docs.length > 0 &&
                docs.map((el, i) => <option value={el.id}>{el.name}</option>)}
            </select>

            <FormInputField
              childStyle={"form-control mb-3 mt-3"}
              type={"text"}
              placeholder={"Enter details"}
              name={"detail"}
              event={register}
              errors={errors}
            />

            <div className="form-control text-center">
              <img src="../assets/images/image_up.png" alt="image_up" />
              <p className="mb-0 pb-0">
                <input
                  type="file"
                  name="document_image"
                  {...register("document_image")}
                />
                <b>Click to replace</b> or drag and drop
              </p>
              <p className="mb-0 pb-0">SVG, PNG, JPG or GIF (max. 800*400px)</p>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-5"></div>
          <div className="col-md-7">
            <div className="back_btn_pop">
              <button type="submit" className="btn btn-primary">
                Claim Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClaimModel;
