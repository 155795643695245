import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { ReactMediaRecorder } from "react-media-recorder-2";
import { ContextProvider } from "./Context";
import {
  setChats,
  setToken,
  setUser,
  setUserDetails,
} from "./Reducer/authSlice";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import * as process from "process";
window.global = window;
window.process = process;
window.Buffer = [];

const user =
  localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : "";
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : "";
const userDetails =
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : "";

const chats = localStorage.getItem("chats")
  ? JSON.parse(localStorage.getItem("chats"))
  : [];

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}

if (userDetails) {
  store.dispatch(setUserDetails(userDetails));
}

if (chats) {
  store.dispatch(setChats(chats));
}

const APP = (
  <BrowserRouter>
    <Provider store={store}>
      <ReactMediaRecorder
        audio
        render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
          <ContextProvider
            status={status}
            startRecording={startRecording}
            stopRecording={stopRecording}
            mediaBlobUrl={mediaBlobUrl}
          >
            <App />
          </ContextProvider>
        )}
      />
    </Provider>
  </BrowserRouter>
);
const rootElement = document.getElementById("root");
ReactDOM.render(APP,rootElement);
