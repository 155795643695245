import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { followUnfollowApi } from "../../../../services/api";
import UploadImageModal from "../../../Layouts/Model/uploadImageModal";
import SimpleInput from "../../../Tools/SimpleInput";
import { Toaster, toast } from "react-hot-toast";

const BannerSection = ({
  bgImage,
  logo,
  companyName,
  tagline,
  rating,
  ratingCount,
  selector,
  companyOwner,
  addressStatus,
  businessProofStatus,
  activeStatus,
  gstStatus,
  loading,
  loginModelHandle,
  like,
  setBannerImage,
  bannerImage,
  updateProfileFun,
  companyLogo,
  setCompanyLogo,
  msgStatus,
  videoStatus,
  shareModal,
  setShareModal,
  handleUserNavigate,
  city,
}) => {
  const [following, setFollowing] = useState(0);
  const [bannerEdit, setBannerEdit] = useState(false);
  const [logoEdit, setLogoEdit] = useState("");
  const [visible, setVisible] = useState(false);
  const [editTagline, setEditTagline] = useState("");
  const [followingSms, setFollowingSms] = useState("");
  const [newTagline, setNewTagline] = useState(tagline ? tagline : "");
  const navigate = useNavigate();

  useEffect(() => {
    setNewTagline(tagline);
  }, [tagline]);

  useEffect(() => {
    setFollowing(like);
  }, [like]);

  const likeHandle = (id) => {
    accept(id);
  };

  const accept = (id) => {
    followUnfollowApi({ followId: id })
      .then((response) => {
        if (response.data.follow) {
          setFollowing(1);
          handleUserNavigate(
            "action",
            "follow",
            `Started following ${companyName}`
          );
          toast.success("You started following", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        } else {
          handleUserNavigate(
            "action",
            "unfollow",
            `Started unfollowing ${companyName}`
          );
          toast.success("You unfollowed", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setFollowing(0);
        }
        setTimeout(() => {
          setFollowingSms("");
        }, 3000);
      })
      .catch((e) => {
        toast.error(e?.response.data.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  const handleBanner = (image) => {
    setBannerImage(URL.createObjectURL(image));
    let formData = new FormData();
    formData.append("banner_image", image);
    updateProfileFun(formData);
  };

  const handleCompanyLogo = (image) => {
    setCompanyLogo(URL.createObjectURL(image));
    let formData = new FormData();
    formData.append("profile_image", image);
    updateProfileFun(formData);
  };

  const handleTagline = () => {
    let formData = new FormData();
    formData.append("tagline", newTagline);
    updateProfileFun(formData);
    setEditTagline(!editTagline);
  };


  return (
    <>
      <Toaster position="bottom-center" />
      <div className="single_banner">
        {loading ? (
          <div className="bgimage" style={{ height: "270px", width: "100%" }}>
            <Skeleton height={"100%"} width={"100%"} />
          </div>
        ) : (
          <div className="bgimage">
            <img
              src={`${bannerImage ? bannerImage : bgImage}`}
              alt={`${companyName} Banner - Best Immigration Consultants in ${city} on CHOWNK`}
              title={`${companyName} Banner - Best Immigration Consultants in ${city} on CHOWNK`}
            />
            {selector?.uid === companyOwner && (
              <Link
                className="edit_profile"
                onClick={() => setBannerEdit(!bannerEdit)}
              >
                <i className="bi bi-pencil-square"></i>
              </Link>
            )}
            {bannerEdit && (
              <UploadImageModal
                childStyle={"banner_upload"}
                submitImage={handleBanner}
                onHide={() => setBannerEdit(!bannerEdit)}
                MinHeight={300}
                MinWidth={855}
              />
            )}
          </div>
        )}

        <div className="pencil_banner">
          <div className="row">
            <div className="col-sm-3">
              {loading ? (
                <div className="img">
                  {" "}
                  <Skeleton height={"100%"} borderRadius={"24px"} />
                </div>
              ) : (
                <div className="img">
                  <img
                    src={`${companyLogo ? companyLogo : logo}`}
                    alt={`${companyName} Profile - Best Immigration Consultants in ${city} on CHOWNK`}
                    title={`${companyName} Profile - Best Immigration Consultants in ${city} on CHOWNK`}
                  />
                  {selector?.uid === companyOwner && (
                    <i
                      className="bi bi-pencil-square"
                      onClick={() => setLogoEdit(!logoEdit)}
                    ></i>
                  )}
                </div>
              )}
              {logoEdit && (
                <UploadImageModal
                  submitImage={handleCompanyLogo}
                  onHide={() => setLogoEdit(!logoEdit)}
                  MinHeight={300}
                  MinWidth={300}
                />
              )}
            </div>
            <div className="col-sm-9">
              <div className="headpro">
                <div
                  className="d-inline d-sm-none"
                  onClick={() => navigate(-1)}
                >
                  <i class="bi bi-arrow-left"></i>
                </div>
                <h1>
                  <span>{loading ? <Skeleton /> : companyName}</span>
                  {addressStatus === "Approved" &&
                    gstStatus === "Approved" &&
                    businessProofStatus === "Approved" &&
                    activeStatus && (
                      <i
                        data-tooltip-id="verified"
                        class="bi bi-patch-check-fill"
                      ></i>
                    )}
                </h1>
                {editTagline ? (
                  <div className="tgg">
                    <SimpleInput
                      inType={"text"}
                      placeholder={"Add Tagline"}
                      value={newTagline}
                      onChange={(e) => setNewTagline(e.target.value)}
                      maxlength={70}
                    />
                    <i
                      class="bi bi-check-circle-fill"
                      onClick={() => handleTagline()}
                    ></i>
                    <i
                      class="bi bi-x-circle-fill"
                      onClick={() => setEditTagline(!editTagline)}
                    ></i>
                  </div>
                ) : (
                  tagline &&
                  tagline !== "" && (
                    <p className="pst">
                      <span>
                        {loading ? (
                          <Skeleton />
                        ) : newTagline ? (
                          newTagline
                        ) : (
                          tagline
                        )}
                      </span>
                      {selector?.uid === companyOwner && (
                        <>
                          {!tagline && "Add Tagline"}
                          <i
                            className="bi bi-pencil-square"
                            onClick={() => {
                              setEditTagline(!editTagline);
                            }}
                          ></i>
                        </>
                      )}
                    </p>
                  )
                )}
              </div>
              <div className="rate">
                <p
                  onClick={() => {
                    const element = document.getElementById("reviewsSection");
                    if (element) {
                      element.scrollIntoView();
                    }
                  }}
                >
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      <i className="bi bi-star-fill"></i>{" "}
                      {`${rating ? rating?.toFixed(1) : 0.0}`}
                    </>
                  )}
                </p>
                <p>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    `${ratingCount} ${ratingCount > 1 ? "Ratings" : "Rating"}`
                  )}
                </p>
              </div>
              <ul>
                <li
              className={selector && (companyOwner === selector?.uid || companyOwner === selector.parent_id) ? "disabled" : ""}>
                  <Link
                    className="Call"
                    onClick={() => {
                      if (selector?.uid !== companyOwner) {
                        loginModelHandle("video");
                      }
                    }}
                    data-tooltip-id="call"
                  >
                    {videoStatus && msgStatus && <b></b>}
                    <i className="bi bi-camera-video-fill"></i>
                    <span>Call</span>
                  </Link>
                </li>
                <li
                  className={selector && (companyOwner === selector?.uid || companyOwner === selector.parent_id) ? "disabled" : ""}
                >
                  <Link
                    className="Message"
                    onClick={() => {
                      if (companyOwner !== selector?.uid) {
                        loginModelHandle("chat");
                      }
                    }}
                    data-tooltip-id="message"
                  >
                    {msgStatus && <b></b>}
                    <i className="bi bi-chat-left-dots-fill"></i>
                    <span>Message</span>
                  </Link>
                </li>
                <li
                  className={`${selector && (companyOwner === selector?.uid || companyOwner === selector.parent_id) ? "disabled" : ""}`}
                  data-tooltip-id="follow"
                >
                  <Link
                    onClick={() => {
                      if (companyOwner && selector) {
                        if (companyOwner !== selector?.uid) {
                          likeHandle(companyOwner);
                        }
                      } else {
                        loginModelHandle("");
                      }
                    }}
                  >
                    {following ? (
                      <i class="bi bi-person-dash-fill"></i>
                    ) : (
                      <i className="bi bi-person-plus-fill"></i>
                    )}
                    <span>{following ? "Unfollow" : "Follow"}</span>
                  </Link>
                  <span>{followingSms}</span>
                </li>
                <li
                  className={`d-inline-block d-sm-none`}
                  onClick={() => setShareModal()}
                >
                  <Link>
                    <i className="bi bi-share-fill"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="verified" content="Verified by Chownk" place="bottom" />
      <Tooltip id="call" content="Call" place="bottom" />
      <Tooltip id="message" content="Message" place="bottom" />
      <Tooltip
        id="follow"
        content={following ? "Unfollow" : "Follow"}
        place="bottom"
      />
    </>
  );
};

export default BannerSection;
