import React, { useEffect } from "react";
import Button from "../../Tools/Button";
import FormInputField from "../../Tools/Forminput";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  countMinusWithInputValue,
  getBase64,
} from "../../../services/function";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../data/imagefun";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const successStorySchema = yup.object({
  title: yup.string().required("title is required"),
  description: yup.string().required("Description is required"),
});

const NewSuccessStories = ({
  onHide,
  collection,
  setCollection,
  updateProfileFun,
  index,
  setIndex,
}) => {
  const {
    register,
    setError,
    formState: { errors },
    clearErrors,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(successStorySchema),
  });
  const [count, setCount] = useState({
    title: collection[index] ? collection[index].title.length : 0,
    description: collection[index] ? collection[index].description.length : 0,
  });
  const [image, setImage] = useState("");
  const [imgVideo, setImgVideo] = React.useState({
    type: "",
    image: "",
  });
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 50,
    aspect: 600 / 450,
  });
  const [zoom, setZoom] = useState(null);
  const [croppedImage, setCroppedImage] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: 600,
    height: 450,
    x: 0,
    y: 0,
  });
  const [newStoryData, setNewStoryData] = useState({
    title: collection[index] ? collection[index].title : "",
    description: collection[index] ? collection[index].description : "",
    image: collection[index] ? collection[index].image : "",
    media_type: collection[index] ? collection[index].media_type : "",
  });

  useEffect(() => {
    if (collection[index]) {
      setValue("title", collection[index].title);
      setValue("description", collection[index].description);
      setValue("image", collection[index].image);
    }
    return () => {
      setIndex(null);
    };
  }, [index]);

  const handleChange = (e) => {
    if (e.target.name === "title") {
      // console.log("titleRef.current", titleRef.current);
      if (e.target.value.length > 60) {
        setError("title", {
          type: "custom",
          message: "Minimum length 60 words only",
        });
      } else {
        clearErrors("title");
        setNewStoryData({ ...newStoryData, title: e.target.value });
      }
      console.log("e.target.value.length", e.target.value.length);
      setCount((prev) => ({
        ...prev,
        title: e.target.value.length,
      }));
    }
    if (e.target.name === "description") {
      if (e.target.value.length > 500) {
        setError("description", {
          type: "custom",
          message: "Minimum length 500 words only",
        });
      } else {
        clearErrors("description");
        setNewStoryData({ ...newStoryData, description: e.target.value });
      }
      setCount((prev) => ({
        ...prev,
        description: e.target.value.length,
      }));
    }
  };

  const handleImage = (e) => {
    if (
      e.target.files[0].type !== "video/mp4" &&
      e.target.files[0].type !== "video/mov"
    ) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      if (e.target.files[0].size / 1000 / 1000 > 20) {
        setError("image", {
          type: "custom",
          message: "Please Image Upload less than 20 MB",
        });
      } else {
        clearErrors("image");
        getBase64(e.target.files[0])
          .then((res) => {
            setImgVideo({
              type: e.target.files[0].type === "video/mp4" ? "video" : "image",
              image: e.target.files[0].name,
            });
            setNewStoryData({
              ...newStoryData,
              image: res,
              name: e.target.files[0].name,
              media_type:
                e.target.files[0].type === "video/mp4" ? "video" : "image",
            });
          })
          .catch((err) => console.log("error", err));
      }
    }
  };

  const onSubmit = () => {
    let formData = new FormData();
    if (collection[index]) {
      if (newStoryData.image !== collection[index].image) {
        if (collection[index].media_type === "video") {
          formData.append(
            "remove_stories_name_videos[]",
            collection[index].image
          );
        } else {
          formData.append(
            "remove_stories_name_images[]",
            collection[index].image
          );
        }
      }
      collection[index] = {
        ...newStoryData,
      };
      setCollection((prev) => [...prev]);
      let stories = JSON.stringify(collection);
      formData.append("stories", stories);
      updateProfileFun(formData);
      onHide();
    } else {
      setCollection((prev) => [newStoryData, ...prev]);
      let stories = JSON.stringify([newStoryData, ...collection]);
      formData.append("stories", stories);
      updateProfileFun(formData);
      onHide();
    }
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      getBase64(croppedImage)
        .then((res) => {
          setImgVideo({
            type: croppedImage.type === "video/mp4" ? "video" : "image",
            image: croppedImage.name,
          });
          setNewStoryData({
            ...newStoryData,
            image: res,
            name: croppedImage.name,
            media_type: croppedImage.type === "video/mp4" ? "video" : "image",
          });
          setImage("");
        })
        .catch((err) => console.log("error", err));
    } catch (e) {
      console.error(e);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <>
      <div className="modal-content">
        {/* <Button inType={"button"} style={"btn-close"} onClick={onHide} />  */}
        <div className="modal-body p-0">
          <h5>Add Success Story</h5>
          <p>
            Success story empowers users to discover and connect with your
            accomplishments. It serves as a beacon, enabling individuals to
            understand your achievements and engage with your journey, fostering
            meaningful connections and opportunities for collaboration.
          </p>
          <div className="row">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={"Title"}
                name={"title"}
                inType={"text"}
                onChange={handleChange}
                event={register}
                errors={errors}
              />

              <p id="title_count" className="characters">
                {countMinusWithInputValue(60, count.title)} Characters
              </p>

              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={"Description"}
                name={"description"}
                inType={"text"}
                onChange={handleChange}
                errors={errors}
                event={register}
              />

              <p id="title_count" className="characters">
                {countMinusWithInputValue(500, count.description)} Characters
              </p>

              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={
                  <>
                    Upload File
                    <span>
                      {
                        "(Allowed formats: JPG, PNG, GIF,MP4,MOV Maximum file size: 50MB)"
                      }
                    </span>
                  </>
                }
                name={"image"}
                inType={"file"}
                onChange={handleImage}
                accept={
                  "image/png, image/jpeg, image/jpg, video/mp4, video/mov"
                }
                errors={errors}
                event={register}
              />
              <div className="text-end pt-3">
                <Button
                  inType={"submit"}
                  title={"Save"}
                  // onClick={handleSubmit}
                  style={"btn position-relative me-2"}
                />
                <Button
                  style={"ms-2"}
                  inType={"button"}
                  title={"Cancel"}
                  onClick={onHide}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {image && (
        <div className="select_image">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={600 / 450}
            onCropChange={setCrop}
            initialCroppedAreaPercentages={croppedAreaPixels}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          >
            <img src={image} />
          </Cropper>

          <Button inType={"button"} title={"Save"} onClick={showCroppedImage} />
          <Button
            inType={"button"}
            title={"Cancel"}
            onClick={onHide}
            style={"cancel_btn"}
          />
        </div>
      )}
    </>
  );
};

export default NewSuccessStories;
