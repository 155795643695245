import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import TabTwoForm from "./TabTwoForm";
import Header from "./header";
import HandleButtons from "./stepFormHandleButton";

import {
  JoinKycProcessSchema,
  emailregExp,
  firstVerificationSchema,
} from "../../../../services/schema";
import {
  firstVerificationFun,
  phoneVerification,
  setAction,
  setKycVerificationSuccess,
  setPhoneNumber,
  updateFirstVerification,
} from "../../../../Reducer/authSlice";
import {
  availableCompanyNameApi,
  checkBranchApi,
  emailCheck,
} from "../../../../services/api";
import Meta from "../Meta";
import { JoinUsMetaTags } from "../../../../data/metaTags";
import Example from "../../../Tools/ReactBoostrap";
import StudentLogin from "../../Student/Login";
import StudentOtp from "../../Student/Otp";
import { elements } from "chart.js";

const JoinPage = () => {
  const [step, setStep] = React.useState(1);
  const [address, setAddress] = React.useState("");
  const [availablePhone, setAvailablePhone] = React.useState(false);
  const [type, setType] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [ootp, setOotp] = React.useState("");
  const [lat, setLat] = React.useState("");
  const [lng, setLng] = React.useState("");
  const [userGetData, setUserGetData] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [timeOut, setTimeoutt] = useState("");
  const [message, setMessage] = React.useState("");
  const [slugMessage, setSlugMessage] = React.useState("");
  const selector = useSelector((state) => state.auth);
  const [resendOtp, setResendOtp] = useState(false);
  const [otpButtonHide, setOtpButtonHide] = useState(false);
  const [otpButtonHideOne, setOtpButtonHideOne] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(
      step == 3 ? JoinKycProcessSchema : firstVerificationSchema
    ),
  });

  useEffect(() => {
    modelViewControll();
  }, [selector.success, selector.updateFirstVerificationSuccess]);

  if (selector.kycVerificationSuccess) {
    toast.success(selector.kycVerificationMessage, {
      position: toast.POSITION.TOP_LEFT,
    });
    dispatch(setKycVerificationSuccess(false));
    navigate("/partner-dashboard");
    // window.location.href = "/partner-dashboard";
  }
  React.useEffect(() => {
    dispatch(setAction(""));
    if (
      selector &&
      selector.auth &&
      (selector.auth.role === 5 || selector.auth.role === 6)
    ) {
      navigate(selector.auth.role === 5 ? "/verification" : "/");
      // navigate(selector.auth.role === 5 ? "/partner-dashboard" : "/");
    }
    // if (selector.firstVerificationSuccess) {
    //   navigate("/partner-dashboard");
    // }

    if (selector && selector.auth && selector?.auth?.role === 5) {
      setValue("phone", selector.auth.phone);
      setValue("owner_name", selector.auth.owner_name);
      setValue("company_name", selector.auth.company_name);
      setValue("second_address", selector.auth.second_address);
      setValue("type", selector.auth.type);
      setValue("phone", selector.auth.phone);
      setAddress(selector.auth.permanent_address);
      setValue("permanent_address", selector.auth.permanent_address);
      setValue("city", selector.auth.city);
      setValue("state", selector.auth.state);
      setValue("zipcode", selector.auth.zipcode);
    }

    if (
      selector &&
      selector.auth &&
      (selector?.auth?.role === 1 || selector?.auth?.role === 7)
    ) {
      setValue("phone", selector.auth.phone);
      setValue("owner_name", selector.auth.name);
    }
  }, [
    selector.firstVerificationSuccess,
    selector.kycVerificationSuccess,
    selector?.auth,
  ]);

  const googleHandleChange = async (place) => {
    setAddress(place.label);
    setValue("permanent_address", place.formatted_address);
    setLat(place.geometry.location.lat());
    setLng(place.geometry.location.lng());
    clearErrors("permanent_address");
    let city = "";
    let state = "";

    const objj = {
      latitude: selector.latitude,
      longitude: selector.longitude,
      ip_address: selector.ip_address,
      address: place.formatted_address,
    };

    place.address_components.forEach((element) => {
      if (element.types.includes("administrative_area_level_3")) {
        city = element.long_name;
        setValue("city", city);
        setUserGetData((prev) => ({
          ...prev,
          city: city,
        }));
        clearErrors("city");
      }
      // else {
      //   city = place.formatted_address.split(",")[0];
      //   setValue("city", city);
      //   setUserGetData((prev) => ({
      //     ...prev,
      //     city: city,
      //   }));
      //   clearErrors("city");
      // }
      if (element.types.includes("administrative_area_level_1")) {
        state = element.long_name;
        setValue("state", state);
        clearErrors("state");
      }
    });
  };

  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };
  const onResendFun = () => {
    setResendOtp(true);
    const bodyy = {
      phone: selector.phoneNumber,
      latitude: selector.userDetails?.latitude,
      longitude: selector.userDetails?.longitude,
      ip_address: selector.userDetails?.ip_address,
      current_address: selector.userDetails?.current_address,
    };
    dispatch(phoneVerification(bodyy));
    setOtpShow(false);
  };
  const onSubmit = async (data) => {
    if (slugMessage?.style?.color !== "green") {
      setError("company_name", {
        type: "custom",
        message: "Company Name not Available",
      });
    } else {
      if (selector.auth === null) {
        //not have data in session run the agent register api
        if (availablePhone) {
          //check the phone number is available
          if (ootp.length == 4) {
            let formDataa = new FormData();
            formDataa.append("owner_name", data.owner_name);
            formDataa.append("company_name", data.company_name);
            formDataa.append("permanent_address", data.permanent_address);
            formDataa.append("latitude", lat);
            formDataa.append("longitude", lng);
            formDataa.append("app_version", "17.05.02");
            formDataa.append("type", data.type);
            formDataa.append("phone", data.phone);
            formDataa.append("device_id", "");
            formDataa.append("device_token", "");
            formDataa.append("platform", "web");
            formDataa.append("app_id", 2);
            formDataa.append("ip_address", selector.userDetails.ip_address);
            formDataa.append("company_logo", "");
            formDataa.append("state", data.state);
            formDataa.append("zipcode", data.zipcode);
            formDataa.append("city", data.city);
            formDataa.append("email", data.email);
            formDataa.append("slug", slug);
            formDataa.append("otp", ootp);
            dispatch(firstVerificationFun(formDataa));
          } else {
            toast.error("Please Fill The Otp", {
              position: toast.POSITION.TOP_LEFT,
            });
          }
        } else {
          toast.error("This phone number not availalbe for you", {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      } else {
        dispatch(setAction("header"));
        let formDataaa = new FormData();
        formDataaa.append("owner_name", data.owner_name);
        formDataaa.append("company_name", data.company_name);
        formDataaa.append("permanent_address", data.permanent_address);
        formDataaa.append("latitude", selector.userDetails.latitude);
        formDataaa.append("longitude", selector.userDetails.longitude);
        formDataaa.append("app_version", "17.05.02");
        formDataaa.append("type", data.type);
        formDataaa.append("phone", data.phone);
        formDataaa.append("device_id", "");
        formDataaa.append("device_token", "");
        formDataaa.append("platform", "web");
        formDataaa.append("app_id", 2);
        formDataaa.append("ip_address", selector.userDetails.ip_address);
        formDataaa.append("second_address", data.second_address);
        formDataaa.append("email", data.email);
        formDataaa.append("state", data.state);
        formDataaa.append("zipcode", data.zipcode);
        formDataaa.append("city", data.city);
        formDataaa.append("slug", slug);
        formDataaa.append(
          "oldImage",
          selector.auth && selector.auth.profile_image
            ? selector.auth.profile_image
            : ""
        );
        dispatch(updateFirstVerification(formDataaa));
      }
    }
  };

  const handleCheckNumber = (e) => {
    setOtpButtonHide(false);
    if (isNaN(e.target.value)) {
      setError("phone", { type: "custom", message: "Not use the aplhabet" });
    } else if (e.target.value.length > 10) {
      setError("phone", {
        type: "custom",
        message: "phone must be at most 10 characters",
      });
    } else {
      clearErrors("phone");
    }
    if (e.target.value.trim().length === 10 && !isNaN(e.target.value)) {
      setMessage("");
      clearErrors("phone");
      setAvailablePhone(false);
      checkBranchApi({ phone: e.target.value })
        .then((response) => {
          if (response.data.available) {
            clearErrors("phone");
            setMessage(response.data.message);
            setAvailablePhone(response.data.available);
            setOtpButtonHideOne(true);
            dispatch(setPhoneNumber(e.target.value));
          } else {
            setAvailablePhone(response.data.available);
            setError("phone", {
              type: "custom",
              message: response.data.message,
            });
          }
        })
        .catch((e) => {
          setAvailablePhone(e.response.data.available);
          setError("phone", {
            type: "custom",
            message: e.response.data.message,
          });
        });
    }
    setAvailablePhone(false);
  };

  const companyNameHandleChange = async (e) => {
    clearErrors("company_name");
    clearTimeout(timeOut);
    if (selector.auth && selector.auth?.company_name === e.target.value) {
      clearErrors("company_name");
    } else {
      let TimeInterval = setTimeout(findCompany(e.target.value), 2000);
      setTimeoutt(TimeInterval);
    }
  };

  const findCompany = (val) => {
    availableCompanyNameApi({ company: val })
      .then((response) => {
        if (response.data.available) {
          clearErrors("company_name");
          setSlugMessage({
            style: { color: "green" },
            message: response.data.message,
          });
          setSlug(response.data.slug);
        } else {
          setSlugMessage({
            style: { color: "red" },
            message: "Company Name not Available",
          });
          setError("company_name", {
            type: "custom",
            message: response.data.message,
          });
        }
      })
      .catch((e) => {
        setSlugMessage({
          style: { color: "red" },
          message: "error",
        });
        setError("company_name", { type: "custom", message: e.message });
      });
  };

  const modelViewControll = () => {
    if (selector.success && selector.actionType == "header") {
      setShow(false);
      setOtpShow(true);
      if (resendOtp) {
        setOtpShow(false);
        setResendOtp(false);
      }
    }
    if (selector.otpSuccess && selector.actionType == "header") {
      dispatch(setAction(""));
      switch (selector.redireaction) {
        case "/home":
          navigate(
            selector.auth?.role === 5
              ? "/partner-dashboard"
              : selector.auth?.role === 6
              ? "/staff-dashboard"
              : "/"
          );
        case "/kyc":
          navigate(
            selector.auth?.role === 5
              ? "/partner-dashboard"
              : selector.auth?.role === 6
              ? "/staff-dashboard"
              : "/"
          );
          break;
        case "/":
          navigate(selector.auth?.role === 5 ? "/partner-dashboard" : "/");
          break;
        case "/profile_setup":
          if (selector.auth?.role !== 5) {
          } else {
            navigate(selector.auth?.role === 5 ? "/partner-dashboard" : "/");
          }
          dispatch(setAction(""));
          break;
        case "/login":
          toast.success(selector.error, {
            position: toast.POSITION.TOP_LEFT,
          });
          break;
      }
    }
  };

  const handleEmailChange = async (e) => {
    try {
      const { value } = e.target;
      if (value.match(emailregExp)) {
        const response = await emailCheck(value);
        if (response.data.status === true) {
          setEmailAvailable(true);
          clearErrors("email");
        } else {
          setEmailAvailable(false);
          setError("email", { type: "custom", message: response.data.message });
        }
      } else {
        setEmailAvailable(false);
      }
    } catch (error) {
      setEmailAvailable(false);
      setError("email", { type: "custom", message: "Enter a Valid Email" });
    }
  };

  return (
    <>
      <Meta {...JoinUsMetaTags} />
      <Header />
      <section className="pop_width pb-5">
        <div className="container">
          <div className="modal-body pb-3">
            <div className="join_step_form">
              <form id="regForm" onSubmit={handleSubmit(onSubmit)}>
                <TabTwoForm
                  event={register}
                  control={control}
                  active={
                    step === 1 ? { display: "block" } : { display: "none" }
                  }
                  errors={errors}
                  address={address}
                  ootp={ootp}
                  setOotp={setOotp}
                  googleHandleChange={googleHandleChange}
                  onResendFun={onResendFun}
                  handleOTPTimerTimeout={handleOTPTimerTimeout}
                  phoneCheckHandle={handleCheckNumber}
                  message={message}
                  setMessage={setMessage}
                  availablePhone={availablePhone}
                  companyHanleChange={companyNameHandleChange}
                  dispatch={dispatch}
                  slugMessage={slugMessage}
                  userData={selector && selector.auth}
                  clearErrors={clearErrors}
                  slug={slug}
                  setValue={setValue}
                  setAddress={setAddress}
                  setOtpButtonHide={setOtpButtonHide}
                  otpButtonHide={otpButtonHide}
                  otpButtonHideOne={otpButtonHideOne}
                  setOtpButtonHideOne={setOtpButtonHideOne}
                  getCity={userGetData.city}
                  emailHandleChange={handleEmailChange}
                  emailAvailable={emailAvailable}
                />

                <HandleButtons
                  currentPage={step}
                  setCurrentPage={setStep}
                  errors={errors}
                  type={type}
                  setError={setError}
                  clearError={clearErrors}
                  userData={selector && selector.auth}
                />
              </form>
            </div>

            {selector && !selector.auth && (
              <h6 className="text-center mt-0">
                Already have an account?{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  Log In
                </a>
              </h6>
            )}
          </div>
        </div>
        <Example
          show={show}
          onHide={() => setShow(!show)}
          component={
            <StudentLogin
              onHide={() => {
                setShow(!show);
              }}
              modelViewControll={modelViewControll}
            />
          }
          style={"loginmodal"}
        />
        <Example
          show={otpShow}
          onHide={() => setOtpShow(!otpShow)}
          component={
            <StudentOtp
              onHide={() => setOtpShow(!otpShow)}
              setShowPhoneModel={() => setShow(!show)}
            />
          }
          style={"loginmodal"}
        />
      </section>
    </>
  );
};
export default JoinPage;
