import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./Reducer/authSlice";
import studentSlice from "./Reducer/studentSlice";
import blogSlice from "./Reducer/blogSlice";
import helpSlice from "./Reducer/helpSlice";
import afterSearchSlice from "./Reducer/afterSearchSlice";
import claimAndReportSlice from "./Reducer/claimAndReportSlice";
import accountSettingSlice from "./Reducer/accountSettingSlice";
import applicationSlice from "./Reducer/applicationSlice";
import analyticSlice from "./Reducer/analyticSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  student: studentSlice,
  blogs: blogSlice,
  help: helpSlice,
  afterSearch: afterSearchSlice,
  claimAndReport: claimAndReportSlice,
  accountSetting: accountSettingSlice,
  application: applicationSlice,
  analytic: analyticSlice
});

export default rootReducer;
