import ResponsivePagination from "react-responsive-pagination";
import "bootstrap/dist/css/bootstrap.css";

const PaginationTable = ({ rowsShowLimit, currentPage, setCurrentPage }) => {
  return (
    <div className="text-center">
      <ResponsivePagination
        current={currentPage}
        total={rowsShowLimit}
        onPageChange={setCurrentPage}
        maxWidth={"360"}
      />
    </div>
  );
};

export default PaginationTable;
