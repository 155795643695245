import * as yup from "yup";

const phoneRegExp = /^[0-9]{10}$/;
export const numberAphabet = /^[A-Za-z0-9 ]*$/;
export const emailregExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const loginSchema = yup
  .object({
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(10),
  })
  .required();

export const changeMobileNumberSchema = yup
  .object({
    newPhone: yup
      .string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(10),
  })
  .required();

export const changeEmailSchema = yup.object({
  email: yup
    .string()
    .required("Please Enter your email address")
    .matches(emailregExp, "Email address is not valid"),
});

export const fillDetailsSchema = yup
  .object({
    name: yup.string().required().max(30),
    addhaar_front_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    addhaar_back_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    pan_number_front_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    gst_number_certificate_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    goverment_authorize_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    owner_name: yup.string().required().max(30),
    addhaar_number: yup.string().required().min(12).max(12),
    pan_number: yup.string().required().min(10).max(10),
    company_name: yup.string().required().max(100),
    gst_number: yup.string().required().min(15).max(15),
    permanent_address: yup.string().required(),
    facebook: yup.string().required(),
    instagram: yup.string().required(),
    linkdin: yup.string().required(),
    type: yup.string().required(),
  })
  .required();

export const addMoreBranchSchema = yup
  .object({
    name: yup.string().required().max(30),
    addhaar_front_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    addhaar_back_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    pan_number_front_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    gst_number_certificate_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    goverment_authorize_image: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
    owner_name: yup.string().required().max(30),
    addhaar_number: yup.string().required().min(12).max(12),
    pan_number: yup.string().required().min(10).max(10),
    company_name: yup.string().required().max(100),
    gst_number: yup.string().required().min(15).max(15),
    permanent_address: yup.string().required(),
    facebook: yup.string().required(),
    instagram: yup.string().required(),
    linkdin: yup.string().required(),
    type: yup.string().required(),
    phone: yup.string().required().min(10).max(10),
  })
  .required();

export const registerSchema = yup
  .object({
    name: yup.string().required().min(2),
    profile_image: yup.string().required(),
  })
  .required();

export const editProfileSchema = yup.object({
  name: yup.string().required().min(2),
  email: yup.string().nullable().email("Please Enter Correct Email"),
});

export const firstVerificationSchema = yup
  .object({
    owner_name: yup.string().required("Please enter your full name").min(2),
    company_name: yup
      .string()
      // .matches(/(!|@|#|%)/, "Not use this character")
      .matches(numberAphabet, "Not use special character")
      .required("Please enter your company name")
      .min(2),
    permanent_address: yup
      .string()
      .required("Please enter your company address"),
    phone: yup
      .string()
      .required("Please enter your mobile number")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(10),
    email: yup
      .string()
      .required("Please enter your email address")
      .matches(emailregExp, "Please Enter the correct email"),
  })
  .required();

export const profileUpdateSchema = yup
  .object({
    owner_name: yup.string().required("Please enter your full name").min(2),
    company_name: yup
      .string()
      // .matches(/(!|@|#|%)/, "Not use this character")
      .matches(numberAphabet, "Not use special character")
      .required("Please enter your company name")
      .min(2),
    permanent_address: yup
      .string()
      .required("Please enter your company address"),
    phone: yup
      .string()
      .required("Please enter your mobile number")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(10),
  })
  .required();

export const ApplicationSchema = yup.object({
  fname: yup
    .string()
    .required("Please enter First Name")
    .matches(numberAphabet, "Please fill First Name Correctly")
    .min(3)
    .max(35),

  lname: yup
    .string()
    .required("Please enter Last Name")
    .matches(numberAphabet, "Please fill Last Name Correctly")
    .min(3)
    .max(35),

  gender: yup.string().required("Please Select Gender"),

  dob: yup.string().required("Please Enter Date of Birth").max(35),

  fathername: yup
    .string()
    .required("Please enter Father Name")
    .matches(numberAphabet, "Please fill Father Name Correctly")
    .min(3)
    .max(35),

  mobile1: yup
    .string()
    .required("Please enter your mobile number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(10),

  mothername: yup
    .string()
    .required("Please enter Mother Name")
    .matches(numberAphabet, "Please fill Mother Name Correctly")
    .min(3)
    .max(35),

  mobile2: yup
    .string()
    .required("Please enter your mobile number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(10),

  email: yup
    .string()
    .required("Please enter your Email")
    .matches(emailregExp, "Please Enter Correct Email"),

  address: yup.string().required("Please enter Address"),

  state: yup.string().required("Please enter State"),

  zipcode: yup
    .string()
    .matches(numberAphabet, "Please fill Zipcode Correctly")
    .required("Please enter Zicode"),

  city: yup.string().required("Please enter Address"),

  yearofpassing: yup.string().required("Please enter Year of Passing"),

  score: yup.string().required("Please enter Score"),

  boardofedu: yup.string().required("Please enter Board of Education"),

  institute: yup.string().required("Please enter Institute Name"),

  testattempt: yup.string().required("Please enter Test Attempt"),

  examdate: yup.string().required("Please enter Exam Date").max(10),

  overallscore: yup.string().required("Please enter overall Score"),

  highqualification: yup.string().required("Please enter High Qualification"),

  listening: yup.string().required("Please enter listening Score"),

  reading: yup.string().required("Please enter reading Score"),

  writing: yup.string().required("Please enter reading Score"),

  document: yup.string().required("Please Select document"),

  documentnumber: yup.string().required("Please Enter Passport Number"),

  expirydate: yup.string().required("Please Enter expiry date").max(10),

  academic: yup.string().required("Please Select Attachment"),

  attachmentimg: yup.string().required("Please Select Attachment"),
});

export const kycProcessSchema = yup.object({
  // authorize_expiery: yup.string().required(),
  // pan_card:
  //   !user &&
  //   yup
  //     .mixed()
  //     .test("name", "Image is required", (value) => {
  //       return value !==undefined && value[0] && value[0].name !== "";
  //     })
  //     .test("fileSize", "File must be less than 10MB", (value) => {
  //       return value !==undefined && value[0] && value[0].size <= 2000000;
  //     }),
  // gst_certificate:
  //   !user &&
  //   yup
  //     .mixed()
  //     .test("name", "Image is required", (value) => {
  //       return value !==undefined && value[0] && value[0].name !== "";
  //     })
  //     .test("fileSize", "File must be less than 10MB", (value) => {
  //       return value !==undefined && value[0] && value[0].size <= 10000000;
  //     }),
  // authorize_certificate:
  //   !user &&
  //   yup
  //     .mixed()
  //     .test("name", "Image is required", (value) => {
  //       return value !==undefined && value[0] && value[0].name !== "";
  //     })
  //     .test("fileSize", "File must be less than 10MB", (value) => {
  //       return value !==undefined && value[0] && value[0].size <= 10000000;
  //     }),
  pan_number: yup
    .string()
    .matches(numberAphabet, "Please Pan number fill Correctly")
    .min(10)
    .max(10),
  gst_number: yup
    .string()
    .matches(numberAphabet, "Please Gst number fill Correctly")
    .min(10)
    .max(10),
});

export const JoinKycProcessSchema = yup
  .object({
    authorize_expiery: yup.string().required(),
    pan_card: yup
      .mixed()
      .test("name", "Image is required", (value) => {
        return value !== undefined && value[0] && value[0].name !== "";
      })
      .test("fileSize", "File must be less than 10MB", (value) => {
        return value !== undefined && value[0] && value[0].size <= 2000000;
      }),
    gst_certificate: yup
      .mixed()
      .test("name", "Image is required", (value) => {
        return value !== undefined && value[0] && value[0].name !== "";
      })
      .test("fileSize", "File must be less than 10MB", (value) => {
        return value !== undefined && value[0] && value[0].size <= 10000000;
      }),
    authorize_certificate: yup
      .mixed()
      .test("name", "Image is required", (value) => {
        return value !== undefined && value[0] && value[0].name !== "";
      })
      .test("fileSize", "File must be less than 10MB", (value) => {
        return value !== undefined && value[0] && value[0].size <= 10000000;
      }),
    pan_number: yup
      .string()
      .matches(numberAphabet, "Please Pan number fill Correctly")
      .min(10)
      .max(10),
    gst_number: yup
      .string()
      .matches(numberAphabet, "Please Pan number fill Correctly")
      .min(10)
      .max(10),
  })
  .required();

export const addBranchFormSchema = yup
  .object({
    owner_name: yup.string().required("Owner Name is a required field").min(2),
    phone: yup.string().required(),
    // company_logo: yup
    //   .mixed()
    //   .test("name", "Image is required", (value) => {
    //     return value !==undefined && value[0] && value[0].name !== "";
    //   })
    //   .test("fileSize", "File must be less than 10MB", (value) => {
    //     return value !==undefined && value[0] && value[0].size <= 10000000;
    //   }),
    company_name: yup
      .string()
      .matches(numberAphabet, "Not use special characters")
      .required("Company Name is a required field"),
    email: yup.string().required(),
    permanent_address: yup
      .mixed()
      .test("permanent_address", "This field is required", (value) => {
        return value !== undefined && value && value.formatted_address !== "";
      }),
  })
  .required();

export const addStaffFormSchema = yup
  .object({
    name: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().required(),
    permanent_address: yup
      .mixed()
      .test("permanent_address", "This field is required", (value) => {
        return value !== undefined && value && value.formatted_address !== "";
      }),
  })
  .required();

export const RattingSchema = yup
  .object({
    owner_name: yup.string().required("Please enter your full name").min(2),
    company_name: yup
      .string()
      .required("Please enter your company name")
      .min(2),
    permanent_address: yup
      .string()
      .required("Please enter your company address"),
    phone: yup
      .string()
      .required("Please enter your mobile number")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(10),
  })
  .required();

export const rattingReviewSchema = yup
  .object({
    satisfied: yup.string().required("Please fill the satified"),
    application: yup.string().required("Please fill the application"),
    review: yup.string().required("Please fill the review"),
  })
  .required();

export const contactSchema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(emailregExp, "Please enter a valid email"),
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(10),
    description: yup.string().required("Message is required"),
  })
  .required();
