import ReactTimeAgo from "react-time-ago";
import { mediaUrl } from "../../../services/network";
import $ from "jquery";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { SocketContext } from "../../../Context";
const ChatUser = ({ data, id, click, index }) => {
  const { currentUser } = useContext(SocketContext);
  function shortName() {
    let name = "";
    if (
      (data.userRole === "branch" && data.dynamic_image == 0) ||
      (data.userRole === "staff" && data.dynamic_image == 0)
    ) {
      name = `${data.name}-${data.company_name}`;
    } else {
      name = data.name;
    }
    if (name.length > 20) {
      return `${name.substring(0, 18)}..`;
    }
    return name;
  }
  return (
    <li
      className={`us usr${id} ${
        data &&
        currentUser &&
        (data.id === currentUser.id || data.id === currentUser.connection_user)
          ? "active"
          : ""
      }`}
      onClick={() => {
        $(`.us`).removeClass("active");
        $(`.usr${id}`).addClass("active");
        click(data);
      }}
    >
      {data.dynamic_image === 1 ? (
        <div className="chatuser">
          {data.name.toUpperCase().charAt(0)}
          <i
            className={`bi bi-circle-fill`}
            style={{
              color: `${
                data.online === true || data.online === "true" ? "green" : "red"
              }`,
            }}
          ></i>
        </div>
      ) : (
        <div
          className="chatuser"
          style={{
            backgroundImage: `url(${
              mediaUrl +
              `${data.userRole === "student" ? data.image : data.company_logo}`
            })`,
          }}
        >
          <i
            className={`bi bi-circle-fill`}
            style={{
              color: `${
                data.online === true || data.online === "true" ? "green" : "red"
              }`,
            }}
          ></i>
        </div>
      )}
      <h6>
        {shortName()}
        <label>
          <ReactTimeAgo
            date={data.unix_time * 1000}
            locale="en-US"
            timeStyle="twitter-minute-now"
          />
        </label>
      </h6>
      {data.connection}
      {data.user_id == data.sent_by ? (
        <p>{data.message}</p>
      ) : (
        <p>
          <b>{data.message}</b>
        </p>
      )}
      {data?.smsCount && data.smsCount > 0 ? <span>{data.smsCount}</span> : ""}
    </li>
  );
};
export default ChatUser;
