import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import FeesSection from "./feesSection";
import TopHeading from "./topHeading";

const Membership = () => {
    return (
        <>
            <StudentHeader />
            <TopHeading
                heading={"Membership"}
            />
            <FeesSection />
            <StudentFooter />
        </>
    )
}
export default Membership;