import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StudentHeader from "../../../Layouts/Student/Header/index";
import StudentFooter from "../../../Layouts/Student/Footer/index";
import Table from "../../../Tools/Table";
import { Link } from "react-router-dom";
import NavTabs from "../../../Tools/NavTabs";
import SearchBarWithIcon from "../../../Tools/SearchBarWithIcon";
import {
  fetchApplicationData,
  fetchAppDatapartner,
} from "../../../../Reducer/applicationSlice";
import { mediaUrl } from "../../../../services/network";

const ApplicationList = () => {
  const [activeTab, setActiveTab] = React.useState("all");
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [currentdate, setcurrentdate] = React.useState("");
  const [activeId, setActiveId] = React.useState("1");
  const userApplication = useSelector((state) => state.auth?.auth?.role);

  let list = useSelector((state) => state.application?.list);
  console.log("listdataqw", userApplication);
  const dispatch = useDispatch();

  useEffect(() => {
    // alert(isAdmin)
    let date = new Date();

    setcurrentdate(date);
    let formdata = new FormData();
    formdata.append("activeTab", activeTab);

    if (activeId == 2) {
      // alert(activeTab)
      // alert(activeId)
      //for partner

      if (userApplication == 6) {
        // alert("s")
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }

      // alert("partner")
      dispatch(fetchAppDatapartner(formdata));
    } else {
      // alert(activeId)
      //for user
      // alert("user")
      setIsAdmin(true);
      dispatch(fetchApplicationData(formdata));
    }
    // alert(isAdmin)
  }, [activeTab, activeId, isAdmin]);

  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Title",
      name: "title",
      hidden: isAdmin ? false : true,
    },
    {
      label: "Visa Type",
      name: "visa_type",
      hidden: isAdmin ? false : true,
    },
    {
      label: `${activeId == 1 ? "Offered By" : "Applied By"}`,
      name: "user_details",
      hidden: isAdmin ? true : false,
    },

    {
      label: "Staff",
      name: "username",
      hidden: isAdmin ? true : false,
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Applied By",
      name: "applied_on",
    },
  ];

  const newcolumns = columns.filter((column) => !column.hidden);

  const modifyRows =
    list &&
    list.length > 0 &&
    list.map((_, i) => ({
      ..._,

      user_details: (
        <Link to={`/my-single-application/${_.id}`}>
          <span className="user">
            {/* <img src={`${mediaUrl}${items}`} alt="pdf2" /> */}

            <span
              className="img"
              style={{
                backgroundImage: `url(${mediaUrl}${
                  activeId == 1 ? _.company_logo : _.profile_image
                })`,
              }}
            ></span>
            {_.receiver_name ? _.receiver_name : _.username}
            <span>{_.company_name}</span>
          </span>
        </Link>
      ),
      college_details: (
        <>
          <p>{_.college_name}</p>
          <br></br>
          <span>{_.location}</span>
        </>
      ),

      status: (
        <a href="#" className="activebtn">
          {_.offer_status && _.offer_status == "reject" && "Cancelled"}

          {_.offer_status &&
            _.offer_status !== "pending" &&
            _.payment_status == "completed" &&
            _.fname !== null &&
            "Completed"}
          {_.offer_status &&
            _.offer_status !== "pending" &&
            _.offer_status !== "reject" &&
            _.payment_status == "completed" &&
            _.fname == null &&
            "Active"}
        </a>
      ),
      applied_on: (
        <>
          <p>{_.applied_on}</p>
          <div className="brns float-end">
            <Link
              className="Chat"
              to={
                activeId == 1
                  ? `/messages?msg=${_.sender_id}`
                  : `/messages?msg=${_.receiver_id}`
              }
            >
              <img
                style={{ height: "20px" }}
                src={"../../../assets/images/msg_blue.png"}
              ></img>
            </Link>
            <Link
              to={`/my-single-application/${_.id}`}
              className="d-none d-sm-block"
            >
              View Details
            </Link>
          </div>
        </>
      ),
    }));

  const navTabsColumns = [
    {
      id: "All-tab",
      label: "All",
      name: "all",
    },
    {
      id: "Active-tab",
      label: "Active",
      name: "active",
    },
    {
      id: "Completed-tab",
      label: "Completed",
      name: "completed",
    },
    {
      id: "Cancelled-tab",
      label: "Cancelled",
      name: "cancelled",
    },
  ];

  console.log(list, "list");
  return (
    <>
      <StudentHeader />
      <section className="my_applications_lst">
        <div className="container">
          <h2>
            <img src={"../../assets/images/tabicon4.png"} alt="tabicon4" />{" "}
            {"My Applications"}
            {userApplication && userApplication > 1 ? (
              <ul className="Customer">
                <li
                  className={activeId === "1" ? "active" : "inactive"}
                  onClick={() => setActiveId("1")}
                >
                  {"Customer"}
                </li>
                <li
                  className={activeId === "2" ? "active" : "inactive"}
                  onClick={() => setActiveId("2")}
                >
                  {"Partner"}
                </li>
              </ul>
            ) : (
              ""
            )}
          </h2>
          <SearchBarWithIcon
            style={"form-inline"}
            inputStyle={"form-control"}
            placeholder={"Search here..."}
            areaLabel={"Search"}
            buttonStyle={"btn btn-outline-success my-2 my-sm-0"}
          />
          {/* <form className="form-inline">
               <input className="form-control" type="text" placeholder="Search here..." aria-label="Search" />
               <button className="btn btn-outline-success my-2 my-sm-0" type="submit"><i className="fas fa-search"></i></button>
            </form> */}
          <NavTabs
            style={"mt-3"}
            id={"mtTab"}
            data={navTabsColumns}
            active={activeTab}
            setActiveTab={setActiveTab}
          />

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "all" && "show active"
              }`}
              id="All"
              role="tabpanel"
              aria-labelledby="All-tab"
            >
              <Table columns={newcolumns} rows={modifyRows} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "active" && "show active"
              }`}
              id="Active"
              role="tabpanel"
              aria-labelledby="Active-tab"
            >
              <Table columns={newcolumns} rows={modifyRows} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "completed" && "show active"
              }`}
              id="Completed"
              role="tabpanel"
              aria-labelledby="Completed-tab"
            >
              <Table columns={newcolumns} rows={modifyRows} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "cancelled" && "show active"
              }`}
              id="Cancelled"
              role="tabpanel"
              aria-labelledby="Cancelled-tab"
            >
              <Table columns={newcolumns} rows={modifyRows} />
            </div>
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default ApplicationList;
