import Button from "../../Tools/Button";
import SimpleTextArea from "../../Tools/SimpleTextarea";

const ReviewReplyModel = ({ onHide }) => {
  return (
    <div className="modal-content">
      <Button inType={"button"} style={"btn-close"} onClick={onHide} />
      <div className="modal-body p-0">
        <div className="row">
          <div className="rev">
            <div
              className="imgus"
              style={{ backgroundImage: `url(../../assets/images/user.png)` }}
            ></div>
            <h6>{"Reviews plan"}</h6>
            <p>{"My Reviews for Overseas Consultancy is 4.8 out of 5 stars"}</p>
          </div>

          <h4>{"Your Reply"}</h4>
          <SimpleTextArea placeholder={"Type message..."} />
          <Button style={"post"} inType={"submit"} title={"Post"} />
        </div>
      </div>
    </div>
  );
};
export default ReviewReplyModel;
