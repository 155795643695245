import React, { useEffect, useState, Suspense } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import StudentHeader from "../../../Layouts/Student/Header";
import EducationConsultantFilterOption from "../../../Tools/EducationConsultantFilterOption";
import FilterData from "./filterData";
import StudentFooter from "../../../Layouts/Student/Footer";
import {
  getAfterSearchResult,
  resetStates,
  setMeta,
} from "../../../../Reducer/afterSearchSlice";
import Meta from "../../Common/Meta";
import ListingFaqs from "./listingFaqs";
import { getDynamicFaqs } from "../../../../services/api";
import MainLoader from "../../../Tools/MainLoader";
import { mediaUrl } from "../../../../services/network";
import ListingParaGraph from "./listingParaGraph";

const AfterSearchResult = ({ data, metaTags, script, faqs }) => {
  const [listingFaq, setListingFaq] = useState(faqs?.length > 0 ? faqs : []);
  const [currentPage, setCurrentPage] = React.useState(1);
  // const [citySchema, setCitySchema] = useState(null);
  const { city, dynamic, num } = useParams();
  const [searchParams] = useSearchParams();
  const auth = useSelector((state) => state.auth);
  const { result, totalResults, meta, filterError, submitting, st } =
    useSelector((state) => state.afterSearch);
  const categories = searchParams.get("categories");
  const location = useLocation();
  const [sp, setSp] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mt, setMet] = useState(meta);
  const queryParams = new URLSearchParams(location.search);
  const pagee = queryParams.get("page");

  let PAGE_SIZE = 48;

  const getFaqs = async (body) => {
    const res = await getDynamicFaqs(body);
    if (res.status === 200) {
      setListingFaq(res.data.data);
    }
  };

  useEffect(() => {
    dispatch(setMeta(metaTags));
    const parsed = queryString.parse(location.search, {
      arrayFormat: "bracket",
      parseBooleans: true,
    });
    let visaType = [];
    if (parsed.visatype) {
      const type = parsed.visatype.replaceAll("-", " ");
      visaType.push(type);
    }

    if (data.type !== "") {
      visaType.push(data.type);
    }

    const body = {
      user_id: auth?.auth && auth?.auth?.uid,
      page: pagee ? pagee : 1,
      pageSize: PAGE_SIZE,
      specialization: parsed.specialization ? parsed.specialization : visaType,
      ratting: parsed.ratting,
      online: parsed.online,
      latest: parsed.latest,
      search: parsed.search,
      third: num,
      city: city && city !== "" ? city : parsed.city,
      dynamic: dynamic && dynamic !== "" ? dynamic : "",
      latitude: parsed.lat,
      longitude: parsed.long,
      video_status: parsed.video === true ? "active" : "",
    };
    setSp(body.specialization);
    dispatch(getAfterSearchResult(body));
    return () => {
      dispatch(resetStates());
    };
  }, [location]);

  useEffect(() => {
    if (filterError && filterError.found && filterError.found !== "yes") {
      navigate("/PageNotFound");
    }
    if (metaTags && meta === null) {
      let newmet = {
        ...metaTags,
        script: script && script !== "" ? script : false,
      };

      setMet(newmet);
    } else {
      let newmet = {
        ...meta,
        script: script && script !== "" ? script : false,
      };
      setMet(newmet);
    }
  }, [filterError, meta]);

  useEffect(() => {
    if (city && city !== "" && !dynamic && !num) {
      const body = { first: city, second: "" };
      getFaqs(body);
    } else if (city && dynamic && num) {
      const body = { first: dynamic, second: num };
      getFaqs(body);
    }
  }, [location]);

  return (
    <>
      <Suspense fallback={<MainLoader />}>
        {!st && (
          <Meta
            {...mt}
            ogImage={"/assets/images/chatimg.png"}
            citySchema={
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  name: `Best Immigration Consultants ${
                    city ? `in ${city}` : "in CHOWNK"
                  }`,
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: "4.9",
                    bestRating: "5",
                    reviewCount: `${dynamic ? "1800" : "10345"}`,
                  },
                  review: {
                    "@type": "Review",
                    reviewRating: {
                      "@type": "Rating",
                      ratingValue: "4.9",
                      bestRating: "5",
                    },
                    author: {
                      "@type": "Person",
                      name: "CHOWNK",
                    },
                  },
                })}
              </script>
            }
            listingFaqSchema={
              listingFaq.length > 0 ? (
                <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "FAQPage",
                    mainEntity: listingFaq.map((el, i) => ({
                      "@type": "Question",
                      name: el.question,
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: el.answer,
                      },
                    })),
                  })}
                </script>
              ) : (
                false
              )
            }
            script={
              result.length > 0 && (
                <script type="application/ld+json">
                  {JSON.stringify([
                    {
                      "@context": "http://schema.org",
                      "@type": "LocalBusiness",
                      name: meta?.main_title ? meta.main_title : data.title,
                      url: `${window.location.origin}/${window.location.pathname}`,
                      image: `${mediaUrl}${result[0].company_logo}`,
                      logo: `${mediaUrl}${result[0].company_logo}`,
                      priceRange: "100000.00 - 150000.00",
                      telephone: "+91 8427955568",

                      address: {
                        "@type": "PostalAddress",
                        streetAddress: city ? city : "Optional",
                        addressLocality: city ? city : "Optional",
                        addressRegion: city ? city : "Optional",
                        postalCode: "optional",
                        addressCountry: "IN",
                      },
                      geo: {
                        "@type": "GeoCoordinates",
                        latitude: "30.7410517",
                        longitude: "76.779015",
                      },
                      openingHours: "Mo, Tu, We, Th, Fr, Sa, Su 00:00-23:59",
                      contactPoint: {
                        "@type": "ContactPoint",
                        telephone: "+91 8427955568",
                      },
                    },
                    ...result.map((el) => ({
                      "@context": "http://schema.org",
                      "@type": "LocalBusiness",
                      name: el.company_name,
                      url: `${window.location.origin}/${window.location.pathname}`,
                      image: `${mediaUrl}${el.company_logo}`,
                      logo: `${mediaUrl}${el.company_logo}`,
                      priceRange: "100000.00 - 150000.00",
                      telephone: "+91 8427955568",
                      address: {
                        "@type": "PostalAddress",
                        streetAddress: city ? el.permanent_address : "Optional",
                        addressLocality: city ? city : "Optional",
                        addressRegion: city ? city : "Optional",
                        postalCode: "optional",
                        addressCountry: "IN",
                      },
                      geo: {
                        "@type": "GeoCoordinates",
                        latitude: "30.7410517",
                        longitude: "76.779015",
                      },
                      openingHours: "Mo, Tu, We, Th, Fr, Sa, Su 00:00-23:59",
                      contactPoint: {
                        "@type": "ContactPoint",
                        telephone: "+91 8427955568",
                      },
                    })),
                  ])}
                </script>
              )
            }
          />
        )}

        <StudentHeader
          bellIcon={"hedic1black.png"}
          messageIcon={"hedic2black.png"}
          formDisplay={true}
        />

        {!st && (
          <EducationConsultantFilterOption
            title={data.title}
            description={data.description}
            city={city}
            stype={data.type}
            categories={categories}
            filterDataConsultant={result.length > 0 ? result : []}
            totalRows={totalResults}
          />
        )}

        <FilterData
          filterDataConsultant={result.length > 0 ? result : []}
          city={city}
          dynamic={dynamic}
          categories={categories}
          perPage={PAGE_SIZE}
          totalRows={totalResults}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          sp={sp}
        />
        {mt?.paragraph && <ListingParaGraph para={mt.paragraph} />}
        {listingFaq.length > 0 && <ListingFaqs faqs={listingFaq} />}
        <StudentFooter city={script} />
      </Suspense>
    </>
  );
};
export default AfterSearchResult;
