import React, { useState } from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import { Link } from "react-router-dom";
import StudentFooter from "../../../Layouts/Student/Footer";
import { Accordion } from "react-bootstrap";
import LoginModel from "../../../Tools/LoginModel";
import { useSelector } from "react-redux";

const accordianData = [
  {
    id: 0,
    title: "Connect Through Chat and Video Call",
    desc: " Discover how customers connect with you on CHOWNK. Engage with them through chat and video calls to convert immigration leads into potential clients.",
    video: "https://www.youtube.com/embed/mEEV29_NDzA?si=NNA8Ul0fC705DfaD",
  },
  {
    id: 1,
    title: "Sending an Offer to Customers",
    desc: "Learn how to send offers to your customers on CHOWNK to maximize your earnings and enhance client relationships.",
    video: "https://www.youtube.com/embed/kM5DQAsghDs?si=ZtY1St_roYbbMzuj",
  },
  {
    id: 2,
    title: "Analytics",
    desc: "Learn how to check your analytics on CHOWNK to gain insights that help generate immigration leads.",
    video: "https://www.youtube.com/embed/u1PW72fWsj0?si=EW7WOqGMv-9Wyd2M",
  },
];

const Demo = () => {
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState(accordianData[0].video);
  const selector = useSelector((state) => state.auth);

  return (
    <>
      <StudentHeader formDisplay={true} />
      <section class="action_section text-center">
        <div class="container">
          <h1>CHOWNK Demo Videos</h1>
          <p>
            This page offers a detailed look into how both customers and
            partners can easily navigate on Chownk.Learn through our demo videos
            how users can find the best immigration consultants, and how
            partners can engage, analyze analytics, connect with customers
            through chat and video calls, and send their offers to clients on
            Chownk. Dive into our step-by-step guides and see why Chownk is the
            preferred immigration platform.
          </p>
          {/* <p>
            Check out our customer demo videos, partner demo videos and the
            other features you’ll love.
          </p>
          <p>
            The best way to see what Chownk can do is to try it for Free (set-up
            only takes a few minutes).
          </p>
          <p>
            <b>Note:</b> No Credit Card Required, No Subscription, No Yearly
            Payments
          </p> */}
          <ul class="list-unstyled">
            {selector && selector?.auth?.role !== 5 && (
              <li
                onClick={() => {
                  const element = document.getElementById("customer_demo");
                  element.scrollIntoView({ behavior: "smooth" });
                }}
              >
                <Link>Customer Demo</Link>
              </li>
            )}
            {selector && !selector.auth && (
              <li onClick={() => setShow(!show)}>
                <Link>Sign Up for free</Link>
              </li>
            )}

            <li
              onClick={() => {
                const element = document.getElementById("partner_demo");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <Link>Partner Demo</Link>
            </li>
          </ul>
        </div>
      </section>

      {/* Customer Demo */}
      <section className="customer_section text-center" id="customer_demo">
        <div className="container">
          <h2>Customer Demo Video</h2>
          <p>
            In this section, we show you how easy it is to find the best
            immigration consultants on CHOWNK. From searching the best
            immigration consultants to connecting with them directly through
            chat and video calls
          </p>
          <div className="customer_video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/TtzoahjGKFc?si=fmRO2wTMCTnYtkLA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          {selector && !selector.auth && (
            <button className="btn btn-primary" onClick={() => setShow(!show)}>
              Signup for free
            </button>
          )}
        </div>
      </section>
      {/* Partner Video */}
      <section className="partner_section text-center" id="partner_demo">
        <div className="container">
          <h2>Partner Demo Videos</h2>
          <p>
            In this section, we demonstrate how partners can check their
            analytics, engage with customers through chat and video calls, and
            send their offers to clients. Explore how these features empower
            partners on CHOWNK to enhance their consultancy services.
          </p>
          <div className="row text-start">
            <div className="col-md-6">
              <Accordion defaultActiveKey="0" flush>
                {accordianData.map((el, i) => (
                  <Accordion.Item
                    eventKey={el.id}
                    onClick={() => setVideo(el.video)}
                  >
                    <Accordion.Header>{el.title}</Accordion.Header>
                    <Accordion.Body>{el.desc}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="col-md-6 text-center">
              <iframe
                width="100%"
                height="315"
                src={video}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-md-6">
              <div className="set">
                <h2>
                  {/* <img src="../../assets/images/koma.png" /> */}
                  Effortless Search for Top Immigration Consultants
                </h2>
                <p>
                  Finding the best immigration consultants on CHOWNK is very
                  simple. The demo video clearly shows how easy it is to use the
                  platform and connect with best immigration consultants for my
                  immigration needs.
                </p>
                <div className="user">
                  <div
                    className="img"
                    style={{
                      backgroundImage:
                        "url(../../assets/images/Immigration_Consultant.png)",
                    }}
                  ></div>
                  <p>
                    <strong>Kapil Roy</strong>
                    <br />
                    Successful User
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="set">
                <h2>
                  {/* <img src="../../assets/images/koma.png" /> */}
                  Easy Sign-Up and Lead Generation for Partners
                </h2>
                <p>
                  Signing up on CHOWNK is easy, and generating immigration leads
                  is even easier. The demo video demonstrates how easily I could
                  join Chownk and start receiving quality leads to grow my
                  business.
                </p>
                <div className="user">
                  <div
                    className="img"
                    style={{
                      backgroundImage:
                        "url(../../../assets/images/Immigration_Consultant.png)",
                    }}
                  ></div>
                  <p>
                    <strong>Aniket Chaudhary</strong>
                    <br />
                    Successful Partner
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StudentFooter />
      <LoginModel
        show={show}
        setShow={setShow}
        redirect={window.location.href}
      />
    </>
  );
};

export default Demo;
