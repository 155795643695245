import React, { useRef, useState } from "react";
import SimpleInput from "../../Tools/SimpleInput";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../data/imagefun";

const UploadImageModal = ({
  childStyle,
  submitImage,
  onHide,
  MinHeight,
  MinWidth,
}) => {
  const [image, setImage] = useState(null);
  // const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 50,
    aspect: MinWidth / MinHeight,
  });
  const [zoom, setZoom] = useState(null);
  const [croppedImage, setCroppedImage] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: MinWidth,
    height: MinHeight,
    x: 0,
    y: 0,
  });
  const imageRef = useRef(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      submitImage(croppedImage);
      onHide();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {!image && (
        <div className="main_upload">
          <div className={`upload_img ${childStyle ? childStyle : ""}`}>
            {/* <i className="bi bi-x-lg" onClick={() => onHide()}></i> */}
            <p>{`Recommended Size : ${MinWidth} x ${MinHeight}`}</p>
            <p>{`Supported File Formats : PNG,JPG AND GIF`}</p>
            <SimpleInput
              inType={"file"}
              id={"agent_profile_image"}
              onChange={handleChange}
              accept={"image/*"}
              name={"profile_image"}
            />

            <button
              type="button"
              className="cancel_btn"
              onClick={() => onHide()}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {image && (
        <div className="select_image">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={MinWidth / MinHeight}
            onCropChange={setCrop}
            initialCroppedAreaPercentages={croppedAreaPixels}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          >
            <img src={image} />
          </Cropper>
          <button type="button" className="cancel_btn" onClick={() => onHide()}>
            Cancel
          </button>
          <button onClick={showCroppedImage} type="button">
            Submit
          </button>
        </div>
      )}
    </>
  );
};

export default UploadImageModal;
