import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleInput from "../SimpleInput";
import { on } from "process";

const OnlineFilter = ({ navpath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [onlineStatus, setOnlineStatus] = useState(
    queryParams.get("online") ? true : false
  );

  const handleChange = () => {
    if (onlineStatus) {
      queryParams.delete("online");
    } else {
      queryParams.set("online", !onlineStatus);
    }
    const newSearchString = queryParams.toString();
    navigate({ pathname: navpath, search: newSearchString });
  };

  return (
    <li className="dropdown">
      <button>
        Message
        <div className="form-check form-switch">
          <SimpleInput
            style={"form-check-input"}
            inType={"checkbox"}
            id={"flexSwitchCheckDefault"}
            value={onlineStatus}
            checked={onlineStatus ? true : false}
            onChange={() => {
              setOnlineStatus(!onlineStatus);
              handleChange();
            }}
          />
        </div>
      </button>
    </li>
  );
};

export default OnlineFilter;
