import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SocketContext } from "../../../Context";

const OfferViewOnTheTime = ({ data, right, id }) => {
  const { currentUser, setCurrentChat, socket } = useContext(SocketContext);
  const [expiry, setExpiry] = useState("");
  const navigate = useNavigate();

  const sendMessage = (data) => {
    socket.emit("send_sms", data);
    setCurrentChat((prevData) => [...prevData, data]);
  };

  const send = (status) => {
    let sms = "";
    if (status === "reject") {
      sms = "Your offer is declined";
    }
    var date = Math.round(new Date().getTime() / 1000);
    let dts = {
      first: false,
      sender_id: currentUser.user_id,
      receiver_id: currentUser.connection_user,
      type: "text",
      file_type: "",
      file: "",
      chat_id: id,
      update_status: status,
      sms_status: "sent",
      message: sms,
      unix_time: date,
    };
    // alert(id);
    sendMessage(dts);
  };


  const getExpireDays = useCallback(() => {
    const today = new Date();
    const expiry = new Date(data.expiryDate);
    const diff_in_time = expiry.getTime() - today.getTime();
    const diff_in_days = Math.ceil(diff_in_time / (1000 * 60 * 60 * 24));
    setExpiry(diff_in_days);
  }, [data]);

  useEffect(() => {
    getExpireDays();
    data.offer_status = "pending";
    data.payment_status = "pending";
  }, [getExpireDays]);

  return (
    <div className="txt College_dt">
      <h3>
        {" "}
        <img src="/assets/images/per2.png" alt="img" />{" "}
        {`You have ${right ? "Sent" : "Recieved"} a New
        Offer`}
      </h3>
      <h6>Visa Type</h6>
      <ul>
        <li>{data.visa_type}</li>
      </ul>
      <h6>Offer Details</h6>
      <ul>
        <li>title: {`${data.title}`}</li>
        <li>Description: {data.description}</li>
        <li>Expiry Date: {new Date(data.expiryDate).toLocaleDateString()}</li>
      </ul>

      {/* <p className="Note">
        <b>Note:</b> Course Fees Should be Paid to College's bank account.
      </p> */}

      <h5>Charges:</h5>
      {data.Items &&
        JSON.parse(data.Items).map((el) => (
          <p>{`${el.item_name} : ₹${el.item_price}`}</p>
        ))}
      <p>{data.message}</p>
      {right ? (
        <div className="row mt-2">
          <div className="col-6">
            <h2>₹{data.total}</h2>
          </div>
        </div>
      ) : (
        <div className="row mt-2 footb">
          <div className="col-5">
            <h3>{`₹${data.total}`}</h3>
            <p>Service fees</p>
          </div>
          {data.payment_status && data.payment_status === "accept" && (
            <p className="acpt"> {"This Offer is already accepted"}</p>
          )}
          {(data.payment_status && data.payment_status === "reject") ||
            (data.offer_status && data.offer_status === "reject" && (
              <p className="dicline">
                <i className="bi bi-exclamation-circle-fill"></i>
                {"This Offer is already declined"}
              </p>
            ))}
          {data.payment_status && data.payment_status === "pending" && (
            <>
              <div className="col-7">
                <Link
                  //  target={expiry > 0 && "_blank"}
                  //  to={`/ConfirmOffer/${id}`}
                  onClick={() => {
                    data.payment_status = "accept";
                    data.offer_status = "accept";
                    setTimeout(() => {
                      window.open(`${window.location.protocol}//${window.location.hostname}${`/ConfirmOffer/${id}`}`, '_blank', 'noopener, noreferrer');
                    }, 1000);
                  }}
                  className="btn acept"
                >
                  {"Accept"}
                </Link>
                <button
                  type="button"
                  onClick={() => {
                    send("reject");
                    data.payment_status = "reject";
                    data.offer_status = "reject";
                  }}
                  className="btn"
                >
                  {"Decline"}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <p className="text-end mt-2">
        <i>{`*Offer will expire in ${expiry === 1 ? "day" : "days"}`}</i>
      </p>
    </div>
  );
};
export default OfferViewOnTheTime;
