import React, { useEffect, useState } from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import HelpHead from "../Help/helpHead";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { helpFun, helpsByCat } from "../../../../Reducer/helpSlice";
import { object } from "yup";
import Meta from "../../Common/Meta";
import moment from "moment";
import { saveUserNavigate } from "../../../../services/api";

const HelpCategory = () => {
  const { cat, user } = useParams();
  const [searchData, setSearchData] = React.useState("");
  const [otherCat, setOtherCat] = useState("");
  const selector = useSelector((state) => state.auth);
  const { helps_cat } = useSelector((state) => state.help);
  const guestId = localStorage.getItem("guestId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const body = { name: cat };
    dispatch(helpsByCat(body));
  }, [cat]);

  const searchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      helpFun({
        type: user === "partners" ? "business" : "user",
        search: searchData,
      })
    );
    navigate(`${user === "partners" ? "/partners/help" : "/customers/help"}`, {
      state: searchData,
    });
    handleUserNavigate("action", searchData, "Search Help");
  };

  useEffect(() => {
    if (helps_cat && helps_cat.categories) {
      if (user === "customers") {
        const cat = helps_cat.categories.filter((item) => item.type === "user");
        setOtherCat(cat);
      } else {
        const cat = helps_cat.categories.filter(
          (item) => item.type === "business"
        );
        setOtherCat(cat);
      }
    }
  }, [helps_cat]);

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: selector.auth ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Meta
        title={`${cat && cat.replaceAll("-", " ")} - Chownk / ${user} Help`}
        description={` Complete guides and support to ${
          cat && cat.replaceAll("-", " ")
        } on Chownk for ${user}. For more information, contact us now. Our team is ready to assist you`}
        includeFaq={true}
        faqs={helps_cat.questions ? JSON.stringify(helps_cat.questions) : ""}
        helpFaqSchema={
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "FAQPage",
              mainEntity:
                helps_cat &&
                helps_cat.questions &&
                helps_cat.questions.map((el, i) => ({
                  "@type": "Question",
                  name: el.question,
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: el.answers,
                  },
                })),
            })}
          </script>
        }
      />
      <StudentHeader />
      <HelpHead
        searchData={searchData}
        searchFunction={(e) =>
          dispatch(
            helpFun({
              type: user === "partners" ? "business" : "user",
              search: e.target.value,
            })
          )
        }
        setSearchData={setSearchData}
        searchSubmit={searchSubmit}
        user={user}
      />
      <section class="help_page_single help_pageall">
        <div class="container">
          <nav aria-label="Breadcrumb" className="Breadcrumb">
            <ul>
              <li>
                <Link to={`/${user}/help`}>{"Help & Support /"}</Link>
              </li>
              <li>
                <Link to={`/${user}/help`}>
                  {user === "partners" ? "Partner /" : "Customer /"}
                </Link>
              </li>
              <li>
                <Link>{cat && cat.replaceAll("-", " ")}</Link>
              </li>
            </ul>
          </nav>

          <div class="row">
            <div class="col-lg-8  pe-3 pe-lg-5">
              <h2>
                <span>
                  <img
                    src={
                      location.state
                        ? location.state
                        : "../../../../assets/images/helpiconK_1.png"
                    }
                    alt="getting"
                  />
                </span>{" "}
                {cat && cat.replaceAll("-", " ")}
              </h2>

              {helps_cat &&
                helps_cat.questions &&
                helps_cat.questions.map((item, i) => (
                  <div class="st">
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(
                          `/${user}/help/${cat}/${item.question_slug.toLowerCase()}`
                        );
                        handleUserNavigate(
                          "navigate",
                          `/${user}/help/${cat}/${item.question_slug}`,
                          "Single Faq"
                        );
                      }}
                    >
                      {item.question}
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: item.answers }}></p>
                    <Link
                      to={`/${user}/help/${cat}/${item.question_slug}`}
                      onClick={() => {
                        handleUserNavigate(
                          "navigate",
                          `/${user}/help/${cat}/${item.question_slug.toLowerCase()}`,
                          "Single Faq"
                        );
                      }}
                    >
                      Read more
                    </Link>
                  </div>
                ))}
            </div>
            <div class="col-lg-4">
              <h2>Browse other FAQs</h2>
              <ul class="Related">
                {otherCat &&
                  otherCat.length > 0 &&
                  otherCat.map((item, i) => (
                    <li>
                      <Link
                        to={`/${user}/help/${item.slug.toLowerCase()}`}
                        onClick={() =>
                          handleUserNavigate(
                            "navigate",
                            `/${user}/help/${item.slug}`,
                            "Other Categories"
                          )
                        }
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
              </ul>

              {helps_cat && helps_cat.recent && helps_cat.recent.length > 0 && (
                <>
                  <h3 class="mt-4">Recently Viewed FAQ</h3>
                  <ul class="Related">
                    <li>
                      <Link>Accept an Offer</Link>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpCategory;
