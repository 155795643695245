import {
  EducationLevelCompleted,
  countryInterestedOption,
  standardizedTest,
} from "../../../data/data";
import Button from "../../Tools/Button";
import FormGroupRadio from "../../Tools/FormGroupRadio";
import FormSelectOption from "../../Tools/FormSelectOption";
import FormInputField from "../../Tools/Forminput";
import SimpleInput from "../../Tools/SimpleInput";

const AnswerTheQuestionForm = ({ label }) => {
  return (
    <div className="container mt-4">
      <form>
        <div className="row">
          <div className="col-sm-12">
            <FormGroupRadio
              parentStyle={"form-group"}
              childStyle={"form-check-input"}
              label={"What is your highest level of education completed ?"}
              radioOptions={EducationLevelCompleted}
            />
          </div>
          <div className="col-md-6">
            <FormInputField
              parentStyle={"form-group"}
              childStyle={"form-control"}
              label={"What is your highest level of education completed ?"}
              inType={"text"}
              placeholder={"enter your current GPA"}
            />
          </div>
          <div className="col-md-6">
            <FormSelectOption
              parentStyle={"form-group"}
              childStyle={"form-control"}
              label={"Which countries are you interested in studying in ?"}
              options={countryInterestedOption}
            />
          </div>
          <div className="col-md-6">
            <FormGroupRadio
              parentStyle={"form-group"}
              childStyle={"form-check-input"}
              label={"Have you taken any standardized test ?"}
              radioOptions={standardizedTest}
            />
          </div>
          <div className="col-md-6">
            <div className="form-group enteryour">
              <label>{label}</label>
              <div className="row text-center">
                <div className="col">
                  <SimpleInput style={"form-control"} placeholder={"Overall"} />
                </div>
                <div className="col">
                  <SimpleInput
                    style={"form-control"}
                    placeholder={"Listening"}
                  />
                </div>
                <div className="col">
                  <SimpleInput style={"form-control"} placeholder={"Reading"} />
                </div>
                <div className="col">
                  <SimpleInput style={"form-control"} placeholder={"Writing"} />
                </div>
                <div className="col">
                  <SimpleInput
                    style={"form-control"}
                    placeholder={"Speaking"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 text-end mt-5">
            <Button inType={"button"} title={"Submit"} />
          </div>
        </div>
      </form>
    </div>
  );
};
export default AnswerTheQuestionForm;
