const FormGroupRadio = ({ parentStyle, childStyle, label, radioOptions }) => {
  return (
    <div className={parentStyle}>
      <label className="d-block mb-2">{label}</label>
      {radioOptions.length > 0 &&
        radioOptions.map((item) => {
          return (
            <span>
              <input className={childStyle} type="radio" />
              {item.title}
            </span>
          );
        })}
    </div>
  );
};

export default FormGroupRadio;
