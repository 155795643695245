import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SocketContext } from "../../../../Context";
import { mediaUrl } from "../../../../services/network";
import MobileSideBar from "../../../Layouts/Student/MobileSideBar";

const NewLocation = ({ show, setShow }) => {
  const [sidemenu, setSidemenu] = useState(false);
  const [defaultOption, setDefaultOption] = useState([]);
  const [additionalOption, setAdditionalOption] = useState([]);
  const normalSelector = useSelector((state) => state.auth);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const dt = localStorage.getItem("dt");
  const { owner_name } = useSelector((state) => state.auth?.auth);
  const { addFundsModal, setAddFundsModal, logout, setAllChats } =
    useContext(SocketContext);

  return (
    <div className="mob_location">
      {" "}
      <div className="container">
        <h3>
          {`Welcome , ${normalSelector.auth && normalSelector.auth.name}`}
          {!dt && (
            <Link
              className="Profile_bt"
              onClick={() => {
                setSidemenu(!sidemenu);
              }}
            >
              <img src="../../../assets/images/mobfoot4.svg" alt="home" />
            </Link>
          )}

          <Link className="p-0 float-end" to={"/notification"}>
            <img src={`../../../assets/images/${"notiblue.svg"}`} alt="logo" />
            {normalSelector && normalSelector?.notiCount > 0 && <span></span>}
          </Link>
        </h3>

        <h1>
          <span className="find">Find</span>
          <div id="resizing-h3" className="mb-0 ms-2">
            <span>
              <div className="stage">
                <div className="cubespinner">
                  <div className="face1">
                    <img src="assets/images/per2.png" alt="img" /> Verified
                  </div>
                  <div className="face2">
                    <img src="assets/images/Top_Rated.png" alt="img" /> Top
                    Rated
                  </div>
                  <div className="face3">
                    <img src="assets/images/Nearby.png" alt="img" /> Nearby
                  </div>
                  <div className="face4">
                    <img src="assets/images/populer.png" alt="img" /> Popular
                  </div>
                </div>
              </div>
            </span>
          </div>
          <span>
            Agents/English Institutes in <br />
            60 Seconds
          </span>
        </h1>
      </div>
      <MobileSideBar sidemenu={sidemenu} setSidemenu={setSidemenu} />
    </div>
  );
};

export default NewLocation;
