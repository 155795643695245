import { useContext, useEffect, useState } from "react";
import Chatting from "../../Pages/Chat/Chat/Chatting";
import UserHeader from "../../Pages/Chat/Chat/UserHeader";
import { SocketContext } from "../../../Context";
import $ from "jquery";
import { Tooltip } from "react-tooltip";

const SmsBox = () => {
  const dt = localStorage.getItem("dt");
  const { allChats, setAllChats, chatLimit, access, callAccepted } =
    useContext(SocketContext);
  const [page, setPage] = useState(1);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    setPage(1);
    if (allChats.length >= chatLimit) {
      let chat = allChats.slice(0, chatLimit);
      setChats(
        callAccepted
          ? allChats.slice(0, chatLimit - 1)
          : allChats.slice(0, chatLimit)
      );
    } else {
      setChats(callAccepted ? allChats.slice(0, chatLimit - 1) : allChats);
    }
  }, [allChats, callAccepted]);

  return (
    <div
      className={`chtmodall ${dt ? "" : `chat_browser ${access && "onCall"}`}`}
    >
      {chats.map((el, i) => (
        <div
          className="smsBox ready_msg ms-2 position-relative"
          key={el.connection_user}
          id={`xr-${el.connection_user}`}
        >
          <ul>
            <li>
              <span
                data-tooltip-id="max"
                className="bi bi-arrows-angle-expand d-none"
                id={`expand-${el.connection_user}`}
                onClick={() => {
                  $(`#xr-${el.connection_user}`)
                    .removeClass("mini")
                    .addClass("ready_msg");
                  $(`#expand-${el.connection_user}`).addClass("d-none");
                  $(`#dash-${el.connection_user}`).removeClass("d-none");
                }}
              ></span>
              <span
                data-tooltip-id="minimize"
                className="bi bi-arrows-angle-contract"
                id={`dash-${el.connection_user}`}
                onClick={() => {
                  $(`#xr-${el.connection_user}`)
                    .addClass("mini")
                    .removeClass("ready_msg");
                  $(`#dash-${el.connection_user}`).addClass("d-none");
                  $(`#expand-${el.connection_user}`).removeClass("d-none");
                }}
              ></span>
            </li>
            <li>
              <i
                data-tooltip-id="close"
                className="bi bi-x"
                onClick={() => {
                  const filterData = allChats.filter(
                    (item) => item.connection_user !== el.connection_user
                  );
                  localStorage.setItem("chats", JSON.stringify(filterData));
                  setAllChats(filterData);
                }}
              ></i>
            </li>
          </ul>
          <UserHeader
            currentUser={el}
            ulStyle={"call_video"}
            shortName={true}
            videoIcon={true}
          />
          <Chatting currentUser={el} page={page} setPage={setPage} />
        </div>
      ))}
      <Tooltip id="close" content="Close" style={{ zIndex: 999 }} />
      <Tooltip id="minimize" content="Minimize" style={{ zIndex: 999 }} />
      <Tooltip id="max" content="Maximize" style={{ zIndex: 999 }} />
    </div>
  );
};
export default SmsBox;
