const SimpleTextArea = ({
  style,
  placeholder,
  event,
  name,
  errors,
  onSelect,
  ref,
  onChange,
}) => {
  return (
    <>
      <textarea
        className={style}
        ref={ref}
        {...(event && event(name))}
        placeholder={placeholder}
        name={name}
        onSelect={onSelect}
        onChange={onChange}
      ></textarea>
      {errors && errors[name] && (
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )}
    </>
  );
};
export default SimpleTextArea;
