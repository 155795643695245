import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerAvatarApi, getAvatarApi } from "../../../../services/api";
import FormInputField from "../../../Tools/Forminput";
import Button from "../../../Tools/Button";
import { toast, ToastContainer } from "react-toastify";
import { mediaUrl } from "../../../../services/network";
import { getBalance, setAction, setUser } from "../../../../Reducer/authSlice";
import { useNavigate } from "react-router-dom";

const RegisterAvatarModel = ({ onHide }) => {
  const regex = /[0-9]/;
  const [avatarData, setAvatarData] = React.useState([]);
  const [activeImage, setActiveImage] = React.useState("");
  const [error, setError] = React.useState({});
  const [name, setName] = React.useState("");
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.auth);
  const navigate = useNavigate();
  React.useEffect(() => {
    getAvatarFun(selector?.token);
  }, []);
  const getAvatarFun = async (token) => {
    try {
      const response = await getAvatarApi(token);
      if (response.data.status == "success") {
        setAvatarData(response.data.data);
        setActiveImage(response.data.data[0].image);
      }
    } catch (e) {}
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (activeImage == "") {
      setError({
        image: {
          messsage: "Please Select a Image",
        },
      });
      return;
    }
    if (!name || name.trim() == "") {
      setError({
        name: {
          message: "Please Enter Your Name",
        },
      });
    } else if (regex.test(name)) {
      setError({
        name: {
          message: "Name Should Be In Characters Only",
        },
      });
    } else {
      const body = {
        name: name,
        profile_image: activeImage,
      };
      try {
        const response = await registerAvatarApi(body, selector?.token);

        if (response.data.status == "success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          dispatch(setUser(response.data.detail));
          localStorage.setItem("user", JSON.stringify(response.data.detail));
          // navigate("/");
          dispatch(getBalance());
          dispatch(setAction(""));
          onHide();
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="lest_login avtar_div">
            {avatarData && avatarData.length > 0 && (
              <form onSubmit={onSubmit}>
                <h3 className="mt-0">{"Complete your profile"}</h3>
                <h5 className="mt-2">{"Select Your Avatar"}</h5>
                <div className="row">
                  {avatarData.map((item) => {
                    return (
                      <div className="col-sm-4 col-lg-3">
                        <div
                          className={`img ${
                            activeImage == item.image && "active"
                          }`}
                          onClick={() => setActiveImage(item.image)}
                          style={{
                            backgroundImage: `url(${mediaUrl + item.image})`,
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
                {error && error.image && (
                  <p style={{ color: "red" }}>{error.image.message}</p>
                )}
                <FormInputField
                  parentStyle={"form-group"}
                  childStyle={"form-control"}
                  inType={"text"}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (name) {
                      setError({});
                    } else if (!regex.test(name)) {
                      setError({});
                    }
                  }}
                  name={"name"}
                  errors={error}
                  placeholder={"Enter Your Name"}
                />
                <div className="form-group text-end">
                  <Button
                    inType={"submit"}
                    style={"btn"}
                    title={"Finish Your Profile"}
                    disabled={name.trim() == "" && true}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
export default RegisterAvatarModel;
