import AnswerTheQuestionForm from "../../../Layouts/Form/answerTheQuestionForm";
import HeaderTitle from "../../../Tools/HeaderTitle";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";

const BeforeCallQuestion = () => {
  return (
    <>
      <StudentHeader
        style={"header_white"}
        logo={"assets/images/logo2.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />

      <section className="application answer_the_questions">
        <HeaderTitle
          parentStyle={"hed"}
          childStyle={"container"}
          image={"assets/images/before_questions.png"}
          title={"Answer the Questions"}
        />
        <AnswerTheQuestionForm label={"Enter your score"} />
      </section>
      <StudentFooter />
    </>
  );
};

export default BeforeCallQuestion;
