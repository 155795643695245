
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AppNavigate = () => {
  const location =useLocation();

  useEffect(()=>{
    let url = 'chownk://';
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("data");
    if(page){
      url = url+page;
    }
    window.location.href = url;
  },[]);

  return (
    <></>
  );
};
export default AppNavigate;
