import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDatachecklist } from "../../../../Reducer/applicationSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Savecheclistdata } from "../../../../services/api";
import RecentactivityApp from "./Recentactivity";

const ChecklistApp = () => {
  const dispatch = useDispatch();
  const [chklist, chklistarr] = useState([]);
  const [condition, setcondition] = useState("");
  let list = useSelector((state) => state.application?.checklist);
  let list1 = useSelector((state) => state.application?.single);
  const { id } = useParams();
  console.log("listdata", list1);
  useEffect(() => {
    dispatch(fetchDatachecklist());
    functionc();
  }, [list1]);
  const [userinfo, setUserInfo] = useState({
    checklist: [],
    response: [],
  });
  const functionc = async () => {
    if (list1 && list1.checklists && list1.checklists) {
      let array3 = [];
      let arr1 = JSON.parse(list1.checklists);

      console.log("arr1", arr1);

      chklistarr(array3);
    }
  };
  const handleChange = async (e) => {
    const { value, checked } = e.target;
    const { checklist } = userinfo;

    if (checked) {
      setUserInfo({
        checklist: [...checklist, value],
        response: [...checklist, value],
      });
    } else {
      setUserInfo({
        checklist: checklist.filter((e) => e !== value),
        response: checklist.filter((e) => e !== value),
      });
    }

    console.log("thisdata", userinfo);
    let checkdata = JSON.stringify(userinfo.checklist);
    console.log("thisdata1", checkdata);

    let formdata = new FormData();
    formdata.append("appid", id);

    formdata.append("checklist", checkdata);

    // console.log("thisss", userinfo.checklist.length)
    const savechecklist = await Savecheclistdata(formdata);
    dispatch(fetchDatachecklist());
  };
  console.log("userinfo", userinfo.checklist);
  return (
    <div
      className="tab-pane fade"
      id="Checklist"
      role="tabpanel"
      aria-labelledby="Checklist-tab"
    >
      <div className="row ">
        <div className="col-lg-8">
          <div className="Checklist">
            {list &&
              list.map((items, i) => {
                return (
                  <div className="st">
                    <input
                      type="checkbox"
                      value={items.id}
                      name="languages"
                      onChange={handleChange}
                    />
                    <img
                      src="../../../assets/images/checklist.png"
                      alt="checklist"
                    />
                    <h6>
                      {items.title} <span className="float-end">12:00 PM</span>
                    </h6>
                    <p>{items.description}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-lg-4 mt-3 mt-lg-0">
          <RecentactivityApp />
        </div>
      </div>
    </div>
  );
};

export default ChecklistApp;
