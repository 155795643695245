import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const About = ({
  overview,
  loading,
  companyOwner,
  selector,
  updateProfileFun,
}) => {
  const [readMore, setReadMore] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newOverview, setNewOverview] = useState(overview ? overview : "");
  const [error, setError] = useState("");

  useEffect(() => {
    setNewOverview(overview ? overview : "");
  }, [overview]);

  const handleOverview = () => {
    let formData = new FormData();
    if (!error) {
      formData.append("overview_description", newOverview);
      updateProfileFun(formData);
      setEditing(!editing);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length <= 1000) {
      setNewOverview(e.target.value);
      setError("");
    } else {
      setError("About can only have 1000 characters");
    }
  };
  return (
    <>
      {((overview && overview !== "") || companyOwner === selector?.uid) &&
        !loading && (
          <div className="set">
            <h2>
              About Us{" "}
              {companyOwner === selector?.uid && !editing && (
                <Link
                  className="float-end"
                  onClick={() => setEditing(!editing)}
                >
                  <i className="bi bi-pencil-square"></i>
                </Link>
              )}
              {editing && (
                <Link className="float-end">
                  <i
                    class="bi bi-check-circle-fill"
                    onClick={handleOverview}
                  ></i>
                  <i
                    class="bi bi-x-circle-fill"
                    onClick={() => setEditing(!editing)}
                  ></i>
                </Link>
              )}
            </h2>
            {loading ? (
              <p>
                <Skeleton count={5} />
              </p>
            ) : overview && overview.length > 500 && !editing ? (
              <p>
                {readMore ? newOverview : newOverview.substring(0, 500)}
                <Link onClick={() => setReadMore(!readMore)} className="read">
                  {readMore ? "Read Less" : "Read More"}
                </Link>
              </p>
            ) : (
              !editing && <p>{newOverview}</p>
            )}
            {editing && (
              <>
                <textarea
                  style={{ width: "100%" }}
                  value={newOverview}
                  onChange={handleChange}
                ></textarea>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </>
            )}
          </div>
        )}
    </>
  );
};

export default About;
