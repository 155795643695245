import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAnalyticDetail, getAnalyticsFollowers, getAnalyticsCompitator } from "../services/api";


export const analyticDetail = createAsyncThunk(
  "getAnalyticDetail",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await getAnalyticDetail(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const analyticsFollowers = createAsyncThunk(
  "getAnalyticsFollowers",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await getAnalyticsFollowers(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const analyticsCompitator = createAsyncThunk(
  "getAnalyticsCompitator",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAnalyticsCompitator();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);



const analyticSlice = createSlice({
  name: "analytic",
  initialState: {
    success: false,
    submitting: false,
    error: null,
    analyticDetails: null,
    analyticFollow:null,
    compitator:null
  },
  extraReducers: (builder) => {
    builder
      .addCase(analyticDetail.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(analyticDetail.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.analyticDetails = action.payload.data;
      })
      .addCase(analyticDetail.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(analyticsFollowers.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(analyticsFollowers.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.analyticFollow = action.payload.data;
      })
      .addCase(analyticsFollowers.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(analyticsCompitator.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(analyticsCompitator.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.compitator = action.payload.data;
      })
      .addCase(analyticsCompitator.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
  },
});

export default analyticSlice.reducer;
