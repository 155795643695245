import { mediaUrl } from "../../../services/network";

const AnalyticsTableBox = ({ title, description, imageSrc, columns, rows }) => {
  return (
    <>
      {" "}
      <div className="wap page_views mb-3">
        <h4>
          <img src={imageSrc} alt="Call_History" /> {title}
        </h4>
        <p>{description}</p>
        <div className="table-responsive text-center">
          <table className="table">
            <thead>
              <tr>
                {columns.length > 0 &&
                  columns.map((item) => {
                    return <th scope={item.col}>{item.label}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 &&
                rows.map((item, i) => {
                  return (
                    <tr key={i}>
                      {columns.map((columnItem) => {
                        return (
                          <td>
                            {columnItem.background ? (
                              <>
                                <div
                                  className="img"
                                  style={{
                                    backgroundImage: `url(${
                                      mediaUrl + item[columnItem.image_name]
                                    })`,
                                  }}
                                ></div>
                                {item[columnItem.name]}
                              </>
                            ) : (
                              item[columnItem.name]
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="text-center">
          {/* <a className="See" href="#">
            See all
          </a> */}
        </div>
      </div>
    </>
  );
};
export default AnalyticsTableBox;
