const ProfileButton = ({
  parentStyle,
  iconClassName,
  label,
  inType,
  onClick,
  buttonOnClick,
  image,
}) => {
  return (
    <div className={parentStyle} onClick={onClick}>
      <button type={`${inType ? inType : "button"}`} onClick={buttonOnClick}>
        {iconClassName && <i className={iconClassName}></i>}
        {image && <img src={image} alt="user" />}
        {label}
      </button>
    </div>
  );
};
export default ProfileButton;
