  import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Example from "../../../Tools/ReactBoostrap";
import AddFundsModal from "../../../Layouts/Model/addFunds";
import { SocketContext } from "../../../../Context";
import { getBalance } from "../../../../Reducer/authSlice";
import { useNavigate } from "react-router-dom";
import RegisterAvatarModel from "../RegisterAvatarModel";
import DeleteAccountModal from "../../../Layouts/Model/deleteAccountModal";
import DeclineSms from "../../../Tools/DeclineSms";
import Calling from "../../Chat/Player";
import NotificationBox from "../../../Tools/NotificationBox";
import Notifications from "../../Chat/Player/Notifications";
const MobileHeader = () => {
  const { addFundsModal, setAddFundsModal, deleteModal, setDeleteModal, callAccepted, access } = useContext(SocketContext);
  const [registerModal, setRegisterModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const normalSelector = useSelector((state) => state.auth);
  const redirect = window.location.href;

  const modelViewControll = () => {
    if (
      (normalSelector.auth?.role == 1 || normalSelector.auth?.role == 7) &&
      normalSelector.auth?.status === "uncompleted"
    ) {
      setRegisterModal(!registerModal);
    }
    if (normalSelector.success && normalSelector.actionType == "header") {
    } else if (
      normalSelector.otpSuccess &&
      normalSelector.actionType == "header"
    ) {
      switch (normalSelector.redireaction) {
        case "/home":
          // window.location.href =
          //   normalSelector.auth?.role === 5 ? "/partner-dashboard" : redirect;
          navigate(
            normalSelector.auth?.role === 5
              ? "/partner-dashboard"
              : normalSelector.auth?.role === 6
              ? "/staff-dashboard"
              : "/"
          );

          break;
        case "/":
          // window.location.href =
          //   normalSelector.auth?.role === 5 ? "/partner-dashboard" : redirect;
          navigate(
            normalSelector.auth?.role === 5 ? "/partner-dashboard" : "/"
          );
          break;
        case "/profile_setup":
          if (normalSelector.auth?.role !== 5) {
          } else {
            navigate("/");
            // window.location.href = redirect;
          }
          break;
        case "/login":
          toast.success(normalSelector.error, {
            position: toast.POSITION.TOP_LEFT,
          });
          break;
      }
    }
  };

  useEffect(() => {
    modelViewControll();
    if (normalSelector.auth?.role == 5) {
      navigate("/partner-dashboard");
    }
  }, []);

  useEffect(() => {
    dispatch(getBalance());
  }, []);
  return (
    <>
      {!callAccepted && !access && <DeclineSms />}
      {access && window.innerWidth <= 767 && (
        <div className="call_maini call_2">
          <Calling />
        </div>
      )}
      <section
        className="mob_hed"
        style={{ backgroundImage: "url(../assets/images/mobhed_bg.png)" }}
      >
        <div className="container">
          <h6>
            <img src="../assets/images/per_s.png" alt="per_s" /> Simplifying
            Immigration Journeys — Connecting Users with Vetted Consultants.
          </h6>
        </div>
      </section>
      <Example
        show={addFundsModal}
        onHide={() => setAddFundsModal(!addFundsModal)}
        component={
          <AddFundsModal onHide={() => setAddFundsModal(!addFundsModal)} />
        }
        style={"loginmodal add_money_wallet"}
      />
      <Example
        show={registerModal}
        onHide={() => setRegisterModal(!registerModal)}
        component={
          <RegisterAvatarModel
            onHide={() => setRegisterModal(!registerModal)}
          />
        }
        style={"loginmodal avatar_popup"}
        backDrop={"static"}
      />
      <Example
        show={deleteModal}
        onHide={() => setDeleteModal(!deleteModal)}
        component={
          <DeleteAccountModal onHide={() => setDeleteModal(!deleteModal)} />
        }
        style={"loginmodal otplogin"}
      />
    </>
  );
};
export default MobileHeader;
