import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const OTPTimer = ({ duration, onTimeout, onResend }) => {
  const [seconds, setSeconds] = useState(duration);

  const style = {
    resendButtonStyle: {
      textDecoration: "none",
      color: 'rgb(124, 124, 124, 1`)',
      cursor: "pointer",
    },
    disbabled: {
      textDecoration: "none",
      color: "#E3DAD8",
      pointerEvents: "none",
    },
  };

  useEffect(() => {
    const countdown = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        onTimeout();
      }
    };
    const timer = setInterval(countdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [seconds, onTimeout]);

  const handleResend = () => {
    setSeconds(duration);
    onResend(); 
  };

  return (
    <div>
      <>
        <p className="resend">
          {"Didn't received OTP?"}<br />
          <Link
            className={seconds === 0 && "sndsrc"}
            style={seconds === 0 ? style.resendButtonStyle : style.disbabled}
            onClick={seconds === 0 && handleResend}
          >
            {"Resend OTP"}
          </Link>{" "}
          <span> {"in"} {seconds} {"sec"}</span>
        </p>
      </>
    </div>
  );
};

export default OTPTimer;
