import React, { useContext, useRef, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TotalResult from "./totalResult";
import LoginModel from "../../../Tools/LoginModel";
import { SocketContext } from "../../../../Context";
import { Spinner } from "react-bootstrap";
import { saveCallDetail, saveUserNavigate } from "../../../../services/api";
import MainLoader from "../../../Tools/MainLoader";
import Skeleton from "react-loading-skeleton";
import SearchLoader from "../../../Tools/SearchLoader";
import moment from "moment";

const Example = lazy(() => import("../../../Tools/ReactBoostrap"));
const FindUser = lazy(() => import("../../../Tools/FindUser"));
const ConsultantVideoCallBox = lazy(() =>
  import("../../../Tools/ConsultantVideoCallBox")
);

const FilterData = ({
  filterDataConsultant,
  perPage,
  city,
  categories,
  totalRows,
  currentPage,
  setCurrentPage,
  sp,
}) => {
  const selector = useSelector((state) => state.auth);
  const { filterError } = useSelector((state) => state.afterSearch);
  const [custom, setCustom] = useState({});
  const {
    sendMessage,
    setCurrentUser,
    userModel, 
    setUserModel,
    finUser,
    findChatFun,
    addFundsModal,
    setAddFundsModal,
  } = useContext(SocketContext);
  const navigate = useNavigate();
  const itemsPerPage = perPage ? perPage : 5;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const guestId = localStorage.getItem("guestId");

  const totalPages = Math.ceil(totalRows && totalRows / itemsPerPage);

  const scrollRef = useRef();

  const [show, setShow] = React.useState(false);

  const handleChange = (
    type,
    user_id,
    connection_id,
    title,
    company_logo,
    online,
    owner_name,
    uid,
    status,
    city,
    slug
  ) => {
    if (selector && selector.auth === null) {
      setShow(!show);
    } else {
      readyForAction(
        type,
        user_id,
        company_logo,
        connection_id,
        title,
        online,
        owner_name,
        uid,
        status,
        city,
        slug
      );
    }
  };

  const readyForAction = (
    type,
    user_id,
    company_logo,
    connection_id,
    title,
    online,
    owner_name,
    uid,
    status,
    city,
    slug
  ) => {
    if (type == "video") {
      handleUserNavigate("call", title, `Call to ${title}`);
      if (online !== true || online !== "true") {
        saveCall(uid);
      }
      if (selector.funds >= selector.call_min) {
        let data = {
          connection_user: user_id,
          uid: user_id,
          name: title,
          company_logo: company_logo,
          company_name: title,
          online: online,
        };
        finUser(user_id);
        setCustom(data);
        setCurrentUser(data);
        setUserModel(true);
      } else {
        setAddFundsModal(!addFundsModal);
      }
    } else {
      handleUserNavigate("message", title, `Message to ${title}`);
      let data = {
        receiver_id: uid,
        image: company_logo,
        company_name: title,
        online: online === true ? true : false,
        status: status,
        owner_name: owner_name,
        slug: slug,
        city: city,
        userRole: "branch",
      };
      findChatFun(data);
    }
  };

  const saveCall = async (id) => {
    try {
      const body = { to: id };
      await saveCallDetail(body);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: selector.auth ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section className="total_results" ref={scrollRef}>
      <div className="container">
        <div className="row">
          {filterDataConsultant && filterDataConsultant.length > 0 ? (
            filterDataConsultant.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <ConsultantVideoCallBox
                    title={item.company_name}
                    uid={item.uid}
                    shortDescription={item.shortDescription}
                    status={item.status}
                    location={item.permanent_address}
                    branches={item.branches}
                    exam_offers={item.exam_offers}
                    specialization={
                      item.specializations
                        ? item.specializations.split("----")
                        : []
                    }
                    owner_name={item.owner_name}
                    id={item.id}
                    connection_id={item.connection_id}
                    videoCallPrize={0}
                    image={item.company_logo}
                    handleChange={handleChange}
                    slug={item.slug}
                    online={item.online ? true : false}
                    successStories={
                      item.stories ? JSON.parse(item.stories) : []
                    }
                    like={item.isFollowing}
                    checkUserLogin={selector && selector.auth}
                    verified={
                      item.pan_status === "Approved" &&
                      item.authorize_certificate_status === "Approved" &&
                      item.gst_status === "Approved" &&
                      item.status === "active" &&
                      true
                    }
                    averageRatting={
                      item.averageRating && item.averageRating.toFixed(1)
                    }
                    city={item.city}
                    joinedDate={`${new Date(item.created_at).toLocaleString(
                      "default",
                      { month: "short" }
                    )} ${new Date(item.created_at).getFullYear()}`}
                    video_status={item.video_status}
                    navState={sp && sp.length > 0 && item.boost == 1 ? 4 : 1}
                    handleUserNavigate={handleUserNavigate}
                  />
                </React.Fragment>
              );
            })
          ) : filterError ? (
            <div className="col-12 no_data text-center">
              <img src="../../../assets/images/no_data.jpg" />
              <h6>No Data Available</h6>
            </div>
          ) : (
            <SearchLoader count={6} />
          )}
        </div>
        {filterDataConsultant.length > 0 && (
          <div className="text-end mt-4">
            <nav
              aria-label="Page navigation example"
              className="d-inline-block"
            >
              <ul className="pagination">
                <li
                  className="page-item"
                  onClick={() => {
                    setCurrentPage(currentPage == 1 ? 1 : currentPage - 1);
                    queryParams.delete("page");
                    queryParams.set(
                      "page",
                      currentPage == 1 ? 1 : currentPage - 1
                    );
                    const newSearchString = queryParams.toString();
                    navigate({
                      pathname: location.pathname,
                      search: newSearchString,
                    });
                  }}
                >
                  <Link
                    className={`page-link ${currentPage !== 1 && "active"}`}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </Link>
                </li>
                {Array.from({ length: Math.ceil(totalPages) }, (_, i) => {
                  return (
                    <li
                      key={i}
                      className={`page-item`}
                      onClick={() => {
                        setCurrentPage(i + 1);
                        queryParams.delete("page");
                        queryParams.set("page", i + 1);
                        const newSearchString = queryParams.toString();
                        console.log("newSearchString", newSearchString);
                        navigate({
                          pathname: location.pathname,
                          search: newSearchString,
                        });
                      }}
                    >
                      <Link
                        className={`page-link ${
                          currentPage == i + 1 && "active"
                        }`}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  );
                })}
                <li
                  className="page-item"
                  onClick={() => {
                    queryParams.delete("page");
                    queryParams.set(
                      "page",
                      currentPage == Math.ceil(totalPages)
                        ? currentPage
                        : Number(currentPage) + 1
                    );
                    const newSearchString = queryParams.toString();

                    navigate({
                      pathname: location.pathname,
                      search: newSearchString,
                    });

                    setCurrentPage(
                      currentPage == totalPages
                        ? currentPage
                        : Number(currentPage) + 1
                    );
                  }}
                >
                  <Link
                    className={`page-link ${
                      currentPage !== Math.ceil(totalPages) && "active"
                    }`}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
      <LoginModel
        show={show}
        setShow={setShow}
        redirect={window.location.href}
      />
      <Example
        show={userModel}
        style={"connect ofline_div"}
        onHide={() => setUserModel(!userModel)}
        component={
          <FindUser
            show={userModel}
            data={custom}
            onHide={() => setUserModel(!userModel)}
          />
        }
        backDrop={"static"}
      />
    </section>
  );
};

export default FilterData;
