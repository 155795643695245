import { useEffect, useState } from "react";
import { mediaUrl } from "../../../../services/network";
import Table from "../../../Tools/Table";

const StaffMemberTable = ({ columns, data }) => {
  const [onlineStaff, setOnlineStaff] = useState([]);
  const [offlineStaff, setOfflineStaff] = useState([]);
  const homeStaffMemberDataa =
    data.length > 0 &&
    data.map((item, i) => {
      return {
        ...item,
        image: (
          <div
            className="img"
            style={{ backgroundImage: `url(${mediaUrl}${item.pImage})` }}
          ></div>
        ),
      };
    });

  useEffect(() => {
    let online = [];
    let offline = [];
    data.forEach((item) => {
      if (item.online == true || item.online == "true") {
        online.push(item);
      } else {
        offline.push(item);
      }
    });

    let online_data =
      online.length > 0 &&
      online.map((item, index) => {
        return {
          ...item,
          key: index,
          image: (
            <div
              className="img"
              style={{ backgroundImage: `url(${mediaUrl}${item.pImage})` }}
            ></div>
          ),
        };
      });
    setOnlineStaff(online_data);
    let offline_data =
      offline.length > 0 &&
      offline.map((item, index) => {
        return {
          ...item,
          key: index,
          image: (
            <div
              className="img"
              style={{ backgroundImage: `url(${mediaUrl}${item.pImage})` }}
            ></div>
          ),
        };
      });
    setOfflineStaff(offline_data);
  }, [data]);

  return (
    <div className="col-sm-12 mb-3">
      <div className="wap Staff_Members">
        {data.length > 0 ? (
          <>
            <h4>
              <img src="assets/images/Staff_Members.png" alt="Call_History" />{" "}
              Staff Members
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="Online-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Online-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="Online-tab-pane"
                    aria-selected="true"
                  >
                    Online
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Offline-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Offline-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="Offline-tab-pane"
                    aria-selected="false"
                  >
                    Offline
                  </button>
                </li>
              </ul>
            </h4>
            <p className="Lorem">{"EASILY MANAGE YOUR TEAM"}</p>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="Online-tab-pane"
                role="tabpanel"
                aria-labelledby="Online-tab"
                tabIndex="0"
              >
                <Table columns={columns} rows={onlineStaff} />
              </div>
              <div
                className="tab-pane fade"
                id="Offline-tab-pane"
                role="tabpanel"
                aria-labelledby="Offline-tab"
                tabIndex="0"
              >
                <Table columns={columns} rows={offlineStaff} />
              </div>
            </div>
          </>
        ) : (
          <div className="transactions text-center">
            <img src="../../assets/images/userss.png" alt="deadline1" />
            <p>Your Staff Members will appear here</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffMemberTable;
