import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const TableOfContent = ({ content }) => {
  const [headings, setHeadings] = useState([]);
  const { pathname } = useLocation();
  const [pageURL, setPageURL] = useState(0);

  useEffect(() => {
    // Parse the content and extract headings and subheadings
    setPageURL(window.location.href);
    const parsedHeadings = parseHeadingsFromContent(content);
    setHeadings(parsedHeadings);
  }, [pathname, content]);

  const parseHeadingsFromContent = (content) => {
    const headings = [];
    let currentHeading = null;

    // Use your logic to extract headings from content
    // For simplicity, let's assume content is in HTML format with h1, h2, h3 tags
    const headingElements = new DOMParser()
      .parseFromString(content, "text/html")
      .querySelectorAll("h1, h2, h3");

    headingElements.forEach((headingElement) => {
      const headingLevel = parseInt(headingElement.tagName.charAt(1), 10);

      const heading = {
        id: headingElement.id,
        text: headingElement.innerText,
      };

      if (!currentHeading || headingLevel === 1) {
        // Main heading or no current heading, add to top level
        headings.push(heading);
        currentHeading = heading;
      } else {
        // Subheading, add to the current heading
        currentHeading.subheadings = currentHeading.subheadings || [];
        currentHeading.subheadings.push(heading);
      }
    });
    return headings;
  };

  return (
    <div className="col-md-4 col-xxl-3">
      <div className="blogs_sidebar">
        <h2>Table of Content</h2>
        <ul>
          {headings &&
            headings.length > 0 &&
            headings.map((item, i) => (
              <>
                {item.text && (
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      document
                        .getElementById(`${item.id}`)
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <a href="#">{item.text}</a>
                  </li>
                )}

                {item?.subheadings?.map((el, index) => (
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById(`${el.id}`)
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      {el.text}
                    </a>
                  </li>
                ))}
              </>
            ))}
        </ul>
        <ul className="fb_n mt-4">
          <li>
            <TwitterShareButton url={pageURL}>
              <img src="../assets/images/tu_n.png" alt="icon" />
            </TwitterShareButton>
          </li>
          <FacebookShareButton url={pageURL}>
            <li>
              <img src="../assets/images/fb_n.png" alt="icon" />
            </li>
          </FacebookShareButton>
        </ul>
      </div>
    </div>
  );
};

export default TableOfContent;
