const RowInput = ({
  parentStyle,
  childStyle,
  labelStyle,
  labelDescription,
  label,
  placeholder,
  inType,
  event,
  name,
  errors,
  message,
  messageStyle,
  onChange,
  readOnly,
  disabled
}) => {
  return (
    <div className={parentStyle}>
      <div className={labelStyle}>
        <h5>{label}</h5>
        <p className="label">{labelDescription}</p>
      </div>
      <div className={childStyle}>
        <input
          className="form-control"
          type={inType}
          {...(event && event(name))}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
        />
        {message && <p style={{color: messageStyle}}>{message}</p>}
         {errors && errors[name] && (
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )}
      </div>
     
    </div>
  );
};

export default RowInput;
