import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import EditAgentProfileFaqModel from "../../../Layouts/Model/editagentProfileFaqModel";
import Example from "../../../Tools/ReactBoostrap";
import AgentProfileFaqModel from "../../../Layouts/Model/agentProfileFaqModel";

const FaqSection = ({
  setFaqData,
  faqData,
  companyOwner,
  selector,
  updateProfileFun,
  loading,
  handleUserNavigate,
}) => {
  const [editFaqModel, setEditFaqModal] = useState(false);
  const [addFaqModal, setAddFaqModal] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const editFaqFun = (index) => {
    setEditIndex(index);
    setEditFaqModal(!editFaqModel);
  };
  const deleteFaqFun = (index) => {
    const filterFaq = faqData.filter((el, i) => i !== index);
    let formData = new FormData();
    formData.append("faq", JSON.stringify(filterFaq));
    updateProfileFun(formData);
    setFaqData(filterFaq);
  };
  return (
    <>
      {(faqData?.length > 0 || companyOwner === selector?.uid || loading) && (
        <div className="set">
          <div className="d-flex justify-content-between">
            <h2>FAQ's </h2>
            <div>
              {companyOwner === selector?.uid && (
                <Link
                  className="plus"
                  onClick={() => setAddFaqModal(!addFaqModal)}
                  replace={true}
                >
                  <i class="bi bi-plus-circle"></i>
                </Link>
              )}
              {faqData.length > 3 && (
                <Link
                  className="viewall"
                  to={"faq"}
                  onClick={() => {
                    if (handleUserNavigate) {
                      handleUserNavigate("navigate", "/faq", "view all faq");
                    }
                  }}
                >
                  <i className="bi bi-eye"></i> View all
                </Link>
              )}
            </div>
          </div>
          {loading ? (
            <div className="faq">
              <h3>
                <Skeleton />
              </h3>
              <p>
                <Skeleton />
              </p>
            </div>
          ) : (
            faqData?.length > 0 &&
            faqData.slice(0, 3).map((item, index) => (
              <div className="faq">
                <h3>
                  {item.question}
                  {companyOwner === selector?.uid && (
                    <>
                      <Link onClick={() => editFaqFun(index)}>
                        <i className="bi bi-pencil-square"></i>
                      </Link>{" "}
                      <Link onClick={() => deleteFaqFun(index)}>
                        <i className="bi bi-trash"></i>
                      </Link>
                    </>
                  )}
                </h3>
                <p>{item.answer}</p>
              </div>
            ))
          )}
        </div>
      )}

      <Example
        show={editFaqModel}
        onHide={() => setEditFaqModal(!editFaqModel)}
        component={
          <EditAgentProfileFaqModel
            data={faqData}
            onHide={() => setEditFaqModal(!editFaqModel)}
            setFaqData={setFaqData}
            index={editIndex}
            updateProfileFun={updateProfileFun}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
      <Example
        show={addFaqModal}
        onHide={() => setAddFaqModal(!addFaqModal)}
        component={
          <AgentProfileFaqModel
            data={faqData}
            onHide={() => setAddFaqModal(!addFaqModal)}
            setFaqData={setFaqData}
            updateProfileFun={updateProfileFun}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
    </>
  );
};

export default FaqSection;
