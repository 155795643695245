import React, { useState, useEffect } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import { homeSearchApi } from "../../../../services/api";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SearchSuggestionBox from "../../../Tools/searchSuggestionBox";
import { mediaUrl } from "../../../../services/network";
import NotificationBox from "../../../Tools/NotificationBox";
import NewLocation from "./newLocation";
import ServicesSection from "../HomePage/services";

const MobileLocation = () => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchData, setSearchData] = React.useState("");

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [textIndex, setTextIndex] = useState(0);

  // const userDetails = JSON.parse();
  const normalSelector = useSelector((state) => state.auth);
  const selector = useSelector((state) => state.auth.userDetails);
  const navigate = useNavigate();


  const text = [
    "Immigration Consultants",
    "IELTS-PTE-TOELF Insititutes",
    "Education Visa Consultants",
    "Top Rated Consultants",
  ];
  useEffect(() => {
    let timeout;
    if (textIndex < text.length) {
      if (currentIndex <= text[textIndex].length) {
        timeout = setTimeout(() => {
          setCurrentText(
            (prevText) => prevText + text[textIndex][currentIndex]
          );
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 200);
      } else {
        setCurrentText("");
        setCurrentIndex(0);
        setTextIndex((prev) => (prev === text.length ? 0 : prev + 1));
      }
    } else {
      setTextIndex(0);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, text, textIndex]);

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    if (e.target.value.length === 0) {
      setData([]);
    }
    if (e.target.value.length > 1 && selector?.locationStatus === false) {
      navigate("/no_service");
    }
    if (e.target.value.length > 1) {
      homeSearchApi({
        latitude: selector.latitude,
        longitude: selector.longitude,
        text: e.target.value,
        city: selector.city,
      })
        .then((response) => {
          if (response.data.status === "success") {
            setData(response.data.data);
          }
        })
        .catch((e) => {
          console.log("error->", e);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?search=${searchData}`);
  };

  return (
    <>
      <NewLocation show={show} setShow={setShow} />
      <section className="mob_section">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <SimpleInput
              inType={"text"}
              placeholder={`Search for '${currentText}'`}
              name={"search"}
              onChange={handleSearch}
            />
            <button type="button">
              <i className="bi bi-search"></i>
            </button>{" "}
          </form>
          {data && data.length > 0 && (
            <div className="banner">
              <div className="set m-0 p-0">
                <div className={`ulshow`}>
                  <ul className="search_list">
                    {data.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <SearchSuggestionBox
                            imageClass={"mt-0"}
                            image={mediaUrl + item.company_logo}
                            name={item.company_name}
                            rating={item.rating}
                            location={item.permanent_address}
                            slug={item.slug}
                            verified={
                              item.pan_status === "Approved" &&
                              item.authorize_certificate_status ===
                                "Approved" &&
                              item.gst_status === "Approved" &&
                              true
                            }
                            averageRatting={item.averageRating.toFixed(1)}
                            rattingCount={item.rattingCount}
                            city={item.city}
                          />
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {normalSelector &&
            normalSelector.notification &&
            normalSelector.notification.length > 0 && (
              <ul className="dropdown-menu dropdown-menu-end noti">
                {normalSelector.notification.map((item, i) => (
                  <li key={i}>
                    <NotificationBox
                      type={item.type}
                      description={item.description}
                      message={item.title}
                      time={new Date(item.created_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    />
                  </li>
                ))}
                <li>
                  <Link to={"/notification"}>View All</Link>
                </li>
              </ul>
            )}

          {/* {show && <SearchLocation show={show} setShow={setShow} />} */}

          <ServicesSection />
        </div>
      </section>
    </>
  );
};

export default MobileLocation;
