import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import SiteMapContent from "./siteMapContent";
import StudentFooter from "../../../Layouts/Student/Footer";
import Meta from "../Meta";
import { SitemapMetaTags } from "../../../../data/metaTags";

const SiteMap = () => {
  return (
    <>
      <Meta {...SitemapMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"Sitemap"} />
      <SiteMapContent />
      <StudentFooter />
    </>
  );
};

export default SiteMap;
