import { mediaUrl } from "../../../../services/network";
const DaillingInterface = ({ style }) => {
  // const [visible, setVisible] = useState(false);
  // useEffect(() => {
  //   // setTimeout(() => {
  //   //   setVisible(!visible);
  //   // }, 7000);
  // }, [visible]);
  return (
    <div className={`spinner text-center ${style}`}>
      <img src={`${mediaUrl}spinner.gif`} />
      {/* {visible && (
        <button
          className="btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </button>
      )} */}
    </div>
  );
};
export default DaillingInterface;
