const FormTextarea = ({
  parentStyle,
  childStyle,
  label,
  placeholder,
  event,
  errors,
  name,
}) => {
  return (
    <div className={parentStyle}>
      <label>{label}</label>
      <textarea
        className={childStyle}
        name={name}
        {...(event && event(name))}
        placeholder={placeholder}
      ></textarea>
      {errors && errors[name] && (
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )}
    </div>
  );
};

export default FormTextarea;
