import React, { useEffect } from "react";
import PaginationTable from "../../../Tools/Pagination";
import NotificationBox from "../../../Tools/NotificationBox";
import { useDispatch, useSelector } from "react-redux";
import { allNotifications } from "../../../../Reducer/authSlice";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

const ListingNotifications = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  let itemPerPage = 10;
  let ONE_DAY = 24 * 60 * 60 * 1000; //milliseconds in a day

  const dispatch = useDispatch();
  const selector = useSelector((state) => state.auth);

  const rowsCount =
    selector.allNotifications &&
    selector.allNotifications.length > 0 &&
    selector.allNotifications[1][0].total;
  const rowsShowLimit = Math.ceil(rowsCount / itemPerPage);

  useEffect(() => {
    const body = { page: currentPage, pageSize: itemPerPage };
    dispatch(allNotifications(body));
  }, [currentPage]);

  return (
    <div className="container">
      {selector &&
        selector.auth &&
        ((selector.auth.pan_status == "Rejected" &&
          selector.auth.gst_status == "Rejected" &&
          selector.auth.authorize_certificate_status == "Rejected") ||
          (selector.auth.pan_status == "Pending" &&
            selector.auth.gst_status == "Pending" &&
            selector.auth.authorize_certificate_status == "Pending")) && (
          <Link to={"/account-settings"} className="Submit">
            <i className="bi bi-file-earmark-text-fill"></i>{" "}
            {"Submit your KYC Documents"}
          </Link>
        )}
      <section className="noti_page">
        {selector.allNotifications &&
          selector.allNotifications.length > 0 &&
          selector.allNotifications[0].map((item) => {
            return (
              <NotificationBox
                type={item.type}
                description={item.description}
                message={item.title}
                time={
                  new Date() - new Date(item.created_at) < ONE_DAY
                    ? new Date(item.created_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : new Date(item.created_at).toLocaleDateString([], {
                        day: "2-digit",
                        month: "short",
                      })
                }
              />
            );
          })}
        <PaginationTable
          rowsShowLimit={rowsShowLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          style={"mt-4 text-end"}
        />
      </section>
    </div>
  );
};

export default ListingNotifications;
