import { Link } from "react-router-dom";
import Option from "../../../Tools/Options";
 import DateSelecter from "../../../Tools/DateSelecter";
import Example from "../../../Tools/ReactBoostrap";

const ExportOption = ({
  parentStyle,
  active,
  actions,
}) => {
  return (
    <div className={parentStyle}>
      
      {/* <Example
          show={show}
          onHide={() => setShow(!show)}
          component={<DateSelecter onHide={() => setShow(!show)} />}
          style={"loginmodal study_buddies competitors"}
        /> */}
      {actions &&
        actions.length > 0 &&
        actions.map((item) => {
          return (
            <Link className={item.style} onClick={() => item.onclick("hello")}>
              {active == "competitors" ? (
                <i className={item.imageSrc} />
              ) : (
                <img src={item.imageSrc} />
              )}
              {item.label}
            </Link>
          );
        })}
    </div>
  );
};
export default ExportOption;
