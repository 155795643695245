import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { blogsMediaUrl } from "../../../services/network";
import { convert } from "html-to-text";

const LatestBlog = ({ data }) => {
  const [date, setDate] = useState([]);
  const navigate = useNavigate();

  const text = convert(data?.description);
  useEffect(() => {
    const date = new Date(data.unix_time * 1000).toDateString().split(" ");
    setDate(date);
  }, []);

  return (
    <div className="container">
      <div className="row mb-5">
        {data.image && (
          <div className="col-lg-5">
            <img
              src={`${blogsMediaUrl}${data.image}`}
              className="w-100 mb-3"
              alt="blogmain"
              style={{ cursor: "pointer" }}
            />
          </div>
        )}

        <div className="col-lg-7">
          {/* <h5
            onClick={() => navigate(`/blogs/category/${data.cat_slug}`)}
            style={{ cursor: "pointer" }}
          >
            {data.cat_name}
          </h5> */}
          <h1
            onClick={() => navigate(`/blogs/${data.slug}`)}
            style={{ cursor: "pointer" }}
          >
            {data.title}
          </h1>
          <p>{text.substring(0, 235)}</p>
          {date && date.length == 4 && (
            <p className="Dec">
              {data.author_name}| {date[1]} {date[2]} {date[3]}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestBlog;
