import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginModel from "../../../Tools/LoginModel";
import HeaderLogo from "../../../Tools/HeaderLogo";
import BecomeConsultant from "../../../Tools/BecomeConsultant";
import LoginButton from "../../../Tools/LoginButton";
import Location from "../../../Tools/Location";
import {
  getBalance,
  setUserDetails,
  toptenNotification,
} from "../../../../Reducer/authSlice";
import Example from "../../../Tools/ReactBoostrap";
import { googleMapApi } from "../../../../data/data";
import { mediaUrl } from "../../../../services/network";
import {
  checkPoint,
  checkReviewDetailApi,
  matchLocationApi,
  saveRefer,
  saveUserNavigate,
  updateNotificationStatus,
} from "../../../../services/api";
import Notifications from "../../../Pages/Chat/Player/Notifications";
import { SocketContext } from "../../../../Context";
import StillOnlineModel from "../../Model/stillOnlineModel";
import { defaultLocation } from "../../../../data/data";
import RattingReview from "../../../Tools/RattingReview";
import AddFundsModal from "../../Model/addFunds";
import { Link, useLocation } from "react-router-dom";
import MobileSideBar from "../MobileSideBar";
import DeleteAccountModal from "../../Model/deleteAccountModal";
import RegisterAvatarModel from "../../../Pages/Student/RegisterAvatarModel";
import moment from "moment";
import Calling from "../../../Pages/Chat/Player";
import DeclineSms from "../../../Tools/DeclineSms";
import $ from "jquery";

const StudentHeader = ({
  style,
  logo,
  bellIcon,
  messageIcon,
  formDisplay,
  setLocationShow,
  sts,
}) => {
  const token = localStorage.getItem("token");
  const dt = localStorage.getItem("dt");
  const [show, setShow] = useState(false);
  const [sidemenu, setSidemenu] = useState(false);
  const [avatarModal, setAvatarModal] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [city, setCity] = useState("");
  const [checkReviewData, setCheckReviewData] = useState(null);
  const [thankYou, setThankyou] = useState(false);
  const [mobMsg, setMobMsg] = useState(
    localStorage.getItem("mob_msg") ? false : true
  );
  const [changePhoneModelShow, setChangePhoneModelShow] = useState(false);
  const { userDetails } = useSelector((state) => state.auth);
  const normalSelector = useSelector((state) => state.auth);
  const {
    stillOnline,
    setStillOnline,
    rattingPop,
    setRattingPop,
    callUserId,
    access,
    callAccepted,
    addFundsModal,
    setAddFundsModal,
    screenWidth,
    deleteModal,
    logout,
    setDeleteModal,
  } = useContext(SocketContext);
  const locationf = useLocation();
  const query = new URLSearchParams(locationf.search);
  const dispatch = useDispatch();
  const refid = query.get("refid");
  const guestId = localStorage.getItem("guestId");

  const selector = useSelector((state) => state.auth);
  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: token ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: locationf.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  const location = (ipAddress) => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        fetchLocationInfo(latitude, longitude, ipAddress);
      },
      function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            localStorage.setItem(
              "userDetails",
              JSON.stringify(defaultLocation)
            );
            fetchLocationInfo(
              defaultLocation.latitude,
              defaultLocation.longitude,
              ipAddress
            );
            console.error("User denied the request for geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.error("The request to get user location has timed out.");
            break;
          default:
            console.error("An unknown error occurred.");
        }
      }
    );
  };
  const checkToken = async () => {
    try {
      const response = await checkPoint();
    } catch (error) {
      if (error && error.response.status === 401) {
        logout();
      }
    }
  };

  React.useEffect(() => {
    if (token && token !== null && token !== undefined) {
      checkToken();
    }
    if (
      normalSelector &&
      normalSelector.auth &&
      normalSelector.auth.role === 1 &&
      normalSelector.auth.name === null
    ) {
      setAvatarModal(!avatarModal);
    }
    if (
      localStorage.getItem("connV") &&
      token &&
      token !== null &&
      token !== undefined &&
      !access
    ) {
      checkReviewFun();
    }
  }, [normalSelector.success, normalSelector.otpSuccess]);

  useEffect(() => {
    if (
      normalSelector.token &&
      normalSelector.token !== null &&
      normalSelector.token !== undefined &&
      !access
    ) {
      dispatch(getBalance());
      dispatch(toptenNotification());
    }
  }, [normalSelector.token]);

  React.useEffect(() => {
    if (thankYou) {
      setTimeout(() => {
        setThankyou(!thankYou);
      }, 3000);
    }
  }, [thankYou]);

  useEffect(() => {
    if (access && window.innerWidth <= 767) {
      $("body").addClass("mini_body");
    } else {
      $("body").removeClass("mini_body");
    }
  }, [access]);

  const fetchLocationInfo = (latitude, longitude, ipAddress) => {
    const apiKey = googleMapApi;
    let cities = city;
    let statee;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
      latitude ? latitude : 30.7333
    },${longitude ? longitude : 76.7794}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        await data.results.forEach((i, index) => {
          if (i.types.includes("administrative_area_level_3")) {
            setCity(i.address_components[0].long_name);
            cities = i.address_components[0].long_name;
          }
          if (i.types.includes("administrative_area_level_1")) {
            statee = i.address_components[0].long_name;
          }
        });
        if (data.results && data.results.length > 0) {
          const location = data.results[5].formatted_address.split(",");
          let obj = {
            latitude: latitude,
            longitude: longitude,
            ip_address: ipAddress,
            address: data.results[5].formatted_address,
            city: cities,
          };

          const response = await matchLocationApi({
            city: cities,
            state: statee,
          });
          obj.locationStatus = response.data.status;
          dispatch(setUserDetails(obj));
        } else {
          dispatch(
            setUserDetails((prev) => ({
              ...prev,
              current_address: data.results[5].formatted_address,
            }))
          );
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const agentOption = [
    {
      path: "/partner-dashboard",
      imageSrc: `../../../../assets/images/my_dashboard.svg`,
      label: "My Dashboard",
    },
    {
      label: "View Profile",
      imageSrc: `../../../../assets/images/view_profile.svg`,
      path: `${`/${
        normalSelector.auth &&
        normalSelector.auth.city &&
        normalSelector.auth.city.replaceAll(" ", "-")
      }/${normalSelector.auth && normalSelector.auth.slug}/`}`,
      required: normalSelector.auth?.role === 5 ? false : true,
    },

    {
      path: "/partner/analytics",
      imageSrc: `../../../../assets/images/Account_Analytics.svg`,
      label: "Account Analytics",
      required: normalSelector.auth?.role === 5 ? false : true,
    },
    {
      path: "/partner/reports",
      imageSrc: `../../../../assets/images/My_Reports.svg`,
      label: "My Reports",
      required: normalSelector.auth?.role === 5 ? false : true,
    },
  ];

  const studentOption = [
    {
      path: "/applications/all",
      imageSrc: `../../../../assets/images/Applications.svg`,
      label: "My Applications",
      required:
        normalSelector.auth?.role === 5 &&
        normalSelector.auth?.status === "active" &&
        selector.auth.profile_percentage > 75
          ? false
          : true,
    },
    {
      path: `${
        normalSelector.auth && normalSelector.auth?.role === 5
          ? "/account-settings"
          : `/profile/${normalSelector.auth && normalSelector.auth.id}`
      }`,
      imageSrc: `../../../../assets/images/Account_Setting.svg`,
      label: "Account Settings",
    },
    {
      heading: (
        <span>
          {` INR ${
            normalSelector && normalSelector.funds && normalSelector.funds
          }`}
        </span>
      ),
      options: [
        {
          path: "",
          imageSrc: `../../../../assets/images/Add_Funds.svg`,
          label: "Add Funds",
          onClick: () => setAddFundsModal(!addFundsModal),
        },
        {
          path: `/transactions-list`,
          imageSrc: `../../../../assets/images/Transaction_History.svg`,
          label: "Transaction History",
        },
      ],
    },
    {
      path: `${normalSelector?.auth === 5 ? "/agent/help" : "/customers/help"}`,
      imageSrc: `../../../../assets/images/Help_Support.svg`,
      label: "Help & Support",
      style: "help_support",
    },
  ];
  const studentOptionWithoutFinances = [
    {
      path: "/applications/all",
      imageSrc: `../../../../assets/images/Applications.svg`,
      label: "My Applications ",
    },
    {
      path: `${
        normalSelector.auth && normalSelector.auth?.role === 5
          ? "/account-settings"
          : `/profile/${normalSelector.auth && normalSelector.auth.id}`
      }`,
      imageSrc: `../../../../assets/images/Account_Setting.svg`,
      label: "Account Settings",
    },
    {
      path: `/transactions-list`,
      imageSrc: `../../../../assets/images/Transaction_History.svg`,
      label: "Transaction History",
    },
    {
      path: `${
        normalSelector?.auth === 5 ? "/partners/help" : "/customers/help"
      }`,
      imageSrc: `../../../../assets/images/Help_Support.svg`,
      label: "Help & Support",
      style: "help_support",
    },
  ];

  const staffOption = [
    {
      path: "/staff-dashboard",
      imageSrc: `../../../../assets/images/my_dashboard.svg`,
      label: "My Dashboard",
    },
    {
      label: "View Profile",
      imageSrc: `../../../../assets/images/view_profile.svg`,
      path: `/${
        normalSelector &&
        normalSelector.auth &&
        normalSelector.auth.company_city &&
        normalSelector.auth.company_name
          ? normalSelector.auth.company_city.replaceAll(" ", "-") +
            "/" +
            normalSelector.auth.company_name.replaceAll(" ", "-")
          : "/staff-dashboard"
      }`,
    },
    {
      path: "/partner/analytics",
      imageSrc: `../../../../assets/images/Account_Analytics.svg`,
      label: "Account Analytics",
    },
    {
      path: "/partner/reports",
      imageSrc: `../../../../assets/images/My_Reports.svg`,
      label: "My Reports",
    },
  ];

  const checkReviewFun = async () => {
    try {
      const body = {
        id: localStorage.getItem("connV"),
      };
      const result = await checkReviewDetailApi(body);
      if (result.data.status === "success") {
        setCheckReviewData(result.data.data[0]);
        setRattingPop(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const guestId = localStorage.getItem("guestId");
    if (!guestId) {
      const date = Date.now();
      const random = Math.floor(Math.random() * 1000);
      const deviceID = `${random}${date}`;
      localStorage.setItem("guestId", deviceID);
    }
    if (refid && !access) {
      getReferAndSave();
    }
  }, []);

  const getReferAndSave = async () => {
    try {
      const guestId = localStorage.getItem("guestId");
      const body = {
        guest_id: guestId,
        refId: refid,
        user_id:
          normalSelector && normalSelector.auth && normalSelector.auth.uid,
      };
      const response = await saveRefer(body);
    } catch (error) {
      console.log(error);
    }
  };

  const readNotification = () => {
    try {
      dispatch(toptenNotification());
      if (normalSelector.notification.length > 0) {
        let ids = [];
        normalSelector.notification.forEach((element) => {
          ids.push(element.id);
        });
        updateNotificationStatus({ ids });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {screenWidth < 768 &&
        !dt &&
        mobMsg &&
        !normalSelector.token &&
        window.location.pathname !== "/messages" && (
          <div
            className="consult_hed"
            style={{
              backgroundImage: `url(../../../assets/images/consult_hed_bg.svg)`,
            }}
          >
            <h6>
              <b>Consult with Best Immigration Consultants </b>or
              IELTS-TOEFL-PTE Institutes{" "}
            </h6>
            <p>
              Get <i class="bi bi-currency-rupee"></i>50 upon{" "}
              <Link onClick={() => setShow(!show)} class="link">
                Sign up
              </Link>
            </p>
            <Link className="open_in_app" to={"/download-app"}>
              Open in App
            </Link>
            <i
              class="bi bi-x-circle-fill"
              onClick={() => {
                setMobMsg(!mobMsg);
                localStorage.setItem("mob_msg", true);
              }}
            ></i>
          </div>
        )}

      <header
        className={`main_header  ${
          dt || (sts && sts === "responsive") ? "d-none" : "d-block"
        } ${style} ${
          (normalSelector.auth && normalSelector.auth?.role === 1) ||
          normalSelector.auth?.role === 5 ||
          normalSelector.auth?.role === 7
            ? "header2"
            : ""
        } 
      `}
      >
        {!dt && <Notifications />}

        {thankYou && (
          <div className="thank_you">
            <img src="../../assets/images/thank_you.gif" />
          </div>
        )}
        <div className="container">
          <div className="row">
            <HeaderLogo
              parentStyle={"col-2 py-1"}
              imageSrc={
                logo ? logo : `../../../../assets/images/chownk-logo.png`
              }
              handleUserNavigate={handleUserNavigate}
            />
            {screenWidth > 991 ? (
              <div className="col-10 text-end">
                <Location
                  location={
                    userDetails && userDetails.address
                      ? userDetails.address
                      : ""
                  }
                  formDisplay={formDisplay}
                  fetchedLocation={location}
                  data={{ city: city }}
                  LocationShow={setLocationShow}
                  handleUserNavigate={handleUserNavigate}
                />
                {!normalSelector.auth ||
                Object.keys(normalSelector.auth).length === 0 ||
                (normalSelector.auth?.status === "uncompleted" &&
                  normalSelector.auth?.role === 1) ||
                (normalSelector.auth?.role !== 5 &&
                  normalSelector.auth?.status ===
                    ("uncompleted" || "hold" || "objection")) ? (
                  <LoginButton
                    heading={"Become a Partner"}
                    onClick={() => {
                      normalSelector.otp
                        ? setOtpShow(!otpShow)
                        : setShow(!show);
                    }}
                    path={"/become-consultant"}
                    handleUserNavigate={handleUserNavigate}
                  />
                ) : (
                  <BecomeConsultant
                    heading={
                      normalSelector.auth?.role === 5
                        ? ""
                        : normalSelector.auth?.role === 6
                        ? ""
                        : "Become a Partner"
                    }
                    path={
                      normalSelector.auth?.role === 5
                        ? "/partner-dashboard"
                        : "/become-consultant"
                    }
                    profileOption={
                      normalSelector.auth?.role === 5
                        ? studentOption
                        : normalSelector.auth?.role === 4
                        ? []
                        : studentOptionWithoutFinances
                    }
                    agentOption={
                      normalSelector.auth?.role === 5
                        ? agentOption
                        : staffOption
                    }
                    bellIcon={bellIcon}
                    messageIcon={messageIcon}
                    setChangePhoneModelShow={() =>
                      setChangePhoneModelShow(!changePhoneModelShow)
                    }
                    authenitication={
                      ((normalSelector.auth &&
                        normalSelector.auth?.role === 5) ||
                        6) &&
                      true
                    }
                    userImg={
                      normalSelector.auth &&
                      normalSelector.auth.profile_image &&
                      mediaUrl + normalSelector.auth.profile_image
                    }
                    role={normalSelector.auth && normalSelector.auth?.role}
                    notification={
                      normalSelector.notification
                        ? normalSelector.notification
                        : []
                    }
                    notiCount={normalSelector.notiCount}
                    readNotification={readNotification}
                    handleUserNavigate={handleUserNavigate}
                  />
                )}
              </div>
            ) : (
              !dt && (
                <div className="col-10 text-end">
                  <ul className="mob_join">
                    {token ? (
                      <li
                        className="btn-group"
                        onMouseEnter={() => readNotification()}
                      >
                        <Link className="p-0" to={"/notification"}>
                          <img
                            src={`../../../assets/images/${
                              bellIcon ? bellIcon : "hedic1black.png"
                            }`}
                            alt="logo"
                          />
                          {normalSelector.notiCount > 0 && <span></span>}
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li onClick={() => setShow(!show)}>
                          <Link>Join</Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Link
                        className="Profile_bt"
                        onClick={() => {
                          setSidemenu(!sidemenu);
                        }}
                      >
                        <i class="bi bi-list"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              )
            )}
          </div>
        </div>
        <MobileSideBar
          sidemenu={sidemenu}
          setSidemenu={setSidemenu}
          otpShow={otpShow}
          setOtpShow={setOtpShow}
          show={show}
          setShow={setShow}
        />
      </header>

      <LoginModel
        show={show}
        setShow={setShow}
        redirect={window.location.href}
      />

      <Example
        show={stillOnline}
        onHide={() => setStillOnline(!stillOnline)}
        component={
          <StillOnlineModel onHide={() => setStillOnline(!stillOnline)} />
        }
        style={"areyoustill"}
      />
      {rattingPop && (
        <Example
          show={rattingPop}
          onHide={() => setRattingPop(!rattingPop)}
          component={
            <RattingReview
              onHide={() => {
                setCheckReviewData(null);
                setRattingPop(!rattingPop);
              }}
              setThankyou={setThankyou}
              agentdata={checkReviewData}
              to={callUserId}
            />
          }
          style={"call_ended"}
        />
      )}

      <Example
        show={addFundsModal}
        onHide={() => setAddFundsModal(!addFundsModal)}
        component={
          <AddFundsModal onHide={() => setAddFundsModal(!addFundsModal)} />
        }
        style={"loginmodal add_money_wallet"}
      />
      <Example
        show={deleteModal}
        onHide={() => setDeleteModal(!deleteModal)}
        component={
          <DeleteAccountModal onHide={() => setDeleteModal(!deleteModal)} />
        }
        style={"loginmodal otplogin"}
      />
      <Example
        show={avatarModal}
        onHide={() => setAvatarModal(!avatarModal)}
        component={
          <RegisterAvatarModel onHide={() => setAvatarModal(!avatarModal)} />
        }
        hideCloseBtn={true}
        style={"loginmodal avatar_popup"}
        backDrop={"static"}
      />

      {!callAccepted && !access && <DeclineSms />}
      {access && window.innerWidth > 767 && (
        <div className="call_maini call_2 draggable-item">
          <Calling />
        </div>
      )}
      {access && window.innerWidth <= 767 && (
        <div className="call_maini call_2">
          <Calling />
        </div>
      )}
    </>
  );
};
export default React.memo(StudentHeader);
