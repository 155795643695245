import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AchievementModal from "../../../Layouts/Model/achievementModal";
import Example from "../../../Tools/ReactBoostrap";
import Skeleton from "react-loading-skeleton";

const Achievement = ({
  data,
  companyOwner,
  selector,
  loading,
  updateProfileFun,
}) => {
  const [addAchievement, setAchievement] = useState(false);
  const [achievementsData, setAchievementsData] = useState([]);

  useEffect(() => {
    if (data) {
      const dt = JSON.parse(data);
      if (dt && dt.length > 0) {
        const ac = dt.filter((el) => el.achievement !== "");
        setAchievementsData(ac);
      }
    }
  }, [data]);

  return (
    <>
      {(achievementsData.length > 0 ||
        companyOwner === selector?.uid ||
        loading) && (
        <div class="set Achievements">
          <h2>
            Achievements
            {companyOwner === selector?.uid && (
              <span class="float-end">
                <Link
                  class="ms-2"
                  onClick={() => setAchievement(!addAchievement)}
                >
                  <i class="bi bi-pencil-square"></i>
                </Link>
              </span>
            )}
          </h2>
          {loading ? (
            <Skeleton />
          ) : (
            <div
              id="carouselExampleIndicators"
              class="carousel slide mt-3"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                {achievementsData &&
                  achievementsData.length > 0 &&
                  achievementsData.map((item, index) => (
                    <div class={`carousel-item ${index === 0 ? "active" : ""}`}>
                      <p>
                        <img
                          src="../../../assets/images/koma1.svg"
                          alt="koma1"
                        />
                        {item.achievement}
                        <img
                          src="../../../assets/images/koma2.svg"
                          alt="koma2"
                        />
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}

      <Example
        show={addAchievement}
        onHide={() => {
          setAchievement(!addAchievement);
        }}
        component={
          <AchievementModal
            data={data}
            achievementsData={achievementsData}
            setAchievementsData={setAchievementsData}
            onHide={() => {
              setAchievement(!addAchievement);
            }}
            updateProfileFun={updateProfileFun}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
    </>
  );
};

export default Achievement;
