import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Example from "../ReactBoostrap";
import { onlineOflineApi } from "../../../services/api";
import { SocketContext } from "../../../Context";
import { useDispatch, useSelector } from "react-redux";
import { setOnlineStatus } from "../../../Reducer/authSlice";
import Boost from "../Boost";
import { Link } from "react-router-dom";
import BoostEligibleModal from "../../Layouts/Model/boostEligbleModal";

const SwitchCheckbox = ({
  parentStyle,
  heading,
  description,
  isChecked,
  onClick,
  disabled,
  online,
  crossIcon,
  setOnline,
  showMessage,
  type,
}) => {
  const selector = useSelector((state) => state.auth);
  const { OffOn } = useContext(SocketContext);
  const [open, setOpen] = React.useState(false);
  const [boost, setBoost] = useState(false);
  const [eligibleModal, setEligibleModal] = useState(false);
  const dispatch = useDispatch();
  const handleCheckboxChange = () => {
    if (selector?.auth?.status === "active") {
      const body = {
        status: !isChecked,
      };
      dispatch(setOnlineStatus(isChecked ? "inactive" : "active"));
      OffOn(!isChecked);
      onlineOflineApi(body)
        .then((response) => {
          setOnline(!online);
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  };

  const handleBoost = () => {
    if (selector?.auth?.status === "active") {
      setEligibleModal(!eligibleModal);
    }
  };

  const handleChange = () => {
    if (selector.auth && selector.auth.profile_percentage <= 75) {
      toast.warn("Complete Your Profile above 75%", {
        position: "top-left",
      });
    } else {
      if (type === "online") {
        handleCheckboxChange();
      } else {
        handleBoost();
      }
    }
  };

  return (
    <div className={parentStyle} onClick={onClick}>
      <h6>
        {heading}{" "}
        <span className={isChecked ? "on" : "off"}>
          {isChecked ? "(on)" : "(off)"}
        </span>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            disabled={disabled}
            checked={isChecked}
            onChange={() => {
              handleChange();
            }}
          />
        </div>
        <p>{description}</p>
      </h6>
      {!open && crossIcon && (
        <div className="open">
          <i className="bi bi-x-circle" onClick={() => setOpen(!open)}></i>
          <h6>{"Focus on your tasks"}</h6>
          <p>
            {
              "Choose from online and offline to let others know when you're ready for communication."
            }
          </p>
        </div>
      )}
      {type === "boost" && (
        <>
          {selector.activeBoosts && selector.activeBoosts.length > 0 && (
            <h6>
              {"Currently boosted"}
              {type === "boost" &&
                selector.activeBoosts &&
                selector.activeBoosts.length > 0 && (
                  <Link
                    onClick={() => setBoost(!boost)}
                    className="AddMore AddMore2"
                  >
                    <i class="bi bi-plus-square-fill"></i>
                  </Link>
                )}
            </h6>
          )}
          <ul className="day_left">
            {selector.activeBoosts &&
              selector.activeBoosts.map((item) => {
                let date1 = new Date();
                let date2 = new Date(item.end_unix);
                let Difference_In_Time = date2.getTime() - date1.getTime();
                let Difference_In_Days = Math.ceil(
                  Difference_In_Time / (1000 * 3600 * 24)
                );
                return (
                  <li className="mb-2 me-2">
                    {item.name} - <span>{Difference_In_Days} days left</span>
                  </li>
                );
              })}
          </ul>
        </>
      )}

      {showMessage && <p style={{ color: "red" }}>{"Complete your Profile"}</p>}
      <Example
        show={boost}
        onHide={() => setBoost(!boost)}
        component={<Boost onHide={() => setBoost(!boost)} />}
        style={"add_boost_area"}
      />
      <Example
        show={eligibleModal}
        onHide={() => setEligibleModal(!eligibleModal)}
        component={
          <BoostEligibleModal
            onHide={() => setEligibleModal(!eligibleModal)}
            boost={boost}
            setBoost={setBoost}
          />
        }
        style={"add_boost_area"}
      />
    </div>
  );
};
export default SwitchCheckbox;
