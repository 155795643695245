const SocialMedia = ({ heading }) => {
  return (
    <>
      <h4>{heading}</h4>
      <h6>
        <img src="assets/images/social1.png" alt="social4" />
        <img src="assets/images/social2.png" alt="social4" />
        <img src="assets/images/social3.png" alt="social4" />
        <img src="assets/images/social4.png" alt="social4" />
      </h6>
    </>
  );
};
export default SocialMedia;
