import React from "react";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import OTPTimer from "../../../Tools/Otptimer";
import Button from "../../../Tools/Button";
import {
  phoneVerification,
  otpVerification,
} from "../../../../Reducer/authSlice";
import HeadingWithImageOtp from "./headingWithImageOtp";

const Otp = () => {
  const [otp, setOtp] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otpDuration = 30; // Adjust the OTP timer duration as needed
  const selector = useSelector((state) => state.auth);
  if (!selector.otp) {
    navigate("/login");
  }
  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    try {
      const body = {
        phone: selector?.phoneNumber,
        otp: otp,
        latitude: selector?.latitude,
        longitude: selector?.longitude,
        ip_address: selector?.ip_address,
        current_address:
          selector?.current_address == "Ambāla"
            ? "Ambala"
            : selector.current_address,
        device_token: "3453453453434535r3rfg43534rf3443f34tg43g43gy54hg45",
        device_id: 435345345,
        app_version: "17.05.02",
        app_id: 2,
        platform: "web",
      };
      dispatch(otpVerification(body));
    } catch (e) {}
  };

  const onResendFun = () => {
    const body = {
      phone: selector?.phoneNumber,
      latitude: selector?.latitude,
      longitude: selector?.longitude,
      ip_address: selector?.ip_address,
      current_address: selector?.current_address,
    };
    dispatch(phoneVerification(body));
  };

  if (selector.otpSuccess) {
    switch (selector.redireaction) {
      case "/home":
        toast.success("Successfully Login", {
          position: toast.POSITION.TOP_LEFT,
        });
        navigate("/partner-dashboard");
        break;
      case "/profile":
        toast.success(selector.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        navigate("/fill-details");
        break;
      case "/login":
        toast.success(selector.error, {
          position: toast.POSITION.TOP_LEFT,
        });
        break;
    }
  }
  return (
    <>
      <section
        className="loginpage loginmodal w-100"
        style={{ backgroundImage: "url(assets/images/login_screen.png)" }}
      >
        <div
          className="loginhead"
          style={{ backgroundImage: "url(assets/images/login_screen2.png)" }}
        >
          <div className="container">
            <div className="login_p">
              <div className="row">
                <HeadingWithImageOtp
                  parentStyle={"col-md-6"}
                  childStyle={"right_login h-100"}
                  heading={"OTP Verification"}
                  paragraph={"Fill OTP for verification"}
                  imageSrc={"assets/images/agent_otp_bg.png"}
                  imageStyle={"w-75"}
                />
                <div className="col-md-6">
                  <div className="lest_login text-center">
                    <img src="assets/images/logo2.png" alt="logo" />
                    <h6>
                      {"An OTP has been sent to your"}
                      <br />
                      {"phone number"} <Link to={"/login"}>{"Change"}</Link>
                    </h6>
                    <form
                      className="text-start mt-4"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-group form-groupotp text-center">
                        <label className="d-block text-start">
                          {"Enter your OTP"} {selector.otp}
                        </label>
                        <OTPInput
                          className={"form-control"}
                          inputStyle={{ width: "40px" }}
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <h5 className="mt-3 text-center">
                        {"Resend OTP in"}{" "}
                        <OTPTimer
                          duration={otpDuration}
                          onTimeout={handleOTPTimerTimeout}
                          onResend={onResendFun}
                        />
                      </h5>
                      <Button
                        inType={"button"}
                        style={"btn mt-2 mt-lg-5"}
                        title={"Continue"}
                        disabled={otp.length < 4 && { backgroundImage: "blue" }}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Otp;
