import React, { useContext, useState } from "react";
import OTPTimer from "../../Tools/Otptimer";
import { mediaUrl } from "../../../services/network";
import { ToastContainer, toast } from "react-toastify";
import OTPInput from "react-otp-input";
import Button from "../../Tools/Button";
import {
  deleteAccountApi,
  studentOtpVerificationApi,
  studentPhoneVerificationApi,
  verifyOtp,
} from "../../../services/api";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../Context";

const DeleteAccountModal = ({ onHide }) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const normalSelector = useSelector((state) => state.auth);
  const dt = localStorage.getItem("dt");
  const { logout } = useContext(SocketContext);

  let otpDuration = 120;

  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const response = await deleteAccountApi({
        phone: normalSelector.auth?.phone,
        otp: otp,
      });
      if (response.data.status) {
        setError(null);

        toast.success("Your Account will be 100% deactivated after 30 days");
        setTimeout(() => {
          logout();
          window.ReactNativeWebView.postMessage(JSON.stringify(dt));
        }, 1000);
        onHide();
      }
    } catch (error) {
      if (error) {
        setError(error.response.data.message);
      }
    }
  };

  const onResendFun = async () => {
    try {
      await studentPhoneVerificationApi({
        phone: normalSelector.auth?.phone,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="lest_login text-center">
          <img src={`../../assets/images/chownk-logo.png`} alt="CHOWNK logo" />
          <h3 className="text-center">{"OTP Verification"}</h3>
          <h6>
            {"An OTP has been sent to your"}
            <br />
            {"phone number"}
            {/* <Link onClick={() => {}}>{"Change"}</Link> */}
          </h6>
          <form className="text-start mt-4" onSubmit={onSubmit}>
            <div className="form-group form-groupotp text-center">
              <label className="d-block text-start">{"Enter your OTP"}</label>
              <OTPInput
                className={"form-control"}
                inputStyle={{ width: "40px" }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                inputType={"tel"}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <h5 className="mt-3 text-center">
              <OTPTimer
                duration={otpDuration}
                onTimeout={handleOTPTimerTimeout}
                onResend={onResendFun}
              />
            </h5>
            <Button
              inType={"submit"}
              style={"btn mt-2 mt-lg-5"}
              title={"Continue"}
              inlineStyle={
                otp.length == 4
                  ? { backgroundColor: "#1DBF73", color: "white" }
                  : {}
              }
              disabled={otp.length < 4 && { backgroundImage: "blue" }}
            />
          </form>
        </div>
      </div>
      <div className="col-md-6">
        <div className="right_login">
          <p>
            <div className="column">
              <h3 className="no-puck text-white">
                {" "}
                Trust
                <span className="highlight"> CHOWNK </span>
                to navigate the complexities of international immigration
                seamlessly, ensuring you're well-informed and supported every
                step of the way.{" "}
              </h3>
              <div className="mob video-demonstration">
                <div
                  className="youtube-video"
                  data-lumberjack-action="partnerships.landing_page.video"
                ></div>
              </div>
              <ul className="bullet-list">
                <li>Secure Video 1 to 1 Call with Experts</li>
                <li>Chat and Messaging</li>
                <li>Recording Options</li>
                <li>Document Checklist</li>
                <li>Client Portal</li>
                <li>Informational Content</li>
                <li>Document Sharing</li>
                <li>Feedback and Review System</li>
                <li>Notification Alerts</li>
              </ul>
              <div className="become-partner-container mob">
                <p>
                  <i className="bi bi-arrow-left"></i> <strong>Join Now</strong>
                </p>
              </div>
            </div>
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DeleteAccountModal;
