import React, { useEffect, useState } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import SimpleTextArea from "../../../Tools/SimpleTextarea";
import { countMinusWithInputValue } from "../../../../services/function";
import { Link } from "react-router-dom";

const FaqTab = ({ currentStep, fields, append, errors, register, remove }) => {
  const [count, setCount] = useState(
    fields.map((el) => ({
      question: el.question.length,
      answer: el.answer.length,
    }))
  );

  useEffect(() => {
    setCount(
      fields.map((el) => ({
        question: el.question.length,
        answer: el.answer.length,
      }))
    );
  }, [fields]);

  const handleInputChange = (index, field, value) => {
    const newCharCounts = [...count];
    newCharCounts[index][field] = value.length;
    setCount(newCharCounts);
  };

  return (
    <div
      className="tab"
      style={{ display: currentStep === 8 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          8. Add FAQ’s (minimum 3 required)
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>
                Show Customers Common Questions which Client asks you like your
                Company location etc
              </p>
              <a
                href="../../assets/images/select7.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select7.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
      </div>
      {fields &&
        fields.length > 0 &&
        fields.map((field, index) => (
          <>
            <div className="form-group">
              <p className="label">Title</p>
              <SimpleInput
                key={field.id}
                style="form-control"
                type="text"
                placeholder="Enter Question"
                name={`faq.${index}.question`}
                event={register}
                onChange={(e) =>
                  handleInputChange(index, "question", e.target.value)
                }
              />
              {errors &&
                errors.faq &&
                errors.faq[index] &&
                errors.faq[index].question && (
                  <p style={{ color: "red" }}>
                    {errors.faq[index].question.message}
                  </p>
                )}
              <p className="text-end">{`${countMinusWithInputValue(
                60,
                count[index] ? count[index].question : 0
              )} Characters`}</p>
            </div>
            <div className="form-group">
              <p className="label">Description</p>
              <SimpleTextArea
                key={field.id}
                style="form-control"
                placeholder="Add Answer"
                name={`faq.${index}.answer`}
                event={register}
                onChange={(e) =>
                  handleInputChange(index, "answer", e.target.value)
                }
              />
              {errors &&
                errors.faq &&
                errors.faq[index] &&
                errors.faq[index].answer && (
                  <p style={{ color: "red" }}>
                    {errors.faq[index].answer.message}
                  </p>
                )}
              <p className="text-end">{`${countMinusWithInputValue(
                200,
                count[index] ? count[index].answer : 0
              )} Characters`}</p>
            </div>
            {index > 0 && (
              <Link onClick={() => remove(index)} className="addmore me-2">
                <i className="bi bi-dash-circle"></i> Remove
              </Link>
            )}
          </>
        ))}

      <Link
        onClick={() => append({ question: "", answer: "" })}
        className="addmore"
      >
        <i className="bi bi-plus-circle"></i> Add More
      </Link>
    </div>
  );
};

export default FaqTab;
