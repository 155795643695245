const ExamOfferBox = ({ backgroundImage, title, description }) => {
  return (
    <div className="col-6 col-lg-6 mb-4">
      <div
        className="img2"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <h4 className="mt-2">{title}</h4>
      <p className="mb-2">{description}</p>
      <a href="#" className="more">
        {"More Information"} <i className="bi bi-arrow-right"></i>
      </a>
    </div>
  );
};

export default ExamOfferBox;
