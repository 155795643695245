import { faker } from "@faker-js/faker";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const DashBoardChart = () => {
  const selector = useSelector((state) => state.auth);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Profile Views",
      },
    },
  };
  const labels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thrusday",
    "Friday",
    "Saturday",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Views",
        data: selector.profileViews,
        backgroundColor: "rgba(42, 184, 92)",
      },
      {
        label: "Boosted Views",
        data: selector.boostProfileViews,
        backgroundColor: "rgba(44, 101, 176)",
      },
    ],
  };

  return (
    <div className="col-xxl-7 mb-3">
      <div className="wap">
        <Bar options={options} data={data} />
        <h3>Active Users</h3>
        <p className="than">
          than last week <span>+{selector.activeUsers}</span>
        </p>
        <p className="mt-3">
          We have created multiple options for you to put together and customize
          into pixel perfect pages.
        </p>
        <ul>
          <li>
            {selector.alluser} <br />
            <span>All Users</span>
          </li>
          <li>
            {selector.allCall} <br />
            <span>Calls</span>
          </li>
          {/* <li>
            {selector.alluser} <br />
            <span>Chats</span>
          </li> */}
        </ul>
      </div>
    </div>
  );
};
export default DashBoardChart;
