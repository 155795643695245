import React, { useContext, useEffect } from "react";
import { Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../Context";
import Notifications from "../../Pages/Chat/Player/Notifications";
import DeclineSms from "../../Tools/DeclineSms";
import Calling from "../../Pages/Chat/Player";
import $ from "jquery";
import { checkPoint, checkVersionApi } from "../../../services/api";

const MobileFooter = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const selector = useSelector((state) => state.auth);
  const { smsCount, callAccepted, access,logout } = useContext(SocketContext);

  useEffect(() => {
    if (token && token !== null && token !== undefined) {
      checkToken();
    }
    checkVersionFun();
  }, []);

  useEffect(() => {
    if (access) {
      $("body").addClass("mini_body");
    } else {
      $("body").removeClass("mini_body");
    }
  }, [access]);

  const checkToken = async () => {
    try {
      const response = await checkPoint();
    } catch (error) {
      if (error && error.response.status === 401) {
        logout();
      }
    }
  };


  const checkVersionFun = async () => {
    let name = "website";
    let version = "3.4.9";
    try {
      const body = {
        name,
        version,
      };
      const response = await checkVersionApi(body);
      if (response.data.status) {
        window.location.reload(true);
      }
    } catch (e) {}
  };

  return (
    <>
      <Notifications />
      {!callAccepted && !access && <DeclineSms />}
      {access && window.innerWidth>767  && (
          <div className="call_maini call_2 draggable-item">
            <Calling />
          </div>
      )}
      {access && window.innerWidth<=767 && (
        <div className="call_maini call_2">
          <Calling />
        </div>
      )}
      <div className="footer_mob">
        <ul>
          <li
            className={
              (location.pathname === "/partner-dashboard" ||
                location.pathname === "/staff-dashboard" ||
                location.pathname === "/") &&
              "active"
            }
          >
            <Link
              to={
                selector?.auth?.role === 5
                  ? "/partner-dashboard"
                  : selector?.auth?.role === 6
                  ? "/staff-dashboard"
                  : "/"
              }
            >
              {/* <i className="bi bi-house"></i> */}
              <img src="../../../assets/images/mobfoot1.svg" alt="home" />
            </Link>
          </li>

          <li className={`${location.pathname === "/search" && "active"}`}>
            <Link to={`/search`}>
              {/* <i className="bi bi-link-45deg"></i> */}
              <img src="../../../assets/images/mobfoot2.svg" alt="home" />
            </Link>
          </li>

          <li className={`${location.pathname === "/messages" && "active"}`}>
            <Link to={"/messages"}>
              {/* <i className="bi bi-chat-dots"></i> */}

              <img src="../../../assets/images/mobfoot3.svg" alt="home" />
              {smsCount > 0 && <span className="count">{smsCount}</span>}
            </Link>
          </li>
          <li
            className={
              (location.pathname === "/mobile-sidebar" ||
                location.pathname === "/mobile-sidebarr") &&
              "active"
            }
          >
          <Link
            className="Profile_bt"
            // onClick={() => {
            //   setShow(!show);
            // }}
            to={
              selector && selector.auth && selector.auth.role == 6
                ? "/mobile-sidebarr"
                : "/mobile-sidebar"
            }
          >
          {/* <i className="bi bi-list"></i> */}
          <img src="../../../assets/images/mobfoot4.svg" alt="home" />
        </Link>
          </li>
        </ul>
      </div>
      {/* <MobileSideBar sidemenu={show} setSidemenu={setShow} /> */}
    </>
  );
};

export default MobileFooter;
