import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import Meta from "../Meta";

const PreventFraud = () => {
  return (
    <div>
      <Meta
        title={"Frauds in the Immigration Industry : Tips to Avoid Scams"}
        description={
          "Safeguard your immigration journey by learning how to spot and prevent fraud in the industry. Get expert advice and tips on avoiding scams."
        }
      />
      <StudentHeader />
      <section class="Frauds_set1">
        <div
          class="Fraudsbg text-center mb-4"
          style={{ backgroundImage: "url(../assets/images/Fraudsbg.png)" }}
        >
          <h1>
            What Frauds Are Happening in
            <br />
            Immigration industry and
            <br />
            How to avoid it.
          </h1>
        </div>

        <div class="container">
          <p>
            Many people go to Canada, USA, and other popular foreign countries
            to improve their lives. With rising demand, fraudsters target
            enthusiastic newcomers. In recent years, immigration fraud has
            increased, and cheating has become more common in their schemes.
            These fraudsters can drain their victims' finances and ruin their
            credit with everything from fake job offers to forged visas and
            green cards. This post will tell you about What Frauds Are Happening
            in the Immigration Industry and How to Avoid it. Let us get started
            and make sure your dream abroad does not turn into a nightmare.
          </p>
          <h2 class="mt-4">How Do Scammers Operate These Things</h2>
          <p>
            Scammers understand how complicated the immigration process is. They
            take advantage of the confusion it causes, attempting to exploit
            people seeking help.
          </p>
          <p>
            Scammers offer 'assistance' with immigration in exchange for cash.
            They may promise you a permanent residency permit or a work visa,
            but no immigration consultant can guarantee a specific outcome for
            an immigration application. So be very careful and do not give them
            personal information or money.
          </p>
          <p>
            When working with visa consultants, you can take a variety of
            precautions to ensure your safety. Before you part with any money,
            do your research on the company. If you need immigration assistance,
            there are numerous reputable companies that can help you. Second,
            never send money to someone before they have fulfilled their promise
            of a service. Finally, the majority of things that seem too good to
            be true are not. Be wary of anyone who promises you a specific
            result or makes other overly optimistic claims.{" "}
          </p>
        </div>
      </section>

      <section class="Frauds_set2 text-center">
        <div class="container">
          <div class="setain ">
            <h2>Types Of Immigration Scam That Can Happen </h2>
            <div class="row">
              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Job Offers Scams</h3>
                  <p>
                    These scams typically ask for money or personal information
                    in exchange for what appear to be lucrative job offers or
                    assistance with immigration procedures. Scammers may create
                    job offers in other countries that appear to be genuine,
                    with high pay and benefits.
                  </p>
                  <p>
                    They frequently charge upfront fees for visas, work permits,
                    and recruitment services. And victims may receive letters of
                    invitation, employment contracts, and official-looking
                    documents that are all falsified to look real.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Document Forgery</h3>
                  <p>
                    TDocument forgery fraud is the creation of forged documents
                    in order to deceive immigration authorities for personal
                    gain. This illegal activity involves making false claims to
                    obtain visas, green cards, or citizenship.
                  </p>
                  <p>
                    Criminals may fabricate passports, visas, or other forms of
                    identification in order to conceal their identity or
                    immigration status. These documents could be used to obtain
                    fraudulent legal immigration benefits or illegally enter the
                    country.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Fee Overcharging</h3>
                  <p>
                    Immigration fraud and scams can take many forms, such as
                    overcharging or exploiting vulnerable individuals seeking
                    immigration services. Some people or organizations claim to
                    be immigration experts or consultants but lack the necessary
                    credentials. Additionally, they charge exorbitant fees for
                    their services, promising quick and guaranteed results but
                    frequently providing incorrect information or submitting
                    fraudulent applications.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Ghost Consultants Or Immigration fraudsters</h3>
                  <p>
                    Those or groups that participate in fraud and scam
                    activities linked to the immigration process are referred to
                    as "ghost consultants," or immigration fraudsters. These
                    fraudulent firms frequently present themselves as unlicensed
                    immigration attorneys or specialists.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Marriage fraud</h3>
                  <p>
                    Scammers defraud people by arranging fake marriages in order
                    to obtain immigration benefits such as green cards or
                    citizenship. Furthermore, they take advantage of
                    unsuspecting people, rendering the immigration system
                    unfair. Scammers employ this method to steal your money.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Adoption fraud</h3>
                  <p>
                    Scammers commit adoption fraud in immigration by deceptively
                    manipulating the system. People may experience severe
                    negative effects from this. In fact, it may have legal
                    ramifications, such as deportation or denial of immigration
                    benefits. Furthermore, the fraud may cause emotional and
                    financial distress, as well as uncertainty and potential
                    legal consequences.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Theft of identities</h3>
                  <p>
                    Scams and identity theft can have negative effects on both
                    individuals and governments. Typically, criminals use these
                    scams to take advantage of the vulnerability of migrants who
                    are applying for legal status in the new nation.
                  </p>
                  <p>
                    Scammers create fake websites or send bogus emails claiming
                    to be from government immigration authorities..
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../assets/images/Typesicon1.svg" alt="Types" />
                  </div>
                  <h3>Unlicensed Immigration Service Providers</h3>
                  <p>
                    Verify a consultant's license with the College of
                    Immigration and Citizenship Consultants Immigration before
                    working with them. Unlicensed immigration consultants have
                    not been vetted and may even become identity thieves. The
                    IRCC will not accept operations submitted by unlicensed
                    advisers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Frauds_set3">
        <div class="container">
          <h2>Telephone Scams</h2>
          <p>
            Telephonic scams and frauds are unfortunately common these days, and
            scammers are getting smarter. Here, scammers may ask for personal
            information like bank account numbers and credit card numbers in
            exchange for sharing OTPs
          </p>
          <p>
            If you decline, they might try to identify you over the phone,
            employ emotional blackmail, or make threats. Therefore, you need to
            be careful when handling these phone scams.
          </p>

          <h3>How a Telephone Immigration Scam Can Happen With You?</h3>
          <p>
            In some telephone scams, the caller pretends to be a police or
            immigration officer and tells you that you broke the law. It is
            probably a scam or a phishing scheme if:
          </p>

          <ul>
            <li>You may get a call to pay money, or</li>
            <li>
              To provide personal information (birthdate, passport number,
              details from a bank account or credit card, etc.) or
            </li>
            <li>
              And you might get threatened on the phone with being arrested, put
              in jail, losing your immigration status or visa, getting sent back
              to your home country, or having your account stopped if you don't
              pay up or give out your personal information.
            </li>
            <li>
              You may get a call saying that we are from XYZ Immigration
              Company, and we need your immediate payment or personal
              information to avoid these consequences.
            </li>
          </ul>

          <h2>Email Frauds</h2>
          <p>
            In these schemes, scammers send emails demanding payment for
            invoices while posing as reputable suppliers or services. Every now
            and then, phishing websites are created in an effort to deceive
            users. When someone pays an invoice, the money goes to the account
            of the scammer. Sometimes, scammers hack email addresses to obtain
            personal photos, which they can use to blackmail a victim. Thus,
            keep an eye out for these fraudsters and avoid giving them any
            personal information. If you receive any type of scam email or
            message, please contact your nearest police station.
          </p>

          <h3 class="mt-5">How Email Immigration Scam Can Happen In India?</h3>

          <div class="row mt-4 text-center">
            <div class="col-sm-4">
              <div class="sp">
                <h4>Phishing Emails</h4>
                <p>
                  Scammers may send emails posing as immigration officials or
                  service providers, requesting personal information or payment.
                </p>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="sp">
                <h4>False Information</h4>
                <p>
                  They may give false information about immigration procedures
                  or offer bogus services for a fee.
                </p>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="sp">
                <h4>Malware and Viruses</h4>
                <p>
                  Scammers may send emails posing as immigration officials or
                  service providers, requesting personal information or payment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Frauds_set4 text-center">
        <div class="container">
          <div
            class="setain "
            style={{ background: "url(../assets/images/WarningSigns.svg)" }}
          >
            <h2>
              Warning Signs That You Must Notice To Avoid Immigration Scams
            </h2>

            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h3 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Guaranteed Results Claims
                  </button>
                </h3>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      This is the third item's accordion body. It is hidden by
                      default, until the collapse plugin adds the appropriate
                      classes that we use to style each element. These classes
                      control the overall appearance, as well as the showing and
                      hiding via CSS transitions. You can modify any of this
                      with custom CSS or overriding our default variables. It's
                      also worth noting that just about any HTML can go within
                      the .accordion-body, though the transition does limit
                      overflow.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h3 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Requests for advance payments
                  </button>
                </h3>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      This is the third item's accordion body. It is hidden by
                      default, until the collapse plugin adds the appropriate
                      classes that we use to style each element. These classes
                      control the overall appearance, as well as the showing and
                      hiding via CSS transitions. You can modify any of this
                      with custom CSS or overriding our default variables. It's
                      also worth noting that just about any HTML can go within
                      the .accordion-body, though the transition does limit
                      overflow.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Lack of qualifications
                  </button>
                </h3>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      This is the third item's accordion body. It is hidden by
                      default, until the collapse plugin adds the appropriate
                      classes that we use to style each element. These classes
                      control the overall appearance, as well as the showing and
                      hiding via CSS transitions. You can modify any of this
                      with custom CSS or overriding our default variables. It's
                      also worth noting that just about any HTML can go within
                      the .accordion-body, though the transition does limit
                      overflow.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="headingThree1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree1"
                    aria-expanded="false"
                    aria-controls="collapseThree1"
                  >
                    Offers to falsify data
                  </button>
                </h3>
                <div
                  id="collapseThree1"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree1"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      This is the third item's accordion body. It is hidden by
                      default, until the collapse plugin adds the appropriate
                      classes that we use to style each element. These classes
                      control the overall appearance, as well as the showing and
                      hiding via CSS transitions. You can modify any of this
                      with custom CSS or overriding our default variables. It's
                      also worth noting that just about any HTML can go within
                      the .accordion-body, though the transition does limit
                      overflow.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="headingThree2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree2"
                    aria-expanded="false"
                    aria-controls="collapseThree2"
                  >
                    Pressure techniques
                  </button>
                </h3>
                <div
                  id="collapseThree2"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree2"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      This is the third item's accordion body. It is hidden by
                      default, until the collapse plugin adds the appropriate
                      classes that we use to style each element. These classes
                      control the overall appearance, as well as the showing and
                      hiding via CSS transitions. You can modify any of this
                      with custom CSS or overriding our default variables. It's
                      also worth noting that just about any HTML can go within
                      the .accordion-body, though the transition does limit
                      overflow.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree3"
                    aria-expanded="false"
                    aria-controls="collapseThree3"
                  >
                    Absence of a written agreement or contract
                  </button>
                </h3>
                <div
                  id="collapseThree3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree3"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      This is the third item's accordion body. It is hidden by
                      default, until the collapse plugin adds the appropriate
                      classes that we use to style each element. These classes
                      control the overall appearance, as well as the showing and
                      hiding via CSS transitions. You can modify any of this
                      with custom CSS or overriding our default variables. It's
                      also worth noting that just about any HTML can go within
                      the .accordion-body, though the transition does limit
                      overflow.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Frauds_set2  Frauds_set5 text-center">
        <div class="container">
          <div class="setain ">
            <h2>How to Prevent Yourself From Immigration Scam</h2>
            <p class="several">
              Several immigration scams target unsuspecting people looking to
              start a new life in Canada, the United States, or any other
              foreign country. Many of these scams are perpetrated by unskilled
              consulting firms. Here is what you can do to protect yourself from
              the immigration scam
            </p>
            <div class="row">
              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div
                    class="img1"
                    style={{
                      background: "url(../assets/images/preventimg1.png)",
                    }}
                  ></div>
                  <h2>Do Your Research</h2>
                  <p>
                    There are numerous reputable immigration advisors available.
                    Before agreeing to collaborate with someone, conduct some
                    research on them. There are also numerous online resources
                    available to help you learn more about the immigration
                    process.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div
                    class="img1"
                    style={{
                      background: "url(../assets/images/preventimg2.png)",
                    }}
                  ></div>
                  <h2>Sign a Written Contract</h2>
                  <p>
                    Once you have chosen a consultant with whom you feel
                    comfortable, get everything in writing. It covers their
                    expenses, services, and any assurances they make.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div
                    class="img1"
                    style={{
                      background: "url(../assets/images/preventimg3.png)",
                    }}
                  ></div>
                  <h2>Be Skeptical About Guarantees</h2>
                  <p>
                    You must avoid any expert who guarantees results or offers a
                    quick path to any country's citizenship. There are no
                    guarantees when it comes to immigration law.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div
                    class="img1"
                    style={{
                      background: "url(../assets/images/preventimg4.png)",
                    }}
                  ></div>
                  <h2>Check Their Certifications</h2>
                  <p>
                    Check that the specialist you are working with has the
                    necessary government of Canada accreditation and license.
                    You can check their online reviews and whether they are
                    registered or not.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div
                    class="img1"
                    style={{
                      background: "url(../assets/images/preventimg5.png)",
                    }}
                  ></div>
                  <h2>Get a Second Opinion</h2>
                  <p>
                    If something does not feel right, do not hesitate to seek
                    the advice of another reliable source. A lawyer, accountant,
                    or even a family member or acquaintance with knowledge of
                    the immigration process could be useful.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Frauds_set6">
        <div class="container">
          <div class="setain ">
            <div class="row">
              <div
                class="col-md-5  text-center"
                style={{
                  alignItems: "end",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="../../assets/images/Immigrationimg.svg"
                  alt="Immigrationimg"
                />
              </div>

              <div class="col-md-7">
                <h2 class="text-end">
                  Immigration Fraud Prevention Tips For Students
                </h2>
                <ul>
                  <li>
                    Before hiring an immigration consultant or lawyer, make sure
                    they have the proper license and authorization
                  </li>
                  <li>
                    Examine their qualifications and confirm their background.
                    At the same time, to assess the consultant or agency's
                    reputation and legitimacy, look for online reviews and
                    endorsements from previous clients.
                  </li>
                  <li>
                    Visit official government websites and resources for
                    detailed information on immigration requirements and
                    processes.
                  </li>
                  <li>
                    You must avoid consultants who charge fees that are
                    significantly higher than the typical costs associated with
                    filing immigration applications.
                  </li>
                  <li>
                    If something seems strange or too good to be true, trust
                    your instincts and investigate further.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Frauds_set7 text-center">
        <div class="container">
          <h2>What Happens If You Fall for the Immigration Scam?</h2>
          <p>
            Falling victim to an immigration scam may have serious consequences.
            The first and most obvious outcome is financial loss. Additionally,
            visa consultants may charge you thousands of rupees before
            determining that you are ineligible for immigration to any foreign
            country.
          </p>
          <p>
            Falling victim to an immigration scam can also cause emotional
            distress. Even in ideal circumstances, immigration to foreign
            countries can be tense. It is normal to feel disappointed or
            betrayed after falling victim to a scam. If this is the case, your
            chances of acceptance into an immigrant program may be reduced.
            Finally, they may have revealed your sensitive information. This
            could lead to identity theft and other complications.
          </p>
        </div>
      </section>

      <section class="Frauds_set2 Frauds_set8 text-center">
        <div class="container">
          <div class="setain ">
            <h2>
              Reporting Immigration Scams: How Do I Report Fraud or Abuse In
              India?
            </h2>
            <p>
              Reporting immigration fraud is critical for protecting yourself
              and others from financial loss and preventing future fraudulent
              activity. In addition to helping you stay safe, reporting fraud
              also helps stop others from becoming victims of shady schemes.
            </p>
            <div class="row text-start">
              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting1.png" alt="Types" />
                  </div>
                  <h3>Contact Authorities</h3>
                  <p>
                    You can report it to the local police station or cybercrime
                    division immediately.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting2.png" alt="Types" />
                  </div>
                  <h3>Government Agencies</h3>
                  <p>
                    Inform relevant government agencies such as the National
                    Consumer Helpline, or the Cyber Crime Investigation Cell.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting3.png" alt="Types" />
                  </div>
                  <h3>Consumer Protection Agencies</h3>
                  <p>
                    Contact consumer protection agencies, such as the Consumer
                    Affairs Department, to report the scam and seek help
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting4.png" alt="Types" />
                  </div>
                  <h3>Immigration Authorities</h3>
                  <p>
                    If the scam involves the impersonation of immigration
                    officials, notify the appropriate authorities.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting5.png" alt="Types" />
                  </div>
                  <h3>Online Platform</h3>
                  <p>
                    Report phising emails to your emails service provider (such
                    as Gmail or Outlook) and mark them as spam.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting6.png" alt="Types" />
                  </div>
                  <h3>Financial Institutions</h3>
                  <p>
                    If you are a victim of financial fraud, contact your bank or
                    financial institution to report the scam
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 mb-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Reporting7.png" alt="Types" />
                  </div>
                  <h3>Report on Chownk</h3>
                  <p>
                    On our platform, you can report immigration scam by clicking
                    the report now button.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Frauds_set9 ">
        <div class="container">
          <div
            class="setain "
            style={{ background: `url(../../assets/images/Immigrationbg.svg)` }}
          >
            <div class="text-center">
              <img
                class="logo"
                src="../../assets/images/logo_white.png"
                alt=""
              />
              <h2>How Chownk Can Protect You To Being Scammed</h2>
            </div>

            <div class="row">
              <div class="col-sm-6 col-lg-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Being1.png" alt="Types" />
                  </div>
                  <h3>Easy registration Process</h3>
                  <p>
                    To use and register with our platform, you only need to give
                    a phone number and then you have to type OTP that you
                    receive, that it.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="set set2">
                  <div class="img">
                    <img src="../../assets/images/Being2.png" alt="Types" />
                  </div>
                  <h3>Expert Guidance</h3>
                  <p>
                    Our consultants and trainers offer expert guidance and
                    advice to help you confidently search the immigration
                    process or prepare for language exams.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="set">
                  <div class="img">
                    <img src="../../assets/images/Being3.png" alt="Types" />
                  </div>
                  <h3>Connect with Immigration Consultants</h3>
                  <p>
                    We can connect you up with experts who know all about study
                    visas and can help you get one.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="set set3">
                  <div class="img">
                    <img src="../../assets/images/Being4.png" alt="Types" />
                  </div>
                  <h3>Expert Educational Trainers</h3>
                  <p>
                    Here, you can get help from teachers who are really good at
                    teaching English and can help you pass tests like IELTS,
                    TOEFL, or PTE, which you might need for your study visa.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="set setnone">
                  <div class="img">
                    <img src="../../assets/images/Being5.png" alt="Types" />
                  </div>
                  <h3>Secure Platform</h3>
                  <p>
                    Our platform is safe and secure, with measures in place to
                    protect your personal information and documents from scams
                    or fraud.
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3"></div>
              <div class="col-sm-6 col-lg-3">
                <div class="set set4">
                  <div class="img">
                    <img src="../../assets/images/Being6.png" alt="Types" />
                  </div>
                  <h3>Verified Consultants</h3>
                  <p>
                    We only work with verified immigration consultants and
                    educational trainers, so you can trust that you're getting
                    help from legitimate professionals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StudentFooter />
    </div>
  );
};

export default PreventFraud;
