import React from "react";
import Button from "../../Tools/Button";
import FormInputField from "../../Tools/Forminput";
import { countMinusWithInputValue } from "../../../services/function";
import { useForm } from "react-hook-form";

const EditAgentProfileFaqModel = ({
  onHide,
  data,
  setFaqData,
  index,
  updateProfileFun,
}) => {
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  console.log("data[index].question.length", data[index].question.length);
  const [input, setInput] = React.useState({});
  const [count, setCount] = React.useState({
    title: data[index].question ? data[index].question.length : 0,
    description: data[index].answer ? data[index].answer.length : 0,
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "question") {
      if (e.target.value.length > 60) {
        setError("question", {
          type: "custom",
          message: "Minimum length 60 words only",
        });
      } else {
        clearErrors("question");
        setInput({ ...input, [name]: value });
      }
      console.log("e.target.value.length", e.target.value.length);
      setCount((prev) => ({
        ...prev,
        title: e.target.value.length,
      }));
    }
    if (e.target.name === "answer") {
      if (e.target.value.length > 100) {
        // console.log("descriptionRef.current", descriptionRef.current);
        setError("answer", {
          type: "custom",
          message: "Minimum length 500 words only",
        });
      } else {
        clearErrors("answer");
        setInput({ ...input, [name]: value });
      }
      setCount((prev) => ({
        ...prev,
        description: e.target.value.length,
      }));
    }
  };

  const editFaqModel = (index) => {
    if (input.question === "" && input.answer === "") {
      setError("question", {
        type: "custom",
        message: "Please fill the field",
      });
      setError("answer", {
        type: "custom",
        message: "Please fill the field",
      });
    } else if (input.question === "") {
      setError("question", {
        type: "custom",
        message: "Please fill the field",
      });
    } else if (input.answer === "") {
      setError("answer", {
        type: "custom",
        message: "Please fill the field",
      });
    } else if (errors === undefined || Object.keys(errors).length === 0) {
      clearErrors();
      data[index].question = input.question
        ? input.question
        : data[index].question;
      data[index].answer = input.answer ? input.answer : data[index].answer;
      setFaqData(data);
      let formData = new FormData();
      formData.append("faq", JSON.stringify(data));
      updateProfileFun(formData);
      onHide();
    }

    console.log(
      "Object.keys(errors).length < 0",
      Object.keys(errors).length === 0
    );
  };
  console.log("errorssss", errors);
  return (
    <>
      <div className="modal-content">
        <Button inType={"button"} style={"btn-close"} onClick={onHide} />
        <div className="modal-body p-0">
          <div className="row">
            <form>
              <h5>Update FAQ</h5>
              <p>
                Integrating Frequently Asked Questions (FAQs) into your Profile
                Page.
              </p>
              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={"Title"}
                name={"question"}
                inType={"text"}
                onChange={handleInput}
                errors={errors}
                defaultValue={data && data.length > 0 && data[index]?.question}
              />
              <p id="title_count" className="characters">
                {countMinusWithInputValue(60, count.title)} Characters
              </p>
              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={"Description"}
                name={"answer"}
                inType={"text"}
                onChange={handleInput}
                errors={errors}
                defaultValue={data && data.length > 0 && data[index]?.answer}
              />
              <p id="title_count" className="characters">
                {countMinusWithInputValue(100, count.description)} Characters
              </p>

              <Button
                style={"me-2"}
                inType={"button"}
                title={"Save Faq"}
                onClick={() => editFaqModel(index)}
              />
              <Button inType={"button"} title={"Cancel"} onClick={onHide} />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAgentProfileFaqModel;
