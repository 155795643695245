const ConfirmationBox = ({ cutt, onClose }) => {
  return (
    <div className="custom-ui">
      <img src="../../../assets/images/alrticon.png" />
      <h1>Are you sure?</h1>
      <p>You want to Disconnect the Call ?</p>
      <button
        className="yes"
        onClick={() => {
          cutt();
          onClose();
        }}
      >
        Yes
      </button>
      <button onClick={onClose}>No</button>
    </div>
  );
};
export default ConfirmationBox;
