import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../Tools/Button";
import FormInputField from "../../Tools/Forminput";
import { countMinusWithInputValue } from "../../../services/function";

const Applicationpopup = ({ onHide, data, setFaqData }) => {
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [input, setInput] = React.useState({});
  const [count, setCount] = React.useState({
    title: 0,
    description: 0,
  });
  // const handleInput = (e) => {
  //     const { name, value } = e.target;
  //     setInput({ ...input, [name]: value });
  // }
  const handleInput = (e) => {
    const { name, value } = e.target;
    // setInput({ ...input, [name]: value });
    if (name === "question") {
      // console.log("titleRef.current", titleRef.current);
      if (e.target.value.length > 60) {
        setError("question", {
          type: "custom",
          message: "Minimum length 60 words only",
        });
      } else {
        clearErrors("question");
        setInput({ ...input, [name]: value });
      }
      console.log("e.target.value.length", e.target.value.length);
      setCount((prev) => ({
        ...prev,
        title: e.target.value.length,
      }));
    }
    if (e.target.name === "answer") {
      if (e.target.value.length > 100) {
        // console.log("descriptionRef.current", descriptionRef.current);
        setError("answer", {
          type: "custom",
          message: "Minimum length 100 words only",
        });
      } else {
        clearErrors("answer");
        setInput({ ...input, [name]: value });
      }
      setCount((prev) => ({
        ...prev,
        description: e.target.value.length,
      }));
    }
  };
  const handleFaqModel = () => {
    if (input.question === "" && input.answer === "") {
      setError("question", {
        type: "custom",
        message: "Please fill the field",
      });
      setError("answer", {
        type: "custom",
        message: "Please fill the field",
      });
    } else if (input.question === "") {
      setError("question", {
        type: "custom",
        message: "Please fill the field",
      });
    } else if (input.answer === "") {
      setError("answer", {
        type: "custom",
        message: "Please fill the field",
      });
    } else {
      clearErrors();
      setFaqData({
        review1: input.question,
        review2: input.answer,
      });
      onHide();
    }
  };

  return (
    <>
      <div className="modal-content">
        <Button inType={"button"} style={"btn-close"} onClick={onHide} />
        <div className="modal-body p-0">
          <div className="row">
            <form>
              <h5>{"Add Review"}</h5>
              <p>
                {
                  "Integrating Frequently Asked Questions for your Application Status."
                }
              </p>
              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={"Please review the application status?"}
                name={"question"}
                inType={"text"}
                errors={errors}
                onChange={handleInput}
              />
              <p id="title_count" className="characters">
                {countMinusWithInputValue(60, count.title)} Characters
              </p>
              <FormInputField
                parentStyle={"form-group"}
                childStyle={"form-control"}
                label={"Please recommend application Process"}
                name={"answer"}
                inType={"text"}
                errors={errors}
                onChange={handleInput}
              />
              <p id="title_count" className="characters">
                {countMinusWithInputValue(100, count.description)} Characters
              </p>

              <Button
                style={"me-2"}
                inType={"button"}
                title={"Add Review"}
                onClick={handleFaqModel}
              />
              <Button inType={"button"} title={"Cancel"} onClick={onHide} />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Applicationpopup;
