import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import EditProfileForm from "./editProfileForm";
import ProfilePicture from "./profilePicture";
import { mediaUrl } from "../../../../services/network";
import { editProfileSchema } from "../../../../services/schema";
import {
  setProfileUpdateSuccess,
  setUserDetails,
  studentProfileUpdate,
} from "../../../../Reducer/authSlice";
import { getUserProfileDataApi } from "../../../../services/api";

const ProfileWithForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(editProfileSchema),
  });
  const selector = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [file, setFile] = React.useState();
  const [address, setAddress] = React.useState(selector.userDetails?.address);
  const [error, setError] = React.useState({});
  const [preview, setPreview] = React.useState("");
  const [editProfile, setEditProfile] = React.useState(true);

  React.useEffect(() => {
    if (selector.profileUpdateSuccess) {
      toast.success("Profile Updated", {
        position: toast.POSITION.TOP_LEFT,
      });
      dispatch(setProfileUpdateSuccess(false));
    }
    if (id == selector.auth?.id) {
      setValue("name", selector.auth?.name);
      setValue("phone", selector.auth?.phone);
      setValue("email", selector.auth?.email);
      setAddress(
        selector.auth.permanent_address
          ? selector.auth.permanent_address
          : address
      );
      setPreview(mediaUrl + selector.auth?.profile_image);
    } else {
      getUserProfileFun(id);
    }
  }, [selector.profileUpdateSuccess]);

  const getUserProfileFun = async (id) => {
    try {
      getUserProfileDataApi({ id: id })
        .then((response) => {
          const result = response.data;
          if (result.status === "success") {
            setValue("name", result.data?.name);
            setValue("phone", result.data?.phone);
            setValue("email", result.data?.email);
            setAddress(
              result.data.current_adress
                ? result.data.current_adress
                : selector.auth.permanent_address
            );
            setPreview(mediaUrl + result.data.profile_image);
            // toast.success("Profile Successfully Updated", {
            //   position: toast.POSITION.TOP_LEFT,
            // });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    } catch (e) {}
  };

  const onSubmit = (data) => {
    try {
      if (!address) {
        setError((prev) => ({
          ...prev,
          address: {
            message: "Please fill the field",
          },
        }));
      } else {
        let formData = new FormData();
        formData.append("name", data.name);
        if (data.email) {
          formData.append("email", data.email);
        }
        if (data.address) {
          formData.append("current_address", address);
        }
        if (file) {
          formData.append("profile_image", file ? file : "");
          formData.append("OldImage", selector.auth?.profile_image);
        } else {
          formData.append("OldImage", "");
        }

        dispatch(studentProfileUpdate(formData));
        // setEditProfile(!editProfile);
      }
    } catch (e) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const imageHandle = (e) => {
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png"
    ) {
      setFile(e.target.files[0]);
    } else {
      setError((prev) => ({
        ...prev,
        file: {
          message: "Image Only Accept jpg, jpeg and png",
        },
      }));
    }
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleChange = (e) => {
    setAddress(e.label);
    dispatch(
      setUserDetails({
        address: e.label,
      })
    );
  };

  return (
    <section className="my_profile">
      <div className="my_profile_banner"></div>
      <div className="container">
        <div className="row">
          <ProfilePicture
            imageHandle={imageHandle}
            image={
              preview
                ? preview
                : mediaUrl && mediaUrl + selector?.auth?.profile_image
            }
          />
          {error && error.file && (
            <p style={{ color: "red" }}>{error.file.message}</p>
          )}
          <div className="col-sm-8">
            <EditProfileForm
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              address={address}
              handleCurrentAddress={handleChange}
              addressError={error}
              userId={id}
              editProfile={editProfile}
              setEditProfile={setEditProfile}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProfileWithForm;
