import { Link } from "react-router-dom";
const HeaderLogo = ({ parentStyle, imageSrc, handleUserNavigate }) => {
  return (
    <div className={parentStyle}>
      <Link
        to={"/"}
        onClick={() => handleUserNavigate("navigate", "/", "header logo")}
      >
        <img src={imageSrc} alt="CHOWNK logo" />
      </Link>
    </div>
  );
};
export default HeaderLogo;
