import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { IoMdArrowBack } from "react-icons/io";
import ProfileButton from "../../Tools/ProfileButton";
import RowInput from "../../Tools/RowInput";
import OTPTimer from "../../Tools/Otptimer";
import RowImage from "../../Tools/RowImage";
import {
  branchPhoneVerification,
  phoneVerification,
} from "../../../Reducer/authSlice";
import SimpleInput from "../../Tools/SimpleInput";
import { addStaffApi, checkBranchApi, emailCheck } from "../../../services/api";
import { googleMapApi } from "../../../data/data";
import { addStaffFormSchema, emailregExp } from "../../../services/schema";
import Button from "../../Tools/Button";
import { Link } from "react-router-dom";
import LabelInputBox from "../../Tools/LabelInputBox";

const AddStaffForm = ({ show, heading, setShow }) => {
  const selector = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [ootp, setOotp] = React.useState("");
  const [otpProcess, setOtpProcess] = React.useState(false);
  const [availablePhone, setAvailablePhone] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [otpButtonHide, setOtpButtonHide] = React.useState(false);
  const [otpProcesss, setotpProcesss] = React.useState(false);
  const [otpButtonHideOne, setOtpButtonHideOne] = React.useState(false);
  const [emailAvailable, setEmailAvailable] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(addStaffFormSchema),
  });

  const phoneCheckHandle = (e) => {
    if (isNaN(e.target.value)) {
      setError("phone", { type: "custom", message: "Not use the aplhabet" });
    } else if (e.target.value.length > 10) {
      setError("phone", {
        type: "custom",
        message: "phone must be at most 10 characters",
      });
    } else {
      clearErrors("phone");
    }
    if (e.target.value.length === 10 && !isNaN(e.target.value)) {
      setMessage("");
      clearErrors("phone");
      setAvailablePhone(false);
      setPhoneNumber(e.target.value);
      checkBranchApi({ phone: e.target.value })
        .then((response) => {
          if (response.data.available) {
            clearErrors("phone");
            setMessage(response.data.message);
            setOtpButtonHideOne(true);
            setOtpButtonHide(false);
            setAvailablePhone(response.data.available);
          } else {
            setAvailablePhone(response.data.available);
            setError("phone", {
              type: "custom",
              message: response.data.message,
            });
          }
        })
        .catch((e) => {
          setAvailablePhone(e.response.data.available);
          setError("phone", {
            type: "custom",
            message: e.response.data.message,
          });
        });
    }
    setAvailablePhone(false);
  };

  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };
  const onResendFun = () => {
    const bodyy = {
      phone: phoneNumber,
      latitude: selector.userDetails?.latitude,
      longitude: selector.userDetails?.longitude,
      ip_address: selector.userDetails?.ip_address,
      current_address: selector.userDetails?.current_address,
    };
    dispatch(branchPhoneVerification(bodyy));
  };

  const onSubmit = (data) => {
    if (ootp) {
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append(
        "permanent_address",
        data.permanent_address.formatted_address
      );
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("otp", ootp);
      if (data.profile_image.length > 0) {
        formData.append("profile_image", data.profile_image[0]);
      }

      formData.append(
        "latitude",
        selector.userDetails && selector.userDetails.latitude
      );
      formData.append(
        "longitude",
        selector.userDetails && selector.userDetails.longitude
      );
      addStaffApi(formData)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_LEFT,
            });
            setShow(false);
            setOtpProcess(!otpProcess);
            setAvailablePhone(!availablePhone);
            setOotp("");
            reset();

            delete data.profile_image;
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_LEFT,
            });
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    } else {
      toast.error("Please enter the otp", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const handleEmailChange = async (e) => {
    try {
      const { value } = e.target;
      if (value.match(emailregExp)) {
        const response = await emailCheck(value);
        if (response.data.status === true) {
          setEmailAvailable(true);
          clearErrors("email");
        } else {
          setEmailAvailable(false);
          setError("email", { type: "custom", message: response.data.message });
        }
      } else {
        setEmailAvailable(false);
      }
    } catch (error) {
      setEmailAvailable(false);
      setError("email", { type: "custom", message: "Enter a Valid Email" });
    }
  };

  return (
    <div className={`Add_New_Branch ${show && "d-none"}`}>
      <h3>
        {" "}
        <Link onClick={() => setShow(show)} href="#" className="back-button">
          <i className="bi bi-arrow-left"></i>
        </Link>
        {heading}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RowInput
          parentStyle={"row mt-4"}
          labelStyle={"col-md-5"}
          label={"Full Name"}
          labelDescription={"Add Full Name"}
          childStyle={"col-md-7"}
          inType={"text"}
          name={"name"}
          event={register}
          errors={errors}
          placeholder={"Enter full name"}
        />
        <LabelInputBox
          label={"Email"}
          labelDescription={"Add your email"}
          style={"form-control"}
          event={register}
          error={errors}
          name={"email"}
          placeholder={"Enter Email Address"}
          availableIcon={emailAvailable}
          available={emailAvailable}
          onChange={handleEmailChange}
          message={"message"}
          parentCol={"col-md-5"}
          childCol={"col-md-7"}
        />
        {/* <RowInput
          parentStyle={"row mt-4"}
          labelStyle={"col-md-5"}
          label={"Permanent Address"}
          labelDescription={
            "Customize your security profile name and URL to share with vendors."
          }
          childStyle={"col-md-7"}
          inType={"text"}
          name={"permanent_Address"}
          placeholder={"Enter permanent address"}
        /> */}
        <div className={"row mt-4"}>
          <div className={"col-md-5"}>
            <h5>{"Permanent Address"}</h5>
            <p className="label">{"Add Permanent Address"}</p>
          </div>
          <div className={"col-md-7"}>
            <Controller
              control={control}
              name="permanent_address"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactGoogleAutocomplete
                  apiKey={googleMapApi}
                  onPlaceSelected={onChange}
                  options={{
                    types: ["geocode"],
                    componentRestrictions: { country: "in" },
                  }}
                />
              )}
            />

            {errors && errors.permanent_address && (
              <p style={{ color: "red" }}>{errors.permanent_address.message}</p>
            )}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-5">
            <h5>Mobile Number</h5>
            <p className="label">Add Mobile Number</p>
          </div>
          <div className="col-md-7">
            <div className="form-control">
              <div className="row">
                <div className="col-md-12">
                  <SimpleInput
                    style={"form-control"}
                    inType={"text"}
                    name={"phone"}
                    event={register}
                    placeholder={"Enter Mobile Number"}
                    onChange={phoneCheckHandle}
                  />

                  {otpButtonHideOne && (
                    <span
                      style={{
                        cursor:
                          selector.phoneNumber && availablePhone
                            ? "pointer"
                            : "",
                        color:
                          selector.phoneNumber && availablePhone
                            ? ""
                            : "#E3DAD8",

                        display: availablePhone ? "block" : "none",
                      }}
                      className={"sendotp"}
                      onClick={() => {
                        if (availablePhone) {
                          const bodyy = {
                            phone: phoneNumber,
                            latitude: selector.userDetails?.latitude,
                            longitude: selector.userDetails?.longitude,
                            ip_address: selector.userDetails?.ip_address,
                            current_address:
                              selector.userDetails?.current_address,
                          };
                          dispatch(branchPhoneVerification(bodyy));
                          setMessage("Otp send");
                          setotpProcesss(true);
                          setOtpButtonHide(!otpButtonHide);
                          setOtpButtonHideOne(false);
                          // setDuration(60);
                        }
                      }}
                    >
                      Send OTP
                    </span>
                  )}
                </div>
              </div>
            </div>
            {availablePhone && <p style={{ color: "green" }}>{message}</p>}
            {errors && errors.phone && (
              <p className="arr" style={{ color: "red" }}>
                <i>
                  <i className="bi bi-info-circle-fill"></i>{" "}
                  {errors.phone.message}
                </i>
              </p>
            )}

            {otpButtonHide && otpProcesss && availablePhone && (
              <div className="form-groupotp">
                <label className="d-block text-start">
                  Enter OTP {selector.otp}
                </label>
                <OTPInput
                  className={"form-control"}
                  inputStyle={{ width: "40px" }}
                  value={ootp}
                  onChange={setOotp}
                  numInputs={4}
                  inputType={"tel"}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                <OTPTimer
                  duration={120}
                  onTimeout={handleOTPTimerTimeout}
                  onResend={onResendFun}
                />
                {selector.otpSuccess == false &&
                  selector.error &&
                  selector.errorPosition == "otp" && (
                    <p className="arr">
                      <i>
                        <i className="bi bi-info-circle-fill"></i>{" "}
                        {selector.error}
                      </i>
                    </p>
                  )}

                {/* <input className="form-control" type="text" placeholder="*" />
                        <input className="form-control" type="text" placeholder="*" />
                        <input className="form-control" type="text" placeholder="*" />
                        <input className="form-control" type="text" placeholder="*" />
                        <input className="form-control" type="text" placeholder="*" />
                        <input className="form-control" type="text" placeholder="*" /> */}
              </div>
            )}
          </div>
        </div>

        <RowImage
          parentStyle={"row mt-4"}
          labelStyle={"col-md-5"}
          label={"Profile Image"}
          labelDescription={"Upload Profile image "}
          childStyle={"col-md-7"}
          name={"profile_image"}
          event={register}
          errors={errors}
          inputStyle={"file"}
          accept={"image/jpeg, image/png"}
        />
        <ProfileButton
          parentStyle={"text-end mt-5"}
          inType={"submit"}
          label={"Add Staff"}
        />
      </form>
    </div>
  );
};

export default AddStaffForm;
