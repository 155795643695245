import React from "react";
import { Link } from "react-router-dom";
import { blogsMediaUrl } from "../../../../../services/network";

const Banner = ({
  backgroundImage,
  title,
  category,
  subCategory,
  authorImage,
  authorName,
  publishDate,
  createdDate,
  updateDate,
}) => {
  const convert =
    publishDate > updateDate || updateDate === createdDate
      ? publishDate
      : updateDate;
  const date = new Date(convert * 1000).toDateString().split(" ");

  return (
    <section className="single_blogs mb-5">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/blogs">Blogs</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {title}
            </li>
          </ol>
        </nav>

        {/* <h6 className="bread_crumb">
          <Link to="/blogs">Blogs</Link> &gt;
          {title}
        </h6> */}
        <div className="row">
          <div className="col-sm-6 text-start">
            <h1 className="text-center">{title}</h1>

            <p>
              <span
                style={{
                  backgroundImage: `url(${blogsMediaUrl}${authorImage})`,
                }}
              ></span>
              {` ${authorName} // ${category} // ${
                publishDate > updateDate || updateDate === createdDate
                  ? "Published on"
                  : "Updated on"
              } ${date[1]} ${date[2]} ${date[3]}`}
            </p>
          </div>
          <div className="col-sm-6 text-end">
            <img
              className="sungimg"
              src={`${blogsMediaUrl}${backgroundImage}`}
              alt={`${title}-Chownk`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
