const NavItem = ({
  active,
  id,
  title,
  onClick,
  iconStyle,
  insideButton,
  imageSrc,
}) => {
  return (
    <li className="nav-item" role="presentation" onClick={onClick}>
      <button className={`nav-link ${active}`} id={id} type="button">
        {insideButton === "image" ? (
          <img src={imageSrc} alt="user" />
        ) : (
          <i className={iconStyle}></i>
        )}
        {title}
      </button>
    </li>
  );
};
export default NavItem;
