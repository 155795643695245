import ToolGrowsBox from "../../../Tools/ToolGrowsBox";

const ToolGrows = ({ toolGrowsData }) => {
  return (
    <section className="tools_grow">
      <div className="container">
        <div className="head mb-4 mb-xl-5">
          <h2>
            <span>{"Tools"}</span> {"to grow faster"}
          </h2>
          <p>
            {
              "There are several factors that contribute to your growth on our platform."
            }
          </p>
        </div>
        <div className="row">
          {toolGrowsData.length > 0 &&
            toolGrowsData.map((item) => {
              return (
                <ToolGrowsBox
                  parentStyle={"col-md-6 col-xl-4 mb-3 mb-lg-4"}
                  childStyle={"set"}
                  imgSrc={item.imgSrc}
                  title={item.title}
                  descripiton={item.descripition}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ToolGrows;
