import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportCategories } from "../../../Reducer/afterSearchSlice";

const ReportModel = ({ reportShow, setReportShow, onHide }) => {
  const dispatch = useDispatch();
  const { report_categories } = useSelector((state) => state.afterSearch);
  useEffect(() => {
    dispatch(getReportCategories());
  }, []);

  return (
    <div className="modal-body p-0">
      <div className="Account_Settings mt-0">
        <h4 className="mb-0">Report Profile </h4>
        {report_categories &&
          report_categories.length > 0 &&
          report_categories.map((x, i) => {
            return (
              <div className="stp">
                <input type="radio" name="report" value={x.id} />
                <h6>{x.category}</h6>
              </div>
            );
          })}
        <div className="stp others">
          <h6>Others</h6>
          <textarea></textarea>
        </div>

        <div className="back_btn_pop">
          <button type="button" className="btn btn-primary">
            Report
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => onHide()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModel;
