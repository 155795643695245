import React, { useContext, useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { SocketContext } from "../../../../../Context";
import ReactTimeAgo from "react-time-ago";
import { mediaUrl } from "../../../../../services/network";
import Example from "../../../../Tools/ReactBoostrap";
import ChatImageAndVideoPreviewModel from "../../../../Layouts/Model/chatImageAndVideoPreviewModel";
// import SearchBarWithIcon from "../../../../Tools/SearchBarWithIcon"
import moment from "moment";
import { useSelector } from "react-redux";
import $ from "jquery";
import { chatSearchApi } from "../../../../../services/api";
import { ImCross } from "react-icons/im";
import SimpleInput from "../../../../Tools/SimpleInput";
import { downloadMedia } from "../../../../../services/function";

function RightSidebar({
  page,
  setPage,
  searching,
  setSearching,
  msgId,
  setMsgId,
}) {
  const [imageVideoShow, setImageVideoShow] = React.useState(false);
  const {
    sidebarImages,
    sidebarFile,
    currentUser,
    show,
    setShow,
    offer,
    setOffer,
  } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);
  const [dataa, setDataa] = React.useState(false);
  const [getSearchInputData, setGetSearchInputData] = useState();
  const [searchData, setSearchData] = useState();
  const [searchInput, setSearchInput] = useState("");

  const handleChatSearchBar = (e) => {
    if (e.target.value == "") {
      setSearchInput(e.target.value);
      setSearchData([]);
    } else {
      setGetSearchInputData(e.target.value);
      setSearchInput(e.target.value);
      const body = {
        id: selector && selector.auth && selector.auth.uid,
        selectionId: currentUser.connection_user,
        search: e.target.value,
      };
      chatSearchApi(body)
        .then((response) => {
          if (response.data.status) {
            setSearchData(response.data.data);
          }
        })
        .catch((e) => console.log("error", e));
    }
  };

  return (
    <div className="chat_right">
      <div className="search-data-box">
        <i
          onClick={() => {
            $(".search-data-box").removeClass("main");
            $(".chat_right").removeClass("main");
          }}
          className="bi bi-arrow-left-short"
        ></i>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SimpleInput
            inType={"text"}
            name={"search"}
            onChange={handleChatSearchBar}
            value={searchInput}
            placeholder={"Search chat message"}
          />
          {searchInput && (
            <>
              <ImCross
                onClick={() => {
                  setSearchData([]);
                  setSearchInput("");
                }}
              />
            </>
          )}
        </form>
        {searchData && searchData.length > 0 ? (
          searchData.map((i) => {
            return (
              <>
                <div
                  className="profile_data"
                  onClick={() => {
                    setPage(i.page);
                    setSearching(true);
                    setMsgId(i.id);
                  }}
                >
                  <img src={mediaUrl + i.sender_image} alt="" />
                  <h3>{i.sender_name}</h3>
                  <p>{i.message}</p>
                  <span>{moment(i.created_date).format("DD/MMM/YYYY")}</span>
                </div>
              </>
            );
          })
        ) : (
          <div className="search_image">
            <img
              className="search_image"
              src="assets/images/image_search.png"
            />
            <h3>
              {searchInput != "" ? "NO Data Found" : "Search Message Here"}
            </h3>
          </div>
        )}
      </div>
      <div
        className={`ct_offer ${
          currentUser?.started_by !== selector.auth.uid ? "with_create" : ""
        }`}
      >
        {currentUser?.started_by !== selector.auth.uid && (
          <a onClick={() => setShow(!show)} className="Create_Offer">
            <img src="assets/images/per.png" alt="per" /> Create Offer
          </a>
        )}
        <div className="ct_offerin">
          <div
            onClick={() => {
              $(".ct_offer").removeClass("main");
            }}
          >
            <i className="bi bi-arrow-left-short"></i>
          </div>
          {currentUser.dynamic_image === 1 ? (
            <div className="chatuser">
              {currentUser.name.toUpperCase().charAt(0)}
            </div>
          ) : (
            <div
              className="chatuser"
              style={{
                backgroundImage: `url(${
                  mediaUrl +
                  `${
                    currentUser.userRole === "student"
                      ? currentUser.image
                      : currentUser.company_logo
                  }`
                })`,
              }}
            ></div>
          )}
          <h6 className="text-center mt-2">{`${
            currentUser.name ? currentUser.name : "CHOWNK USER"
          } ${
            (currentUser.userRole === "branch" &&
              currentUser.dynamic_image == 0) ||
            (currentUser.userRole === "staff" && currentUser.dynamic_image == 0)
              ? `(${currentUser.company_name})`
              : ""
          }`}</h6>
          <p className="text-center mb-5">
            <i
              className={`bi bi-circle-fill ${
                currentUser?.online == "true" || currentUser?.online == true
                  ? ""
                  : "offline"
              }`}
            ></i>{" "}
            {currentUser?.online == "true" || currentUser?.online == true
              ? "Online"
              : "Offline"}
          </p>
          <nav>
            <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-Images"
                type="button"
                role="tab"
                aria-controls="nav-Images"
                aria-selected="true"
              >
                Images
              </button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-Documents"
                type="button"
                role="tab"
                aria-controls="nav-Documents"
                aria-selected="false"
              >
                Documents
              </button>
            </div>
          </nav>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-Images"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
            tabIndex="0"
          >
            {sidebarImages && sidebarImages.length > 0
              ? sidebarImages.map((x, i) => {
                  return (
                    <div className="set">
                      <div
                        className="img"
                        onClick={() => {
                          setDataa({ type: "image", data: mediaUrl + x.file });
                          setImageVideoShow(!imageVideoShow);
                        }}
                        style={{ backgroundImage: `url(${mediaUrl + x.file})` }}
                      ></div>
                      <h5>{x.file}</h5>
                      <p>
                        <ReactTimeAgo
                          date={x.unix_time * 1000}
                          locale="en-US"
                          timeStyle="twitter"
                        />{" "}
                        ago
                      </p>
                    </div>
                  );
                })
              : ""}
          </div>
          <div
            className="tab-pane fade"
            id="nav-Documents"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
            tabIndex="0"
          >
            {sidebarFile && sidebarFile.length > 0
              ? sidebarFile.map((x, i) => {
                  return (
                    <div className="set">
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(../assets/images/docImage.webp)`,
                        }}
                        onClick={() => downloadMedia(mediaUrl + x.file)}
                      ></div>
                      <h5>{x.file}</h5>
                      <p>
                        <ReactTimeAgo
                          date={x.unix_time * 1000}
                          locale="en-US"
                          timeStyle="twitter"
                        />{" "}
                        ago
                      </p>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
      <Example
        show={imageVideoShow}
        onHide={() => setImageVideoShow(!imageVideoShow)}
        component={
          <ChatImageAndVideoPreviewModel
            onHide={() => setImageVideoShow(!imageVideoShow)}
            data={dataa.data}
            type={dataa.type}
          />
        }
        style={"pop_up"}
      />
    </div>
  );
}

export default RightSidebar;
