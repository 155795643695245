import { useDispatch, useSelector } from "react-redux";
import {
  viewPerPageData,
  visitorEngagementData,
} from "../../../../services/Analytics";
import AnalyticsBox from "../../../Tools/AnalyticsBox";
import ViewPerPageGraph from "./viewPerPageGraph";
import VisitorsSource from "./visitorsSource";
import { analyticDetail } from "../../../../Reducer/analyticSlice";
import { useEffect, useState } from "react";
import Example from "../../../Tools/ReactBoostrap";
import DateSelecter from "../../../Tools/DateSelecter";
import * as moment from "moment";
const Visitors = ({ activeTab }) => {
  const [show, setShow] = useState(false);
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 30));
  const [dates, setDates] = useState({
    startDate: priorDate,
    endDate: today,
    key: "selection",
  });

  const dispatch = useDispatch();
  const selector = useSelector((state) => state.analytic.analyticDetails);
  const auth = useSelector((state) => state.auth.auth);
  const [pages, setPages] = useState([]);
  const [rightPages, setRightPages] = useState([]);
  useEffect(() => {
    dispatch(analyticDetail(dates));
  }, [dates]);

  useEffect(() => {
    if (selector != null) {
      const sum =
        selector.main + selector.review + selector.success + selector.faq;
      const mainPage = (selector.main * 100) / sum;
      const reviewPage = (selector.review * 100) / sum;
      const successPage = (selector.success * 100) / sum;
      const faqPage = (selector.faq * 100) / sum;
      viewPerPageData[0].title = "/" + auth.slug;
      viewPerPageData[0].childInlineStyle = { width: `${mainPage}%` };
      viewPerPageData[0].views = selector.main + " view";
      viewPerPageData[1].childInlineStyle = { width: `${reviewPage}%` };
      viewPerPageData[1].views = selector.review + " view";
      viewPerPageData[2].childInlineStyle = { width: `${successPage}%` };
      viewPerPageData[2].views = selector.success + " view";
      viewPerPageData[3].childInlineStyle = { width: `${faqPage}%` };
      viewPerPageData[3].views = selector.faq + " view";
      visitorEngagementData[0].views = selector.total + " view";
      visitorEngagementData[1].views = selector.chatUser + " view";
      visitorEngagementData[2].views = selector.allCall + " view";
      setPages(viewPerPageData);
      setRightPages(visitorEngagementData);
    }
  }, [selector]);

  return (
    <div
      className={`tab-pane fade ${activeTab == "visitors" && "show active"}`}
      id="Visitors"
    >
      <div class="d-flex align-items-center mb-4" onClick={() => setShow(!show)}>
        <h3 class="mb-3">
          <b className="select">{`${moment(dates.startDate).format("DD/MM/YYYY")} to ${moment(
            dates.endDate
          ).format("DD/MM/YYYY")}`}<i class="bi bi-chevron-down ms-2"></i></b>
        </h3>
      </div>
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={
          <DateSelecter
            onHide={() => setShow(!show)}
            dates={dates}
            setDates={setDates}
          />
        }
        style={"dateSelection"}
      />

      <div className="row">
        <div className="col-md-6">
          <VisitorsSource detail={selector} />
        </div>
        <div className="col-md-6">
          {rightPages.length > 0 && (
            <AnalyticsBox
              parentStyle={"wap"}
              imgSrc={"../../assets/images/Staff_Members.png"}
              title={"Visitors Engagement"}
              description={
                "Track visitor interactions to improve your strategy and connect with more clients"
              }
              data={rightPages}
              prog={false}
            />
          )}
        </div>
        <div className="col-lg-6">
          {pages.length > 0 && (
            <AnalyticsBox
              imgSrc={"../../assets/images/Staff_Members.png"}
              title={"Views Per Page"}
              description={
                "Monitor the performance of each page to enhance your content and attract more clients."
              }
              data={pages}
              prog={true}
            />
          )}
        </div>
        <div className="col-lg-6">
          {" "}
          {selector != null && selector.graph && selector.graph.length > 0 ? (
            <ViewPerPageGraph data={selector} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Visitors;
