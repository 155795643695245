import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import LastDaysActivity from "./lastDaysActivity";
import ProfileSecion from "./profileSection";
import ReviewsOptions from "./reviewOptions";

const SingleProfileDetailReview = () => {
  return (
    <>
      <StudentHeader
        style={"header_white"}
        logo={"assets/images/logo2.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />
      <section className="single_details">
        <div
          className="single_banner"
          style={{ backgroundImage: `url(assets/images/single_banner.png)` }}
        >
          <div className="single_banner1"></div>
        </div>
        <div className="container">
          <div className="row">
            <ProfileSecion />
            <LastDaysActivity />
            <ReviewsOptions />
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default SingleProfileDetailReview;
