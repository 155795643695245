import { useNavigate } from "react-router-dom";
const SearchSuggestionBox = ({
  imageClass,
  image,
  name,
  type,
  location,
  available,
  staticBox,
  slug,
  verified,
  specialFun,
  city,
  handleUserNavigate,
}) => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleClick = () => {
    if (userDetails && userDetails.city && !city) {
      const new_city = userDetails.city.replaceAll(" ", "-");
      if (type) {
        specialFun && specialFun();
        navigate(`/${new_city}?categories=${type}`);
        handleUserNavigate &&
          handleUserNavigate(
            "navigate",
            `/${new_city}?categories=${type}`,
            "navigate"
          );
      }
      if (slug) {
        specialFun && specialFun();
        navigate(`/${new_city}/${slug}`, { state: 1 });
        handleUserNavigate &&
          handleUserNavigate("navigate", `/${new_city}/${slug}`, "navigate");
      }
    } else if (city) {
      const new_city = city.replaceAll(" ", "-");
      if (type) {
        specialFun && specialFun();
        navigate(`/${new_city}?categories=${type}`);
        handleUserNavigate &&
          handleUserNavigate(
            "navigate",
            `/${new_city}?categories=${type}`,
            "navigate"
          );
      }
      if (slug) {
        specialFun && specialFun();
        navigate(`/${new_city}/${slug}`, { state: 1 });
        handleUserNavigate &&
          handleUserNavigate("navigate", `/${new_city}/${slug}`, "navigate");
      }
    } else {
      specialFun && specialFun();
      navigate("/search");
      handleUserNavigate &&
        handleUserNavigate("navigate", "/search", "navigate");
    }
  };

  return (
    <li onClick={() => handleClick()}>
      <div
        className={`img ${imageClass && imageClass}`}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <h6 className={staticBox ? "dfapt" : ""}>
        <span className="tit">{name}</span>{" "}
        {!staticBox && verified && (
          <>
            <i className="bi bi-patch-check-fill"></i>{" "}
          </>
        )}
        <span>
          {staticBox ? (
            available
          ) : (
            <>
              {/* <i className="bi bi-star-fill"></i>
              {averageRatting} {`(${rattingCount} Reviews)`} */}
            </>
          )}
        </span>{" "}
      </h6>
      <p>
        <i className="bi bi-building-fill"></i> {location}
      </p>
    </li>
  );
};

export default SearchSuggestionBox;
