import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import Meta from "../Meta";
import { DisclaimerMetaTags } from "../../../../data/metaTags";

const Disclaimer = () => {
  return (
    <>
      <Meta {...DisclaimerMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"Disclaimer"} />
      <section className="fees_main">
        <div className="container">
          <p>
            The information and data contained on chownk website is to be
            treated purely for your Education purposes only. Any prediction or
            other message that you receive is not a substitute for advice,
            programs, or treatment that you would normally receive from a
            licensed professional such as a lawyer, doctor, psychiatrist, or
            financial advisor. Accordingly, chownk.com provides no guarantees,
            implied warranties, or assurances of any kind, and will not be
            responsible for any interpretation made or use by the recipient of
            the information and data mentioned above.
          </p>
          <p>
            Moreover, chownk.com is not a registered firm. It is a product of
            Digittrix Infotech Private Limited All the transaction and gathered
            data is / will be accessed by Chownk.
          </p>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default Disclaimer;
