import { el } from "@faker-js/faker";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Specialized = ({
  specialization,
  loading,
  setSpecialization,
  companyOwner,
  selector,
  allSpecialization,
  setAllSpecialization,
  newSpecialization,
  setNewSpecialization,
  updateProfileFun,
}) => {
  const [edit, setEdit] = useState(false);
  const [dltSpecialization, setDltSpecialization] = useState([]);
  const [addedSpecialization, setAddedSpecialization] = useState([]);

  const handleSpecialization = () => {
    const formData = new FormData();
    if (dltSpecialization && dltSpecialization.length > 0) {
      dltSpecialization.forEach((el, i) => {
        return formData.append("remove_specialization[]", el.id);
      });
    }
    if (addedSpecialization && addedSpecialization.length > 0) {
      allSpecialization.map((item) => {
        if (addedSpecialization.find((el) => el.name == item.name)) {
          return formData.append("specialization[]", item.id);
        }
      });
    }
    updateProfileFun(formData);
    setEdit(!edit);
    setAddedSpecialization([]);
    setDltSpecialization([]);
  };

  return (
    <>
      {(allSpecialization.length > 0 ||
        selector?.uid === companyOwner ||
        loading) && (
        <div class="set mt-0">
          <h2>
            Specialized in{" "}
            {selector?.uid === companyOwner &&
              (edit ? (
                <>
                  <Link class="float-end" onClick={() => setEdit(!edit)}>
                    <i class="bi bi-x-circle-fill"></i>
                  </Link>
                  <Link class="float-end" onClick={handleSpecialization}>
                    <i class="bi bi-check-circle-fill"></i>
                  </Link>
                </>
              ) : (
                <Link class="float-end" onClick={() => setEdit(!edit)}>
                  <i class="bi bi-pencil-square"></i>
                </Link>
              ))}
          </h2>
          {edit && (
            <p>{`You have Selected ${newSpecialization.length} out of 25`}</p>
          )}
          <ul>
            {loading ? (
              <>
                <li>
                  <Skeleton width={"80px"} />
                </li>
                <li>
                  <Skeleton width={"80px"} />
                </li>
                <li>
                  <Skeleton width={"80px"} />
                </li>
                <li>
                  <Skeleton width={"80px"} />
                </li>
              </>
            ) : edit ? (
              allSpecialization?.length > 0 &&
              allSpecialization.map((item) => (
                <li
                  style={{
                    color:
                      newSpecialization.find((el) => el.name === item.name) &&
                      "green",
                  }}
                  className={
                    newSpecialization.find((el) => el.name === item.name)
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    const foundPreviousData = specialization.find(
                      (el) => el.name === item.name
                    );

                    const foundNewData = newSpecialization.find(
                      (el) => el.name === item.name
                    );
                    if (foundPreviousData && foundNewData) {
                      setDltSpecialization((prev) => [
                        ...prev,
                        foundPreviousData,
                      ]);
                      const filterSpc = newSpecialization.filter(
                        (el) => el.name !== foundNewData.name
                      );
                      setNewSpecialization(filterSpc);
                    } else if (foundPreviousData) {
                      if (
                        newSpecialization.find(
                          (el) => el.name === foundPreviousData.name
                        )
                      ) {
                        setNewSpecialization(
                          newSpecialization.filter(
                            (el) => el.name !== foundPreviousData.name
                          )
                        );
                      } else {
                        setNewSpecialization((prev) => [
                          ...prev,
                          foundPreviousData,
                        ]);
                      }
                      if (
                        dltSpecialization.find(
                          (el) => el.name === foundPreviousData.name
                        )
                      ) {
                        setDltSpecialization(
                          dltSpecialization.filter(
                            (el) => el.name !== foundPreviousData.name
                          )
                        );
                      } else {
                        setDltSpecialization((prev) => [
                          ...prev,
                          foundPreviousData,
                        ]);
                      }
                    } else {
                      if (
                        addedSpecialization.find((el) => el.name === item.name)
                      ) {
                        const filter = addedSpecialization.filter(
                          (el) => el.name !== item.name
                        );

                        setAddedSpecialization(filter);
                      } else {
                        if (newSpecialization.length < 25) {
                          setAddedSpecialization([
                            ...addedSpecialization,
                            item,
                          ]);
                        }
                      }
                      if (
                        newSpecialization.find((el) => el.name === item.name)
                      ) {
                        const filter = newSpecialization.filter(
                          (el) => el.name !== item.name
                        );
                        setNewSpecialization(filter);
                      } else {
                        if (newSpecialization.length < 25) {
                          setNewSpecialization([...newSpecialization, item]);
                        } else {
                          toast.error("You can only select 25 Specialization");
                        }
                      }
                    }
                  }}
                >
                  <i class="bi bi-patch-check-fill"></i> {item.name}
                </li>
              ))
            ) : (
              newSpecialization?.length > 0 &&
              newSpecialization.map((item) => (
                <li className="active">
                  <i class="bi bi-patch-check-fill"></i>{" "}
                  {item.name ? item.name : item}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Specialized;
