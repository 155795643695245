import React, { useContext } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useSelector } from "react-redux";
import FormInputField from "../../../Tools/Forminput";
import ProfileButton from "../../../Tools/ProfileButton";
import { SocketContext } from "../../../../Context";
import { googleMapApi } from "../../../../data/data";

const EditProfileForm = ({
  register,
  handleSubmit,
  onSubmit,
  errors,
  address,
  addressError,
  handleCurrentAddress,
  userId,
  editProfile,
  setEditProfile,
}) => {
  const getAddress = useSelector((state) => state.auth);
  const { addFundsModal, setAddFundsModal } = useContext(SocketContext);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4>
        {"Current Balance:"} <i className="bi bi-currency-rupee"></i>{" "}
        {getAddress.funds}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setAddFundsModal(!addFundsModal);
          }}
        >
          <i className="bi bi-plus-lg"></i> {"Add Funds"}
        </a>{" "}
        {/* <i className="bi bi-arrow-clockwise ms-2"></i> */}
      </h4>
      <div className="row">
        <div className="col-sm-6">
          <FormInputField
            parentStyle={"form-group"}
            childStyle={"form-control"}
            label={"Name"}
            name={"name"}
            event={register}
            errors={errors}
            placeholder={"Full name"}
            disabled={!editProfile && true}
          />
        </div>
        <div className="col-sm-6">
          <FormInputField
            parentStyle={"form-group"}
            childStyle={"form-control"}
            label={"Phone Number"}
            name={"phone"}
            event={register}
            errors={errors}
            disabled={true}
            readOnly={true}
            placeholder={"Enter Phone Number"}
            // readOnly={!editProfile && true}
          />
        </div>
        <div className="col-sm-6">
          <FormInputField
            parentStyle={"form-group"}
            childStyle={"form-control"}
            label={"Email"}
            name={"email"}
            event={register}
            errors={errors}
            placeholder={"Enter Email ( Optional )"}
            disabled={!editProfile && true}
          />
        </div>
        <div className="col-sm-6">
          <div className={"form-group"}>
            <label>{"Current Address"}</label>
            <GooglePlacesAutocomplete
              apiKey={googleMapApi}
              selectProps={{
                address,
                onChange: handleCurrentAddress,
                className: "form-control",
                name: "current_address",
                defaultInputValue: address,
                backspaceRemovesValue: true,
                isDisabled: !editProfile ? true : false,
              }}
            />
            {addressError && addressError.address && (
              <p style={{ color: "red" }}>{addressError.address.message}</p>
            )}
          </div>
        </div>
        {getAddress.auth?.id == userId &&
          (editProfile ? (
            <ProfileButton
              inType={"submit"}
              parentStyle={"col-sm-12 text-right"}
              iconClassName={"bi bi-pencil-square"}
              label={"Save Profile"}
            />
          ) : (
            <ProfileButton
              inType={"button"}
              parentStyle={"col-sm-12 text-right"}
              iconClassName={"bi bi-pencil-square"}
              buttonOnClick={handleSubmit(() => setEditProfile(!editProfile))}
              label={"Edit Profile"}
            />
          ))}
      </div>
    </form>
  );
};
export default EditProfileForm;
