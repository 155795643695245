import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { otpVerification } from "../../../../Reducer/authSlice";
import { mediaUrl } from "../../../../services/network";

const Loader = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const token = useSelector((state) => state.auth?.token);
    const auth = useSelector((state) => state.auth?.auth);
    useEffect(() => {
      applogin();
    }, []);
    const applogin = async () => {
      if (
        (!token || token == "" || token == null || token == undefined) &&
        query.get("phone") &&
        query.get("otp")
      ) {
        const body = {
          phone: query.get("phone"),
          otp: query.get("otp"),
          app_id: query.get("app_id"),
          app_version: query.get("app_version"),
          platform: query.get("platform"),
          platform_type:query.get("deviceType"),
          longitude: 0,
          latitude: 0,
          ip_address: query.get("ip_address"),
          current_address: "",
          device_token: query.get("device_token"),
          device_id: query.get("device_id"),
          log: query.get("log"),
          app: true,
        };
        localStorage.setItem("dt", query.get("device_token"));
        const dts = await dispatch(otpVerification(body));
        if(dts.payload && dts.payload.user && dts.payload.user.role){
          let dtss = { type: "loader" };
          switch (dts.payload.user.role) {
            case 6:
              navigate('/staff-dashboard');
            break;
            case 5:
              if(query.get("partner_register") && query.get("partner_register")=="1"){
                navigate('/verification');
              }else{
                navigate('/partner-dashboard');
              }
            break;
            default:
              navigate('/');
            break;
          }
          window.ReactNativeWebView.postMessage(JSON.stringify(dtss));
        }
      }else if(auth && auth.role){
        switch (auth.role) {
          case 6:
            navigate('/staff-dashboard');
          break;
          case 5:
            if(query.get("partner_register") && query.get("partner_register")=="1" && auth.profile_percentage && auth.profile_percentage<80){
              navigate('/verification');
            }else{
              navigate('/partner-dashboard');
            }
          break;
          case 1:
            navigate('/');
          break;
          case 7:
            navigate('/');
          break;
          default:
          break;
        }
      }
    };
    return (
        <div className={`spinner text-center`}>
            <img src={`${mediaUrl}spinner.gif`}/>
        </div>
    )
}
export default Loader;

