import { Link } from "react-router-dom";
import { mediaUrl } from "../../../../services/network";
import { useSelector } from "react-redux";

const LeaveCompany = () => {
  const { auth } = useSelector((state) => state.auth);

  return (
    <div className="wap matters mb-3 h-auto">
      <div className="set mb-0">
        <div class="imgmain">
          <div
            className="img"
            style={{
              backgroundImage: `url(${mediaUrl}${auth && auth.profile_image})`,
            }}
          ></div>
        </div>
        <h5>
          {auth && auth.name}
          <Link to={`/profile/${auth.id}`}>
            <i className="bi bi-pencil-square"></i>
          </Link>{" "}
        </h5>
        <p>
          <Link
            to={
              auth && auth && auth.company_city && auth.company_name
                ? "/" +
                  auth.company_city.replaceAll(" ", "-") +
                  "/" +
                  auth.company_name.replaceAll(" ", "-")
                : "/staff-dashboard"
            }
          >
            {auth && auth.company_name ? auth.company_name : ""}
          </Link>{" "}
          {/* <a href="#" className="leave float-end">
            {"Leave Company"}
          </a> */}
        </p>
      </div>
    </div>
  );
};
export default LeaveCompany;
