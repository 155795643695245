import { mediaUrl } from "../../../services/network";

const BranchBox = ({
  parentStyle,
  childStyle,
  imgSrc,
  location,
  address,
  branchName,
}) => {
  return (
    <div className={parentStyle}>
      <div
        className={childStyle}
        style={{ backgroundImage: `url(${mediaUrl}${imgSrc})` }}
      ></div>
      <h3>{branchName}</h3>
      <p>{address}</p>
    </div>
  );
};
export default BranchBox;
