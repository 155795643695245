export const navTabsData = [
  {
    label: "Visitors",
    imageSrc: "../../assets/images/analyticsicon1.png",
    name: "visitors",
  },
  {
    label: "Followers",
    imageSrc: "../../assets/images/analyticsicon2.png",
    name: "followers",
  },
  {
    label: "Competitors",
    imageSrc: "../../assets/images/analyticsicon3.png",
    name: "competitors",
  },
];

export const exportSectionOptions = [
  {
    label: "October 20, 2023 - November 18, 2023",
  },
  {
    label: "October 20, 2023 - November 18, 2023",
  },
  {
    label: "October 20, 2023 - November 18, 2023",
  },
];

export const visitorEngagementData = [
  {
    title: "Total Visitors",
    description: "See the number of people visiting your profile.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    childInlineStyle: { width: "30%" },
    views: "0 views",
  },
  {
    title: "Chats",
    description: "See how many people have messaged you.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    childInlineStyle: { width: "30%" },
    views: "0 views",
  },
  {
    title: "Calls",
    description: "See how many people have called you.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    views: "0 views",
  },
];
export const viewPerPageData = [
  {
    title: "/company",
    description: "Track the views for your Profile page to see its popularity.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    views: "0 views",
  },
  {
    title: "/reviews",
    description: "Check how many people are reading reviews on your profile.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    views: "0 views",
  },

  {
    title: "/success-stories",
    description: "See the number of views on your success stories page.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    views: "0 views",
  },
  {
    title: "/faq",
    description:
      "Monitor the views on your FAQ page to understand visitor interests.",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    parentInlineStyle: { height: "30px" },
    views: "0 views",
  },
];

export const visitorsLocationData = [
  {
    title: "Chandigarh Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "156 (15.3%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "75%" },
  },
  {
    title: "Ludhiana Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "33 (3.2%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "75%" },
  },
  {
    title: "Shimla Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "17 (1.7%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "75%" },
    views: "10 views",
  },
  {
    title: "Patna Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "75",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "16 (1.6%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "75%" },
    views: "10 views",
  },
];

export const followerDemographicsData = [
  {
    title: "Greater Delhi Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "75",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "1,003 (9.2%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "75%" },
  },
  {
    title: "Chandigarh, India",
    areaLabel: "Example 15px high",
    areaValueNow: "45",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "837 (7.7%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "45%" },
  },
  {
    title: "Greater Bengaluru Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "35",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "479 (4.4%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "35%" },
  },
  {
    title: "Amritsar/Ludhiana Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "30",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "399 (3.7%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "30%" },
  },
  {
    title: "Noida, India",
    areaLabel: "Example 15px high",
    areaValueNow: "25",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "355 (3.3%) ",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "25%" },
  },
  {
    title: "Pune/Pimpri-Chinchwad Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "20",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "274 (2.5%)",
    parentInlineStyle: { height: "20px" },
    childInlineStyle: { width: "30%" },
  },
  {
    title: "Greater Hyderabad Area, India",
    areaLabel: "Example 15px high",
    areaValueNow: "15",
    areaValueMin: "0",
    areaValueMax: "100",
    percentage: "199 (1.8%)",
    parentInlineStyle: { height: "15px" },
    childInlineStyle: { width: "15%" },
  },
];

export const pageViewsColumn = [
  {
    label: "Company Name",
    name: "company_name",
    background: true,
    image_name: "company_image",
  },
  {
    label: "Address",
    name: "address",
    scope: "col",
  },
  {
    label: "Total View",
    name: "total_view",
    scope: "col",
  },
  {
    label: "New View",
    name: "newest_view",
    scope: "col",
  },
];

export const pageViewsRows = [
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
];

export const followerMetricsColumn = [
  {
    label: "Company Name",
    name: "company_name",
    background: true,
    image_name: "company_image",
  },
  {
    label: "Address",
    name: "address",
    scope: "col",
  },
  {
    label: "Total Follwers",
    name: "total_followers",
    scope: "col",
  },
  {
    label: "New Followers",
    name: "newest_followers",
    scope: "col",
  },
];

export const followerMetricsRows = [
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
  {
    page: 1,
    companyImage: "assets/images/ltimg.png",
    companyName: "Digittrix Infotech Pvt Ltd",
    address:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry",
    totalFollowers: "10,904",
    newFollowers: 224,
  },
];
