import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SpecializationFilter = ({
  filterData,
  setItem,
  selectedItems,
  heading,
  navpath,
  image,
  image2,
}) => {
  const [specializationFilter, setSpecializationFilter] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { meta } = useSelector((state) => state.afterSearch);
  const specializedd = queryParams.get("specialization[]");
  const newNavPath = "/search";
  useEffect(() => {
    setItem((prev) => ({
      ...prev,
      specialized: specializedd ? [...specializedd.split(",")] : [],
    }));
    if (meta && meta != null && meta.sps) {
      setItem((prev) => ({
        ...prev,
        specialized: meta.sps.split(","),
      }));
    }
    if (meta && meta != null && meta.city) {
      setItem((prev) => ({
        ...prev,
        city: meta.city,
      }));
    }
  }, [meta, specializedd, setItem]);

  useEffect(() => {
    if (!selectedItems.specialized) {
      setCheckboxValues([]);
      setSpecializationFilter([]);
    } else {
      setCheckboxValues([...selectedItems.specialized]);
      selectedItems && setSpecializationFilter(selectedItems.specialized);
    }
  }, [selectedItems]);

  const handleChange = (e) => {
    if (specializationFilter.includes(e.target.value)) {
      const filter = specializationFilter.filter((el) => el !== e.target.value);
      setItem &&
        setItem((prev) => ({
          ...prev,
          specialized: [...filter],
        }));
      setSpecializationFilter(filter);
    } else {
      setSpecializationFilter([...specializationFilter, e.target.value]);
      setItem &&
        setItem((prev) => ({
          ...prev,
          specialized: [...specializationFilter, e.target.value],
        }));
    }
  };

  const handleClick = () => {
    queryParams.delete("visatype");
    if (checkboxValues.length > 0) {
      queryParams.set("specialization[]", specializationFilter);
      const newSearchString = queryParams.toString();
      navigate({ pathname: newNavPath, search: newSearchString });
      setItem &&
        setItem((prev) => ({
          ...prev,
          specialized: [...specializationFilter],
        }));
    } else {
      setItem((prev) => ({
        ...prev,
        specialized: [],
      }));
      queryParams.delete("specialization[]");
      const newSearchString = queryParams.toString();
      navigate({ pathname: newNavPath, search: newSearchString });
    }
  };

  const handleClear = () => {
    queryParams.delete("specialization[]");
    const newSearchString = queryParams.toString();
    navigate({ pathname: newNavPath, search: newSearchString });
  };

  const checkboxHandle = (index) => {
    if (checkboxValues.length > 0) {
      if (checkboxValues.includes(index)) {
        setCheckboxValues(checkboxValues.filter((el) => el !== index));
      } else {
        setCheckboxValues([...checkboxValues, index]);
      }
    } else {
      setCheckboxValues([...checkboxValues, index]);
    }
  };

  return (
    <>
      <li className="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={image} alt="img" />
          <img src={image2} alt="img" className="white_img" />
          {heading}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <React.Fragment>
            <ul className="Visa">
              {filterData &&
                filterData.child &&
                filterData.child.length > 0 &&
                filterData.child.map((el, i) => (
                  <li key={i}>
                    <input
                      type="checkbox"
                      value={el.name}
                      checked={
                        checkboxValues && checkboxValues.includes(el.name)
                      }
                      onClick={() => checkboxHandle(el.name)}
                      onChange={handleChange}
                    />
                    {el.name}
                  </li>
                ))}
            </ul>
          </React.Fragment>
          <div className="d-none d-md-block">
            <div className="row">
              <div className="col-6">
                <button
                  className="btn"
                  onClick={() => {
                    handleClear();
                    setItem &&
                      setItem((prev) => ({
                        ...prev,
                        specialized: [],
                      }));
                  }}
                >
                  Clear all
                </button>
              </div>
              <div className="col-6">
                <button className="btn apply" onClick={handleClick}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </ul>
      </li>
    </>
  );
};

export default SpecializationFilter;
