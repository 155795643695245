import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputField from "../../../Tools/Forminput";
import Button from "../../../Tools/Button";
import AgreeTermAndCondition from "./agreeTermAndCondition";
import {
  setPhoneNumber,
  phoneVerification,
  setAction,
} from "../../../../Reducer/authSlice";
import { loginSchema } from "../../../../services/schema";
import { mediaUrl } from "../../../../services/network";
import { Link } from "react-router-dom";

const StudentLogin = ({ onHide, modelViewControll }) => {
  const [phone, setPhone] = React.useState("");
  const [isAgreed, setIsAgreed] = React.useState(false);
  const selector = useSelector((state) => state.auth);
  const userLocation = useSelector((state) => state.auth?.userDetails);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const onSubmit = (data) => {
    try {
      const body = {
        phone: data.phone,
        latitude: userLocation?.latitude,
        longitude: userLocation?.longitude,
        ip_address: userLocation?.ip_address,
        current_address: userLocation?.current_address,
        role: 1,
      };
      dispatch(setAction("header"));
      dispatch(setPhoneNumber(data.phone));
      dispatch(phoneVerification(body));
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const handleChange = () => {
    setIsAgreed(!isAgreed);
  };

  useEffect(() => {
    setPhone(getValues("phone"));
  }, []);

  useEffect(() => {
    modelViewControll();
  }, [selector.success, selector.otpSuccess]);

  return (
    <>
      {
        <div className="row">
          <div className="col-md-6">
            <div className="lest_login text-center">
              <img
                src={`../../assets/images/chownk-logo.png`}
                alt="CHOWNK logo"
              />
              <h3 className="text-center">Welcome to Chownk</h3>
              <h6>
                Sign in or create an account. It takes less than a minute!
              </h6>
              <form
                className="text-start mt-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormInputField
                  parentStyle={"form-group prefix"}
                  childStyle={"form-control"}
                  label={"Enter your mobile Number"}
                  event={register}
                  prefix={"+91"}
                  name={"phone"}
                  errors={errors}
                  defaultValue={selector.phoneNumber}
                  onChange={(e) => {
                    if (
                      e.target.value.length == 10 &&
                      isNaN(e.target.value) === false
                    ) {
                      clearErrors("phone");
                    }
                    setPhone(e.target.value);
                  }}
                  placeholder={"Enter your mobile number here..."}
                />

                <AgreeTermAndCondition
                  onChange={handleChange}
                  isAgreed={isAgreed}
                />
                <Button
                  inType={"submit"}
                  style={"btn mt-2 mt-lg-5"}
                  title={"Continue"}
                  disabled={!isAgreed}
                  inlineStyle={
                    isAgreed &&
                    phone.length === 10 &&
                    !isNaN(phone) &&
                    Object.keys(errors).length === 0
                      ? { backgroundColor: "#1DBF73", color: "white" }
                      : {}
                  }
                />
              </form>
              <p className="consult">
                Are you a Consultant?{" "}
                <Link to={"/become-consultant"}>Become a Partner</Link>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right_login">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <div className="column">
                  <h3 className="no-puck text-white">
                    {" "}
                    Trust
                    <span className="highlight"> CHOWNK </span>
                    to navigate the complexities of international immigration
                    seamlessly, ensuring you're well-informed and supported
                    every step of the way.{" "}
                  </h3>
                  <div className="mob video-demonstration">
                    <div
                      className="youtube-video"
                      data-lumberjack-action="partnerships.landing_page.video"
                    ></div>
                  </div>
                  <ul className="bullet-list">
                    <li>Secure Video 1 to 1 Call with Experts</li>
                    <li>Chat and Messaging</li>
                    <li>Recording Options</li>
                    <li>Document Checklist</li>
                    <li>Client Portal</li>
                    <li>Informational Content</li>
                    <li>Document Sharing</li>
                    <li>Feedback and Review System</li>
                    <li>Notification Alerts</li>
                  </ul>
                  <div className="become-partner-container mob">
                    <p>
                      <i className="bi bi-arrow-left"></i>{" "}
                      <strong>Join Now</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      }
    </>
  );
};
export default StudentLogin;
