import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearRecentSearches } from "../../../Reducer/afterSearchSlice";
import { googleMapApi } from "../../../data/data";

const RecentSearchBox = ({
  recentSearches,
  popularSearches,
  setSearch,
  handleUserNavigate,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const checkType = (item) => {
    switch (item.type) {
      case "specialization":
        navigate(item.path);
        handleUserNavigate("navigate", item.path, "popular searches");
        return;
      case "location":
        Fetchlocation();
        return;
      case "fraud":
        navigate("/how-to-avoid-immigration-fraud-from-agents");
        handleUserNavigate(
          "navigate",
          "/how-to-avoid-immigration-fraud-from-agents",
          "popular searches"
        );
        return;
      default:
        return navigate("/");
    }
  };

  const Fetchlocation = (ipAddress) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      fetchLocationInfo(latitude, longitude, ipAddress);
    });
  };

  const fetchLocationInfo = (latitude, longitude, ipAddress) => {
    const apiKey = googleMapApi;
    let cities = "";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        await data.results.forEach((i, index) => {
          if (i.types.includes("administrative_area_level_3")) {
            cities = i.address_components[0].long_name;
            queryParams.set("city", cities.replaceAll(" ", "-"));
            queryParams.set("lat", latitude);
            queryParams.set("long", longitude);
            const newSearchString = queryParams.toString();
            navigate({ pathname: "/near-me", search: newSearchString });
            handleUserNavigate("navigate", "/near-me", "near me");
          }
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  return (
    <>
      {recentSearches && recentSearches.length > 0 && (
        <li>
          <h6>
            <i class="bi bi-clock"></i>Recent Searches
            <span
              className="float-end"
              onClick={() => dispatch(clearRecentSearches())}
            >
              clear
            </span>
          </h6>
          <ul>
            {recentSearches.slice(0, 5).map((el, i) => (
              <li
                key={i}
                onClick={() => {
                  navigate(`/search?search=${el}`);
                  setSearch(el);
                }}
              >
                <Link>{el}</Link>
              </li>
            ))}
          </ul>
        </li>
      )}
      {popularSearches && popularSearches.length > 0 && (
        <li>
          <h5>Popular Services</h5>
          <ul>
            {popularSearches.map((el, i) => (
              <li
                key={i}
                onClick={() => {
                  checkType(el);
                }}
              >
                <Link>{el.label}</Link>
              </li>
            ))}
          </ul>
        </li>
      )}
    </>
  );
};

export default RecentSearchBox;
