import React, { useEffect, useState } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import { countMinusWithInputValue } from "../../../../services/function";

const TaglineTab = ({
  setValue,
  currentStep,
  errors,
  register,
  taglineLength,
}) => {
  const [count, setCount] = useState(taglineLength);

  useEffect(() => {
    setCount(taglineLength);
  }, [taglineLength]);

  return (
    <div
      className="tab"
      style={{ display: currentStep === 10 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          10. Add Tagline
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>
                Customers Can See this on your Profile Page and Feel Confident
                on your listing
              </p>
              <a
                href="../../assets/images/select9.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select9.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
        <p className="label">Add Here</p>
        <SimpleInput
          style="form-control"
          type="text"
          name={"tagline"}
          placeholder="Add Tagline"
          event={register}
          errors={errors}
          onChange={(e) => {
            setValue("tagline", e.target.value);
            setCount(e.target.value.length);
          }}
        />
        <p className="text-end">{`${countMinusWithInputValue(
          50,
          count
        )} Characters`}</p>
      </div>
    </div>
  );
};

export default TaglineTab;
