import HomePage from "../Components/Pages/Student/HomePage";
import AfterSearchResult from "../Components/Pages/Student/AfterSearchResult";
import SingleProfileDetailReview from "../Components/Pages/Student/SingleProfileDetailReviews";
import MyProfile from "../Components/Pages/Student/MyProfile";
import Transactions from "../Components/Pages/Student/Transactions";
import BeforeCallQuestion from "../Components/Pages/Student/BeforeCallQuestion";
import LastestDashboard from "../Components/Pages/Agent/LastestDashboard";
import AccountSettings from "../Components/Pages/Agent/AccountSettings";
import BecomeConsultantPage from "../Components/Pages/Student/BecomeConsultant";
import Analytics from "../Components/Pages/Agent/Analytics";
import Help from "../Components/Pages/Agent/Help";
import Reports from "../Components/Pages/Agent/Reports";
import TryChanngingLocation from "../Components/Pages/Student/TryChangingLocation";
import AllBlogs from "../Components/Pages/Common/Blogs/AllBlogs";
import SingleBlog from "../Components/Pages/Common/Blogs/SingleBlog";
import BlogsByCatandTag from "../Components/Pages/Common/Blogs/BlogsByCatandTag";
import JoinPage from "../Components/Pages/Common/JoinPage";
import PrivacyPolicy from "../Components/Pages/Common/Privacy";
import FeesAndCharges from "../Components/Pages/Common/FeesAndCharges";
import SiteMap from "../Components/Pages/Common/SiteMap";
import Disclaimer from "../Components/Pages/Common/Disclaimer";
import AboutUs from "../Components/Pages/Common/AboutUs";
import TermsAndCondition from "../Components/Pages/Common/T&C";
import Contact from "../Components/Pages/Common/Contact";
import ApplicationList from "../Components/Pages/Student/ApplicationList";
import Chat from "../Components/Pages/Chat/Chat";
import ConfirmOffer from "../Components/Pages/Chat/ConfirmOffer";
import Calling from "../Components/Pages/Chat/Player";
import MyApplicationSingle from "../Components/Pages/Student/MyApplicationSingle";
import Membership from "../Components/Pages/Common/Membership";
import NotificationPage from "../Components/Pages/Common/NotificationPage";
import SingleHelpComponent from "../Components/Pages/Agent/SingleHelp";
import Record from "../Components/Pages/Chat/Record";
import AskExpert from "../Components/Pages/Student/AskExpert";
import Payment from "../Components/Pages/Student/Payment";
import ChatwithExpert from "../Components/Pages/Student/ChatwithExpert";
import PaymentPage from "../Components/Pages/Common/Payment";
import SingleApplication from "../Components/Pages/Common/SingleApplication";
import CallHistory from "../Components/Pages/Common/CallHistory";
import PreventFraud from "../Components/Pages/Common/PreventFraud";
import { searchPageData } from "../data/data";
import Loader from "../Components/Pages/Common/Loader";
import {
  IeltsMetaTags,
  StudyVisaMetaTags,
  nearMeMetaTags,
  prMetaTags,
  searchPageMetaTags,
  spouseVisaMetaTags,
  touristVisaMetaTags,
  workVisaMetaTags,
} from "../data/metaTags";
import DownloadApp from "../Components/Pages/Common/DownloadApp";
import MobileMenu from "../Components/Layouts/MobileMenu";
import NewHelp from "../Components/Pages/Agent/Help/newHelp";
import HelpCategory from "../Components/Pages/Agent/HelpCategory";
import AppNavigate from "../Components/Pages/Student/AppNavigate";
import TrainingVideos from "../Components/Pages/Agent/TrainingVideos";
import { ListingFaqs } from "../data/data";
import VerificationStepForm from "../Components/Pages/Agent/VerificationStepForm";
import Demo from "../Components/Pages/Common/Demo";
export const AgentRoute = [
  {
    path: "/verification",
    component: <VerificationStepForm />,
    role: 5,
  },
  {
    path: "/mobile-sidebar",
    component: <MobileMenu />,
    role: 5,
  },
  {
    path: "/partner-dashboard",
    component: <LastestDashboard />,
    role: 5,
  },
  {
    path: "/account-settings/:tab?",
    component: <AccountSettings />,
    role: 5,
  },
  {
    path: "/partner/analytics",
    component: <Analytics />,
    role: 5,
  },
  {
    path: "/partner/reports",
    component: <Reports />,
    role: 5,
  },
  {
    path: "/payment-summary",
    component: <PaymentPage />,
    role: 5,
  },
  {
    path: "/partner/training",
    component: <TrainingVideos />,
    role: 5,
  },
];

export const FreeRoute = [
  {
    path: "/demo",
    component: <Demo />,
  },
  {
    path: "/download-app",
    component: <DownloadApp />,
  },
  {
    path: "/app-navigates",
    component: <AppNavigate />,
  },
  {
    path: "/loader",
    component: <Loader />,
  },
  {
    path: "/how-to-avoid-immigration-fraud-from-agents",
    component: <PreventFraud />,
  },
  {
    path: "/applications/all",
    component: <ApplicationList />,
  },
  {
    path: "/applications/:id",
    component: <MyApplicationSingle />,
  },
  {
    path: "/profile/:id",
    component: <MyProfile />,
  },
  {
    path: "/:user/help",
    component: <Help />,
  },
  {
    path: "/temp_help",
    component: <NewHelp />,
  },
  {
    path: "/:user/help/:title/:id",
    component: <SingleHelpComponent />,
  },
  {
    path: "/:user/help/:cat",
    component: <HelpCategory />,
  },
  {
    path: "/sign-up-as-partner",
    component: <JoinPage />,
  },
  {
    path: "/after-search-results",
    component: <AfterSearchResult />,
  },
  {
    path: "/:city?/:dynamic?/:num?",
    component: <HomePage />,
  },
  {
    path: "/:city?/:company?",
    component: <HomePage />,
  },
  {
    path: "/search",
    component: (
      <AfterSearchResult
        data={searchPageData.search}
        metaTags={searchPageMetaTags}
      />
    ),
  },
  {
    path: "/Study-Visa",
    component: (
      <AfterSearchResult
        data={searchPageData.studyVisa}
        metaTags={StudyVisaMetaTags}
        faqs={ListingFaqs.study_visa}
      />
    ),
  },
  {
    path: "/Ielts-toefl-pte-institutes",
    component: (
      <AfterSearchResult
        data={searchPageData.IeltsToeflPteInstitutes}
        metaTags={IeltsMetaTags}
        faqs={ListingFaqs.ielts}
      />
    ),
  },
  {
    path: "/tourist-visa",
    component: (
      <AfterSearchResult
        data={searchPageData.touristVisa}
        metaTags={touristVisaMetaTags}
        faqs={ListingFaqs.tourist_visa}
      />
    ),
  },
  {
    path: "/spouse-visa",
    component: (
      <AfterSearchResult
        data={searchPageData.spouseVisa}
        metaTags={spouseVisaMetaTags}
        faqs={ListingFaqs.spouse_visa}
      />
    ),
  },
  {
    path: "/permanent-resident",
    component: (
      <AfterSearchResult
        data={searchPageData.permanentResidency}
        metaTags={prMetaTags}
        faqs={ListingFaqs.permanent_resident}
      />
    ),
  },
  {
    path: "/work-visa",
    component: (
      <AfterSearchResult
        data={searchPageData.workVisa}
        metaTags={workVisaMetaTags}
        faqs={ListingFaqs.work_visa}
      />
    ),
  },
  {
    path: "/australia",
    component: <AfterSearchResult data={searchPageData.australia} />,
  },
  {
    path: "/new-zealand",
    component: <AfterSearchResult data={searchPageData.newZealand} />,
  },
  {
    path: "/usa",
    component: <AfterSearchResult data={searchPageData.usa} />,
  },
  {
    path: "/france",
    component: <AfterSearchResult data={searchPageData.france} />,
  },
  {
    path: "/uk",
    component: <AfterSearchResult data={searchPageData.uk} />,
  },
  {
    path: "/canada",
    component: <AfterSearchResult data={searchPageData.canada} />,
  },
  {
    path: "/united-kingdom",
    component: <AfterSearchResult data={searchPageData.canada} />,
  },
  {
    path: "/singapore",
    component: <AfterSearchResult data={searchPageData.singapore} />,
  },
  {
    path: "/dubai",
    component: <AfterSearchResult data={searchPageData.dubai} />,
  },
  {
    path: "/germany",
    component: <AfterSearchResult data={searchPageData.germany} />,
  },
  {
    path: "/cae-or-cpe",
    component: <AfterSearchResult data={searchPageData.caeOrCpe} />,
  },
  {
    path: "/celpip",
    component: <AfterSearchResult data={searchPageData.celpip} />,
  },
  {
    path: "/gre",
    component: <AfterSearchResult data={searchPageData.gre} />,
  },
  {
    path: "/gmat",
    component: <AfterSearchResult data={searchPageData.gmat} />,
  },
  {
    path: "/sat",
    component: <AfterSearchResult data={searchPageData.sat} />,
  },
  {
    path: "/toefl",
    component: <AfterSearchResult data={searchPageData.toefl} />,
  },
  {
    path: "/pte",
    component: <AfterSearchResult data={searchPageData.pte} />,
  },
  {
    path: "/ielts",
    component: <AfterSearchResult data={searchPageData.ielts} />,
  },
  {
    path: "/near-me",
    component: (
      <AfterSearchResult
        data={searchPageData.nearMe}
        metaTags={nearMeMetaTags}
        faqs={ListingFaqs.near_me}
      />
    ),
  },
  {
    path: "/become-consultant",
    component: <BecomeConsultantPage />,
  },
  {
    path: "/no-service",
    component: <TryChanngingLocation />,
  },
  {
    path: "/blogs",
    component: <AllBlogs />,
  },
  {
    path: "/blogs/:slug",
    component: <SingleBlog />,
  },
  {
    path: "/blogs/category/:slug",
    component: <BlogsByCatandTag type="category" />,
  },
  {
    path: "/blogs/tag/:slug",
    component: <BlogsByCatandTag type="hashtag" />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/fees-and-charges",
    component: <FeesAndCharges />,
  },
  {
    path: "/sitemap",
    component: <SiteMap />,
  },
  {
    path: "/disclaimer",
    component: <Disclaimer />,
  },
  {
    path: "/about-us",
    component: <AboutUs />,
  },
  {
    path: "/terms-and-conditions",
    component: <TermsAndCondition />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
];

export const staffRoute = [
  {
    path: "/mobile-sidebarr",
    component: <MobileMenu />,
    role: 6,
  },
  {
    path: "/staff-dashboard",
    component: <LastestDashboard />,
  },
  {
    path: "/partner/analytics",
    component: <Analytics />,
  },
  {
    path: "/partner/reports",
    component: <Reports />,
  },
];

export const studentRoute = [
  {
    path: "/mobile-sidebar",
    component: <MobileMenu />,
  },
  {
    path: "/single-profile-detail-review",
    component: <SingleProfileDetailReview />,
  },

  {
    path: "/before-call-questions",
    component: <BeforeCallQuestion />,
  },
  {
    path: "/ask-a-expert",
    component: <AskExpert />,
  },
  {
    path: "/payment-summary",
    component: <Payment />,
  },
  {
    path: "/chat-with-expert",
    component: <ChatwithExpert />,
  },
];

export const commonRoute = [
  {
    path: "/my-single-application/:id",
    component: <SingleApplication />,
  },
  {
    path: "/messages",
    component: <Chat />,
  },
  {
    path: "/ConfirmOffer/:id",
    component: <ConfirmOffer />,
  },
  {
    path: "/Calling",
    component: <Calling />,
  },
  {
    path: "/membership",
    component: <Membership />,
  },
  {
    path: "/notification",
    component: <NotificationPage />,
  },
  {
    path: "/record",
    component: <Record />,
  },
  {
    path: "/transactions-list",
    component: <Transactions />,
  },
  {
    path: "/call-history",
    component: <CallHistory />,
  },
];

export const expertRoute = [
  {
    path: "/messages",
    component: <Chat />,
    role: 4,
  },
];
