// import React, { useState, useEffect } from "react";
// import { useReactMediaRecorder } from "react-media-recorder";
// import RecordRTC from 'recordrtc';
// const Record = () => {
//   const [recording, setRecording] = useState(false);
//   const [audio, setAudio] = useState('');
//   //const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({video:true});

//   // useEffect(() => {
//   //   if (mediaBlobUrl) {
//   //     // Save the recording.
//   //   }
//   // }, [mediaBlobUrl]);
//   const startRecording=()=>{
//     navigator.mediaDevices.getUserMedia({
//       video: true,
//       audio: true
//   }).then(async function(stream) {
//       let recorder = RecordRTC(stream, {
//           type: 'screen'
//       });
//       recorder.startRecording();
  
//       const sleep = m => new Promise(r => setTimeout(r, m));
//       await sleep(30000);
  
//       recorder.stopRecording(function() {
//           let blob = recorder.getBlob();
//           // invokeSaveAsDialog(blob);
//           const reader = new FileReader();
//           reader.onloadend = () => {
//             const base64Data = reader.result.split(',')[1];
//             setAudio(`data:video/mp4;base64,${base64Data}`);
//             console.log("base64Data base64Data base64Data base64Data base64Data",base64Data);
//           };
//           reader.readAsDataURL(blob);
//       });
//   });
//   }

//   return (
//     <div>
//       <button onClick={()=>startRecording()}>Start Recording</button>
//       {/* <button onClick={()=>stopRecording}>Stop Recording</button> */}
//       {recording && <p>Recording...</p>}
//       {audio && audio!="" &&
      
//       <video width="100%" height="100%" controls>
//       <source src={audio} type="video/mp4" />
//   </video>
//       }
//     </div>
//   );
// };
// export default Record;


// import React from 'react';
// import { useMediaRecorder } from 'react-media-recorder';

// const Record = () => {
//   const {
//     status,
//     startRecording,
//     stopRecording,
//     mediaBlobUrl,
//     getBlob,
//   } = useMediaRecorder();

//   const handleStartRecording = () => {
//     startRecording();
//   };

//   const handleStopRecording = () => {
//     stopRecording();
//     const blob = getBlob();
    
//     // Convert the Blob to base64
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       const base64Data = reader.result.split(',')[1];
//       console.log(base64Data);
//     };
//     reader.readAsDataURL(blob);
//   };

//   return (
//     <div>
//       {/* Your other components or UI elements */}
//       {status === 'idle' && (
//         <button onClick={handleStartRecording}>
//           Start Recording
//         </button>
//       )}

//       {status === 'recording' && (
//         <button onClick={handleStopRecording}>
//           Stop Sharing
//         </button>
//       )}

//       {/* Display the recorded media if available */}
//       {mediaBlobUrl && (
//         <video controls src={mediaBlobUrl} />
//       )}
//     </div>
//   );
// };

// export default Record;


// import { ReactMediaRecorder } from "react-media-recorder-2";

// const Record = () => (
//   <div>
//     <ReactMediaRecorder
//       screen
//       render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
//         <div>
//            <p>{status}</p>
//            <button onClick={startRecording}>Start Recording</button>
//            <button onClick={stopRecording}>Stop Recording</button>
//            <video src={mediaBlobUrl} controls autoPlay loop />
//         </div>
//       )}
//     />
//   </div>
// );
// export default Record;
// import { useReactMediaRecorder } from "react-media-recorder";
// const Record = () => {
//   const { status, startRecording, stopRecording, mediaBlobUrl } =
//     useReactMediaRecorder({ video: true });

//   return (
//     <div>
//       <p>{status}</p>
//       <button onClick={startRecording}>Start Recording</button>
//       <button onClick={stopRecording}>Stop Recording</button>
//       <video src={mediaBlobUrl} controls autoPlay loop />
//     </div>
//   );
// };
// export default Record;

import React, { useState, useEffect } from "react";

const Record = () => {
  const [stream, setStream] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);

  // useEffect(() => {
  //   const getCameraStream = async () => {
  //     const mediaStream = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //     });
  //     setStream(mediaStream);
  //   };

  //   getCameraStream();

  //   return () => {
  //     if (stream) {
  //       stream.getTracks().forEach((track) => track.stop());
  //     }
  //   };
  // }, []);

  const handleStartStreaming = async() => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio:true
    });
    setStream(mediaStream);
    setIsStreaming(true);
  };

  const handleStopStreaming = () => {
    // setIsStreaming(false);
    // if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    // }
  };

  return (
    <div>
      {isStreaming && <video srcObject={stream} />}
      <button onClick={handleStartStreaming}>Start Streaming</button>
      <button onClick={handleStopStreaming}>Stop Streaming</button>
    </div>
  );
};

export default Record;