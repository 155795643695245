import React, { lazy, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agentOfficeData } from "../../../../services/HomePageData";
import { checkVersionApi, saveUserNavigate } from "../../../../services/api";
import { getTopFourBlogs } from "../../../../Reducer/blogSlice";
import {
  bussinessAccountDetailFun,
  getUpdateData,
} from "../../../../Reducer/authSlice";
import LoadingSpinner from "../../../Tools/LoadingSpinner";
import MainLoader from "../../../Tools/MainLoader";
import LoginModel from "../../../Tools/LoginModel";
import moment from "moment";
import Meta from "../../Common/Meta";

const RecentBlogs = lazy(() => import("./recentBlogs"));
const StudentFooter = lazy(() => import("../../../Layouts/Student/Footer"));
const WhyChoose = lazy(() => import("./whyChoose"));
const WhatIs = lazy(() => import("./whatIs"));
const HowDoes = lazy(() => import("./howDoes"));
const ServicesSection = lazy(() => import("./services"));
const StudentHeader = lazy(() => import("../../../Layouts/Student/Header"));
const BannerSection = lazy(() => import("./bannerSection"));

const HomePage = () => {
  const { auth, otpLoading } = useSelector((state) => state.auth);
  const navbarRef = React.useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const additionalServices = {
    isMenuOpen,
    setIsMenuOpen,
  };
  const { topFourBlogs } = useSelector((state) => state.blogs);
  const token = localStorage.getItem("token");
  const guestId = localStorage.getItem("guestId");
  const dispatch = useDispatch();

  React.useEffect(() => {
    checkVersionFun();
    dispatch(getTopFourBlogs());
    if (auth && auth.role === 5) {
      dispatch(bussinessAccountDetailFun());
    }
    if (
      auth &&
      token &&
      (auth.role === 6 || auth.role === 1 || auth.role === 7)
    ) {
      dispatch(getUpdateData({ role: auth.role }));
    }
  }, []);

  const checkVersionFun = async () => {
    let name = "website";
    let version = "3.4.9";
    try {
      const body = {
        name,
        version,
      };
      const response = await checkVersionApi(body);
      if (response.data.status) {
        window.location.reload(true);
      }
    } catch (e) {}
  };

  const handleUserNavigate = (type, hit, from, description) => {
    try {
      const body = {
        id: token ? auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: from,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Meta
        homeSchema={true}
        title={"Chownk: Find the Best Immigration Consultants in 60 seconds"}
        description={
          "CHOWNK is a secure immigration platform connecting you with the best immigration consultants to complete your immigration process entirely online"
        }
        ogImage={"/assets/images/fileimg.png"}
      />
      <Suspense fallback={<MainLoader />}>
        {otpLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div ref={navbarRef}>
              <StudentHeader
                logo={"assets/images/chownk-logo.png"}
                bellIcon={"hedic1.png"}
                messageIcon={"hedic2.png"}
              />
              <BannerSection
                agentOfficeData={agentOfficeData}
                additionalServices={additionalServices}
                handleUserNavigate={handleUserNavigate}
              />
              <ServicesSection handleUserNavigate={handleUserNavigate} />
              <WhyChoose handleUserNavigate={handleUserNavigate} />
              <WhatIs
                show={show}
                setShow={setShow}
                handleUserNavigate={handleUserNavigate}
              />
              <HowDoes />
              {topFourBlogs && (
                <RecentBlogs
                  parentStyle={"recent_blogs"}
                  heading={"Recent Blogs"}
                  description={
                    "Stay Updated with Our Latest Immigration Insights!"
                  }
                  blogsData={topFourBlogs}
                  handleUserNavigate={handleUserNavigate}
                />
              )}
              <StudentFooter />
            </div>
            <LoginModel
              show={show}
              setShow={setShow}
              redirect={window.location.href}
            />
          </>
        )}
      </Suspense>
    </>
  );
};

// export default HomePage;
export default React.memo(HomePage);
