import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";

const AgentStatus = ({
  parentInlineStyle,
  image,
  heading,
  description,
  link,
  linkLabel,
  linkInHeading,
  onHide,
  style,
}) => {
  return (
    <div
      className={`${style && style} underreview mb-3`}
      style={parentInlineStyle}
    >
      <div className="img">
        <img src={image} alt="deadline1" />
      </div>
      {heading && (
        <h5>
          {heading} {linkInHeading && <Link to={link}>{linkInHeading}</Link>}{" "}
        </h5>
      )}
      {description && <p>{description}</p>}
      {link && linkLabel && <Link to={link}>{linkLabel}</Link>}
    </div>
  );
};
export default AgentStatus;
