const FormInputField = ({
  errors,
  accept,
  required,
  parentStyle,
  onChange,
  event,
  error,
  name,
  childStyle,
  label,
  inType,
  placeholder,
  message,
  messageStyle,
  defaultValue,
  onKeyPress,
  readOnly,
  disabled,
  prefix,
  key,
}) => {
  return (
    <div className={parentStyle}>
      {label && <label>{label}</label>}
      {prefix && <span>{prefix}</span>}
      <input
        className={childStyle}
        {...(event && event(name))}
        type={inType}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        accept={accept}
        required={required}
        defaultValue={defaultValue}
        onKeyPress={onKeyPress}
        readOnly={readOnly}
        disabled={disabled}
        key={key}
      />
      {message && <p style={{ color: messageStyle }}>{message}</p>}
      {errors && errors[name] && (
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )}
    </div>
  );
};

export default FormInputField;
