import React from "react";
import PaginationTable from "../Pagination";
import Option from "../Options";
import Button from "../Button";
const Table = ({
  rows,
  columns,
  limit,
  action,
  actionCloumn,
  actionDropdown,
}) => {
  const rowsCount = rows.length === 0 ? 1 : rows.length;
  const itemPerPage = limit ? limit : 10;
  const rowsShowLimit = Math.ceil(rowsCount / itemPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);
  const startIndex = (currentPage - 1) * itemPerPage;
  const endIndex = Math.min(startIndex + itemPerPage, rows.length);
  return (
    <>
      <div className="table-responsive">
        <table className="table mb-0">
          <thead>
            <tr>
              {columns.length > 0 &&
                columns.map((item, i) => {
                  return <th key={i}>{item.label}</th>;
                })}
              {actionCloumn &&
                actionCloumn.map((item, i) => {
                  return (
                    <th key={i} className={item.style}>
                      {item.label}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 &&
              rows.slice(startIndex, endIndex).map((item, i) => {
                return (
                  <tr key={i}>
                    {columns.map((column, i) => {
                      return <td key={i}>{item[column.name]}</td>;
                    })}
                    {action &&
                      action.length > 0 &&
                      action.map((actionItem, actionIndex) => {
                        return (
                          <td className="text-end" key={actionIndex}>
                            {actionItem.type === "button" ? (
                              <Button
                                inType={"button"}
                                title={actionItem.name}
                                onClick={() => actionItem.onClick(item.id)}
                              />
                            ) : !actionDropdown ? (
                              <a
                                key={i}
                                href="javascript:void(0)"
                                className={`${action.style ? "" : "Active"}`}
                                onClick={() =>
                                  actionItem.onClick(actionItem.id)
                                }
                              >
                                {actionItem.name}
                              </a>
                            ) : (
                              <select
                                key={i}
                                onChange={(e) =>
                                  actionItem.onchange(e, item.id)
                                }
                              >
                                {" "}
                                {actionItem.option &&
                                  actionItem.option.map((i, index) => {
                                    return "hold" === item.status ? (
                                      <React.Fragment key={index}>
                                        <Option
                                          value={i.value}
                                          title={i.title}
                                          disabled={true}
                                          selected={
                                            i.value === item.status
                                              ? true
                                              : false
                                          }
                                        />
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment key={index}>
                                        <Option
                                          value={i.value}
                                          title={i.title}
                                          disabled={i.disabled}
                                          selected={
                                            i.value === item.status
                                              ? true
                                              : false
                                          }
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                              </select>
                            )}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <PaginationTable
        rowsShowLimit={rowsShowLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        style={"d-inline-block"}
      />
    </>
  );
};
export default Table;
