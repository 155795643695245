import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDatadetailsapp } from "../../../../Reducer/applicationSlice";
import { mediaUrl } from "../../../../services/network";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Updateappreview } from "../../../../services/api";
import { toast } from "react-toastify";
import Applicationpopup from "../../../Layouts/Model/applicationpopup";
import Example from "../../../Tools/ReactBoostrap";

const ApplicationidApp = () => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.auth?.auth?.uid);
  let list = useSelector((state) => state.application?.single);
  const [showPopup, setShowPopup] = useState(false);
  const [FaqData, setFaqData] = useState("");
  console.log("listdataa", list);
  if (list && list !==undefined) {
    console.log("listdataitem", list);
  }
  const [formData, setFormData] = useState({
    appstatus: "",
  });

  const { id } = useParams();
  console.log("listdataitemafter", list);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  useEffect(() => {
    let formdata = new FormData();
    formdata.append("appid", id);
    dispatch(fetchDatadetailsapp(formdata));
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // alert(name)
    // alert(value)
  };

  const onSubmit = async (e) => {
    // alert(e.target.elements.appstatus.value)
    // e.preventDefault();
    //if appstatus ==accept check form submit or not

    let formdata = new FormData();
    formdata.append("appid", id);
    formdata.append("appstatus", formData.appstatus);
    formdata.append("review1", FaqData.review1);
    formdata.append("review2", FaqData.review2);
    if (formData.appstatus == "reject") {
      //do nothing
    } else {
      //accepted
      if (!list?.fname) {
        toast.error("Please submit details form then review", {
          position: toast.POSITION.TOP_LEFT,
        });
        return false;
      }
    }

    if (
      FaqData.review1 &&
      FaqData.review2 &&
      FaqData.review1 !== undefined &&
      FaqData.review2 !== undefined
    ) {
      const savecollege = await Updateappreview(formdata);
      toast.success("Review Submitted", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      toast.error("Error while Submission", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  return (
    <>
      {list?.application_status == "accept" ||
      list?.application_status == "reject" ||
      userid !== list?.receiver_id ? (
        ""
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <select
            onChange={(e) => {
              handleChange(e);
              togglePopup();
            }}
            name="appstatus"
            className="form-control"
          >
            <option value="">Select Status</option>
            <option value="accept">Accepted</option>
            <option value="reject">Rejected</option>
          </select>
          <button>Submit</button>
        </form>
      )}

      <Example
        show={showPopup}
        onHide={() => setShowPopup(!showPopup)}
        component={
          <Applicationpopup
            onHide={() => setShowPopup(!showPopup)}
            setFaqData={setFaqData}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />

      <div class="stuser">
        <h6>Application ID: {list?.id}</h6>
        <p>
          Status: <span>{list?.offer_status}</span>
        </p>
        <div class="user">
          <span class="img"></span>
          {list?.username}
          <ul>
            <li>
              <a href="#">
                <span
                  style={{
                    backgroundImage: `url(${mediaUrl + list?.profile_image})`,
                  }}
                  alt="icon"
                ></span>
              </a>{" "}
            </li>
            {/* <li><a href="#"><img src="images/call_ic2.png" alt="icon" /></a> </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ApplicationidApp;
