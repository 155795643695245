import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { mediaUrl } from "../../../../services/network";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../../Context";
import { studentPhoneVerificationApi } from "../../../../services/api";
const LoginSideBar = ({ sidemenu, setSidemenu }) => {
  const [defaultOption, setDefaultOption] = useState([]);
  const [additionalOption, setAdditionalOption] = useState([]);
  const normalSelector = useSelector((state) => state.auth);
  const dt = localStorage.getItem("dt");
  const {
    addFundsModal,
    setAddFundsModal,
    logout,
    setAllChats,
    deleteModal,
    setDeleteModal,
  } = useContext(SocketContext);

  const owner_name =
    normalSelector && normalSelector.auth && normalSelector.auth.owner_name;
  const token = localStorage.getItem("token");

  const agentOption = [
    {
      path: "/partner-dashboard",
      imageSrc: `../../../../assets/images/my_dashboard.svg`,
      label: "My Dashboard",
    },
    {
      label: "View Profile",
      imageSrc: `../../../../assets/images/view_profile.svg`,
      path: `${`/${
        normalSelector.auth &&
        normalSelector.auth.city &&
        normalSelector.auth.city.replaceAll(" ", "-")
      }/${normalSelector.auth && normalSelector.auth.slug}/`}`,
    },
    {
      path: "/partner/analytics",
      imageSrc: `../../../../assets/images/Account_Analytics.svg`,
      label: "Account Analytics",
      required:
        normalSelector.auth?.role === 5
          ? // && normalSelector.auth?.status === "active"
            false
          : true,
    },
    {
      path: "/partner/reports",
      imageSrc: `../../../../assets/images/My_Reports.svg`,
      label: "My Reports",
      required:
        normalSelector.auth?.role === 5
          ? // && normalSelector.auth?.status === "active"
            false
          : true,
    },
  ];

  const studentOption = [
    {
      path: "/applications/all",
      imageSrc: `../../../../assets/images/Applications.svg`,
      label: "My Applications",
    },
    {
      path: "/messages",
      imageSrc: `../../../../assets/images/hedic2_gray.svg`,
      label: "Messages",
    },
    {
      path: `${
        normalSelector.auth && normalSelector.auth?.role === 5
          ? "/account-settings"
          : `/profile/${normalSelector.auth && normalSelector.auth.id}`
      }`,
      imageSrc: `../../../../assets/images/Account_Setting.svg`,
      label: "Account Settings",
    },
    {
      heading: <span>{` INR ${normalSelector.funds}`}</span>,
      options: [
        {
          path: "",
          imageSrc: `../../../../assets/images/Add_Funds.svg`,
          label: "Add Funds",
          onClick: () => setAddFundsModal(!addFundsModal),
        },
        {
          path: `/transactions-list`,
          imageSrc: `../../../../assets/images/Transaction_History.svg`,
          label: "Transaction History",
        },
      ],
    },
    {
      path: `${normalSelector?.auth === 5 ? "/agent/help" : "/customers/help"}`,
      imageSrc: `../../../../assets/images/Help_Support.svg`,
      label: "Help & Support",
      style: "help_support",
    },
  ];
  const studentOptionWithoutFinances = [
    {
      path: "/applications/all",
      imageSrc: `../../../../assets/images/Applications.svg`,
      label: "My Applications",
    },
    {
      path: "/messages",
      imageSrc: `../../../../assets/images/hedic2_gray.svg`,
      label: "Messages",
    },
    {
      path: `${
        normalSelector.auth && normalSelector.auth?.role === 5
          ? "/account-settings"
          : `/profile/${normalSelector.auth && normalSelector.auth.id}`
      }`,
      imageSrc: `../../../../assets/images/Account_Setting.svg`,
      label: "Account Settings",
    },

    {
      heading: (
        <span>{` INR ${
          normalSelector && normalSelector.funds && normalSelector.funds
        }`}</span>
      ),
      options: [
        {
          path: "",
          imageSrc: `../../../../assets/images/Add_Funds.svg`,
          label: "Add Funds",
          onClick: () => setAddFundsModal(!addFundsModal),
        },
        {
          path: `/transactions-list`,
          imageSrc: `../../../../assets/images/Transaction_History.svg`,
          label: "Transaction History",
        },
      ],
    },
    {
      path: `${
        normalSelector?.auth === 5 ? "/partners/help" : "/customers/help"
      }`,
      imageSrc: `../../../../assets/images/Help_Support.svg`,
      label: "Help & Support",
      style: "help_support",
    },
  ];
  const staffOption = [
    {
      path: "/staff-dashboard",
      imageSrc: `../../../../assets/images/my_dashboard.svg`,
      label: "My Dashboard",
    },
    {
      label: "View Profile",
      imageSrc: `../../../../assets/images/view_profile.svg`,
      path: `/${
        normalSelector &&
        normalSelector.auth &&
        normalSelector.auth.company_city &&
        normalSelector.auth.company_name
          ? normalSelector.auth.company_city.replaceAll(" ", "-") +
            "/" +
            normalSelector.auth.company_name.replaceAll(" ", "-")
          : "/staff-dashboard"
      }`,
    },
    {
      path: "/partner/analytics",
      imageSrc: `../../../../assets/images/Account_Analytics.svg`,
      label: "Account Analytics",
      required:
        normalSelector.auth?.role === 6 &&
        normalSelector.auth?.status === "active"
          ? false
          : true,
    },
    {
      path: "/partner/reports",
      imageSrc: `../../../../assets/images/My_Reports.svg`,
      label: "My Reports",
      required:
        normalSelector.auth?.role === 6 &&
        normalSelector.auth?.status === "active"
          ? false
          : true,
    },
  ];

  useEffect(() => {
    switch (normalSelector.auth?.role) {
      case 5:
        setAdditionalOption(studentOption);
        setDefaultOption(agentOption);
        break;
      case 6:
        setAdditionalOption(studentOption);
        setDefaultOption(staffOption);
        break;
      case 4:
        setAdditionalOption([]);
        setDefaultOption(staffOption);
        break;
      default:
        setAdditionalOption(studentOptionWithoutFinances);
        setDefaultOption([]);
        break;
    }
  }, [normalSelector.funds]);

  const logoutFunction = () => {
    setAllChats([]);
    logout();
  };

  const onHide = () => {
    setSidemenu(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await studentPhoneVerificationApi({
        phone: normalSelector.auth?.phone,
      });
      if (response.status === 200) {
        setDeleteModal(!deleteModal);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`sidebar_mob ${sidemenu && dt && "show"}`}>
        <i className="bi bi-x-lg" onClick={() => setSidemenu(!sidemenu)}></i>
        {normalSelector.auth?.profile_image ? (
          <div
            className="images"
            style={{
              backgroundImage: `url(${mediaUrl}/${normalSelector.auth.profile_image})`,
            }}
          ></div>
        ) : (
          <div className="images">
            {owner_name &&
            owner_name !== undefined &&
            owner_name?.split(" ").length > 1
              ? owner_name?.split(" ")[0].substr(0, 1) +
                owner_name?.split(" ")[1].substr(0, 1)
              : owner_name?.split(" ")[0].substr(0, 1)}
          </div>
        )}

        <ul>
          {defaultOption &&
            defaultOption.length > 0 &&
            defaultOption.map((item, i) => {
              return (
                <li onClick={() => onHide()} key={i}>
                  <Link
                    to={!item.required && item.path}
                    onClick={() => {
                      if (item.required) {
                        // setCommonShow(true);
                      }
                    }}
                  >
                    <img src={item.imageSrc} alt="logo" /> {item.label}
                  </Link>
                </li>
              );
            })}
          {additionalOption.length > 0 &&
            additionalOption.map((item, i) => {
              if (item.options && item.options.length > 0) {
                return (
                  <li
                    onClick={() => onHide()}
                    className={item.style ? item.style : ""}
                    key={i}
                  >
                    <h4>
                      {"Balance :"}
                      {item.heading}
                    </h4>
                    <ul>
                      {item.options.map((_, i) => {
                        return (
                          <li className={_.style ? _.style : ""} key={i}>
                            <Link
                              to={_.path ? _.path : ""}
                              onClick={_.onClick ? _.onClick : ""}
                            >
                              <img src={_.imageSrc} alt="logo" /> {_.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li
                    onClick={() => onHide()}
                    className={item.style ? item.style : ""}
                    key={i}
                  >
                    <Link to={item.path}>
                      <img src={item.imageSrc} alt="logo" /> {item.label}
                    </Link>
                  </li>
                );
              }
            })}
          <li onClick={logoutFunction}>
            <Link>
              <img src={"../../../assets/images/logout.png"} alt="logo" />{" "}
              {"Logout"}
            </Link>
          </li>
          {dt && (
            <li>
              <Link onClick={() => handleDeleteAccount}>
                <img
                  src={"../../../assets/images/delete_account.svg"}
                  alt="logo"
                />{" "}
                {"Delete Account"}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default LoginSideBar;
