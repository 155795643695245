import { Link } from "react-router-dom";

const ReviewsBox = ({
  backgroundImage,
  name,
  rating,
  time,
  description,
  reply,
  replyOnclick,
  shareOnClick,
  reportOnClick,
}) => {
  return (
    <div className="rev">
      <div
        className="imgus"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <h3>
        {name}{" "}
        <label className="ms-4">
          {Array.from({ length: rating }, (_, i) => {
            return <i key={i} className="bi bi-star-fill"></i>;
          })}
        </label>
      </h3>
      <span className="float-end">{time}</span>
      <p>{description}</p>
      {reply && (
        <p className="text-end">
          {/* <a onClick={replyOnclick}>
            <i className="bi bi-reply-fill"></i> Reply
          </a> */}
          {/* <a onClick={shareOnClick}>
            <i className="bi bi-share-fill"></i> Share
          </a> */}
          <Link onClick={reportOnClick}>
            <i className="bi bi-flag-fill"></i> Report
          </Link>
        </p>
      )}
    </div>
  );
};
export default ReviewsBox;
