import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { contactSchema } from "../../../../services/schema";
import FormInputField from "../../../Tools/Forminput";
import SimpleInput from "../../../Tools/SimpleInput";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { secondUrl } from "../../../../services/network";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${secondUrl}v3/contact`, data);
      setIsLoading(false);
      if (response.status === 200) {
        toast.success("Form Updated Successfully");
        reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-sm-12">
          <label>First Name</label>
          <SimpleInput
            inType={"text"}
            style={"form-control"}
            event={register}
            name={"first_name"}
            errors={errors}
          />
        </div>
        <div className="col-sm-12">
          <label>Last Name</label>
          <SimpleInput
            inType={"text"}
            style={"form-control"}
            event={register}
            name={"last_name"}
            errors={errors}
          />
        </div>

        <div className="col-sm-12">
          <label>Email</label>
          <SimpleInput
            inType={"text"}
            style={"form-control"}
            event={register}
            name={"email"}
            errors={errors}
          />
        </div>
        <div className="col-sm-12">
          <label>Mobile Number</label>
          <SimpleInput
            inType={"text"}
            style={"form-control"}
            event={register}
            name={"phone"}
            errors={errors}
          />
        </div>
        <div className="col-sm-12">
          <label>Write your message</label>
          <textarea
            // placeholder="Write your message..."
            style={{ height: "51px;" }}
            name="description"
            {...register("description")}
          ></textarea>
          {errors && errors["description"] && (
            <p style={{ color: "red" }}>{errors["description"].message}</p>
          )}
        </div>
        <div className="col-sm-12 ">
          <button className="btn wow zoomIn" data-wow-delay=".2" type="submit">
            {isLoading ? <Spinner /> : "Send Message"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
