import React, { useState } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import { Link } from "react-router-dom";
import { register } from "../../../../serviceWorkerRegistration";
import { useEffect } from "react";
import { countMinusWithInputValue } from "../../../../services/function";

const AchievementTab = ({ currentStep, fields, append, register, errors }) => {
  const [count, setCount] = useState(
    fields.map((el) => ({ achievement: el.achievement.length }))
  );

  useEffect(() => {
    setCount(fields.map((el) => ({ achievement: el.achievement.length })));
  }, [fields]);

  const handleCount = (e, index) => {
    const newCharCounts = [...count];
    newCharCounts[index].achievement = e.target.value.length;
    setCount(newCharCounts);
  };
  return (
    <div
      className="tab"
      style={{ display: currentStep === 9 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          9. Add Achievements
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>
                Customers Can See this on your Profile Page and Feel Confident
                on your listing
              </p>
              <a
                href="../../assets/images/select13.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select13.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
      </div>

      <div className="form-group">
        {/* <p className="label">Achievement</p> */}
        {fields &&
          fields.length > 0 &&
          fields.map((field, index) => (
            <>
              <SimpleInput
                key={field.id}
                style="form-control"
                type="text"
                placeholder={`Enter your Achievement here`}
                name={`Achievement.${index}.achievement`}
                event={register}
                onChange={(e) => handleCount(e, index)}
              />
              <p className="text-end">{`${countMinusWithInputValue(
                150,
                count[index] ? count[index].achievement : 0
              )} Characters`}</p>
            </>
          ))}
      </div>
      {fields.length < 5 && (
        <Link className="addmore" onClick={() => append({ achievement: "" })}>
          <i className="bi bi-plus-circle"></i> Add More
        </Link>
      )}
    </div>
  );
};

export default AchievementTab;
