import React, { useState } from "react";
import Button from "../../Tools/Button";
import FormInputField from "../../Tools/Forminput";
import { countMinusWithInputValue } from "../../../services/function";
import SimpleInput from "../../Tools/SimpleInput";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const achievementSchema = yup.object({
  Achievements: yup
    .array()
    .of(
      yup.object({
        achievement: yup
          .string()
          .max(150, "Achievement must be at most 150 characters"),
      })
    )
    .max(150, "Achievement must be at most 150 characters"),
});

const AchievementModal = ({
  data,
  achievementsData,
  setAchievementsData,
  onHide,
  updateProfileFun,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(achievementSchema),
    defaultValues: {
      Achievements: [
        {
          achievement:
            achievementsData && achievementsData[0]
              ? achievementsData[0].achievement
              : "",
        },
        {
          achievement:
            achievementsData && achievementsData[1]
              ? achievementsData[1].achievement
              : "",
        },
        {
          achievement:
            achievementsData && achievementsData[2]
              ? achievementsData[2].achievement
              : "",
        },
        {
          achievement:
            achievementsData && achievementsData[3]
              ? achievementsData[3].achievement
              : "",
        },
        {
          achievement:
            achievementsData && achievementsData[4]
              ? achievementsData[4].achievement
              : "",
        },
      ],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "Achievements",
  });
  const [count, setCount] = useState({
    "Achievements.0.achievement":
      achievementsData && achievementsData[0]
        ? achievementsData[0].achievement.length
        : 0,
    "Achievements.1.achievement":
      achievementsData && achievementsData[1]
        ? achievementsData[1].achievement.length
        : 0,
    "Achievements.2.achievement":
      achievementsData && achievementsData[2]
        ? achievementsData[2].achievement.length
        : 0,
    "Achievements.3.achievement":
      achievementsData && achievementsData[3]
        ? achievementsData[3].achievement.length
        : 0,
    "Achievements.4.achievement":
      achievementsData && achievementsData[4]
        ? achievementsData[4].achievement.length
        : 0,
  });

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (value.length > 150) {
      setError(
        `Achievements.${index}.achievement`,
        "Achievement must be in 150 characters"
      );
    } else {
      clearErrors(`Achievements[${index}].achievement`);
      setCount({ ...count, [name]: value.length });
    }
  };

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("achievements", JSON.stringify(data.Achievements));
    updateProfileFun(formData);
    onHide();
  };

  return (
    <>
      <div className="modal-content">
        <Button inType={"button"} style={"btn-close"} />
        <div className="modal-body p-0">
          <div className="row">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h5>{"Add Achievement"}</h5>
              <p>{"Setting New Benchmarks in Excellence and Innovation"}</p>
              {fields.map((field, index) => (
                <>
                  <FormInputField
                    parentStyle={"form-group"}
                    childStyle={"form-control"}
                    placeholder={`Achievement ${index + 1}`}
                    name={`Achievements.${index}.achievement`}
                    event={register}
                    onChange={(e) => handleChange(e, index)}
                    errors={errors}
                  />
                  {errors &&
                    errors.Achievements &&
                    errors.Achievements[index] && (
                      <p style={{ color: "red" }}>
                        {errors.Achievements[index].achievement.message}
                      </p>
                    )}
                  <p id="title_count" className="characters">
                    {countMinusWithInputValue(
                      150,
                      count[`Achievements.${index}.achievement`]
                    )}{" "}
                    Characters
                  </p>
                </>
              ))}
              <Button
                style={"btn me-2"}
                inType={"submit"}
                title={"Add Achievement"}
              />
              <Button inType={"button"} title={"Cancel"} onClick={onHide} />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AchievementModal;
