import { useDispatch, useSelector } from "react-redux";
import { analyticsFollowers } from "../../../../Reducer/analyticSlice";
import FollowerMetricsGraph from "./followerMetricsGraph";
import FollwerHighlight from "./followersHighlight";
import { useEffect, useState } from "react";
import DateSelecter from "../../../Tools/DateSelecter";
import Example from "../../../Tools/ReactBoostrap";
import * as moment from "moment";
const Followers = ({ activeTab }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.analytic.analyticFollow);
  const [graph, setGraph] = useState([]);
  const [show, setShow] = useState(false);
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 30));
  const [dates, setDates] = useState({
    startDate: priorDate,
    endDate: today,
    key: "selection",
  });
  useEffect(() => {
    dispatch(analyticsFollowers(dates));
  }, [dates]);
  useEffect(() => {
    if (selector != null && selector.graph && selector.graph.length > 0) {
      let dts = selector.graph.map(function (x) {
        let newDate = moment(new Date(x.date)).format("DD-MM");
        return { ...x, date: newDate };
      });
      setGraph(dts);
    }
  }, [selector]);

  return (
    <div
      className={`tab-pane fade ${activeTab == "followers" && "show active"}`}
      id="Followers"
    >
      {/* <ExportOption
        parentStyle={"hdt"}
        active={activeTab}
        options={exportSectionOptions}
        actions={exportSectionAction}
      /> */}
      <FollwerHighlight data={selector} />
      <div class="d-flex align-items-center mb-4" onClick={() => setShow(!show)}>
        <h3 class="mb-3">
          <b className="select">{`${moment(dates.startDate).format("DD/MM/YYYY")} to ${moment(
            dates.endDate
          ).format("DD/MM/YYYY")}`}<i class="bi bi-chevron-down ms-2"></i></b>
        </h3>
      </div>
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={
          <DateSelecter
            onHide={() => setShow(!show)}
            dates={dates}
            setDates={setDates}
          />
        }
        style={"dateSelection"}
      />
      {graph && graph.length > 0 ? (
        <FollowerMetricsGraph
          parentStyle={"wap views_per mb-3"}
          title={"Follower Metrics"}
          description={
            "Visualize your follower count trends with our detailed graph."
          }
          imageSrc={"../../assets/images/follower_metrics.png"}
          data={graph}
        />
      ) : (
        ""
      )}
      {/* <FollowerDemoGraphics data={followerDemographicsData} /> */}
    </div>
  );
};
export default Followers;
