import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { helpFun } from "../../../../Reducer/helpSlice";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import $ from "jquery";
import Meta from "../../Common/Meta";
import {
  agentHelpMetaTags,
  customerHelpMetaTags,
} from "../../../../data/metaTags";
import NewHelp from "./newHelp";
import HelpHead from "./helpHead";
import moment from "moment";
import { saveUserNavigate } from "../../../../services/api";

const Help = ({ type }) => {
  const { user } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const selector = useSelector((state) => state.help);
  const userSelector = useSelector((state) => state.auth);
  const [searchData, setSearchData] = React.useState(
    location.state ? location.state : ""
  );
  const navigate = useNavigate();
  const guestId = localStorage.getItem("guestId");
  React.useEffect(() => {
    dispatch(
      helpFun({
        type: user === "partners" ? "business" : "user",
        search: searchData,
      })
    );
  }, [user]);

  const searchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      helpFun({
        type: user === "partners" ? "business" : "user",
        search: searchData,
      })
    );
  };

  const tabHandle = (user) => {
    switch (user) {
      case "user":
        navigate(`${"/customers/help"}`);
        handleUserNavigate("action", "/customers/help", "Customer help");
        break;
      case "business":
        navigate(`${"/partners/help"}`);
        handleUserNavigate("action", "/partners/help", "Partner help");
        break;
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.chat = "true";
    script.src = "//in.fw-cdn.com/31794956/927928.js";
    script.async = true;
    document.body.appendChild(script);
    $("#fc_frame").removeClass("d-none");
    return () => {
      document.body.removeChild(script);
      $("#fc_frame").addClass("d-none");
    };
  }, []);

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: userSelector.auth ? userSelector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  const searchFun = (e) => {
    dispatch(
      helpFun({
        type: user === "partners" ? "business" : "user",
        search: e.target.value,
      })
    );
    handleUserNavigate(
      "action",
      `search help ${e.target.value}`,
      "Search Help"
    );
  };

  return (
    <>
      {user === "partners" ? (
        <Meta {...agentHelpMetaTags} />
      ) : (
        <Meta {...customerHelpMetaTags} />
      )}

      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />
      <HelpHead
        searchData={searchData}
        searchFunction={(e) => searchFun(e)}
        setSearchData={setSearchData}
        searchSubmit={searchSubmit}
        handleUserNavigate={handleUserNavigate}
        user={user}
      />
      <NewHelp
        helpData={selector.help ? selector.help : []}
        user={user}
        tabHandle={tabHandle}
        handleUserNavigate={handleUserNavigate}
      />

      <StudentFooter />
    </>
  );
};

export default Help;
