const AgreeTermAndCondition = ({ onChange, isAgreed }) => {
  return (
    <>
      <p>
        <input
          type="checkbox"
          className="form-check-input"
          onChange={onChange}
          checked={isAgreed}
        />{" "}
        I agree to{" "}
        <a href="/terms-and-conditions" target="_blank">
          {"Terms & Conditions"}
        </a>
      </p>
      <p>
        <input type="checkbox" className="form-check-input" defaultChecked />{" "}
        Send updates on <a href="#">Whatsapp</a>
      </p>
    </>
  );
};
export default AgreeTermAndCondition;
