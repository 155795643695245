import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import TermsContent from "./termsContent";
import Meta from "../Meta";
import { TermsAndConditionMetaTags } from "../../../../data/metaTags";

const TermsAndCondition = () => {
  return (
    <>
      <Meta {...TermsAndConditionMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"TERMS AND CONDITIONS OF USAGE"} />
      <TermsContent />
      <StudentFooter />
    </>
  );
};

export default TermsAndCondition;
