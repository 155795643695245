export const baseUrl = "https://callshandlinglive.chownk.com/apis/v2/"; // Admin
export const secondUrl = "https://callshandlinglive.chownk.com//apis/"; // Normal User
export const mediaUrl = "https://callshandlinglive.chownk.com/"; // Media Url
export const blogsMediaUrl = "https://callshandlinglive.chownk.com/"; // Blogs Images
export const backEndUrl = "https://callshandlinglive.chownk.com/";                     

export const firebaseConfig = {
  apiKey: "AIzaSyCaZHM2L1uoDSIdy7Hy35e9u9eULNYF22Q",
  authDomain: "chownk.firebaseapp.com",
  projectId: "chownk",
  storageBucket: "chownk.appspot.com",
  messagingSenderId: "184393014062",
  appId: "1:184393014062:web:e99af5fbd196ef40c338bf",
  measurementId: "G-ETWKPEXVT6",
};
export const vap = "BDblvarh0ScIk6S-uuytEMazPlJdT-LHMZoEqoEIYr4ffhmlMW6FDBrTVrNUptJclhWNKxHm9HiCTerh8rTqs-4";