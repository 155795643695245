import { useContext, useState } from "react";
import { SocketContext } from "../../../../Context";
import { Tooltip } from "react-tooltip";
import $ from "jquery";
import { Link } from "react-router-dom";
import { welcomeApi } from "../../../../services/api";
import { useSelector } from "react-redux";
const DashboardWallet = () => {
  const { addFundsModal, setAddFundsModal } = useContext(SocketContext);
  const [showMsg, setShowMsg] = useState(
    localStorage.getItem("boostmsg") ? false : true
  );
  const selector = useSelector((state) => state.auth);

  const checkWalletMsg = async () => {
    try {
      const body = { type: "wallet" };
      const response = await welcomeApi(body);
    } catch (error) {}
  };
  return (
    <>
      <li className="btn-group ms-3 price">
        <Link className="plus" data-tooltip-id="funds">
          ₹{selector.funds}{" "}
          <i
            className="bi bi-plus-circle-fill"
            onClick={() => setAddFundsModal(!addFundsModal)}
          ></i>{" "}
        </Link>

        {showMsg &&
          selector.auth &&
          selector.auth?.wallet_notified === 1 &&
          (selector.auth?.role === 1 || selector.auth?.role === 7) && (
            <div className="boost">
              <i
                className="bi bi-x"
                onClick={() => {
                  $(".boost").addClass("d-none");
                  localStorage.setItem("boostmsg", true);
                  checkWalletMsg();
                }}
              ></i>
              <h4>Welcome Bonus</h4>
              <p>
                We have Credited Rs 50 into your account as Welcome Bonus.This
                can be used to Call Educational Consultants within our platform
              </p>
            </div>
          )}
      </li>{" "}
      <Tooltip id="funds" content="Add Funds to Wallet" />
    </>
  );
};
export default DashboardWallet;
