import React from "react";
import LiItem from "../../../Tools/LiItem";

const ContactUs = ({
  parentStyle,
  childStyle,
  heading,
  data,
  handleUserNavigate,
}) => {
  return (
    <div className={parentStyle}>
      <p>{heading}</p>
      <ul className={childStyle}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <LiItem
                  path={item.path}
                  label={item.label}
                  onClick={() =>
                    handleUserNavigate(
                      "navigate",
                      item.path,
                      `help ${item.label}`
                    )
                  }
                />
              </React.Fragment>
            );
          })}
      </ul>
    </div>
  );
};

export default ContactUs;
