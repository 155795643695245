import React from "react";
import LoginSideBar from "./loginSideBar";
import OfflineSideBar from "./OfflineSideBar";

const MobileSideBar = ({
  sidemenu,
  setSidemenu,
  otpShow,
  setOtpShow,
  show,
  setShow,
}) => {
  const token = localStorage.getItem("token");
  return (
    <>
      {token ? (
        <LoginSideBar sidemenu={sidemenu} setSidemenu={setSidemenu} />
      ) : (
        <OfflineSideBar
          sidemenu={sidemenu}
          setSidemenu={setSidemenu}
          otpShow={otpShow}
          setOtpShow={setOtpShow}
          show={show}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default MobileSideBar;
