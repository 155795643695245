import Option from "../Options";

const SimpleSelectOption = ({ style, inlineStyle, id, onClick, onChange, options }) => {
    return (<select
        id={id}
        className={style}
        style={inlineStyle}
        onClick={onClick}
        onChange={onChange}
    >
        {options && options.length > 0 && options.map((item) => {
            return <Option
                title={item.title}
                value={item.value}
                style={item.style}
                selected={item.selected}
                onChange={onChange}
            />
        })}

    </select>)
}
export default SimpleSelectOption;