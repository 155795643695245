import React, { useEffect, useState } from "react";
import { countMinusWithInputValue } from "../../../../services/function";
import SimpleTextArea from "../../../Tools/SimpleTextarea";

const AboutTab = ({
  currentStep,
  errors,
  register,
  user,
  setValue,
  getValues,
}) => {
  const [about, setAbout] = useState("");

  useEffect(() => {
    const about_val = getValues("about");
    if (about_val) {
      setAbout(about_val);
    }
  }, [user]);

  return (
    <div
      className="tab"
      style={{ display: currentStep === 6 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          {`6. Add About ${user.company_name}`}
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>This Will be About us of your Company</p>
              <a
                href="../../assets/images/select5.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select5.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
        <p>Add Description</p>
        <SimpleTextArea
          style="form-control"
          placeholder="Add Description"
          name="about"
          errors={errors}
          event={register}
          onChange={(e) => {
            setValue("about", e.target.value);
            setAbout(e.target.value);
          }}
        />
        <p className="text-end">
          {countMinusWithInputValue(1000, about.length)} Characters
        </p>
      </div>
    </div>
  );
};

export default AboutTab;
