import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleInput from "../../../Tools/SimpleInput";
import SimpleTextArea from "../../../Tools/SimpleTextarea";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../data/imagefun";
import { mediaUrl } from "../../../../services/network";
import { Player } from "video-react";
import {
  countMinusWithInputValue,
  getBase64,
} from "../../../../services/function";

const SuccessStoriesTab = ({
  currentStep,
  fields,
  append,
  register,
  errors,
  setValue,
  update,
  getValues,
  remove,
}) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [count, setCount] = useState(
    fields.map((el) => ({
      title: el.title.length,
      description: el.description.length,
    }))
  );

  useEffect(() => {
    setCount(
      fields.map((el) => ({
        title: el.title.length,
        description: el.description.length,
      }))
    );
  }, [fields]);

  const handleChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "video/mp4") {
        const currentField = getValues("Success");
        update(index, {
          title: currentField[index].title,
          description: currentField[index].description,
          image: file,
          media_type: "video",
        });
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
          setCurrentIndex(index);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedImage,
        croppedAreaPixels,
        0
      );
      getBase64(croppedImage).then((res) => {
        const currentField = getValues("Success");
        console.log(currentField, "croppedImage");
        update(currentIndex, {
          title: currentField[currentIndex].title,
          description: currentField[currentIndex].description,
          image: res,
          media_type: "image",
        });
        setSelectedImage("");
        setCurrentIndex(null);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const removeImage = (e, index) => {
    const currentField = fields[index];
    update(index, {
      ...currentField,
      image: "",
      media_type: "",
    });
  };

  const getBackgroundImage = (item) => {
    const base64Pattern =
      /^data:(image|video)\/([a-zA-Z0-9]+(\/[a-zA-Z0-9]+)*)?(;.*)?;base64,/;
    const isBase64 = base64Pattern.test(item);
    return isBase64 ? item : mediaUrl + item;
  };

  const handleInputChange = (index, field, value) => {
    const newCharCounts = [...count];
    newCharCounts[index][field] = value.length;
    setCount(newCharCounts);
  };

  return (
    <div
      className="tab"
      style={{ display: currentStep === 7 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          7. Add Success Story (minimum 3 required)
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important </span>?
            </a>
            <div className="dropdown-menu">
              <p>
                Show Customers your Company Past Experience or Success Stories,
                More you add Success Stories, more will the Chances to getting
                Customers
              </p>
              <a
                href="../../assets/images/select6.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select6.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
      </div>

      {fields &&
        fields.length > 0 &&
        fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <div className="form-group">
              <p className="label">Title</p>
              <SimpleInput
                key={field.id}
                style="form-control"
                type="text"
                placeholder="Add Title"
                name={`Success.${index}.title`}
                event={register}
                onChange={(e) =>
                  handleInputChange(index, "title", e.target.value)
                }
              />
              <p className="text-end">
                {`${countMinusWithInputValue(
                  60,
                  count[index] ? count[index].title : 0
                )} Characters`}
              </p>
              {errors && errors.Success && errors.Success[index] && (
                <p style={{ color: "red" }}>
                  {errors.Success[index].title &&
                    errors.Success[index].title.message}
                </p>
              )}
            </div>
            <div className="form-group">
              <p className="label">Description</p>
              <SimpleTextArea
                style="form-control"
                placeholder="Add Description"
                name={`Success.${index}.description`}
                event={register}
                onChange={(e) =>
                  handleInputChange(index, "title", e.target.value)
                }
              />

              <p className="text-end">
                {`${countMinusWithInputValue(
                  200,
                  count[index] ? count[index].description : 0
                )} Characters`}
              </p>
              {errors && errors.Success && errors.Success[index] && (
                <p style={{ color: "red" }}>
                  {errors.Success[index].description &&
                    errors.Success[index].description.message}
                </p>
              )}
            </div>
            {!field.image && (
              <div className="form-group mt-5">
                <p className="label">
                  Allowed file formats: JPG, PNG Maximum file size: 10MB
                </p>

                <div className="upload">
                  <SimpleInput
                    inType={"file"}
                    onChange={(e) => handleChange(e, index)}
                    accept={"image/* , video/mp4"}
                  />
                  <img
                    src="../../assets/images/uploadimg.png"
                    alt="uploadimg"
                  />
                  <h6>Drop your files here!</h6>
                  <p className="mb-0">or click</p>
                </div>
              </div>
            )}
            {selectedImage && currentIndex === index && (
              <div className="select_image">
                <Cropper
                  image={selectedImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={600 / 450}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
                <button
                  type="button"
                  className="cancel_btn"
                  onClick={() => setSelectedImage("")}
                >
                  Cancel
                </button>
                <button onClick={showCroppedImage} type="button">
                  Submit
                </button>
              </div>
            )}
            {field.image && (
              <div className="form-group">
                {field.media_type === "image" ? (
                  <div className="file mb-4">
                    <img
                      src={getBackgroundImage(field.image)}
                      alt=""
                      className="w-100"
                    />
                    <i
                      className="bi bi-x"
                      onClick={(e) => removeImage(e, index)}
                    ></i>
                  </div>
                ) : (
                  <div>
                    <Player
                      playsInline
                      src={
                        typeof field.image === "string"
                          ? `${mediaUrl}${field.image}`
                          : URL.createObjectURL(field.image)
                      }
                    />
                    <i
                      className="bi bi-x"
                      onClick={(e) => removeImage(e, index)}
                    ></i>
                  </div>
                )}

                {/* <p>
                  <b>File :</b> {field.image}
                </p> */}
              </div>
            )}
            {index > 0 && (
              <Link className="addmore me-2" onClick={() => remove(index)}>
                <i className="bi bi-dash-circle"></i> Remove
              </Link>
            )}
          </React.Fragment>
        ))}
      <Link
        className="addmore"
        onClick={() => append({ title: "", description: "", image: "" })}
      >
        <i className="bi bi-plus-circle"></i> Add More
      </Link>
    </div>
  );
};

export default SuccessStoriesTab;
