import React, { useState } from "react";
import SimpleInput from "../SimpleInput";
import { Link } from "react-router-dom";
import { mediaUrl } from "../../../services/network";

const InputImageBox = ({
  label,
  labelDescription,
  inputType,
  inputStyle,
  inputName,
  inputPlaceholder,
  fileStyle,
  fileName,
  filePlaceholder,
  event,
  errors,
  textOnChange,
  fileOnChange,
  fileStatus,
  image,
  msgClass,
  min,
  imageIcon,
  parentStyle,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const simulateUpload = (e) => {
    setSelectedImage(e.target.files[0]);
    if (fileOnChange) {
      fileOnChange(e);
    }
    const file = e.target.files[0];
    const fileSize = file.size;
    const chunkSize = 1024 * 1024; // 1 MB chunks (adjust as needed)
    let offset = 0;
    setMessage("");
    const intervalId = setInterval(() => {
      const end = offset + chunkSize;
      const chunk = file.slice(offset, end);

      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          offset = end;

          if (offset < fileSize) {
            // Calculate and update progress
            const uploadProgress = Math.round((offset / fileSize) * 100);
            setProgress(uploadProgress);
          } else {
            // Upload completed
            setProgress(100);
            setMessage("Image is Successfully Uploaded");
            clearInterval(intervalId); // Clear the interval
          }
        }
      };

      reader.readAsDataURL(chunk);
    }, 1000); // Update progress every 1 second (adjust as needed)
  };

  return (
    <div className={parentStyle}>
      <div className="col-md-5">
        <h5>{label}</h5>
        <p className="label">{labelDescription}</p>
      </div>
      <div className="col-md-7">
        {inputType && fileStatus !== "Approved" && (
          <SimpleInput
            style={"form-control"}
            inType={inputType}
            inlineStyle={inputStyle}
            name={inputName}
            event={event}
            placeholder={inputPlaceholder}
            onChange={textOnChange}
            min={min}
            readOnly={
              fileStatus === "Approved" || (fileStatus === "Hold" && true)
            }
          />
        )}
        {errors && errors[inputName] && (
          <p className="arr" style={{ color: "red" }}>
            <i>
              <i className="bi bi-info-circle-fill"></i>&nbsp;~{" "}
              {errors[inputName].message}
            </i>
          </p>
        )}
        {fileStatus === "Rejected" ||
        fileStatus === "Pending" ||
        !fileStatus ? (
          <SimpleInput
            style={"form-control"}
            inType={"file"}
            inlineStyle={fileStyle}
            name={fileName}
            // event={event}
            placeholder={filePlaceholder}
            onChange={simulateUpload}
            disabled={
              fileStatus === "Approved" || (fileStatus === "Hold" && true)
            }
            accept={"image/jpeg,image/jpg,image/png,application/pdf"}
          />
        ) : (
          <Link target="_blank" to={mediaUrl + image} className="View">
            {imageIcon.icon}
            {imageIcon.title}
          </Link>
        )}

        {message && (
          <span className="sendotp">
            <i className="bi bi-check-circle"></i>
          </span>
        )}
        {message ? (
          <p style={{ color: "green" }}>{message}</p>
        ) : (
          progress > 0 && <progress value={progress} max="100" />
        )}

        {/* <ProcessBar
                style={"process-bar"}
                role={"processbar"}
                childInlineStyle={{ width: "25%" }}
                areaValueNow={"25"}
                areaValueMin={"0"}
                areaValueMax={"100"}
            /> */}
        {(fileStatus === "Rejected" || fileStatus === "Pending") &&
          selectedImage === "" && (
            <p>
              {"Allowed Images JPG, JPEG and PNG having size less than 4 mb"}
            </p>
          )}
        {fileStatus && fileStatus === "Rejected" && (
          <span className={`Document-Rejected ${msgClass ? msgClass : ""}`}>
            <i className="bi bi-info-circle-fill"></i>Document Rejected
          </span>
        )}
        {fileStatus && fileStatus === "Approved" && (
          <span className={`Approved ${msgClass ? msgClass : ""}`}>
            <i className="bi bi-patch-check-fill"></i>Approved
          </span>
        )}
        {fileStatus && fileStatus === "Hold" && (
          <span className={`Under-Review ${msgClass ? msgClass : ""}`}>
            <i className="bi bi-pause-circle-fill"></i>Under Review
          </span>
        )}
        {errors && errors[fileName] && (
          <p className="arr" style={{ color: "red" }}>
            <i>
              {errors[fileName].message && (
                <>
                  <i className="bi bi-info-circle-fill"></i> &nbsp;
                  {errors[fileName].message}
                </>
              )}
            </i>
          </p>
        )}
      </div>
    </div>
  );
};

export default InputImageBox;
