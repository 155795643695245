import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../../../Context";
import Example from "../../../../Tools/ReactBoostrap";
import View from "./view";

const Notifications = () => {
  const { call, access } = useContext(SocketContext);
  const [userModel, setUserModel] = useState(call.isReceivingCall);
  useEffect(() => {
    // if (!userModel) {
    //   setUserModel(call.isReceivingCall);
    // }else{
      setUserModel(call.isReceivingCall);
    // }
  }, [call]);

  return (
    <>
      {call.isReceivingCall == true && !access && (
        <Example
          show={userModel}
          onHide={() => setUserModel(!userModel)}
          component={<View onHide={() => setUserModel(!userModel)} />}
          backDrop={"static"}
          style={!call.show ? "viewpro Incomming d-none" : "viewpro Incomming"}
        />
      )}
    </>
  );
};
export default Notifications;
