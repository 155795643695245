import { Link } from "react-router-dom";

const LandingBanner = () => {
  return (
    <section
      className="landing_banner"
      style={{ backgroundImage: "url(assets/images/landing_banner.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div class="patner">
              <h1>
                Get Genuine <br />
                Immigration leads
              </h1>
              <Link to={"/sign-up-as-partner"} className="brn">
                SIGN UP NOW
              </Link>
              <img
                class=""
                alt="two_types"
                src="../../assets/images/line_bcm.svg"
              />
            </div>
          </div>
          <div className="col-md-6 text-end">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/T5d94r2lkkA?si=_u_OwiYlA_9xT5FO"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingBanner;
