import React from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import OTPTimer from "../../../Tools/Otptimer";
import {
  bussinessAccountDetailFun,
  phoneVerification,
  setAction,
} from "../../../../Reducer/authSlice";
import Button from "../../../Tools/Button";
import { profileUpdateRequestApi } from "../../../../services/api";
import SimpleInput from "../../../Tools/SimpleInput";
import { mediaUrl } from "../../../../services/network";
const AccountSettingOtpModel = ({ duration, data, onHide }) => {
  const [otp, setOtp] = React.useState("");
  const [formCheckBox, setFormCheckBox] = React.useState(false);
  const [formWhatsapp, setFormWhatsapp] = React.useState(false);
  const { latitude, longitude, ip_address, address } = useSelector(
    (state) => state.auth.userDetails
  );
  const selector = useSelector((state) => state.auth);
  const { phoneNumber } = useSelector((state) => state.auth?.auth);
  const dispatch = useDispatch();
  const otpDuration = duration; // Adjust the OTP timer duration as needed
  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (!formCheckBox && !formWhatsapp) {
      toast.error(
        "Please check the term and condition and check the whatsapp update",
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );
    } else if (!formCheckBox) {
      toast.error("Please check the term and condition", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else if (!formWhatsapp) {
      toast.error("Please the whatsapp update", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      if (data.type) {
        formData.append("type", data.type);
      }

      if (data.owner_name) {
        formData.append("owner_name", data.owner_name);
      }
      if (data.company_name) {
        formData.append("company_name", data.company_name);
      }
      if (data.permanent_address) {
        formData.append("permanent_address", data.permanent_address);
      }
      formData.append("otp", otp);
      formData.append("latitude", data.latitude);
      formData.append("longitude", data.longitude);
      formData.append("city", data.city);
      formData.append("state", data.state);
      dispatch(setAction("accountSettingOtpModel"));
      profileUpdateRequestApi(formData)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_LEFT,
            });
            dispatch(bussinessAccountDetailFun());
            onHide();
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          onHide();
        });
    }
  };
  const onResendFun = () => {
    const bodyy = {
      phone: phoneNumber,
      latitude: latitude,
      longitude: longitude,
      ip_address: ip_address,
      current_address: address,
    };
    dispatch(phoneVerification(bodyy));
  };
  const buttonCondition = otp.length < 4;
  //  ||
  // formCheckBox === false ||
  // formWhatsapp === false;
  return (
    <div className="modal-content">
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={onHide}
      ></button>
      <div className="modal-body p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="lest_login text-center">
              <img src={`${mediaUrl}chownk-logo.png`} alt="logo" />
              <h3 className="text-center">{"OTP Verification"}</h3>
              <h6>
                {"An OTP has been sent to your"}
                <br></br>
                {"phone number"}{" "}
                <Link onClick={() => onHide()}>{"Change"}</Link>
              </h6>
              <form className="text-start" onSubmit={onSubmit}>
                <div className="form-group form-groupotp text-center">
                  <label className="d-block text-start">
                    {"Enter your mobile number"} {selector.otp}
                  </label>
                  <OTPInput
                    className={"form-control"}
                    inputStyle={{ width: "40px" }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <p>
                  <SimpleInput
                    inType={"checkbox"}
                    style={"form-check-input"}
                    onclick={() => setFormCheckBox(!formCheckBox)}
                  />
                  {/* <input type="checkbox" className="form-check-input" /> */}
                  {/* {" I agree to "} */}
                  <a href="/terms-and-conditions" target="_blank">
                    {"Terms & Conditions"}
                  </a>
                </p>
                <p>
                  <SimpleInput
                    inType={"checkbox"}
                    style={"form-check-input"}
                    onclick={() => setFormWhatsapp(!formWhatsapp)}
                  />

                  {/* <input type="checkbox" className="form-check-input" /> */}
                  {/* {" Send updates on "} */}
                  <Link>{"Whatsapp"}</Link>
                  {/* <a href="#">{"Whatsapp"}</a> */}
                </p>
                <h5 className="mt-3 text-center">
                  {"Resend OTP in"}{" "}
                  {otpDuration !== 0 && (
                    <OTPTimer
                      duration={otpDuration}
                      onTimeout={handleOTPTimerTimeout}
                      onResend={onResendFun}
                    />
                  )}
                </h5>
                <Button
                  inType={"submit"}
                  style={"btn mt-2 mt-lg-5"}
                  title={"Continue"}
                  disabled={buttonCondition}
                />

                {/* {console.log("formCheckBox", formCheckBox)} */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AccountSettingOtpModel;
