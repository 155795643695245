import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAfterSearchResultApi,
  getCitiesRoutes,
  getCompanyDetailsApi,
  getCompanyMediaApi,
  getReportCategoriesApi,
  getServiceLocations,
} from "../services/api";

export const getAfterSearchResult = createAsyncThunk(
  "getsearchresult",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await getAfterSearchResultApi(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyProfile = createAsyncThunk(
  "getCompanyDetails",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await getCompanyDetailsApi(slug);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReportCategories = createAsyncThunk(
  "getReportCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getReportCategoriesApi();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyMedia = createAsyncThunk(
  "getcompanymedia",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getCompanyMediaApi(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getCities = createAsyncThunk(
  "afterSearch/getCities",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getServiceLocations(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getDynamicRoutes = createAsyncThunk(
  "afterSearch/getDynamicRoutes",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getCitiesRoutes(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const afterSearchSlice = createSlice({
  name: "after_search",
  initialState: {
    submitting: false,
    success: false,
    mediaSuccess: false,
    error: null,
    meta: null,
    result: [],
    st: false,
    location: null,
    company_detail: {},
    company_media: [],
    report_categories: [],
    totalResults: 0,
    recentSearches: [],
    cities: [],
    dynamicRoutes: [],
  },
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
    saveSearches: (state, action) => {
      const searches = JSON.parse(localStorage.getItem("recentsearch"));
      if (action.payload !== "") {
        if (searches) {
          if (searches.includes(action.payload)) {
            const filter_search = searches.filter(
              (el) => el !== action.payload
            );
            const new_search = [action.payload, ...filter_search];
            localStorage.setItem("recentsearch", JSON.stringify(new_search));
          } else {
            const recent = [action.payload, ...searches];
            localStorage.setItem("recentsearch", JSON.stringify(recent));
          }
        } else {
          localStorage.setItem(
            "recentsearch",
            JSON.stringify([action.payload])
          );
        }
      }
    },
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
    getRecentSearches: (state, action) => {
      const searches = JSON.parse(localStorage.getItem("recentsearch"));
      state.recentSearches = searches;
    },
    clearRecentSearches: (state, action) => {
      localStorage.removeItem("recentsearch");
      state.recentSearches = [];
    },
    clearStates: (state, action) => {
      state.company_detail = {};
      state.company_media = [];
    },
    resetStates: (state) => {
      state.success = false;
      state.submitting = false;
      state.mediaSuccess = false;
      state.error = null;
      state.filterError = null;
      state.meta = null;
      state.result = [];
      state.location = null;
      state.company_detail = {};
      state.company_media = [];
      state.totalResults = 0;
      state.recentSearches = [];
      state.cities = [];
    },
  },
  extraReducers: (buider) => {
    buider
      .addCase(getAfterSearchResult.pending, (state, action) => {
        state.submitting = true;
        state.st = true;
        state.success = false;
        state.filterError = null;
        state.result = [];
        state.location = false;
      })
      .addCase(getAfterSearchResult.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.st = false;
        state.filterError = null;
        state.result = action.payload.data[0];
        state.totalResults = action.payload.data[1][0].total;
        state.meta = action.payload.meta;
        state.location = action.payload.location;
      })
      .addCase(getAfterSearchResult.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.st = false;
        state.filterError = action.payload;
      })
      .addCase(getCompanyProfile.pending, (state, action) => {
        state.submitting = true;

        state.success = false;
        state.error = null;
        state.company_media = [];
      })
      .addCase(getCompanyProfile.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.company_detail = action.payload;
      })
      .addCase(getCompanyProfile.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getCompanyMedia.pending, (state, action) => {
        state.submitting = true;
        state.mediaSuccess = false;
        state.error = null;
        state.company_media = [];
      })
      .addCase(getCompanyMedia.fulfilled, (state, action) => {
        state.submitting = false;
        state.mediaSuccess = true;
        state.error = null;
        state.company_media = action.payload;
      })
      .addCase(getCompanyMedia.rejected, (state, action) => {
        state.submitting = false;
        state.mediaSuccess = false;
        state.error = action.payload;
      })
      .addCase(getCities.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.cities = action.payload.data;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getDynamicRoutes.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getDynamicRoutes.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.dynamicRoutes = action.payload.data;
      })
      .addCase(getDynamicRoutes.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getReportCategories.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getReportCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.report_categories = action.payload;
      })
      .addCase(getReportCategories.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const {
  clearError,
  saveSearches,
  getRecentSearches,
  clearRecentSearches,
  clearStates,
  resetStates,
  setMeta,
} = afterSearchSlice.actions;

export default afterSearchSlice.reducer;
