import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AgentLogin, Otp } from "../Components";
import {
  AgentRoute,
  FreeRoute,
  commonRoute,
  expertRoute,
  staffRoute,
  studentRoute,
} from "./route";
import PrivateRoute from "../PrivateRoute";
import Error from "../Components/Pages/Common/Error";
import { useSelector } from "react-redux";
import { RemoveTrailingSlash } from "../removeTrailingSlash";
import Meta from "../Components/Pages/Common/Meta";
import Testing from "../Components/Pages/Student/Testing";

const RouteWebPage = ({ normalToken }) => {
  const selector = useSelector((state) => state.auth.auth);
  const location = useLocation();

  return (
    <>
      <RemoveTrailingSlash />
      <Meta
        canonical={true}
        redirect={`${window.location.origin}${location.pathname}`}
      />
      <Routes>
        <Route exact path="/PageNotFound" element={<Error />} />
        <Route exact path="/login" element={<AgentLogin />} />
        <Route exact path="/testing" element={<Testing />} />
        <Route exact path="/otp" element={<Otp />} />
        {AgentRoute.length > 0 &&
          AgentRoute.map((item) => {
            return (
              <React.Fragment key={item.path}>
                {item.role === selector?.role && (
                  <Route
                    exact
                    path={item.path}
                    element={
                      <PrivateRoute
                        auth={normalToken}
                        component={item.component}
                      />
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
        {expertRoute.length > 0 &&
          expertRoute.map((item) => (
            <React.Fragment key={item.path}>
              {item.role === selector?.role && (
                <Route
                  exact
                  path={item.path}
                  element={
                    <PrivateRoute
                      auth={normalToken}
                      component={item.component}
                    />
                  }
                />
              )}
            </React.Fragment>
          ))}
        {FreeRoute.length > 0 &&
          FreeRoute.map((item) => {
            return (
              <React.Fragment key={item.path}>
                {<Route exact path={item.path} element={item.component} />}
              </React.Fragment>
            );
          })}
        {studentRoute.length > 0 &&
          studentRoute.map((item) => {
            return (
              <React.Fragment key={item.path}>
                {selector?.role === 1 ? (
                  <Route
                    exact
                    path={item.path}
                    element={
                      <PrivateRoute
                        auth={normalToken}
                        component={item.component}
                      />
                    }
                  />
                ) : (
                  <Route exact path={item.path} element={<Error />} />
                )}
              </React.Fragment>
            );
          })}
        {staffRoute.length > 0 &&
          staffRoute.map((item, i) => {
            return (
              <React.Fragment key={item.path}>
                {selector?.role === 6 && (
                  <Route
                    exact
                    path={item.path}
                    element={
                      <PrivateRoute
                        auth={normalToken}
                        component={item.component}
                      />
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
        {commonRoute.length > 0 &&
          commonRoute.map((item) => {
            return (
              <React.Fragment key={item.path}>
                {selector?.role === 1 ||
                selector?.role === 5 ||
                selector?.role === 6 ? (
                  <Route
                    exact
                    path={item.path}
                    element={
                      <PrivateRoute
                        auth={normalToken}
                        component={item.component}
                      />
                    }
                  />
                ) : (
                  <Route exact path={item.path} element={<Error />} />
                )}
              </React.Fragment>
            );
          })}
        <Route exact path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default RouteWebPage;
