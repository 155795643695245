import { useDispatch, useSelector } from "react-redux";
import {
  exportSectionOptions,
  followerMetricsColumn,
  pageViewsColumn,
} from "../../../../services/Analytics";
import AnalyticsTableBox from "../../../Tools/AnalysisTableBox";
import ExportOption from "./exportOption";
import { useState, useEffect } from "react";
import { analyticsCompitator } from "../../../../Reducer/analyticSlice";
import Example from "../../../Tools/ReactBoostrap";
import CompetitorModel from "./CompetitorModel";

const Competitors = ({ activeTab }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.analytic.compitator);
  const [show, setShow] = useState(false);
  useEffect(() => {
    dispatch(analyticsCompitator());
  }, []);
  const exportSectionAction = [
    // {
    //   label: "Export",
    //   style: "export",
    //   imageSrc: "../../assets/images/Export.png",
    //   onclick: (data) => {},
    // },
    {
      label: "Edit Competitors",
      style: "export2",
      imageSrc: "bi bi-pencil-square",
      onclick: (data) => {
        setShow(!show);
      },
    },
  ];

  return (
    <div
      className={`tab-pane fade ${activeTab == "competitors" && "show active"}`}
      id="Competitors"
      role="tabpanel"
      aria-labelledby="Competitors-tab"
    >
      <ExportOption
        parentStyle={"hdt"}
        active={activeTab}
        options={exportSectionOptions}
        actions={exportSectionAction}
      />
      <div class="wap views_per mb-3">
        <h4>
          <img
            src="../../assets/images/follower_highlights.png"
            alt="Call_History"
          />{" "}
          Competitors
        </h4>
        <p>
          Keep an eye on your competition with CHOWNK’s Competitors page. Stay
          ahead by monitoring key metrics of your competitors.
        </p>
      </div>
      {selector && selector.viewers ? (
        <AnalyticsTableBox
          title={"Page Views"}
          description={
            "See how many views your competitors' pages are getting."
          }
          imageSrc={"../../assets/images/eye.png"}
          columns={pageViewsColumn}
          rows={selector.viewers}
        />
      ) : (
        ""
      )}
      {selector && selector.followers ? (
        <AnalyticsTableBox
          title={"Follower Metrics"}
          description={"Track the follower count of your competitors."}
          imageSrc={"../../assets/images/follower_metrics.png"}
          columns={followerMetricsColumn}
          rows={selector.followers}
        />
      ) : (
        ""
      )}
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={<CompetitorModel onHide={() => setShow(!show)} />}
        style={"loginmodal study_buddies competitors"}
      />
    </div>
  );
};
export default Competitors;
