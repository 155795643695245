import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  studentOtpVerificationApi,
  studentPhoneVerificationApi,
} from "../services/api";
import { toast } from "react-toastify";
export const studentPhoneVerification = createAsyncThunk(
  "phoneVerification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentPhoneVerificationApi(formData);

      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      return rejectWithValue(error.response);
    }
  }
);

export const studentOtpVerification = createAsyncThunk(
  "otpVerification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await studentOtpVerificationApi(formData);

      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      return rejectWithValue(error.response);
    }
  }
);

const studentSlice = createSlice({
  name: "student",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    otpSuccess: false,
    loading: false,
    user: {},
    otp: "",
    phoneNumber: "",
    token: "",
    redireaction: "",
    message: "",
    otpMessage: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    clearUser: (state) => {
      state.auth = null;
      state.success = false;
      localStorage.removeItem("normalUser");
    },
    clearToken: (state) => {
      state.token = null;
      state.success = false;
      localStorage.removeItem("normalToken");
    },
    clearStatus: (state) => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(studentPhoneVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
        state.loading = true;
      })
      .addCase(studentPhoneVerification.fulfilled, (state, action) => {
        state.submitting = false;
        state.otp = action.payload.otp;
        state.loading = false;
        state.success = true;
      })
      .addCase(studentPhoneVerification.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.error;
        state.success = false;
        state.loading = false;
      })

      .addCase(studentOtpVerification.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
        state.loading = true;
      })
      .addCase(studentOtpVerification.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload;
        state.user = action.payload.user;
        state.otpMessage = action.payload.message;
        state.redireaction = action.payload.redireaction;
        state.token = action.payload.token;
        state.loading = false;
        state.otpSuccess = true;
      })
      .addCase(studentOtpVerification.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.error;
        state.success = false;
        state.loading = false;
      });
  },
});
export const {
  setUser,
  setPhoneNumber,
  setToken,
  setOtp,
  clearUser,
  clearToken,
  clearStatus,
} = studentSlice.actions;
export default studentSlice.reducer;
