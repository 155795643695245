import Chatting from "./Chatting";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import { SocketContext } from "../../../../Context";
import { useContext, useEffect, useState } from "react";
import UserHeader from "./UserHeader";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import Meta from "../../Common/Meta";
import $ from "jquery";
import StudentHeader from "../../../Layouts/Student/Header";
import { mediaUrl } from "../../../../services/network";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Example from "../../../Tools/ReactBoostrap";
import NewCreateOfferModal from "../../../Layouts/Model/createOfferModal";
import { useSelector } from "react-redux";
import MobileFooter from "../../../Layouts/MobileFooter";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

function Chat() {
  const {
    screenWidth,
    chatUser,
    setCurrent,
    currentUser,
    ChatfirstEmits,
    chatavail,
    show,
    setCurrentUser,
    setShow,
    chatlist,
    socket,
  } = useContext(SocketContext);
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [msgId, setMsgId] = useState("");
  const locationf = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(locationf.search);
  const role = useSelector((state) => state.auth?.auth?.role);
  const dt = localStorage.getItem("dt");

  useEffect(() => {
    ChatfirstEmits();
  }, [socket]);

  useEffect(() => {
    if (screenWidth < 768) {
      const elem = document.getElementsByClassName("test test-right pt-3");
      if (elem && elem[0] && elem[0].classList.contains("main")) {
        setShowHeader(false);
      }
    }
    if (
      chatUser.length > 0 &&
      Object.keys(currentUser).length === 0 &&
      screenWidth > 768
    ) {
      const user = chatUser[0];
      setCurrent(user);
    } else if (
      query &&
      query.get("msg") &&
      query.get("msg") !== "" &&
      chatUser.length > 0
    ) {
      let filter = chatUser.filter(
        (d) => d.connection_user == Number(query.get("msg"))
      );
      const usert = filter[0];
      setCurrent(usert);
      query.delete("msg");
    } else if (state && state.nav) {
      $(".test").addClass("main");
    }
  }, [chatUser, locationf]);

  const checkUser = () => {
    switch (role) {
      case 5:
        return "/partner-dashboard";
      case 6:
        return "/staff-dashboard";
      default:
        return "/";
    }
  };

  const handleBack = ()=>{
    if(chatUser.length==0){
      setCurrentUser({});
    } 
    $(".chat_user").addClass("main");
  }

  useEffect(() => {
    const handleTestChange = () => {
      if (screenWidth < 768) {
        const elem = document.querySelector(".test.test-right.pt-3");
        if (elem && elem.classList.contains("main")) {
          setShowHeader(false);
        } else {
          setShowHeader(true);
        }
      }
    };

    // Initial check
    handleTestChange();

    // Event listener for DOM changes

    const observer = new MutationObserver(handleTestChange);
    observer.observe(document.body, {
      subtree: true,
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <Meta title={"Messages: Chownk"} />

      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
        sts={
          screenWidth > 768 || (locationf.pathname !== "messages" && showHeader)
            ? true
            : "responsive"
        }
      />

      <section
        className="dashboard"
        // style={{ backgroundImage: `url(../assets/images/login_screen.png)` }}
      >
        <div className={`dashboard_in header_msg ${dt ? "app_msg" : ""}`}>
          <div className="container-fluid">
            {(chatUser && chatUser.length > 0) || chatavail || (currentUser && Object.keys(currentUser).length !== 0) ? (
              <div className="chatdiv">
                <div className="main_user main_user2">
                  {chatUser.length > 0 || chatavail || (currentUser && Object.keys(currentUser).length !== 0) ? (
                    <>
                      <LeftSidebar page={page} setPage={setPage} />
                      {currentUser && Object.keys(currentUser) !== 0 ? (
                        <div className="test test-right pt-3">
                          <span
                            className="divclose"
                            onClick={() => {
                              $(".test").removeClass("main");
                            }}
                          >
                            <i
                              onClick={() => handleBack()}
                              className="bi bi-arrow-down-short"
                            ></i>
                          </span>
                          <UserHeader
                            currentUser={currentUser}
                            ulStyle={"call_video_2"}
                          />
                          <Chatting
                            currentUser={currentUser}
                            page={page}
                            setPage={setPage}
                            searching={searching}
                            setSearching={setSearching}
                            msgId={msgId}
                          />
                          <RightSidebar
                            page={page}
                            setPage={setPage}
                            searching={searching}
                            setSearching={setSearching}
                            msgId={msgId}
                            setMsgId={setMsgId}
                          />
                        </div>
                      ) : (
                        <div className="no_chat">
                          <img src="../assets/images/Google_Chat_Logo_512px.png" />
                          <h4>Start chat</h4>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="loaderImage">
                      <img src={mediaUrl + "loader.gif"} />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="no_chats">
                <img src={mediaUrl + "emp-chat.png"} alt="deadline1" />
                <p> No Messages yet, start the conversation!</p>
                {/* <Link to={checkUser()}>
                  <i className="bi bi-house-door-fill"></i> Back to Home
                </Link> */}
                {screenWidth <= 767 && dt && <MobileFooter />}
              </div>
            )}
          </div>
        </div>
        <Example
          style={"pop_width CreateOffer"}
          show={show}
          component={<NewCreateOfferModal show={show} setShow={setShow}/>}
          onHide={() => setShow(!show)}
        />
      </section>
    </>
  );
}
export default Chat;
