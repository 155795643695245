import { Link, useNavigate } from "react-router-dom";
import { mediaUrl } from "../../../services/network";
const LoginButton = ({
  onClick,
  heading,
  path,
  hideLocation,
  darkIcon,
  handleUserNavigate,
}) => {
  const navigate = useNavigate();
  return (
    <ul className="nologin">
      <li className="btn-group app_download">
        <Link className="dropdown-toggle" to={"/download-app"}>
          <img
            src="../../../assets/images/chownk-download-mobile-app.png"
            alt="Download CHOWNK mobile app"
          />
        </Link>
        <ul
          className="dropdown-menu dropdown-menu-end"
          data-popper-placement="bottom-end"
          onClick={() => {
            if (handleUserNavigate) {
              handleUserNavigate("navigate", "/download-app", "download app");
            }
            navigate("/download-app");
          }}
        >
          <p>Download Mobile app</p>
          <p>
            Receive instant notifications, engage in real- time chat, and make
            calls effortlessly
          </p>
          <img src="../../../assets/images/mobst.png" alt="mobst" />
        </ul>
      </li>
      <li className="btn-group" onClick={() => navigate("/demo")}>
        <Link className="take_a_tour">Take a tour</Link>
      </li>
      {!hideLocation && !darkIcon && (
        <li
          className="bcm"
          onClick={() => {
            if (handleUserNavigate) {
              handleUserNavigate(
                "navigate",
                "/become-consultant",
                "become a partner"
              );
            }
            navigate(path);
          }}
        >
          <Link>
            <img
              src={`../../../../assets/images/become-consultant-chownk.svg`}
              alt="Become a consultant at CHOWNK"
            />
            <span> {heading}</span>
          </Link>
        </li>
      )}
      <li>
        <Link
          className="logi"
          onClick={() => {
            onClick();
            if (handleUserNavigate) {
              handleUserNavigate("action", "login", "login Modal click");
            }
          }}
        >
          <img src={`${mediaUrl}Login.png`} alt="Login icon - CHOWNK" />
          <img
            src={
              darkIcon
                ? `${mediaUrl}Consultants_color.png`
                : `${mediaUrl}Consultants_color.png`
            }
            className="color"
            alt="consultants"
          />
          <span>{"Login / Register"}</span>
        </Link>
      </li>
      {darkIcon && (
        <li onClick={() => navigate(path)}>
          <Link>
            <img
              src={`../../../assets/images/become_a_partner_blue.svg`}
              alt="become_a_partner"
            />{" "}
            {heading}
          </Link>
        </li>
      )}
    </ul>
  );
};

export default LoginButton;
