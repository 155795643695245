import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import AboutUsContent from "./aboutUsContent";
import Meta from "../Meta";
import { AboutUsMetaTags, SitemapMetaTags } from "../../../../data/metaTags";

const AboutUs = () => {
  return (
    <>
      <Meta {...AboutUsMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"About Us"} />
      <AboutUsContent />
      <StudentFooter />
    </>
  );
};

export default AboutUs;
