const AdditionalServiceBox = ({
  parentStyle,
  title,
  descriptionStyle,
  description,
  singleDiv
}) => {
  return (singleDiv ?
    <div className={parentStyle}>
      <h5>{title}</h5>
      <p className={descriptionStyle}>{description}</p>
    </div>
    : <div className="addi mb-3">
      <i className="fa-solid fa-passport"></i>
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  );
};
export default AdditionalServiceBox;
