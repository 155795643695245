export const singleProfileReviewsData = [
  {
    id: 1,
    user_image: "assets/images/user.png",
    name: "Meavwe Willy",
    rating: 5,
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    created_at: "10:30 AM 10 June 2023",
  },
  {
    id: 2,
    user_image: "assets/images/user.png",
    name: "Meavwe Willy",
    rating: 5,
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    created_at: "10:30 AM 10 June 2023",
  },
  {
    id: 3,
    user_image: "assets/images/user.png",
    name: "Meavwe Willy",
    rating: 5,
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    created_at: "10:30 AM 10 June 2023",
  },
];

export const singleProfileExamsOffersData = [
  {
    id: 1,
    image: "assets/images/ielts.png",
    title: "IELTS",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry.",
  },
  {
    id: 2,
    image: "assets/images/ielts.png",
    title: "IELTS",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry.",
  },
  {
    id: 3,
    image: "assets/images/ielts.png",
    title: "IELTS",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry.",
  },
];

export const singleProfileAdditionalService = [
  {
    id: 1,
    title: "Title",
    description:
      "Lorem Ipsum is dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since 1500s.",
  },
  {
    id: 2,
    title: "Title",
    description:
      "Lorem Ipsum is dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since 1500s.",
  },
  {
    id: 3,
    title: "Title",
    description:
      "Lorem Ipsum is dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since 1500s.",
  },
];

export const singleProfileOption = [
  {
    id: "tab1-tab",
    title: "Reviews",
    iconStyle: "fa-solid fa-star",
    name: "reviews",
  },
  {
    id: "tab2-tab",
    title: "Exams Offers",
    iconStyle: "fa-solid fa-file-invoice",
    name: "exams_offers",
  },
  {
    id: "tab3-tab",
    title: "Additional Service",
    iconStyle: "fa-solid fa-passport",
    name: "additional_service",
  },
];
