import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { popularSearchData } from "../../../../data/data";
import { googleMapApi } from "../../../../data/data";

const Intrested = ({ handleUserNavigate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const checkType = (item) => {
    switch (item.type) {
      case "specialization":
        handleClick(item.path);
        handleUserNavigate("navigate", item.path, "intrested searches");
        return;
      case "location":
        Fetchlocation();
        return;
      case "fraud":
        handleClick("/how-to-avoid-immigration-fraud-from-agents");
        handleUserNavigate(
          "navigate",
          "/how-to-avoid-immigration-fraud-from-agents",
          "intrested searches"
        );
    }
  };

  const handleClick = (value) => {
    navigate(value);
  };

  const Fetchlocation = (ipAddress) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      fetchLocationInfo(latitude, longitude, ipAddress);
    });
  };

  const fetchLocationInfo = (latitude, longitude, ipAddress) => {
    const apiKey = googleMapApi;
    let cities = "";
    const apiUrl = `https://aps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        await data.results.forEach((i, index) => {
          if (i.types.includes("administrative_area_level_3")) {
            cities = i.address_components[0].long_name;
            queryParams.set("city", cities.replaceAll(" ", "-"));
            queryParams.set("lat", latitude);
            queryParams.set("long", longitude);
            const newSearchString = queryParams.toString();
            navigate({ pathname: "/near-me", search: newSearchString });
            handleUserNavigate("navigate", "/near-me", "intrested searches");
          }
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  return (
    <div className="maybe">
      <h2>
        You maybe <span>Interested</span> in
      </h2>
      <ul>
        {popularSearchData &&
          popularSearchData.length > 0 &&
          popularSearchData.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                checkType(item);
              }}
            >
              <Link to={item.path}>{item.label}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Intrested;
