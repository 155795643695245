import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ApplicationSchema } from "../../../../services/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { fetchDatadetailsapp } from "../../../../Reducer/applicationSlice";

import SimpleInput from "../../../Tools/SimpleInput";
import { Savecollegedetails } from "../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
const Detailsapp = () => {
  const userApplication = useSelector((state) => state.auth?.auth?.role);
  const userid = useSelector((state) => state.auth?.auth?.uid);

  const emailregExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const phoneRegExp = /^[0-9]{10}$/;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(ApplicationSchema),
  });

  const { id } = useParams();
  const dispatch = useDispatch();

  let list = useSelector((state) => state.application?.single);
  console.log("dataaa", list);

  useEffect(() => {
    let formdata = new FormData();
    formdata.append("appid", id);
    dispatch(fetchDatadetailsapp(formdata));
  }, [id]);

  useEffect(() => {
    // alert(list?.expirydate)
    setValue("fname", list?.fname);
    setValue("lname", list?.lname);
    setValue("gender", list?.gender);
    setValue("dob", list?.dob);
    setValue("fathername", list?.fathername);
    setValue("mobile1", list?.mobile1);
    setValue("mothername", list?.mothername);
    setValue("mobile2", list?.mobile2);
    setValue("email", list?.email);
    setValue("address", list?.address);
    setValue("city", list?.city);
    setValue("state", list?.state);
    setValue("zipcode", list?.zipcode);
    setValue("highqualification", list?.highqualification);
    setValue("yearofpassing", list?.yearofpassing);
    setValue("score", list?.score);
    setValue("boardofedu", list?.boardofedu);
    setValue("institute", list?.institute);
    setValue("testattempt", list?.testattempt);
    setValue("examdate", list?.examdate);
    setValue("overallscore", list?.overallscore);
    setValue("listening", list?.listening);
    setValue("reading", list?.reading);
    setValue("writing", list?.writing);
    setValue("speaking", list?.speaking);
    setValue("document", list?.document);
    setValue("documentnumber", list?.documentnumber);
    setValue("expirydate", list?.expirydate);
    setValue("academic", list?.academic);
    setValue("attachmentimg", list?.attachmentimg);
  }, [list]);

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    gender: "",
    dob: "",
    fathername: "",
    mobile1: "",
    mothername: "",
    mobile2: "",
    email: "",
    address: "",
    city: "",
    zipcode: "",
    highqualification: "",
    yearofpassing: "",
    score: "",
    boardofedu: "",
    institute: "",
    testattempt: "",
    examdate: "",
    overallscore: "",
    listening: "",
    reading: "",
    writing: "",
    speaking: "",
    documentnumber: "",
    expirydate: "",
  });

  const handleSubmitt = async (e) => {
    console.log("valuedata", e);
    // e.preventDefault();
    // alert(id)
    let formdata = new FormData();
    formdata.append("appid", id);
    formdata.append("fname", e.fname);
    formdata.append("lname", e.lname);
    formdata.append("gender", e.gender);
    formdata.append("dob", e.dob);
    formdata.append("fathername", e.fathername);
    formdata.append("mobile1", e.mobile1);
    formdata.append("mothername", e.mothername);
    formdata.append("mobile2", e.mobile2);
    formdata.append("email", e.email);
    formdata.append("address", e.address);
    formdata.append("city", e.city);
    formdata.append("state", e.state);
    formdata.append("zipcode", e.zipcode);
    formdata.append("highqualification", e.highqualification);
    formdata.append("yearofpassing", e.yearofpassing);
    formdata.append("score", e.score);
    formdata.append("boardofedu", e.boardofedu);
    formdata.append("institute", e.institute);
    formdata.append("testattempt", e.testattempt);
    formdata.append("examdate", e.examdate);
    formdata.append("overallscore", e.overallscore);
    formdata.append("listening", e.listening);
    formdata.append("reading", e.reading);
    formdata.append("writing", e.writing);
    formdata.append("speaking", e.speaking);
    formdata.append("document", e.document);
    formdata.append("documentnumber", e.documentnumber);
    formdata.append("expirydate", e.expirydate);

    if (attachementimage.length > 0) {
      for (let index = 0; index < attachementimage.length; index++) {
        formdata.append("scoreattach", attachementimage[index]);
      }
    }

    if (academic.length > 0) {
      for (let index = 0; index < academic.length; index++) {
        formdata.append("docuattach", academic[index]);
      }
    }

    const savecollege = await Savecollegedetails(formdata);
    toast.success("Data Saved", {
      position: toast.POSITION.TOP_LEFT,
    });
    dispatch(fetchDatadetailsapp());
  };

  const [attachementimage, setImage] = useState("");
  const [academic, setacademicImage] = useState("");
  useEffect(() => {
    console.log(errors, "hbbbhb");
  }, [errors]);
  const handleChange = (e) => {
    if (
      (e.target.name == "expirydate" && e.target.value.length > 10) ||
      e.target.value.length == 0
    ) {
      setError("expirydate", {
        type: "custom",
        message: "Please Enter Valid Year",
      });
    }
    if (
      (e.target.name == "examdate" && e.target.value.length > 10) ||
      e.target.value.length == 0
    ) {
      setError("examdate", {
        type: "custom",
        message: "Please Enter Valid Year",
      });
    }

    if (
      (e.target.name == "dob" && e.target.value.length > 10) ||
      e.target.value.length == 0
    ) {
      setError("dob", {
        type: "custom",
        message: "Please Enter Valid Year",
      });
    }
    // if(e.target.name=='mobile1' && e.target.value.length>10)
    // {
    //   setError("mobile1", {
    //     type: "custom",
    //     message: "Please Enter Valid Mobile Number",
    //   });
    // }
    // if(e.target.name=='mobile2' && e.target.value.length>10)
    // {
    //   setError("mobile2", {
    //     type: "custom",
    //     message: "Please Enter Valid Mobile Number",
    //   });
    // }

    if (e.target.name == "email" && !emailregExp.test(e.target.value)) {
      setError("email", {
        type: "custom",
        message: "Please Enter Valid Email",
      });
    } else {
      clearErrors("email");
    }

    if (e.target.name == "mobile1" && !phoneRegExp.test(e.target.value)) {
      setError("mobile1", {
        type: "custom",
        message: "Please Enter Valid Phone",
      });
    } else {
      clearErrors("mobile1");
    }
    if (e.target.name == "mobile2" && !phoneRegExp.test(e.target.value)) {
      setError("mobile2", {
        type: "custom",
        message: "Please Enter Valid Phone",
      });
    } else {
      clearErrors("mobile2");
    }

    if (e.target.name == "attachmentimg") {
      setImage(e.target.files);
    }
    if (e.target.name == "academic") {
      if (e.target.files) {
        setacademicImage(e.target.files);
      }
    }

    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // console.log("viewfile", viewfile)
  // console.log("image1", attachementimage)
  // console.log("image2", academic)

  return (
    <div
      className="tab-pane fade"
      id="Details"
      role="tabpanel"
      aria-labelledby="Details-tab"
    >
      <div className="set">
        <form className="text-start" onSubmit={handleSubmit(handleSubmitt)}>
          <h3>College details</h3>

          <div class="row">
            <div class="col-sm-4">
              <p class="form-control">
                <b>Name:</b> {list?.college_name}
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Address:</b> {list?.college_address}
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Website:</b> <a href="#">{list?.college_website_url}</a>{" "}
              </p>
            </div>
          </div>
          <h3>Course Details</h3>

          <div class="row">
            <div class="col-sm-4">
              <p class="form-control">
                <b>Name:</b> {list?.course}
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Intake:</b> {list?.intake_from}
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Duration:</b> {list?.course_duration}
              </p>
            </div>
          </div>
          <h3>Personal Information</h3>

          <div class="row">
            <div class="col-sm-4">
              <p class="form-control">
                <b>First Name:</b>
                {/* <input type="text" name="fname"></input> */}
                <SimpleInput
                  id={"fname"}
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"fname"}
                  onChange={(e) => {
                    clearErrors("fname");
                    handleChange(e);
                  }}
                  placeholder={"First Name"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Last Name:</b>

                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"lname"}
                  onChange={(e) => {
                    clearErrors("lname");
                    handleChange(e);
                  }}
                  placeholder={"Last Name"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Gender:</b>

                <select
                  name="gender"
                  {...register("gender")}
                  onChange={(e) => {
                    handleChange(e);
                    clearErrors("gender");
                  }}
                  className="form-control"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                {errors && errors.gender && (
                  <p style={{ color: "red" }}>{errors.gender.message}</p>
                )}
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Date of Birth:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"date"}
                  event={register}
                  name={"dob"}
                  min="1980-12-31"
                  max="9999-12-31"
                  onChange={(e) => {
                    clearErrors("dob");
                    handleChange(e);
                  }}
                  placeholder={"Date of Birth"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Father Name:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"fathername"}
                  onChange={(e) => {
                    clearErrors("fathername");
                    handleChange(e);
                  }}
                  placeholder={"Father Name"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Mobile Number - 1:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"mobile1"}
                  max={10}
                  onChange={(e) => {
                    clearErrors("mobile1");
                    handleChange(e);
                  }}
                  placeholder={"Mobile Number"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Mother Name:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"mothername"}
                  onChange={(e) => {
                    clearErrors("mothername");
                    handleChange(e);
                  }}
                  placeholder={"Mother Name"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Mobile Number -2:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"mobile2"}
                  onChange={(e) => {
                    clearErrors("mobile2");
                    handleChange(e);
                  }}
                  placeholder={"Mobile Number"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Email Address:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"email"}
                  event={register}
                  name={"email"}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={"Email Address"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Permanent Address:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"address"}
                  onChange={(e) => {
                    clearErrors("address");
                    handleChange(e);
                  }}
                  placeholder={"Permanent Address"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>City:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"city"}
                  onChange={(e) => {
                    clearErrors("city");
                    handleChange(e);
                  }}
                  placeholder={"City"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>State:</b>
                <SimpleInput
                  event={register}
                  style={"form-control"}
                  inType={"text"}
                  name={"state"}
                  onChange={(e) => {
                    clearErrors("state");
                    handleChange(e);
                  }}
                  placeholder={"State"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Zipcode:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"zipcode"}
                  min={1}
                  onChange={(e) => {
                    clearErrors("zipcode");
                    handleChange(e);
                  }}
                  placeholder={"Zipcode"}
                  errors={errors}
                />
              </p>
            </div>
          </div>
          <h3>Educational Qualification</h3>

          <div class="row">
            <div class="col-sm-4">
              <p class="form-control">
                <b>High Qualification:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"highqualification"}
                  onChange={(e) => {
                    clearErrors("highqualification");
                    handleChange(e);
                  }}
                  placeholder={"High Qualification"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Year of Passing:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"yearofpassing"}
                  onChange={(e) => {
                    clearErrors("yearofpassing");
                    handleChange(e);
                  }}
                  placeholder={"Year of Passing"}
                  min={1}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Score:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"score"}
                  onChange={(e) => {
                    clearErrors("score");
                    handleChange(e);
                  }}
                  placeholder={"Enter score"}
                  min={1}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b> Board of Education:</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"boardofedu"}
                  onChange={(e) => {
                    clearErrors("boardofedu");
                    handleChange(e);
                  }}
                  placeholder={"Board of Education"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Institute :</b>
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"institute"}
                  onChange={(e) => {
                    clearErrors("institute");
                    handleChange(e);
                  }}
                  placeholder={"Enter Institute"}
                  errors={errors}
                />
              </p>
            </div>
          </div>
          <h3>Test Information</h3>
          <p>Test Information.</p>
          <div className="row">
            <div className="col-sm-4">
              <p className="form-control">
                <b>Test Attempted :</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"testattempt"}
                  onChange={(e) => {
                    clearErrors("testattempt");
                    handleChange(e);
                  }}
                  placeholder={"Test Attempted"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Exam Date :</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"date"}
                  event={register}
                  name={"examdate"}
                  min="1980-12-31"
                  max="9999-12-31"
                  onChange={(e) => {
                    clearErrors("examdate");
                    handleChange(e);
                  }}
                  placeholder={"Enter Exam Date"}
                  errors={errors}
                />
              </p>
            </div>
          </div>
          <h4>Score</h4>
          <div className="row">
            <div className="col-sm-4">
              <p className="form-control">
                <b>Overall Score:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"overallscore"}
                  onChange={(e) => {
                    clearErrors("overallscore");
                    handleChange(e);
                  }}
                  placeholder={"Overall Score"}
                  min={1}
                  max={10}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Listening :</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"listening"}
                  onChange={(e) => {
                    clearErrors("listening");
                    handleChange(e);
                  }}
                  placeholder={"Listening"}
                  min={1}
                  max={10}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Reading:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"reading"}
                  onChange={(e) => {
                    clearErrors("reading");
                    handleChange(e);
                  }}
                  placeholder={"Reading"}
                  min={1}
                  max={10}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Writing:</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"writing"}
                  onChange={(e) => {
                    clearErrors("writing");
                    handleChange(e);
                  }}
                  placeholder={"Writing"}
                  min={1}
                  max={10}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Speaking :</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"number"}
                  event={register}
                  name={"speaking"}
                  onChange={(e) => {
                    clearErrors("speaking");
                    handleChange(e);
                  }}
                  placeholder={"Speaking"}
                  min={1}
                  max={10}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Attachment :</b>{" "}
                {/* <input
                  type="file"
                  name="attachmentimg"
                  multiple
                  onChange={handleChange}
                /> */}
                <SimpleInput
                  style={"form-control"}
                  inType={"file"}
                  event={register}
                  name={"attachmentimg"}
                  onChange={(e) => {
                    clearErrors("attachmentimg");
                    handleChange(e);
                  }}
                  errors={errors}
                />
              </p>
            </div>
          </div>
          <h3 className="mb-3">Documents</h3>
          <div className="row">
            <div className="col-sm-4">
              <select
                name="document"
                className="form-control"
                {...register("document")}
                onChange={(e) => {
                  handleChange(e);
                  clearErrors("document");
                }}
              >
                <option value="">Select ID Proof</option>
                <option value="adhar">Aadhaar Card</option>
                <option value="passport">Passport</option>
                <option value="pan">PAN Card</option>
              </select>
              {errors && errors.document && (
                <p style={{ color: "red" }}>{errors.document.message}</p>
              )}
            </div>

            {/* <div className="col-sm-4">
                        <p className="form-control"><b>ID Proof :</b> Aadhaar Card</p>
                    </div> */}
            <div className="col-sm-4">
              <p className="form-control">
                <b>Document Number :</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"text"}
                  event={register}
                  name={"documentnumber"}
                  onChange={(e) => {
                    clearErrors("documentnumber");
                    handleChange(e);
                  }}
                  placeholder={"Enter First Name"}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Attachment :</b>{" "}
                {/* <input
                  type="file"
                  name="academic"
                  multiple
                  onChange={handleChange}
                /> */}
                <SimpleInput
                  style={"form-control"}
                  inType={"file"}
                  event={register}
                  name={"academic"}
                  onChange={(e) => {
                    clearErrors("academic");
                    handleChange(e);
                  }}
                  errors={errors}
                />
              </p>
            </div>
            <div className="col-sm-4">
              <p className="form-control">
                <b>Expiry Date :</b>{" "}
                <SimpleInput
                  style={"form-control"}
                  inType={"date"}
                  event={register}
                  name={"expirydate"}
                  min="1980-12-31"
                  max="9999-12-31"
                  onChange={(e) => {
                    clearErrors("expirydate");
                    handleChange(e);
                  }}
                  placeholder={"Expiry Date"}
                  errors={errors}
                />
              </p>
            </div>
          </div>

          {userid && userid == list?.receiver_id ? (
            <button className="btn">Submit</button>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default Detailsapp;
