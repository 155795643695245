export const staffMemberColumns = [
  {
    label: "Date",
    name: "date",
  },
  {
    label: "Picture",
    name: "pImage",
  },
  {
    label: "Full Name",
    name: "name",
  },
  {
    label: "Mobile Number",
    name: "phone",
  },
  {
    label: "Address",
    name: "address",
  },
];

export const staffMemberData = [
  {
    id: 1,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 2,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 3,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 4,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 5,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 6,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 7,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 8,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 9,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 10,
    date: "11/20/2023",
    picture: "assets/images/ltimg.png",
    full_name: "Lucciaa",
    mobile_number: "+91 123456789",
    address: "Lorem Ipsum is simply dummy text",
  },
];

export const branchColumns = [
  {
    label: "Date",
    name: "date",
  },
  {
    label: "Logo",
    name: "company_logo",
  },
  {
    label: "Company Name",
    name: "company_name",
  },
  {
    label: "Owner’s Name",
    name: "owner_name",
  },
  {
    label: "Business Type",
    name: "type",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Phone",
    name: "phone",
  },
];

export const BranchAcionColumns = [
  {
    label: "Status",
    style: "text-end",
  },
];

export const branchRows = [
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 2",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 3",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 4",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 5",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 6",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 7",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 8",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 9",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 10",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
  {
    id: 1,
    date: "11/20/2023",
    logo: (
      <div
        className="img"
        style={{
          backgroundImage: "url(assets/images/ltimg.png)",
        }}
      ></div>
    ),
    company_name: "Gray Matters 11",
    owner_name: "Jagan Lewis",
    business_type: "Immigration Consultancy",
    address: "Lorem Ipsum is simply dummy text",
  },
];
