import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  staffMemberColumns,
  staffMemberData,
} from "../../../../services/AccountSettingData";
import Table from "../../../Tools/Table";
import AddStaffForm from "../../../Layouts/Form/addStaffForm";
import { getStaffFun } from "../../../../Reducer/accountSettingSlice";
import { mediaUrl } from "../../../../services/network";
import { updateStaffStatusApi } from "../../../../services/api";
import { toast } from "react-toastify";

const StaffMemberSection = ({ active }) => {
  const [addBranchShow, setAddBranchShow] = React.useState(false);
  const selector = useSelector((state) => state.accountSetting);
  const dispatch = useDispatch();
  const staffMemberDataa =
    staffMemberData.length > 0 &&
    staffMemberData.map((item) => {
      return {
        ...item,
        date: moment(item.date).format("DD/MMM/YYYY"),
        picture: (
          <div
            className="img"
            style={{ backgroundImage: `url(${item.picture})` }}
          ></div>
        ),
      };
    });

  const actions = [
    {
      label: "Status",
      option: [
        {
          title: "Block",
          value: "block",
        },
        {
          title: "Active",
          value: "active",
        },
        {
          title: "deactivate",
          name: "deactivate",
        },
      ],
      onchange: (e, id) => {
        updateStaffStatusApi({ status: e.target.value, id: id, type: "staff" })
          .then((response) => {
            if (response.data.status) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_LEFT,
              });
            }
          })
          .catch((e) => {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_LEFT,
            });
          });
      },
    },
  ];

  React.useEffect(() => {
    dispatch(getStaffFun());
  }, [addBranchShow]);

  const staffData = selector
    ? selector.staff &&
      selector.staff.map((item) => {
        return {
          ...item,
          date: moment(new Date(Number(item.date))).format("DD/MM/YYYY"),
          pImage: (
            <img style={{ width: "100px" }} src={mediaUrl + item.pImage} />
          ),
        };
      })
    : [];

  return (
    <div
      className={`tab-pane fade ${active}`}
      id="Settingstab3-tab-pane"
      role="tabpanel"
      aria-labelledby="Settingstab3-tab"
      tabIndex="0"
    >
      <div className="Branches_main2">
        <div className={`Branches ${addBranchShow == true && "d-none"}`}>
          <h4>
            <img src="assets/images/Staff_Members.png" alt="Branches" />{" "}
            {"Staff Members"}
            <Link
              onClick={() => setAddBranchShow(!addBranchShow)}
              className="Add_New1"
            >
              <i className="bi bi-plus-square-fill"></i> {"Add"}
            </Link>
          </h4>
          <p className="Lorem">GET YOUR ACCOUNT DETAILS OR ADD AN ACCOUNT</p>

          <Table
            columns={staffMemberColumns}
            rows={staffData}
            action={actions}
            actionCloumn={[{ style: "text-end", label: "Status" }]}
            actionDropdown={true}
          />
        </div>
      </div>

      <AddStaffForm
        heading={"Add New Staff"}
        show={!addBranchShow}
        setShow={setAddBranchShow}
      />
    </div>
  );
};

export default StaffMemberSection;
