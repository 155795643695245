import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import PrivacyContent from "./privacyContent";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import Meta from "../Meta";
import { PrivacyPolicyMetaTags } from "../../../../data/metaTags";

const PrivacyPolicy = () => {
  return (
    <>
      <Meta {...PrivacyPolicyMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"Privacy Policy"} />
      <PrivacyContent />

      <StudentFooter />
    </>
  );
};

export default PrivacyPolicy;
