import { Link } from "react-router-dom";
import Button from "../../../Tools/Button";

const HandleButtons = ({
  currentPage,
  setCurrentPage,
  userData,
  errors,
  type,
  setError,
  clearError,
}) => {
  return (
    <div style={{ overflow: "auto" }} className="mt-3">
      <div className="back_btn_pop">
        {currentPage > 1 && (
          <Button
            inType={"button"}
            id={"prevBtn"}
            onClick={() => setCurrentPage(currentPage - 1)}
            title={"Previous"}
          />
        )}
        {/* {currentPage === 1 && (
          <Button
            inType={"button"}
            id={"nextBtn"}
            onClick={() => {
              setCurrentPage(currentPage + 1);
              clearError("type");
            }}
            title={"Next"}
          />
        )} */}
        {currentPage === 1 && (
          <Button inType={`submit`} id={"nextBtn"} title={"Next"} />
        )}
      </div>
    </div>
  );
};
export default HandleButtons;
