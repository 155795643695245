import { mediaUrl } from "./network";

export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

export function countMinusWithInputValue(totalValue, count) {
  return totalValue - count < 0
    ? totalValue - count
    : count === 0
    ? totalValue
    : totalValue - count === 0 || totalValue - count < 1
    ? 0
    : totalValue - count;
}

export function getProfileCompletePercentage(data) {
  let count = 30;
  // item.pan_status === "Approved" &&
  // item.authorize_certificate_status === "Approved" &&
  // item.gst_status === "Approved"
  if (data.pan_status === "Approved") {
    count += 10;
  }
  if (data.authorize_certificate_status === "Approved") {
    count += 10;
  }
  if (data.gst_status === "Approved") {
    count += 10;
  }
  if (data.faq >= 3) {
    count += 5;
  }
  if (data.stories >= 3) {
    count += 5;
  }
  if (data.overview_description) {
    count += 5;
  }
  if (data.company_logo) {
    count += 10;
  }
  if (data.specialization.length >= 5) {
    count += 5;
  }
  if (data.banner_image) {
    count += 10;
  }
  console.log("count----->123", count);
  return count;
}

export function kFormatter(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export const downloadMedia = (data) => {
  const imgName = data.split(`${mediaUrl}`)[1];
  fetch(data, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imgName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
