import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  allBlogs,
  blogByCategories,
  blogsByCatOrTagApi,
  getAllBlogsApi,
  getSimilarBlogsApi,
  getTopBlogsApi,
  singleBlogApi,
  topFourBlogs,
} from "../services/api";

export const getTopFourBlogs = createAsyncThunk(
  "get_top4_blogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await topFourBlogs();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBlogsList = createAsyncThunk(
  "getblogslist",
  async (_, { rejectWithValue }) => {
    try {
      const response = await allBlogs();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBlogByCategory = createAsyncThunk(
  "blogByCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await blogByCategories(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSingleBlog = createAsyncThunk(
  "getSingleBlog",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await singleBlogApi(slug);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBlogsByCatorTag = createAsyncThunk(
  "blogsbytagorcat",
  async (body, { rejectWithValue }) => {
    try {
      const response = await blogsByCatOrTagApi(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getTopBlogs = createAsyncThunk(
  "popularblogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTopBlogsApi();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllBlogs = createAsyncThunk(
  "allBlogs",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getAllBlogsApi(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSimilarBlogs = createAsyncThunk(
  "similarBlogs",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getSimilarBlogsApi(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    success: false,
    submitting: false,
    error: null,
    categories: [],
    latest_blog: {},
    recent_blogs: [],
    topFourBlogs: [],
    blogsByCategories: [],
    singleBlog: {},
    similarBlogs: [],
    blogsByCatOrTag: [],
    topBlogs: [],
    all_blogs: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSimilarBlogs.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSimilarBlogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.similarBlogs = action.payload.data.data;
      })
      .addCase(getSimilarBlogs.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getTopBlogs.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getTopBlogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.topBlogs = action.payload.data.data;
      })
      .addCase(getTopBlogs.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getTopFourBlogs.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getTopFourBlogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.topFourBlogs = action.payload;
      })
      .addCase(getTopFourBlogs.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getBlogsList.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getBlogsList.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.latest_blog = action.payload.topOne;
        state.recent_blogs = action.payload.topFour;
        state.categories = action.payload.categories;
        state.blogsByCategories = action.payload.defaultBlogs;
      })
      .addCase(getBlogsList.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getBlogByCategory.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getBlogByCategory.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.blogsByCategories = action.payload;
      })
      .addCase(getBlogByCategory.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getSingleBlog.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSingleBlog.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.singleBlog = action.payload.single;
        state.similarBlogs = action.payload.similarBlogs;
      })
      .addCase(getSingleBlog.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getBlogsByCatorTag.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getBlogsByCatorTag.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.blogsByCatOrTag = action.payload;
      })
      .addCase(getBlogsByCatorTag.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getAllBlogs.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.all_blogs = action.payload.data.data;
      })
      .addCase(getAllBlogs.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default blogSlice.reducer;
