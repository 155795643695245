import { useDispatch } from "react-redux";
import { onlineOflineApi } from "../../../services/api";
import { mediaUrl } from "../../../services/network";
import Button from "../../Tools/Button";
import { setOnlineStatus } from "../../../Reducer/authSlice";

const StillOnlineModel = ({ onHide }) => {
  const dispatch = useDispatch();
  const handleOnlineFunction = async (status) => {
    let st = false;
    if (status == "yes") {
      st = true;
    }
    const update = await onlineOflineApi({ status: st });
    if (update) {
      dispatch(setOnlineStatus(st));
    }
    onHide();
  };
  return (
    <div className="modal-body">
      <img src="../../assets/images/modelLogo.png" className="modelLogo" />
      <h5>{"Are you still online ?"}</h5>
      <p>
      To Receive Calls Keep your Availablity Updated
      </p>
      <Button
        title={"Yes"}
        className="yes"
        onClick={() => handleOnlineFunction("yes")}
        icon={<i className="bi bi-check-circle-fill"></i>}
      />
      <Button
        title={"No"}
        className="no"
        onClick={() => handleOnlineFunction("no")}
        icon={<i className="bi bi-x-circle-fill"></i>}
      />
    </div>
  );
};

export default StillOnlineModel;
