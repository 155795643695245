import SocialMedia from "../../../Tools/SocialMedia";

const ProfilePicture = ({ imageHandle, image }) => {
  return (
    <div className="col-sm-4">
      <div className="pr-0 pe-md-3">
        <div
          className="chatuser"
          style={{
            backgroundImage: `url(${image ? image : "assets/images/user.png"})`,
          }}
        >
          <span className="profile-mg-upload">
            <i className="bi bi-upload"></i>
            <input
              type="file"
              name="image"
              onChange={imageHandle}
              accept="image/*"
            />
          </span>
        </div>
      </div>
    </div>
  );
};
export default ProfilePicture;
