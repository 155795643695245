import { Link } from "react-router-dom";
// import Stories from 'react-insta-stories';
import { Carousel } from "react-responsive-carousel";

const LearnGetStarted = () => {
  return (
    <div className="col-sm-12 mb-3">
      <div className="carousel carousel-slider">
        <div
          className="wap wap1"
          style={{ backgroundImage: "url(../../../assets/images/wapbg.png)" }}
        >
          <h5>Learn How to Get Started</h5>
          <p>
            Setting up your Profile, How to have Communication in <br />
            Simple words, How to get more calls/chats
          </p>
          <Link className="link" to={"/account-settings"}>
            View Details <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default LearnGetStarted;
