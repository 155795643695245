import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { branchListApi, getStaffApi } from "../services/api";
// import { toast } from "react-toastify";

export const getStaffFun = createAsyncThunk(
  "getStaff",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getStaffApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBranchFun = createAsyncThunk(
  "getBranch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await branchListApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const accountSettingPage = createSlice({
  name: "accountSetting",
  initialState: {
    success: false,
    submitting: false,
    error: null,
    loading: false,
    staff: [],
    branch: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.loading = true;
      })
      .addCase(getStaffFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.staff = action.payload.data;
        state.message = action.payload.message;
        state.loading = false;
      })
      .addCase(getStaffFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.loading = false;
      })
      .addCase(getBranchFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.loading = true;
      })
      .addCase(getBranchFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.branch = action.payload.data;
        state.message = action.payload.message;
        state.loading = false;
      })
      .addCase(getBranchFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.loading = false;
      });
  },
});

export default accountSettingPage.reducer;
