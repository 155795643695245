import { useContext, useEffect } from "react";
import {
  videoModelList,
  FOR_CHAT_SMS
} from "../../../data/data";
import { mediaUrl } from "../../../services/network";
import { SocketContext } from "../../../Context";
import $ from "jquery";

const VideoCallModal = ({ show, onHide, setConn, data }) => {
  const {
    callTo,
    fAgnt,
    findChatFun,
    setFindAgnt,
    modalChat
  } = useContext(SocketContext);

  const connect = () => {
    if (fAgnt.length > 0) {
      setConn(true);
      callTo(data.uid || data.attendant_id, false, 0);
    } else {
      onHide();
      modalChat(true);
    }
  };

  useEffect(() => {
    setFindAgnt([]);
  }, []);

  const handleSendMsg = () => {
    let userData = {
      receiver_id: data.connection_user || data.uid,
      image: data.company_logo,
      company_name: data.company_name,
      online: data.online == "true" || data.online == true ? true : false,
      status: data.status,
      owner_name: data.owner_name || data.name,
      slug: data.slug,
      city: data.city,
      userRole: "branch",
    };
    findChatFun(userData);
  };

  return (
    <div className="VideoNotification">
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => onHide()}
          >
            <i className="bi bi-x"></i>
          </button>

          <div className="digit">
            {data.dynamic_image && data.dynamic_image === 1 ? (
              <div className="img">{data.name && data.name.charAt(0)}</div>
            ) : (
              <div
                className="img"
                style={{
                  backgroundImage: `url(${mediaUrl}${
                    data.company_logo ? data.company_logo : data.image
                  })`,
                }}
              ></div>
            )}
            <h4>{data.company_name ? data.company_name : data.name}</h4>
            <h2>
              <span>
                {data.online == "true" || data.online == true ? "" : "Offline"}
              </span>
            </h2>
          </div>
          {fAgnt.length > 0 ? (
            <ul>
              {videoModelList &&
                videoModelList.length > 0 &&
                videoModelList.map((x, i) => {
                  return <li>{x}</li>;
                })}
            </ul>
          ) : (
            <p>{FOR_CHAT_SMS}</p>
          )}
        </div>
        <div className="modal-footer d-block text-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(".connect").removeClass("ofline_div");
              $(".connect").addClass("call_user");
              connect();
              if (fAgnt.length === 0) {
                handleSendMsg();
              }
            }}
          >
            {fAgnt.length > 0 ? (
              <span>
                <img src="../../assets/images/connect.png" /> Call
              </span>
            ) : (
              <span>
                <i className="bi bi-chat-square-dots-fill"></i> Message
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default VideoCallModal;
