import React from "react";
import TwoTypesDescription from "./twoTypesDescription";
import TwoTypesNavTabs from "./twoTypesNavTabs";
import { Link, useNavigate } from "react-router-dom";

const TwoTypesUser = () => {
  // const [activeTab, setActiveTab] = React.useState("imigration_consultant");
  return (
    <section className="two_types">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="head mb-4 mb-xl-5">
              <h2>
                Global Opportunities with Our Overseas Education Marketplace!
              </h2>
              <p>
                Dive into a world of opportunities, connect with students, and
                make a meaningful impact on their educational pursuits.
              </p>
            </div>

            <p>
              As a dedicated marketplace, we adeptly bridge the gap between
              education consultants, language trainers, and eager students
              globally. We warmly welcome education consultants and renowned
              trainers proficient in IELTS, PTE, and TOEFL to become part of our
              dynamic and lively community. By joining us, you'll gain access to
              a diverse pool of aspiring learners from every part of the world,
              fostering growth, collaboration, and excellence in education.
              Embrace this opportunity to connect, share knowledge, and make a
              lasting impact on students seeking quality education and language
              training. Together, we can shape futures and create meaningful
              learning experiences.{" "}
            </p>
            <Link className="Join" to={"/sign-up-as-partner"}>
              Join
            </Link>
          </div>
          <div className="col-lg-5">
            <img
              src="assets/images/two_types.png"
              alt="two_types"
              className="w-100"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoTypesUser;
