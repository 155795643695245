import Option from "../Options";

const FormSelectOption = ({
  parentStyle,
  childStyle,
  label,
  options,
  name,
  event,
  errors,
  onChange,
  heading,
}) => {
  return (
    <div className={parentStyle}>
      {label && <label>{label}</label>}
      {heading && <h5>{heading}</h5>}

      <select
        className={childStyle}
        name={name}
        {...(event && event(name))}
        onChange={onChange}
      >
        <Option title={"Select The Option"} value={""} />
        {options.length > 0 &&
          options.map((item) => {
            return (
              <Option
                title={item.title}
                value={item.value}
                onChange={onChange}
              />
            );
          })}
      </select>
      {errors && errors[name] && (
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )}
    </div>
  );
};
export default FormSelectOption;
