import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  changeEmailSchema,
  changeMobileNumberSchema,
  emailregExp,
  loginSchema,
} from "../../../services/schema";
import Button from "../../Tools/Button";
import CrouselItem from "../../Tools/Crouselitem";
import FormInputField from "../../Tools/Forminput";
import Example from "../../Tools/ReactBoostrap";
import OtpModel from "./otp";
import { Link } from "react-router-dom";
import {
  setAction,
  setOldPhoneOtp,
  setOtp,
  setPhoneNumber,
  setOldPhoneNumber,
} from "../../../Reducer/authSlice";
import {
  changeNumberSendOtpApi,
  checkBranchApi,
  emailCheck,
  updateEmail,
} from "../../../services/api";
import ChangeMobileNumberOtp from "./changeMobileNumberOtp";
import axios from "axios";
import { secondUrl } from "../../../services/network";

const ChangeEmailModal = ({ onHide }) => {
  const selector = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [available, setAvailable] = React.useState(false);
  const { phone } = useSelector((state) => state.auth.auth);
  const { otp, oldPhoneOtp } = useSelector((state) => state.auth);
  const [input, setInputs] = React.useState({
    phoneNumber: "",
    oldPhoneNumber: phone,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(changeEmailSchema),
  });

  const onSubmit = async (email) => {
    try {
      const response = await updateEmail(email);
      if (response.status === 200) {
        toast.success("Email Updated Successfully");
        onHide();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkEmail = async (e) => {
    try {
      const { value } = e.target;
      if (value.match(emailregExp)) {
        const response = await emailCheck(value, selector.auth.uid);
        if (response.data.status === true) {
          setAvailable(true);
          clearErrors("email");
        } else {
          setAvailable(false);
          setError("email", { type: "custom", message: response.data.message });
        }
      } else {
        setAvailable(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={onHide}
      ></button>
      <div className="modal-body p-0">
        <div className="row">
          <div className="col-md-6">
            <div className="lest_login text-center">
              <img
                src="../../../assets/images/chownk-logo.png"
                alt="CHOWNK logo"
              />
              <h3 className="text-center">{"Change Email"}</h3>
              {/* <h6>{"Lorem ipsum is simply dummy text for printing."}</h6> */}
              <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
                <FormInputField
                  parentStyle={"form-group"}
                  childStyle={"form-control"}
                  inType={"text"}
                  event={register}
                  label={"Enter your new email address"}
                  message={!available ? message : ""}
                  name={"email"}
                  placeholder={"Enter your New email address here..."}
                  errors={errors}
                  onChange={checkEmail}
                />

                {errors && errors.newPhone && (
                  <p style={{ color: "red" }}>{errors.newPhone.message}</p>
                )}

                <Button
                  inType={"submit"}
                  style={"btn mt-2 mt-lg-5"}
                  title={"Continue"}
                  disabled={available ? false : true}
                  inlineStyle={
                    available
                      ? { backgroundColor: "#1DBF73", color: "white" }
                      : {}
                  }
                />
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right_login">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <p>
                  <div className="column">
                    <h3 className="no-puck text-white">
                      {" "}
                      Trust
                      <span className="highlight"> CHOWNK </span>
                      to navigate the complexities of international immigration
                      seamlessly, ensuring you're well-informed and supported
                      every step of the way.{" "}
                    </h3>
                    <div className="mob video-demonstration">
                      <div
                        className="youtube-video"
                        data-lumberjack-action="partnerships.landing_page.video"
                      ></div>
                    </div>
                    <ul className="bullet-list">
                      <li>Secure Video 1 to 1 Call with Experts</li>
                      <li>Chat and Messaging</li>
                      <li>Recording Options</li>
                      <li>Document Checklist</li>
                      <li>Client Portal</li>
                      <li>Informational Content</li>
                      <li>Document Sharing</li>
                      <li>Feedback and Review System</li>
                      <li>Notification Alerts</li>
                    </ul>
                    <div className="become-partner-container mob">
                      {/* <p>
                        <i className="bi bi-arrow-left"></i>{" "}
                        <strong>Join Now</strong>
                      </p> */}
                    </div>
                  </div>
                </p>
              </div>
              {/* <p>
                Lorem Ipsum is simply dummy text for printing and typesetting
                industry. Lorem Ipsum has been industry’s standard dummy text.
              </p>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <div className="carousel-inner">
                  <CrouselItem
                    parentStyle={"carousel-item active"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                  <CrouselItem
                    parentStyle={"carousel-item"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                  <CrouselItem
                    parentStyle={"carousel-item"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                </div>
                <div className="carousel-indicators">
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"0"}
                    style={"active"}
                    ariaCurrent={"true"}
                    ariaLabel={"Slide 1"}
                  />
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"1"}
                    ariaLabel={"Slide 1"}
                  />
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"1"}
                    ariaLabel={"Slide 2"}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEmailModal;
