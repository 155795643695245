import React, { useContext, useEffect, useState } from "react";
import RecentactivityApp from "./Recentactivity";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../../../../Reducer/applicationSlice";
import SimpleInput from "../../../Tools/SimpleInput";
import { Savecollegedetails } from "../../../../services/api";
import { mediaUrl } from "../../../../services/network";
const FilesApp = () => {
  const dispatch = useDispatch();
  const [attachments, attachmentarr] = useState([]);
  let list = useSelector((state) => state.application?.single);
  console.log("fetchdata", list);
  // attachmentarr(list)

  const functionc = async () => {
    console.log("test1", list);
    if (list && list.attachment_document && list.attachment_score) {
      let array3 = [];
      let arr1 = JSON.parse(list.attachment_document);
      let arr2 = JSON.parse(list.attachment_score);
      array3 = [...arr1, ...arr2];
      attachmentarr(array3);
    }
  };

  useEffect(() => {
    //  dispatch(fetchData());
    functionc();
  }, [list]);
  return (
    <div
      className="tab-pane fade"
      id="Files"
      role="tabpanel"
      aria-labelledby="Files-tab"
    >
      <div className="row ">
        <div className="col-lg-8">
          <div className="files">
            <div className="table-responsive">
              <table className="table">
                {attachments &&
                  attachments.map((items, i) => {
                    return (
                      <tr>
                        <td>
                          <div className="pdf">
                            <img src={`${mediaUrl}${items}`} alt="pdf2" />
                            <h6>{items}</h6>
                            <p>Created by Lucciaa</p>
                          </div>
                        </td>
                        <td>
                          01/12/2023
                          <br />
                          Filled by you
                        </td>
                        <td>
                          <a
                            href={`${mediaUrl}${items}`}
                            download
                            className="download"
                          >
                            <img
                              src="../../../assets/images/downloadicon.png"
                              alt="download"
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mt-3 mt-lg-0">
          <RecentactivityApp />
        </div>
      </div>
    </div>
  );
};

export default FilesApp;
