import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AppListingsuser,
  Allmyapplications,
  Allmyapplicationsstaff,
  Checklists,
  SingleListing,
} from "../services/api";

export const fetchData = createAsyncThunk(
  "claim_business",
  async (body, { rejectWithValue }) => {
    try {
      const response = await AppListingsuser(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const fetchDatadetailsapp = createAsyncThunk(
  "fetchdetails",
  async (body, { rejectWithValue }) => {
    try {
      const response = await SingleListing(body);
      // console.log("reach for files", response)
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fetchApplicationData = createAsyncThunk(
  "claim_businesshh",
  async (body, { rejectWithValue }) => {
    try {
      console.log("bodyy", body);
      const response = await Allmyapplications(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const fetchAppDatapartner = createAsyncThunk(
  "fetchAppDatapartner",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Allmyapplicationsstaff(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const fetchDatachecklist = createAsyncThunk(
  "checklistdata",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Checklists(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    success: false,
    submitting: false,
    error: null,
    list: [],
    single: {},
    checklist: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.single = action.payload.data.data[0];
        state.error = null;
      })

      .addCase(fetchData.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(fetchDatadetailsapp.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchDatadetailsapp.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.single = action.payload.data.data[0];
        state.error = null;
      })
      .addCase(fetchDatadetailsapp.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })

      .addCase(fetchApplicationData.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })

      .addCase(fetchApplicationData.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.list = action.payload.data.data;
        state.error = null;
      })
      .addCase(fetchApplicationData.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })

      //partner
      .addCase(fetchAppDatapartner.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })

      .addCase(fetchAppDatapartner.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.list = action.payload.data.data;
        state.error = null;
      })
      .addCase(fetchAppDatapartner.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })

      //checklist
      .addCase(fetchDatachecklist.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })

      .addCase(fetchDatachecklist.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.checklist = action.payload.data.data;
        state.error = null;
      })
      .addCase(fetchDatachecklist.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default applicationSlice.reducer;
