import { useSelector } from "react-redux";
import {
  successStoriesData,
  toolGrowsData,
} from "../../../../services/BecomeConsultant";
import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import LandingBanner from "./landingBanner";
import SuccessStories from "./successStories";
import ToolGrows from "./toolsGrow";
import TwoTypesUser from "./twoTypesUser";
import Meta from "../../Common/Meta";
import { BecomeConsultantMetaTags } from "../../../../data/metaTags";
import HowToGetStarted from "./howToGetStarted";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAction } from "../../../../Reducer/authSlice";
const BecomeConsultantPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.auth);
  if (selector.kycVerificationSuccess) {
    navigate("/");
  }
  useEffect(() => {
    dispatch(setAction(""));
  }, []);
  return (
    <>
      <Meta {...BecomeConsultantMetaTags} />
      <StudentHeader
        logo={"assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />
      <LandingBanner />
      <TwoTypesUser />
      <ToolGrows toolGrowsData={toolGrowsData} />
      <HowToGetStarted />
      <StudentFooter />
    </>
  );
};

export default BecomeConsultantPage;
