import SimpleInput from "../SimpleInput";

const FormRadio = ({ parentStyle, childStyle, label, event, name, errors }) => {
  return <div className={parentStyle}>
    <label>{label}</label>
    <span><SimpleInput
      style={childStyle}
      inType={"radio"}
      event={event}
      name={name}
      value={"yes"}
    />
      yes
    </span>
    <span>
      <SimpleInput
        style={childStyle}
        inType={"radio"}
        event={event}
        name={name}
        value={"no"}
      />
      no
      {/* <input className={childStyle} type="radio" /> */}
      {errors && errors[name] && (
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )}
    </span>
  </div>
}
export default FormRadio;