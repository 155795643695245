export const toolGrowsData = [
  {
    imgSrc: "assets/images/tools1.png",
    title: "Global Reach",
    descripition:
      "Engage with students throughout India who are looking for top-notch Overseas education and language learning opportunities",
  },
  {
    imgSrc: "assets/images/tools2.png",
    title: "Enhanced Visibility",
    descripition:
      "Showcase your expertise to a broader audience. Increase your visibility and attract potential clients through our user-friendly platform.",
  },
  {
    imgSrc: "assets/images/tools3.png",
    title: "Comprehensive Support",
    descripition:
      "Education Consultants can leverage our platform to offer comprehensive support, guiding students through every step of their educational journey. ",
  },
  {
    imgSrc: "assets/images/tools4.png",
    title: "Valuable Connections",
    descripition:
      "Build valuable connections within our community of experts. Collaborate with fellow consultants and trainers to enhance your professional network",
  },
  {
    imgSrc: "assets/images/tools5.png",
    title: "Collaborative Community",
    descripition:
      "Join a community of like-minded professionals dedicated to shaping the future of education. Collaborate with fellow consultants and trainers, sharing insights and best practices.",
  },
  {
    imgSrc: "assets/images/tools6.png",
    title: "Specialized Language Training Showcase",
    descripition:
      "or IELTS, PTE, and TOEFL trainers, our platform is a prime stage to showcase your expertise. Gain visibility among students seeking language proficiency and be a pivotal part of their journey toward international education.",
  },
];

export const successStoriesData = [
  {
    imgSrc: "assets/images/Successimg.png",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took galley of type and scrambled it to make a type specimen book.",
    name: "Deva Djaafar",
    position: "Executive Assistant & Office Operations Manager",
    company_name: "Gray Matters",
    company_employees: "100+ employees",
  },
  {
    imgSrc: "assets/images/Successimg.png",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took galley of type and scrambled it to make a type specimen book.",
    name: "Deva Djaafar",
    position: "Executive Assistant & Office Operations Manager",
    company_name: "Gray Matters",
    company_employees: "100+ employees",
  },
];
