import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AccountSettingOption from "./AccountSettingOption";
import ProfileSettingSection from "./profileSettingSection";
import BranchesSettingSection from "./branchesSettingSection";
import StaffMemberSection from "./staffMemberSection";
import KycDocumentSection from "./kycDocumentSection";
import Example from "../../../Tools/ReactBoostrap";
import CommonPopUp from "../../../Layouts/Model/commonPopUp";
import Meta from "../../Common/Meta";
import StudentHeader from "../../../Layouts/Student/Header";
import { saveUserNavigate } from "../../../../services/api";
import moment from "moment";

const AccountSettings = () => {
  const location = useLocation();
  const [show, setShow] = React.useState(false);
  const { tab } = useParams();
  const navigate = useNavigate();
  const selector = useSelector((state) => state.auth?.auth);
  const [activeTab, setActiveTab] = React.useState(
    tab === "branches"
      ? tab
      : location.state &&
        location.state.active &&
        location.state.active === "kyc_documents"
      ? "kyc_documents"
      : selector?.status === "active"
      ? "profile_setting"
      : "kyc_documents"
  );
  const guestId = localStorage.getItem("guestId");

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: selector ? selector.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`highlightdiv ${
        location && location.state && location.state.overlay && "active"
      }`}
    >
      <Meta title={"Partner Dashboard : Chownk"} />
      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />
      <section
        className="dashboard"
        // style={{ backgroundImage: "url(../../assets/images/login_screen.png)" }}
      >
        <div
          className="dashboard_in"
          // style={{
          //   backgroundImage: "url(../../assets/images/login_screen2.png)",
          // }}
        >
          <div className="container">
            <div className="Account_Settings pop_width">
              <AccountSettingOption
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                userData={selector}
                showModel={() => setShow(!show)}
              />
              {location && location.state && location.state.overlay && (
                <span
                  className={"highlightbtn closebtn"}
                  onClick={() => navigate(location.pathname, { replace: true })}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              )}
              <div className="tab-content join_step_form" id="myTabContent">
                <ProfileSettingSection
                  active={activeTab === "profile_setting" && "show active"}
                  showModel={() => setShow(!show)}
                />
                <KycDocumentSection
                  active={activeTab === "kyc_documents" && "show active"}
                  user={selector}
                />
                <BranchesSettingSection
                  active={activeTab === "branches" && "show active"}
                />
                <StaffMemberSection
                  active={activeTab === "staff_members" && "show active"}
                />
              </div>
            </div>
          </div>
        </div>
        <Example
          show={show}
          onHide={() => setShow(!show)}
          component={
            <CommonPopUp
              parentStyle={"thank_you_cont"}
              childStyle={"thank_you_img"}
              imgSrc={"../../../assets/images/Hourglass.png"}
              title={"More Information"}
              description={
                "We still require more information from your side to get your account verified."
              }
              linkTitle={"Add information"}
              onHide={() => setShow(!show)}
            />
          }
          backDrop={"static"}
          style={"thank_you_pop"}
        />
      </section>
    </div>
  );
};
export default AccountSettings;
