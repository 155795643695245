import React, { useContext, useEffect, useState } from "react";
import AskExpertHeader from "../../../Layouts/Student/AskExpertHeader";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { expertPayFun, findExpertPlanFun } from "../../../../services/api";
import { SocketContext } from "../../../../Context";
import { phoneVerification } from "../../../../Reducer/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Roptions } from "../../../../data/data";

const Payment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [Razorpay, isLoaded] = useRazorpay();
  const [amount, setAmount] = useState("");
  const [expiry, setExpiry] = useState("");
  const [tax, setTax] = useState("");
  const [total, setTotal] = useState("");
  const [planId, setPlanId] = useState("");
  const [payFromWallet, setPayFromWallet] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const { funds } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { setCurrentUser } = useContext(SocketContext);

  const handleExpertPayment = () => {
    if (payFromWallet) {
      const body = {
        phone: user.phone,
        latitude: user.latitude,
        longitude: user.longitude,
        ip_address: user.ip_address,
        current_address: user?.current_address,
      };
      dispatch(phoneVerification(body));
      setOtpShow(!otpShow);
    } else {
      if (!isLoading) {
        let options = {
          key: Roptions.key,
          amount: Number(total) * 100,
          name: "Ask a Expert",
          description: "Purchase Description",
          image: "/your_logo.png",
          handler: function (response) {
            if (response.razorpay_payment_id) {
              const body = {
                plan_id: planId,
                amount: total,
              };
              payExpert(body);
            }
          },
          prefill: {
            name: "",
            email: "harshil@razorpay.com",
          },
          notes: {
            address: "Hello World",
          },
          theme: {
            color: "#1DBF73",
          },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
      }
    }
  };

  useEffect(() => {
    getExpertPlans();
  }, []);

  const getExpertPlans = async () => {
    try {
      setIsLoading(true);
      const response = await findExpertPlanFun();
      if (response.status === 200) {
        const plan = response.data.data;
        setPlanId(plan.id);
        setExpiry(plan.expiry_month);
        setAmount(plan.amount);
        const taxamt = plan.amount * (plan.gst / 100);
        setTax(taxamt);
        setTotal(plan.amount + taxamt);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const payExpert = async (body) => {
    try {
      const response = await expertPayFun(body);
      if (response.status === 200) {
        const data = {
          ...response.data.id,
          connection_user: response.data.id.id,
        };

        setCurrentUser(data);
        navigate("/chat-with-expert", { state: response.data.id.id });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* <AskExpertHeader /> */}
      <section className="payment">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="payment_left">
                <div className="set">
                  <h4>What we Provide</h4>
                  <p>
                    With the help of our extensive immigration consultation
                    platform, take advantage of worldwide prospects. We enable
                    you to smoothly manage the complexity of immigration by
                    providing you with individualized information on visa
                    requirements and expert advice on visa applications. Our
                    platform provides customized solutions to make sure your
                    trip to a new location is easy and hassle-free. Count on us
                    to deliver dependable assistance at every turn, freeing you
                    up to concentrate on accomplishing your aspirations of
                    traveling abroad.
                  </p>
                </div>

                <h5>WHY CHOOSE US </h5>
                <p>
                  At Chownk, we offer a safe space for individuals and
                  immigration consultants to interact. Here, the immigration
                  agents may provide individualized visa services, doing away
                  with the necessity for repeated trips and the potential for
                  fraud.
                </p>
                <h5>What sets us apart </h5>
                <p>
                  1.Expert Network 2.Personalized Assistance 3.Narrowing down
                  your search 4.Instant Chat & Video Call 5.Secure Document
                  Sharing
                </p>
                <h5>JOIN NOW </h5>
                <p>
                  Enroll right away, and let Chownk assist you in starting a new
                  journey.
                </p>

                <a href="#" className="joinnow">
                  Join Now <i className="bi bi-arrow-right"></i>
                </a>

                <h6 className="mb-0">
                  Need help? <a href="#">Contact Us</a>
                </h6>
              </div>
            </div>

            <div className="col-md-6">
              <div className="payment_right">
                <h2>Payment Summary</h2>
                <h3>Ask Expert Service</h3>
                <h6 className="mb-3">
                  ({`Validity ${expiry} Months`}){" "}
                  <span className="float-end">{`INR ${amount}`}</span>{" "}
                </h6>
                <p>(Includes : 5 Top Rated</p>
                <p>
                  Consultants/Training Institutes , Arrange Video/Chat
                  Consultation , Avoid Getting into Frauds , Available 24*7)
                </p>
                <h6>
                  Taxes <span className="float-end">{`INR ${tax}`}</span>{" "}
                </h6>
                <p>(Goods & Service Tax)</p>
                <h6 className="total">
                  Total <span className="float-end">{`INR ${total}`}</span>
                </h6>
                <input
                  type="checkbox"
                  checked={payFromWallet}
                  onChange={() => setPayFromWallet(!payFromWallet)}
                  disabled={total > funds}
                />
                Pay From Wallet
                <button
                  type="button"
                  className="confirm"
                  onClick={handleExpertPayment}
                >
                  Confirm & Pay
                </button>
                <p className="text-center">
                  <b>SSL Secure Payment</b>
                </p>
                <p className="text-center mb-0">
                  {`You will be charged INR ${total} Total amount`}
                  <br />
                  includes currency conversion fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p className="rights mb-0">All Rights Reserved by Chownk</p>
    </div>
  );
};

export default Payment;
