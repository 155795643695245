import React, { useEffect, useState } from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import { Link, useNavigate } from "react-router-dom";
import VerificationTabs from "./verificationTabs";
import PanTab from "./panTab";
import AddressTab from "./addressTab";
import LogoTab from "./logoTab";
import BannerTab from "./bannerTab";
import SpecializedTab from "./specializedTab";
import AboutTab from "./aboutTab";
import SuccessStoriesTab from "./successStoriesTab";
import FaqTab from "./faqTab";
import AchievementTab from "./achievementTab";
import TaglineTab from "./taglineTab";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { numberAphabet } from "../../../../services/schema";
import { useFilter } from "@adobe/react-spectrum";
import $ from "jquery";
import {
  getAgentProfileApi,
  getSpecializationFilterApi,
  panVerificationApi,
  updateAgentProfileApi,
} from "../../../../services/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getCompanyProfile } from "../../../../Reducer/afterSearchSlice";
import { mediaUrl } from "../../../../services/network";
import MainLoader from "../../../Tools/MainLoader";
import RightBar from "./rightBar";
import Example from "../../../Tools/ReactBoostrap";
import ProfileIncompleteModal from "./profileIncomplete";
import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

const pan_regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
const gst_regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/;

const documentSchema = yup.object({
  pan_number: yup
    .string()
    .required("Please enter your PAN number")
    .matches(pan_regex, "Please Enter a valid PAN number")
    .min(10, "Pan number must be at least 10 characters")
    .max(10, "Pan number must be at most 10 characters"),
  pan_verified: yup
    .boolean()
    .default(false)
    .test("is-verified", "Please verify PAN number", (value) => value === true),
  has_gst: yup.boolean().required().default(false),
  gst_number: yup.string().when("has_gst", {
    is: true,
    then: () =>
      yup
        .string()
        .required("Please enter your GST number")
        .matches(gst_regex, "Please enter a valid GST number"),
    otherwise: () => yup.string().nullable(),
  }),
  gst_verified: yup.boolean().when("has_gst", {
    is: true,
    then: () =>
      yup
        .boolean()
        .default(false)
        .test(
          "is-gst-verified",
          "GST number must be verified",
          (value) => value === true
        ),
    otherwise: () => yup.boolean().nullable(),
  }),
});

const addressProofSchema = yup.object({
  address_proof: yup
    .mixed()
    .test("fileName", "Image is required", (value) => value && value.name)
    .test(
      "fileSize",
      "File too large",
      (value) => value && value.size <= 1024 * 1024 * 20
    ),
  address_type: yup.string().required("Address proof type is required"),
});

const logoSchema = yup.object({
  company_logo: yup
    .mixed()
    .test("fileName", "Image is required", (value) => value),
});

const bannerSchema = yup.object({
  banner_image: yup
    .mixed()
    .test("fileName", "Image is required", (value) => value),
});

const aboutSchema = yup.object({
  about: yup
    .string()
    .required()
    .max(1000, "About must be at most 500 characters"),
});

const successItem = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .max(60, "Title must be at most of 60 characters"),
  description: yup
    .string()
    .required("Description is required")
    .max(200, "Description must be at most of 500 characters"),
  image: yup.mixed().test("fileName", "Image is required", (value) => value),
});

const faqItem = yup.object().shape({
  question: yup
    .string()
    .required("Question is required")
    .max(60, "Question must be at most of 60 characters"),
  answer: yup
    .string()
    .required("Answer is required")
    .max(200, "Answer must be at most of 200 characters"),
});

const successStoriesSchema = yup.object({
  Success: yup
    .array()
    .of(successItem)
    .min(1, "Please add atleast 1 success story"),
});

const faqSchema = yup.object({
  faq: yup.array().of(faqItem).min(1, "Please add at least 1 faq"),
});

const achievementSchema = yup.object({
  achievement: yup.array().of(
    yup.object({
      achievement: yup
        .string()
        .max(150, "Achievement must be at most 150 characters"),
    })
  ),
});

const taglineSchema = yup.object({
  tagline: yup.string().max(50, "Tagline must be at most of 50 characters"),
});

const specializationSchema = yup.object({
  specialization: yup.array(),
  remove_specialization: yup.array(),
});
const VerificationStepForm = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [allSpecialization, setAllSpecialization] = useState([]);
  const [existedSpecialization, setExistedSpecialization] = useState([]);
  const [removeSpecialization, setRemoveSpecialization] = useState([]);
  const [newCompany, setNewCompany] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [dltLogo, setDltLogo] = useState("");
  const [dltBanner, setDltBanner] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const user_details = useSelector((state) => state.auth);
  const { company_detail } = useSelector((state) => state.afterSearch);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      Success: [{ title: "", description: "", image: "" }],
      faq: [{ question: "", answer: "" }],
      Achievement: [{ achievement: "" }],
    },
    resolver: yupResolver(
      currentStep == 1
        ? documentSchema
        : currentStep == 2
        ? addressProofSchema
        : currentStep == 3
        ? logoSchema
        : currentStep == 4
        ? bannerSchema
        : currentStep === 5
        ? specializationSchema
        : currentStep == 6
        ? aboutSchema
        : currentStep == 7
        ? successStoriesSchema
        : currentStep === 8
        ? faqSchema
        : currentStep === 9
        ? achievementSchema
        : taglineSchema
    ),
  });
  const {
    fields: successFields,
    append: successAppend,
    remove: successRemove,
    update,
  } = useFieldArray({
    control,
    name: "Success",
  });

  const {
    fields: faqFields,
    append: faqAppend,
    remove: faqRemove,
  } = useFieldArray({
    control,
    name: "faq",
  });

  const { fields: achievementFaq, append: achievementAppend } = useFieldArray({
    control,
    name: "Achievement",
  });

  const formValues = watch();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectTab = () => {
    if (user_details && user_details.auth) {
      const user = company_detail;
      if (user.pan_status !== "Approved") {
        setCurrentStep(1);
        return;
      }
      if (!user.company_logo) {
        setCurrentStep(3);
        return;
      }
      if (!user.banner_image) {
        setCurrentStep(4);
        return;
      }
      if (
        !user.specialization ||
        (user.specialization && user.specialization.split(",").length < 5)
      ) {
        setCurrentStep(5);
        return;
      }
      if (!user.overview_description) {
        setCurrentStep(6);
        return;
      }
      if (
        !user.stories ||
        (user.stories && JSON.parse(user.stories).length < 3)
      ) {
        setCurrentStep(7);
        return;
      }
      if (!user.faq || (user.faq && JSON.parse(user.faq).length < 3)) {
        setCurrentStep(8);
        return;
      }
      if (!user.achievements) {
        setCurrentStep(9);
        return;
      }
      if (!user.tagline) {
        setCurrentStep(10);
        return;
      }
    }
  };

  const checkValid = () => {
    switch (currentStep) {
      case 1:
        formValues.pan_number && formValues.pan_number !== ""
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 2:
        formValues.address_type && formValues.address_proof
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 3:
        formValues.company_logo && formValues.company_logo?.length !== 0
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 4:
        formValues.banner_image && formValues.banner_image?.length !== 0
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 5:
        formValues.specialization &&
        formValues.specialization.length + existedSpecialization.length > 0
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 6:
        formValues.about ? setDisabled(false) : setDisabled(true);
        break;
      case 7:
        formValues.Success && formValues.Success.length > 0
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 8:
        formValues.faq && formValues.faq.length > 0
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 9:
        formValues.Achievement && formValues.Achievement.length > 0
          ? setDisabled(false)
          : setDisabled(true);
        break;
      case 10:
        setDisabled(false);
        break;
    }
  };

  useEffect(() => {
    checkValid();
  }, [formValues]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }, []);

  useEffect(() => {
    selectTab();
  }, []);

  useEffect(() => {
    dispatch(
      getCompanyProfile({
        slug: user_details?.auth?.slug,
        user_id: user_details?.auth?.uid,
      })
    );
  }, [updateTrigger]);

  useEffect(() => {
    getSpecializationData();
    getAgentProfileData();
    if (company_detail.faq) {
      setValue("faq", JSON.parse(company_detail.faq));
    }
    if (company_detail.achievements) {
      setValue("Achievement", JSON.parse(company_detail.achievements));
    }
    if (company_detail.stories) {
      setValue("Success", JSON.parse(company_detail.stories));
    }
    if (company_detail.pan_status === "Approved") {
      setValue("pan_verified", true);
    }
    if (company_detail.gst_status === "Approved") {
      setValue("gst_verified", true);
      setValue("has_gst", true);
    }
    setValue("tagline", company_detail.tagline);
    setValue("pan_number", company_detail.pan_number);
    setValue("gst_number", company_detail.gst_number);
    if (company_detail.company_logo && company_detail.company_logo !== "") {
      setValue("company_logo", company_detail.company_logo);
    }
    if (company_detail.banner_image) {
      setValue("banner_image", `${company_detail.banner_image}`);
    }
    if (company_detail.authorize_certificate) {
      setValue("address_proof", company_detail.authorize_certificate);
    }
    setValue("about", company_detail.overview_description);
  }, [updateTrigger, company_detail]);

  const getAgentProfileData = () => {
    getAgentProfileApi()
      .then(async (response) => {
        if (response.data.status === "success") {
          setExistedSpecialization(response.data.data.specialization);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const onSubmit = (data) => {
    try {
      let formData = new FormData();
      switch (currentStep) {
        case 1:
          if (data.pan_number) {
            formData.append("pan_number", data.pan_number);
            formData.append("pan_status", "Approved");
          }
          if (data.has_gst && data.gst_number) {
            formData.append("gst_number", data.gst_number);
            formData.append("gst_status", "Approved");
          }
          if (newCompany && newCompany !== "") {
            formData.append("new_company", newCompany);
          }
          if (newUserName && newUserName !== "") {
            formData.append("new_owner", newUserName);
          }

          updateProfileFun(formData);
          setNewUserName("");
          setNewCompany("");
          break;
        case 2:
          formData.append("address_proof", data.address_proof);
          formData.append("address_type", data.address_type);
          updateProfileFun(formData);
          break;
        case 3:
          formData.append("profile_image", data.company_logo);
          if (dltLogo) {
            formData.append("dlt_logo", dltLogo);
            setValue("company_logo", "");
          }
          updateProfileFun(formData);
          break;
        case 4:
          formData.append("banner_image", data.banner_image);
          if (dltBanner) {
            formData.append("dlt_banner", dltBanner);
            setValue("banner_image", "");
          }
          updateProfileFun(formData);
          break;
        case 5:
          if (data.specialization && data.specialization.length > 0) {
            data.specialization.forEach((el) => {
              const isExisting = existedSpecialization.some(
                (item) => item.name === el.name
              );
              if (!isExisting) {
                formData.append("specialization[]", el.id);
              }
            });
          }
          if (removeSpecialization && removeSpecialization.length > 0) {
            removeSpecialization.map((el) =>
              formData.append("remove_specialization[]", el.id)
            );
          }
          updateProfileFun(formData);
          setRemoveSpecialization([]);
          setExistedSpecialization([]);
          setValue("specialization", []);
          break;
        case 6:
          formData.append("overview_description", data.about);
          updateProfileFun(formData);
          break;
        case 7:
          formData.append("stories", JSON.stringify(data.Success));
          updateProfileFun(formData);
          break;
        case 8:
          formData.append("faq", JSON.stringify(data.faq));
          updateProfileFun(formData);
          break;
        case 9:
          formData.append("achievements", JSON.stringify(data.Achievement));
          updateProfileFun(formData);
          break;
        case 10:
          formData.append("tagline", data.tagline);
          updateProfileFun(formData);
          if (company_detail.profile_percentage > 75) {
            navigate("/partner-dashboard");
          } else {
            setShowModal(!showModal);
          }
          break;
      }
      if (currentStep < 10) {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSpecializationData = async () => {
    const Specialization = await getSpecializationFilterApi();
    if (Specialization && Specialization.length > 0) {
      let all_specialization = [];
      Specialization.forEach((element) => {
        all_specialization = [...all_specialization, ...element.child];
      });
      setAllSpecialization(all_specialization);
    }
  };

  const updateProfileFun = async (formData) => {
    try {
      const response = await updateAgentProfileApi(formData);
      if (response.data.status === "success") {
        setUpdateTrigger(!updateTrigger);
        if (currentStep === 10) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  if (showLoader) {
    return <MainLoader />;
  }

  return (
    <>
      <StudentHeader formDisplay={true} />
      <section className="stapform">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <h6>
                <Link to={"/partner-dashboard"} className="backprofile">
                  <i className="bi bi-arrow-left-short"></i> Back to Profile
                  Dashboard
                </Link>
              </h6>

              <h3>
                Complete your Profile
                <span className="float-end">
                  <b>
                    {company_detail.profile_percentage
                      ? company_detail.profile_percentage
                      : 0}
                    %
                  </b>{" "}
                  Completed
                </span>
              </h3>
              <p>
                Profiles with a 100% completion rate are more likely to attract
                leads on CHOWNK
              </p>
              <form id="regForm" onSubmit={handleSubmit(onSubmit)}>
                <VerificationTabs
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  user={company_detail}
                />

                <PanTab
                  currentStep={currentStep}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  trigger={trigger}
                  setError={setError}
                  getValues={getValues}
                  user={company_detail}
                  newCompany={newCompany}
                  setNewCompany={setNewCompany}
                  newUserName={newUserName}
                  setNewUserName={setNewUserName}
                />
                <AddressTab
                  setValue={setValue}
                  currentStep={currentStep}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                />
                <LogoTab
                  currentStep={currentStep}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  company_logo={`${
                    company_detail.company_logo
                      ? company_detail.company_logo
                      : ""
                  }`}
                  clearErrors={clearErrors}
                  getValues={getValues}
                  user={company_detail}
                  dltLogo={dltLogo}
                  setDltLogo={setDltLogo}
                />

                <BannerTab
                  currentStep={currentStep}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  banner_image={`${
                    company_detail.banner_image
                      ? company_detail.banner_image
                      : ""
                  }`}
                  getValues={getValues}
                  user={company_detail}
                  dltBanner={dltBanner}
                  setDltBanner={setDltBanner}
                />

                <SpecializedTab
                  currentStep={currentStep}
                  allSpecialization={allSpecialization}
                  existSpecialization={existedSpecialization}
                  setValue={setValue}
                  getValues={getValues}
                  removeSpecialization={removeSpecialization}
                  setRemoveSpecialization={setRemoveSpecialization}
                />

                <AboutTab
                  currentStep={currentStep}
                  errors={errors}
                  register={register}
                  user={company_detail}
                  setValue={setValue}
                  getValues={getValues}
                />

                <SuccessStoriesTab
                  currentStep={currentStep}
                  fields={successFields}
                  append={successAppend}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  update={update}
                  getValues={getValues}
                  remove={successRemove}
                />

                <FaqTab
                  currentStep={currentStep}
                  fields={faqFields}
                  append={faqAppend}
                  errors={errors}
                  register={register}
                  remove={faqRemove}
                />

                <AchievementTab
                  currentStep={currentStep}
                  fields={achievementFaq}
                  append={achievementAppend}
                  register={register}
                  errors={errors}
                />

                <TaglineTab
                  setValue={setValue}
                  currentStep={currentStep}
                  errors={errors}
                  register={register}
                  taglineLength={
                    company_detail.tagline ? company_detail.tagline.length : 0
                  }
                />

                <div style={{ overflow: "auto" }} className="mt-3">
                  <Link
                    className="skip"
                    onClick={() => {
                      if (currentStep < 10) {
                        setCurrentStep(currentStep + 1);
                      } else {
                        if (company_detail.profile_percentage < 75) {
                          selectTab();
                        } else {
                          navigate("/partner-dashboard");
                        }
                      }
                    }}
                  >
                    Skip now
                  </Link>
                  <div style={{ float: "right" }}>
                    {currentStep > 1 && (
                      <button
                        type="button"
                        id="prevBtn"
                        onClick={() => setCurrentStep(currentStep - 1)}
                        style={{ display: currentStep < 10 ? "" : "none" }}
                      >
                        Previous
                      </button>
                    )}
                    <button
                      type="submit"
                      id="nextBtn"
                      style={{ opacity: disabled ? "0.6" : "1" }}
                      disabled={disabled}
                    >
                      {currentStep < 10 ? "Next" : "Publish Profile"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <RightBar />
          </div>
        </div>
      </section>
      <Example
        show={showModal}
        component={
          <ProfileIncompleteModal
            user={company_detail}
            selectTab={selectTab}
            onHide={() => setShowModal(!showModal)}
          />
        }
        onHide={() => setShowModal(!showModal)}
        backDrop={false}
        hideCloseBtn={true}
        style={"areyoustill pro_inc"}
      />
    </>
  );
};

export default VerificationStepForm;
