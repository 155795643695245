import { data } from "jquery";
import React from "react";

const FormTwo = ({ page, setPage, data, onChange }) => {
  return (
    <div className="form-page">
      <h2>
        IN WHICH <b>COUNTRY</b> YOU ARE PLANNING TO START A BUSINESS?
      </h2>
      <p>
        It is a long established fact that a reader will be distracted by the
        readable
      </p>
      <ul>
        <li>
          <span className="option">
            UNITED STATES OF AMERICA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?"
                className="quality"
                value="UNITED STATES OF AMERICA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?" &&
                    el.ans === "UNITED STATES OF AMERICA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            UNITED KINGDOM
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?"
                className="quality"
                value="UNITED KINGDOM"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?" &&
                    el.ans === "UNITED KINGDOM"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            AUSTRALIA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?"
                className="quality"
                value="AUSTRALIA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?" &&
                    el.ans === "AUSTRALIA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            CANADA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?"
                className="quality"
                value="CANADA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?" &&
                    el.ans === "CANADA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            NEW ZEALAND
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?"
                className="quality"
                value="NEW ZEALAND"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?" &&
                    el.ans === "NEW ZEALAND"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            OTHERS
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?"
                className="quality"
                value="OTHERS"
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "IN WHICH COUNTRY YOU ARE PLANNING TO START A BUSINESS?" &&
                    el.ans === "OTHERS"
                )}
                onChange={onChange}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>
      </ul>

      <button className="btn" data-nav="next" onClick={() => setPage(page + 1)}>
        {" "}
        Next{" "}
      </button>
      <button
        className="btn prevBtn"
        data-nav="prev"
        onClick={() => setPage(page - 1)}
      >
        {" "}
        Prev{" "}
      </button>
    </div>
  );
};

export default FormTwo;
