import React from "react";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import ViewAllComponent from "../../../Tools/ViewAllComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCompanyProfile } from "../../../../Reducer/afterSearchSlice";
import Example from "../../../Tools/ReactBoostrap";
import ViewProfileModel from "../../../Layouts/Model/viewProfileModel";
import { mediaUrl } from "../../../../services/network";
import { reviewsData } from "../../../../services/AgentProfile";
import { SocketContext } from "../../../../Context";
import Meta from "../../Common/Meta";
import FindUser from "../../../Tools/FindUser";
import LoginModel from "../../../Tools/LoginModel";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  addViewProfileAction,
  saveCallDetail,
  updateAgentProfileApi,
} from "../../../../services/api";
import { toast } from "react-toastify";

const ViewAll = () => {
  const { company, section, num, dynamic } = useParams();
  const third = section !== undefined ? section : num;
  const second = company !== undefined ? company : dynamic;
  const dispatch = useDispatch();
  const { company_detail } = useSelector((state) => state.afterSearch);
  const [show, setShow] = React.useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const [index, setIndex] = React.useState(0);
  const [reRender, setReRender] = useState(false);

  const selector = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const {
    setCurrentUser,
    finUser,
    findChatFun,
    isPlaying,
    setIsPlaying,
    addFundsModal,
    setAddFundsModal,
  } = React.useContext(SocketContext);

  const updateProfileFun = async (formData) => {
    try {
      const response = await updateAgentProfileApi(formData);
      if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        setReRender(!reRender);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const loginModelHandle = (type) => {
    if (
      selector &&
      selector.auth &&
      company_detail &&
      company_detail.id !== selector.auth.id &&
      company_detail.uid !== selector.auth.parent_id
    ) {
      if (type == "video") {
        if (
          company_detail.online !== true ||
          company_detail.online !== "true"
        ) {
          saveCall(company_detail.uid);
        }
        if (selector.funds >= selector.call_min) {
          let data = {
            connection_user: company_detail.uid,
            uid: company_detail.uid,
            name: company_detail.company_name,
            image: company_detail.company_logo,
          };
          setCurrentUser(data);
          finUser(company_detail.uid);
          setUserModel(true);
        } else {
          setAddFundsModal(!addFundsModal);
        }
      } else {
        var date = Math.round(new Date().getTime() / 1000);
        let data = {
          receiver_id: company_detail.uid,
          image: company_detail.company_logo,
          company_name: company_detail.company_name,
          online: company_detail.online !== "" ? true : false,
          status: company_detail.status,
          owner_name: company_detail.owner_name,
          slug: company_detail.slug,
          city: company_detail.city,
          userRole: "branch",
        };
        findChatFun(data);
      }
    } else {
      setLoginShow(true);
    }
  };
  React.useEffect(() => {
    if (company_detail) {
      if (
        third === "success-stories" ||
        third === "faq" ||
        third === "reviews"
      ) {
        dispatch(getCompanyProfile({ slug: second }));
      } else {
        navigate("/");
      }
      if (company_detail.uid) {
        viewProfileAction();
      }
    }
  }, [reRender]);

  const checkPage = () => {
    switch (third) {
      case "success-stories":
        return 2;
      case "reviews":
        return 3;
      case "faq":
        return 4;
      default:
        return 1;
    }
  };

  const viewProfileAction = async () => {
    try {
      const guestId = localStorage.getItem("guestId");
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const body = {
        profile: company_detail.uid,
        user_id: Number(selector.auth.id ? selector.auth.id : guestId),
        type: selector ? 1 : 0,
        check: 0,
        city: userDetails.city,
        page: checkPage(),
        category: 2,
      };
      await addViewProfileAction(body);
    } catch (error) {
      console.log(error);
    }
  };

  let dataa;
  if (third === "success-stories") {
    dataa =
      company_detail &&
      company_detail.stories &&
      JSON.parse(company_detail.stories);
  }

  const saveCall = (id) => {
    try {
      const body = { to: id };
      saveCallDetail(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {third === "success-stories" ? (
        <Meta
          title={`Success Stories Showcase of ${company_detail.company_name} in ${company_detail.city} - Chownk `}
          description={`Dive into the triumphs at ${company_detail.company_name} in ${company_detail.city} ! Uncover inspiring success stories that showcase excellence. Join us in crafting your own success narrative.View Success Stories , Reviews and Ratings , About , FAQ and more`}
          keywords={`${company_detail.company_name} in ${company_detail.city} , Success Stories`}
        />
      ) : third === "faq" ? (
        <Meta
          title={`${company_detail.company_name} in ${company_detail.city} | Frequently Asked Questions (FAQs) - Chownk`}
          description={`Explore answers to your queries at ${company_detail.company_name} in ${company_detail.city} FAQs. Clear insights into our courses, schedules, and more. Your pathway to informed decisions starts here!`}
          keywords={`${company_detail.company_name} in ${company_detail.city}, Frequently Asked Questions`}
        />
      ) : third === "reviews" ? (
        <Meta
          title={`${company_detail.company_name} in ${company_detail.city} - Ratings and Reviews - Chownk`}
          description={`Discover excellence at ${company_detail.company_name} in ${company_detail.city} through glowing ratings and reviews. Join a community of satisfied learners on a journey to success!`}
          keywords={`${company_detail.company_name} in ${company_detail.city}, Ratings and Reviews`}
        />
      ) : (
        ""
      )}
      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />

      <section className="single_details profile_viewall_page">
        <div className="container mt-0">
          <Link
            to={`${`/${company_detail && company_detail.city}/${
              company_detail && company_detail.slug
            }/`}`}
            replace={true}
            className="back-button-viewAll"
          >
            <i className="bi bi-arrow-left"></i>
          </Link>
          <div className="all_page">
            <div className="viewall">
              <div className="digit">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${
                      mediaUrl + company_detail?.company_logo
                    })`,
                  }}
                ></div>

                <h1>{company_detail.company_name}</h1>
                <p>
                  <Rating
                    initialValue={
                      company_detail && company_detail.averageRating
                    }
                    allowFraction
                    readonly={true}
                  />
                </p>
                <p>{company_detail.permanent_address}</p>
              </div>
              <div className="starpeople">
                <p>
                  <i className="bi bi-star-fill"></i>{" "}
                  {company_detail &&
                  company_detail.averageRating !== undefined &&
                  company_detail.averageRating > 0
                    ? company_detail.averageRating.toFixed(1)
                    : ""}
                  ({company_detail && company_detail.rattingCount}) Rating
                </p>
                <p>
                  <i className="bi bi-people-fill"></i>{" "}
                  {`${
                    company_detail && company_detail.followersCount
                  } Followers`}
                </p>
              </div>
              <p className="my-4">
                {isPlaying && isPlaying == true ? (
                  <Link className="btntimer">
                    <i className="bi bi-camera-video-fill"></i> {"Call"}
                    <CountdownCircleTimer
                      isPlaying={isPlaying}
                      duration={10}
                      colors="#A30000"
                      size={36}
                      initialRemainingTime={10}
                      strokeWidth={4}
                      trailStrokeWidth={4}
                      children={({ remainingTime }) => {
                        const minutes = Math.floor(remainingTime / 60);
                        const seconds = remainingTime % 60;
                        return `${seconds}`;
                      }}
                      onComplete={() => {
                        setIsPlaying(false);
                        return { shouldRepeat: false, delay: 1.5 };
                      }}
                    />
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      if (company_detail.uid !== selector?.auth?.uid) {
                        loginModelHandle("video");
                      }
                    }}
                    className={`${
                      company_detail.uid === selector?.auth?.uid
                        ? "disabled"
                        : ""
                    }`}
                  >
                    {(company_detail.online == true ||
                      company_detail.online == "true") &&
                      company_detail.video_status == "active" && <span></span>}
                    <i className="bi bi-camera-video-fill"></i> {"Call"}
                  </Link>
                )}

                <Link
                  onClick={() => {
                    if (company_detail.uid !== selector?.auth?.uid) {
                      loginModelHandle("chat");
                    }
                  }}
                  className={`${
                    company_detail.uid === selector?.auth?.uid ? "disabled" : ""
                  }`}
                >
                  {(company_detail.online == true ||
                    company_detail.online == "true") && <span></span>}
                  <i className="bi bi-chat-fill"></i> {"Message"}
                </Link>
              </p>
            </div>

            <h2>
              {" "}
              {`${
                third === "success-stories"
                  ? "Success Stories"
                  : third === "faq"
                  ? "FAQ's"
                  : third === "reviews" && "Reviews "
              } of ${company_detail.company_name}
                  ${company_detail.permanent_address}`}
            </h2>
            <ViewAllComponent
              data={
                third === "success-stories"
                  ? company_detail && company_detail.stories && dataa
                  : third === "faq"
                  ? company_detail &&
                    company_detail.faq &&
                    JSON.parse(company_detail.faq)
                  : third === "reviews"
                  ? reviewsData
                  : []
              }
              sectionType={third}
              setShow={setShow}
              setIndex={setIndex}
              user_id={company_detail && company_detail.uid}
              averageRatting={company_detail && company_detail.averageRating}
              updateProfileFun={updateProfileFun}
              showEdit={
                company_detail?.uid === selector?.auth?.uid ? true : false
              }
            />
          </div>
        </div>
      </section>
      <Example
        show={show}
        size={"lg"}
        onHide={() => setShow(!show)}
        component={
          <ViewProfileModel
            onHide={() => setShow(!show)}
            //   companyName={company_detail && company_detail.company_name}
            data={dataa}
            index={index}
            // ref={ref}
          />
        }
        style={"viewpro "}
      />
      <Example
        show={userModel}
        style={"connect ofline_div"}
        onHide={() => setUserModel(!userModel)}
        component={
          <FindUser
            show={userModel}
            data={company_detail}
            onHide={() => setUserModel(!userModel)}
          />
        }
        backDrop={"static"}
      />
      <LoginModel
        show={loginShow}
        setShow={setLoginShow}
        redirect={window.location.href}
      />
      <StudentFooter />
    </>
  );
};
export default ViewAll;
