import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { mediaUrl } from "../../../../services/network";
import { Player } from "video-react";
import Skeleton from "react-loading-skeleton";
import Example from "../../../Tools/ReactBoostrap";
import NewSuccessStories from "../../../Layouts/Model/newSuccessStories";
import ViewProfileModel from "../../../Layouts/Model/viewProfileModel";
import { Carousel } from "react-bootstrap";

const SuccessStories = ({
  companyName,
  data,
  loading,
  companyOwner,
  selector,
  updateProfileFun,
  city,
  slug,
  navState,
  handleUserNavigate,
}) => {
  const [stories, setStories] = useState([]);
  const [addNewStory, setAddNewStory] = useState(false);
  const [index, setIndex] = useState("");
  const [storyModel, setStoryModel] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [viewIndex, setViewIndex] = useState(null);
  const new_city = city && city.replaceAll(" ", "-");
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const navigate = useNavigate();
  const reff = useRef();

  useEffect(() => {
    if (data) {
      const new_stories = JSON.parse(data);
      new_stories.sort((a, b) => new Date(b.time) - new Date(a.time));
      setStories(new_stories);
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getBackgroundImage = (item) => {
    const base64Pattern =
      /^data:(image|video)\/([a-zA-Z0-9]+(\/[a-zA-Z0-9]+)*)?(;.*)?;base64,/;
    const isBase64 = base64Pattern.test(item);
    return isBase64 ? item : mediaUrl + item;
  };

  return (
    <>
      {(stories.length > 0 || selector?.uid === companyOwner || loading) && (
        <div className="set Success">
          <div className="d-flex justify-content-between">
            <h2 className="mb-3">Success Stories</h2>
            <span>
              {selector?.uid === companyOwner && (
                <Link
                  className="plus"
                  onClick={() => {
                    setAddNewStory(!addNewStory);
                  }}
                >
                  <i className="bi bi-plus-circle"></i>
                </Link>
              )}
              {stories.length > 4 && (
                <Link
                  className="viewall ms-2"
                  to={"success-stories"}
                  replace={true}
                  onClick={() => {
                    if (handleUserNavigate) {
                      handleUserNavigate(
                        "navigate",
                        "/success-stories",
                        "view all success stories"
                      );
                    }
                  }}
                >
                  <i className="bi bi-eye"></i> View all
                </Link>
              )}
            </span>
          </div>

          <div className="row ">
            {loading ? (
              <>
                <div className="col-sm-5 col-lg-5">
                  <div className="imagevideo1">
                    <Skeleton height={"343px"} width={"100%"} />
                  </div>
                </div>
                <div className="col-sm-5 col-lg-5">
                  <div className="imagevideo1">
                    <Skeleton height={"343px"} width={"100%"} />
                  </div>
                </div>
              </>
            ) : (
              <>
                {screenSize.width &&
                screenSize.width <= 570 &&
                stories &&
                stories.length > 0 ? (
                  <div
                    className="col-sm-12"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                  >
                    <Carousel
                      autoPlay={false}
                      showThumbs={false}
                      showIndicators={false}
                      infiniteLoop={false}
                      showArrows={showArrow}
                    >
                      {stories.map((item, i) => {
                        if (item.media_type === "video") {
                          return (
                            <Carousel.Item>
                              <>
                                {selector?.uid === companyOwner && (
                                  <span
                                    onClick={() => {
                                      setIndex(i);
                                      setAddNewStory(!addNewStory);
                                    }}
                                  >
                                    <i class="bi bi-pencil-square"></i>
                                  </span>
                                )}
                                <div
                                  key={i}
                                  onMouseEnter={(e) => {
                                    const element = e.target;
                                    element.play();
                                  }}
                                  onMouseLeave={(e) => {
                                    const element = e.target;
                                    element.pause();
                                  }}
                                  // onClick={() =>
                                  //   navigate(`/${new_city}/${slug}`, {
                                  //     state: navState,
                                  //   })
                                  // }
                                  onClick={() => {
                                    handleUserNavigate(
                                      "action",
                                      "view stories",
                                      "view Stories Modal"
                                    );
                                    setViewIndex(i);
                                    setStoryModel(!storyModel);
                                    navigate(`/${new_city}/${slug}`, {
                                      state: navState,
                                    });
                                  }}
                                >
                                  <Player
                                    playsInline
                                    src={mediaUrl + item.image}
                                    fluid={false}
                                    ref={reff}
                                    preload="metadata"
                                  />
                                  <h3>{item.title}</h3>
                                </div>
                              </>
                            </Carousel.Item>
                          );
                        } else {
                          return (
                            <Carousel.Item>
                              <>
                                {selector?.uid === companyOwner && (
                                  <span
                                    onClick={() => {
                                      setIndex(i);
                                      setAddNewStory(!addNewStory);
                                    }}
                                  >
                                    <i class="bi bi-pencil-square"></i>
                                  </span>
                                )}
                                <div
                                  onClick={() => {
                                    handleUserNavigate(
                                      "action",
                                      "view stories",
                                      "view Stories Modal"
                                    );
                                    setViewIndex(i);
                                    setStoryModel(!storyModel);
                                    navigate(`/${new_city}/${slug}`, {
                                      state: navState,
                                    });
                                  }}
                                >
                                  <img
                                    src={`${mediaUrl + item.image}`}
                                    alt={`Success story ${i + 1} of ${companyName} ${city}`}
                                  />
                                  <h3>{item.title}</h3>
                                </div>
                              </>
                            </Carousel.Item>
                          );
                        }
                      })}
                    </Carousel>
                  </div>
                ) : (
                  ""
                )}
                {screenSize.width &&
                  screenSize.width > 570 &&
                  stories &&
                  stories.length > 0 &&
                  stories.slice(0, 2).map((item, index) => (
                    <div className="col-sm-5 col-lg-5">
                      {item.media_type === "image" ? (
                        <>
                          {selector?.uid === companyOwner && (
                            <span
                              onClick={() => {
                                setIndex(index);
                                setAddNewStory(!addNewStory);
                              }}
                            >
                              <i class="bi bi-pencil-square"></i>
                            </span>
                          )}
                          <div
                            className="imagevideo1"
                            // style={{
                            //   backgroundImage: `url(${getBackgroundImage(
                            //     item.image
                            //   )})`,
                            // }}
                            onClick={() => {
                              handleUserNavigate(
                                "action",
                                "view stories",
                                "view Stories Modal"
                              );
                              setViewIndex(index);
                              setStoryModel(!storyModel);
                            }}
                          >
                            <img
                              src={`${getBackgroundImage(item.image)}`}
                              alt={`Success story ${index + 1} of ${companyName} ${city}`}
                            />
                            <h3>{item.title}</h3>
                          </div>
                        </>
                      ) : (
                        <>
                          {selector?.uid === companyOwner && (
                            <span
                              onClick={() => {
                                setIndex(index);
                                setAddNewStory(!addNewStory);
                              }}
                            >
                              <i class="bi bi-pencil-square"></i>
                            </span>
                          )}
                          <div
                            className="imagevideo1"
                            onClick={() => {
                              handleUserNavigate(
                                "action",
                                "view stories",
                                "view Stories Modal"
                              );
                              setViewIndex(index);
                              setStoryModel(!storyModel);
                            }}
                          >
                            <Player
                              playsInline
                              src={getBackgroundImage(item.image)}
                            />
                            <h3>{item.title}</h3>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </>
            )}
            {loading ? (
              <>
                <div className="col-sm-2 col-lg-2">
                  <div className="imagevideo1 imagevideo0 mb-2">
                    <Skeleton height={"200px"} width={"100%"} />
                  </div>
                  <div className="imagevideo1 imagevideo2">
                    <Skeleton height={"100%"} width={"100%"} />
                  </div>
                </div>
              </>
            ) : (
              screenSize.width &&
              screenSize.width > 570 &&
              stories?.length > 2 && (
                <>
                  <div className="col-sm-2 col-lg-2">
                    {stories[2].media_type === "image" ? (
                      <>
                        {selector?.uid === companyOwner && (
                          <span
                            onClick={() => {
                              setIndex(2);
                              setAddNewStory(!addNewStory);
                            }}
                          >
                            <i class="bi bi-pencil-square"></i>
                          </span>
                        )}
                        <div
                          className="imagevideo1 imagevideo0 mb-2"
                          // style={{
                          //   backgroundImage: `url(${getBackgroundImage(
                          //     stories[2].image
                          //   )})`,
                          // }}
                          onClick={() => {
                            handleUserNavigate(
                              "action",
                              "view stories",
                              "view Stories Modal"
                            );
                            setViewIndex(2);
                            setStoryModel(!storyModel);
                          }}
                        >
                          <img
                            src={`${getBackgroundImage(stories[2].image)}`}
                            alt={`Success story ${index + 1} of ${companyName} ${city}`}
                          />
                          <h3>{stories[2].title}</h3>
                        </div>
                      </>
                    ) : (
                      <>
                        {selector?.uid === companyOwner && (
                          <span
                            onClick={() => {
                              setIndex(2);
                              setAddNewStory(!addNewStory);
                            }}
                          >
                            <i class="bi bi-pencil-square"></i>
                          </span>
                        )}
                        <div
                          className="imagevideo1 imagevideo0 mb-2"
                          onClick={() => {
                            setViewIndex(2);
                            setStoryModel(!storyModel);
                          }}
                        >
                          <Player
                            playsInline
                            src={getBackgroundImage(stories[2].image)}
                          />

                          <h3>{stories[2].title}</h3>
                        </div>
                      </>
                    )}
                    {stories.length > 4 ? (
                      <div className="imagevideo1 imagevideo2">
                        <Link to={"success-stories"} className="View">{`+${
                          stories.length - 3
                        } more`}</Link>
                      </div>
                    ) : (
                      stories.length === 4 &&
                      stories.slice(3, 4).map((item, index) =>
                        item.media_type === "image" ? (
                          <>
                            {selector?.uid === companyOwner && (
                              <span
                                onClick={() => {
                                  setIndex(3);
                                  setAddNewStory(!addNewStory);
                                }}
                              >
                                <i class="bi bi-pencil-square"></i>
                              </span>
                            )}
                            <div
                              className="imagevideo1 imagevideo2"
                              // style={{
                              //   backgroundImage: `url(${getBackgroundImage(
                              //     item.image
                              //   )})`,
                              // }}
                              onClick={() => {
                                handleUserNavigate(
                                  "action",
                                  "view stories",
                                  "view Stories Modal"
                                );
                                setViewIndex(3);
                                setStoryModel(!storyModel);
                              }}
                            >
                              <img
                                src={`${getBackgroundImage(item.image)}`}
                                alt={`Success story ${index + 1} of ${companyName} ${city}`}
                              />
                              <h3>{item.title}</h3>
                            </div>
                          </>
                        ) : (
                          <>
                            {selector?.uid === companyOwner && (
                              <span
                                onClick={() => {
                                  setIndex(3);
                                  setAddNewStory(!addNewStory);
                                }}
                              >
                                <i class="bi bi-pencil-square"></i>
                              </span>
                            )}
                            <div
                              className="imagevideo1 imagevideo2"
                              onClick={() => {
                                handleUserNavigate(
                                  "action",
                                  "view stories",
                                  "view Stories Modal"
                                );
                                setViewIndex(3);
                                setStoryModel(!storyModel);
                              }}
                            >
                              <Player
                                playsInline
                                src={getBackgroundImage(item.image)}
                              />

                              <h3>{item.title}</h3>
                            </div>
                          </>
                        )
                      )
                    )}
                  </div>
                </>
              )
            )}
          </div>
        </div>
      )}
      <Example
        show={addNewStory}
        onHide={() => setAddNewStory(!addNewStory)}
        component={
          <NewSuccessStories
            onHide={() => setAddNewStory(!addNewStory)}
            collection={stories}
            setCollection={setStories}
            updateProfileFun={updateProfileFun}
            index={index}
            setIndex={setIndex}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
      <Example
        show={storyModel}
        onHide={() => setStoryModel(!storyModel)}
        style={"viewpro"}
        size={"lg"}
        component={<ViewProfileModel data={stories} index={viewIndex} />}
      />
    </>
  );
};

export default SuccessStories;
