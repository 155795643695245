import { Base64 } from "js-base64";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Meta = ({
  title,
  keywords,
  description,
  canonical,
  redirect,
  serviceType,
  script,
  ogImage,
  twitterImage,
  homeSchema,
  includeFaq,
  blogsfaqs,
  faqs,
  ogTitle,
  singleProfileSchema,
  profileImageSchema,
  bannerImageSchema,
  SuccessStoriesSchema,
  businessSchema,
  ratingSchema,
  citySchema,
  listingFaqSchema,
  video_url,
  embed_url,
  video_thumbnail,
  video_title,
  video_description,
  created_at,
  singleHelpSchema,
  helpFaqSchema,
}) => {
  let tp = "india";
  let url = "https://chownk.com";
  let st = "Immigration Consultancy";
  // if (script) {
  //   tp = script;
  //   let slug = script.replaceAll(" ", "-");
  //   url = url + "/" + slug;
  // }
  if (serviceType) {
    st = serviceType;
  }
  return (
    <Helmet>
      {profileImageSchema && profileImageSchema}
      {bannerImageSchema && bannerImageSchema}
      {singleProfileSchema && singleProfileSchema}
      {SuccessStoriesSchema && SuccessStoriesSchema}
      {businessSchema && businessSchema}
      {ratingSchema && ratingSchema}
      {citySchema && citySchema}
      {listingFaqSchema && listingFaqSchema}
      {singleHelpSchema && singleHelpSchema}
      {helpFaqSchema && helpFaqSchema}
      {video_url &&
        embed_url &&
        video_thumbnail &&
        video_title &&
        video_description &&
        created_at && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "VideoObject",
              name: video_title,
              description: video_description,
              thumbnailUrl: video_thumbnail,
              uploadDate: created_at,
              duration: "PT1M",
              publisher: {
                "@type": "Organization",
                name: "CHOWNK",
              },
              contentUrl: video_url,
              embedUrl: embed_url,
            })}
          </script>
        )}
      {includeFaq && blogsfaqs && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: JSON.parse(blogsfaqs).map((item, i) => ({
              "@type": "Question",
              name: `${item.que}`,
              acceptedAnswer: {
                "@type": "Answer",
                text: `${Base64.decode(item.ans)}`,
              },
            })),
          })}
        </script>
      )}
      {includeFaq && faqs && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: JSON.parse(faqs).map((item, i) => ({
              "@type": "Question",
              name: `${item.question}`,
              acceptedAnswer: {
                "@type": "Answer",
                text: `${
                  item.answer ? `<p>${item.answer}</p>` : `${item.answers}`
                }`,
              },
            })),
          })}
        </script>
      )}
      {homeSchema && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            name: "Chownk",
            url: "https://chownk.com/",
            potentialAction: {
              "@type": "SearchAction",
              target: "https://chownk.com/search?search={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      )}
      {homeSchema && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "CHOWNK",
            alternateName: "Chownk",
            description:
              "CHOWNK is a secure immigration platform connecting you with the best immigration consultants to complete your immigration process entirely online.",
            url: "https://chownk.com/",
            logo: "https://chownk.com/assets/images/chownk-logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+91 8427955568",
              contactType: "customer service",
              areaServed: "IN",
              availableLanguage: ["en", "Hindi"],
            },
            sameAs: [
              "https://www.instagram.com/chownkofficial",
              "https://www.youtube.com/@chownkofficial",
              "https://www.linkedin.com/company/84013959",
              "https://chownk.com/",
            ],
            founder: "Harsh Abrol",
            foundingDate: "2024",
            foundingLocation: {
              "@type": "Place",
              name: "Chandigarh, India",
            },
          })}
        </script>
      )}
      {homeSchema && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "CHOWNK",
            alternateName: "Chownk",
            url: "https://chownk.com/",
            logo: "https://chownk.com/assets/images/chownk-logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "",
              contactType: "",
              areaServed: "IN",
              availableLanguage: ["en", "Hindi"],
            },
            sameAs: [
              "https://www.instagram.com/chownkofficial",
              "https://www.youtube.com/@chownkofficial",
              "https://www.linkedin.com/company/84013959",
              "https://chownk.com/",
            ],
          })}
        </script>
      )}

      {
        script && script
        // (
        //   <script type="application/ld+json">
        //     {JSON.stringify({
        //       "@context": "https://schema.org",
        //       "@type": "LocalBusiness",
        //       name: "CHOWNK",
        //       alternateName: "Chownk",
        //       description: description,
        //       url: url,
        //       telephone: "+91 8427955568",
        //       address: {
        //         "@type": "PostalAddress",
        //         streetAddress: "optional",
        //         postalCode: "optional",
        //         addressLocality: tp,
        //         addressRegion: "CH",
        //         addressCountry: "IN",
        //       },
        //       parentOrganization: {
        //         "@type": "Organization",
        //         "@id": "https://chownk.com#organization",
        //       },
        //       areaServed: {
        //         "@type": "Place",
        //         name: tp,
        //       },
        //       makesOffer: {
        //         "@type": "Offer",
        //         itemOffered: {
        //           "@type": "Service",
        //           name: serviceType,
        //         },
        //       },
        //       contactPoint: {
        //         "@type": "ContactPoint",
        //         telephone: "+91 8427955568",
        //         contactType: "customer service",
        //         areaServed: "IN",
        //         availableLanguage: ["en", "Hindi"],
        //       },
        //       priceRange: "optional",
        //       image: "https://chownk.com/assets/images/chownk-logo.png",
        //       sameAs: [
        //         "https://www.facebook.com/chownkofficial",
        //         "https://www.instagram.com/chownkofficial",
        //         "https://www.youtube.com/channel/UC3gYIcz4rHJ9vEtm4sKlr1Q",
        //         "https://www.linkedin.com/company/84013959",
        //       ],
        //     })}
        //   </script>
        // )
      }

      <title id="pageTitle">
        {title ? title : "Chownk: Your Trusted Overseas Education Marketplace"}
      </title>
      <meta name="description" content={description ? description : ""} />
      <meta name="keywords" content={keywords ? keywords : ""} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={
          ogTitle
            ? ogTitle
            : "Chownk: Your Trusted Overseas Education Marketplace"
        }
      />
      <meta
        property="og:description"
        content={description ? description : ""}
      />
      {ogImage && <meta property="og:image" content={ogImage} />}

      <meta property="og:url" content="https://chownk.com" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {ogTitle && <meta
        name="twitter:title"
        content={
          ogTitle ? ogTitle : "Chownk: Your Trusted Overseas Education Marketplace"
        }
      />}
      <meta
        name="twitter:description"
        content={description ? description : ""}
      />
      {ogImage && <meta name="twitter:image" content={ogImage} />}

      {canonical && <link rel="canonical" href={redirect.toLowerCase()} />}
    </Helmet>
  );
};

export default Meta;
