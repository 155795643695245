import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import ApplicationidApp from "./ApplicationId";
import OverviewApp from "./Overviewapp";
import Detailsapp from "./Detailsapp";
import FilesApp from "./Filesapp";
import ChecklistApp from "./Checklistapp";
import { fetchDatadetailsapp } from "../../../../Reducer/applicationSlice";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Example from "../../../Tools/ReactBoostrap";
import Applicationpopup from "../../../Layouts/Model/applicationpopup";
import { Updateappreview } from "../../../../services/api";
import { toast } from "react-toastify";

const SingleApplication = () => {
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  let list = useSelector((state) => state.application?.single);
  const userid = useSelector((state) => state.auth?.auth?.uid);
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [FaqData, setFaqData] = useState("");
  const [data, setdata] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [formData, setFormData] = useState({
    appstatus: "",
  });

  const onSubmit = async (e) => {
    // alert(e.target.elements.appstatus.value)
    // e.preventDefault();
    //if appstatus ==accept check form submit or not

    let formdata = new FormData();
    formdata.append("appid", id);
    formdata.append("appstatus", formData.appstatus);
    formdata.append("review1", FaqData.review1);
    formdata.append("review2", FaqData.review2);
    if (formData.appstatus == "reject") {
      //do nothing
    } else {
      //accepted
      if (!list?.fname) {
        toast.error("Please submit details form then review", {
          position: toast.POSITION.TOP_LEFT,
        });
        return false;
      }
    }

    if (
      FaqData.review1 &&
      FaqData.review2 &&
      FaqData.review1 !== undefined &&
      FaqData.review2 !== undefined
    ) {
      const savecollege = await Updateappreview(formdata);
      toast.success("Review Submitted", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      toast.error("Error while Submission", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // alert(name)
    // alert(value)
  };

  useEffect(() => {
    let formdata = new FormData();
    formdata.append("appid", id);
    dispatch(fetchDatadetailsapp(formdata));
    let data = [1, 2, 3];
    console.log("FaqData", FaqData);
    setdata(data);
  }, [id, FaqData]);
  return (
    <>
      <StudentHeader />

      {/* {(list?.application_status == 'accept' || list?.application_status == 'reject'|| userid !== list?.receiver_id) ? "" :
  <form onSubmit={handleSubmit(onSubmit)}>
    <select onChange={(e) => {
      handleChange(e);
      togglePopup();
    }} name="appstatus" className="form-control">
      <option value="">Select Status</option>
      <option value="accept">Accepted</option>
      <option value="reject">Rejected</option>
    </select>
    <button>Submit</button>
  </form>
} */}

      {/* {userid && userid !== list?.receiver_id && (list?.application_status=='accept'||list?.application_status=='reject')?"":<form onSubmit={handleSubmit(onSubmit)}>
      <select onChange={(e) => {
                    handleChange(e);
                    togglePopup()
                  }} name="appstatus" class="form-control">
                  
                  <option value="">Select Status</option>
                  <option value="accept">Accepted</option>
                  <option value="reject">Reject</option>
                </select>
      <button >Submit</button>
      </form>} */}

      <Example
        show={showPopup}
        onHide={() => setShowPopup(!showPopup)}
        component={
          <Applicationpopup
            onHide={() => setShowPopup(!showPopup)}
            setFaqData={setFaqData}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
      <section className="my_applications_single">
        <div className="container">
          <ApplicationidApp />

          <div className="codeul">
            <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Overview"
                  type="button"
                  role="tab"
                  aria-controls="Overview"
                  aria-selected="true"
                >
                  <img
                    src="../../../assets/images/singicon4.png"
                    alt="singicon1"
                  />
                  Overview
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Details"
                  type="button"
                  role="tab"
                  aria-controls="Details"
                  aria-selected="false"
                >
                  <img
                    src="../../../assets/images/singicon2.png"
                    alt="singicon2"
                  />{" "}
                  Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Files-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Files"
                  type="button"
                  role="tab"
                  aria-controls="Files"
                  aria-selected="false"
                >
                  <img
                    src="../../../assets/images/singicon3.png"
                    alt="singicon3"
                  />{" "}
                  Files
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Checklist-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Checklist"
                  type="button"
                  role="tab"
                  aria-controls="Checklist"
                  aria-selected="false"
                >
                  <img
                    src="../../../assets/images/singicon4.png"
                    alt="singicon4"
                  />{" "}
                  Checklist
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <OverviewApp />
            <Detailsapp />
            <FilesApp />
            <ChecklistApp />
          </div>
        </div>
      </section>

      <StudentFooter />
    </>
  );
};

export default SingleApplication;
