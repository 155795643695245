import React, { useContext, useEffect } from "react";
import { SocketContext } from "../../../../Context";
import { becomeConsultantFooter } from "../../../../data/data";
import QuickLink from "./quickLinks";
import ContactUs from "../../Student/BecomeConsultantFooter/contactUs";
import SocialMedia from "../BecomeConsultantFooter/socialMedia";
import SmsBox from "../../../Tools/SmsBox";
import { saveUserNavigate } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getCities,
  getDynamicRoutes,
} from "../../../../Reducer/afterSearchSlice";

const StudentFooter = ({ city }) => {
  const dt = localStorage.getItem("dt");
  const { allChats } = useContext(SocketContext);
  const token = localStorage.getItem("token");
  const guestId = localStorage.getItem("guestId");
  const { auth } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { cities, dynamicRoutes } = useSelector((state) => state.afterSearch);

  useEffect(() => {
    if (city) {
      dispatch(getDynamicRoutes({ city: city }));
    } else {
      dispatch(getCities({}));
    }
  }, []);

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: token ? auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <footer className={`${dt ? "d-none" : ""} main-footer`}>
        <div className="container">
          <div className="row">
            <div className="col-12 imctc">
              {city ? (
                <>
                  {dynamicRoutes && dynamicRoutes.length > 0 && (
                    <p>Popular Cities</p>
                  )}
                  <ul>
                    {dynamicRoutes &&
                      dynamicRoutes.length > 0 &&
                      dynamicRoutes.map((x, i) => {
                        return (
                          <li className="d-inline">
                            <Link
                              to={`/${x.slug.toLowerCase()}/${x.routes.toLowerCase()}/${x.second_route.toLowerCase()}`}
                              onClick={() =>
                                handleUserNavigate(
                                  "navigate",
                                  `/${x.slug}/${x.routes}/${x.second_route}`,
                                  "navigate from footer"
                                )
                              }
                            >{`${x.name}`}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </>
              ) : (
                <>
                  {cities && cities.length > 0 && <p>Popular Cities</p>}
                  <ul>
                    {cities &&
                      cities.length > 0 &&
                      cities.map((x, i) => {
                        return (
                          <li className="d-inline">
                            <Link
                              to={`/${x.slug.toLowerCase()}`}
                              onClick={() =>
                                handleUserNavigate(
                                  "navigate",
                                  `/${x.slug}`,
                                  "navigate from footer"
                                )
                              }
                            >{`Immigration Consultants in ${x.city}`}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </>
              )}
            </div>
            <div className="col-md-6 col-xl-5 col-xxl-6">
              <img
                className="f_logo"
                src={`../.././../assets/images/chownk-logo.png`}
                alt="img"
              />
              <p className="ptext">
                In India, the trend of moving abroad for education, work, or
                permanent residency is growing rapidly. At CHOWNK, we make this
                transition smoother by connecting you directly with the best
                immigration consultants and top IELTS/PTE/TOEFL institutes.
                Whether you're at home, in the office, or anywhere else, our
                immigration platform ensures a convenient and efficient process
                for all your relocation needs. Simplify your journey abroad with
                CHOWNK.
              </p>
            </div>

            <div className="col-md-8 col-xl-7 col-xxl-6">
              <div className="row">
                <QuickLink
                  parentStyle={"col-md-4 col-xl-3"}
                  childStyle={"locul"}
                  heading={"About"}
                  data={becomeConsultantFooter.quick_links}
                  handleUserNavigate={handleUserNavigate}
                />
                <ContactUs
                  parentStyle={"col-md-4 col-xl-3 contact"}
                  childStyle={"locul"}
                  heading={"Help"}
                  data={becomeConsultantFooter.help}
                  handleUserNavigate={handleUserNavigate}
                />
                <QuickLink
                  parentStyle={"col-md-4 col-xl-3"}
                  childStyle={"locul"}
                  heading={"Consumer Policy"}
                  data={becomeConsultantFooter.consumer_policy}
                  handleUserNavigate={handleUserNavigate}
                />
              </div>
            </div>
          </div>
          <SocialMedia
            data={becomeConsultantFooter.social_media}
            handleUserNavigate={handleUserNavigate}
          />
        </div>
      </footer>
      {allChats && allChats.length > 0 && <SmsBox />}
    </>
  );
};
export default React.memo(StudentFooter);