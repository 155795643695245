import React, { useState } from "react";
import { Link } from "react-router-dom";
import ClaimModel from "../../../Layouts/Model/claimModal";
import Example from "../../../Tools/ReactBoostrap";
import ReportModel from "../../../Layouts/Model/reportModel";

const Report = ({ companyId, companyOwner, selector }) => {
  const [claimModal, setClaimModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  return (
    <>
      <div className="row">
        <div className="col-7">
          <Link
            className={`btn ${companyOwner == selector?.uid ? "disabled" : ""}`}
            onClick={() => {
              if (companyOwner !== selector?.uid) {
                setClaimModal(!claimModal);
              }
            }}
          >
            Claim this business
          </Link>
        </div>
        <div
          className="col-5"
          onClick={() => {
            if (companyOwner !== selector?.uid) {
              setReportModal(!reportModal);
            }
          }}
        >
          <Link
            className={`btn rep ${
              companyOwner == selector?.uid ? "disabled" : ""
            } `}
          >
            Report
          </Link>
        </div>
      </div>
      <Example
        show={claimModal}
        onHide={() => setClaimModal(!claimModal)}
        component={
          <ClaimModel
            show={claimModal}
            setShow={setClaimModal}
            id={companyId}
          />
        }
        style={"pop_width CreateOffer Claim_Business"}
      />
      <Example
        show={reportModal}
        onHide={() => setReportModal(!reportModal)}
        component={
          <ReportModel
            reportShow={reportModal}
            id={companyId}
            setReportShow={setReportModal}
            onHide={() => setReportModal(!reportModal)}
          />
        }
        style={"pop_width CreateOffer reports_modal"}
      />
    </>
  );
};

export default Report;
