import React, { useEffect } from "react";
import MobileHeader from "./MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { getTopFourBlogs } from "../../../../Reducer/blogSlice";
import MobileLocation from "./location";

import $ from "jquery";
import Refund from "./refund";

const MobileHomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("dt")) {
      $("body").addClass("mobscreen");
    }
    dispatch(getTopFourBlogs());
  }, []);
  return (
    <div>
      <MobileHeader />
      <MobileLocation />
      {/* <LiveConsultant />
      <PopularInstitutes />
      <NewAndPopular /> */}
      <Refund />
    </div>
  );
};

export default MobileHomePage;
