import { useContext } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { SocketContext } from "../../../Context";

const Button = ({
  title,
  errors,
  style,
  id,
  inType,
  onClick,
  dataToggale,
  inlineStyle,
  disabled,
  dataTarget,
  dataSlide,
  ariaLabel,
  ariaCurrent,
  icon,
  showTimer,
}) => {
  const { isPlaying, setIsPlaying } = useContext(SocketContext);
  return (
    <button
      id={id}
      type={inType}
      data-bs-target={dataTarget}
      data-bs-slide-to={dataSlide}
      data-bs-toggle={dataToggale}
      aria-current={ariaCurrent}
      aria-label={ariaLabel}
      className={style}
      style={inlineStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && icon} {title}
      {showTimer && (
        <CountdownCircleTimer
          isPlaying={isPlaying}
          duration={10}
          colors="#A30000"
          size={36}
          initialRemainingTime={10}
          strokeWidth={4}
          trailStrokeWidth={4}
          children={({ remainingTime }) => {
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;
            return `${seconds}`;
          }}
          onComplete={() => {
            setIsPlaying(false);
            return { shouldRepeat: false, delay: 1.5 };
          }}
        />
      )}
    </button>
  );
};
export default Button;
