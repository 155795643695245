import React from "react";
import HelpHead from "./helpHead";
import StudentHeader from "../../../Layouts/Student/Header";
import HelpTabs from "./tabs";
import HelpBox from "../../../Tools/HelpBox";
import CategoryBox from "./categoryBox";
import NewHelpBox from "./newHelpBox";

const NewHelp = ({ helpData, user, tabHandle, handleUserNavigate }) => {
  const openHelpBox = () => {
    handleUserNavigate("action", "Need more assistance", "Need Help");
    if (window.fcWidget) {
      window.fcWidget.open();
    }
  };

  return (
    <>
      <section class="help_page">
        <div class="container">
          <HelpTabs user={user} tabHandle={tabHandle} />
          <h2 class="text-center">GET IN TOUCH WITH THE EXPERTS</h2>
          <div class="tab-content mt-3 mt-xl-4 mt-xxl-5" id="myTabContent">
            <div
              class="tab-pane fade active show"
              id="Customers"
              role="tabpanel"
              aria-labelledby="Customers-tab"
            >
              <div class="row">
                {helpData.length > 0 &&
                  helpData.map((el, i) => (
                    <CategoryBox
                      parentStyle={"col-sm-6 col-lg-4 mb-3"}
                      childStyle={"set"}
                      name={el.name}
                      image={`${
                        user === "customers"
                          ? `../../../assets/images/helpiconK_${i + 1}.png`
                          : `../../../assets/images/partnericonK_${i + 1}.png`
                      }`}
                      help_questions={el.help_questions}
                      slug={el.slug}
                      type={user}
                      ques_slug={el.question_slug}
                      handleUserNavigate={handleUserNavigate}
                    />
                  ))}
              </div>
              <div className="row">
                <NewHelpBox
                  parentStyle={"col-sm-6 col-lg-4 mb-3"}
                  childStyle={"set contactus"}
                  image={"../../assets/images/help_desk.png"}
                  heading={"Do you need additional assistance?"}
                  subHeading={"Our friendly support team is here to help."}
                  openHelpBox={openHelpBox}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewHelp;
