import React, { useEffect } from "react";

const VerificationTabs = ({ currentStep, setCurrentStep, user }) => {
  useEffect(() => {
    const elem = document.querySelector(".active");
    elem.scrollIntoView({ behavior: "instant", inline: "center" });
  }, [currentStep]);

  return (
    <div className="ulstap">
      <ul className="navi d-flex justify-content-center">
        <li
          className={`step ${currentStep === 1 && "active"} ${
            user?.pan_status === "Approved" ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(1)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon1.png" alt="icon" />
            <img
              src="../../assets/images/stapicon1blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon1white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 1</span>
          <i
            className={`bi bi-check-circle-fill ${
              user?.pan_status === "Approved" ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 2 && "active"} ${
            user.authorize_certificate_status === "Approved" ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(2)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon2.png" alt="icon" />
            <img
              src="../../assets/images/stapicon2blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon2white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 2</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.authorize_certificate_status === "Approved" ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 3 && "active"} ${
            user.company_logo ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(3)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon3.png" alt="icon" />
            <img
              src="../../assets/images/stapicon3blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon3white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 3</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.company_logo ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 4 && "active"} ${
            user.banner_image ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(4)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon4.png" alt="icon" />
            <img
              src="../../assets/images/stapicon4blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon4white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 4</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.banner_image ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 5 && "active"} ${
            user.specialization && user.specialization.split(",").length > 4
              ? "finish"
              : ""
          }`}
          onClick={() => setCurrentStep(5)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon5.png" alt="icon" />
            <img
              src="../../assets/images/stapicon5blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon5white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 5</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.specialization && user.specialization.split(",")?.length > 4
                ? ""
                : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 6 && "active"} ${
            user.overview_description ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(6)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon6.png" alt="icon" />
            <img
              src="../../assets/images/stapicon6blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon6white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 6</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.overview_description ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 7 && "active"} ${
            user.stories && JSON.parse(user.stories).length > 2 ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(7)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon7.png" alt="icon" />
            <img
              src="../../assets/images/stapicon7blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon7white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>
          <span className="text">Step 7</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.stories && JSON.parse(user.stories).length > 2
                ? ""
                : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 8 && "active"} ${
            user.faq && JSON.parse(user?.faq)?.length > 2 ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(8)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon8.png" alt="icon" />
            <img
              src="../../assets/images/stapicon8blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon8white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 8</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.faq && JSON.parse(user?.faq)?.length > 2 ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 9 && "active"} ${
            user?.achievements ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(9)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon9.png" alt="icon" />
            <img
              src="../../assets/images/stapicon9blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon9white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 9</span>
          <i
            className={`bi bi-check-circle-fill ${
              user?.achievements ? "" : "d-none"
            }`}
          ></i>
        </li>
        <li
          className={`step ${currentStep === 10 && "active"} ${
            user.tagline ? "finish" : ""
          }`}
          onClick={() => setCurrentStep(10)}
        >
          <span className="img">
            <img src="../../assets/images/stapicon10.png" alt="icon" />
            <img
              src="../../assets/images/stapicon10blue.svg"
              className="blue d-none"
              alt="icon"
            />
            <img
              src="../../assets/images/stapicon10white.svg"
              className="white d-none"
              alt="icon"
            />
          </span>{" "}
          <span className="text">Step 10</span>
          <i
            className={`bi bi-check-circle-fill ${
              user.tagline ? "" : "d-none"
            }`}
          ></i>
        </li>
      </ul>
    </div>
  );
};

export default VerificationTabs;
