import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RatingFilter = ({ filterData, setItem, selectedItems, navpath }) => {
  const [ratingfilters, setRatingFilters] = useState("");
  const [filterValue, setFilterValue] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const rattingg = queryParams.get("ratting[]");

  const ratingNavigationHandle = useCallback(() => {
    if (selectedItems.rating && selectedItems.rating.length === 0) {
      setRatingFilters([]);
      setFilterValue([]);
    }
    if (selectedItems.rating.length > 0) {
      setFilterValue([...selectedItems.rating]);
      selectedItems && setRatingFilters(selectedItems.rating);
    }
  }, [selectedItems]);

  useEffect(() => {
    ratingNavigationHandle();
  }, [ratingNavigationHandle, navigate]);

  useEffect(() => {
    setItem((prev) => ({
      ...prev,
      rating: rattingg ? [...rattingg.split(",")] : [],
    }));
  }, [rattingg, setItem]);

  const handleRatingFilter = (e) => {
    setRatingFilters([e.target.value]);
    setItem &&
      setItem((prev) => ({
        ...prev,
        rating: [e.target.value],
      }));
  };

  const handleSubmit = () => {
    if (filterValue.length > 0 || ratingfilters !== "") {
      queryParams.set("ratting[]", ratingfilters);
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
      setItem &&
        setItem((prev) => ({
          ...prev,
          rating: ratingfilters,
        }));
    } else {
      queryParams.delete("ratting[]");
      setItem &&
        setItem((prev) => ({
          ...prev,
          rating: [],
        }));
      setFilterValue([]);
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
    }
  };

  const handleClear = () => {
    queryParams.delete("ratting[]");
    setItem &&
      setItem((prev) => ({
        ...prev,
        rating: [],
      }));
    setFilterValue([]);
    const newSearchString = queryParams.toString();
    navigate({ pathname: navpath, search: newSearchString });
  };

  // const checkboxHandle = (index) => {
  //   if (filterValue.length > 0) {
  //     if (filterValue.includes(index)) {
  //       setFilterValue(filterValue.filter((el) => el !== index));
  //       setItem((prev) => ({
  //         ...prev,
  //         rating: selectedItems.rating.filter((el) => el !== index),
  //       }));
  //     } else {
  //       setFilterValue([...filterValue, index]);
  //     }
  //   } else {
  //     //console.log("checkBbbsbsbs", ...filterValue);
  //     setFilterValue([...filterValue, index]);
  //   }
  // };
  return (
    <li className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="../../assets/images/dp4.png" alt="img" />
        <img
          src="../../assets/images/apdp4W.svg"
          alt="img"
          className="white_img"
        />
        Ratings
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <ul className="Visa">
          {filterData.map((item, i) => (
            <li key={i}>
              <input
                type="checkbox"
                name="ratting"
                value={item.value}
                checked={ratingfilters[0] == item.value}
                // onClick={() => checkboxHandle(item.value)}
                onChange={handleRatingFilter}
              />
              <i className="bi bi-star-fill"></i>
              {item.label}
            </li>
          ))}
        </ul>
        <div className="d-none d-md-block">
          <div className="row">
            <div className="col-6">
              <button className="btn" onClick={handleClear}>
                Clear all
              </button>
            </div>
            <div className="col-6">
              <button className="btn apply" onClick={handleSubmit}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </ul>
    </li>
  );
};

export default RatingFilter;
