import React from "react";
import SimpleInput from "../../../Tools/SimpleInput";

const HelpHead = ({
  searchData,
  searchFunction,
  setSearchData,
  searchSubmit,
  handleUserNavigate,
  user,
}) => {
  return (
    <section class="help_banner text-center">
      <div class="container">
        <h1>
          {user === "partners"
            ? "Partners Help & Support"
            : "Customers Help & Support"}
        </h1>
        <p>Search our knowledge base or browse categories below.</p>
        <form onSubmit={(e) => searchSubmit(e)}>
          <SimpleInput
            inType={"text"}
            placeholder={"Search any Text or Keyword"}
            name={"search"}
            onChange={(e) => {
              if (e.target.value === "") {
                searchFunction(e);
              }
              setSearchData(e.target.value);
              !e.target.value && searchFunction(e);
            }}
            value={searchData}
          />
          <button type="submit">Search</button>
        </form>
      </div>
    </section>
  );
};

export default HelpHead;
