export const agentTrainingVideos = [
  {
    category: "All",
    videos: [
      {
        url: "https://youtube.com/embed/0ZUHG2dMh24?feature=share",
        title: "Why It Is Important to Complete Your Profile 100%",
        description: "Learn why it is important to complete your profile 100%.",
      },
      {
        url: "https://youtube.com/embed/MZs9Sw33Fl4?feature=share",
        title: "Why it is important to install Chownk Mobile app",
        description:
          "Discover why it's important to install the CHOWNK mobile app",
      },
      {
        url: "https://youtube.com/embed/EZeNq05BvZA?feature=share",
        title: "What should I do after my profile is 100% completed?",
        description:
          "Discover what steps to take next after completing your profile to 100%.",
      },
      {
        url: "https://youtube.com/embed/hDfYAKXoNsc?feature=share",
        title: "What does OFFER mean and how does it help you?",
        description:
          "Find out what an offer means and how it can help you in your visa application process.",
      },
      {
        url: "https://youtube.com/embed/GZi-XRevgYA?feature=share",
        title: "Can I send multiple offers to one customer?",
        description:
          "Learn if you can send multiple offers to one customer and how it works.",
      },
      {
        url: "https://youtube.com/embed/VRNF690XiGw?feature=share",
        title: "Accepted or rejected offer, what does that mean?",
        description:
          "Learn what it means when an offer is accepted or rejected and how it impacts your next steps.",
      },
      {
        url: "https://youtube.com/embed/HpUYg-fJjaU?feature=share",
        title: "When do I start working on a Customer Visa application?",
        description:
          "Find out when you should start working on your Customer Visa application to make the process easy and stress-free.",
      },
      {
        url: "https://youtube.com/embed/NaX2zNjoKdo?feature=share",
        title:
          "Do I need to pay any fees for accepting a call or chatting with a customer?",
        description:
          "Discover whether you need to pay any fees for accepting a call or chatting with a customer.",
      },
      {
        url: "https://youtube.com/embed/Rn0jOK18XoI?feature=share",
        title: "Do I need to pay any fees to keep my profile active?",
        description:
          "Find out if there are any fees to keep your profile active.",
      },
      {
        url: "https://youtube.com/embed/Ng5op4glgcc?feature=share",
        title: "Do I need to pay any fees for sending an offer?",
        description:
          "Discover if there are any fees associated with sending an offer.",
      },
      {
        url: "https://youtube.com/embed/YrST9HBEN5w?feature=share",
        title:
          "How much fees to be paid once an offer is accepted by the customer?",
        description:
          "Find out how much fees need to be paid once an offer is accepted by the customer.",
      },
      {
        url: "https://youtube.com/embed/jycu4fmrmEg?feature=share",
        title: "Will all calls recorded on CHOWNK",
        description:
          "Find out if all calls on CHOWNK are recorded to ensure a  reliable experience for users.",
      },
      {
        url: "https://youtube.com/embed/6FQmIGCF28U?feature=share",
        title: "Importance of Verifying KYC",
        description: "Learn why it is essential to complete your KYC.",
      },
      {
        url: "https://youtube.com/embed/7mdAZhiEVDo?feature=share",
        title: "How to Update Profile Banner",
        description: "Learn how to add or update profile banner",
      },
      {
        url: "https://youtube.com/embed/LJiW58qkRUs?feature=share",
        title: "How to Update Profile Image",
        description: "Learn how to add or update profile image",
      },
      {
        url: "https://youtube.com/embed/R9J17CElQt8",
        title: "How to Add / Update Success Stories",
        description:
          "Learn how to add or update success stories to highlight inspiring client achievements",
      },
      {
        url: "https://youtube.com/embed/3F-NWOmK92Y",
        title: "How to Add / Update FAQs",
        description:
          "Learn how to add or update FAQs effortlessly to enhance user experience",
      },
      {
        url: "https://youtube.com/embed/39Wbx-NR_TI?feature=share",
        title: "How to Add / Update About Us",
        description:
          "Discover how to add or update your About Us section to showcase your story effectively.",
      },
      {
        url: "https://youtube.com/embed/xYsek5FYk74?feature=share",
        title: "How to Add / Update Achievements",
        description:
          "Discover how to add or update achievements to showcase your accomplishments",
      },
      {
        url: "https://youtube.com/embed/uGUxwXFqLCg?feature=share",
        title: "How to add/update Specializations",
        description:
          "Effortlessly add or update your specializations to highlight your expertise",
      },
      {
        url: "https://youtube.com/embed/C6LM2a-5P54?feature=share",
        title: "Is it compulsory to upload PAN/GST Proof or Business Document",
        description:
          "Get to know about the necessity of uploading all KYC documents.",
      },
      {
        url: "https://youtube.com/embed/ZyWlE6W9at0?feature=share",
        title: "What happens if my KYC is Rejected",
        description: "Learn what to do if your KYC is rejected.",
      },
      {
        url: "https://youtube.com/embed/bn16nIuSFYc?feature=share",
        title: "Will my KYC documents  is safe within the platform?",
        description:
          "Learn if your KYC documents are safe within the platform.",
      },
      {
        url: "https://youtube.com/embed/xHsaJB1u6tM?feature=share",
        title: "Source of Earnings for Partners",
        description: "Discover How you can earn through CHOWNK",
      },
      {
        url: "https://youtube.com/embed/V0kIEb_PiBA?feature=share",
        title: "Will Sharing personal details allowed on Chownk?",
        description:
          "Find out if sharing personal details is permitted on CHOWNK.",
      },
      {
        url: "https://youtube.com/embed/ggFjDBdx3gk?feature=share",
        title: "What if i haven't Completed Profile",
        description: "",
      },
    ],
    primamry_order: 1,
    secondary_order: 1,
  },
  {
    category: "KYC",
    videos: [
      {
        url: "https://youtube.com/embed/6FQmIGCF28U?feature=share",
        title: "Importance of Verifying KYC",
        description: "Learn why it is essential to complete your KYC.",
      },
      {
        url: "https://youtube.com/embed/C6LM2a-5P54?feature=share",
        title: "Is it compulsory to upload PAN/GST Proof or Business Document",
        description:
          "Get to know about the necessity of uploading all KYC documents.",
      },
      {
        url: "https://youtube.com/embed/ZyWlE6W9at0?feature=share",
        title: "What happens if my KYC is Rejected",
        description: "Learn what to do if your KYC is rejected.",
      },
      {
        url: "https://youtube.com/embed/bn16nIuSFYc?feature=share",
        title: "Will my KYC documents  is safe within the platform?",
        description:
          "Learn if your KYC documents are safe within the platform.",
      },
    ],
    primamry_order: 2,
    secondary_order: 10,
  },
  {
    category: "Fees and Charges",
    videos: [
      {
        url: "https://youtube.com/embed/NaX2zNjoKdo?feature=share",
        title:
          "Do I need to pay any fees for accepting a call or chatting with a customer?",
        description:
          "Discover whether you need to pay any fees for accepting a call or chatting with a customer.",
      },
      {
        url: "https://youtube.com/embed/Rn0jOK18XoI?feature=share",
        title: "Do I need to pay any fees to keep my profile active?",
        description:
          "Find out if there are any fees to keep your profile active.",
      },
      {
        url: "https://youtube.com/embed/Ng5op4glgcc?feature=share",
        title: "Do I need to pay any fees for sending an offer?",
        description:
          "Discover if there are any fees associated with sending an offer.",
      },
      {
        url: "https://youtube.com/embed/YrST9HBEN5w?feature=share",
        title:
          "How much fees to be paid once an offer is accepted by the customer?",
        description:
          "Find out how much fees need to be paid once an offer is accepted by the customer.",
      },
    ],
    primamry_order: 3,
    secondary_order: 2,
  },
  {
    category: "Managing Offers",
    videos: [
      {
        url: "https://youtube.com/embed/hDfYAKXoNsc?feature=share",
        title: "What does OFFER mean and how does it help you?",
        description:
          "Find out what an offer means and how it can help you in your visa application process.",
      },
      {
        url: "https://youtube.com/embed/GZi-XRevgYA?feature=share",
        title: "Can I send multiple offers to one customer?",
        description:
          "Learn if you can send multiple offers to one customer and how it works.",
      },
      {
        url: "https://youtube.com/embed/VRNF690XiGw?feature=share",
        title: "Accepted or rejected offer, what does that mean?",
        description:
          "Learn what it means when an offer is accepted or rejected and how it impacts your next steps.",
      },
      {
        url: "https://youtube.com/embed/HpUYg-fJjaU?feature=share",
        title: "When do I start working on a Customer Visa application?",
        description:
          "Find out when you should start working on your Customer Visa application to make the process easy and stress-free.",
      },
    ],
    primamry_order: 4,
    secondary_order: 3,
  },
  {
    category: "Managing Applications",
    videos: [
      {
        url: "https://www.youtube.com/embed/TKLRHpKWWzw?si=3PhPvj_k-PZ1Rk4G",
        title: "Lorem Ipsum is simply dummy text",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        url: "https://www.youtube.com/embed/TKLRHpKWWzw?si=3PhPvj_k-PZ1Rk4G",
        title: "Lorem Ipsum is simply dummy text",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
    primamry_order: 5,
    secondary_order: 4,
  },
  {
    category: "Tips to Improve Profile",
    videos: [
      {
        url: "https://youtube.com/embed/0ZUHG2dMh24?feature=share",
        title: "Why It Is Important to Complete Your Profile 100%",
        description: "Learn why it is important to complete your profile 100%.",
      },
      {
        url: "https://youtube.com/embed/MZs9Sw33Fl4?feature=share",
        title: "Why it is important to install Chownk Mobile app",
        description:
          "Discover why it's important to install the CHOWNK mobile app",
      },
      {
        url: "https://youtube.com/embed/EZeNq05BvZA?feature=share",
        title: "What should I do after my profile is 100% completed?",
        description:
          "Discover what steps to take next after completing your profile to 100%.",
      },
      {
        url: "https://youtube.com/embed/7mdAZhiEVDo?feature=share",
        title: "How to Update Profile Banner",
        description: "Learn how to add or update profile banner",
      },
      {
        url: "https://youtube.com/embed/LJiW58qkRUs?feature=share",
        title: "How to Update Profile Image",
        description: "Learn how to add or update profile image",
      },
      {
        url: "https://youtube.com/embed/R9J17CElQt8",
        title: "How to Add / Update Success Stories",
        description:
          "Learn how to add or update success stories to highlight inspiring client achievements",
      },
      {
        url: "https://youtube.com/embed/3F-NWOmK92Y",
        title: "How to Add / Update FAQs",
        description:
          "Learn how to add or update FAQs effortlessly to enhance user experience",
      },
      {
        url: "https://youtube.com/embed/39Wbx-NR_TI?feature=share",
        title: "How to Add / Update About Us",
        description:
          "Discover how to add or update your About Us section to showcase your story effectively.",
      },
      {
        url: "https://youtube.com/embed/xYsek5FYk74?feature=share",
        title: "How to Add / Update Achievements",
        description:
          "Discover how to add or update achievements to showcase your accomplishments",
      },
      {
        url: "https://youtube.com/embed/uGUxwXFqLCg?feature=share",
        title: "How to add/update Specializations",
        description:
          "Effortlessly add or update your specializations to highlight your expertise",
      },
      {
        url: "https://youtube.com/embed/ggFjDBdx3gk?feature=share",
        title: "What if i haven't Completed Profile",
        description: "",
      },
    ],
    primamry_order: 6,
    secondary_order: 5,
  },
  {
    category: "Earning",
    videos: [
      {
        url: "https://youtube.com/embed/xHsaJB1u6tM?feature=share",
        title: "Source of Earnings for Partners",
        description: "Discover How you can earn through CHOWNK",
      },
    ],
    primamry_order: 7,
    secondary_order: 6,
  },
  {
    category: "Policies",
    videos: [
      {
        url: "https://youtube.com/embed/V0kIEb_PiBA?feature=share",
        title: "Will Sharing personal details allowed on Chownk?",
        description:
          "Find out if sharing personal details is permitted on CHOWNK.",
      },
    ],
    primamry_order: 8,
    secondary_order: 7,
  },
  {
    category: "Call/Chat Tutorials",
    videos: [
      {
        url: "https://youtube.com/embed/jycu4fmrmEg?feature=share",
        title: "Will all calls recorded on CHOWNK",
        description:
          "Find out if all calls on CHOWNK are recorded to ensure a  reliable experience for users.",
      },
    ],
    primamry_order: 9,
    secondary_order: 8,
  },
];
