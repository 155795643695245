import React from "react";

const Refund = () => {
  return (
    <div className="refund">
      <div className="container">
        <div className="set">
          <img src="../assets/images/refundimg.svg" />
          <h4>Claim 100% Refund</h4>
          <p>On service fee and Consultation fee.</p>
        </div>
      </div>
    </div>
  );
};

export default Refund;
