import React from "react";
import { GrView } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../Tools/Button";
import {
  bussinessAccountDetailFun,
  kycVerificationFun,
  setKycVerificationSuccess,
} from "../../../../Reducer/authSlice";
import { mediaUrl } from "../../../../services/network";
import InputImageBox from "../../../Tools/InputImageBox";
import { useLocation, useNavigate } from "react-router-dom";

const KycDocumentSection = ({ active, user }) => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    // resolver: yupResolver(kycProcessSchema),
  });

  const selector = useSelector((state) => state.auth);
  const numberAphabet = /^[A-Za-z0-9]*$/;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (selector.kycVerificationSuccess) {
    dispatch(bussinessAccountDetailFun());
  }

  React.useEffect(() => {
    setValue("authorize_expiery", selector.auth?.authorize_expiery);
    setValue("pan_number", selector.auth?.pan_number);
    setValue("gst_number", selector.auth?.gst_number);

    if (
      selector.auth &&
      selector.auth.authorize_certificate_status === "Rejected"
    ) {
      setError("authorize_certificate", {
        type: "custom",
        message: selector.auth.authorize_certificate_comment,
      });
    } else {
      clearErrors("authorize_certificate");
    }
    if (selector.auth && selector.auth.pan_status === "Rejected") {
      setError("pan_card", {
        type: "custom",
        message: selector.auth.pan_comment,
      });
    } else {
      clearErrors("pan_card");
    }
    if (selector.auth && selector.auth.gst_status === "Rejected") {
      setError("gst_certificate", {
        type: "custom",
        message: selector.auth.gst_comment,
      });
    } else {
      clearErrors("gst_certificate");
    }

    if (selector.kycVerificationSuccess) {
      // dispatch(bussinessAccountDetailFun());
      toast.success(selector.kycVerificationMessage, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
    if (selector.profileUpdateSuccess) {
      dispatch(setKycVerificationSuccess(false));
      // window.location.reload();
    }
  }, [selector.kycVerificationSuccess, selector.auth]);

  const onSubmit = (data) => {
    if (
      (data.pan_number && data.pan_card && data.pan_card[0]) ||
      (data.gst_number && data.gst_certificate && data.gst_certificate[0]) ||
      (data.authorize_certificate && data.authorize_certificate[0])
    ) {
      // console.log("Required One Document 1");
      let formData = new FormData();

      formData.append(
        "gst_status",
        data.gst_certificate && data.gst_certificate[0]
          ? "Hold"
          : selector.auth?.gst_status
      );
      formData.append(
        "pan_status",
        data.pan_card && data.pan_card[0] ? "Hold" : selector.auth?.pan_status
      );
      formData.append(
        "authorize_certificate_status",
        data.authorize_certificate && data.authorize_certificate[0]
          ? "Hold"
          : selector.auth?.authorize_certificate_status
      );
      if (data.authorize_expiery) {
        formData.append("authorize_expiery", data.authorize_expiery);
      }
      if (data.pan_card && data.pan_card[0]) {
        clearErrors("pan_card");
        formData.append("pan_card", data.pan_card[0]);
      }
      //  else {
      //   setError("pan_card", {
      //     type: "custom",
      //     message: "Pan Card image is required",
      //   });
      // }
      if (data.gst_certificate && data.gst_certificate[0]) {
        // clearErrors("gst_certificate");
        formData.append("gst_certificate", data.gst_certificate[0]);
      }
      // else {
      //   setError("gst_certificate", {
      //     type: "custom",
      //     message: "GST certificate image is required",
      //   });
      // }
      if (data.authorize_certificate && data.authorize_certificate[0]) {
        clearErrors("authorize_certificate");
        formData.append("authorize_certificate", data.authorize_certificate[0]);
      }
      //  else {
      //   setError("authorize_certificate", {
      //     type: "custom",
      //     message: "Authorize Certificate image is required",
      //   });
      // }
      if (data.pan_number) {
        formData.append("pan_number", data.pan_number);
      }
      // if (data.gst_number) {
        formData.append("gst_number", "");
      // }
      dispatch(kycVerificationFun(formData));
      navigate(location.pathname, { replace: true });
    } else {
      // console.log("Required One Document");
      toast.error(
        "Kindly submit at least one document to fully activate your profile on Chownk.",
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );
    }
  };
  // console.log("errors", errors);
  const panNumberHandle = (e) => {
    if (numberAphabet.test(e.target.value)) {
      if (e.target.value.length == 10 || e.target.value.length==15) {
        clearErrors("pan_number");
      } else {
        setError("pan_number", {
          type: "custom",
          message: "Invalid Identification Number. Please correct and resubmit.",
        });
      }
    } else {
      setError("pan_number", {
        type: "custom",
        message: "Not Use Special Character In Document",
      });
    }
  };

  // const gstNumberHandle = (e) => {
  //   if (numberAphabet.test(e.target.value)) {
  //     if (e.target.value.length !==15) {
  //       setError("gst_number", {
  //         type: "custom",
  //         message: "Invalid GST Number provided. Please correct and resubmit.",
  //       });
  //     } else {
  //       clearErrors("gst_number");
  //     }
  //   } else {
  //     setError("gst_number", {
  //       type: "custom",
  //       message: "Not Use Special Character In Pan Number",
  //     });
  //   }
  // };

  const handleImage = (e) => {
    clearErrors("authorize_certificate");
    clearErrors("gst_certificate");
    clearErrors("pan_card");
    setValue(e.target.name, e.target.files);
    clearErrors(e.target.name);
  };
  return (
    <div
      className={`tab-pane fade ${active}`}
      id="Settingstab4-tab-pane"
      role="tabpanel"
      aria-labelledby="Settingstab4-tab"
      tabIndex="0"
    >
      <p>
        To complete the activation of your profile on Chownk, it is essential
        that you submit at least <b>One</b> document. Your cooperation in this
        matter ensures the full functionality and verification of your account
        within the platform.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="no_input">
        {/* <RowInputWithImage
          parentStyle={"row mt-5"}
          labelStyle={"col-md-5"}
          label={"PAN Number"}
          labelDescription={
            "Upload your company logo and then choose where you want it to display."
          }
          childStyle={"col-md-7"}
          inType={"text"}
          event={register}
          placeholder={"PAN number"}
          fileName={"pan_card"}
          errors={errors}
          inputName={"pan_number"}
          image={selector.auth && mediaUrl + selector.auth.pan_card}
          fileStatus={selector.auth && selector.auth.pan_status}
          inputStyle={{ maxWidth: "350px" }}
        /> */}
        <InputImageBox
          label={"PAN Number/GST Proof"}
          labelDescription={
            "Please provide the proof of your GST (Goods and Services Tax) OR PAN (Permanent Account Number) registration or details to validate your compliance and credentials within the specified parameters of our system."
          }
          inputType={"text"}
          inputName={"pan_number"}
          fileName={"pan_card"}
          event={register}
          errors={errors}
          image={selector.auth.pan_card}
          imageIcon={{ icon: <GrView />, title: "View" }}
          inputPlaceholder={"PAN number"}
          fileStatus={selector.auth && selector.auth.pan_status}
          textOnChange={panNumberHandle}
          fileOnChange={handleImage}
          parentStyle={`row mt-4 ${
            location &&
            location.state &&
            location.state.overlay &&
            location.state.overlay == "pan"
              ? "highlightbtn"
              : ""
          }`}
          // textOnChange={(e) => {
          //   if (e.target.value.length == 10) {
          //     clearErrors("pan_number");
          //   }
          // }}
          // fileOnChange={(e) => clearErrors("pan_card")}
        />

        {/* <RowInputWithImage
          parentStyle={"row mt-5"}
          labelStyle={"col-md-5"}
          label={"Business Proof"}
          labelDescription={
            "Upload your company logo and then choose where you want it to display."
          }
          childStyle={"col-md-7"}
          inputName={"authorize_expiery"}
          inType={"date"}
          event={register}
          fileName={"authorize_certificate"}
          errors={errors}
          placeholder={"Enter expiry date"}
          image={
            selector.auth && mediaUrl + selector.auth.authorize_certificate
          }
          fileStatus={
            selector.auth && selector.auth.authorize_certificate_status
          }
          inputStyle={{ maxWidth: "350px" }}
          min={new Date().toISOString().split("T")[0]}
        /> */}

        <InputImageBox
          label={"Business Proof"}
          labelDescription={
            "Please provide documentation that serves as proof of your business operations."
          }
          // inputType={"date"}
          inputName={"authorize_expiery"}
          fileName={"authorize_certificate"}
          event={register}
          errors={errors}
          placeholder={"Enter expiry date"}
          imageIcon={{ icon: <GrView />, title: "View" }}
          image={selector.auth.authorize_certificate}
          fileStatus={
            selector.auth && selector.auth.authorize_certificate_status
          }
          min={new Date().toISOString().split("T")[0]}
          msgClass={"rej2 undrev"}
          parentStyle={`row mt-4 ${
            location &&
            location.state &&
            location.state.overlay &&
            location.state.overlay == "cert"
              ? "highlightbtn"
              : ""
          }`}
          // textOnChange={(e) => {
          //   if (e.target.value.length == 10) {
          //     clearErrors("pan_number");
          //   }
          // }}
          // fileOnChange={(e) => clearErrors("pan_card")}
          fileOnChange={handleImage}
        />

        {/* <RowInputWithImage
          parentStyle={"row mt-5"}
          labelStyle={"col-md-5"}
          label={"GST Proof"}
          labelDescription={
            "Upload your company logo and then choose where you want it to display."
          }
          childStyle={"col-md-7"}
          inType={"text"}
          event={register}
          inputName={"gst_number"}
          fileName={"gst_certificate"}
          errors={errors}
          placeholder={"GST number"}
          image={selector.auth && mediaUrl + selector.auth.gst_certificate}
          fileStatus={selector.auth && selector.auth.gst_status}
          inputStyle={{ maxWidth: "350px" }}
        /> */}

        {/* <InputImageBox
          label={"GST Proof"}
          labelDescription={
            "Please provide the proof of your GST (Goods and Services Tax) registration or details to validate your compliance and credentials within the specified parameters of our system."
          }
          inputType={"text"}
          inputName={"gst_number"}
          fileName={"gst_certificate"}
          event={register}
          errors={errors}
          inputPlaceholder={"GST number"}
          imageIcon={{ icon: <GrView />, title: "View" }}
          fileStatus={selector.auth && selector.auth.gst_status}
          image={selector.auth.gst_certificate}
          textOnChange={gstNumberHandle}
          fileOnChange={handleImage}
          parentStyle={`row mt-4 ${
            location &&
            location.state &&
            location.state.overlay &&
            location.state.overlay == "gst"
              ? "highlightbtn"
              : ""
          }`}
        /> */}

        <div className="text-end kycbtn mt-5">
          {selector.auth &&
            (selector.auth.pan_status === "Pending" ||
              selector.auth.pan_status === "Rejected" ||
              selector.auth.authorize_certificate_status == "Pending" ||
              selector.auth.authorize_certificate_status === "Rejected" ||
              selector.auth.gst_status === "Pending" ||
              selector.auth.gst_status === "Rejected") && (
              <Button
                inType={"submit"}
                style={`${
                  location &&
                  location.state &&
                  location.state.overlay &&
                  "highlightbtn"
                }`}
                title={"Submit Document for Verification"}
              />
            )}
        </div>
      </form>
    </div>
  );
};

export default KycDocumentSection;
