import React from "react";

const LandingHeader = ({ heading }) => {
  return (
    <section className="landing_banner">
      <div className="container">
        <h1 className="mt-0">{heading}</h1>
      </div>
    </section>
  );
};
export default LandingHeader;
