import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { homeStaffMemberColumns } from "../../../../data/data";
import SwitchCheckbox from "../../../Tools/SwitchCheckbox";
import Branches from "./branches";
import CallHistory from "./callHistory";
import Chart from "./charts";
import LearnGetStarted from "./learnGetStarted";
import ProfileStatus from "./profileStatus";
import StaffMemberTable from "./staffMemberTable";
import TodaySales from "./todaySales";
import AgentStatus from "../../../Tools/AgentStatus";
import {
  ProfileViews,
  bussinessAccountDetailFun,
  fetChCallHistory,
  getActiveBoosts,
  getdashBoardGraphs,
} from "../../../../Reducer/authSlice";
import { mediaUrl } from "../../../../services/network";
import Meta from "../../Common/Meta";
import StudentHeader from "../../../Layouts/Student/Header";
import Example from "../../../Tools/ReactBoostrap";
import CommonPopUp from "../../../Layouts/Model/commonPopUp";
import {
  getBranchFun,
  getStaffFun,
} from "../../../../Reducer/accountSettingSlice";
import LeaveCompany from "./leaveCompany";
import Boost from "../../../Tools/Boost";
import { welcomeApi } from "../../../../services/api";

const LastestDashboard = () => {
  const [show, setShow] = React.useState(false);
  const [showApprovedStatus, setApprovedStatus] = useState(true);
  const [showWel, setShowWel] = useState(
    localStorage.getItem("welcomeMsg") ? false : true
  );
  const selector = useSelector((state) => state.auth);
  const accountselector = useSelector((state) => state.accountSetting);
  const dispatch = useDispatch();
  const dt = localStorage.getItem("dt");
  const [online, setOnline] = useState(
    selector && selector.auth && selector.auth.online
  );
  const [boostModal, setBoostModal] = useState(false);
  const showStatus = localStorage.getItem("showApprovedStatus");
  React.useEffect(() => {
    if (selector && selector.auth && selector.auth.role === 5) {
      dispatch(bussinessAccountDetailFun());
      dispatch(getBranchFun());
      dispatch(getStaffFun());
      dispatch(getActiveBoosts());
      dispatch(ProfileViews());
      dispatch(fetChCallHistory({ page: 1, pageSize: 10 }));
      dispatch(getdashBoardGraphs());
    }
    if (selector && selector.auth && selector.auth.role === 6) {
      dispatch(fetChCallHistory({ page: 1, pageSize: 10 }));
    }
  }, [online]);

  const welcomeMsg = async () => {
    try {
      const body = { type: "wellcome" };
      const response = await welcomeApi(body);
    } catch (error) {}
  };

  useEffect(() => {
    if (showStatus) {
      setApprovedStatus(false);
    }
  }, [showApprovedStatus]);

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("welcomeMsg", true);
      setShowWel(false);
      welcomeMsg();
    }, 10000);
  }, []);

  return (
    <>
      <Meta title={"Partner Dashboard : Chownk"} />
      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />
      <Meta title={"Partner Dashboard : Chownk"} />
      <section
        className="dashboard"
        // style={{ backgroundImage: "assets/images/login_screen.png" }}
      >
        <div
          className="dashboard_in"
          // style={{ backgroundImage: "assets/images/login_screen2.png" }}
        >
          <div className="container">
            <div className="latest_dashboard">
              <div className="row">
                <div className="col-lg-8 col-xxl-8">
                  {showWel &&
                    selector.auth &&
                    selector.auth.role === 5 &&
                    selector.auth.wellcome_notified && (
                      <AgentStatus
                        parentInlineStyle={{ backgroundColor: "green" }}
                        image={"assets/images/deadline1.png"}
                        heading={
                          "Welcome to a world of opportunity and diversity. Your journey to a new homeland starts now."
                        }
                        description={""}
                      />
                    )}

                  {selector &&
                    selector.auth &&
                    (selector.auth.pan_status == "Rejected" ||
                      selector.auth.pan_status == "Pending") &&
                    (selector.auth.authorize_certificate_status == "Rejected" ||
                      selector.auth.authorize_certificate_status ==
                        "Pending") && (
                      <Link
                        to={"/account-settings"}
                        className="Submit d-none d-md-block"
                      >
                        <i className="bi bi-file-earmark-text-fill"></i>{" "}
                        {"Submit your KYC Documents"}
                      </Link>
                    )}
                  {showApprovedStatus &&
                    selector.auth &&
                    selector.auth.status === "hold" && (
                      <AgentStatus
                        style={"d-none d-md-block"}
                        onHide={() => setApprovedStatus(false)}
                        parentInlineStyle={{ backgroundColor: "orange" }}
                        image={"assets/images/deadline1.png"}
                        heading={"Your Profile is Under Review"}
                        description={
                          selector && selector.auth
                            ? selector.auth.comment
                            : "We will update you soon, once your profile gets verified"
                        }
                      />
                    )}

                  {showApprovedStatus &&
                    selector.auth &&
                    selector.auth.status === "active" &&
                    (selector.auth.pan_status == "Approved" ||
                      selector.auth.authorize_certificate_status ==
                        "Approved") &&
                    selector.auth.profile_percentage <= 75 && (
                      <AgentStatus
                        style={"d-none d-md-block"}
                        onHide={() => setApprovedStatus(false)}
                        parentInlineStyle={{ backgroundColor: "#1DBF73" }}
                        image={"assets/images/checkk.png"}
                        heading={"Account approved and activated successfully "}
                        description={
                          selector && selector.auth
                            ? selector.auth.comment
                            : "We will update you soon, once your profile gets verified"
                        }
                        link={`/${selector.auth.city}/${selector.auth.slug}`}
                        linkInHeading={"Now build your profile"}
                      />
                    )}
                  {selector &&
                    selector.auth &&
                    (selector.auth.pan_status == "Approved" ||
                      selector.auth.authorize_certificate_status ==
                        "Approved") &&
                    (selector.auth.pan_status !== "Approved" ||
                      selector.auth.authorize_certificate_status !==
                        "Approved") && (
                      <Link
                        to={"/account-settings"}
                        className="green_Submit d-none d-md-block"
                      >
                        <i className="bi bi-file-earmark-text-fill"></i>{" "}
                        {
                          "Build Trust on your Profile by Verifying all KYC Documents"
                        }
                      </Link>
                    )}
                  <div className="row">
                    <h6 className="ht">
                      {"Hi,"}{" "}
                      {selector.auth && selector.role == 5
                        ? selector.auth.owner_name
                        : selector.auth.name}
                    </h6>
                    {!dt && <LearnGetStarted />}
                    <TodaySales
                      label={"Monthly Views"}
                      salesPrice={selector.monthsViews}
                      salesPrice2={selector.monthsViewsData}
                      profit={""}
                      imageStyle={"img"}
                      imageSrc={
                        selector.monthsViews !== 0
                          ? Math.sign(selector.monthsViews) === 1
                            ? "../../assets/images/leticon1.png"
                            : Math.sign(selector.monthsViews) === -1
                            ? "../../assets/images/leticon2.png"
                            : ""
                          : ""
                      }
                    />
                    <TodaySales
                      label={"Engagement"}
                      salesPrice={selector.monthlyEngagement}
                      salesPrice2={selector.monthsEngagementData}
                      profit={""}
                      imageStyle={"img2"}
                      imageSrc={
                        selector.monthlyEngagement !== 0
                          ? Math.sign(selector.monthlyEngagement) === 1
                            ? "../../assets/images/leticon1.png"
                            : Math.sign(selector.monthlyEngagement) === -1
                            ? "../../assets/images/leticon2.png"
                            : ""
                          : ""
                      }
                    />
                    <Chart />
                    <CallHistory
                      callHistoryData={
                        selector.callHistory && selector.callHistory.length > 0
                          ? selector.callHistory[0]
                          : []
                      }
                    />
                    {selector && selector.auth && selector.auth.role == 5 && (
                      <>
                        <StaffMemberTable
                          columns={homeStaffMemberColumns}
                          data={
                            accountselector &&
                            accountselector.staff &&
                            accountselector.staff.length > 0
                              ? accountselector.staff
                              : []
                          }
                        />
                        <div className="text-center mb-3">
                          <Link className="See" to={"/account-settings"}>
                            {"See all"}
                          </Link>
                        </div>
                        <div className="col-sm-12 onlymob mb-3">
                          <Branches
                            data={
                              accountselector &&
                              accountselector.branch &&
                              accountselector.branch.length > 0
                                ? accountselector.branch
                                : []
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-xxl-4">
                  {selector &&
                    selector.auth &&
                    (selector.auth.pan_status == "Rejected" ||
                      selector.auth.pan_status == "Pending") &&
                    (selector.auth.authorize_certificate_status == "Rejected" ||
                      selector.auth.authorize_certificate_status ==
                        "Pending") && (
                      <Link
                        to={"/account-settings"}
                        className="Submit d-block d-md-none"
                      >
                        <i className="bi bi-file-earmark-text-fill"></i>{" "}
                        {"Submit your KYC Documents"}
                      </Link>
                    )}
                  {showApprovedStatus &&
                    selector.auth &&
                    selector.auth.status === "hold" && (
                      <AgentStatus
                        style={"d-block d-md-none"}
                        onHide={() => setApprovedStatus(false)}
                        parentInlineStyle={{ backgroundColor: "orange" }}
                        image={"assets/images/deadline1.png"}
                        heading={"Your Profile is Under Review"}
                        description={
                          selector && selector.auth
                            ? selector.auth.comment
                            : "We will update you soon, once your profile gets verified"
                        }
                      />
                    )}
                  {showApprovedStatus &&
                    selector.auth &&
                    selector.auth.status === "active" &&
                    (selector.auth.pan_status == "Approved" ||
                      selector.auth.authorize_certificate_status ==
                        "Approved") &&
                    selector.auth.profile_percentage <= 75 && (
                      <AgentStatus
                        style={"d-block d-md-none"}
                        onHide={() => setApprovedStatus(false)}
                        parentInlineStyle={{ backgroundColor: "#1DBF73" }}
                        image={"assets/images/checkk.png"}
                        heading={"Account approved and activated successfully "}
                        description={
                          selector && selector.auth
                            ? selector.auth.comment
                            : "We will update you soon, once your profile gets verified"
                        }
                        link={`/${selector.auth.city}/${selector.auth.slug}`}
                        linkInHeading={"Now build your profile"}
                      />
                    )}

                  {selector.auth && selector.auth.role == 5 && (
                    <ProfileStatus
                      companyImg={
                        selector.auth && selector.auth.company_logo
                          ? mediaUrl + selector.auth.company_logo
                          : null
                      }
                      companyName={selector.auth && selector.auth.company_name}
                      branchCount={
                        accountselector &&
                        accountselector.branch &&
                        accountselector.branch.length
                      }
                      staffCount={
                        accountselector &&
                        accountselector.staff &&
                        accountselector.staff.length
                      }
                    />
                  )}

                  {selector.auth && selector.auth.role == 5 && (
                    <>
                      <div className="training mb-3">
                        Training Videos
                        <Link className="float-end" to={"/partner/training"}>
                          Click here
                        </Link>
                      </div>
                      <SwitchCheckbox
                        heading={"Call Availabilty"}
                        parentStyle={"wap Available mb-3 h-auto"}
                        switcher={"off"}
                        value={false}
                        description={
                          "Choose from online and offline to let others know when you're ready for Call Communication"
                        }
                        online={selector.auth && selector.auth.online}
                        onClick={(e) => {
                          if (selector?.auth?.status !== "active") {
                            setShow(!show);
                          }
                        }}
                        crossIcon={true}
                        isChecked={
                          selector.auth.video_status === "active" ? true : false
                        }
                        setOnline={setOnline}
                        type={"online"}
                      />
                      <SwitchCheckbox
                        parentStyle={"wap Available mb-3 h-auto"}
                        heading={"Boost your profile"}
                        switcher={"off"}
                        description={
                          "Stand out and attract attention with upgraded features to showcase your skills, experience, and achievements effectively.."
                        }
                        onClick={(e) => {
                          // if (selector?.auth?.status !=="active") {
                          //   setShow(!show);
                          // }
                        }}
                        isChecked={
                          selector.activeBoosts.length > 0 ? true : false
                        }
                        type={"boost"}
                      />
                    </>
                  )}

                  {selector.auth && selector.auth.role == 5 ? (
                    <Branches
                      data={
                        accountselector &&
                        accountselector.branch &&
                        accountselector.branch.length > 0
                          ? accountselector.branch
                          : []
                      }
                    />
                  ) : (
                    <>
                      <LeaveCompany />
                      <SwitchCheckbox
                        heading={"Call Availabilty"}
                        parentStyle={"wap Available mb-3 h-auto"}
                        switcher={"off"}
                        value={false}
                        description={
                          "Choose from online and offline to let others know when you're ready for Call Communication"
                        }
                        online={selector.auth && selector.auth.online}
                        onClick={(e) => {
                          if (selector?.auth?.status !== "active") {
                            setShow(!show);
                          }
                        }}
                        crossIcon={true}
                        isChecked={
                          selector.auth.video_status === "active" ? true : false
                        }
                        setOnline={setOnline}
                        type={"online"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={
          <CommonPopUp
            parentStyle={"thank_you_cont"}
            childStyle={"thank_you_img"}
            imgSrc={"../../../assets/images/Hourglass.png"}
            title={"More Information"}
            description={
              "We still require more information from your side to get your account verified."
            }
            linkTitle={"Add information"}
            onHide={() => setShow(!show)}
          />
        }
        backDrop={"static"}
        style={"thank_you_pop"}
      />
      {boostModal && <Boost />}
    </>
  );
};

export default LastestDashboard;
