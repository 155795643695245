import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import FormRadio from "../FormRadio";
import FormTextarea from "../FormTextarea";
import Button from "../Button";
import { videoReviewRattingApi } from "../../../services/api";
import { rattingReviewSchema } from "../../../services/schema";
import { mediaUrl } from "../../../services/network";
import { useContext } from "react";
import { SocketContext } from "../../../Context";
import Example from "../ReactBoostrap";
import FindUser from "../FindUser";
import { useSelector } from "react-redux";

const RattingReview = ({ onHide, setThankyou, to, agentdata }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(rattingReviewSchema),
  });
  const [rating, setRating] = useState(0);
  const [duration, setDuration] = useState("");
  const [videoModal, setVideoModal] = React.useState(false);
  const selector = useSelector((state) => state.auth);
  const { callTo, setCurrentUser, isPlaying } = useContext(SocketContext);

  const removeTheItemInLocalStorage = () => {
    localStorage.removeItem("AgentV");
    localStorage.removeItem("branchV");
    localStorage.removeItem("connV");
    onHide();
  };

  const handleRating = (rate) => {
    setRating(rate);
  };
  const onPointerEnter = () => {
    clearErrors("ratting");
  };
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  React.useEffect(() => {
    if (agentdata && agentdata.duration) {
      if (agentdata.duration > 60) {
        const minutes = Math.floor(agentdata.duration / 60);
        const seconds = agentdata.duration - minutes * 60;
        setDuration(`${minutes} min ${seconds} sec`);
      } else {
        setDuration(`${agentdata.duration} sec`);
      }
    }
  }, [agentdata]);

  const onSubmit = async (data) => {
    data.ratting = rating;
    data.user_to = localStorage.getItem("AgentV") || agentdata.attendant_id;
    data.branch_id = localStorage.getItem("branchV");
    data.connectionId = localStorage.getItem("connV");
    if (rating === 0) {
      setError("ratting", {
        type: "custom",
        message: "Please fill the ratting",
      });
    } else {
      const body = { ...data, name: selector.auth.name };
      try {
        const result = await videoReviewRattingApi(body);
        if (result.data.status === "success") {
          toast.success(result.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          localStorage.removeItem("AgentV");
          localStorage.removeItem("branchV");
          localStorage.removeItem("connV");
          setThankyou(true);
          onHide();
        }
      } catch (e) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        console.log("videoCallRatting", e);
      }
    }
  };

  const handleCallAgain = async () => {
    let data = {
      connection_user: agentdata.attendant_id,
      uid: agentdata.attendant_id,
      image: agentdata.profile_image,
      name: agentdata.name,
    };
    setCurrentUser(data);
    callTo(agentdata.attendant_id, true, 0);
    setVideoModal(true);
  };

  const likeHandle = () => {
    // followUnfollowApi({ followId: id })
    //   .then((response) => {
    //     // console.log("response111", response);
    //     if (response.data.follow) {
    //       //  like = 1;
    //     } else {
    //       //  like = 0;
    //     }
    //   })
    //   .catch((e) => {
    //     toast.error(e.response.data.message, {
    //       position: toast.POSITION.TOP_LEFT,
    //     });
    //   });
  };
  return (
    <>
      <div className="modal-header p-0 text-center b-none">
        <h5 className="modal-title" id="exampleModalLabel">
          {/* <img
            src="../../assets/images/call_ended.png"
            alt="call_ended"
            onClick={() => onHide()}
          />{" "} */}
          {"Video Session Ended"}
        </h5>
      </div>
      <div className="modal-body p-0 ">
        <div className="row">
          <div className="col-sm-5">
            <div className="Deration">
              <p className="sp">
                {"Duration :"}
                <b> {duration !== undefined ? duration : 0 + "sec"}</b>
              </p>

              <div
                className="img"
                style={{
                  backgroundImage: `url(${
                    agentdata?.profile_image
                      ? mediaUrl + agentdata?.profile_image
                      : "../../assets/images/user.png"
                  })`,
                }}
              ></div>
              <h4>{agentdata?.name}</h4>
              <h3>{agentdata?.company_name}</h3>
              <p>
                {
                  "Were you satisfied with the information provided during the call ?"
                }
              </p>
              <Link onClick={likeHandle}>
                <i className="bi bi-star-fill"></i> {"Mark as Favourite"}
              </Link>
            </div>
          </div>
          <div className="col-sm-7">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group">
                <label>
                  {"How was your Communication with "}
                  {agentdata?.name}
                  {" ?"}
                </label>
                <h6>
                  <Rating
                    onClick={handleRating}
                    allowFraction
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                  />
                  {errors && errors.ratting && (
                    <p style={{ color: "red" }}>{errors.ratting.message}</p>
                  )}
                </h6>
              </div>
              <FormRadio
                parentStyle={"form_group"}
                childStyle={"form-check-input"}
                label={
                  "Were you satisfied with the information provided during the call ?"
                }
                event={register}
                errors={errors}
                name={"satisfied"}
              />
              <FormRadio
                parentStyle={"form_group"}
                label={
                  "Would you like to proceed with the agent for your Application ?"
                }
                childStyle={"form-check-input"}
                event={register}
                errors={errors}
                name={"application"}
              />
              <FormTextarea
                parentStyle={"form_group mb-5"}
                label={"Write your Review"}
                childStyle={"form-control"}
                event={register}
                name={"review"}
                errors={errors}
                placeholder={"Type your message here..."}
              />

              <div className="form_group text-center">
                <Button
                  inType={"submit"}
                  style={"form_group text-center"}
                  title={"Submit Review"}
                />
                {isPlaying && isPlaying === true ? (
                  <Button
                    inType={"button"}
                    style={"form_group text-center btntimer"}
                    title={"Call Again"}
                    showTimer={true}
                  />
                ) : (
                  <Button
                    inType={"button"}
                    style={"form_group text-center"}
                    title={"Call Again"}
                    onClick={handleCallAgain}
                  />
                )}

                <Button
                  inType={"button"}
                  style={"form_group text-center"}
                  title={"Skip"}
                  onClick={async () => {
                    await removeTheItemInLocalStorage();
                    onHide();
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <Example
          show={videoModal}
          onHide={() => setVideoModal(!videoModal)}
          style={"call_user"}
          component={
            <FindUser
              show={videoModal}
              data={agentdata}
              onHide={() => setVideoModal(!videoModal)}
              single={true}
            />
          }
          backDrop={"static"}
        />
      </div>
    </>
  );
};
export default RattingReview;
