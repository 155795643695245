import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../Tools/Button";
import FormInputField from "../../../Tools/Forminput";
import { loginSchema } from "../../../../services/schema";
import {
  phoneVerification,
  setPhoneNumber,
} from "../../../../Reducer/authSlice";
import HeadingAndImage from "./headingAndImage";

const AgentLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.auth);
  const [phone, setPhone] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  if (selector.success) {
    navigate("/otp");
  }
  const onSubmit = (data) => {
    dispatch(setPhoneNumber(data.phone));
    const body = {
      phone: data.phone,
      latitude: selector?.latitude,
      longitude: selector?.longitude,
      ip_address: selector?.ip_address,
      current_address: "chandigarh",
    };
    dispatch(phoneVerification(body));
  };
  return (
    <>
      <section
        className="loginpage loginmodal w-100"
        style={{ backgroundImage: "url(assets/images/login_screen.png)" }}
      >
        <div
          className="loginhead"
          style={{ backgroundImage: "url(assets/images/login_screen2.png)" }}
        >
          <div className="container">
            <div className="login_p">
              <div className="row">
                <HeadingAndImage
                  parentStyle={"col-md-6"}
                  childStyle={"right_login"}
                  backgroundImage={"linear-gradient(180deg, #1DBF7338, #fff)"}
                  heading={"Welcome to Chownk"}
                  paragraph={
                    "Sign in or create an account. It takes less than a minute!"
                  }
                  imageSrc={"assets/images/agent_login_bg.png"}
                  imageStyle={"d-block w-100"}
                />
                <div className="col-md-6">
                  <div className="lest_login text-center">
                    <img src="assets/images/logo2.png" alt="logo" />
                    <form
                      className="text-start mt-4"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <FormInputField
                        parentStyle={"form-group"}
                        childStyle={"form-control"}
                        label={"Enter your mobile Number"}
                        event={register}
                        name={"phone"}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={"Enter your mobile number here..."}
                      />
                      {errors && errors.phone && (
                        <p style={{ color: "red" }}>{errors.phone.message}</p>
                      )}
                      <p>
                        <input type="checkbox" className="form-check-input" /> I
                        agree to <a href="#">Terms</a> &{" "}
                        <a href="#">Conditions</a>
                      </p>
                      <p>
                        <input type="checkbox" className="form-check-input" />{" "}
                        Send updates on <a href="#">Whatsapp</a>
                      </p>
                      <Button
                        inType={"button"}
                        style={"btn mt-2 mt-lg-5"}
                        title={"Continue"}
                        inlineStyle={
                          phone.length > 9 &&
                          phone.length < 11 &&
                          Object.keys(errors).length == 0
                            ? { backgroundColor: "blue" }
                            : {}
                        }
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgentLogin;
