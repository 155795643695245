import React from "react";
import AskExpertButton from "../../../Tools/AskExpertButton";

const Counselling = () => {
  return (
    <section className="Counselling">
      <div className="container">
        <h2 className="text-center">
          <span>
            Why Choose <b>Ask Expert</b> <br />
            Counselling ?
          </span>
        </h2>

        <div className="row">
          <div className="col-sm-6 col-md-4 mb-3">
            <div className="set">
              <span className="coun_icon">
                <img src="assets/images/coun_icon1.png" alt="coun_icon1" />
              </span>
              <h6>Expert Counselling</h6>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <div className="set">
              <span className="coun_icon">
                <img src="assets/images/coun_icon2.png" alt="coun_icon1" />
              </span>
              <h6>Customized Assistance</h6>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <div className="set">
              <span className="coun_icon">
                <img src="assets/images/coun_icon3.png" alt="coun_icon1" />
              </span>
              <h6>Interest Based Suggestions</h6>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <div className="set">
              <span className="coun_icon">
                <img src="assets/images/coun_icon4.png" alt="coun_icon1" />
              </span>
              <h6>Arrange Video/Chat TALK</h6>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <div className="set">
              <span className="coun_icon">
                <img src="assets/images/coun_icon5.png" alt="coun_icon1" />
              </span>
              <h6>We Stand with you</h6>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <div className="set">
              <span className="coun_icon">
                <img src="assets/images/coun_icon1.png" alt="coun_icon1" />
              </span>
              <h6>Interest Based Suggestions</h6>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>

          <div className="col-md-12 text-center mt-3 mt-xl-5">
            <AskExpertButton />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counselling;
