import React from "react";
import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import NavTabs from "../../../Tools/NavTabs";
import ProcessDetails from "./processDetails";
import TopBar from "./topBar";

const MyApplicationSingle = () => {
  const [activeTab, setActiveTab] = React.useState("overview");

  const applicationSingleColumns = [
    {
      label: "Overview",
      name: "overview",
      imageSrc: "../../assets/images/singicon4.png",
    },
    {
      label: "Details",
      name: "details",
      imageSrc: "../../assets/images/singicon2.png",
    },
    {
      label: "Files",
      name: "files",
      imageSrc: "../../assets/images/singicon3.png",
    },
    {
      label: "Checklist",
      name: "checklist",
      imageSrc: "../../assets/images/singicon4.png",
    },
  ];
  return (
    <>
      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />
      <section className="my_applications_single">
        <div className="container">
          <TopBar />
          <NavTabs
            style={"mt-3"}
            id={"myTab"}
            data={applicationSingleColumns}
            active={activeTab}
            setActiveTab={setActiveTab}
          />

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade show active`}
              id="Overview"
              role="tabpanel"
              aria-labelledby="Overview-tab"
            >
              <ProcessDetails />
            </div>
            <div
              className={`tab-pane fade`}
              id="Details"
              role="tabpanel"
              aria-labelledby="Details-tab"
            >
              <ProcessDetails />
            </div>
            <div
              className={`tab-pane fade`}
              id="Files"
              role="tabpanel"
              aria-labelledby="Files-tab"
            >
              <ProcessDetails />
            </div>
            <div
              className={`tab-pane fade`}
              id="Checklist"
              role="tabpanel"
              aria-labelledby="Checklist-tab"
            >
              <ProcessDetails />
            </div>
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};
export default MyApplicationSingle;
