import { useNavigate } from "react-router-dom";
import ImageBox from "../../../Tools/ImageBox";

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <header className="main_header landing_header"> 
        <div className="container">
            <div className="row">
                <ImageBox
                    parentStyle={"col-2"}
                    imageSrc={"../../assets/images/chownk-logo.png"}
                    alt={"logo"}
                />
                <div className="col-10 text-end">
                    <ul className="nologin">
                        <li><a className="logi" data-bs-toggle="modal" data-bs-target="#exampleModal_login">Login</a>/<a className="logi" data-bs-toggle="modal" data-bs-target="#exampleModal_login">Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
        <ImageBox
            parentStyle={"text-center"}
            imageSrc={"../../../assets/images/chownk-logo.png"}
            alt={"logo"}
            imgInlineStyle={{ height: "50px", width: 'auto' }}
        />  */}
      <div className="text-center mt-4">
        <img
          src="../assets/images/logo2.png"
          alt="logo"
          style={{ height: "50px", width: "auto", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </div>
    </>
  );
};
export default Header;
