import React, { useContext, useEffect, useState } from "react";
import SimpleInput from "../../Tools/SimpleInput";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormSelectOption from "../../Tools/FormSelectOption";
import { SocketContext } from "../../../Context";
import moment from "moment";
import { countMinusWithInputValue } from "../../../services/function";

const itemSchema = Yup.object().shape({
  item_name: Yup.string().required("Please enter a item name"),
  item_price: Yup.number()
    .typeError("Please enter a valid price")
    .test(
      "is-positive-or-zero",
      "Please enter a positive number",
      (value) => value >= 0
    )
    .required("Please enter an item price"),
});

const offerSchema = Yup.object({
  title: Yup.string().required("Please Enter the offer title"),
  visa: Yup.string().required("Please select the visa type"),
  date: Yup.date()
    .typeError("Please Select a date")
    .required("Please select a date")
    .min(new Date(), "Expiry must be in the future")
    .max(moment().add(2, "M"), "Expiry date limit is 2 months"),
  desc: Yup.string()
    .max(2000, "Description should be less than 2000 characters")
    .required("Please describe your offer"),
  Items: Yup.array().of(itemSchema),
}).required();

const options = [
  { title: "Study Visa", value: "Study Visa" },
  { title: "Work Visa", value: "Work Visa" },
  { title: "Spouse Visa", value: "Spouse Visa" },
  { title: "Business Visa", value: "Business Visa" },
];

const NewCreateOfferModal = ({ show, setShow }) => {
  const [descCount, setDescCount] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(offerSchema),
    defaultValues: {
      Items: [{ item_name: "", item_price: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: "Items", // unique name for your Field Array
  });

  const { offer, setOffer } = useContext(SocketContext);

  const onSubmit = async (data) => {
    let total = null;
    for (let i = 0; i <= data.Items.length; i++) {
      if (data.Items[i] !== undefined) {
        total = total + data.Items[i]["item_price"];
      }
    }
    const body = {
      title: data.title,
      visa_type: data.visa,
      expiryDate: data.date,
      description: data.desc,
      Items: JSON.stringify(data.Items),
      total: total,
    };
    // sendOffer(body);
    setOffer(body);
    setShow(!show);
  };

  return (
    <div class="modal-content">
      <div class="modal-body p-0">
        <h3 class="mt-0">
          <img src="../assets/images/CreateOffer.png" alt="per2" /> Create Offer
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="Account_Settings mt-0">
            <div class="row mt-2">
              <div class="col-md-12 mb-2">
                <SimpleInput
                  inType={"text"}
                  placeholder={"Enter a title for your offer"}
                  name={"title"}
                  event={register}
                  errors={errors}
                />
              </div>

              <FormSelectOption
                parentStyle={"col-md-6 mb-3"}
                heading={"Select Visa Type"}
                options={options}
                childStyle={"form-control"}
                event={register}
                name={"visa"}
                errors={errors}
              />
              <div class="col-md-6 mb-3">
                <h5>Valid Until</h5>
                <input
                  type="date"
                  name="date"
                  className="form-control"
                  min={moment(new Date()).format("YYYY-MM-DD")}
                  max={moment().add(2, "month").format("YYYY-MM-DD")}
                  {...register("date")}
                />
                {errors && errors["date"] && (
                  <p style={{ color: "red" }}>{errors["date"].message}</p>
                )}
              </div>
              <div class="col-md-12 mb-3">
                <h5>Describe your Offer</h5>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Write your offer here..."
                  name="desc"
                  {...register("desc")}
                  onChange={(e) => setDescCount(e.target.value.length)}
                ></textarea>
                <p id="title_count" className="characters float-end mt-1">
                  {`${countMinusWithInputValue(2000, descCount)} Characters`}
                </p>
              </div>

              {errors && errors["desc"] && (
                <p style={{ color: "red" }}> {errors["desc"].message}</p>
              )}

              <div class="col-md-12 mb-3">
                <h5>Enter Items by Items Charges</h5>
                <h5 class="my-3">
                  <i
                    class="bi bi-plus-circle-fill"
                    onClick={() => append({ item_name: "", item_price: "" })}
                  ></i>{" "}
                  Add Item
                  {errors.Items && errors.Items.type === "min" && (
                    <p style={{ color: "red" }}>{errors.Items.message}</p>
                  )}
                </h5>
                {fields &&
                  fields.length > 0 &&
                  fields.map((field, index) => (
                    <div class="set">
                      <div class="row">
                        <div class="col-sm-6">
                          <h5>Item Name</h5>
                          <SimpleInput
                            key={field.id}
                            inType={"text"}
                            placeholder={"Item name"}
                            name={`Items.${index}.item_name`}
                            event={register}
                          />
                          {errors && errors.Items && errors.Items[index] && (
                            <p style={{ color: "red" }}>
                              {errors.Items[index].item_name &&
                                errors.Items[index].item_name.message}
                            </p>
                          )}
                        </div>
                        <div class="col-sm-6">
                          <h5>Item Price</h5>
                          <SimpleInput
                            inType={"text"}
                            placeholder={"Item Price"}
                            name={`Items.${index}.item_price`}
                            event={register}
                          />
                          {errors && errors.Items && errors.Items[index] && (
                            <p style={{ color: "red" }}>
                              {errors.Items[index].item_price &&
                                errors.Items[index].item_price.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <i
                        class="bi bi-x-square-fill"
                        onClick={() => remove(index)}
                      ></i>
                    </div>
                  ))}
              </div>
              <div class="col-md-12 pt-3 text-end">
                <button
                  type="button"
                  class="btn Cancel"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">
                  Create Offer
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCreateOfferModal;
