import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import Meta from "../Meta";
import { downloadAppMetaTags } from "../../../../data/metaTags";
import { useEffect } from "react";

const DownloadApp = () => {
  var isPageHidden = function () {
    var browserSpecificProps = {
      hidden: 1,
      mozHidden: 1,
      msHidden: 1,
      webkitHidden: 1,
    };
    for (var p in browserSpecificProps) {
      if (typeof document[p] !== "undefined") {
        return document[p];
      }
    }
    return false; // actually inconclusive, assuming not
  };

  var redirectt = (link) => {
    //  alert(link);
    window.location.href = link;
  };

  var elapsedMoreTimeThanTimerSet = function (
    timestamp,
    processingBuffer,
    timerDelay
  ) {
    var elapsed = new Date().getTime() - timestamp;
    return timerDelay + processingBuffer < elapsed;
  };

  var redirectToFallbackIfBrowserStillActive = function (
    timestamp,
    processingBuffer,
    timerDelay
  ) {
    var elapsedMore = elapsedMoreTimeThanTimerSet(
      timestamp,
      processingBuffer,
      timerDelay
    );

    if (isPageHidden() || elapsedMore) {
      redirectt(
        "https://itunes.apple.com/in/app/chownk-1-to-1-consultations/id6444736318?mt=8"
      );
    } else {
      redirectt(
        "https://itunes.apple.com/in/app/chownk-1-to-1-consultations/id6444736318?mt=8"
      );
    }
  };

  var log = function (msg) {
    // alert(msg);
  };

  const handleClick = async () => {
    // async function checkRedirectionAndNavigate(chownkUrl, httpUrl) {
    // try {
    //   const response = await fetch("chownk://", { redirect: 'follow' });
    //   if (response.redirected) {
    //    alert('first');
    //   } else {
    //     alert('second');
    //     window.location.href = "https://itunes.apple.com/in/app/chownk-1-to-1-consultations/id6444736318?mt=8";
    //   }
    // } catch (error) {
    //  alert(error.message);
    // }

    try {
      // Attempt to navigate to the chownk URI
      window.location.href = "chownk://";

      // If no error occurs, this line may not be reached
      alert("Navigating to chownk URI...");
    } catch (error) {
      // Handle error - chownk URI not accessible
      alert(
        "Failed to navigate to chownk URI, redirecting to alternative URL..."
      );
      window.location.href =
        "https://itunes.apple.com/in/app/chownk-1-to-1-consultations/id6444736318?mt=8";
    }
    // }

    // Example usage
    // checkRedirectionAndNavigate("chownk://example", "http://example.com");

    // Check if the URL starts with "chownk://"
    // if (window.location.href = "chownk://") {
    //   alert('if');
    // console.log("Redirection to chownk URI:", url);
    // Handle redirection to chownk URI here
    // } else {
    //   alert('else');
    // If it's not a chownk URI, handle it accordingly
    //console.log("Redirected URL is not a chownk URI:", url);
    // }

    //window.location.href = 'https://itunes.apple.com/in/app/chownk-1-to-1-consultations/id6444736318?mt=8';
    // var timestamp = new Date().getTime();
    // var timerDelay = 5000;
    // var processingBuffer = 2000;
    // setTimeout(
    //   redirectToFallbackIfBrowserStillActive(
    //     timestamp,
    //     processingBuffer,
    //     timerDelay
    //   ),
    //   timerDelay
    // );
    // redirectt("chownk://");
    // return()=>{
    //   window.location.href = 'https://itunes.apple.com/in/app/chownk-1-to-1-consultations/id6444736318?mt=8';
    // }
    // window.location.href = 'chownk://';
  };

  return (
    <>
      <Meta {...downloadAppMetaTags} />
      <StudentHeader formDisplay={true} />
      <section className="DownloadApp">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>
                <span>Download the App</span> for Realtime Alerts on Immigration
                abroad <span>NEEDS</span>!
              </h1>
              <h2>Key Benefits:</h2>
              <ul>
                <li>
                  Streamlined Connection with Certified Immigration Agents
                </li>
                <li>Reduced Risk of Fraud and Misrepresentation</li>
                <li>Convenient and Time-Efficient Visa Processing</li>
              </ul>
              <div className="col-6"></div>
              {/* <a onClick={() => handleClick()}>
                  First Deep Link
                </a>  */}
              {/* <h2>Download App</h2> */}

              <Link
                to={"https://play.google.com/store/apps/details?id=com.Chownk"}
                target="_blank"
                className="me-2"
              >
                <img
                  src="../assets/images/googleplayicon.png"
                  alt="iconDownloadapp1"
                />
                GET IT ON
                <span>Google Play</span>
              </Link>
              <Link
                to={
                  "https://apps.apple.com/in/app/chownk-1-to-1-consultations/id6444736318"
                }
                target="_blank"
              >
                <img
                  src="../assets/images/appstoricon.png"
                  alt="iconDownloadapp2"
                />
              </Link>
            </div>
            <div className="col-md-6">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="../assets/images/downloadapp1.png" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="../assets/images/downloadapp2.png" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="../assets/images/downloadapp3.png" alt="..." />
                  </div>
                </div>
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <StudentFooter /> */}
    </>
  );
};

export default DownloadApp;
