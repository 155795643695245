import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import ReportBox from "../../../Tools/ReportBox";
import ExportOption from "../Analytics/exportOption";
import ReportGraph from "./graph";

const Reports = () => {
  const dt = localStorage.getItem("dt");
  const actions = [
    {
      style: "export",
      imageSrc: "../../assets/images/Export.png",
      label: "Export Report",
    },
  ];

  const jobCreatedpercentage = [
    {
      label: "+1%",
    },
    {
      style: "red",
      label: "-1%",
    },
    {
      label: "+9%",
    },
  ];

  const candidateSourced = [
    {
      label: "+1%",
    },
  ];

  const candidateConnected = [
    {
      label: "+1%",
    },
    {
      style: "red",
      label: "-1%",
    },
  ];
  return (
    <>
      <StudentHeader
        style={"header_white"}
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />
      <section
        className="dashboard"
        // style={{
        //   backgroundImage: "url(../../../assets/images/login_screen.png)",
        // }}
      >
        <div
          className="dashboard_in"
          // style={{
          //   backgroundImage: "url(../../../assets/images/login_screen2.png)",
          // }}
        >
          <div className="container">
            <h2 className="ht">
              <i className="bi bi-pie-chart-fill"></i> Reports
            </h2>
            <p>
              You do not qualify for Reports and Analytics. Kindly obtain a
              minimum of 5 reviews and 10 followers to access this feature.
            </p>
          </div>
        </div>
      </section>
      {!dt && <StudentFooter />}
    </>
  );
};
export default Reports;
