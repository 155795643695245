const HowToGetStarted = () => {
  return (
    <section className="started">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <h2>How to Get Started</h2>
            <ul>
              <li>
                Click on the <a href="#">Sign Up</a> button.
              </li>
              <li>Fill in your details to create an account.</li>
              <li>
                Enjoy your complimentary subscription for the first month.
              </li>
              <li>
                Dive into a world of opportunities, connect with students, and
                make a meaningful impact on their educational pursuits.
              </li>
            </ul>
            <p>
              Join us in shaping the future of education! Your expertise
              combined with our platform can open doors to new possibilities for
              students seeking quality education and language proficiency. Don't
              miss out on this complimentary opportunity - sign up today!
            </p>

            <a href="#" className="btn">
              Sign Up
            </a>
          </div>
          <div className="col-lg-5 text-end">
            <img src="../assets/images/started.png" alt="img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToGetStarted;
