import React, { useContext, useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import { useDispatch, useSelector } from "react-redux";
import { addMoney, getBalance } from "../../../Reducer/authSlice";
import $ from "jquery";
import { Roptions } from "../../../data/data";
import { SocketContext } from "../../../Context";

const AddFundsModal = ({ onHide, remainingBalance }) => {
  const { socket } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);
  const [recharge, setRecharge] = useState(
    remainingBalance && selector.funds < remainingBalance
      ? remainingBalance - selector.funds
      : ""
  );
  const [totalMins, setTotalMins] = useState("");
  const [totalCharges, setTotalCharges] = useState(
    recharge ? Number(recharge) + Number(recharge) * 0.18 : ""
  );
  const [error, setError] = useState("");
  const [Razorpay, isLoaded] = useRazorpay();

  const dispatch = useDispatch();

  const allRecharge = [
    {
      amount: 100,
      originalAmt: 200,
      mintues: 40,
    },
    {
      amount: 200,
      originalAmt: 400,
      mintues: 80,
    },
    {
      amount: 500,
      originalAmt: 1000,
      mintues: 200,
    },
    {
      amount: 750,
      originalAmt: 1500,
      mintues: 300,
    },
    {
      amount: 1000,
      originalAmt: 2000,
      mintues: 400,
    },
  ];

  const handlePayment = () => {
    if (Number(recharge) >= 50) {
      const dt = localStorage.getItem("dt");
      if (dt) {
        Roptions.amount = Number(totalCharges);
        let repo = {
          options: Roptions,
          token: dt,
          type: "payment",
          amount: recharge,
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(repo));
      } else {
        let options = {
          key: Roptions.key,
          amount: Number(totalCharges) * 100,
          name: "Add Funds",
          description: "Purchase Description",
          image: "/your_logo.png",
          handler: function (response) {
            if (response.razorpay_payment_id) {
              let body = {
                transaction_id: response.razorpay_payment_id,
                amount: recharge,
              };
              dispatch(addMoney(body));
              onHide();
            }
          },
          prefill: {
            name: "",
            email: "harshil@razorpay.com",
          },
          notes: {
            address: "Hello World",
          },
          theme: {
            color: "#1DBF73",
          },
        };
        setError("");
        const rzpay = new Razorpay(options);
        rzpay.open();
      }
    } else {
      setError("Minimum balance should be INR 50");
    }
  };

  const removeClass = () => {
    const highlightedElements = document.querySelectorAll(".spt.active");
    highlightedElements.forEach((elem) => {
      elem.classList.remove("active");
    });
  };

  useEffect(() => {
    dispatch(getBalance());
    socket.on(`addMoneyStatus`, (data) => {
      if (data.status) {
        dispatch(getBalance());
        onHide();
      }
    });
    return () => {
      socket.off(`addMoneyStatus`);
    };
  }, []);

  return (
    <div className="modal-content">
      <button
        type="button"
        className="btn-close"
        onClick={() => onHide()}
      ></button>
      <div className="modal-body p-0">
        <div className="row">
          <div className="col-md-6">
            <div className="lest_login ">
              <h3>
                Add Funds{" "}
                <span className="float-end">{`Current Balance:  ₹ ${
                  selector && selector.funds && selector.funds
                }`}</span>
              </h3>
              <h5>Popular Recharges</h5>
              <div className="row">
                {allRecharge &&
                  allRecharge.length > 0 &&
                  allRecharge.map((item) => (
                    <div className="col-sm-4" key={item.amount}>
                      <div
                        className="spt"
                        id={item.amount}
                        onClick={() => {
                          setRecharge(item.amount);
                          setTotalMins(item.mintues);
                          setTotalCharges(
                            Number(item.amount) + Number(item.amount) * 0.18
                          );
                          removeClass();
                          $(`#${item.amount}`).addClass("active");
                        }}
                      >
                        <h6>
                          <i className="bi bi-currency-rupee"></i>
                          {item.amount}
                          {selector.auth && selector.auth.role !== 5 && (
                            <>
                              <span className="ms-1">{item.originalAmt}</span>
                              <p>{`For ${item.mintues} mins`}</p>
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="amount">
                <i className="bi bi-currency-rupee"></i>
                <input
                  type="text"
                  placeholder="Enter any amount"
                  value={recharge}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setRecharge(e.target.value);
                      setTotalCharges(
                        Number(e.target.value) + Number(e.target.value) * 0.18
                      );
                      setTotalMins(Math.ceil(e.target.value / 10));
                    }
                    removeClass();
                  }}
                />
              </div>
              {selector.auth && selector.auth.role !== 5 && totalMins && (
                <p>
                  <b>You will receive {totalMins} minutes Call Credit</b>
                </p>
              )}
              <p className="note">
                Note: You will be Charged{" "}
                {<i className="bi bi-currency-rupee"></i>}
                {recharge !== "" ? recharge : 0} + GST{" "}
                {error && error !== "" && <p>{error}</p>}
                <a
                  className="addmoney float-end"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePayment();
                  }}
                >
                  Add Money
                </a>{" "}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="right_login pb-0 pt-5 text-center">
              <p>ADDING MONEY TO ACCOUNT IS SIMPLE & JUST TAKES A FEW CLICKS</p>
              <img
                src="../assets/images/add_money_walletimg.png"
                className="w-75"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFundsModal;
