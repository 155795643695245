import React, { useRef, useEffect, useCallback } from "react";
import { blogsMediaUrl } from "../../../../../services/network";
import { useNavigate } from "react-router-dom";

const SimilarBlogs = ({ data, handleUserNavigate, page, setPage }) => {
  const navigate = useNavigate();
  const observer = useRef();
  const lastBlogElementRef = useRef();

  const unixtoDate = (unix_date) => {
    let date = new Date(unix_date * 1000);
    let formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const lastBlogElementCallback = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [setPage]
  );

  useEffect(() => {
    if (lastBlogElementRef.current) {
      lastBlogElementCallback(lastBlogElementRef.current);
    }
  }, [lastBlogElementCallback, data]);

  return (
    <>
      <h2 className="mt-5">Similar Blogs</h2>
      <p>Stay Updated with Our Latest Immigration Insights!</p>

      <div className="row mb-4 SimilarBlogs">
        {data &&
          data.map((item, index) =>
            data.length === index + 1 ? (
              <div
                className="col-sm-6 col-lg-3 mb-3"
                ref={lastBlogElementRef}
                key={item.id}
              >
                <div className="img">
                  <img
                    src={`${blogsMediaUrl}${item.image}`}
                    alt={`${item.title}-Chownk`}
                    className="w-100"
                    onClick={() => navigate(`/blogs/${item.slug}`)}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="h5">
                    <span>
                      <img
                        src={`${blogsMediaUrl}${item.author_image}`}
                        alt={`${item.author_name}-author of ${item.title}-Chownk`}
                        className="w-100"
                      />
                    </span>{" "}
                    {item.author_name}
                  </p>
                  <p className="h6">{unixtoDate(item.unix_time)}</p>
                </div>
                <h2 onClick={() => navigate(`/blogs/${item.slug}`)}>
                  {item.title}
                </h2>
                <p>{item.description}</p>
              </div>
            ) : (
              <div className="col-sm-6 col-lg-3 mb-3" key={item.id}>
                <div className="img">
                  <img
                    src={`${blogsMediaUrl}${item.image}`}
                    alt="similar-blog-image"
                    className="w-100"
                    onClick={() => navigate(`/blogs/${item.slug}`)}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="h5">
                    <span>
                      <img
                        src={`${blogsMediaUrl}${item.author_image}`}
                        alt={`${item.author_name}-author of ${item.title}-Chownk`}
                        className="w-100"
                      />
                    </span>{" "}
                    {item.author_name}
                  </p>
                  <p className="h6">{unixtoDate(item.unix_time)}</p>
                </div>
                <h2 onClick={() => navigate(`/blogs/${item.slug}`)}>
                  {item.title}
                </h2>
                <p>{item.description}</p>
              </div>
            )
          )}
      </div>
    </>
  );
};

export default SimilarBlogs;
