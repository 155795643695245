import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Feedback = () => {
  return (
    <>
      <section className="customers_feedback text-center">
        <div className="container-fluid p-0">
          <h2 className="text-center">
            <span>
              Customers Feedback Who Follows <br />
              <b>Ask Expert</b> Suggestions
            </span>
          </h2>
          <OwlCarousel
            className="owl-carousel feedbackcarousel owl.carousel.min owl-theme"
            loop={true}
            autoplay={true}
            autoplayTimeout={5000}
            margin={20}
            responsiveClass={true}
            responsive={{
              0: { items: 1, nav: true },
              576: { items: 1.3, nav: true },
              768: { items: 2.5, nav: true },
              1200: { items: 3.5, nav: true, margin: 20 },
              1699: { items: 4.5, nav: true, margin: 30 },
              1800: { items: 4.5, nav: true, margin: 30 },
            }}
          >
            <div className="item">
              <div className="set">
                <span
                  className="img"
                  style={{ backgroundImage: "url(./assets/images/user.png)" }}
                ></span>
                <h6>Kanika Arora</h6>
                <p>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                </p>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum'
                </p>
              </div>
            </div>

            <div className="item">
              <div className="set">
                <span
                  className="img"
                  style={{ backgroundImage: "url(./assets/images/user.png)" }}
                ></span>
                <h6>Kanika Arora</h6>
                <p>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                </p>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum'
                </p>
              </div>
            </div>

            <div className="item">
              <div className="set">
                <span
                  className="img"
                  style={{ backgroundImage: "url(./assets/images/user.png)" }}
                ></span>
                <h6>Kanika Arora</h6>
                <p>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                </p>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum'
                </p>
              </div>
            </div>

            <div className="item">
              <div className="set">
                <span
                  className="img"
                  style={{ backgroundImage: "url(./assets/images/user.png)" }}
                ></span>
                <h6>Kanika Arora</h6>
                <p>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                </p>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum'
                </p>
              </div>
            </div>

            <div className="item">
              <div className="set">
                <span
                  className="img"
                  style={{ backgroundImage: "url(./assets/images/user.png)" }}
                ></span>
                <h6>Kanika Arora</h6>
                <p>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                </p>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum'
                </p>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default Feedback;
