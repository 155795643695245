import { useContext, useState } from "react";
import { mediaUrl } from "../../../../../services/network";
import FindUser from "../../../../Tools/FindUser";
import Example from "../../../../Tools/ReactBoostrap";
import { SocketContext } from "../../../../../Context";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useSelector } from "react-redux";
import { saveCallDetail } from "../../../../../services/api";
function UserHeader({ currentUser, ulStyle, shortName, videoIcon }) {
  const {
    userModel,
    setUserModel,
    callTo,
    callToStatic,
    isPlaying,
    connectId,
    setIsPlaying,
    setAddFundsModal,
    addFundsModal,
  } = useContext(SocketContext);
  let tl = true;
  const navigate = useNavigate();
  const selector = useSelector((state) => state.auth);
  const { funds, call_min } = useSelector((state) => state.auth);
  const videoCondition =
    selector.auth?.role !==4 && currentUser.userRole !=="expert";

  const click = (st) => {
    if (tl == true && !isPlaying) {
      tl = false;
      if (
        currentUser.user_id == currentUser.started_by ||
        currentUser.application_process == 1
      ) {
        if (currentUser.online == true || currentUser.online == "true") {
          if (funds >= call_min || st == 1) {
            setUserModel(true);
            callTo(currentUser.connection_user, true, st);
          } else {
            setAddFundsModal(!addFundsModal);
          }
        } else {
          saveCall(currentUser.connection_user);
          if (funds >= call_min || st == 1) {
            setUserModel(true);
            callToStatic(currentUser.connection_user);
          } else {
            setAddFundsModal(!addFundsModal);
          }
        }
      } else {
      }
    }
  };

  function shortNameFun() {
    let name = "";
    if (
      (currentUser.userRole === "branch" && currentUser.dynamic_image == 0) ||
      (currentUser.userRole === "staff" && currentUser.dynamic_image == 0)
    ) {
      name = `${currentUser.name}-${currentUser.company_name}`;
    } else {
      name = currentUser.name;
    }
    if (name.length > 12) {
      return `${name.substring(0, 12)}..`;
    }
    return name;
  }

  const saveCall = (id) => {
    try {
      const body = { to: id };
      saveCallDetail(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {currentUser &&
      currentUser !==null &&
      Object.keys(currentUser).length > 0 ? (
        <div className="mainuser md-0">
          {currentUser.dynamic_image === 1 ? (
            <div className="chatuser">
              {currentUser.name.toUpperCase().charAt(0)}
            </div>
          ) : (
            <div
              className="chatuser"
              onClick={() => {
                if (
                  currentUser.userRole == "staff" ||
                  currentUser.userRole == "branch"
                ) {
                  navigate(`/${currentUser.city}/${currentUser.slug}`);
                }
              }}
              style={{
                backgroundImage: `url(${
                  mediaUrl +
                  `${
                    currentUser.userRole === "student"
                      ? currentUser.image
                      : currentUser.company_logo
                  }`
                })`,
              }}
            ></div>
          )}
          <span
            onClick={() => {
              if (
                (currentUser.userRole == "staff" ||
                  currentUser.userRole == "branch") &&
                currentUser.dynamic_image === 0
              ) {
                navigate(`/${currentUser.city}/${currentUser.slug}`);
              }
            }}
          >
            {shortName
              ? shortNameFun()
              : `${currentUser?.name ? currentUser.name : "CHOWNK USER"} ${
                  (currentUser.userRole == "branch" &&
                    currentUser.dynamic_image == 0) ||
                  (currentUser.userRole == "staff" &&
                    currentUser.dynamic_image == 0)
                    ? `(${currentUser.company_name})`
                    : ""
                }`}
          </span>
          <p>
            <i
              className={`bi bi-circle-fill ${
                currentUser?.online == "true" || currentUser?.online == true
                  ? ""
                  : "offline"
              }`}
            ></i>{" "}
            {currentUser?.online == "true" || currentUser?.online == true
              ? "Online"
              : "Offline"}
          </p>
          <ul className={ulStyle}>
            <li
              className="offericon"
              onClick={() => {
                $(".ct_offer").addClass("main");
              }}
            >
              <img src="../../../assets/images/imgdoc.png" />
            </li>
            {!videoIcon && (
              <li
                onClick={() => {
                  $(".search-data-box").addClass("main");
                  $(".chat_right").addClass("main");
                }}
                data-tooltip-id="search"
              >
                <img src="../../../../../assets/images/srchicon.png" />
              </li>
            )}

            {videoCondition && (
              <li
                onClick={() => {
                  if (
                    currentUser.user_id == currentUser.started_by ||
                    currentUser.application_process == 1
                  ) {
                    click(currentUser.application_process);
                  }
                }}
              >
                {isPlaying && isPlaying == true ? (
                  <CountdownCircleTimer
                    isPlaying={isPlaying}
                    duration={10}
                    colors="#A30000"
                    size={25}
                    initialRemainingTime={10}
                    strokeWidth={4}
                    trailStrokeWidth={4}
                    children={({ remainingTime }) => {
                      const minutes = Math.floor(remainingTime / 60);
                      const seconds = remainingTime % 60;
                      return `${seconds}`;
                    }}
                    onComplete={() => {
                      setIsPlaying(false);
                      return { shouldRepeat: false, delay: 1.5 };
                    }}
                  />
                ) : videoIcon ? (
                  <i
                    data-tooltip-id="video-call"
                    className={`bi bi-telephone-fill ${
                      currentUser.user_id == currentUser.started_by ||
                      currentUser.application_process == 1
                        ? ""
                        : "disabled"
                    }`}
                  ></i>
                ) : (
                  <img
                    src="../../../../../assets/images/calloicon.svg"
                    data-tooltip-id="video-call"
                    className={
                      currentUser.user_id == currentUser.started_by ||
                      currentUser.application_process == 1
                        ? ""
                        : "disabled"
                    }
                  />
                )}
              </li>
            )}
          </ul>
          <Example
            show={userModel}
            style={
              currentUser.online == true || currentUser.online == "true"
                ? connectId !==null
                  ? "call_user"
                  : "call_user plswait"
                : "ofline_div"
            }
            onHide={() => setUserModel(!userModel)}
            component={
              <FindUser
                show={userModel}
                data={currentUser}
                single={
                  currentUser.online == true || currentUser.online == "true"
                    ? true
                    : false
                }
                onHide={() => setUserModel(!userModel)}
              />
            }
            backDrop={"static"}
          />
        </div>
      ) : (
        ""
      )}
      <Tooltip
        id="video-call"
        content={
          currentUser.user_id == currentUser.started_by ||
          currentUser.application_process == 1
            ? "Call now"
            : "Initiating calls pre-application is exclusive to customers. Kindly request the customer to initiate the call."
        }
        place="bottom"
        style={{
          zIndex: 999999999999999,
          width:
            currentUser.user_id == currentUser.started_by ||
            currentUser.application_process == 1
              ? "auto"
              : "200px",
        }}
      />
      <Tooltip
        id="search"
        content="Search"
        place="left"
        style={{ zIndex: 999 }}
      />
    </>
  );
}
export default UserHeader;
