import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { JoinAsOption } from "../../../../data/data";
import {
  emailregExp,
  firstVerificationSchema,
  profileUpdateSchema,
} from "../../../../services/schema";

import Example from "../../../Tools/ReactBoostrap";
import AccountSettingOtpModel from "./accountSettingOtpModel";
import RowRadio from "../../../Tools/RowRadio";
import LabelInputBox from "../../../Tools/LabelInputBox";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { googleMapApi } from "../../../../data/data";
import ProfileButton from "../../../Tools/ProfileButton";
import { availableCompanyNameApi, emailCheck } from "../../../../services/api";
import {
  accountProfileUpdatePhoneVerification,
  setPhoneNumber,
} from "../../../../Reducer/authSlice";
import ChangeEmailModal from "../../../Layouts/Model/changeEmailModal";
import ChangeMobileNumberModel from "../../../Layouts/Model/changeMobileNumberModel";
import ChangeMobileNumberOtp from "../../../Layouts/Model/changeMobileNumberOtp";

const ProfileSettingSection = ({ active, showModel }) => {
  const selector = useSelector((state) => state.auth);
  const [profileBody, setProfileBody] = React.useState({});
  const [emailEdit, setEmailEdit] = useState(false);
  const [mobileChange, setMobileChange] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [timeOut, setTimeoutt] = useState("");
  const [slug, setSlug] = React.useState("");
  const [slugMessage, setSlugMessage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(profileUpdateSchema),
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    setValue("owner_name", selector.auth.owner_name);
    setValue("phone", selector.auth.phone);
    setValue("permanent_address", selector.auth.permanent_address);
    setValue("second_address", selector.auth.second_address);
    setValue("city", selector.auth.city);
    setValue("zipcode", selector.auth.zipcode);
    setValue("state", selector.auth.state);
    setValue("company_name", selector.auth.company_name);
    setValue("slug", selector.auth.slug);
    setValue("type", selector.auth.type);
    setValue("email", selector.auth.email);
  }, []);

  const onSubmit = (data) => {
    let city = selector.userDetails.city;
    let state = selector.userDetails.state;
    let latitude = selector.userDetails.latitude;
    let longitude = selector.userDetails.longitude;
    const address = JSON.parse(data.permanent_address);
    console.log(address, "address");
    if (address.formatted_address) {
      address.address_components.forEach((element) => {
        if (element.types.includes("administrative_area_level_3")) {
          city = element.long_name;
        } else if (element.types.includes("administrative_area_level_1")) {
          state = element.long_name;
        }
      });
      if (address.geometry.location) {
        latitude = address.geometry.location.lat;
        longitude = address.geometry.location.lng;
      }
    }

    if (selector.auth?.status === "active") {
      setProfileBody({
        type: data.type,
        owner_name: data.owner_name,

        company_name: data.company_name,
        permanent_address: address.formatted_address
          ? address.formatted_address
          : selector.userDetails.address,
        phone: data.phone,
        latitude: latitude,
        longitude: longitude,
        city: city,
        state: state,
      });

      dispatch(setPhoneNumber(data.phone));
      dispatch(
        accountProfileUpdatePhoneVerification({
          phone: data.phone,
          latitude: selector.userDetails && selector.userDetails.latitude,
          longitude: selector.userDetails && selector.userDetails.longitude,
          ip_address: selector.userDetails && selector.userDetails.ip_address,
          current_address: selector.userDetails && selector.userDetails.address,
        })
      );
      setShow(!show);
    } else {
      showModel();
    }
  };

  const companyNameHandleChange = async (e) => {
    clearErrors("company_name");
    clearTimeout(timeOut);
    if (selector.auth && selector.auth?.company_name === e.target.value) {
      clearErrors("company_name");
    } else {
      let TimeInterval = setTimeout(findCompany(e.target.value), 2000);
      setTimeoutt(TimeInterval);
    }
  };

  const findCompany = (val) => {
    availableCompanyNameApi({ company: val })
      .then((response) => {
        if (response.data.available) {
          clearErrors("company_name");
          setSlugMessage({
            style: { color: "green" },
            message: response.data.message,
          });
          setSlug(response.data.slug);
        } else {
          setSlugMessage({
            style: { color: "red" },
            message: "Company Name not Available",
          });
          setError("company_name", {
            type: "custom",
            message: response.data.message,
          });
        }
      })
      .catch((e) => {
        setSlugMessage({
          style: { color: "red" },
          message: "error",
        });
        setError("company_name", { type: "custom", message: e.message });
      });
  };

  console.log(errors, "EOORS");
  return (
    <div
      className={`tab-pane fade ${active}`}
      id="Settingstab1-tab-pane"
      role="tabpanel"
      aria-labelledby="Settingstab1-tab"
      tabIndex="0"
    >
      <p>
        {"Profile settings enable you to customize and manage your account"}
        {
          "preferences. By accessing this section, you can adjust personal details,"
        }
        {"modify privacy options, and tailor the user experience to meet your"}
        {
          "specific needs, ensuring a personalized and secure platform interaction."
        }
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabelInputBox
          label={"Full Name"}
          labelDescription={"Add Full Owner Name"}
          style={"form-control"}
          inType={"text"}
          name={"owner_name"}
          error={errors}
          event={register}
          placeholder={"Enter Name here"}
          disabled={selector.auth?.status !== "active" ? true : false}
          onChange={(e) => clearErrors("owner_name")}
        />

        <LabelInputBox
          label={"Mobile Number"}
          labelDescription={"Add Mobile Number"}
          style={"form-control"}
          inType={"text"}
          name={"phone"}
          error={errors}
          prefix={"+91"}
          event={register}
          disabled={true}
          placeholder={"Enter Mobile Number"}
          editable={true}
          editAction={() => setMobileChange(!mobileChange)}
        />

        <LabelInputBox
          label={"Email"}
          labelDescription={"Add Email Address"}
          style={"form-control"}
          event={register}
          error={errors}
          name={"email"}
          placeholder={"Enter Email Address"}
          disabled={true}
          availableIcon={true}
          available={slugMessage?.style?.color == "green" ? true : false}
          onChange={companyNameHandleChange}
          message={slug ? slug : ""}
          editable={true}
          editAction={() => setEmailEdit(!emailEdit)}
        />

        <LabelInputBox
          label={"Company Name"}
          labelDescription={"Add Company Name"}
          style={"form-control"}
          event={register}
          error={errors}
          name={"company_name"}
          placeholder={"Enter Company Name"}
          disabled={selector.auth?.status !== "active" ? true : false}
          availableIcon={true}
          available={slugMessage?.style?.color == "green" ? true : false}
          onChange={companyNameHandleChange}
          message={slug ? slug : ""}
        />
        <div className="row mt-4">
          <div className="col-md-4">
            <h5>{"Company Address"}</h5>
            <p className="label">{"Add Company address"}</p>
          </div>
          <div className={`col-md-8`}>
            <Controller
              control={control}
              name="permanent_address"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  {console.log(onChange)}
                  <ReactGoogleAutocomplete
                    apiKey={googleMapApi}
                    onPlaceSelected={(e) =>
                      setValue("permanent_address", JSON.stringify(e))
                    }
                    inputAutocompleteValue={
                      value && value.formatted_address
                        ? value.formatted_address
                        : value
                    }
                    ref={ref}
                    onError={() => {}}
                    options={{
                      types: ["geocode"],
                      componentRestrictions: { country: "in" },
                    }}
                    defaultValue={
                      selector.auth && selector.auth.permanent_address
                    }
                  />
                </>
              )}
            />
          </div>
        </div>

        {selector.auth && selector.auth.slug && selector.auth.city && (
          <p className="text-start">{`Profile Link: https://www.chownk.com/${selector.auth.city.replace(
            / /g,
            "-"
          )}/${selector.auth.slug}`}</p>
        )}
        {selector.auth?.status == "active" && (
          <ProfileButton
            parentStyle={"text-center mt-5"}
            inType={"submit"}
            label={"Request to Edit Profile"}
            image={"../../assets/images/edit-2.png"}
          />
        )}
      </form>
      <Example
        size={"lg"}
        show={emailEdit}
        onHide={() => setEmailEdit(!emailEdit)}
        component={<ChangeEmailModal onHide={() => setEmailEdit(!emailEdit)} />}
        style={"loginmodal"}
      />
      <Example
        size={"lg"}
        show={mobileChange}
        onHide={() => setMobileChange(!mobileChange)}
        component={
          <ChangeMobileNumberModel
            showOtp={showOtp}
            setShowOtp={setShowOtp}
            onHide={() => setMobileChange(!mobileChange)}
          />
        }
        style={"loginmodal"}
      />
      <Example
        show={showOtp}
        style={"loginmodal otplogin"}
        onHide={() => setShowOtp(!showOtp)}
        backDrop={"static"}
        component={
          <ChangeMobileNumberOtp
            phoneOnHide={() => setMobileChange(!mobileChange)}
            onHide={() => setShowOtp(!showOtp)}
            duration={30}
          />
        }
      />
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={
          <AccountSettingOtpModel
            onHide={() => setShow(!show)}
            duration={120}
            data={profileBody}
          />
        }
        size={"lg"}
        style={"pop_width loginmodal otplogin"}
        backDrop={"static"}
      />
    </div>
  );
};
export default ProfileSettingSection;
