import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { popularSearchData } from "../../../../data/data";
import { googleMapApi } from "../../../../data/data";

const ServicesSection = ({ handleUserNavigate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const checkType = (item) => {
    switch (item.type) {
      case "specialization":
        handleClick(item.path);
        return;
      case "location":
        Fetchlocation();
        return;
      case "fraud":
        handleClick("/how-to-avoid-immigration-fraud-from-agents");
    }
  };

  const Fetchlocation = (ipAddress) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      fetchLocationInfo(latitude, longitude, ipAddress);
    });
  };

  const fetchLocationInfo = (latitude, longitude, ipAddress) => {
    const apiKey = googleMapApi;
    let cities = "";
    const apiUrl = `https://aps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        await data.results.forEach((i, index) => {
          if (i.types.includes("administrative_area_level_3")) {
            cities = i.address_components[0].long_name;
            queryParams.set("city", cities.replaceAll(" ", "-"));
            queryParams.set("lat", latitude);
            queryParams.set("long", longitude);
            const newSearchString = queryParams.toString();
            if (handleUserNavigate) {
              handleUserNavigate(
                "navigate",
                "/near-me",
                "/",
                "search with location"
              );
            }
            navigate({ pathname: "/near-me", search: newSearchString });
          }
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const handleClick = (value) => {
    if (handleUserNavigate) {
      handleUserNavigate("navigate", value, "/", "search with type");
    }
    navigate(value);
  };
  return (
    <section className="service">
      <div className="container">
        <ul>
          {popularSearchData &&
            popularSearchData.length > 0 &&
            popularSearchData.map((item, index) => (
              <li
                key={index}
                className={item.style}
                onClick={() => {
                  checkType(item);
                }}
              >
                <Link to={item.path}>
                  <img src={item.image} alt={item.alt} />
                  <h2>{item.label}</h2>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

export default ServicesSection;
