import React, { useEffect, useLayoutEffect } from "react";
import StudentHeader from "../../../../Layouts/Student/Header";
import StudentFooter from "../../../../Layouts/Student/Footer";
import LatestBlog from "../../../../Tools/LatestBlog";
import BlogCard from "../../../../Tools/BlogCard";
import BlogsByCategories from "../../../../Tools/BlogsByCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogByCategory,
  getBlogsList,
  getTopBlogs,
} from "../../../../../Reducer/blogSlice";
import { useLocation } from "react-router-dom";
import Meta from "../../Meta";
import { AllBlogsMetaTags } from "../../../../../data/metaTags";
import SearchSection from "./searchSection";
import TopBlogs from "./topBlogs";
import BlogsByCat from "./blogsByCat";
import { saveUserNavigate } from "../../../../../services/api";
import moment from "moment";

const AllBlogs = () => {
  const dispatch = useDispatch();
  const { latest_blog, recent_blogs, categories, topBlogs } = useSelector(
    (state) => state.blogs
  );
  const selector = useSelector((state) => state.auth);
  const guestId = localStorage.getItem("guestId");
  const location = useLocation();

  useEffect(() => {
    dispatch(getBlogsList());
    dispatch(getTopBlogs());
    dispatch(getBlogByCategory());
  }, [location.pathname]);

  const handleUserNavigate = (type, hit, from, description) => {
    try {
      const body = {
        id: selector.auth ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Meta {...AllBlogsMetaTags} />
      <StudentHeader
        logo={"assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />
      <SearchSection />
      <section class="section_blogs">
        <div class="container">
          <TopBlogs
            topBlogs={topBlogs}
            handleUserNavigate={handleUserNavigate}
          />
          <BlogsByCat
            categories={categories}
            handleUserNavigate={handleUserNavigate}
          />
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default AllBlogs;
