import React from "react";

const FormFour = ({ page, setPage, data, onChange }) => {
  return (
    <div className="form-page">
      <h2>
        WHAT IS YOUR <b>PREFERRED</b> FIELD OF STUDY?
      </h2>
      <p>
        It is a long established fact that a reader will be distracted by the
        readable
      </p>
      <ul>
        <li>
          <span className="option">
            SCIENCE & ENGINEERING
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT IS YOUR PREFERRED FIELD OF STUDY?"
                className="quality"
                value="SCIENCE & ENGINEERING"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "WHAT IS YOUR PREFERRED FIELD OF STUDY?" &&
                    el.ans === "SCIENCE & ENGINEERING"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT IS YOUR PREFERRED FIELD OF STUDY?"
                className="quality"
                value="ARTS"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "WHAT IS YOUR PREFERRED FIELD OF STUDY?" &&
                    el.ans === "ARTS"
                )}
              />
              <span className="radiobtn"></span>
            </span>
            ARTS
          </span>
        </li>

        <li>
          <span className="option">
            BUSINESS ADMINISTRATION
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT IS YOUR PREFERRED FIELD OF STUDY?"
                className="quality"
                value="BUSINESS ADMINISTRATION"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "WHAT IS YOUR PREFERRED FIELD OF STUDY?" &&
                    el.ans === "BUSINESS ADMINISTRATION"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            OTHERS
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT IS YOUR PREFERRED FIELD OF STUDY?"
                className="quality"
                value="OTHERS"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "WHAT IS YOUR PREFERRED FIELD OF STUDY?" &&
                    el.ans === "OTHERS"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>
      </ul>
      <button className="btn" onClick={() => setPage(page + 1)}>
        {" "}
        Complete{" "}
      </button>
      <button
        className="btn prevBtn"
        data-nav="prev"
        onClick={() => setPage(page - 1)}
      >
        {" "}
        Prev{" "}
      </button>
    </div>
  );
};

export default FormFour;
