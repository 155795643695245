const ToolGrowsBox = ({
  parentStyle,
  childStyle,
  imgSrc,
  title,
  descripiton,
}) => {
  return (
    <div className={parentStyle}>
      <div className={childStyle}>
        <img src={imgSrc} alt="tools1" />
        <h3>{title}</h3>
        <p>{descripiton}</p>
      </div>
    </div>
  );
};
export default ToolGrowsBox;
