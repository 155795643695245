import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetChCallHistory } from "../../../../Reducer/authSlice";
import { mediaUrl } from "../../../../services/network";
import PaginationTable from "../../../Tools/Pagination";
import { useNavigate } from "react-router-dom";

const CallListing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const selector = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const body = { page: currentPage, pageSize: 10 };
    dispatch(fetChCallHistory(body));
  }, [currentPage]);

  const formattedDateTime = (dateString) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Get the hours and minutes
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Convert hours to 12-hour format and determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // 12-hour clock, so 0 should be converted to 12

    // Format the date and time
    const formattedDate = `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`;
    const formattedTime = `${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };

  console.log(selector.callHistory, "callHistory");
  return (
    <section className="call_history">
      <div className="container">
        <h3>Call History</h3>
        <span
          onClick={() => navigate("/partner-dashboard")}
          className="bck_btn"
        >
          <i class="bi bi-arrow-left-short"></i>
        </span>
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table">
              {selector.callHistory &&
                selector.callHistory.length > 0 &&
                selector.callHistory[0].map((item) => {
                  return (
                    <tr>
                      <td>
                        <div className="img">
                          <img
                            src={
                              selector.auth.uid == item.user_id
                                ? `${mediaUrl + item.attendant_image}`
                                : `${mediaUrl}${item.dailler_image}`
                            }
                            width={"50px"}
                            alt="percent"
                          />
                          <h6>
                            {" "}
                            {selector.auth.uid == item.user_id
                              ? item.attendant_name
                              : item.dailler_name}
                          </h6>
                          <p>
                            {selector.auth.uid == item.user_id
                              ? "Outgoing Call"
                              : "Incoming Call"}
                          </p>
                        </div>
                      </td>

                      <td>
                        {" "}
                        <h6>
                          <span className="time">
                            {item.duration > 60
                              ? `${(item.duration / 60).toFixed(2)} Mins`
                              : `${item.duration} Sec`}
                          </span>
                        </h6>
                      </td>
                      <td>
                        {formattedDateTime(item.accept_time * 1000)}
                        <audio
                          preload="auto"
                          src={`${mediaUrl}/${item.recording}`}
                        ></audio>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>

          <PaginationTable
            rowsShowLimit={
              selector.callHistory.length > 0
                ? Math.ceil(selector.callHistory[1][0].total / 10)
                : 1
            }
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            style={"mt-4 text-end"}
          />
        </div>
      </div>
    </section>
  );
};

export default CallListing;
