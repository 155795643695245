import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../../Context";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { mediaUrl } from "../../../services/network";
import $ from "jquery";

const Find = ({ data, onHide, single }) => {
  const {
    callAccepted,
    fAgnt,
    connectId,
    CutTheCall,
    hasCamera,
    findChatFun,
    callingSms,
    onCall,
    setCallDecline,
    myConnect,
    setCallingSms,
    callDecline,
  } = useContext(SocketContext);
  // const [percentage, setPercentage] = useState(0);
  const [timer, setTimer] = useState(0);
  const [agents, setAgents] = useState([]);
  const navigate = useNavigate();
  let timeoutId = 0;
  // useEffect(() => {
  //   socket.on("userAvailability", (data) => {
  //     socket.off("userAvailability");
  //     toast.error(data.message);
  //     // socket.on("userAvailability");
  //   });
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPercentage((prevPercentage) =>
  //       prevPercentage < 100 ? prevPercentage + 1 : prevPercentage
  //     );
  //     if (!single) {
  //       setUserHandler(percentage + 1);
  //     }
  //   }, 1000);

  //   if (callAccepted) {
  //     navigate("/Calling");
  //   }

  //   if (fAgnt.length > 0 && agents.length === 0) {
  //     setAgents((prevAgents) => [...prevAgents, fAgnt[0]]);
  //   }
  //   if (percentage == 100) {
  //     CutTheCall();
  //     onHide();
  //     // window.location.reload();
  //   }
  // }, [percentage, agents]);

  // const setUserHandler = (p) => {
  //   if (p > 11 && fAgnt.length > 1) {
  //     setTimeout(() => {
  //       let obj = fAgnt && fAgnt[p - 11] ? fAgnt[p - 11] : null;
  //       if (obj !== null && obj !== undefined) {
  //         setAgents((prevAgents) => [...prevAgents, obj]);
  //       }
  //     }, 600);
  //   }
  // };

  useEffect(() => {
    if (!hasCamera) {
      if (!callAccepted) {
        timeoutId = setTimeout(() => {
          setTimer((prevTimer) =>
            prevTimer < 90
              ? callDecline === true && prevTimer < 60
                ? 60
                : prevTimer + 1
              : prevTimer
          );
        }, 1000);
      }
      if (timer > 60) {
        $(".cancelbtn").removeClass("col-12 text-center");
        $(".cancelbtn").addClass("col-6");
      }
      if (callAccepted) {
        onHide();
      }
      if (fAgnt.length > 0 && agents.length === 0) {
        setAgents((prevAgents) => [...prevAgents, fAgnt[0]]);
      }
      if (timer == 90 && !callDecline) {
        clearTimeout(timeoutId);
        myConnect(null);
        CutTheCall();
        setTimer(0);
      }
    }
  }, [timer, callDecline]);

  useEffect(() => {
    if (callDecline) {
      clearTimeout(timeoutId);
    } else {
      if (timer > 0) {
        clearTimeout(timeoutId);
      }
      setTimer(0);
    }
  }, [callDecline]);

  const handleSendMsg = () => {
    myConnect(null);
    let userData = {
      receiver_id: data.connection_user || data.uid,
      image: data.company_logo,
      company_name: data.company_name,
      online: data.online !== "" ? true : false,
      status: data.status,
      owner_name: data.owner_name || data.name,
      slug: data.slug,
      city: data.city,
      userRole: "branch",
    };
    findChatFun(userData);
    clearTimeout(timeoutId);
    setTimer(0);
    setCallDecline(false);
    setCallingSms("");
  };

  const concelCall = () => {
    clearTimeout(timeoutId);
    myConnect(null);
    CutTheCall();
    onHide();
    setTimer(0);
  };
  return (
    <div style={{ textAlign: "center" }} className="findUsr">
      {(connectId && connectId !== null) || connectId == 0 ? (
        <>
          <div className="allUsers">
            <img
              src="../../assets/images/modelLogo.png"
              className="modelLogo"
            />
            {!hasCamera && (
              <h6>
                {single == true
                  ? `${
                      (data.userRole === "branch" ||
                        data.userRole === "staff") &&
                      data.dynamic_image === 0
                        ? `${data.name}-${data.company_name}`
                        : data.name
                    }`
                  : "Finding Agents"}
              </h6>
            )}

            {!hasCamera && (
              <h4>
                <img
                  src={
                    callDecline
                      ? "../../assets/images/call_rejected.gif"
                      : "../../assets/images/calling_on_chownk.gif"
                  }
                />
                {callDecline ? "Call Declined" : "Calling"}
              </h4>
            )}

            {timer < 90 && !hasCamera && !callDecline && (
              <p>
                <i className="bi bi-stopwatch-fill"></i> {`${timer} sec`}
              </p>
            )}
          </div>
          <div className="footerbtn">
            <div className="row">
              <div className="col-12 text-center cancelbtn">
                <button
                  onClick={() => {
                    concelCall();
                  }}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
              </div>
              <div className="col-6">
                {" "}
                {timer > 60 && (
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      handleSendMsg();
                      onHide();
                    }}
                  >
                    <i className="bi bi-chat-square-dots-fill"></i>
                    {"Send Message"}
                  </button>
                )}
              </div>
            </div>
            {/* {timer > 60 && (
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.{" "}
          </p>
        )} */}
            {timer == 90 && !callDecline && (
              <h6>
                Note : User is not available for calls, Please send a message
              </h6>
            )}
            {onCall && <h6>{"You are already on call"}</h6>}
            {hasCamera && <h6>{hasCamera}</h6>}
            {callDecline && <h6>{callingSms}</h6>}
          </div>
        </>
      ) : (
        <div className={`spinner spinner2 text-center`}>
          <img src={`${mediaUrl}spinner.gif`} />
        </div>
      )}
    </div>
  );
};
export default Find;
