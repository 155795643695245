import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const InstantConnect = ({
  loginModelHandle,
  companyName,
  city,
  loading,
  selector,
  companyOwner,
  loginModel,
  setLoginModel,
  videoStatus,
  msgStatus,
}) => {
  return (
    <>
      <div className="maybe">
        {loading ? (
          <h3>
            <Skeleton />
          </h3>
        ) : (
          <h2>
            Instantly Connect with <br />
            <span>{`${companyName}, ${city}`}</span>
          </h2>
        )}

        <div className="row">
          <div className="col-6">
            <Link
              className={`Call ${selector && (companyOwner === selector?.uid || companyOwner === selector.parent_id) ? "disabled" : ""}`}
              onClick={() => {
                if (companyOwner !== selector?.uid) {
                  loginModelHandle("video");
                }
              }}
              data-tooltip-id="call"
            >
              {(msgStatus == true || msgStatus == "true") && videoStatus && (
                <b></b>
              )}
              <i className="bi bi-camera-video-fill"></i> Call
            </Link>
          </div>
          <div className="col-6">
            <Link
              className={`Message ${selector && (companyOwner === selector?.uid || companyOwner === selector.parent_id) ? "disabled" : ""}`}
              onClick={() => {
                if (companyOwner !== selector?.uid) {
                  loginModelHandle("chat");
                }
              }}
              data-tooltip-id="message"
            >
              {(msgStatus == true || msgStatus == "true") && <b></b>}
              <i className="bi bi-chat-left-dots-fill"></i> Message
            </Link>
          </div>
        </div>
        {!selector && (
          <p>
            Get a Instant ₹50 credit upon{" "}
            <Link onClick={() => setLoginModel(!loginModel)}>signing up!</Link>
          </p>
        )}
      </div>
    </>
  );
};

export default InstantConnect;
