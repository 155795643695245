import { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import MessageBox from "../../../../Tools/MessageBox";
import { SocketContext } from "../../../../../Context";
import axios from "axios";
import ImagePreview from "../../../../Tools/ImagePreview";
import { backEndUrl, baseUrl, mediaUrl } from "../../../../../services/network";
import { fetchChat } from "../../../../../services/api";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function Chatting({
  currentUser,
  page,
  setPage,
  searching,
  setSearching,
  msgId,
}) {
  const {
    offer,
    setOffer,
    socket,
    setImages,
    setCurrentChat,
    setFile,
    chatUser,
    call,
    expendMode,
    setChatUser,
  } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState("");
  const [box, setSelectionBox] = useState(false);
  const [messages, setMessages] = useState([]);
  const [showDefaultMessage, setDefaultMessage] = useState(false);
  const [sms, setSms] = useState("");
  const messageEl = useRef(null);
  const [prev, setPrev] = useState();
  const [hasbottomData, setHasBottomData] = useState(false);
  const [offerMessage, setOfferMessages] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const audio = new Audio("/assets/audio/incoming_msg.mp3");
  let timer;


  useEffect(()=>{
if(messageEl.current && page == 1){
  messageEl.current.scrollTop = messageEl.current.scrollHeight
}
  },[messages])

  useEffect(() => {
    // if (messageEl && page == 1) {
    //   messageEl.current.scrollTop = messageEl.current.scrollHeight
    //   messageEl.current.addEventListener("DOMNodeInserted", (event) => {
    //     const { currentTarget: target } = event;
    //     target.scroll({ top: target.scrollHeight + 5, behavior: "instant" });
    //   });
    // }
    getInitialChat(page);
    if (socket) {
      socket.on(
        `send_sms${selector.auth.id}_${currentUser?.connection_user}`,
        (data) => {
          socket.emit("update_chat_status", {
            user: currentUser?.connection_user,
            status: "read",
          });
          // capture
          setMessages((prevData) => [...prevData, data]);
          if (data.file_type === "image") {
            setImages((prev) => [...prev, data]);
          }
          if (data.file_type === "application") {
            setFile((prev) => [...prev, data]);
          }
          audio.play();
        }
      );
    }
    return () => {
      if (socket) {
        socket.off(
          `send_sms${selector.auth.id}_${currentUser?.connection_user}`
        );
      }
    };
  }, [currentUser, searching]);

  useEffect(() => {
    if (socket) {
      socket.on(
        `typing_${selector.auth.id}_${currentUser?.connection_user}`,
        () => {
          setIsTyping(true);
        }
      );

      socket.on(
        `stop_typing_${selector.auth.id}_${currentUser?.connection_user}`,
        () => {
          setIsTyping(false);
        }
      );
    }
    return () => {
      if (socket) {
        socket.off(
          `typing_${selector.auth.id}_${currentUser?.connection_user}`
        );
        socket.off(
          `stop_typing_${selector.auth.id}_${currentUser?.connection_user}`
        );
      }
    };
  }, [isTyping, currentUser]);

  useEffect(() => {
    if (socket) {
      socket.on(
        `update_sms_${selector.auth.id}_${currentUser?.connection_user}`,
        (data) => {
          const updatedItems = messages.map((item) =>
            item.sms_status === "sent" || item.sms_status == "delivered"
              ? { ...item, sms_status: data.status }
              : item
          );
          setMessages(updatedItems);
        }
      );
    }
    return () => {
      if (socket) {
        socket.off(
          `update_sms_${selector.auth.id}_${currentUser?.connection_user}`
        );
      }
    };
  }, [messages]);

  const getInitialChat = async (page) => {
    try {
      setIsLoading(true);
      const response = await getChatFunn(page);
      if (response && response.length > 0) {
        setIsLoading(false);

        setMessages(response);
        if (searching && msgId) {
          const highlightedElements =
            document.querySelectorAll(".highlightmsg");
          highlightedElements.forEach((elem) => {
            elem.classList.remove("highlightmsg");
          });

          const elem = document.getElementById(msgId);
          elem.scrollIntoView({ behavior: "instant" });
          elem.classList.add("highlightmsg");
        }
      } else {
        setIsLoading(false);
        setDefaultMessage(true);
      }
      if (response.length < 100) {
        sethasMore(false);
      } else {
        sethasMore(true);
      }
      if (searching && page > 1) {
        setHasBottomData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChatFunn = async (page) => {
    try {
      const body = {
        page: page,
        selectionId: currentUser.connection_user,
        id: selector.auth && selector.auth.id,
      };
      const response = await fetchChat(body);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = (data) => {
    socket.emit("stop_typing", { user: currentUser?.connection_user });
    socket.emit("send_sms", data);
    if (data.file_type === "image") {
      setImages((prev) => [...prev, data]);
    }
    if (data.file_type === "application") {
      setFile((prev) => [...prev, data]);
    }
    setCurrentChat((prevData) => [...prevData, data]);
    setMessages((prevData) => [...prevData, data]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentUser.status === "block") {
      toast.error("This user is blocked");
    } else {
      sendMsg();
    }
  };

  useEffect(() => {
    if (offer) {
      var date = Math.round(new Date().getTime() / 1000);
      let connect = call && call.from ? call.from : call?.callUserId;
      const receiver = expendMode ? connect : currentUser.connection_user;
      let dts = {
        first: messages.length > 0 ? false : true,
        sender_id: selector.auth.id,
        connection_id: currentUser.connection_id,
        receiver_id: receiver,
        type:
          sms !== "" && files !== ""
            ? "text_with_file"
            : files !== ""
            ? "file"
            : offer !== ""
            ? "application"
            : "text",
        file_type:
          selectedFile && selectedFile !== null
            ? selectedFile.type.split("/")[0]
            : "",
        file: files,
        offer: offer,
        sms_status: "sent",
        message: sms,
        unix_time: date,
      };
      setOfferMessages("");
      sendMessage(dts);
      setFiles("");
      setSelectedFile(null);
      setOffer("");
      setSms("");
      setDefaultMessage(false);
    }
  }, [offer]);

  const sendMsg = () => {
    if (sms.trim() !== "" || files) {
      var date = Math.round(new Date().getTime() / 1000);
      let dts = {
        first: messages.length > 0 ? false : true,
        sender_id: selector.auth.id,
        connection_id: currentUser.connection_id,
        receiver_id: currentUser.connection_user,
        type:
          sms !== "" && files !== ""
            ? "text_with_file"
            : files !== ""
            ? "file"
            : offer !== ""
            ? "application"
            : "text",
        file_type:
          selectedFile && selectedFile !== null
            ? selectedFile.type.split("/")[0]
            : "",
        file: files,
        offer: offer,
        sms_status: "sent",
        message: sms,
        unix_time: date,
      };

      setOfferMessages("");
      sendMessage(dts);
      setFiles("");
      setSelectedFile(null);
      setOffer("");
      setSms("");
      setDefaultMessage(false);
    }
  };

  const handleKeyDown = () => {
    if (!typing) {
      setTyping(true);
      let newArr = [...chatUser];
      newArr[0].smsCount = 0;
      newArr[0].read = 1;
      setChatUser(newArr);
      socket.emit("typing", { user: currentUser?.connection_user });
    }
  };

  const handleKeyUp = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setTyping(false);
      socket.emit("stop_typing", { user: currentUser?.connection_user });
    }, 2000);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectionBox(false);
    setSelectedFile(file);
    const token = localStorage.getItem("token");
    const headers = {
      "x-access-token": token,
    };
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(backEndUrl + "/apis/v3/uploadChat", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data && response.data.file) {
          setFiles(response.data.file);
        }
      })
      .catch((error) => {
        console.log("error zip", error);
      });
  };

  const onScroll = async () => {
    if (searching) {
      setSearching(false);
    }
    if (messageEl.current) {
      let { scrollTop, scrollHeight, clientHeight, offsetTop } =
        messageEl.current;
      if (prev !== scrollHeight) {
        setPrev(scrollHeight);
      }
      if (scrollTop == 0 && hasMore) {
        setPage((prev) => prev + 1);
        const response = await getChatFunn(page);
        if (response && response.length > 0 && page > 1) {
          setMessages([...response, ...messages]);
          messageEl.current.scrollTop = messageEl.current.scrollHeight - prev;
        }
        if (response && response.length < 100) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
      }
      if (
        clientHeight + scrollTop === scrollHeight &&
        page > 1 &&
        hasbottomData
      ) {
        setPage(page - 1);
        const response = await getChatFunn(page - 1);
        if (response.length > 0) {
          setMessages([...messages, ...response]);
          messageEl.current.scrollTop = messageEl.current.scrollHeight - prev;
        }
        if (response.length < 100) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        if (page === 1) {
          setHasBottomData(false);
        }
      }
    }
  };

  return (
    <div className="innchat">
      {offerMessage !== "" && (
        <div className="oft">
          <p>{offerMessage}</p>
          <ImCross
            onClick={() => {
              setOffer("");
              setOfferMessages("");
            }}
          />
        </div>
      )}

      <div className="dddd" ref={messageEl} onScroll={onScroll}>
        {messages.length <= 0 && showDefaultMessage && (
          <h6 className="mt-4">
            {`Ask (${
              currentUser.company_name || currentUser.name
            }) a question or Share your Requirements in detail`}
          </h6>
        )}
        {isLoading ? (
          <div className="lodar_chat">
            <Spinner />
          </div>
        ) : (
          <div className="row">
            {!isLoading &&
              messages &&
              messages.length > 0 &&
              messages.map((x, i) => {
                return (
                  <MessageBox
                    data={x}
                    right={
                      selector && selector.auth.id == x.sender_id ? true : false
                    }
                    style={
                      x.type === "default"
                        ? "col-12 inCenter"
                        : selector && selector.auth.id == x.sender_id
                        ? "col-12 inright text-end"
                        : "col-12 inleft"
                    }
                  />
                );
              })}
          </div>
        )}
      </div>
      {messages.length <= 0 && showDefaultMessage && (
        <>
          <form onSubmit={handleSubmit}>
            <div className="float_msg">
              <button
                value={"Hi , How are you ?"}
                onClick={(e) => {
                  setSms(e.target.value);
                }}
              >
                Hi , How are you ?
              </button>
              <button
                value={"Hi , can you help me in applying Visa in"}
                onClick={(e) => {
                  setSms(e.target.value);
                }}
              >
                Hi , can you help me in applying Visa in
              </button>
              <button
                value={"Hi , Do you think you Can help me applying Visa"}
                onClick={(e) => {
                  setSms(e.target.value);
                }}
              >
                Hi , Do you think you Can help me applying Visa
              </button>
            </div>
          </form>
        </>
      )}

      {offer !== "" && <p>New Offer Added</p>}
      <form className="intype" onSubmit={handleSubmit}>
        {isTyping && <img src={mediaUrl + "typing.gif"} />}
        <div className="snd text-center">
          <span
          // onClick={() => setSelectionBox(true)}
          >
            <i class="bi bi-paperclip"></i>
            <input
              type="file"
              accept="image/* , application/doc , application/docx , application/pdf , application/zip , application/rar"
              onChange={handleChange}
            />
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          id="messageText"
          value={sms}
          placeholder="Type a message...."
          onChange={(e) => setSms(e.target.value)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
        <button type="submit">
          <i className="bi bi-send-fill"></i>
        </button>
        <ImagePreview
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </form>
    </div>
  );
}

export default Chatting;
