import React, { useContext, useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { purchaseBoost } from "../../../../services/api";
import Example from "../../../Tools/ReactBoostrap";
import StudentOtp from "../../Student/Otp";
import PaymentOtpModal from "../../../Layouts/Model/paymentOtpModal";
import { phoneVerification, setOtpError } from "../../../../Reducer/authSlice";
import { SocketContext } from "../../../../Context";
import AddFundsModal from "../../../Layouts/Model/addFunds";
import { Roptions } from "../../../../data/data";

const PaymentPage = () => {
  const [totalCharge, setTotalCharges] = useState(0);
  const [otpShow, setOtpShow] = useState(false);
  const [payFromWallet, setPayFromWallet] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [otp, setOtp] = useState("");
  const [otpSubmit, setOtpSubmit] = useState(false);
  const { funds } = useSelector((state) => state.auth);
  const { addFundsModal, setAddFundsModal } = useContext(SocketContext);

  const dispatch = useDispatch();

  const [Razorpay, isLoaded] = useRazorpay();
  const location = useLocation();
  const { state } = location;
  const selector = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== null) {
      setTotalCharges(Math.round(state.total));
    }
  }, [location]);

  const handlePayment = () => {
    if (payFromWallet) {
      if (totalCharge > funds) {
        setAddFundsModal(!addFundsModal);
      } else {
        const body = {
          phone: user.phone,
          latitude: user.latitude,
          longitude: user.longitude,
          ip_address: user.ip_address,
          current_address: user?.current_address,
        };
        dispatch(phoneVerification(body));
        setOtpShow(!otpShow);
      }
    } else {
      let options = {
        key: Roptions.key,
        amount: Number(totalCharge) * 100,
        name: "Ask a Expert",
        description: "Purchase Description",
        image: "/your_logo.png",
        handler: async function (response) {
          if (response.razorpay_payment_id) {
            const body = {
              amount: totalCharge,
              boost_id: state.boostId,
              sp_id: state.sp,
              lid: state.city,
              user_id: selector.auth.uid,
              start_unix: state.boostData.from,
              end_unix: state.boostData.to,
              days: state.days,
              transaction_id: response.razorpay_payment_id,
              type: "bank",
              otp: "",
            };
            const res = await purchaseBoost(body);
            if (res.status === 200) {
              setOtpShow(!otpShow);
              navigate("/partner-dashboard");
            }
          }
        },
        prefill: {
          name: "",
          email: "harshil@razorpay.com",
        },
        notes: {
          address: "Hello World",
        },
        theme: {
          color: "#1DBF73",
        },
      };
      const rzpay = new Razorpay(options);
      rzpay.open();
    }
  };

  const payFromWalletFun = async () => {
    try {
      const body = {
        amount: totalCharge,
        boost_id: state.boostId,
        sp_id: state.sp,
        user_id: selector.auth.uid,
        start_unix: state.boostData.from,
        end_unix: state.boostData.to,
        days: state.days,
        lid: state.city,
        transaction_id: "",
        type: "wallet",
        otp: otp,
      };
      const res = await purchaseBoost(body);
      if (res.status === 200) {
        setOtpSubmit(false);
        setOtp("");
        dispatch(setOtpError(""));
        navigate("/partner-dashboard");
      }
    } catch (error) {
      dispatch(setOtpError(error.response.data.message));
    }
  };

  // useEffect(() => {
  //   if (otpSubmit) {
  //     payFromWalletFun();
  //   }
  // }, [otpSubmit]);
  return (
    <section className="payment">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="payment_left">
              <div className="set">
                <h4>What we Provide</h4>
                <p>
                  With the help of our extensive immigration consultation
                  platform, take advantage of worldwide prospects. We enable you
                  to smoothly manage the complexity of immigration by providing
                  you with individualized information on visa requirements and
                  expert advice on visa applications. Our platform provides
                  customized solutions to make sure your trip to a new location
                  is easy and hassle-free. Count on us to deliver dependable
                  assistance at every turn, freeing you up to concentrate on
                  accomplishing your aspirations of traveling abroad.
                </p>
              </div>

              <h5>WHY CHOOSE US </h5>
              <p>
                At Chownk, we offer a safe space for individuals and immigration
                consultants to interact. Here, the immigration agents may
                provide individualized visa services, doing away with the
                necessity for repeated trips and the potential for fraud.
              </p>
              <h5>What sets us apart </h5>
              <p>
                1.Expert Network 2.Personalized Assistance 3.Narrowing down your
                search 4.Instant Chat & Video Call 5.Secure Document Sharing
              </p>
              <h5>JOIN NOW </h5>
              <p>
                Enroll right away, and let Chownk assist you in starting a new
                journey.
              </p>

              <a href="#" className="joinnow">
                Join Now <i className="bi bi-arrow-right"></i>
              </a>

              <h6 className="mb-0">
                Need help? <a href="#">Contact Us</a>
              </h6>
            </div>
          </div>

          <div className="col-md-6">
            <div className="payment_right">
              <h2>Payment Summary</h2>
              <h3>Payment</h3>
              <h6 className="mb-3">
                ({`Validity ${state.days} Days`}){" "}
                <span className="float-end">{`₹ ${state.total}`}</span>{" "}
              </h6>
              <h6 className="mb-3">
                Boost for
                <span className="float-end">{`${state.boost_type}`}</span>{" "}
              </h6>
              <h6 className="mb-3">
                from
                <span className="float-end">{`${state.boostfrom}`}</span>{" "}
              </h6>
              <h6 className="mb-3">
                to
                <span className="float-end">{`${state.boostto}`}</span>{" "}
              </h6>
              {/* <p>(Includes : 5 Top Rated</p>
              <p>
                Consultants/Training Institutes , Arrange Video/Chat
                Consultation , Avoid Getting into Frauds , Available 24*7)
              </p> */}
              {/* <h6>
                Taxes <span className="float-end">{`INR ${gstAmt}`}</span>{" "}
              </h6>
              <p>(Goods & Service Tax)</p> */}
              <h6 className="total">
                Total <span className="float-end">{`₹ ${totalCharge}`}</span>
              </h6>
              <input
                type="checkbox"
                onChange={(e) => setPayFromWallet(!payFromWallet)}
                checked={payFromWallet}
              />
              {`Pay from Wallet(${selector && selector.funds})`}

              <button type="button" className="confirm" onClick={handlePayment}>
                Confirm & Pay
              </button>
              <p className="text-center">
                <b>SSL Secure Payment</b>
              </p>
              <p className="text-center mb-0">
                {`You will be charged ₹ ${totalCharge} Total amount`}
                {/* <br />
                includes currency conversion fees. */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Example
        show={otpShow}
        onHide={() => setOtpShow(!otpShow)}
        component={
          <PaymentOtpModal
            otp={otp}
            setOtp={setOtp}
            otpSubmit={otpSubmit}
            setOtpSubmit={setOtpSubmit}
            onHide={() => setOtpShow(!otpShow)}
            onSubmitForm={payFromWalletFun}
          />
        }
        style={"loginmodal"}
      />
      <Example
        show={addFundsModal}
        onHide={() => setAddFundsModal(!addFundsModal)}
        component={
          <AddFundsModal
            onHide={() => setAddFundsModal(!addFundsModal)}
            remainingBalance={totalCharge}
          />
        }
        style={"loginmodal add_money_wallet"}
      />
    </section>
  );
};

export default PaymentPage;
