import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../Context";
import Example from "../ReactBoostrap";
import AddFundsModal from "../../Layouts/Model/addFunds";
import FindUser from "../FindUser";
import { useSelector } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
let tl = true;
const DeclineSms = () => {
  const {
    declineCase,
    setDeclineCase,
    lastCall,
    callTo,
    isPlaying,
    setIsPlaying,
    callAccepted,
    access,
  } = useContext(SocketContext);
  const [userModel, setUserModel] = useState(false);
  const [userCallModel, setUserCallModel] = useState(false);
  const [content, setContent] = useState(null);
  const { funds, call_min, auth } = useSelector((state) => state.auth);
  useEffect(() => {
    getContent();
    tl = true;
  }, [declineCase, userCallModel, userModel,isPlaying]);

  const callAgain = () => {
    if(!isPlaying){
    setDeclineCase(null);
    if (tl) {
      if (funds >= call_min || lastCall.permission === 1) {
        setUserCallModel(true);
        let p = lastCall.permission ? lastCall.permission : 0;
        callTo(lastCall.id, true, lastCall.permission);
      } else {
        setUserCallModel(!userCallModel);
      }
    }
    tl = false;
  }
  };

  const reconnect = 
    isPlaying && isPlaying == true ? (
      <div className="connect_loader"><CountdownCircleTimer
        isPlaying={isPlaying}
        duration={10}
        colors="#A30000"
        size={25}
        initialRemainingTime={10}
        strokeWidth={4}
        trailStrokeWidth={4}
        children={({ remainingTime }) => {
          const seconds = remainingTime % 60;
          return `${seconds}`;
        }}
        onComplete={() => {
          setIsPlaying(false);
          return { shouldRepeat: false, delay: 1.5 };
        }}
      /> Re-connect</div>) : "Re-connect";
    
  

  const getContent = () => {
    let newContent;
    switch (declineCase) {
      case "1":
        newContent = (
          <>
            <h6>The call is disconnected due to low internet connectivity</h6>
            <div className="row">
              {lastCall &&
                lastCall.id &&
                (auth?.uid === lastCall.started_by ||
                  lastCall.permission === 1) && (
                  <div className="col-6">
                    <button className="yes" onClick={() => callAgain()}>
                      {reconnect}
                    </button>
                  </div>
                )}
              <div
                className={
                  auth?.uid === lastCall?.started_by || lastCall?.permission === 1
                    ? "col-6"
                    : "col-12"
                }
              >
                <button onClick={() => setDeclineCase(null)}>Cancel</button>
              </div>
            </div>
          </>
        );
        break;
      case "2":
        newContent = (
          <>
            <h6>The call is disconnected due to low internet connectivity</h6>
            <div className="row">
              <div className="col-12">
                <button onClick={() => setDeclineCase(null)}>Cancel</button>
              </div>
            </div>
          </>
        );
        break;
      case "3":
        newContent = (
          <>
            <h6>
              The call is disconnected due to low balance. To connect again
              Please
            </h6>
            <div className="row">
              <div className="col-6">
                <button
                  className="yes"
                  onClick={() => setUserModel(!userModel)}
                >
                  Add Funds {userModel}
                </button>
              </div>
              <div className="col-6">
                <button onClick={() => setDeclineCase(null)}>Cancel</button>
              </div>
            </div>
          </>
        );
        break;
      case "4":
        newContent = (
          <>
            <h6>The call is disconnected due to low balance</h6>
            <div className="row">
              <div className="col-12">
                <button onClick={() => setDeclineCase(null)}>Cancel</button>
              </div>
            </div>
          </>
        );
        break;
      case "5":
        newContent = (
          <>
            <h6>The call is disconnected</h6>
            <div className="row">
              {lastCall &&
                lastCall.id &&
                (auth?.uid === lastCall.started_by ||
                  lastCall.permission === 1) && (
                  <div className="col-6">
                    <button className="yes" onClick={() => callAgain()}>
                      {reconnect}
                    </button>
                  </div>
                )}
              <div
                className={
                  auth?.uid === lastCall?.started_by || lastCall?.permission === 1
                    ? "col-6"
                    : "col-12"
                }
              >
                <button onClick={() => setDeclineCase(null)}>Cancel</button>
              </div>
            </div>
          </>
        );
        break;
      case "6":
        newContent = (
          <>
            <h6>
              {lastCall && `The call is disconnected by `}
              {lastCall && <h3>{lastCall.name}</h3>}
            </h6>
            <div className="row">
              {lastCall &&
                lastCall.id &&
                (auth?.uid === lastCall.started_by ||
                  lastCall.permission === 1) && (
                  <div className="col-6">
                    <button className="yes" onClick={() => callAgain()}>
                      {reconnect}
                    </button>
                  </div>
                )}
              <div
                className={
                  auth?.uid === lastCall?.started_by || lastCall?.permission === 1
                    ? "col-6"
                    : "col-12"
                }
              >
                <button onClick={() => setDeclineCase(null)}>Cancel</button>
              </div>
            </div>
          </>
        );
        break;
      default:
        break;
    }
    setContent(newContent);
  };

  return (
    <>
      {content !== null && content && (
        <div className="decline_sms">
          <div className="insms">
            <img
              src={"../../assets/images/modelLogo.png"}
              className="modelLogo"
            />
            {content}
          </div>
        </div>
      )}
      <Example
        show={userCallModel}
        style={"call_user"}
        onHide={() => setUserCallModel(!userCallModel)}
        component={
          <FindUser
            show={userCallModel}
            data={lastCall}
            single={true}
            onHide={() => setUserCallModel(!userCallModel)}
          />
        }
        backDrop={"static"}
      />
      <Example
        show={userModel}
        style={"loginmodal add_money_wallet"}
        onHide={() => {
          setUserModel(!userModel);
          setDeclineCase(null);
        }}
        component={<AddFundsModal onHide={() => setUserModel(!userModel)} />}
        backDrop={"static"}
      />
    </>
  );
};

export default DeclineSms;
