import React from "react";

const FormThree = ({ page, setPage, data, onChange }) => {
  return (
    <div className="form-page">
      <h2>
        WHAT LEVEL OF <b>EDUCATION</b> ARE YOU PLANNING TO STUDY?
      </h2>
      <p>
        It is a long established fact that a reader will be distracted by the
        readable
      </p>
      <ul>
        <li>
          <span className="option">
            DIPLOMA (FOR 10TH PASSED STUDENTS)
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?"
                className="quality"
                value="DIPLOMA (fOR 10TH PASSED STUDENTS)"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?" &&
                    el.ans === "DIPLOMA (fOR 10TH PASSED STUDENTS)"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            GRADUATE (FOR 12TH PASSED STUDENTS)
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?"
                className="quality"
                value="GRADUATE (FOR 12TH PASSED STUDENTS)"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?" &&
                    el.ans === "GRADUATE (FOR 12TH PASSED STUDENTS)"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            POSTGRADUATE (fOR GRADUATE STUDENTS)
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?"
                className="quality"
                value="POSTGRADUATE (fOR GRADUATE STUDENTS)"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?" &&
                    el.ans === "POSTGRADUATE (fOR GRADUATE STUDENTS)"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            PHD
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?"
                className="quality"
                value="PHD"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?" &&
                    el.ans === "PHD"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            OTHERS
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?"
                className="quality"
                value="OTHERS"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques ===
                      "WHAT LEVEL OF EDUCATION ARE YOU PLANNING TO STUDY?" &&
                    el.ans === "OTHERS"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>
      </ul>
      <button className="btn" data-nav="next" onClick={() => setPage(page + 1)}>
        {" "}
        Next{" "}
      </button>
      <button
        className="btn prevBtn"
        data-nav="prev"
        onClick={() => setPage(page - 1)}
      >
        {" "}
        Prev{" "}
      </button>
    </div>
  );
};

export default FormThree;
