import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogsApi } from "../../../../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { blogsMediaUrl } from "../../../../../services/network";
import { toast } from "react-toastify";

const BlogsByCat = ({ categories, handleUserNavigate }) => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const observer = useRef();

  const unixtoDate = (unix_date) => {
    let date = new Date(unix_date * 1000);
    let formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  useEffect(() => {
    getAllBlogsFun();
  }, [page]);

  const getAllBlogsFun = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const body = { limit: 9, page: page };
      const response = await getAllBlogsApi(body);
      if (response.data.status) {
        const newBlogs = response.data.data;
        setAllBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]);
        setHasMore(newBlogs.length === 9); // Check if there are more blogs to load
      }
    } catch (error) {
      toast.error("Error fetching blogs");
    } finally {
      setLoading(false);
    }
  };

  const lastBlogElementRef = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      {categories && categories.length > 0 && (
        <ul className="mb-3 mt-5" id="myTab" role="tablist">
          {categories.map((item, index) => (
            <li className="" role="presentation" key={index}>
              <Link to={`/blogs/category/${item.slug.toLowerCase()}`}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
      <div className="all_blogs_list">
        <div className="row">
          {allBlogs &&
            allBlogs.length > 0 &&
            allBlogs.map((item, index) => {
              if (allBlogs.length === index + 1) {
                return (
                  <div
                    className="col-sm-6 col-lg-3 mb-3"
                    ref={lastBlogElementRef}
                    key={item.id}
                  >
                    <div className="img">
                      <img
                        src={`${blogsMediaUrl}${item.image}`}
                        alt={`${item.title}-Chownk`}
                        className="w-100"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/blogs/${item.slug}`)}
                      />
                      <p className="h5">
                        <span>
                          <img
                            src={`${blogsMediaUrl}${item.author_image}`}
                            alt={`${item.author_name}-author of ${item.title}-Chownk`}
                            className="w-100"
                          />
                        </span>{" "}
                        {item.author_name}
                      </p>
                      <p className="h6">{unixtoDate(item.unix_time)}</p>
                    </div>
                    <h2
                      onClick={() => {
                        handleUserNavigate(
                          "navigate",
                          `/blogs/${item.slug}`,
                          "single blog"
                        );
                        navigate(`/blogs/${item.slug}`);
                      }}
                    >
                      {item.title}
                    </h2>
                    <p>{item.description}</p>
                  </div>
                );
              } else {
                return (
                  <div className="col-sm-6 col-lg-3 mb-3" key={item.id}>
                    <div className="img">
                      <img
                        src={`${blogsMediaUrl}${item.image}`}
                        alt={`${item.title}-Chownk`}
                        className="w-100"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/blogs/${item.slug}`)}
                      />
                      <p className="h5">
                        <span>
                          <img
                            src={`${blogsMediaUrl}${item.author_image}`}
                            alt={`${item.author_name}-author of ${item.title}-Chownk`}
                            className="w-100"
                          />
                        </span>{" "}
                        {item.author_name}
                      </p>
                      <p className="h6">{unixtoDate(item.unix_time)}</p>
                    </div>
                    <h2
                      onClick={() => {
                        handleUserNavigate(
                          "navigate",
                          `/blogs/${item.slug}`,
                          "single blog"
                        );
                        navigate(`/blogs/${item.slug}`);
                      }}
                    >
                      {item.title}
                    </h2>
                    <p>{item.description}</p>
                  </div>
                );
              }
            })}
        </div>
        {loading && <p>Loading...</p>}
      </div>
    </>
  );
};

export default BlogsByCat;
