import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HelpBox from "../../../Tools/HelpBox";
import SearchBarWithIcon from "../../../Tools/SearchBarWithIcon";
import QuestionAnswers from "../../../Tools/QuestionAnswes";
import { helpFun, singleHelpFun } from "../../../../Reducer/helpSlice";
import StudentHeader from "../../../Layouts/Student/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import StudentFooter from "../../../Layouts/Student/Footer";
import $ from "jquery";
import HelpHead from "../Help/helpHead";
import Meta from "../../Common/Meta";
import moment from "moment";
import { saveUserNavigate } from "../../../../services/api";

const SingleHelpComponent = ({ type }) => {
  const { id, user, title } = useParams();
  const [searchData, setSearchData] = React.useState("");
  const { singleHelp } = useSelector((state) => state.help);
  const selector = useSelector((state) => state.auth);
  const guestId = localStorage.getItem("guestId");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(singleHelpFun({ id: id }));
    $("#fc_frame").removeClass("d-none");

    return () => {
      $("#fc_frame").addClass("d-none");
    };
  }, [id]);

  const openHelpBox = () => {
    handleUserNavigate("action", "Need more assistance", "More help");
    if (window.fcWidget) {
      window.fcWidget.open();
    }
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      helpFun({
        type: user === "partners" ? "business" : "user",
        search: searchData,
      })
    );
    navigate(`${user === "partners" ? "/partners/help" : "/customers/help"}`, {
      state: searchData,
    });
    handleUserNavigate("action", searchData, "search help");
  };

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: selector.auth ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(singleHelp, "SINLE");
  return (
    <>
      <Meta
        title={`${
          singleHelp && singleHelp.current?.question
        } | ${user} - FAQ - Chownk`}
        description={`Explore ${
          singleHelp && singleHelp.current?.question
        } in our comprehensive FAQ guide for partners. Simplify your process and ensure compliance with our detailed checklist.
        `}
        singleHelpSchema={
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "@id": "https://chownk.com/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Chownk",
                  item: {
                    "@type": "WebSite",
                    "@id": "https://chownk.com/",
                  },
                },

                {
                  "@type": "ListItem",
                  position: 2,
                  name: `${user === "partners" ? "Partner" : "Customer"}`,
                  item: {
                    "@type": "WebPage",
                    "@id": `https://chownk.com/${user}/help`,
                  },
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: singleHelp.current?.cat_name,
                  item: {
                    "@type": "WebPage",
                    "@id": `https://chownk.com/${user}/${singleHelp.current?.cat_name}`,
                  },
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: singleHelp.current?.question,
                  item: {
                    "@type": "WebPage",
                    "@id": `https://chownk.com/${user}/${singleHelp.current?.question}`,
                  },
                },
              ],
            })}
          </script>
        }
      />
      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />
      <HelpHead
        searchData={searchData}
        searchFunction={(e) =>
          dispatch(
            helpFun({
              type: user === "partners" ? "business" : "user",
              search: e.target.value,
            })
          )
        }
        setSearchData={setSearchData}
        searchSubmit={searchSubmit}
        user={user}
      />
      <section class="help_page_single">
        <div class="container">
          <nav aria-label="Breadcrumb" className="Breadcrumb">
            <ul>
              <li>
                <Link to={`/${user}/help`}>{"Help & Support /"}</Link>
              </li>
              <li>
                <Link to={`/${user}/help`}>
                  {user === "partners" ? "Partner /" : "Customer /"}
                </Link>
              </li>
              <li>
                <Link to={`/${user}/help/${title}`}>
                  {singleHelp.current?.cat_name}
                </Link>
              </li>
              <li>
                <Link>{singleHelp.current?.question}</Link>
              </li>
            </ul>
          </nav>

          <div class="row">
            <div class="col-lg-8  pe-3 pe-lg-5 mb-4">
              <h2>{singleHelp.current?.question}</h2>
              {singleHelp.current &&
                singleHelp.current.youtube_ref &&
                singleHelp.current.youtube_ref !== "" && (
                  <iframe
                    width={"100%"}
                    height={"300px"}
                    src={singleHelp.current.youtube_ref}
                  ></iframe>
                )}
              <div
                className="foremost"
                dangerouslySetInnerHTML={{
                  __html: singleHelp.current?.answers,
                }}
              ></div>

              <p>
                {singleHelp &&
                  singleHelp.previous &&
                  Object.keys(singleHelp.previous).length > 0 && (
                    <Link
                      to={`/${
                        singleHelp.previous?.cat_name == "user"
                          ? "customers"
                          : "partners"
                      }/help/${singleHelp.previous?.slug.toLowerCase()}/${singleHelp.previous?.question_slug.toLowerCase()}`}
                      onClick={() =>
                        handleUserNavigate(
                          "navigate",
                          `/${
                            singleHelp.previous?.cat_name == "user"
                              ? "customers"
                              : "partners"
                          }/help/${singleHelp.previous?.slug}/${
                            singleHelp.previous?.question_slug
                          }`,
                          "Previous Faq"
                        )
                      }
                    >
                      <i class="bi bi-arrow-left"></i> Previous FAQ
                    </Link>
                  )}
                {singleHelp &&
                  singleHelp.next &&
                  Object.keys(singleHelp.next).length > 0 && (
                    <Link
                      to={`/${
                        singleHelp.next?.cat_name == "user"
                          ? "customers"
                          : "partners"
                      }/help/${singleHelp.next?.slug.toLowerCase()}/${singleHelp.next?.question_slug.toLowerCase()}`}
                      class="float-end"
                      onClick={() =>
                        handleUserNavigate(
                          "navigate",
                          `/${
                            singleHelp.next?.cat_name == "user"
                              ? "customers"
                              : "partners"
                          }/help/${singleHelp.next?.slug}/${
                            singleHelp.next?.question_slug
                          }`,
                          "Next Faq"
                        )
                      }
                    >
                      Next FAQ <i class="bi bi-arrow-right"></i>
                    </Link>
                  )}
              </p>
              <div class="set contactus">
                <div class="row">
                  <div class="col-8">
                    <div class="img">
                      <img
                        src="../../../../assets/images/help_desk.png"
                        alt="helpiconK_1"
                      />
                    </div>
                    <h2>Do you need additional assistance?</h2>
                    <p class="mb-0">
                      Our friendly support team is here to help.
                    </p>
                  </div>
                  <div class="col-4 text-end">
                    <Link onClick={openHelpBox} class="seeall">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <h2>Related FAQs</h2>
              <ul class="Related">
                {singleHelp.related &&
                  singleHelp.related.length > 0 &&
                  singleHelp.related.map((item, i) => (
                    <li>
                      <Link
                        to={`/${user}/help/${title.replaceAll(" ", "-")}/${
                          item.question_slug
                        }`}
                        onClick={() =>
                          handleUserNavigate(
                            "navigate",
                            `/${user}/help/${title.replaceAll(" ", "-")}/${
                              item.question_slug
                            }`,
                            "Related Faq"
                          )
                        }
                      >
                        {item.question}
                        <img
                          src="../../../../../assets/images/arrowright_help.png"
                          alt="arrowright_help"
                        />
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};
export default SingleHelpComponent;
