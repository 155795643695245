import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Example from "../ReactBoostrap";
import CommonPopUp from "../../Layouts/Model/commonPopUp";
import { mediaUrl } from "../../../services/network";
import { SocketContext } from "../../../Context";
import { Tooltip } from "react-tooltip";
import DashboardWallet from "../../Layouts/Agent/DashboardHeader/wallet";
import NotificationBox from "../NotificationBox";

const BecomeConsultant = ({
  profileOption,
  agentOption,
  heading,
  bellIcon,
  messageIcon,
  userImg,
  path,
  notification,
  authenitication,
  role,
  notiCount,
  readNotification,
  handleUserNavigate,
}) => {
  const colors = ["white"];
  const random = Math.floor(Math.random() * colors.length);
  const { owner_name } = useSelector((state) => state.auth?.auth);
  const [commonShow, setCommonShow] = React.useState(false);
  const { setAllChats, screenWidth } = useContext(SocketContext);
  const navigate = useNavigate();

  const { smsCount, logout } = useContext(SocketContext);

  const logoutFunction = () => {
    handleUserNavigate("action", "logout", "logout");
    logout();
    setAllChats([]);
  };

  return (
    <>
      <ul className=" after_login" style={{ display: "block" }}>
        <li className="btn-group app_download">
          <Link className="dropdown-toggle" to={"/download-app"}>
            <img
              src="../../../assets/images/chownk-download-mobile-app.png"
              alt="Download CHOWNK mobile app"
            />
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            data-popper-placement="bottom-end"
            onClick={() => {
              handleUserNavigate("navigate", "/download-app", "download app");
              navigate("/download-app");
            }}
          >
            <p>Download Mobile app</p>
            <p>
              Receive instant notifications, engage in real- time chat, and make
              calls effortlessly
            </p>
            <img src="../../../assets/images/mobst.png" alt="mobst" />
          </ul>
        </li>
        <li className="btn-group" onClick={() => navigate("/demo")}>
          <Link className="take_a_tour">Take a tour</Link>
        </li>
        {authenitication ? (
          <>
            {role && (role === 1 || role === 7) && (
              <li className="bcm">
                <Link
                  to={"/become-consultant"}
                  onClick={() =>
                    handleUserNavigate(
                      "navigate",
                      "/become-consultant",
                      "Become Consultant"
                    )
                  }
                >
                  <img
                    src={`../../../../assets/images/become-consultant-chownk.svg`}
                    alt="Become a consultant at CHOWNK"
                  />
                  <span> {heading}</span>
                </Link>
              </li>
            )}
            <DashboardWallet />
            {role && role === 6 && screenWidth > 767 && (
              <li className="btn-group ms-3">
                <>
                  <span
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={`../../../assets/images/partner_W.svg`} />
                    <Link to={"/become-consultant"}>{heading}</Link>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {/* <h4>{"Account"}</h4> */}
                    {agentOption &&
                      agentOption.length > 0 &&
                      agentOption.map((item, index) => {
                        return (
                          <li className={item.style && item.style} key={index}>
                            <Link
                              to={!item.required && item.path}
                              onClick={() => {
                                if (item.required) {
                                  setCommonShow(true);
                                } else {
                                  handleUserNavigate(
                                    "navigate",
                                    item.path,
                                    "navigate"
                                  );
                                }
                              }}
                            >
                              <img src={item.imageSrc} alt="logo" />{" "}
                              {item.label}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </>
              </li>
            )}
            {role && role === 5 && screenWidth > 767 && (
              <li className="btn-group ms-3">
                <>
                  <span
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={`../../../assets/images/partner_W.svg`} />
                    {/* <Link>{heading}</Link> */}
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end account">
                    {agentOption &&
                      agentOption.length > 0 &&
                      agentOption.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={!item.required && item.path}
                              onClick={() => {
                                if (item.required) {
                                  setCommonShow(true);
                                } else {
                                  handleUserNavigate(
                                    "navigate",
                                    item.path,
                                    "navigate"
                                  );
                                }
                              }}
                            >
                              <img src={item.imageSrc} alt="logo" />{" "}
                              {item.label}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </>
              </li>
            )}
          </>
        ) : (
          <>
            <li>
              <Link className="dropdown-toggle" to={"/"}>
                <img src={`${mediaUrl}Applications.svg`} />
                {"Applications"}
              </Link>
            </li>
            <li>
              <img src={`${mediaUrl}Login.png`} alt="Login icon - CHOWNK" />
              <Link to={path}>{heading}</Link>
            </li>
          </>
        )}
        <li className="btn-group" onMouseEnter={() => readNotification()}>
          <Link className="p-0">
            <img
              src={`../../../assets/images/${
                bellIcon ? bellIcon : "hedic1black.png"
              }`}
              alt="logo"
            />
            {notiCount > 0 && <span></span>}
          </Link>
          {notification && notification.length > 0 && (
            <ul className="dropdown-menu dropdown-menu-end noti">
              {notification.slice(0, 5).map((item, index) => (
                <li key={index}>
                  <NotificationBox
                    type={item.type}
                    description={item.description}
                    message={item.title}
                    time={new Date(item.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  />
                </li>
              ))}
              <li>
                <Link
                  to={"/notification"}
                  onClick={() =>
                    handleUserNavigate(
                      "navigate",
                      "/notification",
                      "all notifications"
                    )
                  }
                >
                  View All
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li className="ms-3 msg">
          <Link
            to="/messages"
            className="p-0"
            data-tooltip-id="messages"
            onClick={() =>
              handleUserNavigate(
                "navigate",
                "/messages",
                "navigate to messages"
              )
            }
          >
            <img
              src={`../../../assets/images/${
                messageIcon ? messageIcon : "hedic2black.png"
              }`}
              alt="logo"
            />
            {smsCount > 0 && <span className="count">{smsCount}</span>}
          </Link>
        </li>
        {screenWidth > 767 && (
          <li className="btn-group ms-3">
            <button
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {userImg ? (
                <span style={{ backgroundImage: `url(${userImg})` }}></span>
              ) : (
                <span
                  style={{
                    background: colors[random],
                    color: "white",
                  }}
                >
                  {owner_name &&
                  owner_name !== undefined &&
                  owner_name?.split(" ").length > 1
                    ? owner_name?.split(" ")[0].substr(0, 1) +
                      owner_name?.split(" ")[1].substr(0, 1)
                    : owner_name?.split(" ")[0].substr(0, 1)}
                </span>
              )}
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              {profileOption.length > 0 &&
                profileOption.map((item, index) => {
                  if (item.options && item.options.length > 0) {
                    return (
                      <li className={item.style ? item.style : ""} key={index}>
                        <h4>
                          {"Balance :"}
                          {item.heading}
                        </h4>
                        <ul>
                          {item.options.map((_, i) => {
                            return (
                              <li className={_.style ? _.style : ""} key={i}>
                                <Link
                                  to={_.path ? _.path : ""}
                                  onClick={() => {
                                    handleUserNavigate(
                                      "navigate",
                                      _.path,
                                      "navigate"
                                    );
                                    if (_.onClick) {
                                      _.onClick();
                                    }
                                  }}
                                >
                                  <img src={_.imageSrc} alt="logo" /> {_.label}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        className={item.style ? item.style : ""}
                        key={index}
                        onClick={() => {
                          if (item.click) {
                            item.click();
                          }
                        }}
                      >
                        <Link
                          to={!item.required && item.path ? item.path : ""}
                          onClick={() => {
                            if (item.required) {
                              setCommonShow(true);
                            } else {
                              handleUserNavigate(
                                "navigate",
                                item.path,
                                "navigate from header"
                              );
                            }
                          }}
                        >
                          <img src={item.imageSrc} alt="logo" /> {item.label}
                        </Link>
                      </li>
                    );
                  }
                })}

              <li onClick={logoutFunction}>
                <Link>
                  <img src={"../../../assets/images/logout.png"} alt="logo" />{" "}
                  {"Logout"}
                </Link>
              </li>
            </ul>
          </li>
        )}
      </ul>
      <Example
        show={commonShow}
        onHide={() => setCommonShow(!commonShow)}
        component={
          <CommonPopUp
            parentStyle={"thank_you_cont"}
            childStyle={"thank_you_img"}
            imgSrc={"../../../assets/images/Hourglass.png"}
            title={"More Information"}
            description={
              "We still require more information from your side to get your account verified."
            }
            linkTitle={"Add information"}
            onHide={() => setCommonShow(!commonShow)}
          />
        }
        backDrop={"static"}
        style={"thank_you_pop"}
      />
      <Tooltip id="notification" content="Notification" />
      <Tooltip id="messages" content="Messages" />
    </>
  );
};
export default BecomeConsultant;
