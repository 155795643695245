import { AgChartsReact } from "ag-charts-react";
import { useEffect, useState } from "react";
const ViewPerPageGraph = ({ data }) => {
  const tst = data.graph;
  // tst.pop();

  const dtss = {
    title: {
      text: "Users views",
    },
    data: tst,
    series: [
      {
        type: "area",
        xKey: "date",
        yKey: "search",
        yName: "Searching",
      },
      {
        type: "area",
        xKey: "date",
        yKey: "reffer",
        yName: "Reffer",
      },
      {
        type: "area",
        xKey: "date",
        yKey: "direct",
        yName: "Direct",
      },
      {
        type: "area",
        xKey: "date",
        yKey: "boost",
        yName: "Boost",
      },
    ],
  };
  const [options, setOptions] = useState(dtss);
  useEffect(() => {
    setOptions(dtss);
  }, [data.graph]);

  return (
    <div>
      <div className="wap views_per">
        <h4>
          <img src="../../assets/images/Staff_Members.png" alt="Call_History" />{" "}
          Views by Source
          {/* <span>
            <select>
              <option>Unique Views</option>
              <option>Unique Views</option>
            </select>
            <select>
              <option>All Sections</option>
              <option>All Sections</option>
            </select>
          </span> */}
        </h4>
        <p className="Lorem">
          Analyze the total views from different sources with our comprehensive
          graph.
        </p>
        {/* <img
          src="../../assets/images/views_per.png"
          alt="views_per"
          className="w-100 py-3"
        /> */}
        <AgChartsReact options={options} className="w-100 py-3" />
        {/* <ul className="mt-3">
          <li>
            <input type="checkbox" /> Desktop <span>258</span>
          </li>
          <li>
            <input type="checkbox" /> Mobile <span>761</span>
          </li>
        </ul> */}
      </div>
    </div>
  );
};
export default ViewPerPageGraph;
