import React from "react";
const RowImage = ({
  parentStyle,
  labelStyle,
  inputStyle,
  label,
  labelDescription,
  childStyle,
  name,
  event,
  errors,
  image,
  fileDisabled,
  accept,
}) => {
  const [file, setFile] = React.useState(null);
  const handleChange = (e) => {
    if (e.target.files[0].size > 5 * 1024 * 1024) {
      delete errors[name];
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className={parentStyle}>
      <div className={labelStyle}>
        <h5>{label}</h5>
        <p className="label">{labelDescription}</p>
      </div>
      <div className={childStyle}>
        <div
          className="form-control text-center"
          style={{ backgroundImage: `url(${file ? file : image})` }}
        >
          {!file && (
            <>
              <img src="assets/images/image_up.png" alt="image_up" />
              <p className="mb-0 pb-0">
                <b>{"Click to replace"}</b> {"or drag and drop"}
              </p>
              <p className="mb-0 pb-0">
                {"SVG, PNG, JPG or GIF (max. 800*400px)"}
              </p>
            </>
          )}
          <input
            type="file"
            {...(event && event(name))}
            name={name}
            className={inputStyle}
            onChange={handleChange}
            disabled={fileDisabled}
            accept={accept}
          />
        </div>
        {errors && errors[name] && (
          <p style={{ color: "red" }}>{errors[name].message}</p>
        )}
      </div>
    </div>
  );
};
export default RowImage;
