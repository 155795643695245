import { Link } from "react-router-dom";
import RecentActivityBox from "./recentActivityBox";
import SimpleSelectOption from "../../../Tools/SimpleSelectOption";
import AdditionalServiceBox from "../../../Tools/AdditionalServiceBox";

const ProcessDetails = () => {
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="main_s">
          <div className="Progress">
            <div className="row">
              <div className="col-sm-8">
                <h6>{"Progress"}</h6>
                <p>
                  {
                    "Lorem Ipsum is simply dummy text for printing & typesetting."
                  }
                </p>
              </div>
              <div className="col-sm-4 text-center">
                <img
                  className="w-75"
                  src="../../assets/images/10per.png"
                  alt="ac1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <AdditionalServiceBox
              parentStyle={"col-sm-4"}
              title={"Start Date"}
              description={"01/12/2023"}
              singleDiv={true}
            />
            <AdditionalServiceBox
              parentStyle={"col-sm-4"}
              title={"Due Date"}
              description={"-----------"}
              singleDiv={true}
            />
            <AdditionalServiceBox
              parentStyle={"col-sm-4"}
              title={"Category"}
              description={"Study Visa"}
              singleDiv={true}
            />
            <AdditionalServiceBox
              parentStyle={"col-sm-4"}
              title={"Status"}
              description={"Pending"}
              descriptionStyle={"pending"}
              singleDiv={true}
            />
            <AdditionalServiceBox
              parentStyle={"col-sm-4"}
              title={"College"}
              description={"Fleming Colleg"}
              singleDiv={true}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main_s main_right">
          <h6>
            {"Recent Activity"}
            <SimpleSelectOption
              options={[
                { title: "Today", value: "today" },
                { title: "Today", value: "today" },
              ]}
            />
          </h6>
          <RecentActivityBox
            parentStyle={"st"}
            childStyle={"img"}
            imgSrc={"../../assets/images/ac1.png"}
            title={"Lorem Ipsum is simply dummy text"}
            time={"12:00 PM"}
          />
          <RecentActivityBox
            parentStyle={"st"}
            childStyle={"img"}
            imgSrc={"../../assets/images/ac2.png"}
            title={"Lorem Ipsum is simply dummy text"}
            time={"12:00 PM"}
          />

          <RecentActivityBox
            parentStyle={"st"}
            childStyle={"img"}
            imgSrc={"../../assets/images/ac3.png"}
            title={"Lorem Ipsum is simply dummy text"}
            time={"12:00 PM"}
          />

          <RecentActivityBox
            parentStyle={"st"}
            childStyle={"img"}
            imgSrc={"../../assets/images/ac1.png"}
            title={"Lorem Ipsum is simply dummy text"}
            time={"12:00 PM"}
          />
          <Link className="view_all">{"View All"}</Link>
        </div>
      </div>
    </div>
  );
};
export default ProcessDetails;
