import React from "react";
import Meta from "../../Common/Meta";

const ListingFaqs = ({ faqs }) => {
  return (
    <>
      <div className="what">
        <div className="container">
          {faqs.map((el, i) => (
            <div className="set">
              <h2>{el.question}</h2>
              <p dangerouslySetInnerHTML={{ __html: el.answer }}></p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ListingFaqs;
