import React, { useEffect, useState } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import {
  checkCardsApi,
  gstVerificationApi,
  panVerificationApi,
} from "../../../../services/api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

import { documentApiAuthToken } from "../../../../data/data";

const PanTab = ({
  currentStep,
  errors,
  register,
  setValue,
  clearErrors,
  trigger,
  getValues,
  setError,
  user,
  newCompany,
  setNewCompany,
  newUserName,
  setNewUserName,
}) => {
  const [panVerified, setPanVerified] = useState(
    user.pan_status === "Approved" ? true : false
  );
  const [gstVerified, setGstVerified] = useState(
    user.gst_status === "Approved" ? true : false
  );
  const [showGst, setShowGst] = useState(false);
  const [panLoading, setPanLoading] = useState(false);
  const [gstLoading, setGstLoading] = useState(false);

  useEffect(() => {
    if (user.pan_status === "Approved") {
      setPanVerified(true);
    }
    if (user.gst_status === "Approved") {
      setGstVerified(true);
      setShowGst(true);
    }
  }, [user]);

  const gstHandle = (e) => {
    console.log(e.target);
    if (e.target.value == "true") {
      setShowGst(true);
      setValue("has_gst", true);
    } else {
      setShowGst(false);
      setValue("has_gst", false);
    }
  };

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      setValue("pan_number", value.toUpperCase());
      const body = { number: value.toUpperCase(), card_type: "pan_number" };
      await checkCardsApi(body);
      clearErrors("pan_number");
    } catch (error) {
      setError("pan_number", {
        type: "custom",
        message: "Pan Number already in use",
      });
    }
  };

  const handleGstChange = async (e) => {
    try {
      const { name, value } = e.target;
      setValue("gst_number", value.toUpperCase());
      const body = { number: value.toUpperCase(), card_type: "gst_number" };
      await checkCardsApi(body);
      clearErrors("gst_number");
    } catch (error) {
      setError("gst_number", {
        type: "custom",
        message: "Gst Number already in use",
      });
    }
  };

  const checkPanStatus = async (value) => {
    try {
      setPanLoading(true);
      const body = { pan: value };
      const res = await panVerificationApi(body, documentApiAuthToken);
      const data = res.data;

      if (data.valid) {
        if (
          data.category === "INDIVIDUAL" &&
          (data.name === user.owner_name.toUpperCase() ||
            data.name.split(" ")[0] ===
              user.owner_name.toUpperCase().split(" ")[0])
        ) {
          setPanVerified(true);
          setValue("pan_verified", true);
          clearErrors("pan_verified");
          clearErrors("pan_number");
          if (data.name !== user.owner_name.toUpperCase()) {
            setNewUserName(data.name);
          } else {
            setNewUserName("");
          }
        } else if (
          data.category === "BUSINESS" &&
          (data.name === user.company_name.toUpperCase() ||
            data.name.split(" ")[0] ===
              user.company_name.toUpperCase().split(" ")[0])
        ) {
          setPanVerified(true);
          setValue("pan_verified", true);
          clearErrors("pan_verified");
          clearErrors("pan_number");
          if (data.name !== user.company_name.toUpperCase()) {
            setNewCompany(data.name);
          } else {
            setNewCompany("");
          }
        } else {
          setError("pan_number", {
            message:
              "PAN Number Should Match with Account Owner or Belong to Company Name",
          });
        }
        setPanLoading(false);
      } else {
        setError("pan_number", { message: data.message });
        setPanLoading(false);
      }
    } catch (error) {
      setPanLoading(false);
      toast.error("Something Went Wrong");
    }
  };

  const checkGstStatus = async (value) => {
    try {
      setGstLoading(true);
      const body = { gstin: value, fetchFilings: false };
      const res = await gstVerificationApi(body, documentApiAuthToken);
      const data = res.data;
      if (data.valid && data.active) {
        if (
          data.legalName === user.owner_name.toUpperCase() ||
          data.legalName.split(" ")[0] ===
            user.owner_name.toUpperCase().split(" ")[0] ||
          data.tradeName === user.company_name.toUpperCase() ||
          data.tradeName.split(" ")[0] ===
            user.company_name.toUpperCase().split(" ")[0]
        ) {
          setGstVerified(true);
          setValue("gst_verified", true);
          clearErrors("gst_number");
          clearErrors("gst_verified");
          if (data.tradeName !== user.company_name.toUpperCase()) {
            setNewCompany(data.tradeName);
          } else {
            setNewCompany("");
          }
        } else {
          setError("gst_number", {
            message: "GST Number Should Belong to Company Name",
          });
        }
        setGstLoading(false);
      } else {
        setGstLoading(false);
        setError("gst_number", { message: data.message });
      }
    } catch (error) {
      setGstLoading(false);
      toast.error("Something Went Wrong");
    }
  };

  const validateForm = async () => {
    const result = await trigger(["pan_number", "has_gst", "gst_number"]); // Trigger validation
    const pan_number = getValues("pan_number");
    if (result) {
      if (!panVerified) {
        checkPanStatus(pan_number);
      }
      const has_gst = getValues("has_gst");
      const gst_number = getValues("gst_number");
      if (has_gst && !gstVerified && gst_number.includes(pan_number)) {
        checkGstStatus(gst_number);
      } else {
        setError("gst_number", {
          message: "GST number should match with PAN holder",
        });
      }
    }
  };

  return (
    <div
      className="tab"
      style={{ display: currentStep === 1 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          1. Enter PAN (Permanent Account Number) <span> *</span>
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>
                Customers Can See this on your Profile Page and Feel Confident
                on your listing
              </p>
              <a
                href="../../assets/images/select1.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select1.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
        <p>You Can Add Company or Account Owner PAN Details</p>
        {/* <Controller /> */}
        <div className={panLoading ? "postion-relative" : ""}>
          <SimpleInput
            inType={"text"}
            placeholder={"Enter PAN Number"}
            style={"form-control"}
            name={"pan_number"}
            event={register}
            errors={errors}
            onChange={handleChange}
            disabled={panVerified}
          />
          {errors && errors["pan_verified"] && (
            <p style={{ color: "red" }}>{errors["pan_verified"].message}</p>
          )}
          {(!panVerified || panVerified === "") &&
            !errors["pan_number"] &&
            (panLoading ? (
              <Spinner />
            ) : (
              <button
                type="button"
                onClick={validateForm}
                className="verify_btn"
              >
                Verify
              </button>
            ))}
        </div>
        {panVerified && (
          <p className="veri">
            <i className="bi bi-check-circle-fill"></i> Verified
          </p>
        )}
      </div>

      {panVerified && (
        <div className="form-group">
          <label>
            Do you have GST Number ?<span>*</span>
            <h6 className="mt-2">
              <input
                type="radio"
                name="has_gst"
                value={true}
                onChange={gstHandle}
                checked={showGst}
                disabled={gstVerified}
              />{" "}
              Yes
              <input
                type="radio"
                name="has_gst"
                className="ms-4"
                value={false}
                onChange={gstHandle}
                checked={showGst === false}
                disabled={gstVerified}
              />{" "}
              No
            </h6>
          </label>
        </div>
      )}

      {showGst && (
        <>
          <div className="form-group">
            <div className={gstLoading ? "postion-relative" : ""}>
              <SimpleInput
                inType={"text"}
                placeholder={"Enter GST Number"}
                style={"form-control"}
                name={"gst_number"}
                event={register}
                errors={errors}
                onChange={handleGstChange}
                disabled={gstVerified}
              />
              {errors && errors["gst_verified"] && (
                <p style={{ color: "red" }}>{errors["gst_verified"].message}</p>
              )}
              {(!gstVerified || gstVerified === "") &&
                !errors["gst_verified"] &&
                (gstLoading ? (
                  <Spinner />
                ) : (
                  <button
                    onClick={validateForm}
                    className="verify_btn"
                    type="button"
                  >
                    Verify
                  </button>
                ))}
            </div>
            {gstVerified && (
              <p className="veri">
                <i className="bi bi-check-circle-fill"></i> Verified
              </p>
            )}
          </div>
        </>
      )}
      <p style={{ marginTop: "50px" }} className="note">
        <b>Note :</b> PAN/GSTIN is required to Connect Customers on Chownk
      </p>
    </div>
  );
};

export default PanTab;
