import React from "react";

const Suggestion = () => {
  return (
    <section className="Suggestion_Best pb-0">
      <div className="container">
        <h2 className="text-center">
          <span>
            What Makes Our Experts <br />
            <b>Suggestion Best</b> ?
          </span>
        </h2>

        <div className="row mt-4 mt-xl-5">
          <div className="col-md-4">
            <div className="set">
              <h1>1</h1>
              <h6>Heading</h6>
              <p>
                you are going to use a passage of Lorem Ipsum, you need to be
                sure
              </p>
            </div>

            <div className="set">
              <h1>2</h1>
              <h6>Heading</h6>
              <p>
                you are going to use a passage of Lorem Ipsum, you need to be
                sure
              </p>
            </div>

            <div className="set">
              <h1>3</h1>
              <h6>Heading</h6>
              <p>
                you are going to use a passage of Lorem Ipsum, you need to be
                sure
              </p>
            </div>
          </div>

          <div className="col-md-4 d-md-block d-none">
            <img src="assets/images/Suggestion_Best.png" alt="chat_img" />
          </div>

          <div className="col-md-4">
            <div className="set">
              <h1 style={{ background: "#151515" }}>4</h1>
              <h6>Heading</h6>
              <p>
                you are going to use a passage of Lorem Ipsum, you need to be
                sure
              </p>
            </div>

            <div className="set">
              <h1 style={{ background: "#151515" }}>5</h1>
              <h6>Heading</h6>
              <p>
                you are going to use a passage of Lorem Ipsum, you need to be
                sure
              </p>
            </div>

            <div className="set">
              <h1 style={{ background: "#151515" }}>6</h1>
              <h6>Heading</h6>
              <p>
                you are going to use a passage of Lorem Ipsum, you need to be
                sure
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Suggestion;
