import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SocketContext } from "../../../../Context";
import { Tooltip } from "react-tooltip";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./radialSeprator";

const ProfileStatus = ({
  companyName,
  companyImg,
  branchCount,
  staffCount,
}) => {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.auth);
  const accountselector = useSelector((state) => state.accountSetting);
  const { showEditProfile, setShowEditProfile } = useContext(SocketContext);

  const {
    company_logo,
    banner_image,
    faq,
    overview_description,
    stories,
    pan_status,
    authorize_certificate_status,
    city,
    slug,
    gst_status,
    specialization,
  } = selector.auth;

  let panCondition = pan_status !== "Approved";
  let certCondition = authorize_certificate_status !== "Approved";
  // let gstCondition = gst_status !== "Approved";

  let overviewCondition =
    overview_description === null || overview_description === "";

  let bannerCondition = banner_image === null || banner_image === "";

  let logoCondition = company_logo === null || company_logo === "";

  let storiesCondition =
    stories && stories !== "" && JSON.parse(stories).length < 3;

  let faqCondition =
    faq && faq !== "" && faq !==null && JSON.parse(faq).length < 3;

  let specializationCondition =
    specialization &&
    specialization !== "" &&
    specialization.split(",").length < 5;

  let disableCondition =
    authorize_certificate_status !== "Approved" && pan_status !== "Approved";

  let msgCondition =
    authorize_certificate_status == "Hold" || pan_status == "Hold";

  return (
    <>
      <Link
        className="float-end mx-2 my-2 edtp"
        data-tooltip-id="Edit Profile"
        to={"/account-settings"}
      >
        <i className="bi bi-pencil"></i>
      </Link>
      <div className="wap matters mb-3 h-auto">
        <div className="set">
          {companyImg !== null ? (
            <div className="imgmain">
              <CircularProgressbarWithChildren
                value={selector.auth.profile_percentage}
                strokeWidth={10}
                styles={buildStyles({
                  strokeLinecap: "round",
                })}
              >
                {" "}
                <div
                  className="img"
                  style={{ backgroundImage: `url(${companyImg})` }}
                  onClick={() =>
                    navigate(`/${selector.auth.city}/${selector.auth.slug}`)
                  }
                ></div>
                <RadialSeparators
                  count={6}
                  style={{
                    background: "#fff",
                    width: "2px",

                    height: `${10}%`,
                  }}
                />
              </CircularProgressbarWithChildren>{" "}
            </div>
          ) : (
            <div className="imgmain">
              <div
                className="img"
                style={{ backgroundImage: `url(${companyImg})` }}
                onClick={() =>
                  navigate(`/${selector.auth.city}/${selector.auth.slug}`)
                }
              >
                <CircularProgressbarWithChildren
                  value={selector.auth.profile_percentage}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "round",
                  })}
                >
                  {" "}
                  {/* <div
                    className="img"
                    style={{ backgroundImage: `url(${companyImg})` }}
                  ></div> */}
                  {companyName &&
                  companyName !==undefined &&
                  companyName?.split(" ").length > 1
                    ? companyName?.split(" ")[0].substr(0, 1) +
                      companyName?.split(" ")[1].substr(0, 1)
                    : companyName?.split(" ")[0].substr(0, 1)}
                  <RadialSeparators
                    count={6}
                    style={{
                      background: "#fff",
                      width: "2px",
                      // This needs to be equal to props.strokeWidth
                      height: `${10}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
            </div>
          )}

          <h5
            onClick={() =>
              navigate(`/${selector.auth.city}/${selector.auth.slug}`)
            }
          >
            {companyName}
          </h5>
          <p>
            <Link to="#">{`${branchCount && branchCount} Branches`}</Link>{" "}
            <br></br>{" "}
            <Link to="#">{`${staffCount && staffCount} Staff Members`}</Link>
          </p>
        </div>

        <h6>
          <i className="bi bi-person-circle"></i> Profile completed{" "}
          <span>{`${selector.auth && selector.auth.profile_percentage}%`}</span>
        </h6>

        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${selector.auth.profile_percentage}%`,
            }}
            aria-valuenow={
              selector.auth && selector.auth.status == "active" ? "100" : "25"
            }
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <p className="afterprogress">
          Ensuring your account is fully completed which significantly boosts of
          getting more clients.
        </p>

        {panCondition && (
          <Link
            to={pan_status !== "Hold" ? "/account-settings" : ""}
            state={{ active: "kyc_documents", overlay: "pan" }}
            className={`upcomp ${pan_status == "Hold" ? "disable" : ""}`}
            data-tooltip-id={
              authorize_certificate_status == "Hold" ? "document" : ""
            }
          >
            <i className="bi bi-tag"></i> PAN/GST proof{" "}
            <span>{pan_status == "Hold" ? "under review" : "+ 15%"}</span>
          </Link>
        )}
        {certCondition && (
          <Link
            to={
              authorize_certificate_status !== "Hold" ? "/account-settings" : ""
            }
            state={{ active: "kyc_documents", overlay: "cert" }}
            className={`upcomp ${
              authorize_certificate_status == "Hold" ? "disable" : ""
            }`}
            data-tooltip-id={
              authorize_certificate_status == "Hold" ? "document" : ""
            }
          >
            <i className="bi bi-briefcase"></i> Business proof
            <span>
              {authorize_certificate_status == "Hold"
                ? "under review"
                : "+ 15%"}
            </span>
          </Link>
        )}
        {/* {gstCondition && (
          <Link
            to={gst_status !== "Hold" ? "/account-settings" : ""}
            state={{ active: "kyc_documents", overlay: "gst" }}
            className={`upcomp ${gst_status == "Hold" ? "disable" : ""}`}
            data-tooltip-id={gst_status == "Hold" ? "document" : ""}
          >
            <i className="bi bi-currency-rupee"></i> GST proof{" "}
            <span>{gst_status == "Hold" ? "under review" : "+ 10%"}</span>
          </Link>
        )} */}
        {logoCondition && (
          <Link
            to={disableCondition ? "" : `/${city.replaceAll(" ", "-")}/${slug}`}
            onClick={() => setShowEditProfile(true)}
            state={{ overlay: "logo" }}
            className={`upcomp ${disableCondition && "disable"}`}
            data-tooltip-id={disableCondition ? "profile" : ""}
          >
            <i className="bi bi-person-square"></i> Profile Logo{" "}
            <span>{"+ 10%"}</span>
          </Link>
        )}

        {overviewCondition && (
          <Link
            to={disableCondition ? "" : `/${city.replaceAll(" ", "-")}/${slug}`}
            state={{ overlay: "overview" }}
            onClick={() => setShowEditProfile(true)}
            className={`upcomp ${disableCondition && "disable"}`}
            data-tooltip-id={disableCondition ? "profile" : ""}
          >
            <i className="bi bi-body-text"></i> Company overview{" "}
            <span>{"+ 5%"}</span>
          </Link>
        )}
        {bannerCondition && (
          <Link
            to={disableCondition ? "" : `/${city.replaceAll(" ", "-")}/${slug}`}
            state={{ overlay: "banner" }}
            onClick={() => setShowEditProfile(true)}
            className={`upcomp ${disableCondition && "disable"}`}
            data-tooltip-id={disableCondition ? "profile" : ""}
          >
            <i className="bi bi-card-image"></i> Profile banner
            <span>{"+ 10%"}</span>
          </Link>
        )}

        {specializationCondition && (
          <Link
            to={disableCondition ? "" : `/${city.replaceAll(" ", "-")}/${slug}`}
            state={{ overlay: "specialization" }}
            onClick={(e) => {
              setShowEditProfile(true);
            }}
            className={`upcomp ${disableCondition && "disable"}`}
            data-tooltip-id={disableCondition ? "profile" : ""}
          >
            <i className="bi bi-highlighter"></i> 5 Specialization{" "}
            <span>{"+ 5%"}</span>
          </Link>
        )}
        {storiesCondition && (
          <Link
            to={disableCondition ? "" : `/${city.replaceAll(" ", "-")}/${slug}`}
            state={{ overlay: "success_stories" }}
            onClick={() => {
              setShowEditProfile(true);
            }}
            className={`upcomp ${disableCondition && "disable"}`}
            data-tooltip-id={disableCondition ? "profile" : ""}
          >
            <i className="bi bi-layout-text-window-reverse"></i> 3 Success
            stories <span>{"+ 5%"}</span>
          </Link>
        )}

        {(faqCondition || faqCondition === null) && (
          <Link
            to={disableCondition ? "" : `/${city.replaceAll(" ", "-")}/${slug}`}
            state={{ overlay: "faq" }}
            onClick={() => setShowEditProfile(true)}
            className={`upcomp ${disableCondition && "disable"}`}
            data-tooltip-id={disableCondition ? "profile" : ""}
          >
            <i className="bi bi-menu-button-wide"></i> 3 FAQ's{" "}
            <span>{"+ 5%"}</span>
          </Link>
        )}

        <p>
          Maintaining a fully Complete profile enhances visibility and boosts
          your chances of attracting more attention and engagement
        </p>
        {/* <Link to={"/account-settings"} className="Submit">
        <i className="bi bi-file-earmark-text-fill"></i>{" "}
        {"Submit your KYC Documents"}
      </Link> */}
        <Tooltip
          id="profile"
          content={
            msgCondition
              ? "Application is Under review please wait"
              : "Submit Kyc Document"
          }
          place="bottom"
        />
        <Tooltip id="document" content="Document under review" place="bottom" />
      </div>
    </>
  );
};
export default ProfileStatus;
