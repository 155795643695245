import React from "react";
import { Link } from "react-router-dom";

const SiteMapContent = () => {
  return (
    <section className="fees_main sitemap">
      <div className="container">
        <h2>Links</h2>
        <ul>
          <li>
            <Link>Immigration Consultants</Link>
          </li>
        </ul>

        <h2>Articles</h2>
        <ul>
          <li>
            <Link to="/blogs/category/Immigrations-Guides">
              Immigration Guides
            </Link>
          </li>
        </ul>

        <h2>Network</h2>
        <ul>
          <li>
            <a href="/about-us">About us</a>
          </li>
          <li>
            <a href="/terms-and-conditions">Terms and Conditions</a>
          </li>
          <li>
            <a href="/fees-and-charges">Fees and Charges</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/disclaimer">Disclaimer</a>
          </li>
          <li>
            <a href="/contact">Contact Us</a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SiteMapContent;
