import React, { useContext } from "react";
import Home from "./home";
import { useParams } from "react-router-dom";
import AfterSearchResult from "../AfterSearchResult";
import ViewAll from "../../Agent/ViewAll";
import MobileHomePage from "../MobileHomePage";
import { SocketContext } from "../../../../Context";
import { useSelector } from "react-redux";
import { searchPageData } from "../../../../data/data";
import { searchPageMetaTags } from "../../../../data/metaTags";
import NewViewProfile from "../../Agent/newViewProfile";

const HomePage = () => {
  const { city, dynamic, num } = useParams();
  const { screenWidth } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);

  switch (true) {
    case city !== undefined &&
      dynamic !== undefined &&
      num !== undefined &&
      (num == "success-stories" || num == "faq" || num == "reviews"):
      return <ViewAll />;
    case city !== undefined && dynamic == undefined:
      return (
        <AfterSearchResult
          data={searchPageData.search}
          metaTags={searchPageMetaTags}
          script={city}
        />
      );
    case city !== undefined && num == undefined:
      return <NewViewProfile />;
    case city !== undefined &&
      dynamic !== undefined &&
      num !== undefined &&
      num !== "success-stories" &&
      num !== "faq" &&
      num !== "reviews":
      return (
        <AfterSearchResult
          data={searchPageData.search}
          metaTags={searchPageMetaTags}
          script={city}
        />
      );
    case city == undefined && dynamic == undefined:
      if (
        screenWidth <= 768 &&
        selector.auth &&
        (selector.auth?.role !== 5 || selector.auth?.role !== 6)
      ) {
        return <MobileHomePage />;
      } else {
        return <Home />;
      }
  }
};
export default HomePage;
