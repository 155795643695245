import axios from "axios";
import React, { useState } from "react";
import { searchBlogs } from "../../../../../services/api";
import { blogsMediaUrl } from "../../../../../services/network";
import { useNavigate } from "react-router-dom";

const SearchSection = () => {
  const [search, setSearch] = useState("");
  const [blogsList, setBlogsList] = useState([]);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    try {
      if (e.target.value === "") {
        setBlogsList([]);
      } else {
        setSearch(e.target.value);
        const response = await searchBlogs({ search: e.target.value });
        setBlogsList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setSearch(e.target.value);
  };

  return (
    <section class="hed_blogs mt-2">
      <div class="container">
        <h1>
          Insight Trends and Stories Explore
          <br />
          Our Chownk Blog
        </h1>
        <p>
          Embarking on a journey to a new country can be both exhilarating and
          daunting. Whether you're pursuing opportunities, reuniting with loved
          ones, or seeking refuge, navigating immigration processes can be
          complex. That's where an immigration consultant comes in. With Chownk
        </p>
        <form onSubmit={(e) => e.preventDefault()}>
          <i class="bi bi-search"></i>
          <input
            type="text"
            placeholder="Search for Articles , Contents or Knowledge"
            value={search}
            onChange={handleSearch}
          />
          {blogsList && blogsList.length > 0 && (
            <ul className="search_list">
              {blogsList.map((el, i) => (
                <li onClick={() => navigate(`/blogs/${el.slug}`)}>
                  <img src={blogsMediaUrl + el.image} />
                  <h6>{el.title}</h6>
                </li>
              ))}
            </ul>
          )}
        </form>
      </div>
    </section>
  );
};

export default SearchSection;
