// import AgentFooter from "../../../Layouts/Footer/footer";
import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import ProfileWithForm from "./profileWithForm";

const MyProfile = () => {
  return (
    <>
      <StudentHeader
        style={"header_white"}
        // logo={"../../../assets/images/logo2.png"}
        bellIcon={"hedic1black.png"}
        messageIcon={"hedic2black.png"}
        formDisplay={true}
      />
      <ProfileWithForm />
      <StudentFooter />
    </>
  );
};
export default MyProfile;
