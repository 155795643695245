import React, { useState } from "react";
import SimpleInput from "../../Tools/SimpleInput";
import Button from "../../Tools/Button";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const ShareProfileModal = () => {
  const [btnText, setBtnText] = useState("Copy");
  const current_url = window.location.href;

  return (
    <div className="modal-content">
      <div className="modal-body p-3">
        <h5 className="text-center">{"Share Your Profile"}</h5>
        <ul
          className="fb_n d-flex mt-3 justify-content-center"
          style={{ listStyle: "none" }}
        >
          <li className="m-2">
            <FacebookShareButton url={current_url}>
              {" "}
              <FacebookIcon round />{" "}
            </FacebookShareButton>
          </li>
          <li className="m-2">
            <TwitterShareButton url={current_url}>
              <TwitterIcon round />
            </TwitterShareButton>
          </li>
          <li className="m-2">
            <WhatsappShareButton url={current_url}>
              <WhatsappIcon round />
            </WhatsappShareButton>
          </li>
          <li className="m-2">
            <LinkedinShareButton url={current_url}>
              <LinkedinIcon round />
            </LinkedinShareButton>
          </li>
        </ul>

        <div className="d-flex mt-3">
          <SimpleInput
            inType={"text"}
            style={"form-control"}
            value={current_url}
          />
          <Button
            inType={"button"}
            title={btnText}
            onClick={() => {
              navigator.clipboard.writeText(current_url);
              setBtnText("Copied");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareProfileModal;
