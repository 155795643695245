import React, { useEffect } from "react";
import StudentHeader from "../../../../Layouts/Student/Header";
import BlogCard from "../../../../Tools/BlogCard";
import StudentFooter from "../../../../Layouts/Student/Footer";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogByCategory,
  getBlogsByCatorTag,
} from "../../../../../Reducer/blogSlice";
import Meta from "../../Meta";
import { categorytagsMetaTags } from "../../../../../data/metaTags";
import {
  backEndUrl,
  blogsMediaUrl,
  mediaUrl,
} from "../../../../../services/network";

const BlogsByCatandTag = ({ type }) => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { blogsByCatOrTag } = useSelector((state) => state.blogs);

  useEffect(() => {
    const body = { type: type, name: slug };
    dispatch(getBlogsByCatorTag(body));
  }, []);

  const unixtoDate = (unix_date) => {
    let date = new Date(unix_date * 1000);
    let formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      <Meta
        title={`Visa and Immigration Guides for Different Countries | CHOWNK`}
        description={`Find easy-to-understand visa and immigration information for different countries. Get the latest updates and expert tips from CHOWNK's Immigration Guides.`}
      />
      <StudentHeader
        logo={"../../../../assets/images/chownk-logo.png"}
        bellIcon={"../../../assets/images/hedic1.png"}
        messageIcon={"../../../assets/images/hedic2.png"}
        formDisplay={true}
      />
      <section className="section_blogs consultants">
        <div className="container">
          <nav aria-label="Breadcrumb" className="Breadcrumb">
            <ul>
              <li>
                <Link to="/blogs">Blogs &gt;</Link>
              </li>
              <li>
                <Link>{type == "hashtag" ? "tag" : "Category"} &gt;</Link>
              </li>
              <li>
                <Link>{type == "hashtag" ? `#${slug}` : `${slug}`}</Link>
              </li>
            </ul>
          </nav>

          <h1>Immigration consultants</h1>

          <div className="row mb-4">
            {blogsByCatOrTag &&
              blogsByCatOrTag.map((item) => (
                <div class="col-sm-6 col-lg-3 mb-3">
                  <div class="img">
                    <img
                      src={`${blogsMediaUrl}${item.image}`}
                      alt={`${item.title}-Chownk`}
                      className="w-100"
                      onClick={() => navigate(`/blogs/${item.slug}`)}
                      style={{ cursor: "pointer" }}
                    />
                    <p className="h5">
                      <span>
                        <img
                          src={`${blogsMediaUrl}${item.author_image}`}
                          alt={`${item.author_name}-author of ${item.title}-Chownk`}
                          className="w-100"
                        />
                      </span>{" "}
                      {item.author_name}
                    </p>
                    <p className="h6">{unixtoDate(item.unix_time)}</p>
                  </div>
                  <h2
                    onClick={() => {
                      navigate(`/blogs/${item.slug}`);
                    }}
                  >
                    {item.title}
                  </h2>
                  <p>{item.description}</p>
                </div>
              ))}
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default BlogsByCatandTag;
