import React, { useContext, useEffect, useState } from "react";
import FormOne from "./formOne";
import FormTwo from "./formTwo";
import FormThree from "./formThree";
import FormFour from "./formFour";
import { useLocation, useNavigate } from "react-router-dom";
import SmsBox from "../../../Tools/SmsBox";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../../Context";
import SingleChatBox from "../../../Tools/SingleChatBox";

const ChatwithExpert = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const selector = useSelector((state) => state.auth);
  const { socket } = useContext(SocketContext);

  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (e) => {
    const { name, value } = e.target;
    if (data.some((el) => el.ques == name)) {
      const filter = data.filter((item) => item.ques !==name);
      setData([...filter, { ques: name, ans: value }]);
    } else {
      setData([...data, { ques: name, ans: value }]);
    }
  };

  useEffect(() => {
    if (page === 5) {
      sendMsg();
      navigate("/chat-with-expert");
    }
  }, [page]);

  const sendMsg = () => {
    var date = Math.round(new Date().getTime() / 1000);
    let dts = {
      first: false,
      sender_id: selector.auth.uid,
      connection_id: location && location.state,
      receiver_id: location && location.state,
      type: "default",
      file_type: "",
      file: "",
      offer: "",
      sms_status: "sent",
      message: JSON.stringify(data),
      unix_time: date,
    };
    socket.emit("send_sms", dts);
    // setOffer('');
  };

  return (
    <section className="expert_help h-100">
      <div className="container-fluid p-0 h-100">
        <div className="row h-100">
          <div className="col-md-6 h-100">
            <div className="expert_int">
              <img
                className="logo"
                src="assets/images/logo_white.png"
                alt="logo"
                onClick={() => navigate("/")}
              />

              <span className="hint">*You can also navigate with dots</span>

              <div className="bar">
                <span className="bar-circle active"></span>
                <span className="bar-circle"></span>
                <span className="bar-circle"></span>
                <span className="bar-circle"></span>
              </div>

              <form className="fff" onSubmit={(e) => e.preventDefault()}>
                {page && page === 1 && (
                  <FormOne
                    page={page}
                    setPage={setPage}
                    onChange={onChange}
                    data={data}
                  />
                )}

                {page && page === 2 && (
                  <FormTwo
                    page={page}
                    setPage={setPage}
                    onChange={onChange}
                    data={data}
                  />
                )}
                {page && page === 3 && (
                  <FormThree
                    page={page}
                    setPage={setPage}
                    onChange={onChange}
                    data={data}
                  />
                )}
                {page && page === 4 && (
                  <FormFour
                    page={page}
                    setPage={setPage}
                    onChange={onChange}
                    data={data}
                  />
                )}
                {page && page === 5 && (
                  <div className="form-page">
                    <p className="final-message">
                      Please Wait While We Save your Details
                    </p>
                  </div>
                )}
              </form>

              {/* <form id="regForm" action="" className="d-none">
                <div className="tab">
                  <h2>
                    TELL US WHY YOU NEED <b>EXPERT</b> HELP ?
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable
                  </p>
                  <ul>
                    <li>
                      <span className="option">
                        STUDY ABROAD VISA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        START A BUSINESS ABROAD VISA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        TRAVEL VISA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        SPOUSE VISA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        OTHERS
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="tab">
                  <h2>
                    IN WHICH <b>COUNTRY</b> YOU ARE PLANNING TO START A
                    BUSINESS?
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable
                  </p>
                  <ul>
                    <li>
                      <span className="option">
                        UNITED STATES OF AMERICA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        UNITED KINGDOM
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        AUSTRALIA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        CANADA
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        NEW ZEALAND
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        OTHERS
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="tab">
                  <h2>
                    WHAT LEVEL OF <b>EDUCATION</b> ARE YOU PLANNING TO STUDY?
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable
                  </p>
                  <ul>
                    <li>
                      <span className="option">
                        DIPLOMA (fOR 10TH PASSED STUDENTS)
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        GRADUATE (fOR 12TH PASSED STUDENTS)
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        POSTGRADUATE (fOR GRADUATE STUDENTS)
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        PHD
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        OTHERS
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="tab">
                  <h2>
                    WHAT IS YOUR <b>PREFERRED</b> FIELD OF STUDY?
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable
                  </p>
                  <ul>
                    <li>
                      <span className="option">
                        sCIENCE & ENGINEERING
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                        ARTS
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        BUSINESS ADMINISTRATION
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <span className="option">
                        OTHERS
                        <span className="checkcontainer">
                          <input
                            type="checkbox"
                            name="quality"
                            className="quality"
                            value="I1"
                          />
                          <span className="radiobtn"></span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>

                <div style={{ overflow: "auto" }} className="main_prevBtn">
                  <div style={{ float: "right" }} className="w-100">
                    <button type="button" id="prevBtn" onclick="nextPrev(-1)">
                      Previous
                    </button>
                    <button type="button" id="nextBtn" onclick="nextPrev(1)">
                      Next
                    </button>
                  </div>
                </div>

                <div
                  style={{ textAlign: "center", marginTop: "40px" }}
                  className="d-none"
                >
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                </div>
              </form> */}
            </div>
          </div>
          <div className="col-md-6 text-center h-100 d-md-block d-none">
            {/* <img
              className="chatimg"
              src="assets/images/chatimg1.png"
              alt="chatimg"
            /> */}
            <SingleChatBox />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatwithExpert;
