import React, { useEffect } from "react";
import AskExpertButton from "../../../Tools/AskExpertButton";
import $ from "jquery";
import AskExpertHeader from "../../../Layouts/Student/AskExpertHeader";
import Talk from "./talkExpert";
import Feedback from "./feedback";
import Counselling from "./counselling";
import Suggestion from "./suggestion";
import Faq from "./faq";

const AskExpert = () => {
  return (
    <div style={{ background: "#F2F2F2" }}>
      <AskExpertHeader />
      <Talk />
      <Feedback />
      <Counselling />
      <Suggestion />
      <Faq />
      <p className="rights mb-0">All Rights Reserved by Chownk</p>
    </div>
  );
};

export default AskExpert;
