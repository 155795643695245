import React from "react";
import AskExpertButton from "../../../Tools/AskExpertButton";

const Talk = () => {
  return (
    <section className="Talk_Expert">
      <div className="container">
        <h1 className="text-center">
          Talk to Our Expert <br />
          and Skip the Hassle
        </h1>
        <p className="text-center">
          Immediately Connect with an Expert Who Can guide you
        </p>

        <div className="row mt-3 mt-md-4 mt-xl-5 ">
          <div className="col-md-4 chatimg_col1">
            <div className="set">
              <h1>1</h1>
              <h6>Tell Aman about your Kindle isue</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable
              </p>
              <img
                className="set1img"
                src="assets/images/aaro1.png"
                alt="icon"
              />
            </div>

            <div className="set">
              <h1>2</h1>
              <h6>Tell Aman about your Kindle isue</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable
              </p>
              <img
                className="set2img"
                src="assets/images/aaro2.png"
                alt="icon"
              />
            </div>

            <div className="set">
              <h1>3</h1>
              <h6>Tell Aman about your Kindle isue</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable
              </p>
              <img
                className="set3img"
                src="assets/images/aaro3.png"
                alt="icon"
              />
            </div>
          </div>

          <div className="col-md-4 text-center chatimg_col ">
            <div className="chatimg">
              <img src="assets/images/Comp1.gif" />
              {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/u50O6O5WbPo?autoplay=1&mute=1&amp;loop=1"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
              controls  
              loop
            ></iframe> */}
              <AskExpertButton />
            </div>
          </div>

          <div className="col-md-4 chatimg_col3">
            <div className="set mt-4">
              <img
                className="set4img"
                src="assets/images/aaro4.png"
                alt="icon"
              />
              <h1>4</h1>
              <h6>Tell Aman about your Kindle isue</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable
              </p>
            </div>

            <div className="set mb-5  ms-xl-5">
              <img
                className="set5img"
                src="assets/images/aaro5.png"
                alt="icon"
              />
              <h1>5</h1>
              <h6>Tell Aman about your Kindle isue</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable
              </p>
            </div>

            <div className="set">
              <h1>6</h1>
              <h6>Tell Aman about your Kindle isue</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable
              </p>
              <img
                className="set6img"
                src="assets/images/aaro6.png"
                alt="icon"
              />
            </div>
          </div>

          <div className="col-md-12 text-center mt-4">
            <AskExpertButton />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Talk;
