import { createContext, useState, useRef, useEffect, useCallback } from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { useNavigate } from "react-router-dom";
import { backEndUrl } from "../services/network";
import { addMedia, checkUserReady, logoutfun } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { blobToBase64 } from "../data/data";
import { toast } from "react-toastify";
import $ from "jquery";
import { clearToken, clearUser, getBalance, setUser } from "../Reducer/authSlice";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
const SocketContext = createContext();
const token = localStorage.getItem("token");

const ContextProvider = ({
  children,
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
}) => {
  const audio = new Audio("/assets/audio/incoming_msg.mp3");
  let me = "";
  const { chats } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const callTimerIntervalApi = useRef(null);
  const callTimerInterval = useRef(null);
  const [userModel, setUserModel] = useState(false);
  const [expendMode, setExpendMode] = useState(false);
  const [lastCall, setLastCall] = useState(null);
  const [walletMsg, setWalletMsg] = useState(false);
  const [allChats, setAllChats] = useState(chats ? chats : []);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callMode, setCallMode] = useState("audio");
  const [stream, setStream] = useState(null);
  const selector = useSelector((state) => state?.auth);
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [mute, setMute] = useState(true);
  const [video, setVideo] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [chatLimit, setChatLimit] = useState(1);
  const [declineCall, setDeclineCall] = useState(false);
  const [smsCount, setSmsCount] = useState(null);
  const [type, setCallType] = useState("own");
  const [callingSms, setCallingSms] = useState("");
  const [onCall, setOnCall] = useState(false);
  const [access, setAccess] = useState(false);
  const [mchat, modalChat] = useState(false);
  const [userMute, setUserMute] = useState(true);
  const [userVideoStatus, setUserVideo] = useState(true);
  const [sidebar, setSideBar] = useState([]);
  const [sidebarImages, setImages] = useState([]);
  const [callUserId, setCallUser] = useState(0);
  const [connectId, myConnect] = useState(null);
  const [videoOffer, setVideoOffer] = useState(false);
  const [sidebarFile, setFile] = useState([]);
  const [userView, setUserView] = useState(null);
  const [chatUser, setChatUser] = useState([]);
  const [show, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState(
    chatUser && chatUser.length > 0 ? chatUser[0] : {}
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasCamera, setHasCamera] = useState(null);
  const [chatlist, setCurrentChat] = useState([]);
  const [offer, setOffer] = useState("");
  const myVideo = useRef();
  const [callTrue, setCallTrue] = useState(false);
  const userVideo = useRef();
  const declineClick = useRef();
  const acceptClick = useRef();
  const declineOwnClick = useRef();
  const [socket, setSocket] = useState(null);
  const [calltouser, calltoSet] = useState(true);
  const [ans, setAns] = useState(false);
  const [vchat, setVChat] = useState(false);
  const [rattingPop, setRattingPop] = useState(false);
  const [fAgnt, setFindAgnt] = useState([]);
  const [callDecline, setCallDecline] = useState(false);
  // const [stillOnline, setStillOnline] = useState(false);
  const [imageVideoShow, setImageVideoShow] = useState(false);
  const [chatavail, setChatAvail] = useState(false);
  const [addFundsModal, setAddFundsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { call_price } = useSelector((state) => state.auth);

  let conn = true;
  const dispatch = useDispatch();
  const screenWidth = window.innerWidth;
  const dt = localStorage.getItem("dt");
  const [peeer, setPeer] = useState(null);
  const [declineCase, setDeclineCase] = useState(null);
  const peerRef = useRef(null);
  let use = false;
  useEffect(() => {
    if (selector.token || (token && token !== undefined && token !== null)) {
      const new_socket = io(backEndUrl, {
        auth: {
          token: selector.token,
        },
      });
      setSocket(new_socket);
    }
  }, [selector.token]);

  useEffect(() => {
    if (!socket) return;

    const handleSocketEvent = (data) => {
      handleCallCutFromUser(data);
    };
    const handleSocketEventMyconnection = (data) => {
      myConnect(data.id);
      if (data.message && data.message !== "") {
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
        setCallDecline(true);
        setCallingSms(data.message);
      }
    };
  

    socket.on(`CallCutFromUser`, handleSocketEvent);
    socket.on("connections", handleSocketEventMyconnection);
    socket.on("callActionReceived", handleCallCutListen);
    socket.on("check", (data) => {
      socket.emit("update_chat_status", {
        user: data.user,
        status: "delivered",
      });
    });

    return () => {
      socket.off(`CallCutFromUser`, handleSocketEvent);
      socket.off(`connections`, handleSocketEventMyconnection);
      socket.off(`callActionReceived`, handleCallCutListen);
      socket.off(`check`);
    };
  }, [socket, connectId]); // Add necessary dependencies here

  const handleCallCutListen = (data) => {
    if (data && data.call === false) {
      setCallAccepted(false);
      setCallEnded(true);
      setAccess(false);
      myConnect(null);
      stopCamera(-1);
      switch (data.d_type) {
        case "1":
          setDeclineCase("2");
          break;
        case "2":
          setDeclineCase("4");
          break;
        case "3":
          setDeclineCase("5");
          break;
        case "4":
          setDeclineCase("6");
          break;
        default:
          break;
      }
    }
    if (data && data.call !== false) {
      setUserMute(data.mute);
      setUserVideo(data.video);
    }
  };

  const handleCallCutFromUser = (data) => {
    if (
      data &&
      data.message !== "" &&
      callAccepted === false &&
      data.id === connectId
    ) {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      setCallDecline(true);
      setCallingSms(data.message);
    }
  };

  useEffect(() => {
    if (!socket) return;
    if (token) {
      dispatch(getBalance());
    }
    setChatModal();
    try {
      socket.on(
        `callUser${selector.auth.uid}`,
        ({
          from,
          name: callerName,
          signal,
          image: UserImage,
          connectionId,
          started_by,
          dts,
          dynamic_image,
          userRole,
          company_name,
          company_logo,
          single,
          permission,
        }) => {
          setCall({
            isReceivingCall: true,
            from,
            name: callerName,
            signal,
            image: UserImage,
            started_by: started_by,
            connectionId,
            role: dts.role,
            dts: dts,
            permission: permission,
            dynamic_image,
            userRole,
            company_name,
            company_logo,
            single: single,
            show: true,
          });
        }
      );
    } catch (e) {}

    socket.on("callError", (data) => {
      try {
        console.log("error call", data);
      } catch (e) {
        console.log("catch error", e);
      }
    });

    socket.on("updatePermission", (data) => {
      setCurrentUser((prevState) => ({ ...prevState, permission: 1 }));
    });

    // socket.off(`chatting${dt}`, {});
    socket.once(`chatting`, (data) => {
      navigate(`/messages?msg=${data.redirect}`);
    });

    socket.on(`callAns${dt}`,handleCallAccept);

    socket.emit("sms_count", {});
    socket.on("sms_count", (data) => {
      setSmsCount(data.count);
    });

    // if (selector.auth && selector.auth.uid) {
    //   socket.off(`smsNotification${selector.auth.uid}`);
    //   socket.once(`smsNotification${selector.auth.uid}`, (data) => {
    //     // if (data && data.message) {
    //     //   if (stream) {
    //     //     stream.getTracks().forEach((track) => track.stop());
    //     //   }
    //     //   setCallDecline(true);
    //     //   // toast.error(data.message);
    //     // }
    //   });
    // }

    // socket.on("OffinePop", (data) => {
    //   setStillOnline(true);
    // });

    socket.on(
      `ownclose${
        chats?.deviceToken
          ? chats?.deviceToken
          : localStorage.getItem("guestId")
      }`,
      (data) => {
        try {
          if (
            data &&
            data.connectionId &&
            call.connectionId &&
            call.connectionId === data.connectionId
          ) {
            if (declineOwnClick.current) {
              declineOwnClick.current.click();
            }
            
            setCallDecline(false);
            setCallingSms("");
            setIsPlaying(true);
            setTimeout(() => {
              setIsPlaying(false);
            }, 10000);
          }
        } catch (e) {}
      }
    );

    // socket.on(`reSignal${selector.auth.uid}`, (data) => {
    //   console.log("signaling------------------->", data);
    //   if (peeer) {
    //     peeer.signal(data.signal);
    //   }
    // });

    socket.on(
      `stopCall${call.connectionId ? call.connectionId : connectId}`,
      (data) => {
        try {
          const cid = call.connectionId ? call.connectionId : connectId;
          if (data && cid && cid === data.connectionId) {
            if (declineOwnClick.current) {
              declineOwnClick.current.click();
            }
            setIsPlaying(true);
            setCallDecline(false);
            setCallingSms("");
            setTimeout(() => {
              setIsPlaying(false);
            }, 10000);
          }
        } catch (e) {}
      }
    );
    socket.on(
      `offer_${call.connectionId ? call.connectionId : connectId}${access}`,
      handleVideoOffer
    );
    socket.on(
      `answer_${call.connectionId ? call.connectionId : connectId}${access}`,
      handleAnswer
    );
    return () => {
      socket.off(
        `answer_${call.connectionId ? call.connectionId : connectId}${access}`,
        handleAnswer
      );
      socket.off(`callAns${dt}`,handleCallAccept);
      socket.off(
        `offer_${call.connectionId ? call.connectionId : connectId}${access}`,
        handleVideoOffer
      );
      socket.off(
        `stopCall${call.connectionId ? call.connectionId : connectId}`
      );
      socket.off(
        `ownclose${
          chats?.deviceToken
            ? chats?.deviceToken
            : localStorage.getItem("guestId")
        }`
      );
      socket.off(`OffinePop`);
      socket.off(`updatePermission`);
      socket.off(`callUser${selector?.auth?.uid}`);
    };
  }, [
    call,
    mediaBlobUrl,
    fAgnt,
    callUserId,
    hasCamera,
    callTrue,
    callAccepted,
    connectId,
    call.isReceivingCall,
    chatUser,
    stream,
    socket,
    startTime,
    mute,
    access,
    declineCase,
    video,
    userMute,
    userVideoStatus,
    callEnded,
    chats?.deviceToken,
    navigate,
    selector?.auth?.uid,
    dispatch,
    peeer,
  ]);

  const setChatModal = async () => {
    for (let index = 0; index < allChats.length; index++) {
      socket.on(`offOn-${allChats[index].connection_user}`, (datas) => {
        updateObjectInArray(datas);
      });
    }
  };

  const handleCallAccept = async (data)=>{
    if (data.type === "accept") {
      if (Object.entries(call).length === 0) {
        setCall({
          type: "accept",
          isReceivingCall: true,
          from: Number(data.froming),
          name: data.name,
          signal: JSON.parse(data.signal),
          image: data.image,
          started_by: Number(data.started_by),
          connectionId: Number(data.connectionId),
          role: Number(data.role),
          dts: {},
          dynamic_image: Number(data.dynamic_image),
          userRole: Number(data.userRole),
          company_name: data.company_name,
          company_logo: data.company_logo,
          single: data.single,
          show: false,
        });
        setTimeout(() => {
          if (acceptClick.current) {
            acceptClick.current.click();
          }
        }, 300);
      }
      //  acceptClick.current.click();
      setCallTrue(true);
    }
    if (data.type === "decline" && declineClick.current) {
      declineClick.current.click();
    }
  }

  const CheckAudioAccess = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some((device) => device.kind === "audioinput");
      if (hasCamera) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (stream) {
          setMute(true);
          return stream;
        } else {
          alert("stream not found");
          setMute(false);
          return false;
        }
      } else {
        alert("hasCamera not found");
        setMute(false);
        return false;
      }
    } catch (e) {
      setMute(false);
      return false;
    }
  };

  const checkVideoAccess = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some((device) => device.kind === "videoinput");
      if (hasCamera) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        if (stream) {
          setVideo(true);
          setMute(true);
          return stream;
        } else {
          setVideo(false);
          return false;
        }
      } else {
        setVideo(false);
        return false;
      }
    } catch (e) {
      setVideo(false);
      return false;
    }
  };

  const speedtest = async () => {
    // if (navigator.connection) {
    //   console.log(
    //     `Effective network type: ${navigator.connection.effectiveType}`
    //   );
    //   console.log(`Downlink Speed: ${navigator.connection.downlink}Mb/s`);
    //   console.log(`Round Trip Time: ${navigator.connection.rtt}ms`);
    // } else {
    //   console.log("Navigator Connection API not supported");
    // }
  };

  const completeOfferPayment = async () => {
    setCurrentUser((prevState) => ({ ...prevState, permission: 1 }));
    socket.emit("updatePermission", {
      id: currentUser.connection_user,
      permission: 1,
    });
  };

  const logout = async () => {
    try {
      if (dt && dt !== undefined) {
        const dtss = { type: "logout" };
        window.ReactNativeWebView.postMessage(JSON.stringify(dtss));
      }
    } catch (e) {}
    try {
      await logoutfun();
      localStorage.removeItem("chats");
      localStorage.removeItem("showApprovedStatus");
      dispatch(clearUser());
      dispatch(clearToken());
      serviceWorkerRegistration.unregister();
      if (dt && dt !== undefined) {
        navigate("/loader");
        localStorage.removeItem("dt");
      } else {
        navigate("/");
        window.location.href = "/";
      }
    } catch (e) {}
  };

  const callTo = async (ids, status, st) => {
    try {
      const ad = await CheckAudioAccess();
      if (ad && ad !== null) {
        setHasCamera(null);
        setStream(ad);
        setCallDecline(false);
        setCallingSms("");
        callUser(ids, ad, status, st);
        setTimeout(() => {
          conn = true;
        }, 1000);
      } else {
        setUserModel(false);
        toast.error("Webcam or microphone not accessible");
      }
    } catch (e) {
      toast.error(e.message);
      setHasCamera(e.message);
    }
  };

  const callToStatic = (ids) => {
    socket.emit("callUserTemo", { from: selector.auth.uid, to: ids });
  };

  const setAnsFun = async () => {
    try {
      if (mute === true || video === true) {
        let currentStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (currentStream && currentStream !== null) {
          startCallFun();
          setStream(currentStream);
          setAns(true);
          setAccess(true);
          use=false;
          // navigate("/Calling");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Your device not compatible for call");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const ChatfirstEmits = () => {
    try {
      socket.emit("fetch_chat_users", { id: "" });
      socket.on("chatSidebar", (data) => {
        if (data && data.length > 0) {
          setChatAvail(true);
          setChatUser(data);
          for (let index = 0; index < data.length; index++) {
            socket.on(`offOn-${data[index].connection_user}`, (datas) => {
              updateObjectInArray(datas);
            });
          }
        }
      });
      socket.on("chatSidebarSms", (data) => {
        if (data && data.length > 0) {
          audio.play();
          setChatAvail(true);
          setChatUser(data);
          for (let index = 0; index < data.length; index++) {
            socket.on(`offOn-${data[index].connection_user}`, (datas) => {
              updateObjectInArray(datas);
            });
          }
        }
      });
    } catch (e) {}
  };

  const setChatLimitFun = (screenWidth) => {
    if (screenWidth < 599.99) {
      setChatLimit(1);
    } else if (screenWidth < 899.99 && screenWidth > 600) {
      setChatLimit(2);
    } else if (screenWidth < 1199.99 && screenWidth > 900) {
      setChatLimit(3);
    } else if (screenWidth < 1499.99 && screenWidth > 1200) {
      setChatLimit(4);
    } else {
      setChatLimit(5);
    }
  };

  const updateObjectInArray = (data) => {
    setChatUser((current) =>
      current.map((obj) => {
        if (obj.connection_user === data.id) {
          return {
            ...obj,
            video_status: data.video_status ? "active" : "inactive",
            online: data.online,
          };
        }
        return obj;
      })
    );

    if (currentUser.user_id && currentUser.user_id === data.id) {
      setCurrentUser((prevData) => ({
        ...prevData,
        online: data.online,
        video_status: data.video_status ? "active" : "inactive",
      }));
    }

    setAllChats((current) =>
      current.map((obj) => {
        if (obj.connection_user === data.id) {
          return {
            ...obj,
            video_status: data.video_status ? "active" : "inactive",
            online: data.online,
          };
        }
        return obj;
      })
    );
  };

  const OffOn = (data) => {
    socket.emit("changeOnOf", {
      video_status: data,
      id: selector.auth.uid,
      online: data,
    });
    socket.on(`offOn-${selector.auth.uid}`, (datas) => {
      updateObjectInArray(datas);
    });
  };

  const setCurrent = (data) => {
    try {
      setCurrentUser(data);
      $(".test").addClass("main");
      socket.on(`offOn-${data.connection_user}`, (datas) => {
        let dg = data;
        dg.online = datas.online;
        setCurrentUser(dg);
        updateObjectInArray({
          online: datas.online,
          id: datas.id,
        });
      });

      socket.emit("fetch_sidebar_of_users", {
        connect: data.connection_user,
        user: data.user_id,
      });

      socket.on("fetch_sidebar_of_users", (dataf) => {
        if (dataf && dataf.length > 0) {
          const di = dataf.filter((x) => x.file_type === "image");
          setImages(() => di);
          const da = dataf.filter(function (x) {
            return x.file_type === "application";
          });
          setFile(da);
        } else {
          setImages([]);
          setFile([]);
        }
      });
    } catch (e) {}
  };

  const findChatFun = (data) => {
    try {
      socket.emit("findChat", data);
      socket.on("findChat", (data) => {
        if ((dt && dt !== "undefined") || screenWidth < 767) {
          setCurrentUser(data);
          navigate("/messages", {
            state: {
              nav: true,
            },
          });
          socket.on(`offOn-${data.connection_user}`, (datas) => {
            let dg = data;
            dg.online = datas.online;
            setCurrentUser(dg);
          });
        } else {
          const sameChat = allChats.find(
            (el) => el.connection_user === data.connection_user
          );
          if (!sameChat) {
            let chats = [data, ...allChats];
            setAllChats(chats);
            socket.on(`offOn-${data.connection_user}`, (datas) => {
              updateObjectInArray(datas);
            });
            localStorage.setItem("chats", JSON.stringify(chats));
          }
        }
      });
      // setIsPlaying(true);
      // setTimeout(() => {
      //   setIsPlaying(false);
      // }, 10000);
    } catch (e) {}
  };

  const answerCall = () => {
    try {

      if (peeer) {
        peeer.destroy();
        setPeer(null);
      }
      if (stream && stream !== null) {
        setAccess(true);
        const peer = new Peer({ initiator: false, trickle: false, stream });
        peer.on("signal", (data) => {
          if (!use) {
            socket.emit("answerCall", {
              signal: data,
              to: call.from,
              single: call.single,
              roleFrom: call.role,
              started_by: call.started_by,
              me: selector.auth.uid ? selector.auth.uid : selector.auth.id,
              dts: selector.auth,
              connectionId: call.connectionId,
            });
            use = true;
          }
        });
        peer.on("stream", (currentStream) => {
          setUserView(currentStream);
        });
        // peer._pc.oniceconnectionstatechange = () => {
        //   const iceConnectionState = peer._pc.iceConnectionState;
        //   console.log(`ICE Connection State: ${iceConnectionState}`);

        //   if (
        //     iceConnectionState === "closed" ||
        //     iceConnectionState === "failed" ||
        //     iceConnectionState === "disconnected"
        //   ) {
        //     //  attemptReconnection(false);
        //   }
        // };
        peer.on('close',()=>{
          //  closePeer();
        });
        peer.on('error',(error)=>{
           closePeer();
        });
        peer.signal(call.signal);
        setCallUser(call.from);
        setLastCall({
          id: call.from,
          started_by: call.started_by,
          name: call.name,
          image: call.image,
          permission: call.permission,
          dynamic_image: call.dynamic_image,
          userRole: call.userRole,
        });
        setAns(false);
        setCallAccepted(true);
        setCallType("another");
        setPeer(peer);
        peerRef.current = peer;
    
      } else {
        toast.error("Stream not fetch");
      }
      // startCallFun();
      socket.on("AlreadyAccepted", (data) => {
        stopCamera(-1);
        setCallAccepted(false);
        toast.error(data.message, { position: toast.POSITION.TOP_RIGHT });
      });
    } catch (e) {}
  };

  const finUser = (id) => {
    socket.emit("findedUser", { id: id });
    socket.on("findedUser", ({ data }) => {
      setFindAgnt(data);
    });
  };

  const switchToVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((newStream) => {
        if (callMode == "audio") {
          // newStream.getAudioTracks().forEach(track => stream.addTrack(track));
          newStream.getAudioTracks().forEach(async function (track) {
            if (mute) {
              track.enabled = true;
              stream.addTrack(track);
            } else {
              track.enabled = false;
              stream.addTrack(track);
            }
          });
          newStream.getVideoTracks().forEach((track) => stream.addTrack(track));
          const newPeer = new Peer({
            initiator: true,
            trickle: false,
            stream: newStream,
          });
          newPeer.on("signal", (data) => {
            socket.emit("offer", {
              call: call.connectionId ? call.connectionId : connectId,
              id: currentUser.callUserId ? currentUser.callUserId : callUserId,
              signal: data,
            });
          });
          newPeer.on("stream", (remoteStream) => {
            setUserView(remoteStream);
          });
          newPeer.on("close", () => {
            closePeer();
          });
          newPeer.on("error", () => {
            closePeer();
          });
          setVideoOffer(false);
          setCallMode("video");
          setStream(newStream);
          setPeer(newPeer);
        }
      })
      .catch((error) => console.error("Error accessing media devices.", error));
  };

  const handleVideoOffer = async (data) => {
    try {
      const peerN = new Peer({
        initiator: false,
        trickle: false,
        stream: stream,
      });
      peerN.on("signal", (data) => {
        socket.emit("answer", {
          call: call.connectionId ? call.connectionId : connectId,
          id: currentUser.callUserId ? currentUser.callUserId : callUserId,
          signal: data,
        });
      });
      peerN.on("stream", (stream) => {
        setUserView(stream);
      });
      // peerN.on('close',()=>{closePeer();});
      // peerN.on('error',()=>{ closePeer();});
      peerN.signal(data.signal);
      setPeer(peerN);
      if (callMode == "audio" && !videoOffer) {
        setVideoOffer(true);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleAnswer = (data) => {
    try {
      if (peeer) {
        peeer.signal(data.signal);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // const attemptReconnection = (ini) => {
  //   // if (peeer) {
  //   //   peeer.destroy();
  //   // }
  //   // setTimeout(() => {
  //     try{
  //     const newPeer = new Peer({
  //       initiator: ini, 
  //       trickle: false,
  //       stream
  //     });
  
  //     newPeer.on('signal', data => {
  //       console.log('call----------------->',call);
  //       console.log('latCall--------------->',lastCall);
  //       let us = lastCall.id;
  //       alert('uuuu '+us);
  //       socket.emit('signal', { signal: data, id: us });
  //     });
  
  //     newPeer._pc.oniceconnectionstatechange = () => {
  //       const iceConnectionState = newPeer._pc.iceConnectionState;
  //       console.log(`ICE Connection State: ${iceConnectionState}`);
  
  //       if (iceConnectionState === 'closed' || iceConnectionState === 'failed' || iceConnectionState === 'disconnected') {
  //        alert('ice '+iceConnectionState);
  //         //  attemptReconnection();
  //       }
  //     };
  
  //     newPeer.on('error', err => {
  //       console.error('Peer Error:', err);
  //       if (newPeer._pc) {
  //         const iceConnectionState = newPeer._pc.iceConnectionState;
  //         console.log(`ICE Connection State during error: ${iceConnectionState}`);
  //       }
  //     });
  
  //     newPeer.on('stream', stream => {
  //       setUserView(stream);
  //     });
  
  //     // navigator.mediaDevices.getUserMedia({audio: true }).then(stream => {
  //     //   if (localVideoRef.current) {
  //     //     localVideoRef.current.srcObject = stream;
  //     //   }
  //     //    newPeer.addStream(stream);
  //     // });
  
  //     setPeer(newPeer);
  //     // if (signal) {
  //     //   socket.emit('signal', { signal: signal});
  //     // }
  //   // }, 2000);
  //   }catch(e){
  //     alert(e.message);
  //   }
  // };





  const callUser = async (id, st, status, permission) => {
    try {
      if (peeer) {
        peeer.destroy();
        setPeer(null);
      }
      const rd = Math.floor(1000 + Math.random() * 9000);
      setOnCall(false);
      setCallDecline(false);
      setCallingSms("");
      checkUserReady();
      if (conn) {
        conn = false;
        try {
          let me = selector.auth?.uid ? selector.auth?.uid : selector.auth.id;
          let ids = id;
          localStorage.setItem("branchV", ids);
          localStorage.setItem("AgentV", ids);
          const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: st,
          });

          peer.on("signal", (data) => {
            if (data) {
              socket.emit("callUser", {
                userToCall: ids,
                signalData: data,
                from: me,
                name: selector.auth.name,
                image: selector.auth.profile_image,
                dts: selector.auth,
                single: status,
                permission: permission,
                random: rd,
              });
            }
          });
          peer.on("stream", (currentStream) => {
            setUserView(currentStream);
          });

          peer._pc.oniceconnectionstatechange = () => {
            const iceConnectionState = peer._pc.iceConnectionState;
            console.log(`ICE Connection State: ${iceConnectionState}`);
      
            // if (iceConnectionState === 'closed' || iceConnectionState === 'failed' || iceConnectionState === 'disconnected') {
            //    attemptReconnection(true);
            // }
          };
          peer.on('error',(error)=>{
             closePeer();
          });
          peer.on("close", () => {
            socket.off("callAccepted");
            //  closePeer();
          });

          // peer.on('close',()=>{closePeer();});
          // peer.on('error',()=>{ closePeer();});

          socket.on("callAccepted", (data) => {
            setUserModel(false);
            if (data.signal && data.signal !== null) {
              peerRef.current = peer;
              startCallFun();
              localStorage.setItem("AgentV", data.acceptedBy);
              localStorage.setItem("connV", data.connectionId);
              peer.signal(data.signal);
              setPeer(peer);
              setCall((prevState) => ({
                ...prevState,
                callUserId: data.acceptedBy,
                permission: data.permission,
                name: data.name,
                image: data.image,
                dynamic_image: data.dynamic_image,
                started_by: data.started_by,
                userRole: data.userRole,
                company_name: data.company_name,
              }));
              setCurrentUser((prevState) => ({
                ...prevState,
                callUserId: data.acceptedBy,
                permission: data.permission,
                name: data.name,
                image: data.image,
                dynamic_image: data.dynamic_image,
                started_by: data.started_by,
                userRole: data.userRole,
                company_name: data.company_name,
              }));
              setCallType("own");
              setCallAccepted(true);
              setAccess(true);
              setLastCall({
                id: data.acceptedBy,
                name: data.name,
                image: data.image,
                permission: data.permission,
                dynamic_image: data.dynamic_image,
                userRole: data.userRole,
                started_by: data.started_by,
              });
              setCallUser(data.acceptedBy);
              myConnect(data.connectionId);
              startRecording();
           
            } else {
              stopCamera(-1);
              setCallAccepted(false);
              CutTheCall();
              toast.error(data.message, { position: toast.POSITION.TOP_RIGHT });
            }
          });

          socket.on("connections", (data) => {
            myConnect(data.id);
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    } catch (error) {
      setOnCall(true);
    }
  };

  const handleMute = (vid, mt) => {
    try {
      socket.emit("callAction", {
        userToCall: currentUser.callUserId
          ? currentUser.callUserId
          : callUserId,
        video: vid,
        type: type,
        call_price: call_price,
        call: true,
        mute: mt,
      });
    } catch (e) {}
  };
  const stopCamera = (v) => {
    setCallAccepted(false);
    if (access) {
      const dt = localStorage.getItem("dt");
      try {
        if (stream || peerRef.current || access) {
          stream.getTracks().forEach((track) => track.stop());
          setStream(null);
          //setCallUser(0);
          setCall({});
          setFindAgnt([]);
          setUserView(null);
          setCallMode("audio");
          setVideoOffer(false);
          if (callTimerIntervalApi.current) {
            clearInterval(callTimerInterval.current);
          }
          setStartTime(null);
        } else {
          stream.getTracks().forEach((track) => track.stop());
        }
        if (peeer) {
          peeer.destroy();
        }
        if (peerRef && peerRef.current) {
          peerRef.current.destroy();
          peerRef.current = null;
        }
        setOnCall(false);
        setPeer(null);
        setCallingSms("");
        setCallDecline(false);
        setIsPlaying(true);
        setAccess(false);
        setTimeout(() => {
          setIsPlaying(false);
        }, 10000);
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  const CutTheCall = () => {
    try {
      setUserModel(false);
      const idp =
        connectId && connectId !== null ? connectId : call.connectionId;
      socket.emit("cuttCallFromDailerHit", {
        connectionId: idp,
      });
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      stopCamera(-1);
    } catch (e) {
      //alert(e.massage);
      stopCamera(-1);
    }
    // setTimeout(() => {
    //   navigate("/");
    //   // window.location.reload();
    // }, 300);
  };

  const cut = (status, qid, from) => {
    try {
      socket.emit("CallCut", {
        id: from,
        qid: qid,
        status: status,
      });
    } catch (e) {}
  };

  const leaveCall = async (callDurations, d_type) => {
    try {
      //if((connectId!==null) || (call.connectionId && call.connectionId!==null)){
      if (callDurations && callDurations !== 0) {
        stopRecording();
        const record = {
          userToCall: currentUser.callUserId
            ? currentUser.callUserId
            : callUserId,
          call: false,
          call_price: call_price,
          video: video,
          d_type: d_type,
          type: type,
          mute: mute,
          duration: callDurations,
          connectionId:
            connectId && connectId !== null ? connectId : call.connectionId,
          media: "",
          permission: currentUser.permission ? currentUser.permission : 0,
          from: currentUser.callUserId ? currentUser.callUserId : callUserId,
        };
        socket.emit("callAction", record);
        setIsPlaying(true);
        setTimeout(() => {
          setIsPlaying(false);
        }, 10000);
        setCallAccepted(false);
        setVideo(true);
        myConnect(null);
        setMute(true);
        setCallEnded(true);
        switch (d_type) {
          case "1":
            setDeclineCase("1");
            break;
          case "2":
            setDeclineCase("3");
            break;
          case "3":
            setDeclineCase("5");
            break;
          default:
            break;
        }
        if (mediaBlobUrl) {
          const meta = await blobToBase64(mediaBlobUrl);
          if (meta) {
            try {
              let data = {
                userToCall: currentUser.callUserId
                  ? currentUser.callUserId
                  : callUserId,
                uid: selector.auth?.uid ? selector.auth?.uid : selector.auth.id,
                call: false,
                mute: mute,
                connectionId:
                  connectId && connectId !== null
                    ? connectId
                    : call.connectionId,
                media: meta,
              };
              let respo = await addMedia(data);
              stopCamera(-1);
            } catch (e) {
              stopCamera(-1);
            }
          } else {
            stopCamera(-1);
          }
        } else {
          stopCamera(-1);
        }
      } else {
        toast.error("something went wrong");
        stopCamera(-1);
      }
      setTimeout(() => {
        setIsPlaying(false);
      }, 10000);
      //}
    } catch (e) {
      stopCamera(-1);
    }
  };

  const startCallFun = () => {
    // if(startTime===null){
    const vl = new Date().getTime();
    setStartTime(vl);
    // }
  };

  // useEffect(() => {
  //   try{
  //     if(access){
  //         peeer.on('close', (e) => {
  //           // alert(e);
  //           // alert(e.message);
  //           closePeer();
  //       });
  //         peeer.on('error', (e) => {
  //           alert(e.message);
  //           closePeer();
  //       });
  //       return () => {
  //         peeer.off('close', closePeer);
  //          peeer.off('error', closePeer);
  //       };
  //     }
  //   }catch(e){
  //     console.log(e.message);
  //   }
  // }, [peeer, startTime, connectId, call.connectionId, access]);

  const closePeer = () => {
    setUserModel(false);
    socket.off("callAccepted");
    setCall({});
    setCallAccepted(false);
    setAccess(false);
    clearInterval(callTimerInterval.current);
    const endTime = new Date().getTime();
    const durationInSeconds = Math.floor((endTime - startTime) / 1000);
    leaveCall(durationInSeconds, "3");
  };

  return (
    <SocketContext.Provider
      value={{
        expendMode,
        setExpendMode,
        deleteModal,
        setDeleteModal,
        lastCall,
        setLastCall,
        walletMsg,
        setWalletMsg,
        setImages,
        setFile,
        allChats,
        setAllChats,
        call,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        name,
        finUser,
        video,
        setVideo,
        setName,
        vchat,
        setVChat,
        callEnded,
        me,
        setAnsFun,
        setCurrentChat,
        offer,
        sidebar,
        calltouser,
        calltoSet,
        rattingPop,
        setRattingPop,
        setSideBar,
        socket,
        setSocket,
        mute,
        showEditProfile,
        setShowEditProfile,
        fAgnt,
        setMute,
        setOffer,
        callTo,
        sidebarImages,
        sidebarFile,
        setShow,
        status,
        show,
        ans,
        startRecording,
        stopRecording,
        findChatFun,
        handleMute,
        userMute,
        OffOn,
        setCurrentUser,
        callUser,
        leaveCall,
        CheckAudioAccess,
        checkVideoAccess,
        userView,
        callUserId,
        answerCall,
        connectId,
        ChatfirstEmits,
        chatUser,
        hasCamera,
        type,
        setHasCamera,
        declineCall,
        setDeclineCall,
        setCall,
        isPlaying,
        setIsPlaying,
        currentUser,
        setChatUser,
        chatlist,
        mchat,
        completeOfferPayment,
        callToStatic,
        modalChat,
        CutTheCall,
        acceptClick,
        mediaBlobUrl,
        setAns,
        logout,
        userVideoStatus,
        setCurrent,
        // setStillOnline,
        declineCase,
        setDeclineCase,
        // stillOnline,
        callTimerIntervalApi,
        imageVideoShow,
        setImageVideoShow,
        setChatLimitFun,
        chatLimit,
        cut,
        callingSms,
        setChatLimit,
        screenWidth,
        startTime,
        videoOffer,
        chatavail,
        declineClick,
        setChatAvail,
        speedtest,
        onCall,
        myConnect,
        callTimerInterval,
        setOnCall,
        callTrue,
        declineOwnClick,
        setCallTrue,
        switchToVideo,
        setCallDecline,
        setCallingSms,
        access,
        addFundsModal,
        userModel,
        setUserModel,
        setAddFundsModal,
        setFindAgnt,
        setAccess,
        callMode,
        setVideoOffer,
        callDecline,
        smsCount,
        setSmsCount,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
export { ContextProvider, SocketContext };
