import { Link } from "react-router-dom";

const CommonPopUp = ({
  parentStyle,
  childStyle,
  heading,
  title,
  description,
  imgSrc,
  path,
  linkTitle,
  onHide,
}) => {
  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className={parentStyle}>
          {heading && <h1>{heading}</h1>}
          <div className={childStyle}>
            <img src={imgSrc} alt="" />
          </div>
          <h2>{title}</h2>
          <p>{description}</p>

          <Link to={path} className="btn" onClick={onHide}>
            {linkTitle}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CommonPopUp;
