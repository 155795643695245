import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CategoryBox = ({
  parentStyle,
  childStyle,
  name,
  image,
  help_questions,
  slug,
  type,
  ques_slug,
  handleUserNavigate,
}) => {
  const navigate = useNavigate();

  return (
    <div className={parentStyle}>
      <div className={childStyle}>
        <img src={image} alt="helpiconK_1" />
        <h3
          onClick={() => {
            navigate(`${slug.toLowerCase()}`);
            handleUserNavigate(
              "navigate",
              `${slug}`,
              "navigate to help category"
            );
          }}
          style={{ cursor: "pointer" }}
        >
          {name}
        </h3>
        <ul>
          {help_questions
            .split("----")
            .slice(0, 2)
            .map((ques, index) => (
              <li
                onClick={() => {
                  navigate(
                    `${
                      type === "partners"
                        ? "/partners/help/"
                        : "/customers/help/"
                    }${slug.toLowerCase()}/${ques
                      .split("||||")[2]
                      .trim()
                      .toLowerCase()}`,
                    { state: image }
                  );
                  handleUserNavigate(
                    "navigate",
                    `${
                      type === "partners"
                        ? "/partners/help/"
                        : "/customers/help/"
                    }${slug}/${ques.split("||||")[2].trim()}`,
                    "navigate to single FAQ"
                  );
                }}
              >
                {ques.split("||||")[1]}
              </li>
            ))}
        </ul>
        <Link
          className="seeall"
          to={`${slug.toLowerCase()}`}
          onClick={() => {
            handleUserNavigate(
              "navigate",
              `${slug}`,
              "navigate to help category"
            );
          }}
          state={image}
        >
          See all <i className="bi bi-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default CategoryBox;
