import React from "react";
import Faq from "./Faq";
import PopularTags from "./PopularTags";

const MainContent = ({ description, faq, tags }) => {
  return (
    <div className="col-md-8 col-xxl-9">
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
      {faq && <Faq data={faq} />}
    </div>
  );
};

export default MainContent;
