import axios from "axios";
import { backEndUrl, baseUrl, secondUrl } from "./network";
import { io } from "socket.io-client";

export const AppListingsuser = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(
      `${secondUrl}v3/application_listings_user`,
      {},
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const Updateappreview = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/Updateappreview`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const SingleListing = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/singleapplication`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};
export const Allmyapplications = async (body) => {
  try {
    const token = localStorage.getItem("token");
    console.log("thisdata", token);
    console.log("thisdata1", body);
    return await axios.post(
      `${secondUrl}v3/application_listings_user`,
      // `${secondUrl}v3/application_listings_staff`,
      body,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
export const Allmyapplicationsstaff = async (body) => {
  try {
    const token = localStorage.getItem("token");
    console.log("thisdata", token);
    console.log("thisdata1", body);
    return await axios.post(
      // `${secondUrl}v3/application_listings_user`,
      `${secondUrl}v3/application_listings_staff`,
      body,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const Savecheclistdata = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/Savechecklistdata`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const Savecollegedetails = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/Savecollegedetails`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};
export const Checklists = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(
      `${secondUrl}v3/checklists`,
      {},
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const socketFun = async (token) => {
  const socket = io(backEndUrl, {
    auth: {
      token: token,
    },
  });
  return socket;
};

export const phoneVerificationApi = async (body) => {
  try {
    return await axios.post(`${baseUrl}phone_Verification`, body);
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (body) => {
  try {
    return await axios.post(`${baseUrl}verifyOtp`, body);
  } catch (error) {
    throw error;
  }
};

export const fillDetailsApi = async (body, token) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${baseUrl}update_profile`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const addMoreBranchApi = async (body, token) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${baseUrl}add_branch`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const logoutfun = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v3/logout`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const studentPhoneVerificationApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/phone_Verification`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};

export const studentOtpVerificationApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/verifyOtp`, body);
  } catch (error) {
    throw error;
  }
};

export const registerAvatarApi = async (body, token) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v1/registration`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getAvatarApi = async (token) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v1/avtar`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const checkVersionApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/check_version`, body);
  } catch (error) {
    throw error;
  }
};

export const studentProfileUpdateApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v1/update_profile`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const firstVerificationApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v2/register`, body);
  } catch (error) {
    throw error;
  }
};

export const UsersNavigations = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/UsersNavigates`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const kycVerificationApi = async (body) => {
  const tokenn = localStorage.getItem("token");

  try {
    return await axios.post(`${secondUrl}v2/kyc`, body, {
      headers: {
        "x-access-token": tokenn,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const checkBranchApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v2/check_phone`, body);
  } catch (e) {
    throw e;
  }
};

export const updateFirstVerificationApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v2/register_update`, body, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
  } catch (e) {
    throw e;
  }
};

export const availableCompanyNameApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v2/avail_company`, body);
  } catch (e) {
    throw e;
  }
};

export const matchLocationApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/MatchLocation`, body);
  } catch (e) {
    throw e;
  }
};

export const topFourBlogs = async () => {
  try {
    const { data } = await axios.get(`${secondUrl}v3/top_four_blog`);
    const response = data.data;
    return response;
  } catch (error) {
    throw error;
  }
};

export const allBlogs = async () => {
  try {
    const { data } = await axios.get(`${secondUrl}v3/blogs`);
    const response = data.data;
    return response;
  } catch (error) {
    throw error;
  }
};

export const blogByCategories = async (id) => {
  try {
    const { data } = await axios.post(`${secondUrl}v3/BlogByCategory`, { id });
    const response = data.data;
    return response;
  } catch (error) {
    throw error;
  }
};

export const singleBlogApi = async (slug) => {
  try {
    const { data } = await axios.post(`${secondUrl}v3/SingleBlog`, { slug });
    const response = data.data;
    return response;
  } catch (error) {
    throw error;
  }
};

export const homeSearchApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/searching`, body);
  } catch (error) {
    throw error;
  }
};

export const bussinessAccountDetailApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v2/bussinessAccountDetail`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const blogsByCatOrTagApi = async (body) => {
  try {
    const { data } = await axios.post(`${secondUrl}v3/BlogByCatOrTag`, body);
    const response = data.data;
    return response;
  } catch (error) {
    throw error;
  }
};

export const profileUpdateRequestApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v2/profile_request`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const changeNumberSendOtpApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/send_otp_change`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const changeNumberOtpVerifyApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/verify_update`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const helpApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/Helps`, body);
  } catch (error) {
    throw error;
  }
};

export const singleHelpApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/SingleHelp`, body);
  } catch (error) {
    throw error;
  }
};

export const singleDetailsHelpApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/HelpByCategories`, body);
  } catch (error) {
    throw error;
  }
};

export const getUserProfileDataApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/GetUserProfile`, body);
  } catch (e) {
    throw e;
  }
};

export const getSpecializationDataApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v2/specializationSearch`, body, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
  } catch (e) {
    throw e;
  }
};

export const updateAgentProfileApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v2/updateProfilePage`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const getAgentProfileApi = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v2/profile`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const getAfterSearchResultApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/Filter`, body);
  } catch (error) {
    throw error;
  }
};

export const getSpecializationFilterApi = async () => {
  try {
    const { data } = await axios.get(`${secondUrl}v3/Specialization`);
    const responseData = data.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDetailsApi = async (slug) => {
  try {
    const { data } = await axios.post(`${secondUrl}v3/company`, slug);
    const responseData = data.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getReportCategoriesApi = async () => {
  try {
    const { data } = await axios.get(`${secondUrl}v3/report_categories`);
    const responseData = data.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getCompanyMediaApi = async (body) => {
  try {
    const { data } = await axios.post(`${secondUrl}v3/Media`, body);
    const responseData = data.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const claimBusinessApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/claimProfile`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const addBranchFormApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v2/add_branch`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const addStaffApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v2/addStaff`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const getStaffApi = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v2/child_staff`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const getDocs = async () => {
  try {
    const { data } = await axios.get(`${secondUrl}v3/docs`);
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updateStaffStatusApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v2/updateStatusOfChildUser`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const branchListApi = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v2/child_branches`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const getOfferDetail = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${backEndUrl}/apis/v3/OfferDetail`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const paymentZone = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${backEndUrl}/apis/v3/pay-add`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getUserDetail = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${backEndUrl}/apis/v3/Udetail`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const addMedia = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${backEndUrl}/apis/v3/addMedia`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const videoReviewRattingApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/saveView`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const checkReviewDetailApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/CheckReviewDetail`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const onlineOflineApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/OnlineOfline`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const followUnfollowApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/followUnfollow`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const fetchReviewApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v2/fetch_review`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const fetchChat = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/chat`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const chatSearchApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/ChatSearch`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const searchChatUsers = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/searchingChatUser`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const localCountsAgentsApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/LocalAgents`, body);
  } catch (e) {
    throw e;
  }
};

export const checkPoint = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(
      `${secondUrl}v3/CheckPoint`,
      {},
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const Calling = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/Calling`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const checkUserReady = async () => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${secondUrl}v3/checkUserReadyOrNot`, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const checkCall = async (body) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/checkCall`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const updateEngage = async (data) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.post(`${secondUrl}v3/updateEngage`, data, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const findExpertPlanFun = async () => {
  try {
    const response = await axios.get(`${secondUrl}v3/ExpertPlans`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const expertPayFun = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/forExpertPay`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSpecializationForBoostFun = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v2/UserSpecialization`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchBoostPlan = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v2/fetchBoostPlan`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const purchaseBoost = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v2/purchaseBoost`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getActiveBoostPlans = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v2/currentBoostPlan`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMoneyToWallet = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/addmoney`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getWalletBalance = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v3/myChownkBalance`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTopTenNotification = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v3/toptenNotification`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateNotificationStatus = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/updateNotiStatus`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllNotification = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${secondUrl}v3/fetchAllNotifications`,
      body,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const addViewProfileAction = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/ViewProfileAction`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const ProfileViewGraphData = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v2/fetchDashboardGraph`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCallHistory = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/CallRecords`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTransactionHistory = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/transactions`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const dashBoardGraphs = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v2/graphs`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPopularCities = async () => {
  try {
    const response = await axios.get(`${secondUrl}v3/popular`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const welcomeApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/notified`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getServiceLocations = async (body) => {
  try {
    const hasSearch = body.search !== undefined && body.search !== null;
    const hasPopular = body.popular !== undefined && body.popular !== null;
    let url = `${secondUrl}v3/service_locations?`;
    if (hasSearch) {
      url += `search=${body.search}`;
    }
    if (hasPopular) {
      url += `${hasSearch ? "&" : ""}popular=${body.popular}`;
    }
    if (hasSearch || hasPopular) {
      const response = await axios.get(url);
      return response;
    } else {
      const response = await axios.get(`${secondUrl}v3/service_locations`);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getCitiesRoutes = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/getCitiesRoutes`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveRefer = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/Refer`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const emailCheck = async (email, user_id) => {
  try {
    const response = await axios.post(`${secondUrl}v3/checkEmail`, {
      email,
      user_id,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEmail = async (email) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/emailUpdate`, email, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveCallDetail = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/smsCall`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAccountApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/deleteAccount`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const helpsByCategory = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/categoryByfaq`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUpdatedDataApi = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v3/UserDetail`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveUserNavigate = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/UsersNavigates`, body);
  } catch (error) {
    throw error;
  }
};

export const getAnalyticDetail = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v2/analytics`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getAnalyticsFollowers = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${secondUrl}v2/analyticsFollowers`,
      body,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const getAnalyticsCompitator = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${secondUrl}v2/Competitor`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const GetSearchCompetitor = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v2/SearchCompetitor`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response.data.data;
  } catch (e) {
    throw e;
  }
};

export const deleteCompitator = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v2/deleteCompetitor`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const AddCompetitor = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${secondUrl}v2/AddCompetitor`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const relatedSearches = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/RelatedNavigates`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTopBlogsApi = async () => {
  try {
    const response = await axios.get(`${secondUrl}v3/TopBlog`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchBlogs = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/searchBlog`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDynamicFaqs = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/dynamic_faq`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const panVerificationApi = async (body, authToken) => {
  try {
    const response = await axios.post(
      `https://api.attestr.com/api/v2/public/checkx/pan`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const gstVerificationApi = async (body, authToken) => {
  try {
    const response = await axios.post(
      `https://api.attestr.com/api/v2/public/corpx/gstin`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkCardsApi = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/checkCards`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllBlogsApi = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/chownkBlogs`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSimilarBlogsApi = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/SimilarBlog`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
