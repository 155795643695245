import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../../../../Reducer/applicationSlice";
import RecentactivityApp from "./Recentactivity";
const OverviewApp = () => {
  const dispatch = useDispatch();
  // const [listt, setList] = useState([]);
  const list = useSelector((state) => state.application?.single);

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  return (
    <div
      className="tab-pane fade show active"
      id="Overview"
      role="tabpanel"
      aria-labelledby="Overview-tab"
    >
      <div className="row">
        <div className="col-lg-8">
          <div className="main_s">
            <div className="Progress">
              <div className="row">
                <div className="col-sm-8">
                  <h6>Progress</h6>
                  <p>
                    STAY INFORMED AND TRACK YOUR PROGESS REPORT
                    <br />
                    printing & typesetting.
                  </p>
                </div>
                <div className="col-sm-4 ">
                  <p>
                    In progress : <span>25%</span>{" "}
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table mb-0">
                <tr>
                  <th>Start Date</th>
                  <th>Due Date</th>
                  <th>Category</th>
                  <th>College</th>
                  <th className="text-end">Status</th>
                </tr>
                <tr>
                  <td>{new Date(list?.created_at).toUTCString()}</td>
                  <td>{new Date(list?.expiery_unix).toUTCString()}</td>
                  <td>Study Visa</td>
                  <td>{list?.college_name}</td>
                  <td className="text-end">
                    <b>{list?.payment_status}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mt-3 mt-lg-0">
          <RecentactivityApp />
        </div>
      </div>
    </div>
  );
};

export default OverviewApp;
