import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  AddCompetitor,
  GetSearchCompetitor,
  deleteCompitator,
} from "../../../../services/api";
import { mediaUrl } from "../../../../services/network";
import { analyticsCompitator } from "../../../../Reducer/analyticSlice";

const CompetitorModel = ({ onHide }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.analytic.compitator);
  const [list, setList] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //  if (e.target.elements.search.value !== "") {
    //    const name = e.target.elements.search.value;
    //    const data = await GetSearchCompetitor({ search: name });
    //    setList(data);
    //  } else {
    //    setList([]);
    //  }
  };

  const handleChange = async (e) => {
    try {
      if (e.target.value === "") {
        setList([]);
      } else {
        const name = e.target.value;
        const data = await GetSearchCompetitor({ search: name });
        setList(data);
      }
    } catch (error) {}
  };

  const deleteId = async (id) => {
    const data = await deleteCompitator({ id: id });
    dispatch(analyticsCompitator());
  };

  const addData = async (id) => {
    const data = await AddCompetitor({ id: id });
    const updatedItems = list.filter((item) => item.id !== id);
    setList(updatedItems);
    dispatch(analyticsCompitator());
  };

  return (
    <div class="modal-content">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => onHide()}
      ></button>
      <div class="modal-body pb-0">
        <h3 class="mt-0">Edit Competitors</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="search"
            placeholder="Search by page name"
            onChange={handleChange}
          />
          <button type="submit">
            <i class="bi bi-search"></i>
          </button>
          {list && list.length > 0 ? (
            <ul className={"compSearch"}>
              {list.map((x, i) => {
                return (
                  <li>
                    {x.company_name}
                    <span onClick={() => addData(x.id)}>
                      <i class="bi bi-plus-lg"></i>
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </form>
        <table class="table">
          <tbody>
            {selector != null &&
              selector.followers &&
              selector.followers.map((x, i) => {
                return (
                  <tr>
                    <td>
                      <div
                        class="img"
                        style={{
                          backgroundImage: `url(${mediaUrl + x.company_image})`,
                        }}
                      ></div>
                    </td>
                    <td>
                      <h6>{x.company_name}</h6>
                      <p>{x.address}</p>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => deleteId(x.cid)}
                      >
                        <i class="bi bi-trash3-fill"></i>
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div class="modal-footer pt-0 pb-5">
        <button type="button" class="btn btn-primary" onClick={() => onHide()}>
          Cancel
        </button>
      </div>
    </div>
  );
};
export default CompetitorModel;
