import React, { useEffect, useState } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import { mediaUrl } from "../../../../services/network";

const AddressTab = ({ currentStep, errors, register, setValue, getValues }) => {
  const [file, setFile] = useState("");

  useEffect(() => {
    const address = getValues("address_proof");
    if (address) {
      console.log(address, "Address");
      setFile(`${mediaUrl}${address}`);
    }
  }, []);

  const handleChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setValue("address_proof", e.target.files[0], { shouldValidate: true });
  };

  return (
    <div
      className="tab"
      style={{ display: currentStep === 2 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          2. Upload Address Proof
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>
                To Build Trust and Show Customers that your Company is
                legitimate
              </p>
              <a
                href="../../assets/images/select11.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select11.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
        <p>Types of Document Allowed</p>
        <select
          className="form-control"
          name="address_type"
          {...register("address_type")}
        >
          <option value="Business Proof , Utility Proof">
            Business Proof , Utility Proof
          </option>
          <option value="Bank Statement Specifying">
            Bank Statement Specifying
          </option>
          <option value="Address, Govt Authorised Document">
            Address, Govt Authorised Document
          </option>
          <option value="Specifying Name and Address">
            Specifying Name and Address
          </option>
        </select>
        {errors && errors["address_type"] && (
          <p>{errors["address_type"].message}</p>
        )}
      </div>

      {!file && (
        <div className="form-group">
          <div className="upload">
            <SimpleInput
              inType={"file"}
              name={"address_proof"}
              // event={register}
              accept={"image/* , application/pdf"}
              onChange={(e) => handleChange(e)}
            />
            <img src="../../assets/images/uploadimg.png" alt="uploadimg" />
            <h6>Drop your files here!</h6>
            <p className="mb-0">or click</p>
          </div>
          <p>Allowed file formats: PDF, JPG, PNG Maximum file size: 20MB</p>
        </div>
      )}
      {file && (
        <div className="form-group">
          <div className="file">
            <img
              src={
                file.type === "application/pdf"
                  ? "../../assets/images/pdf.png"
                  : file
              }
              alt=""
              className="w-100"
            />
            <i
              className="bi bi-x"
              onClick={() => {
                setFile("");
              }}
            ></i>
          </div>
          {errors && errors["address_proof"] && (
            <p style={{ color: "red" }}>{errors["address_proof"].message}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressTab;
