import { useContext, useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import {
  fetchBoostPlan,
  getSpecializationForBoostFun,
} from "../../../services/api";
import { SocketContext } from "../../../Context";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const Boost = ({ onHide }) => {
  const [boostData, setBoostData] = useState({
    from: new Date().toJSON().slice(0, 10),
    to: "",
  });
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [sp, setSp] = useState("");
  const [sps, setSps] = useState([]);
  const [boostAmt, setBoostAmt] = useState("");
  const [boostDays, setBoostDays] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
  const [boostName, setBoostName] = useState("");
  const [gst, setGst] = useState(0);
  const [boostId, setBoostId] = useState("");
  const [error, setError] = useState({
    date: "",
    city: "",
  });
  const { addFundsModal, setAddFundsModal } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleData = (e) => {
    setBoostData({ ...boostData, [e.target.name]: e.target.value });
  };

  const getCities = async () => {
    try {
      const response = await getSpecializationForBoostFun();
      if (response.status === 200) {
        let spec_data = response.data.data.sp;
        let city_data = response.data.data.cities;

        spec_data = spec_data.map((item) => {
          return {
            ...item,
            name: item.name,
            value: item.id,
          };
        });
        setSps(spec_data);
        city_data = city_data.map((item) => {
          return {
            ...item,
            name: item.city,
            value: item.id,
          };
        });
        setCities(city_data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCities();
    getBoostPlan();
  }, []);

  const getBoostPlan = async () => {
    try {
      const response = await fetchBoostPlan();
      if (response.data.status) {
        const amount = response.data.data.amount;
        setBoostId(response.data.data.id);
        setBoostAmt(Number(amount));
        setGst(Number(response.data.data.gst));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (boostData.to !== "") {
      let date1 = new Date(boostData.from);
      let date2 = new Date(boostData.to);

      let Difference_In_Time = date2.getTime() - date1.getTime();

      let Difference_In_Days = Math.ceil(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      if (!isNaN(Difference_In_Days) && Math.sign(Difference_In_Days) === 1) {
        let total = boostAmt * Difference_In_Days;
        setBoostDays(Difference_In_Days);
        setTotalCharges(total);
        setError({ ...error, date: "" });
      } else {
        setTotalCharges(0);
        setError({ ...error, date: "please select the future date" });
      }
    }
  }, [boostData.to, boostAmt]);

  useEffect(() => {
    if (boostData.from !== "") {
      let boostTo = moment(new Date(boostData.from))
        .add(7, "d")
        .format("YYYY-MM-DD");
      setBoostData({ ...boostData, to: boostTo });
    }
  }, [boostData.from]);

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          <img
            src="../../assets/images/boost_profile.png"
            alt="boost_profile"
          />{" "}
          BOOST PROFILE
        </h5>
      </div>
      <div className="modal-body">
        <p>
          Boosting is a demonstrated method to highlight your profile. Companies
          that activate their boosting badge receive <span>up to 50%</span> more
          invitations.
        </p>
        <p>
          Cost to turn on the badge is <span>{`INR ${boostAmt} per day`}</span>{" "}
          , Note Price will vary according to Competitors . How does it Works ?
        </p>
        <div className="set_start">
          <div className="form-group">
            <label>Set a Start date and End Date</label>
            <div className="row">
              <div className="col-6 col-md-4">
                <div className="form-control">
                  <span>
                    <i className="bi bi-calendar2-minus"></i> From
                  </span>
                  <input
                    type="date"
                    value={boostData.from}
                    name="from"
                    onChange={handleData}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    disabled
                  />
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="form-control">
                  <span>
                    <i className="bi bi-calendar2-minus"></i> To
                  </span>
                  <input
                    type="date"
                    value={boostData.to}
                    name="to"
                    onChange={handleData}
                    min={moment(new Date(boostData.from))
                      .add(1, "d")
                      .format("YYYY-MM-DD")}
                    max="9999-12-31"
                  />
                </div>
              </div>
            </div>
          </div>
          {error && error.date && error.date !== "" && (
            <p style={{ color: "red" }}>{error.date}</p>
          )}

          <p>
            Total charge : <b>{`INR ${totalCharges}`}</b>
          </p>
          <div className="form-group">
            <label>Select Specialization</label>
            <div className="row">
              <div className="col-sm-6">
                <SelectSearch
                  options={sps}
                  search={true}
                  placeholder="Select Specialization"
                  name="sp"
                  value={sp}
                  onChange={(e) => {
                    setSp(e);
                    const spec = sps.find((el) => el.id === e);
                    setBoostName(spec.name);
                  }}
                />
              </div>
            </div>
            {error && error.sp && error.sp !== "" && (
              <p style={{ color: "red" }}>{error.sp}</p>
            )}
          </div>
          <div className="form-group">
            <label>Select City</label>
            <div className="row">
              <div className="col-sm-6">
                <SelectSearch
                  options={cities}
                  search={true}
                  placeholder="Select City"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e)}
                />
              </div>
            </div>
            {error && error.city && error.city !== "" && (
              <p style={{ color: "red" }}>{error.city}</p>
            )}
          </div>
        </div>
        <p>
          You have <span>INR {selector.funds}</span> in your Chownk Account.{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setAddFundsModal(true);
            }}
          >
            Add More Funds
          </a>
        </p>
      </div>
      <div className="modal-footer b-none mt-4">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onHide()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (boostDays > 0) {
              if (sp == "") {
                setError({ ...error, sp: "Please select the specialization" });
                return;
              }
              if (city == "") {
                setError({ ...error, city: "Please select the city" });
              } else {
                setError({ ...error, sp: "", city: "" });
                navigate("/payment-summary", {
                  state: {
                    days: boostDays,
                    total: totalCharges,
                    city: city,
                    sp: sp,
                    boostData: boostData,
                    gst: gst,
                    boostId: boostId,
                    boostfrom: boostData.from,
                    boostto: boostData.to,
                    boost_type: boostName,
                  },
                });
              }
            }
          }}
        >
          Start Boosting
        </button>
      </div>
    </>
  );
};
export default Boost;
