import { singleProfileOption } from "../../../../services/SingleProfileDetailReviews";
import Button from "../../../Tools/Button";
import NavItem from "../../../Tools/Navitem";

const SingleProfileOption = ({ active, setActiveTab }) => {
  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {singleProfileOption.length > 0 &&
        singleProfileOption.map((item) => {
          return (
            <NavItem
              id={item.id}
              active={active == item.name && "active"}
              title={item.title}
              iconStyle={item.iconStyle}
              onClick={() => setActiveTab(item.name)}
            />
          );
        })}
    </ul>
  );
};
export default SingleProfileOption;
