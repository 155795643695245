export const agentProfile = [
  {
    label: "Overview",
    iconStyle: "bi bi-info-circle-fill",
    name: "overview",
  },
  {
    label: "Success Stories",
    iconStyle: "bi bi-youtube",
    name: "success_stories",
  },
  // {
  //   label: "Reviews",
  //   iconStyle: "bi bi-stars",
  //   name: "reviews",
  // },
  {
    label: "Faq",
    iconStyle: "bi bi-question-diamond-fill",
    name: "faq",
  },
];

export const faqData = [
  {
    title: "Lorem Ipsum is simply dummy text",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    title: "Lorem Ipsum is simply dummy text ?",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    title: "Lorem Ipsum is simply dummy text ?",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    title: "Lorem Ipsum is simply dummy text ?",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

export const reviewsData = [
  {
    image: "../../assets/images/user.png",
    name: "Kunal Banda",
    rating: 5,
    time: "10:30 AM  10 June 2023",
    description:
      "Highly recommend this visa consultant! They demonstrated immense expertise and professionalism, ensuring I had all the necessary documents and information for a successful application.",
  },
  {
    image: "../../assets/images/user.png",
    name: "Jaswinder Singh",
    rating: 5,
    time: "10:30 AM  10 June 2023",
    description:
      "Five-star service! From the initial consultation to the final approval, the visa consultant was with me every step of the way."
  },
  {
    image: "../../assets/images/user.png",
    name: "Meavwe Willy 3",
    rating: 5,
    time: "10:30 AM  10 June 2023",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    image: "../../assets/images/user.png",
    name: "Ankit",
    rating: 5,
    time: "10:30 AM  10 June 2023",
    description:
      "Outstanding visa consultancy! Their efficiency and dedication made the visa application process seamless."
  },
  {
    image: "../../assets/images/user.png",
    name: "Meavwe Willy 5",
    rating: 5,
    time: "10:30 AM  10 June 2023",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    image: "../../assets/images/user.png",
    name: "Meavwe Willy 6",
    rating: 5,
    time: "10:30 AM  10 June 2023",
    description:
      "Lorem Ipsum is simply dummy text for printing and typesetting industry. Lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

export const popularInHashTag = [
  {
    label: "#Immigration consultants",
    path: "#",
  },
  {
    label: "#Baby sitting at home",
    path: "#",
  },
  {
    label: "#Student visa assistance",
    path: "#",
  },
  {
    label: "#Immigration consultants for uk",
    path: "#",
  },
  {
    label: "#Immigration consultants for europe",
    path: "#",
  },
  {
    label: "#Immigration consultants for USA",
    path: "#",
  },
  {
    label: "#Immigration consultants for singapore",
    path: "#",
  },
];
