import React from "react";
const SearchBarWithIcon = ({
  style,
  inputStyle,
  placeholder,
  handleSubmit,
  onChange,
  areaLabel,
  buttonStyle,
  buttonOnClick,
  buttonInType,
  icon,
  value,
}) => {
  return (
    <form onSubmit={handleSubmit} className={style}>
      <input
        type="text"
        name="search"
        className={inputStyle}
        placeholder={placeholder}
        onChange={onChange}
        aria-label={areaLabel}
        value={value}
      />
      <button
        className={buttonStyle}
        type={buttonInType ? buttonInType : "submit"}
        onClick={buttonOnClick}
      >
        <i className="bi bi-search"></i>
        {icon}
      </button>
    </form>
  );
};

export default SearchBarWithIcon;
