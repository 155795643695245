import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  changeMobileNumberSchema,
  loginSchema,
} from "../../../services/schema";
import Button from "../../Tools/Button";
import CrouselItem from "../../Tools/Crouselitem";
import FormInputField from "../../Tools/Forminput";
import Example from "../../Tools/ReactBoostrap";
import OtpModel from "./otp";
import { Link } from "react-router-dom";
import {
  setAction,
  setOldPhoneOtp,
  setOtp,
  setPhoneNumber,
  setOldPhoneNumber,
} from "../../../Reducer/authSlice";
import { changeNumberSendOtpApi, checkBranchApi } from "../../../services/api";
import ChangeMobileNumberOtp from "./changeMobileNumberOtp";

const ChangeMobileNumberModel = ({ showOtp, setShowOtp, onHide }) => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [available, setAvailable] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const { phone } = useSelector((state) => state.auth.auth);
  const { otp, oldPhoneOtp } = useSelector((state) => state.auth);
  const [input, setInputs] = React.useState({
    phoneNumber: "",
    oldPhoneNumber: phone,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(changeMobileNumberSchema),
  });

  React.useEffect(() => {
    setValue("oldPhone", phone);
  }, []);

  const onSubmit = ({ oldPhone, newPhone }) => {
    dispatch(setAction("changeMobileNumberModel"));
    const body = {
      oldPhone,
      phone: newPhone,
    };

    changeNumberSendOtpApi(body)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });

          dispatch(setOldPhoneOtp(response.data.first));
          dispatch(setOtp(response.data.second));
          dispatch(setOldPhoneNumber(oldPhone));
          dispatch(setPhoneNumber(newPhone));
          setShowOtp(!showOtp);
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };
  return (
    <>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={onHide}
      ></button>
      <div className="modal-body p-0">
        <div className="row">
          <div className="col-md-6">
            <div className="lest_login text-center">
              <img
                src="../../../assets/images/chownk-logo.png"
                alt="CHOWNK logo"
              />
              <h3 className="text-center">{"Change Number"}</h3>
              <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
                <FormInputField
                  parentStyle={"form-group"}
                  childStyle={"form-control"}
                  inType={"text"}
                  event={register}
                  label={"Current mobile number"}
                  name={"oldPhone"}
                  onChange={(e) => {
                    setInputs((prev) => ({
                      ...prev,
                      oldPhoneNumber: e.target.value,
                    }));
                  }}
                  placeholder={"Enter your Old mobile number here..."}
                  disabled={true}
                />

                <FormInputField
                  parentStyle={"form-group"}
                  childStyle={"form-control"}
                  inType={"text"}
                  event={register}
                  label={"Enter your mobile number"}
                  message={!available ? message : ""}
                  name={"newPhone"}
                  onChange={(e) => {
                    setInputs((prev) => ({
                      ...prev,
                      phoneNumber: e.target.value,
                    }));
                    if (
                      e.target.value.length === 10 &&
                      !isNaN(e.target.value)
                    ) {
                      setMessage("");
                      setAvailable(false);
                      checkBranchApi({ phone: e.target.value })
                        .then((response) => {
                          if (response.data.available) {
                            clearErrors("newPhone");
                            setMessage(response.data.message);
                            setAvailable(response.data.available);
                          } else {
                            setAvailable(response.data.available);
                            setError("newPhone", {
                              type: "custom",
                              message: response.data.message,
                            });
                          }
                        })
                        .catch((e) => {
                          toast.error(e.response.data.message, {
                            position: toast.POSITION.TOP_LEFT,
                          });
                        });
                    } else {
                      setError("newPhone", {
                        type: "custom",
                        message: "Please fill the phone number correctly",
                      });
                      setMessage("");
                    }
                  }}
                  placeholder={"Enter your New mobile number here..."}
                />

                {errors && errors.newPhone && (
                  <p style={{ color: "red" }}>{errors.newPhone.message}</p>
                )}

                <Button
                  inType={"submit"}
                  style={"btn mt-2 mt-lg-5"}
                  title={"Continue"}
                  disabled={Object.keys(errors).length === 0 ? false : true}
                  inlineStyle={
                    input.phoneNumber?.length === 10 &&
                    input.oldPhoneNumber?.length === 10
                      ? { backgroundColor: "#1DBF73", color: "white" }
                      : {}
                  }
                />
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right_login">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <p>
                  <div className="column">
                    <h3 className="no-puck text-white">
                      {" "}
                      Trust
                      <span className="highlight"> CHOWNK </span>
                      to navigate the complexities of international immigration
                      seamlessly, ensuring you're well-informed and supported
                      every step of the way.{" "}
                    </h3>
                    <div className="mob video-demonstration">
                      <div
                        className="youtube-video"
                        data-lumberjack-action="partnerships.landing_page.video"
                      ></div>
                    </div>
                    <ul className="bullet-list">
                      <li>Secure Video 1 to 1 Call with Experts</li>
                      <li>Chat and Messaging</li>
                      <li>Recording Options</li>
                      <li>Document Checklist</li>
                      <li>Client Portal</li>
                      <li>Informational Content</li>
                      <li>Document Sharing</li>
                      <li>Feedback and Review System</li>
                      <li>Notification Alerts</li>
                    </ul>
                    <div className="become-partner-container mob">
                      {/* <p>
                        <i className="bi bi-arrow-left"></i>{" "}
                        <strong>Join Now</strong>
                      </p> */}
                    </div>
                  </div>
                </p>
              </div>
              {/* <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <div className="carousel-inner">
                  <CrouselItem
                    parentStyle={"carousel-item active"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                  <CrouselItem
                    parentStyle={"carousel-item"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                  <CrouselItem
                    parentStyle={"carousel-item"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                </div>
                <div className="carousel-indicators">
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"0"}
                    style={"active"}
                    ariaCurrent={"true"}
                    ariaLabel={"Slide 1"}
                  />
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"1"}
                    ariaLabel={"Slide 1"}
                  />
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"1"}
                    ariaLabel={"Slide 2"}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeMobileNumberModel;
