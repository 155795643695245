import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import FeesContent from "./content";
import StudentFooter from "../../../Layouts/Student/Footer";
import Meta from "../Meta";
import { FeesAndChargesMetaTags } from "../../../../data/metaTags";

const FeesAndCharges = () => {
  return (
    <>
      <Meta {...FeesAndChargesMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"Chownk Fees and Charges"} />
      <FeesContent />
      <StudentFooter />
    </>
  );
};

export default FeesAndCharges;
