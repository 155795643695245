import { useContext, useEffect, useState } from "react";
import Find from "./Find";
import VideoCallModal from "./VideoCallModal";
import { useSelector } from "react-redux";
import AddFundsModal from "../../Layouts/Model/addFunds";
import { SocketContext } from "../../../Context";
const FindUser = ({ data, show, onHide, single }) => {
  const [conn, setConn] = useState(
    single && single !== undefined ? true : false
  );
  const { funds, call_min } = useSelector((state) => state.auth);
  const {callTrue} = useContext(SocketContext);
  useEffect(()=>{
    if(callTrue){
      onHide();
    }
  },[callTrue])
  
  
  return (
    <div className="viewpro clickcall">
      <>
        {conn && conn == true ? (
          <Find onHide={onHide} data={data} single={single} />
        ) : (
          <VideoCallModal
            show={show}
            onHide={onHide}
            data={data}
            setConn={setConn}
          />
        )}
      </>
    </div>
  );
};
export default FindUser;
