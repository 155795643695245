import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginModel from "../LoginModel";
import { findExpertFun } from "../../../services/api";

const AskExpertButton = () => {
  const [show, setShow] = useState(false);
  const selector = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    if (selector && selector.auth) {
      // findExpert();
      navigate("/payment-summary");
    } else {
      setShow(true);
    }
  };

  // const findExpert = async () => {
  //   try {
  //     const response = await findExpertFun();
  //     if (response.status === 200) {
  //       navigate("/payment-summary");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <a className="btn" onClick={handleClick}>
        <img src="assets/images/connect_icon.png" alt="connect_icon" /> CONNECT
        WITH EXPERT
      </a>
      <LoginModel
        show={show}
        setShow={setShow}
        redirect={window.location.href}
      />
    </>
  );
};

export default AskExpertButton;
