const NavTabs = ({ style, id, active, data, setActiveTab }) => {
  return (
    <div className="codeul">
      <ul className={`nav nav-tabs ${style}`} id={id} role="tablist">
        {data &&
          data.length > 0 &&
          data.map((item, i) => {
            return (
              <li
                key={i}
                className="nav-item"
                role="presentation"
                id={item.id}
                onClick={() => setActiveTab(item.name)}
              >
                <button
                  className={`nav-link ${active === item.name && "active"}`}
                  type="button"
                >
                  {item.imageSrc ? (
                    <img src={item.imageSrc} alt="analyticsicon1" />
                  ) : (
                    <i className={item.iconStyle}></i>
                  )}{" "}
                  {item.label}
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default NavTabs;
