import MembershipPlanBox from "../../../Tools/MembershipPlanBox";

const FeesSection = () => {
  const membershipData = [
    {
      image: "../../assets/images/application1.png",
      type: "Free",
      typeDescription: "Suggested for Individuals",
      currency: "USD",
      pricePerMonth: "0/month",
      currentActive: "Free",
      currentPlan: "Currently Active",
      pricePerMonthDescription: "Lorem ipsum is dummy text",
      items: ["10", "0", "01", "05", "----", "----", "----", "----", "----"],
    },
    {
      image: "../../assets/images/favourites1.png",
      type: "Premium",
      typeDescription: "For Mid-Level Companies & Growing Organizations",
      currency: "USD",
      pricePerMonth: "10/month",
      currentActive: "Free",
      currentPlan: "Try this plan",
      pricePerMonthDescription: "$10/month after 7 days",
      items: [
        "Unlimited",
        "1000",
        "03",
        "60",
        "Available",
        "Available",
        "Available",
        "Available",
        "Available",
      ],
    },
    {
      image: "../../assets/images/favourites1-1.png",
      type: "Gold",
      typeDescription: "For Mid-Level Companies & Growing Organizations",
      currency: "USD",
      pricePerMonth: "0/month",
      currentActive: "Free",
      currentPlan: "Contact Sales",
      pricePerMonthDescription: "Lorem ipsum is dummy text",
      items: [
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
      ],
    },
    {
      image: "../../assets/images/Platinum.png",
      type: "Platinum",
      typeDescription:
        "For Companies who want to increase there presence round the Globe",
      currency: "USD",
      pricePerMonth: "0/month",
      currentActive: "Free",
      currentPlan: "Contact Sales",
      pricePerMonthDescription: "Lorem ipsum is dummy text",
      items: [
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
      ],
    },
    //  {
    //     image: "../../assets/images/premium1.png",
    //     type: "Platinum",
    //     typeDescription: "For Companies who want to increase there presence round the Globe",
    //     currency: "USD",
    //     pricePerMonth: "0/month",
    //     currentActive: "Free",
    //     currentPlan: "Currently Active",
    //     pricePerMonthDescription: "Lorem ipsum is dummy text",
    //     items: ["----", "----", "----", "----", "----", "----", "----", "----", "----"]

    // }
  ];
  return (
    <section className="Membership">
      <div className="container">
        <div className="row ">
          <div className="col-3">
            <div className="Plans">
              <h3 className="mb-2 mt-2">Pricing Plans</h3>
              <img
                className="w-100 mb-3"
                src="../../assets/images/Pricing_Plans.png"
                alt="Pricing_Plans"
              />
              <ul>
                <li>Charges Per Accepted Offer*</li>
                <li>Chat Sessions Per User**</li>
                <li>Total Minutes for Video Calls</li>
                <li>Staff Count</li>
                <li>Total Branches can add</li>
                <li>Successful Stories</li>
                <li>Analytics Report</li>
                <li>Boost your Profile</li>
                <li>Outside Platform Promotion</li>
                <li>Goodies</li>
              </ul>
              <p>*Lorem Ipsum</p>
              <p>*Lorem Ipsum</p>
            </div>
          </div>
          {membershipData &&
            membershipData.map((i) => {
              return (
                <MembershipPlanBox
                  image={i.image}
                  type={i.type}
                  typeDescription={i.typeDescription}
                  currency={i.currency}
                  pricePerMonth={i.pricePerMonth}
                  currentActive={i.currentActive}
                  currentPlan={i.currentPlan}
                  pricePerMonthDescription={i.pricePerMonthDescription}
                  items={i.items}
                />
              );
            })}
          {/* {/* */}

          {/* <div className="col-3 ">
                    <div className="set" style="background-image: url(./images/application1.png);">
                        <h3>Free</h3>
                        <h4>Suggested for Individuals</h4>
                        <h5>USD <span>0/month</span></h5>
                        <div className="text-center">
                            <a href="#" className="btn btnm">Currently Active</a>
                        </div>

                        <p>Lorem ipsum is dummy text</p>

                        <h6>USD 5</h6>
                        <ul>
                            <li>10</li>
                            <li>0</li>
                            <li>01</li>
                            <li>05</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                        </ul>
                    </div>
                </div>

                <div className="col-3">
                    <div className="set" style="background-image: url(./images/favourites1.png);">
                        <h3>Premium</h3>
                        <h4>For Mid-Level Companies & Growing Organizations</h4>
                        <h5>USD <span>10/month</span></h5>
                        <div className="text-center">
                            <a href="#" className="btn">Try this plan</a>
                        </div>

                        <p>$10/<span>month</span> after 7 days</p>

                        <h6>USD 1</h6>
                        <ul>
                            <li>Unlimited</li>
                            <li>1000</li>
                            <li>03</li>
                            <li>60</li>
                            <li>Available</li>
                            <li>Available</li>
                            <li>Available</li>
                            <li>Available</li>
                            <li>Available</li>
                        </ul>
                    </div>
                </div>

                <div className="col-3">
                    <div className="set" style="background-image: url(./images/favourites1-1.png);">
                        <h3>Gold</h3>
                        <h4>For Companies who want to increase there presence round the Globe</h4>
                        <h5></h5>
                        <div className="text-center">
                            <a href="#" className="btn">Contact Sales</a>
                        </div>

                        <p>Lorem ipsum is dummy text</p>

                        <h6>----</h6>
                        <ul>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                        </ul>
                    </div>
                </div>

                <div className="col-3">
                    <div className="set" style="background-image: url(./images/premium1.png);">
                        <h3>Platinum</h3>
                        <h4>For Companies who want to increase there presence round the Globe</h4>
                        <h5></h5>
                        <div className="text-center">
                            <a href="#" className="btn">Contact Sales</a>
                        </div>
                        <p>Lorem ipsum is dummy text</p>
                        <h6>----</h6>
                        <ul>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                            <li>----</li>
                        </ul>
                    </div>
                </div> */}
        </div>
      </div>
    </section>
  );
};
export default FeesSection;
