const user = JSON.parse(localStorage.getItem("user"));

export const documentApiAuthToken =
  "T1gwRkRMLXBXM2V4dGZscXVKLjhiZDEyMjIzZTBhNDc5ZjExOGE5ZmEyMzk0NGFmNGZlOjhlZTc2MWYyYTY5YjdmOTY3ZWNlYWE1OTI3ODc5M2M1ODhkNjQxYmUxOWQ1YzFhOQ==";

  export const googleMapApi = "AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw";

  export const Roptions = {
    key: "rzp_live_eu2MHBIpUKNEmh",   //LIVE KEYS
    amount: "50000",
    currency: "INR",
    name: "Chownk",
    description: "Credits towards consultation",
   // image: "https://i.imgur.com/3g7nmJC.png",
    order_id: "order_9A33XWu170gUtm",
    handler: function (response) {
     // console.log("response----------------------------------->", response);
     // alert(response.razorpay_payment_id);
     // alert(response.razorpay_order_id);
     // alert(response.razorpay_signature);
    },
    prefill: {
      email: "jyoti@chownk.com",
      contact: "+918427955568",
      name: "Chownk",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };
  

export const whyChooseData = [
    {
      image1: "../assets/images/expert-network.png",
      image2: "../assets/images/expert-networkW.png",
      title: "Expert Network",
      para: `Chownk hosts a vast network of authorized consultants and
      language trainers holding a valid license. Compare them
      based on pricing, reviews, etc., and connect with the one
      that meets your needs`,
      alt: "Expert Network on CHOWNK",
    },
    {
      image1: "../assets/images/personalized-assistance-chownk.png",
      image2: "../assets/images/personalized-assistance-chownkW.png",
      title: "Personalized Assistance",
      para: ` Find and connect with agents according to your specific
      needs. Whether you need a study visa, tourist visa, or work
      visa, contact a consultant with tailored guidance.`,
      alt: "Personalized Assistance on CHOWNK",
    },
    {
      image1: "../assets/images/narrow-your-search-chownk.png",
      image2: "../assets/images/narrow-your-search-chownkW.png",
      title: "Narrow down your search",
      para: `Chownk allows users to filter consultants based on highly
      rated reviews, such as those with 4-star or 5-star ratings.
      Narrow down your list of high-performing agents and
      trainers.`,
      alt: "Narrow Your Search on CHOWNK",
    },
    {
      image1: "../assets/images/instant-chat-video-call-chownk.png",
      image2: "../assets/images/instant-chat-video-call-chownkW.png",
      title: "Instant Chat & Video Call",
      para: ` Engage in real-time interaction with consultants and agents
      directly on our platform, featuring voice and video calls
      for your convenience.`,
      alt: "Instant Chat and Video Call on CHOWNK",
    },
    {
      image1: "../assets/images/secure-document-sharing-chownk.png",
      image2: "../assets/images/secure-document-sharing-chownkW.png",
      title: "Secure Document Sharing",
      para: `Chownk makes it easy for individuals to securely access and
      share documents with their agents within the platform,
      promoting convenient and efficient collaboration.`,
      alt: "Secure Document Sharing on CHOWNK",
    },
  ];

export const WhatIsData = {
    heading: "What is the Purpose of Chownk?",
    subHeading:
      "Simplifying Immigration Journeys — Connecting Users with Best Immigration Consultants",
    para1: `Migrating to another country involves navigating a complex web of rules, often leading aspirants to unauthorized or fraudulent agents. To mitigate risks, such as financial losses or wasted time, Chownk helps you connect with the best immigration consultants, who are reputable and experienced in their field.`,
    para2: `Being a secure immigration platform, Chownk offers various features, including chat, video calls, and document exchange with agents within our platform. It also allows individuals to directly connect with IELTS/TOEFL/PTE trainers and discuss the required course.`,
  };

export const homeStaffMemberColumns = [
  {
    label: "Image",
    name: "image",
  },
  {
    label: "Name",
    name: "name",
  },

  {
    label: "Address",
    name: "address",
  },
  {
    label: "Calls",
    name: "calls",
  },
  {
    label: "Chats",
    name: "chats",
  },
];

export const AccountSettingOptionData = [
  {
    id: "Settingstab1-tab",
    title: "Profile Settings",
    name: "profile_setting",
    imageSrc: "../../assets/images/tabicon1.png",
  },
  {
    id: "Settingstab4-tab",
    title: "KYC Documents",
    name: "kyc_documents",
    imageSrc: "../../assets/images/tabicon4.png",
  },
  {
    id: "Settingstab2-tab",
    title: "Branches",
    name: "branches",
    imageSrc: "../../assets/images/tabicon2.png",
  },
  {
    id: "Settingstab3-tab",
    title: "Staff Members",
    name: "staff_members",
    imageSrc: "../../assets/images/tabicon3.png",
  },
];

export const ChildAccountSettingOptionData = [
  {
    id: "Settingstab1-tab",
    title: "Profile Settings",
    name: "profile_setting",
    imageSrc: "assets/images/tabicon1.png",
  },
  {
    id: "Settingstab4-tab",
    title: "KYC Documents",
    name: "kyc_documents",
    imageSrc: "assets/images/tabicon4.png",
  },
  {
    id: "Settingstab3-tab",
    title: "Staff Members",
    name: "staff_members",
    imageSrc: "assets/images/tabicon3.png",
  },
];

export const TwoTypesUserOption = [
  {
    title: "Immigration Consultant",
    imageSrc: "assets/images/Immigration_Consultant.png",
    name: "immigration_consultant",
  },
  {
    title: "English Institute",
    imageSrc: "assets/images/English_Institute.png",
    name: "english_institute",
  },
];

export const becomeConsultantFooter = {
  quick_links: [
    {
      label: "Contact us",
      path: "/contact",
    },
    {
      label: "About Us",
      path: "/about-us",
    },
    {
      label: "Become a Partner",
      path: "/become-consultant",
    },
    {
      label: "Articles",
      path: "/blogs",
    },
    // {
    //   label: ""
    // }
    //   <span>
    //   <img src={`${mediaUrl}partner.svg`} />
    //   <Link to="/become-consultant">{heading}</Link>
    // </span>
    // {
    //   label: "Become a Partners",
    //   path: "#",
    // },
  ],
  help: [
    {
      label: "For Customers",
      path: "/customers/help",
    },
    {
      label: "For Partners",
      path: "/partners/help",
    },
  ],

  consumer_policy: [
    {
      label: "Fees and Charges",
      path: "/fees-and-charges",
    },
    {
      label: "Privacy Policy",
      path: "/privacy-policy",
    },
    {
      label: "Sitemap",
      path: "/sitemap",
    },
    {
      label: "Terms and Conditions",
      path: "/terms-and-conditions",
    },
    {
      label: "Disclaimer",
      path: "/disclaimer",
    },
  ],

  immigration_consultant1: [
    {
      label: "Immigration Consultants near me",
      path: "#",
    },
    {
      label: "Immigration Consultants in Mohali",
      path: "#",
    },
    {
      label: "Immigration Consultants in Chandigarh",
      path: "#",
    },
    {
      label: "Immigration Consultants in Bathinda",
      path: "#",
    },
    {
      label: "Immigration Consultants in Amritsar",
      path: "#",
    },
    {
      label: "Immigration Consultants in Barnala",
      path: "#",
    },
    {
      label: "Immigration Consultants Faridkot",
      path: "#",
    },
  ],

  immigration_consultant2: [
    {
      label: "Immigration Consultants in kotakpura",
      path: "#",
    },
    {
      label: "Immigration Consultants in Shimla",
      path: "#",
    },
    {
      label: "Immigration Consultants in Manali",
      path: "#",
    },
    {
      label: "Immigration Consultants in Kasoli",
      path: "#",
    },
    {
      label: "Immigration Consultants in Dharmpur",
      path: "#",
    },
    {
      label: "Immigration Consultants in Mansoori",
      path: "#",
    },
    {
      label: "Immigration Consultants Kasol",
      path: "#",
    },
  ],

  training_insitute1: [
    {
      label: "Training Institutes near me",
      path: "#",
    },
    {
      label: "Training Institutes in Mohali",
      path: "#",
    },
    {
      label: "Training Institutes in Chandigarh",
      path: "#",
    },
    {
      label: "Training Institutes in Bathinda",
      path: "#",
    },
    {
      label: "Training Institutes in Amritsar",
      path: "#",
    },
    {
      label: "Training Institutes in Barnala",
      path: "#",
    },
    {
      label: "Training Institutes Faridkot",
      path: "#",
    },
  ],
  training_insitute2: [
    {
      label: "Training Institutes in Kotakpura",
      path: "#",
    },
    {
      label: "Training Institutes in Shimla",
      path: "#",
    },
    {
      label: "Training Institutes in Manali",
      path: "#",
    },
    {
      label: "Training Institutes in Kasoli",
      path: "#",
    },
    {
      label: "Training Institutes in Dharmpur",
      path: "#",
    },
    {
      label: "Training Institutes in Mansoori",
      path: "#",
    },
    {
      label: "Training Institutes in Kasol",
      path: "#",
    },
  ],

  social_media: [
    {
      label: "facebook",
      path: "https://www.facebook.com/people/Chownk/100083289649022/",
    },
    {
      label: "instagram",
      path: "https://www.instagram.com/chownkofficial/",
    },
    {
      label: "linkedin",
      path: "https://www.linkedin.com/company/chownk/",
    },
    {
      label: "youtube",
      path: "https://www.youtube.com/channel/UC3gYIcz4rHJ9vEtm4sKlr1Q",
    },
  ],
};

export const JoinAsOption = [
  {
    imageSrc: "assets/images/join-1.png",
    title: "Immigrations Agents , Consultants or Advisors",
    value: "immigration",
    description: "I assist Students and professionals in relocating overseas.",
  },
  {
    imageSrc: "assets/images/join-2.png",
    title: "English Training Institutes",
    value: "institute",
    description: "I assist Students and Professionals in IELTS/TOEFL/PTE ",
  },
  {
    imageSrc: "assets/images/join-3.png",
    title: "Both",
    value: "both",
    description:
      "I assist Students and Professionals in Immigration and English Training.",
  },
];

export const ratingFilterData = [
  { label: "3 Star Above", value: 3 },
  { label: "4 Star Above", value: 4 },
  { label: "5 Star Above", value: 5 },
];

export const FOR_CHAT_SMS =
  "Try again after sometime or send message instead now";

export const videoModelList = [
  "You will be Charged INR 2.5 per Minute for Call",
  "Ensure stable internet connection.",
  "Remember to turn your MIC & video on.",
  "Choose a quite & well-lit location.",
  "Gather any necessary documents or materials that will be referenced or shared during the call.",
  "Close unnecessary application or tabs to optimize performance.",
];
export async function blobToBase64(blobUrl) {
  try {
    // Fetch the blob data
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    // Convert blob to base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
}
export const popularSearchData = [
  {
    label: "Study Visa Consultants",
    path: "/study-visa",
    value: "Study-Visa",
    image: "../../assets/images/study-visa-consultants-graduation-cap.svg",
    imageBlack: "../../assets/images/study-visa-consultants-graduation-cap.svg",
    type: "specialization",
    alt: "Study Visa Consultants at CHOWNK - Graduation cap",
  },
  {
    label: "IELTS/TOEFL/PTE Institutes",
    path: "/Ielts-toefl-pte-institutes",
    image: "../../assets/images/ielts-toefl-pte-institutes-bulb.svg",
    imageBlack: "../../assets/images/ielts-toefl-pte-institutes-bulb.svg",
    value: ["IELTS", "PTE", "TOEFL", "CAE OR CPE", "CELPIP", "GMAT"],
    type: "specialization",
    alt: "IELTS/TOEFL/PTE Institutes at CHOWNK - Bulb",
  },
  {
    label: "Tourist Visa Consultants",
    path: "/tourist-visa",
    value: "Tourist-Visa",
    image: "../../assets/images/tourist-visa-consultants-camera.svg",
    imageBlack: "../../assets/images/tourist-visa-consultants-camera.svg",
    type: "specialization",
    alt: "Tourist Visa Consultants at CHOWNK - Man with Camera",
  },
  {
    label: "Spouse Visa Consultants",
    path: "/spouse-visa",
    value: "Spouse-Visa",
    image: "../../assets/images/spouse-visa-consultants-couple.svg",
    imageBlack: "../../assets/images/spouse-visa-consultants-couple.svg",
    type: "specialization",
    alt: "Spouse Visa Consultants at CHOWNK - Couple",
  },
  {
    label: "Permanent Residency(PR)",
    path: "/permanent-resident",
    value: "Permanent-Resident",
    image: "../../assets/images/permanent-resident-consultants-id-card.svg",
    imageBlack:
      "../../assets/images/permanent-resident-consultants-id-card.svg",
    type: "specialization",
    alt: "Permanent Resident Consultants at CHOWNK - ID Card",
  },
  {
    label: "Work Visa Consultants",
    path: "/work-visa",
    value: "Work-Visa",
    image: "../../assets/images/work-visa-consultants-suitcase.svg",
    imageBlack: "../../assets/images/work-visa-consultants-suitcase.svg",
    type: "specialization",
    alt: "Work Visa Consultants at CHOWNK - Suitcase",
  },
  {
    label: "Consultants Near Me",
    path: "/near-me",
    image: "../../assets/images/consultant-near-me-search.svg",
    imageBlack: "../../assets/images/consultant-near-me-search.svg",
    type: "location",
    alt: "Consultant Near Me at CHOWNK - Search Icon",
  },
  {
    label: "How to Avoid Fraud",
    path: "/",
    image: "../../assets/images/how-to-avoid-immigration-fraud-desktop.svg",
    imageBlack:
      "../../assets/images/how-to-avoid-immigration-fraud-desktop.svg",
    style: "fraud",
    type: "fraud",
    alt: "How to Avoid Immigration Fraud at CHOWNK - Desktop with Alert Icon",
  },
];

export const ListingFaqs = {
  study_visa: [
    {
      question: "Why should I choose study visa consultants in India for my education plans?",
      answer:
        "Choosing study visa consultants in India provides expert advice tailored for Indian students, helping you navigate the application and visa process efficiently. Study visa consultants in India offer comprehensive services including course selection, document preparation, and visa application assistance",
    },
    {
      question: "How can study visa consultants in India help with my application process?",
      answer:
        "Study visa consultants in India make the application process smoother by guiding you in selecting the right courses and universities, preparing your documents, and applying for your visa. Their services ensure every step, from application to visa approval, is handled professionally.",
    },
    {
      question: "What are the benefits of consulting study visa consultants in India?",
      answer:
        "Study visa consultants in India offer personalized guidance, up-to-date information on visa rules, and continuous support, ensuring your study abroad journey is successful. Their services include application assistance, interview preparation, and post-arrival support.",
    },
    {
      question: "How do study visa consultants in India assist with financial planning?",
      answer:
        "Study visa consultants in India provide insights into scholarships, financial aid, and budgeting tips to help you manage your expenses while studying abroad. Their financial planning services include advice on scholarships, funding options, and cost management."   
    },
    {
      question: "Can study visa consultants in India help with post-arrival services?",
      answer:
        "Yes, study visa consultants in India often offer services like accommodation arrangements, airport pickups, and orientation programs to help you settle in smoothly. Their post-arrival services ensure you have support in your new environment.",
    },
    {
      question: "How do I find study visa consultants on CHOWNK?",
      answer:
        "Visit chownk.com, go to the homepage, click on Study Visa Consultants, apply the required filters, and you’re all set. CHOWNK provides a comprehensive list of study visa consultants in India offering various services",
    },
  ],
  ielts: [
    {
      question: "Why join an IELTS/TOEFL/PTE institute in India?",
      answer:
        "Joining an IELTS/TOEFL/PTE institute in India offers specialized training and experienced instructors who help you achieve higher scores on these important language tests. Their services include structured courses, practice tests, and personalized feedback.",
    },
    {
      question: "How can IELTS/TOEFL/PTE institutes in India improve my test scores?",
      answer:
        "IELTS/TOEFL/PTE institutes in India provide structured courses, practice tests, and personalized feedback to help you identify areas for improvement and boost your scores. Their comprehensive services include study materials, mock exams, and performance analysis."
    },
    {
      question: "What are the advantages of enrolling in an IELTS/TOEFL/PTE institute in India?",
      answer:
        "Enrolling in an IELTS/TOEFL/PTE institute in India gives you access to expert guidance, updated study materials, and a supportive learning environment, ensuring you’re well-prepared for your exams. Their services include one-on-one tutoring and group classes"
    },
    {
      question: "Do IELTS/TOEFL/PTE institutes in India offer online classes?",
      answer:
        "Tourist visa consultants in India provide step-by-step guidance, ensure all your documents are in order, and help you avoid common pitfalls, simplifying the process. Their services include application checks, document verification, and submission",
    },
    {
      question: "How can I choose the best IELTS/TOEFL/PTE institute in India?",
      answer:
       "Consider factors like the institute’s reputation, success rate, experienced faculty, course structure, and student reviews when choosing the best institute. Their services should align with your learning needs and goals."
    },
    {
      question: "How do I find IELTS/TOEFL/PTE institutes on CHOWNK?",
      answer:
       "Visit chownk.com, go to the homepage, click on IELTS/TOEFL/PTE Institutes, apply the required filters, and you’re all set. CHOWNK provides a detailed list of institutes in India offering various preparatory services."
    },
  ],
  tourist_visa: [
    {
      question: "What services do tourist visa consultants in India offer",
      answer:
        "Tourist visa consultants in India help with visa applications, document verification, interview preparation, and travel planning, making the process hassle-free. Their comprehensive services cover every aspect of the visa process.",
    },
    {
      question: "How can tourist visa consultants in India simplify my visa application?",
      answer:
        "Tourist visa consultants in India provide step-by-step guidance, ensure all your documents are in order, and help you avoid common pitfalls, simplifying the process. Their services include application checks, document verification, and submission",
    },
    {
      question: "Why should I hire tourist visa consultants in India for my travel plans?",
      answer:
        "Hiring tourist visa consultants in India ensures professional advice, accurate visa requirements, and timely updates, making your travel plans stress-free. Their services include consultation, application preparation, and travel advice.",
    },
    {
      question: "Can tourist visa consultants in India help with last-minute travel plans?",
      answer:
       "Yes, many tourist visa consultants in India offer expedited services to help you obtain your visa quickly for last-minute travel plans. Their services include fast-track application processing and emergency support."
    },
    {
      question: "What are the benefits of consulting tourist visa consultants in India?",
      answer:
       "Consulting tourist visa consultants in India provides peace of mind, reduces the risk of visa rejection, and saves you time by handling the entire process on your behalf. Their services ensure a smooth and efficient visa application."
    },
    {
      question: " How do I find tourist visa consultants on CHOWNK?",
      answer:
        "Visit chownk.com, go to the homepage, click on Tourist Visa Consultants, apply the required filters, and you’re all set. CHOWNK offers a comprehensive list of tourist visa consultants in India providing various services"
    },
  ],
  spouse_visa: [
    {
      question: "How can spouse visa consultants in India assist with my application?",
      answer:
        "Spouse visa consultants in India guide you on required documents, help you prepare for interviews, and ensure you meet all immigration regulations. Their services include document collection, application preparation, and interview coaching",
    },
    {
      question: "What are the benefits of hiring spouse visa consultants in India?",
      answer:
        "Hiring spouse visa consultants in India makes the process smoother, reduces errors, and increases your chances of visa approval, reuniting you with your spouse sooner. Their services include detailed documentation support and continuous guidance",
    },
    {
      question: "Can spouse visa consultants in India help with visa extensions?",
      answer:
      "Yes, spouse visa consultants in India provide expert advice on eligibility criteria, required documentation, and the application process for visa extensions. Their services cover the extension application process and necessary follow-ups."
    },
    {
      question: "Why choose spouse visa consultants in India for your application?",
      answer:
      "Choosing spouse visa consultants in India offers expert support, personalized advice, and the latest information on visa requirements, making the process stress-free. Their services include initial consultations, application checks, and ongoing assistance."
    },
    {
      question: "How do spouse visa consultants in India ensure successful applications?",
      answer:
        "Spouse visa consultants in India meticulously review your documents, offer interview preparation tips, and guide you through each step of the process. Their comprehensive services ensure a high success rate."
    },
    {
      question: "How do I find spouse visa consultants on CHOWNK?",
      answer:
      "Visit chownk.com, go to the homepage, click on Spouse Visa Consultants, apply the required filters, and you’re all set. CHOWNK provides a detailed list of spouse visa consultants in India with various service offerings"
    },
  ],
  permanent_resident: [
    {
      question: "Why consult PR consultants in India for your application?",
      answer:
        "Consulting PR consultants in India offers expert advice, accurate information on eligibility, and step-by-step guidance, increasing your chances of obtaining permanent residency. Their services include eligibility assessment, documentation, and application support.",
    },
    {
      question: "How can PR consultants in India help with my application process?",
      answer:
        "PR consultants in India assess your eligibility, prepare necessary documents, and provide support throughout the application journey. Their services ensure a thorough and compliant application process.",
    },
    {
      question: "What are the advantages of hiring PR consultants in India?",
      answer:
       "Hiring PR consultants in India makes the process smoother, reduces errors, and enhances your chances of successful PR approval. Their services include detailed document checks, application preparation, and submission."
    },
    {
      question: "Can PR consultants in India assist with post-landing services?",
      answer:
      "Yes, PR consultants in India often offer services like job search assistance, accommodation arrangements, and orientation programs to help you settle in. Their post-landing services ensure a smooth transition to your new country."
    },
    {
      question: "How do PR consultants in India stay updated with immigration laws?",
      answer:
     "PR consultants in India regularly attend training sessions, participate in industry seminars, and keep track of changes in immigration policies. Their continuous learning ensures they provide accurate and up-to-date services."
    },
    {
      question: "How do I find PR consultants on CHOWNK?",
      answer:
      "Visit chownk.com, go to the homepage, click on Permanent Residency (PR) Consultants, apply the required filters, and you’re all set. CHOWNK lists numerous PR consultants in India, each offering a range of services."
    },
  ],
  work_visa: [
    {
      question: "How can work visa consultants in India assist with my application?",
      answer:
        "Work visa consultants in India guide you on required documents, help you prepare for interviews, and ensure you meet all immigration regulations. Their services include document verification, interview coaching, and compliance checks.",
    },
    {
      question: "What are the benefits of hiring work visa consultants in India?",
      answer:
      "Hiring work visa consultants in India makes the process smoother, reduces errors, and increases your chances of visa approval, enabling you to start your job abroad sooner. Their services encompass application processing, document review, and follow-up with immigration authorities."
    },
    {
      question: "Can work visa consultants in India help with visa extensions?",
      answer:
       "Yes, work visa consultants in India provide expert advice on eligibility criteria, required documentation, and the application process for visa extensions. Their services include extension application preparation and submission."
    },
    {
      question: "Why choose work visa consultants in India for your application?",
      answer:
        "Choosing work visa consultants in India offers expert support, personalized advice, and the latest information on visa requirements, making the process stress-free. Their comprehensive services include initial assessment, application support, and ongoing assistance."
    },
    {
      question: "How do work visa consultants in India ensure successful applications?",
      answer:
      "Work visa consultants in India meticulously review your documents, offer interview preparation tips, and guide you through each step of the process. Their detailed services ensure compliance and thorough preparation." 
    },
    {
      question: "How do I find work visa consultants on CHOWNK?",
      answer:
      "Visit chownk.com, go to the homepage, click on Work Visa Consultants, apply the required filters, and you’re all set. CHOWNK lists numerous work visa consultants in India, each providing a range of services."
    },
  ],
  near_me: [
    {
      question: "What services do immigration consultants near me offer?",
      answer:
      "Immigration consultants near you provide comprehensive services including visa application assistance, document preparation, legal advice, and representation in immigration matters."
    },
    {
      question: "How can I choose the best immigration consultant near me?",
      answer:
      "Ensure you select from Chownk’s verified immigration consultants who offer extensive experience, positive client feedback, relevant credentials, and specialization in your specific immigration needs."
    },
    {
      question: "Can immigration consultants near me help with study visas?",
      answer:
      "Yes, verified immigration consultants near you specialize in study visa applications, guiding you through the process to ensure compliance with all requirements."
    },
    {
      question: "How can immigration consultants near me assist with work visas?",
      answer:
      "Chownk’s verified immigration consultants facilitate the process of obtaining work visas by providing expert guidance, document preparation, and personalized support throughout the application process."
    },
    {
      question: "What documents are required for immigration consultants near me to assist with visa applications?",
      answer:
      "Typically, you will need a valid passport, proof of financial stability, educational certificates, and relevant work experience documents. Chownk’s consultants ensure all necessary documents are prepared accurately"  
    },
    {
      question: "Do immigration consultants near me offer assistance with spouse visas?",
      answer:
      "Yes, Chownk’s verified immigration consultants can assist with spouse visa applications, ensuring thorough documentation and adherence to immigration regulations."
    },
    {
      question: "How can I verify the credibility of immigration consultants near me?",
      answer:
      "At Chownk, you only find verified immigration consultants near you. Each consultant undergoes rigorous verification to ensure credibility, including checking credentials and client feedback."
    },
    {
      question: "What are the advantages of using immigration consultants near me?",
      answer:
      "Choosing Chownk’s local immigration consultants offers convenience, specialized local expertise, and personalized service tailored to your specific immigration requirements."
    },
    {
      question: "How long does it typically take to process a visa application with immigration consultants near me?",
      answer:
      "Processing times vary based on visa type and country. Chownk’s immigration consultants provide an estimated timeline based on your specific circumstances and ensure timely processing."
    },
    {
      question: "How do I get started with Chownk’s verified immigration consultants near me?",
      answer:
      "Begin by exploring Chownk’s list of verified immigration consultants near you. Contact them directly through our platform to discuss your immigration goals and begin the consultation process."
    },
  ],
};
export const searchPageData = {
  search: {
    title: "Search Results for Immigration Consultant",
    type: "",
    description:
      "Find the best immigration consultants for various visa services",
  },
  studyVisa: {
    title: "Study Visa Consultants",
    type: "Study Visa",
    description:
      "Find the Best and Top Rated Study Visa Consultants to help you to get Admission Abroad",
  },
  IeltsToeflPteInstitutes: {
    title: "IELTS PTE TOEFL Training Institutes",
    type: "IELTS,PTE,TOEFL",
    description:
      "Find English Training Institutes which help you to Crack IELTS-PTE-TOEFL Exams",
  },
  touristVisa: {
    title: "Tourist Visa Consultants",
    type: "Tourist Visa",
    description: "Find Consultants which help in getting Tourist Visa",
  },
  spouseVisa: {
    title: "Spouse Visa Consultants",
    type: "Spouse Visa",
    description: "Pick the Best Consultants which help you to Get Spouse Visa",
  },
  permanentResidency: {
    title: "Permanent Resident(PR)",
    type: "Permanent Resident",
    description:
      "Find Best Consultants who Can get you Permanent Resident Abroad",
  },
  workVisa: {
    title: "Work Visa Consultants",
    type: "Work Visa",
    description: "Fullfill your Dream of getting Business or Work Visa Abroad",
  },
  nearMe: {
    title: "Consultants Near me",
    type: "",
    description: "Find Best Consultants Near you",
  },
};
export const defaultLocation = {
  address: "Chandigarh, 160022, India",
  city: "Chandigarh",
  latitude: 30.7333148,
  locationStatus: true,
  longitude: 76.7794179,
};
export const standardizedTest = [
  {
    title: "IELTS",
  },
  {
    title: "TOEFL",
  },
  {
    title: "GRE",
  },
];
export const countryInterestedOption = [
  {
    title: "Select country",
  },
  {
    title: "Select country",
  },
  {
    title: "Select country",
  },
];
export const EducationLevelCompleted = [
  {
    title: "10th",
  },
  {
    title: "12th",
  },
  {
    title: "Graduation",
  },
  {
    title: "Diploma",
  },
];