const Option = ({ title, value, style, selected, onChange, disabled }) => {
  return (
    <option
      className={style}
      value={value}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
    >
      {title}
    </option>
  );
};
export default Option;
