import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  helpApi,
  helpsByCategory,
  singleDetailsHelpApi,
  singleHelpApi,
} from "../services/api";
import { toast } from "react-toastify";

export const helpFun = createAsyncThunk(
  "help",
  async (body, { rejectWithValue }) => {
    try {
      const response = await helpApi(body);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      return rejectWithValue(error.response);
    }
  }
);

export const singleHelpFun = createAsyncThunk(
  "singleHelp",
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleHelpApi(body);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      return rejectWithValue(error.response);
    }
  }
);

export const singleDetailsHelpFun = createAsyncThunk(
  "singleDetailsHelp",
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleDetailsHelpApi(body);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      return rejectWithValue(error.response);
    }
  }
);

export const helpsByCat = createAsyncThunk(
  "helpsbycat",
  async (body, { rejectWithValue }) => {
    try {
      const response = await helpsByCategory(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const helpSlice = createSlice({
  name: "help",
  initialState: {
    success: false,
    submitting: false,
    error: null,
    help: [],
    singleHelp: [],
    singleDetailsHelp: [],
    helps_cat: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(helpFun.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(helpFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.help = action.payload.data;
        state.error = null;
      })
      .addCase(helpFun.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(singleHelpFun.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(singleHelpFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.singleHelp = action.payload.data;
        state.error = null;
      })
      .addCase(singleHelpFun.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(singleDetailsHelpFun.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(singleDetailsHelpFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.singleDetailsHelp = action.payload.data;
        state.error = null;
      })
      .addCase(singleDetailsHelpFun.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(helpsByCat.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(helpsByCat.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.helps_cat = action.payload.data;
        state.error = null;
      })
      .addCase(helpsByCat.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default helpSlice.reducer;
