import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const LowercaseUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathname = location.pathname;
    const lowercasePathname = pathname.toLowerCase();
    if (pathname !== lowercasePathname) {
      navigate(lowercasePathname + location.search + location.hash, {
        replace: true,
      });
    }
  }, [location, navigate]);

  return null;
};

export default LowercaseUrl;
