
import { vap } from "./services/network";

export async function register() {
  try {
    const registration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js"
    );
    registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vap,
    });
  } catch (error) {
    console.error("Error subscribing:", error);
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.log("Service Worker unregistration failed:", error);
      });
  }
}
