import React from "react";
import { useNavigate } from "react-router-dom";

const ProfileIncompleteModal = ({ user, selectTab, onHide }) => {
  const navigate = useNavigate();
  return (
    <div>
      <h1>Profile Incomplete</h1>
      <p>
        Your profile needs to be atleast <b>80% completed</b> to go live. Please
        complete the following required fields:
      </p>
      <div>
        <ul>
          {user.pan_status !== "Approved" && (
            <li>
              {" "}
              <b>PAN Card</b>{" "}
            </li>
          )}
          {user.address_status !== "Approved" && (
            <li>
              <b>Business Address Proof</b>
            </li>
          )}
          {!user.company_logo && (
            <li>
              <b>Profile Image</b>{" "}
            </li>
          )}
          {!user.banner_image && (
            <li>
              <b>Banner Image</b>{" "}
            </li>
          )}
          {(!user.specialization ||
            (user.specialization &&
              user.specialization.split(",").length < 5)) && (
            <li>
              <b>Specializations (minimum 5 required)</b>
            </li>
          )}
          {!user.overview_description && (
            <li>
              <b>About Us</b>
            </li>
          )}
          {(!user.stories ||
            (user.stories && JSON.parse(user.stories).length < 3)) && (
            <li>
              <b>Success Stories (minimum 3 required)</b>
            </li>
          )}
          {(!user.faq || (user.faq && JSON.parse(user.faq).length < 3)) && (
            <li>
              <b>FAQ's (minimum 3 required)</b>
            </li>
          )}
        </ul>
        <p>
          Finish these steps to unlock your profile's full potential and go
          live!
        </p>
        <p>Do you want to complete your profile now?</p>
      </div>
      <div className="modal-footer">
        <button
          className="yes"
          onClick={() => {
            selectTab();
            onHide();
          }}
        >
          Yes
        </button>
        <button
          className="no me-2"
          onClick={() => {
            navigate("/partner-dashboard");
            onHide();
          }}
        >
          Later
        </button>
      </div>
    </div>
  );
};

export default ProfileIncompleteModal;
