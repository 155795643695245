import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import LandingHeader from "../../../Layouts/Common/LandingHeader";
import StudentFooter from "../../../Layouts/Student/Footer";
import Meta from "../Meta";
import { ContactMetaTags } from "../../../../data/metaTags";
import ContactForm from "./contactForm";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <Meta {...ContactMetaTags} />
      <StudentHeader />
      <LandingHeader heading={"Contact Us"} />
      <section className=" contact_us">
        <div className="container">
          <div className="contact_main">
            <div className="row">
              <div className="col-md-6">
                <div className="contact_right">
                  <h2>Let's Start Conversation</h2>
                  <h3>
                    <span>Send Us a Email</span>{" "}
                    <Link to="mailto:customercare@albertotorresi.com">
                      guide@chownk.com
                    </Link>
                  </h3>
                  <h3>
                    <span>You can reach us on</span> <Link>+91 8427955568</Link>
                  </h3>
                  <h3>
                    <span>Contact Us with Chat</span>{" "}
                    <p>
                      Click on <Link to={"/customers/help"}>Chat Now</Link> to
                      Chat with us
                    </p>
                  </h3>
                  <Link className="chat_now" to={"/customers/help"}>
                    {" "}
                    <img src="../../../assets/images/hedic2black.png" /> Chat
                    now
                  </Link>

                  <p className="mt-4 social_msg">
                    You can send message on our social platform
                  </p>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/people/Chownk/100083289649022/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://twitter.com/Chownk1">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.instagram.com/chownkofficial/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/chownk/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default Contact;
