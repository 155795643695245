import { AgChartsReact } from "ag-charts-react";
import { useEffect } from "react";
import { useState } from "react";
const FollowerMetricsGraph = ({
  parentStyle,
  title,
  imageSrc,
  description,
  data,
}) => {
  console.log("graphingggggggggggggggggggg===========>", data);
  const dtss = {
    title: {
      text: "Followers",
    },
    data: data,
    series: [
      {
        type: "area",
        xKey: "date",
        yKey: "followers_count",
        yName: "Followers",
      },
    ],
  };
  const [options, setOptions] = useState(dtss);
  useEffect(() => {
    setOptions(dtss);
  }, [data]);
  return (
    <div className={parentStyle}>
      <h4>
        <img src={imageSrc} alt="Call_History" /> {title}
      </h4>
      <p>{description}</p>{" "}
      <AgChartsReact options={options} className="w-100 py-3" />
    </div>
  );
};
export default FollowerMetricsGraph;
