const HeaderTitle = ({ parentStyle, childStyle, image, title }) => {
  return (
    <div className={parentStyle}>
      <div className={childStyle}>
        <h2>
          <img src={image} alt={title} /> {title}
        </h2>
      </div>
    </div>
  );
};

export default HeaderTitle;
