import { Link } from "react-router-dom";
import BranchBox from "../../../Tools/BranchBox";
import React from "react";

const Branches = ({ data }) => {
  return (
    <div className="wap wap3 mb-3 h-auto">
      {data.length > 0 ? (
        <>
          <h4>
            <img src="assets/images/Branches.png" alt="Branches" />{" "}
            {`${data.length} Branches`}{" "}
            <Link to={"/account-settings/branches"}>{"See all"}</Link>{" "}
          </h4>
          <p></p>
          {data.slice(0, 3).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <BranchBox
                  parentStyle={"st"}
                  childStyle={"img"}
                  branchName={item.company_name}
                  imgSrc={item.company_logo}
                  location={item.city}
                  address={item.address}
                />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <div className="transactions text-center">
          <img src="../../../assets/images/bulding.png" alt="deadline1" />
          <p>
            Your <a href="#">Branches</a> will appear here
          </p>
        </div>
      )}
    </div>
  );
};

export default Branches;
