import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelectorm, useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OTPInput from "react-otp-input";
import Button from "../../Tools/Button";
import OTPTimer from "../../Tools/Otptimer";
import CrouselItem from "../../Tools/Crouselitem";
import { otpVerification, phoneVerification } from "../../../Reducer/authSlice";
const OtpModel = ({ duration, onHide }) => {
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const selector = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const otpDuration = duration; // Adjust the OTP timer duration as needed
  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };

  const onSubmit = (data) => {
    try {
      const body = {
        phone: selector?.phoneNumber,
        otp: otp,
        latitude: selector?.latitude,
        longitude: selector?.longitude,
        ip_address: selector?.ip_address,
        current_address: selector?.current_address,
        device_token: "3453453453434535r3rfg43534rf3443f34tg43g43gy54hg45",
        device_id: 435345345,
        app_version: "17.05.02",
        app_id: 2,
        platform: "web",
      };
      dispatch(otpVerification(body));
    } catch (e) {}
  };
  const onResendFun = () => {
    const body = {
      phone: selector?.phoneNumber,
      latitude: selector?.latitude,
      longitude: selector?.longitude,
      ip_address: selector?.ip_address,
      current_address: selector?.current_address,
    };
    dispatch(phoneVerification(body));
  };

  if (selector.auth.status == "success") {
    switch (selector.auth.redireaction) {
      case "/profile":
        toast.success(selector.auth.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        navigate("/profile");
        break;

      default:
        navigate("/fill-details");
        break;
    }
  }
  return (
    <div className="modal-content">
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={onHide}
      ></button>
      <div className="modal-body p-0">
        <div className="row">
          <div className="col-md-6">
            <div className="lest_login text-center">
              <img src="assets/images/logo.png" alt="logo" />
              <h3 className="text-center">{"OTP Verification"}</h3>
              <h6>
                {"An OTP has been sent to your"}
                <br></br>
                {"phone number"} <Link to={"/login"}>{"Change"}</Link>
              </h6>
              <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-groupotp text-center">
                  <label className="d-block text-start">
                    {"Enter your mobile number"} {selector.otp}
                  </label>
                  <OTPInput
                    className={"form-control"}
                    inputStyle={{ width: "40px" }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <p>
                  <input type="checkbox" className="form-check-input" />
                  {" I agree to "}
                  <a href="/terms-and-conditions" target="_blank">
                    {"Terms & Conditions"}
                  </a>
                </p>
                <p>
                  <input type="checkbox" className="form-check-input" />
                  {" Send updates on "}
                  <a href="#">{"Whatsapp"}</a>
                </p>
                <h5 className="mt-3 text-center">
                  {"Resend OTP in"}{" "}
                  {otpDuration !==0 && (
                    <OTPTimer
                      duration={otpDuration}
                      onTimeout={handleOTPTimerTimeout}
                      onResend={onResendFun}
                    />
                  )}
                </h5>
                <Button
                  inType={"button"}
                  style={"btn mt-2 mt-lg-5"}
                  title={"Continue"}
                  disabled={otp.length < 4 && true}
                />
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right_login">
              <p>
                Lorem Ipsum is simply dummy text for printing and typesetting
                industry. Lorem Ipsum has been industry’s standard dummy text.
              </p>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <div className="carousel-inner">
                  <CrouselItem
                    parentStyle={"carousel-item active"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                  <CrouselItem
                    parentStyle={"carousel-item"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                  <CrouselItem
                    parentStyle={"carousel-item"}
                    childStyle={"d-block w-100"}
                    src={"assets/images/login_slide1.png"}
                    alt={"..."}
                  />
                </div>
                <div className="carousel-indicators">
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"0"}
                    style={"active"}
                    ariaCurrent={"true"}
                    ariaLabel={"Slide 1"}
                  />
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"1"}
                    ariaLabel={"Slide 1"}
                  />
                  <Button
                    inType={"button"}
                    dataTarget={"#carouselExampleIndicators"}
                    dataSlide={"1"}
                    ariaLabel={"Slide 2"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default OtpModel;
