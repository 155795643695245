import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearStates,
  getCompanyProfile,
} from "../../../../Reducer/afterSearchSlice";
import { mediaUrl } from "../../../../services/network";
import BannerSection from "./bannerSection";
import { SocketContext } from "../../../../Context";
import VerificationSection from "./verificationSection";
import "react-loading-skeleton/dist/skeleton.css";
import "video-react/dist/video-react.css";
import {
  addViewProfileAction,
  followUnfollowApi,
  getAgentProfileApi,
  getSpecializationFilterApi,
  relatedSearches,
  saveCallDetail,
  saveUserNavigate,
  updateAgentProfileApi,
} from "../../../../services/api";
import FindUser from "../../../Tools/FindUser";
import Example from "../../../Tools/ReactBoostrap";
import LoginModel from "../../../Tools/LoginModel";
import StudentFooter from "../../../Layouts/Student/Footer";
import { toast } from "react-toastify";
import Achievement from "./achievement";
import SuccessStories from "./successStories";
import InstantConnect from "./instantConnect";
import About from "./about";
import FaqSection from "./faqSection";
import Specialized from "./specilized";
import RatingReview from "./ratingReview";
import Intrested from "./Intrested";
import Report from "./report";
import ShareProfileModal from "../../../Layouts/Model/shareProfileModal";
import AgentStatus from "../../../Tools/AgentStatus";
import PopularSearches from "./popularSearches";
import Meta from "../../Common/Meta";
import moment from "moment";

const NewViewProfile = () => {
  const [loginModel, setLoginModel] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [faq, setFaq] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [newSpecialization, setNewSpecialization] = useState([]);
  const [allSpecialization, setAllSpecialization] = useState([]);
  const [nearbySearches, setNearBySearches] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [reRender, setReRender] = useState(false);

  const { city, company, dynamic } = useParams();
  const slug = dynamic !== undefined ? dynamic : company;
  const selector = useSelector((state) => state.auth);
  const { company_detail, mediaSuccess, submitting } = useSelector(
    (state) => state.afterSearch
  );
  const guestId = localStorage.getItem("guestId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const stateData = location.state;
  const queryParams = new URLSearchParams(location.search);
  const reff = queryParams.get("reffer");
  const {
    setCurrentUser,
    finUser,
    findChatFun,
    addFundsModal,
    setAddFundsModal,
    screenWidth,
    userModel,
    setUserModel,
  } = useContext(SocketContext);

  useEffect(() => {
    dispatch(getCompanyProfile({ slug: slug, user_id: selector?.auth?.uid }));

    getSpecializationData();

    return () => {
      dispatch(clearStates());
    };
  }, [company, slug, city, reRender]);

  useEffect(() => {
    if (Object.keys(company_detail).length > 0 && company_detail.faq) {
      setFaq(JSON.parse(company_detail.faq));
    }
    if (Object.keys(company_detail).length > 0 && company_detail.city) {
      getRelatedSearches(company_detail.city);
    }
    if (
      Object.keys(company_detail).length > 0 &&
      company_detail.uid === selector?.auth?.uid
    ) {
      getAgentProfileData();
    } else {
      setNewSpecialization(company_detail?.specialization?.split(","));
    }
    if (company_detail) {
      viewProfileAction();
    }
  }, [company_detail]);

  const getRelatedSearches = async (city) => {
    const searches = await relatedSearches({ city: city });
    setNearBySearches(searches.data.data);
  };

  const loginModelHandle = (type) => {
    if (selector && selector.auth && company_detail.id !== selector.auth.id) {
      if (type == "video") {
        handleUserNavigate(
          "call",
          company_detail.company_name,
          `Call to ${company_detail.company_name}`
        );
        if (
          company_detail.online !== true ||
          company_detail.online !== "true"
        ) {
          saveCall(company_detail.uid);
        }
        if (selector.funds >= selector.call_min) {
          let data = {
            connection_user: company_detail.uid,
            uid: company_detail.uid,
            image: company_detail.company_logo,
            name: company_detail.company_name,
          };
          setCurrentUser(data);
          finUser(company_detail.uid);
          setUserModel(true);
        } else {
          setAddFundsModal(!addFundsModal);
        }
      } else {
        handleUserNavigate(
          "message",
          company_detail.company_name,
          `Message to ${company_detail.company_name}`
        );
        let data = {
          receiver_id: company_detail.uid,
          image: company_detail.company_logo,
          company_name: company_detail.company_name,
          online: company_detail.online !== "" ? true : false,
          status: company_detail.status,
          owner_name: company_detail.owner_name,
          slug: company_detail.slug,
          city: company_detail.city,
          userRole: "branch",
        };
        findChatFun(data);
      }
    } else {
      setLoginModel(!loginModel);
    }
  };

  const saveCall = (id) => {
    try {
      const body = { to: id };
      saveCallDetail(body);
    } catch (error) {
      console.log(error);
    }
  };

  /* 
  Update Function 
  */
  const updateProfileFun = async (formData) => {
    try {
      const response = await updateAgentProfileApi(formData);
      if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        setReRender(!reRender);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const getSpecializationData = async () => {
    const Specialization = await getSpecializationFilterApi();
    if (Specialization && Specialization.length > 0) {
      let all_specialization = [];
      Specialization.forEach((element) => {
        all_specialization = [...all_specialization, ...element.child];
      });
      setAllSpecialization(all_specialization);
    }
  };

  const getAgentProfileData = () => {
    getAgentProfileApi()
      .then(async (response) => {
        if (response.data.status === "success") {
          setSpecialization(response.data.data.specialization);
          setNewSpecialization(response.data.data.specialization);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const handleShare = () => {
    const dt = localStorage.getItem("dt");
    if (dt) {
      const current_url = window.location.href;
      const dtss = { type: "share", url: current_url };
      window.ReactNativeWebView.postMessage(JSON.stringify(dtss));
    } else {
      setShareModal(!shareModal);
    }
  };

  const handleUserNavigate = (type, hit, description) => {
    try {
      const body = {
        id: selector.auth ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  const viewProfileAction = async () => {
    try {
      const guestId = localStorage.getItem("guestId");
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      if (company_detail.uid) {
        const body = {
          profile: company_detail.uid,
          user_id: Number(
            selector && selector.auth ? selector.auth.uid : guestId
          ),
          type: selector ? 1 : 0,
          check: 0,
          city: userDetails.city,
          page: 1,
          category: stateData && stateData != null ? stateData : reff ? 3 : 2,
        };
        await addViewProfileAction(body);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  };

  return (
    <>
      {!submitting && (
        <Meta
          title={
            company_detail.meta_title
              ? company_detail.meta_title
              : `${company_detail.company_name} in ${company_detail.city}`
          }
          description={
            company_detail.meta_description
              ? company_detail.meta_description
              : `${company_detail.company_name} is the Best immigration consultant in ${company_detail.city}`
          }
          keywords={
            company_detail.meta_description
              ? company_detail.meta_description
              : `${company_detail.company_name},${company_detail.city}`
          }
          ogTitle={`${company_detail.company_name} in ${company_detail.city}`}
          includeFaq={true}
          faqs={company_detail.faq ? company_detail.faq : ""}
          canonical={true}
          ogImage={mediaUrl + company_detail.banner_image}
          redirect={window.location.origin + window.location.pathname}
          singleProfileSchema={
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "@id": "https://chownk.com/#breadcrumb",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Chownk",
                    item: {
                      "@type": "WebSite",
                      "@id": "https://chownk.com/",
                    },
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: `Best Immigration Consultants in ${city}`,
                    item: {
                      "@type": "WebPage",
                      "@id": `https://chownk.com/${city}`,
                    },
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `${company_detail.company_name} - Chownk`,
                    item: {
                      "@type": "WebPage",
                      "@id": `https://chownk.com/${city}/${company_detail.slug}`,
                    },
                  },
                ],
              })}
            </script>
          }
          profileImageSchema={
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "ImageObject",
                name: `${company_detail.company_name} Profile`,
                description: `Profile image of ${company_detail.company_name} - Best Immigration Consultants in ${city} on CHOWNK`,
                contentUrl: `${mediaUrl}${company_detail.company_logo}`,
              })}
            </script>
          }
          bannerImageSchema={
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "ImageObject",
                name: `${company_detail.company_name} Banner`,
                description: `Banner image of ${company_detail.company_name} - Best Immigration Consultants in ${city} on CHOWNK`,
                contentUrl: `${mediaUrl}${company_detail.banner_image}`,
              })}
            </script>
          }
          SuccessStoriesSchema={
            company_detail.stories &&
            JSON.parse(company_detail.stories).map((el, i) => (
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "ImageObject",
                  name: `${company_detail.company_logo} Success ${i + 1}`,
                  description: `Success story image of ${company_detail.company_logo} - Best Immigration Consultants in ${city} on CHOWNK`,
                  contentUrl: `${mediaUrl}${el.image}`,
                })}
              </script>
            ))
          }
          businessSchema={
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "http://www.schema.org",
                "@type": "LocalBusiness",
                name: company_detail.company_name,
                url: `${window.location.href}`,
                image: `${mediaUrl}${company_detail.banner_image}`,
                logo: `${mediaUrl}${company_detail.company_logo}`,
                priceRange: "100000.00 - 150000.00",
                telephone: "+91 8427955568",
                description: `${company_detail.overview_description}`,
                address: {
                  "@type": "PostalAddress",
                  streetAddress: `${company_detail.permanent_address}`,
                  addressLocality: `${city}`,
                  addressRegion: company_detail.state,
                  postalCode: company_detail.zipcode,
                  addressCountry: "IN",
                },
                geo: {
                  "@type": "GeoCoordinates",
                  latitude: company_detail.latitude,
                  longitude: company_detail.longitude,
                },
                openingHours: "Mo, Tu, We, Th, Fr, Sa, Su 00:00-23:59",
                contactPoint: {
                  "@type": "ContactPoint",
                  telephone: "+91 8427955568",
                },
              })}
            </script>
          }
          ratingSchema={
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name: company_detail.company_name,
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: "4.9",
                  bestRating: "5",
                  reviewCount: `${
                    company_detail.rattingCount
                      ? company_detail.rattingCount
                      : 1
                  }`,
                },
                review: {
                  "@type": "Review",
                  reviewRating: {
                    "@type": "Rating",
                    ratingValue: "4.9",
                    bestRating: `5`,
                  },
                  author: {
                    "@type": "Person",
                    name: "CHOWNK",
                  },
                },
              })}
            </script>
          }
        />
      )}

      <StudentHeader
        logo={"../../../assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        formDisplay={true}
      />
      <section className="profile_viewmain">
        <div className="container">
          <nav aria-label="Breadcrumb" className="Breadcrumb">
            <ul>
              <li>
                <Link to={`/${city}`}>{capitalizeFirstLetter(city)}</Link>
              </li>
              <li>
                <Link>{capitalizeFirstLetter(slug)}</Link>
              </li>
            </ul>
            <Link
              class="float-end"
              onClick={() => {
                handleUserNavigate("action", "share", "share profile");
                handleShare();
              }}
            >
              <img
                src="../../../assets/images/share_icon.png"
                alt="share_icon"
              />
            </Link>{" "}
          </nav>

          {!submitting && company_detail?.uid === selector?.auth?.uid && (
            <AgentStatus
              style={"d-none d-md-block"}
              parentInlineStyle={{
                backgroundColor:
                  company_detail?.profile_percentage < 75
                    ? "#FF0000"
                    : "#1DBF73",
              }}
              image={"../../../assets/images/checkk.png"}
              description={
                company_detail?.profile_percentage < 75 ? (
                  "Your Profile is Not Live Yet. Please ensure it is at least 75% complete for customers to interact with you"
                ) : (
                  <>
                    Congratulations! Your profile is now live. Install our{" "}
                    <Link
                      to={"/download-app"}
                      onClick={() =>
                        handleUserNavigate(
                          "navigate",
                          "/download-app",
                          "download app"
                        )
                      }
                    >
                      mobile app
                    </Link>{" "}
                    to connect instantly
                  </>
                )
              }
            />
          )}
          <div class="row">
            <div class="col-lg-8">
              <BannerSection
                bgImage={`${mediaUrl}${company_detail.banner_image}`}
                logo={`${mediaUrl}${company_detail.company_logo}`}
                companyName={company_detail.company_name}
                tagline={company_detail.tagline}
                rating={company_detail.averageRating}
                ratingCount={company_detail.rattingCount}
                selector={selector?.auth}
                companyOwner={company_detail.uid}
                addressStatus={company_detail.address_status}
                businessProofStatus={
                  company_detail.authorize_certificate_status
                }
                activeStatus={
                  company_detail && company_detail.status === "active"
                    ? true
                    : false
                }
                gstStatus={company_detail.gst_status}
                loading={submitting}
                loginModelHandle={loginModelHandle}
                like={company_detail?.isFollowing}
                setBannerImage={setBannerImage}
                bannerImage={bannerImage}
                updateProfileFun={updateProfileFun}
                setCompanyLogo={setCompanyLogo}
                companyLogo={companyLogo}
                msgStatus={
                  company_detail?.online == "true" ||
                  company_detail?.online == true
                    ? true
                    : false
                }
                videoStatus={
                  company_detail?.video_status == "active" ? true : false
                }
                setShareModal={handleShare}
                shareModal={shareModal}
                handleUserNavigate={handleUserNavigate}
                city={city}
              />
            </div>

            <div class="col-lg-4">
              <VerificationSection
                city={company_detail.city}
                joinedOn={company_detail.created_at}
                followers={company_detail.followersCount}
                addressStatus={company_detail.address_status}
                businessProofStatus={
                  company_detail.authorize_certificate_status
                }
                activeStatus={
                  company_detail && company_detail.status === "active"
                    ? true
                    : false
                }
                gstStatus={company_detail.gst_status}
                panStatus={company_detail.pan_status}
                selector={selector?.auth}
                companyOwner={company_detail.uid}
                loading={submitting}
                handleUserNavigate={handleUserNavigate}
              />
            </div>
          </div>
          {/* Achievement section */}
          <div class="row">
            <div class="col-md-12">
              <Achievement
                data={company_detail?.achievements}
                companyOwner={company_detail.uid}
                selector={selector?.auth}
                updateProfileFun={updateProfileFun}
                loading={submitting}
              />
              {/* Success Stories */}
              <SuccessStories
                companyName={company_detail.company_name}
                data={company_detail.stories}
                loading={submitting}
                companyOwner={company_detail.uid}
                selector={selector?.auth}
                updateProfileFun={updateProfileFun}
                city={company_detail.city}
                slug={company_detail.slug}
                navState={
                  newSpecialization &&
                  newSpecialization != "" &&
                  company_detail.boost == 1
                    ? 4
                    : 1
                }
                handleUserNavigate={handleUserNavigate}
              />
            </div>
            <div class="col-lg-8 mt-4">
              <Specialized
                specialization={specialization}
                loading={submitting}
                setSpecialization={setSpecialization}
                companyOwner={company_detail.uid}
                selector={selector?.auth}
                allSpecialization={allSpecialization}
                setAllSpecialization={setAllSpecialization}
                newSpecialization={newSpecialization ? newSpecialization : []}
                setNewSpecialization={setNewSpecialization}
                updateProfileFun={updateProfileFun}
              />

              {company_detail?.rattingCount > 0 && (
                <RatingReview
                  rating={company_detail.averageRating}
                  ratingCount={company_detail.rattingCount}
                  loading={submitting}
                  selector={selector?.auth}
                  companyOwner={company_detail.uid}
                  handleUserNavigate={handleUserNavigate}
                />
              )}

              <About
                overview={company_detail.overview_description}
                loading={submitting}
                companyOwner={company_detail.uid}
                selector={selector?.auth}
                updateProfileFun={updateProfileFun}
              />

              <FaqSection
                faqData={faq ? faq : []}
                setFaqData={setFaq}
                companyOwner={company_detail.uid}
                selector={selector?.auth}
                updateProfileFun={updateProfileFun}
                loading={submitting}
                handleUserNavigate={handleUserNavigate}
              />
            </div>
            <div class="col-lg-4 mt-4">
              <InstantConnect
                loginModelHandle={loginModelHandle}
                companyName={company_detail.company_name}
                city={company_detail.city}
                loading={submitting}
                selector={selector?.auth}
                companyOwner={company_detail.uid}
                loginModel={loginModel}
                setLoginModel={setLoginModel}
                msgStatus={
                  company_detail?.online == "true" ||
                  company_detail?.online == true
                    ? true
                    : false
                }
                videoStatus={
                  company_detail?.video_status == "active" ? true : false
                }
              />
              {nearbySearches && nearbySearches.length > 0 && (
                <PopularSearches
                  city={company_detail?.city}
                  nearbySearches={nearbySearches ? nearbySearches : []}
                  handleUserNavigate={handleUserNavigate}
                />
              )}
              <Intrested handleUserNavigate={handleUserNavigate} />
              <Report
                companyId={company_detail.id}
                companyOwner={company_detail.uid}
                selector={selector?.auth}
              />
            </div>
          </div>
        </div>
      </section>
      {screenWidth > 767 && <StudentFooter />}

      <LoginModel
        show={loginModel}
        setShow={setLoginModel}
        redirect={window.location.href}
      />
      <Example
        show={userModel}
        style={"connect ofline_div"}
        onHide={() => setUserModel(!userModel)}
        component={
          <FindUser
            show={userModel}
            data={company_detail}
            onHide={() => setUserModel(!userModel)}
          />
        }
        backDrop={"static"}
      />
      <Example
        show={shareModal}
        onHide={() => setShareModal(!shareModal)}
        component={<ShareProfileModal />}
        style={"pop_width CreateOffer faqModel sharewidth"}
      />
    </>
  );
};

export default NewViewProfile;
