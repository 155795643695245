import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleMapApi } from "../../../../data/data";
import { popularSearchData } from "../../../../data/data";
import { useSelector } from "react-redux";
import LoginButton from "../../../Tools/LoginButton";

const OfflineSideBar = ({
  sidemenu,
  setSidemenu,
  otpShow,
  setOtpShow,
  show,
  setShow,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const normalSelector = useSelector((state) => state.auth);
  const dt = localStorage.getItem("dt");

  const checkType = (item) => {
    switch (item.type) {
      case "specialization":
        navigate(item.path);
        return;
      case "location":
        Fetchlocation();
        return;
      case "fraud":
        navigate("/how-to-avoid-immigration-fraud-from-agents");
    }
  };

  const Fetchlocation = (ipAddress) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      fetchLocationInfo(latitude, longitude, ipAddress);
    });
  };

  const fetchLocationInfo = (latitude, longitude, ipAddress) => {
    const apiKey = googleMapApi;
    let cities = "";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        await data.results.forEach((i, index) => {
          if (i.types.includes("administrative_area_level_3")) {
            cities = i.address_components[0].long_name;
            queryParams.set("city", cities.replaceAll(" ", "-"));
            queryParams.set("lat", latitude);
            queryParams.set("long", longitude);
            const newSearchString = queryParams.toString();
            navigate({ pathname: "/near-me", search: newSearchString });
          }
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  return (
    <div className={`sidebar_mob ${sidemenu && !dt && "show"}`}>
      <i className="bi bi-x-lg" onClick={() => setSidemenu(!sidemenu)}></i>
      <LoginButton
        heading={"Become a Partner"}
        onClick={() =>
          normalSelector.otp ? setOtpShow(!otpShow) : setShow(!show)
        }
        path={"/become-consultant"}
        darkIcon={true}
      />
      {popularSearchData && popularSearchData.length > 0 && (
        <ul className="services">
          {popularSearchData.map((el, i) => (
            <li
              key={i}
              onClick={() => {
                checkType(el);
                setSidemenu(!sidemenu);
              }}
            >
              <Link to={el.path}>
                {" "}
                <img
                  src={el.type === "fraud" ? el.imageBlack : el.image}
                  alt={el.alt}
                />
                {el.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OfflineSideBar;
