import React from "react";
import OTPInput from "react-otp-input";
import { Link } from "react-router-dom";
import OTPTimer from "../../Tools/Otptimer";

import { mediaUrl } from "../../../services/network";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Tools/Button";
import { phoneVerification } from "../../../Reducer/authSlice";

const PaymentOtpModal = ({
  otp,
  setOtp,
  onHide,
  otpSubmit,
  setOtpSubmit,
  onSubmitForm,
}) => {
  const selector = useSelector((state) => state.auth);
  const userLocation = useSelector((state) => state.auth?.userDetails);
  const dispatch = useDispatch();
  const otpDuration = 120;
  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };
  const onResendFun = () => {
    const body = {
      phone: selector.phoneNumber,
      latitude: userLocation?.latitude,
      longitude: userLocation?.longitude,
      ip_address: userLocation?.ip_address,
      current_address: userLocation?.address,
      role: 1,
    };
    dispatch(phoneVerification(body));
  };

  console.log(selector.otpError, "ERROR");
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="lest_login text-center">
            <img
              src={`../../assets/images/chownk-logo.png`}
              alt="CHOWNK logo"
            />
            <h3 className="text-center">{"OTP Verification"}</h3>
            <h6>
              {"An OTP has been sent to your"}
              <br />
              {"phone number"}
            </h6>
            <form
              className="text-start mt-4"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm();
              }}
            >
              <div className="form-group form-groupotp text-center">
                <label className="d-block text-start">
                  {"Enter your OTP"} {selector.otp}
                </label>
                <OTPInput
                  className={"form-control"}
                  inputStyle={{ width: "40px" }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputType={"tel"}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                {selector.otpError && (
                  <p style={{ color: "red" }}>{selector.otpError}</p>
                )}
              </div>
              <h5 className="mt-3 text-center">
                <OTPTimer
                  duration={otpDuration}
                  onTimeout={handleOTPTimerTimeout}
                  onResend={onResendFun}
                />
              </h5>
              <Button
                inType={"submit"}
                style={"btn mt-2 mt-lg-5"}
                title={"Continue"}
                inlineStyle={
                  otp.length == 4
                    ? { backgroundColor: "#1DBF73", color: "white" }
                    : {}
                }
                disabled={otp.length < 4 && { backgroundImage: "blue" }}
              />
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="right_login">
            <p>
              <div className="column">
                <h3 className="no-puck text-white">
                  {" "}
                  Trust
                  <span className="highlight"> CHOWNK </span>
                  to navigate the complexities of international immigration
                  seamlessly, ensuring you're well-informed and supported every
                  step of the way.{" "}
                </h3>
                <div className="mob video-demonstration">
                  <div
                    className="youtube-video"
                    data-lumberjack-action="partnerships.landing_page.video"
                  ></div>
                </div>
                <ul className="bullet-list">
                  <li>Secure Video 1 to 1 Call with Experts</li>
                  <li>Chat and Messaging</li>
                  <li>Recording Options</li>
                  <li>Document Checklist</li>
                  <li>Client Portal</li>
                  <li>Informational Content</li>
                  <li>Document Sharing</li>
                  <li>Feedback and Review System</li>
                  <li>Notification Alerts</li>
                </ul>
                <div className="become-partner-container mob">
                  <p>
                    <i className="bi bi-arrow-left"></i>{" "}
                    <strong>Join Now</strong>
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
};

export default PaymentOtpModal;
