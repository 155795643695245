export const agentOfficeData = [
  {
    office_image: "assets/images/results1.png",
    office_name: "Hello Masters",
    location: "#102, Park View Street, Australia",
    rating: "5.0",
    reviews: 302,
  },
  {
    office_image: "assets/images/results1.png",
    office_name: "Visa Masters",
    location: "#102, Park View Street, Australia",
    rating: "5.0",
    reviews: 302,
  },
  {
    office_image: "assets/images/results1.png",
    office_name: "Visa Masters",
    location: "#102, Park View Street, Australia",
    rating: "5.0",
    reviews: 302,
  },
  {
    office_image: "assets/images/results1.png",
    office_name: "Visa Masters",
    location: "#102, Park View Street, Australia",
    rating: "5.0",
    reviews: 302,
  },
];

export const topRatedConsultantData = [
  {
    image: "assets/images/pro1.png",
    institute_name: "Visa Masters",
    rating: 5,
    description: "Lorem Ipsum is simply dummy text for printing.",
    pricePerMinute: "$15.00/ 15 min",
  },
  {
    image: "assets/images/pro2.png",
    institute_name: "Visa Masters",
    rating: 3,
    description: "Lorem Ipsum is simply dummy text for printing.",
    pricePerMinute: "Free Call",
  },
  {
    image: "assets/images/pro3.png",
    institute_name: "Visa Masters",
    rating: 2,
    description: "Lorem Ipsum is simply dummy text for printing.",
    pricePerMinute: "$15.00/ 15 min",
  },
  {
    image: "assets/images/pro4.png",
    institute_name: "Visa Masters",
    rating: 5,
    description: "Lorem Ipsum is simply dummy text for printing.",
    pricePerMinute: "Free Call",
  },
  {
    image: "assets/images/pro5.png",
    institute_name: "Visa Masters",
    rating: 5,
    description: "Lorem Ipsum is simply dummy text for printing.",
    pricePerMinute: "$15.00/ 15 min",
  },
  {
    image: "assets/images/pro6.png",
    institute_name: "Visa Masters",
    rating: 5,
    description: "Lorem Ipsum is simply dummy text for printing.",
    pricePerMinute: "$15.00/ 15 min",
  },
];

export const popularInstituteData = [
  {
    name: "Gray Matters",
    location: "SCO. 114, Main Market Sector 34, Chandigarh",
    image: "assets/images/popularpro1.png",
  },
  {
    name: "Gray Matters",
    location: "SCO. 114, Main Market Sector 34, Chandigarh",
    image: "assets/images/popularpro2.png",
  },
  {
    name: "Gray Matters",
    location: "SCO. 114, Main Market Sector 34, Chandigarh",
    image: "assets/images/popularpro3.png",
  },
  {
    name: "Gray Matters",
    location: "SCO. 114, Main Market Sector 34, Chandigarh",
    image: "assets/images/popularpro4.png",
  },
  {
    name: "Gray Matters",
    location: "SCO. 114, Main Market Sector 34, Chandigarh",
    image: "assets/images/popularpro5.png",
  },
  {
    name: "Gray Matters",
    location: "SCO. 114, Main Market Sector 34, Chandigarh",
    image: "assets/images/popularpro6.png",
  },
];

export const testimonialData = [
  {
    image: "assets/images/us1.png",
    name: "Kevin M.",
    rating: 5,
    description:
      "Outstanding service! Their service helped me navigate the complex process with ease. Highly recommended!",
  },
  {
    image: "assets/images/us1.png",
    name: "Kevin M.",
    rating: 5,
    description:
      "Outstanding service! Their service helped me navigate the complex process with ease. Highly recommended!",
  },
  {
    image: "assets/images/us1.png",
    name: "Kevin M.",
    rating: 5,
    description:
      "Outstanding service! Their service helped me navigate the complex process with ease. Highly recommended!",
  },
];

export const recentBlogsData = [
  {
    image: "assets/images/blog1.png",
    title: "Immigration Challenges and Solutions: Overview",
    description:
      "Immigration challenges and solutions vary greatly depending on the country, region, and context",
  },
  {
    image: "assets/images/blog2.png",
    title: "Immigration Challenges and Solutions: Overview",
    description:
      "Immigration challenges and solutions vary greatly depending on the country, region, and context",
  },
  {
    image: "assets/images/blog3.png",
    title: "Immigration Challenges and Solutions: Overview",
    description:
      "Immigration challenges and solutions vary greatly depending on the country, region, and context",
  },
  {
    image: "assets/images/blog4.png",
    title: "Immigration Challenges and Solutions: Overview",
    description:
      "Immigration challenges and solutions vary greatly depending on the country, region, and context",
  },
];

export const searchBarData = [
  {
    company_logo: "../../../../assets/images/search_user.svg",
    company_name: "Immigration consultant near me",
    available: "500+ available",
    type: "immigration",
  },
  {
    company_logo: "../../../../assets/images/search_bulding.svg",
    company_name: "IELTS/PTE/TOEFL insitutes near me",
    available: "450+ available",
    type: "institute",
  },
];
