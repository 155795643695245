import SocialMedia from "../../../Tools/SocialMedia";

const LastDaysActivity = () => {
  return (
    <div className="col-md-4 pe-4 col2">
      <h4>Last 7 days Activity</h4>
      <div className="st px-4">
        <p>
          <i className="fa-solid fa-video"></i> 650 Video Calls
        </p>
        <p>
          <i className="fa-solid fa-comment-dots"></i> 180 Messages
        </p>
        <p className="mb-0">
          <i className="fa-solid fa-eye"></i> 900 Profile Views
        </p>
      </div>
      <h4>Successful Stories</h4>
      <div className="row">
        <div className="col-6 mb-2">
          <img
            className="w-100"
            src="assets/images/single_video1.png"
            alt="video"
          />
        </div>
        <div className="col-6 mb-2">
          <img
            className="w-100"
            src="assets/images/single_video1.png"
            alt="video"
          />
        </div>
        <div className="col-6 mb-2">
          <img
            className="w-100"
            src="assets/images/single_video1.png"
            alt="video"
          />
        </div>
        <div className="col-6 mb-2">
          <img
            className="w-100"
            src="assets/images/single_video1.png"
            alt="video"
          />
        </div>
        <div className="col-12 text-center mt-2">
          <a href="#" className="view">
            View all
          </a>
        </div>
      </div>
      <h4>Staff Members</h4>
      <div className="st mb-2">
        <div className="row">
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
          <div className="col-3 mb-2">
            <div
              className="imgus"
              style={{ backgroundImage: `url(assets/images/user.png)` }}
            ></div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <a href="#" className="view">
          View all
        </a>
      </div>
      <SocialMedia heading={"Social Media Links"} />
      <a href="#" className="report_btn">
        Report
      </a>
    </div>
  );
};
export default LastDaysActivity;
