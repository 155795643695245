import { MdFileDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { mediaUrl } from "../../../services/network";
import { downloadMedia } from "../../../services/function";
const ChatImageAndVideoPreviewModel = ({ type, data, onHide }) => {

    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="row">
              <Link href="#" style={{cursor:"pointer"}} onClick={()=>downloadMedia(data)}> <MdFileDownload /> Download</Link>
                    {type==="image" && <img src={data}/>}
                    {type==="video" && <video width="320" height="240" controls><source src={data} type="video/mp4"/></video>}
                </div>
            </div>
        </div>)
}
export default ChatImageAndVideoPreviewModel;