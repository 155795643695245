import React, { useState } from "react";
import OTPInput from "react-otp-input";
import LabelInputBox from "../../../Tools/LabelInputBox";
import SimpleInput from "../../../Tools/SimpleInput";
import CompanyAddress from "./companyAddress";
import OTPTimer from "../../../Tools/Otptimer";
import { joinPhoneVerification } from "../../../../Reducer/authSlice";
import { useSelector } from "react-redux";

const TabTwoForm = ({
  event,
  errors,
  control,
  setMessage,
  address,
  active,
  googleHandleChange,
  ootp,
  setOotp,
  handleOTPTimerTimeout,
  phoneCheckHandle,
  onResendFun,
  message,
  availablePhone,
  companyHanleChange,
  slugMessage,
  dispatch,
  userData,
  clearErrors,
  setValue,
  slug,
  setAddress,
  otpButtonHide,
  setOtpButtonHide,
  otpButtonHideOne,
  setOtpButtonHideOne,
  getCity,
  emailHandleChange,
  emailAvailable,
}) => {
  const [otpProcess, setOtpProcess] = React.useState(false);
  const selector = useSelector((state) => state.auth);
  const [duration, setDuration] = useState(60);

  return (
    <div className="tab" style={active}>
      <div className="row">
        <div className="col-12">
          <div className="comp">
            <h1>{"Complete Your Profile"}</h1>
            <h2>{"Fill all your details to complete profile"}</h2>
          </div>
        </div>
      </div>

      <div className="Account_Settings">
        <LabelInputBox
          label={"Full Name"}
          labelDescription={"Add Full Owner Name"}
          style={"form-control"}
          inType={"text"}
          name={"owner_name"}
          error={errors}
          event={event}
          placeholder={"Enter Name here"}
          onChange={(e) => clearErrors("owner_name")}
        />
        <div className="row mt-4">
          <div className="col-md-4">
            <h5>Mobile Number</h5>
            <p className="label">Add Mobile Number</p>
          </div>
          <div className="col-md-8">
            <div className="form-control prefix">
              <div className="row">
                <span>+91</span>
                <div className="col-md-12">
                  <SimpleInput
                    style={"form-control"}
                    inType={"text"}
                    name={"phone"}
                    event={event}
                    placeholder={"Enter Mobile Number"}
                    onChange={phoneCheckHandle}
                    disabled={selector.token ? true : false}
                  />

                  {/* {selector.otp ? (
                    <span className={"sendotp"} style={{ color: "#E3DAD8" }}>
                      Send OTP
                    </span>
                  ) : ( */}
                  {otpButtonHideOne && (
                    <span
                      style={{
                        cursor:
                          selector.phoneNumber && availablePhone
                            ? "pointer"
                            : "",
                        color:
                          selector.phoneNumber && availablePhone
                            ? ""
                            : "#E3DAD8",

                        display: availablePhone ? "block" : "none",
                      }}
                      className={"sendotp"}
                      onClick={() => {
                        if (availablePhone) {
                          const bodyy = {
                            phone: selector.phoneNumber,
                            latitude: selector.userDetails?.latitude,
                            longitude: selector.userDetails?.longitude,
                            ip_address: selector.userDetails?.ip_address,
                            current_address:
                              selector.userDetails?.current_address,
                            user: true,
                          };
                          dispatch(joinPhoneVerification(bodyy));
                          setMessage("Otp send");
                          setOtpProcess(true);
                          setOtpButtonHide(!otpButtonHide);
                          setOtpButtonHideOne(false);
                          setDuration(120);
                        }
                      }}
                    >
                      Send OTP
                    </span>
                  )}
                  {/* )} x  */}
                </div>
              </div>
            </div>
            {availablePhone && <p style={{ color: "green" }}>{message}</p>}
            {errors && errors.phone && (
              <p className="arr">
                <i>
                  <i className="bi bi-info-circle-fill"></i>{" "}
                  {errors.phone.message}
                </i>
              </p>
            )}

            {otpButtonHide && otpProcess && availablePhone && !userData && (
              <div className="form-groupotp">
                <label className="d-block text-start">
                  Enter OTP {selector.otp}
                </label>
                <OTPInput
                  className={"form-control"}
                  inputStyle={{ width: "40px" }}
                  value={ootp}
                  onChange={setOotp}
                  numInputs={4}
                  inputType={"tel"}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                <OTPTimer
                  duration={duration}
                  onTimeout={handleOTPTimerTimeout}
                  onResend={onResendFun}
                />
                {selector.otpSuccess == false &&
                  selector.error &&
                  selector.errorPosition == "otp" && (
                    <p className="arr">
                      <i>
                        <i className="bi bi-info-circle-fill"></i>{" "}
                        {selector.error}
                      </i>
                    </p>
                  )}
              </div>
            )}
          </div>
        </div>

        <LabelInputBox
          label={"Email"}
          labelDescription={"Add your email"}
          style={"form-control"}
          event={event}
          error={errors}
          name={"email"}
          placeholder={"Enter Email Address"}
          availableIcon={true}
          available={emailAvailable}
          onChange={emailHandleChange}
          message={"message"}
        />
        <LabelInputBox
          label={"Company Name"}
          labelDescription={"Add Company Name"}
          style={"form-control"}
          event={event}
          error={errors}
          name={"company_name"}
          placeholder={"Enter Company Name"}
          availableIcon={true}
          available={slugMessage?.style?.color == "green" ? true : false}
          onChange={companyHanleChange}
          message={slug ? slug : ""}
        />
        <CompanyAddress
          label={"Company Address"}
          labelDescription={"Add Company Address"}
          address={address}
          event={event}
          control={control}
          errors={errors}
          googleHandleChange={googleHandleChange}
          userData={userData}
          clearErrors={clearErrors}
          setValue={setValue}
          setAddress={setAddress}
          slug={slug}
          getCity={getCity}
          slugMessage={slugMessage}
        />
      </div>
    </div>
  );
};
export default TabTwoForm;
