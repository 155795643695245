import StudentHeader from "../../../Layouts/Student/Header";
import { Link } from "react-router-dom";
import StudentFooter from "../../../Layouts/Student/Footer";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../../Context";
import { getTransactionHistory } from "../../../../services/api";
import moment from "moment";
import PaginationTable from "../../../Tools/Pagination";
import $ from "jquery";

const Transactions = () => {
  const [expanded, setExpanded] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const selector = useSelector((state) => state.auth);
  const { addFundsModal, setAddFundsModal } = useContext(SocketContext);
  let item_per_page = 25;

  const TransactionHistory = async () => {
    try {
      const body = { page: currentPage, pageSize: item_per_page };
      const response = await getTransactionHistory(body);
      if (response.status === 200) {
        setTransactions(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    TransactionHistory();
  }, [currentPage]);

  return (
    <>
      <StudentHeader formDisplay={true} />
      <section className="transactions">
        <div className="hed">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2>
                  <img
                    src="assets/images/transactions.png"
                    alt="Transactions"
                  />{" "}
                  {"Transactions"}
                </h2>
              </div>
              <div className="col-md-6 text-md-end">
                <h4 className="mb-0">
                  Current Balance:<i className="bi bi-currency-rupee"></i>{" "}
                  {selector.funds}
                  <Link onClick={() => setAddFundsModal(!addFundsModal)}>
                    <i className="bi bi-plus-lg"></i> {"Add Funds"}
                  </Link>{" "}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          {selector &&
            selector.auth &&
            selector.auth.role === 5 &&
            ((selector.auth.pan_status == "Rejected" &&
              selector.auth.gst_status == "Rejected" &&
              selector.auth.authorize_certificate_status == "Rejected") ||
              (selector.auth.pan_status == "Pending" &&
                selector.auth.gst_status == "Pending" &&
                selector.auth.authorize_certificate_status == "Pending")) && (
              <Link to={"/account-settings"} className="Submit">
                <i className="bi bi-file-earmark-text-fill"></i>{" "}
                {"Submit your KYC Documents"}
              </Link>
            )}

          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.length > 0 &&
                  transactions[0].map((item, index) => (
                    <tr>
                      <td>
                        {expanded === index ? (
                          <i
                            class="bi bi-dash-circle-fill d-sm-none d-block"
                            onClick={() => setExpanded(null)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-plus-circle-fill d-sm-none d-block"
                            onClick={() => {
                              setExpanded(index);
                            }}
                          ></i>
                        )}
                        {item.title}
                      </td>
                      <td>{item.debit}</td>
                      <td>{item.credit}</td>
                      <td
                        className={`expand ${
                          expanded === index ? "showtd" : ""
                        }`}
                      >
                        <span className="d-inline-block d-sm-none">
                          Balance
                        </span>
                        {item.balance}
                      </td>
                      <td
                        className={`expand ${
                          expanded === index ? "showtd" : ""
                        }`}
                      >
                        <span className="d-inline-block d-sm-none">Date</span>
                        {moment
                          .unix(item.unix_timestamp / 1000)
                          .format("MM-DD-YYYY")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <PaginationTable
            rowsShowLimit={
              transactions.length > 0
                ? Math.ceil(transactions[1][0].total / item_per_page)
                : 1
            }
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            style={"d-inline-block"}
          />
        </div>
      </section>
      <StudentFooter />
    </>
  );
};
export default Transactions;
