import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const SpecializedTab = ({
  currentStep,
  allSpecialization,
  existSpecialization,
  setValue,
  getValues,
  removeSpecialization,
  setRemoveSpecialization,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const selected = allSpecialization.filter((item) =>
      existSpecialization.some((el) => el.name === item.name)
    );
    setSelectedItems(selected);
  }, [existSpecialization]);

  return (
    <div
      className="tab"
      style={{ display: currentStep === 5 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          5. Specialized in (minimum 5 required)
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>
                Customers Can See this on your Profile Page and Feel Confident
                on your listing
              </p>
              <a
                href="../../assets/images/select4.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select4.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
        <p>{`You have Selected ${selectedItems.length} Specialisations`}</p>

        <ul className="specialisations">
          {allSpecialization.map((el) => (
            <li
              onClick={() => {
                if (selectedItems.length < 25) {
                  const exist = existSpecialization.find(
                    (item) => item.name === el.name
                  );
                  const selected = selectedItems.find(
                    (item) => item.name === el.name
                  );
                  const remove = removeSpecialization.find(
                    (item) => item.name === el.name
                  );
                  console.log(remove, exist, selected);
                  if (exist && selected) {
                    setRemoveSpecialization([...removeSpecialization, exist]);
                    const filter = selectedItems.filter(
                      (item) => item.name !== el.name
                    );
                    setSelectedItems(filter);
                  } else if (!exist && selected) {
                    const filter = selectedItems.filter(
                      (item) => item.name !== el.name
                    );
                    setSelectedItems(filter);
                    setValue("specialization", filter);
                  } else {
                    if (remove) {
                      const filter = selectedItems.filter(
                        (item) => item.name !== el.name
                      );
                      const removeFilter = removeSpecialization.filter(
                        (item) => item.name === el.name
                      );
                      setRemoveSpecialization(removeFilter);
                      setSelectedItems(filter);
                    } else {
                      setSelectedItems([...selectedItems, el]);
                      setValue("specialization", [el, ...selectedItems]);
                    }
                  }
                } else {
                  toast.error("You can only add 25 specialization");
                }
              }}
              className={`${
                selectedItems.find((item) => item.name === el.name) && "active"
              }`}
            >
              <i className="bi bi-patch-check-fill"></i> {el.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SpecializedTab;
