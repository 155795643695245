import React from "react";

const FeesContent = () => {
  return (
    <section className="fees_main">
      <div className="container">
        <h2>
          Boosting Your Profile as an Immigration Consultant or Training
          Institute
        </h2>
        <p>
          If you're an immigration consultant, agent, or represent an IELTS,
          PTE, or TOEFL training institute, Chownk offers a powerful way to
          enhance your visibility and attract more clients. By boosting your
          profile, you ensure that it stands out at the top of search results,
          making it easier for prospective clients to find and engage with your
          services. This specialized feature allows you to showcase your
          expertise and credentials prominently, giving you a competitive edge
          in the crowded market.
        </p>
        <h2>How Customers Can Benefit</h2>
        <p>
          Chownk caters to a diverse range of users including students, working
          professionals, and individuals planning to go overseas for study or
          work. As a customer, you can sign up for free and access a broad
          network of consulting partners within India. Whether you're looking
          for immigration consultants or language training institutes, you can
          browse their profiles, search based on your specific needs, and even
          communicate with them directly through video calls or chat.
        </p>

        <h2>Free Messaging and Paid Calls</h2>
        <p>
          Chownk offers free chat functionality, allowing you to send messages
          to any immigration consultant at no cost. This feature helps you ask
          preliminary questions and gather information without any financial
          commitment. However, if you prefer a more direct and real-time
          communication method, calling a consultant is available at a rate of
          INR 2.5 per minute. To get you started, new users receive INR 50 as a
          wallet balance upon registration, giving you a chance to make initial
          calls without incurring immediate charges.
        </p>

        <h2>Off-Site Communication Penalty</h2>
        <p>
          To ensure all transactions and communications occur within the Chownk
          platform, a penalty fee is imposed for users who violate the terms by
          taking their interactions off-site. This penalty amounts to 25% of the
          agreed transaction value, underscoring the importance of adhering to
          platform policies for a safe and regulated user experience.
        </p>

        <h2>Refunds as Bonus Credit</h2>
        <p>
          In certain situations, if you are eligible for a refund, it may be
          provided as bonus credit. This bonus can be used for future services
          on Chownk but cannot be withdrawn or transferred. Additionally, the
          bonus credit has a validity period of 365 days from the date of
          issuance, encouraging timely use of the refunded amount for further
          engagement on the platform.
        </p>

        <h2>Taxation</h2>
        <p>
          Taxes are applied according to the local regulations of the user’s
          country of residence. It’s important to understand the tax
          implications based on your location to avoid any surprises.
        </p>

        <h2>User Agreement</h2>
        <p>
          All fees, charges, and policies mentioned are governed by the Chownk
          User Agreement and related site policies. It is essential to review
          the User Agreement thoroughly as it contains critical terms and
          conditions that affect your use of the platform.
        </p>
        <p>
          Chownk aims to create a seamless and efficient experience for both
          professionals and customers in the realm of immigration consultancy
          and language training. By leveraging the platform’s features, you can
          connect with the right experts, manage your communications
          effectively, and navigate your overseas journey with greater
          confidence.
        </p>
      </div>
    </section>
  );
};

export default FeesContent;
