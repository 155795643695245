import { Link } from "react-router-dom";
import StudentHeader from "../../../Layouts/Student/Header";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../../../../Reducer/afterSearchSlice";
import { useEffect } from "react";

const Error = () => {
  const dispatch = useDispatch();
  const {error } = useSelector(
    (state) => state.afterSearch
  );
  useEffect(() => {
    if(error && error.found && error.found!="yes"){
      dispatch(clearError());
    }
  },[])
    
  return (
    <>
      <StudentHeader />
      <section className="arror text-center">
        <div className="container">
          <img src="../../../../assets/images/404.svg" alt="404" />
          <h4>Page not found</h4>
          <Link to="/" className="btn">
            Go to Home Page
          </Link>
          <ul>
            <li className="">
              <a href="https://www.facebook.com/people/Chownk/100083289649022/">
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li className="">
              <a href="https://www.instagram.com/chownkofficial/">
                <i className="bi bi-instagram"></i>
              </a>
            </li>
            <li className="">
              <a href="https://twitter.com/Chownk1">
                <i className="bi bi-twitter"></i>
              </a>
            </li>
            <li className="">
              <a href="https://www.youtube.com/channel/UC3gYIcz4rHJ9vEtm4sKlr1Q">
                <i className="bi bi-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default Error;
