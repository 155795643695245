import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
const DateSelecter = ({onHide,dates,setDates}) => {
    const [value, setValue] = React.useState(dates);
    const handleSelect=(ranges)=>{
    const dts = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: 'selection'
    }
      setValue(dts);
      const dts2 = {
        startDate: moment(dts.startDate).format('YYYY-MM-DD'),
        endDate: moment(dts.endDate).format('YYYY-MM-DD'),
        key: 'selection'
      }
      setDates(dts2);
    }
    
  return (
    <div>
      <DateRangePicker
        ranges={[value]}
        onChange={handleSelect}
        showSelectionPreview={false}
        showMonthAndYearPickers={false}
        maxDate={new Date()}
      />
      {/* <p>
        Start Date: {value.start.toLocaleDateString()}
      </p>
      <p>
        End Date: {value.end.toLocaleDateString()}
      </p> */}
    </div>
  );
};

export default DateSelecter;