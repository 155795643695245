const ProcessBar = ({
  style,
  areaLabel,
  areaValueNow,
  areaValueMin,
  areaValueMax,
  childAreaLabel,
  childAreaValueNow,
  childAreaValueMin,
  childAreaValueMax,
  parentInlineStyle,
  childInlineStyle,
  role,
  childRole,
}) => {
  return (
    <div
      className="progress"
      role={role ? role : "progressbar"}
      aria-label={areaLabel}
      aria-valuenow={areaValueNow}
      aria-valuemin={areaValueMin}
      aria-valuemax={areaValueMax}
      style={parentInlineStyle}
    >
      <div
        className={style}
        role={childRole}
        style={childInlineStyle}
        aria-label={childAreaLabel}
        aria-valuenow={childAreaValueNow}
        aria-valuemin={childAreaValueMin}
        aria-valuemax={childAreaValueMax}
      ></div>
    </div>
  );
};
export default ProcessBar;
