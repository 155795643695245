import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import RowInput from "../../Tools/RowInput";
import { addBranchFormSchema, emailregExp } from "../../../services/schema";
import { branchPhoneVerification } from "../../../Reducer/authSlice";
import {
  addBranchFormApi,
  availableCompanyNameApi,
  checkBranchApi,
  emailCheck,
} from "../../../services/api";
import SimpleInput from "../../Tools/SimpleInput";
import OTPInput from "react-otp-input";
import OTPTimer from "../../Tools/Otptimer";
import { toast } from "react-toastify";
import { googleMapApi } from "../../../data/data";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import LabelInputBox from "../../Tools/LabelInputBox";
import { Link } from "react-router-dom";

const BranchForm = ({ show, setShow }) => {
  const [companyMessage, setCompanyMessage] = React.useState({
    style: {},
    message: "",
  });
  const selector = useSelector((state) => state.auth);
  const [message, setMessage] = React.useState("");
  const [ootp, setOotp] = React.useState("");
  const [otpProcesss, setotpProcesss] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const dispatch = useDispatch();
  const [availablePhone, setAvailablePhone] = React.useState(false);
  const [otpButtonHide, setOtpButtonHide] = React.useState(false);
  const [otpButtonHideOne, setOtpButtonHideOne] = React.useState(false);
  const [timeOut, setTimeoutt] = React.useState("");
  const [emailAvailable, setEmailAvailable] = React.useState(false);
  const [slug, setSlug] = React.useState("");
  const [slugMessage, setSlugMessage] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(addBranchFormSchema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data.permanent_address) {
      let city = "";
      let state = "";

      data.permanent_address.address_components.forEach((element) => {
        if (element.types.includes("administrative_area_level_3")) {
          city = element.long_name;
          setValue("city", city);
          formData.append("city", city);
        } else if (element.types.includes("administrative_area_level_1")) {
          state = element.long_name;
          formData.append("state", state);
        }
      });
    }
    formData.append("owner_name", data.owner_name);
    formData.append("company_name", data.company_name);
    formData.append("email", data.email);
    formData.append(
      "permanent_address",
      data.permanent_address.formatted_address
    );
    formData.append("latitude", selector.auth && selector.auth.latitude);
    formData.append("longitude", selector.auth && selector.auth.longitude);
    formData.append("type", data.type);
    formData.append("phone", phoneNumber);
    formData.append("otp", ootp);
    if (ootp) {
      try {
        const response = await addBranchFormApi(formData);
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          setShow(!show);
          setotpProcesss(!otpProcesss);
          setAvailablePhone(!availablePhone);
          setOotp("");
          setCompanyMessage({
            style: {},
            message: "",
          });
          reset();
        }
      } catch (e) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        console.log("Add Branch Error", e);
      }
    } else {
      toast.error("Please enter the otp", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const phoneCheckHandle = (e) => {
    if (isNaN(e.target.value)) {
      setError("phone", { type: "custom", message: "Not use the aplhabet" });
    } else if (e.target.value.length > 10) {
      setError("phone", {
        type: "custom",
        message: "phone must be at most 10 characters",
      });
    } else {
      clearErrors("phone");
    }
    if (e.target.value.length === 10 && !isNaN(e.target.value)) {
      setMessage("");
      clearErrors("phone");
      setAvailablePhone(false);
      setPhoneNumber(e.target.value);
      checkBranchApi({ phone: e.target.value })
        .then((response) => {
          if (response.data.available) {
            clearErrors("phone");
            setMessage(response.data.message);
            setOtpButtonHideOne(true);
            setOtpButtonHide(false);
            setAvailablePhone(response.data.available);
          } else {
            setAvailablePhone(response.data.available);
            setError("phone", {
              type: "custom",
              message: response.data.message,
            });
          }
        })
        .catch((e) => {
          setAvailablePhone(e.response.data.available);
          setError("phone", {
            type: "custom",
            message: e.response.data.message,
          });
        });
    }
    setAvailablePhone(false);
  };

  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };
  const onResendFun = () => {
    const bodyy = {
      phone: phoneNumber,
      latitude: selector.userDetails?.latitude,
      longitude: selector.userDetails?.longitude,
      ip_address: selector.userDetails?.ip_address,
      current_address: selector.userDetails?.current_address,
    };
    dispatch(branchPhoneVerification(bodyy));
  };

  const companyHanleChange = async (e) => {
    clearErrors("company_name");
    clearTimeout(timeOut);
    if (selector.auth && selector.auth?.company_name === e.target.value) {
      clearErrors("company_name");
    } else {
      let TimeInterval = setTimeout(findCompany(e.target.value), 2000);
      setTimeoutt(TimeInterval);
    }
  };

  const handleEmailChange = async (e) => {
    try {
      const { value } = e.target;
      if (value.match(emailregExp)) {
        const response = await emailCheck(value);
        if (response.data.status === true) {
          setEmailAvailable(true);
          clearErrors("email");
        } else {
          setEmailAvailable(false);
          setError("email", { type: "custom", message: response.data.message });
        }
      } else {
        setEmailAvailable(false);
      }
    } catch (error) {
      setEmailAvailable(false);
      setError("email", { type: "custom", message: "Enter a Valid Email" });
    }
  };

  const findCompany = (val) => {
    availableCompanyNameApi({ company: val })
      .then((response) => {
        if (response.data.available) {
          clearErrors("company_name");
          setSlugMessage({
            style: { color: "green" },
            message: response.data.message,
          });
          setSlug(response.data.slug);
        } else {
          setSlugMessage({
            style: { color: "red" },
            message: "Company Name not Available",
          });
          setError("company_name", {
            type: "custom",
            message: response.data.message,
          });
        }
      })
      .catch((e) => {
        setSlugMessage({
          style: { color: "red" },
          message: "error",
        });
        setError("company_name", { type: "custom", message: e.message });
      });
  };

  return (
    <div className={`Add_New_Branch ${!show && "d-none"}`}>
      <h3>
        <Link onClick={() => setShow(!show)} href="#" className="back-button">
          <i className="bi bi-arrow-left"></i>
        </Link>
        {"Add New Branch"}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RowInput
          parentStyle={"row mt-4"}
          labelStyle={"col-md-5"}
          label={"Full Name"}
          labelDescription={"Add Full Name"}
          childStyle={"col-md-7"}
          inType={"text"}
          name={"owner_name"}
          event={register}
          errors={errors}
          placeholder={"Enter Full name"}
        />
        <div className="row mt-4">
          <div className="col-md-5">
            <h5>{"Mobile Number"}</h5>
            <p className="label">{"Add Mobile Number"}</p>
          </div>
          <div className="col-md-7">
            <div className="form-control">
              <div className="row">
                <div className="col-md-12">
                  <SimpleInput
                    style={"form-control"}
                    inType={"text"}
                    name={"phone"}
                    event={register}
                    placeholder={"Enter Mobile Number"}
                    onChange={phoneCheckHandle}
                  />
                  {otpButtonHideOne && (
                    <span
                      style={{
                        cursor:
                          selector.phoneNumber && availablePhone
                            ? "pointer"
                            : "",
                        color:
                          selector.phoneNumber && availablePhone
                            ? ""
                            : "#E3DAD8",

                        display: availablePhone ? "block" : "none",
                      }}
                      className={"sendotp"}
                      onClick={() => {
                        if (availablePhone) {
                          const bodyy = {
                            phone: phoneNumber,
                            latitude: selector.userDetails?.latitude,
                            longitude: selector.userDetails?.longitude,
                            ip_address: selector.userDetails?.ip_address,
                            current_address:
                              selector.userDetails?.current_address,
                          };
                          dispatch(branchPhoneVerification(bodyy));
                          setMessage("Otp send");
                          setotpProcesss(true);
                          setOtpButtonHide(!otpButtonHide);
                          setOtpButtonHideOne(false);
                        }
                      }}
                    >
                      Send OTP
                    </span>
                  )}
                </div>
              </div>
            </div>
            {availablePhone && <p style={{ color: "green" }}>{message}</p>}
            {errors && errors.phone && (
              <p className="arr" style={{ color: "red" }}>
                <i>
                  <i className="bi bi-info-circle-fill"></i>{" "}
                  {errors.phone.message}
                </i>
              </p>
            )}

            {otpButtonHide && otpProcesss && availablePhone && (
              <div className="form-groupotp">
                <label className="d-block text-start">
                  {"Enter OTP"} {selector.otp}
                </label>
                <OTPInput
                  className={"form-control"}
                  inputStyle={{ width: "40px" }}
                  value={ootp}
                  onChange={setOotp}
                  numInputs={4}
                  inputType={"tel"}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                <OTPTimer
                  duration={120}
                  onTimeout={handleOTPTimerTimeout}
                  onResend={onResendFun}
                />
                {selector.otpSuccess == false &&
                  selector.error &&
                  selector.errorPosition == "otp" && (
                    <p className="arr">
                      <i>
                        <i className="bi bi-info-circle-fill"></i>{" "}
                        {selector.error}
                      </i>
                    </p>
                  )}
              </div>
            )}
          </div>
        </div>
        <LabelInputBox
          label={"Email"}
          labelDescription={"Add your email"}
          style={"form-control"}
          event={register}
          error={errors}
          name={"email"}
          placeholder={"Enter Email Address"}
          availableIcon={emailAvailable}
          available={emailAvailable}
          onChange={handleEmailChange}
          message={"message"}
          parentCol={"col-md-5"}
          childCol={"col-md-7"}
        />

        <LabelInputBox
          label={"Company Name"}
          labelDescription={"Add Company Name"}
          style={"form-control"}
          event={register}
          error={errors}
          name={"company_name"}
          placeholder={"Enter Company Name"}
          disabled={selector.auth?.status !== "active" ? true : false}
          availableIcon={true}
          available={slugMessage?.style?.color == "green" ? true : false}
          onChange={companyHanleChange}
          message={slug ? slug : ""}
          parentCol={"col-md-5"}
          childCol={"col-md-7"}
        />
        <div className={"row mt-4"}>
          <div className={"col-md-5"}>
            <h5>{"Permanent Address"}</h5>
            <p className="label">{"Add Permanent Address"}</p>
          </div>
          <div className={"col-md-7"}>
            <Controller
              control={control}
              name="permanent_address"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactGoogleAutocomplete
                  apiKey={googleMapApi}
                  onPlaceSelected={onChange}
                  options={{
                    types: ["geocode"],
                    componentRestrictions: { country: "in" },
                  }}
                />
              )}
            />

            {errors && errors.permanent_address && (
              <p style={{ color: "red" }}>{errors.permanent_address.message}</p>
            )}
          </div>
        </div>

        <div className="text-end mt-5">
          <button type="submit">Add Branch</button>
        </div>
      </form>
    </div>
  );
};

export default BranchForm;
