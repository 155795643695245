import { useForm } from "react-hook-form";
import SimpleInput from "../../../Tools/SimpleInput";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAfterSearchResult,
  getCities,
} from "../../../../Reducer/afterSearchSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { googleMapApi } from "../../../../data/data";

const SearchCity = ({ setItem, selectedItems, navpath }) => {
  const { cities } = useSelector((state) => state.afterSearch);
  const [filteredCities, setFilterCities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentCity, setCurrentCity] = useState({});

  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const body = { popular: 1 };
    dispatch(getCities(body));
  }, [location]);

  useEffect(() => {
    setFilterCities(cities);
  }, [cities]);

  const citySearchFun = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value !== "") {
      const body = { search: e.target.value, location: "" };
      dispatch(getCities(body));
    } else {
      const body = { search: "", popular: 1 };
      dispatch(getCities(body));
    }
  };

  const handleClick = (value) => {
    if (value !== "") {
      let cityData = JSON.parse(value);
      setItem((prev) => ({ ...prev, city: cityData.city }));
      queryParams.set("city", cityData.city);
      queryParams.set("lat", cityData.latitude);
      queryParams.set("long", cityData.longitude);
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
      setSearchQuery("");
    } else {
      Fetchlocation();
    }
  };

  const Fetchlocation = (ipAddress) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      fetchLocationInfo(latitude, longitude, ipAddress);
    });
  };

  const fetchLocationInfo = (latitude, longitude, ipAddress) => {
    const apiKey = googleMapApi;
    let cities = "";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        await data.results.forEach((i, index) => {
          if (i.types.includes("administrative_area_level_3")) {
            cities = i.address_components[0].long_name;
            setItem((prev) => ({ ...prev, city: cities }));
            queryParams.set("city", cities.replaceAll(" ", "-"));
            queryParams.set("lat", latitude);
            queryParams.set("long", longitude);
            const newSearchString = queryParams.toString();
            navigate({ pathname: navpath, search: newSearchString });
          }
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  return (
    <li className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="../../assets/images/dp4.png" alt="img" />
        <img
          src="../../assets/images/apdp4W.svg"
          alt="img"
          className="white_img"
        />
        City
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <span className="search_result float-end" ref={dropdownRef}>
          <input
            type="text"
            placeholder="Search for a city..."
            value={searchQuery}
            onChange={citySearchFun}
            // onClick={() => setShowList(true)}
          />

          <ul onChange={(e) => handleClick(e.target.value)}>
            <li value={""} onClick={(e) => handleClick("")}>
              <i class="bi bi-geo-alt-fill"></i>
              Near me
            </li>
            {searchQuery.length === 0 && <h5>Popular Cities</h5>}
            {filteredCities.map((item) => (
              <li
                key={item.id}
                value={JSON.stringify(item)}
                onClick={(e) => handleClick(JSON.stringify(item))}
              >
                {item.city}
              </li>
            ))}
          </ul>
        </span>
      </div>
    </li>
  );
};
export default SearchCity;
