export const HomeMetaTags = {
  title: "Chownk: Find the Best Immigration Consultants in 60 seconds",
  description:
    "Connect instantly with the best immigration consultants and complete your immigration process entirely online from CHOWNK",
  keywords:
    "immigration agent,immigration consultants,education marketplace,educational institutes,pte institutes,find an immigration consultant,immigration consulting services,visa consultancy services",
  url: "",
};

export const AllBlogsMetaTags = {
  title: "Trusted Immigration Consultants, Education Visa Tips & More",
  description:
    "Explore insightful articles on immigration consultants, education visas, language trainers, tourist visa tips, and spouse visa advice on Chownk's informative blog page.",
  keywords: "",
};

export const categorytagsMetaTags = {
  title: " Discover Engaging Blogs and Articles on Chownk",
  description:
    "Dive into captivating blogs and articles on Chownk, covering a wide range of topics to keep you informed and engaged. Explore now!",
  keywords: "",
};

export const BecomeConsultantMetaTags = {
  title: "Join Chownk as an Immigration Consultant Today!",
  description:
    "Join Chownk and take your immigration consultancy to new heights. Connect with clients, expand your reach, and grow your consultancy with our secure platform.",
  keywords: "",
};

export const JoinUsMetaTags = {
  title: "Chownk | Become a Partner",
  description: "Become a Partner",
  keywords: "",
};

export const FeesAndChargesMetaTags = {
  title: "Chownk | Fees and Charges",
  description: "Fees and Charges",
  keywords: "",
};

export const SitemapMetaTags = {
  title: "Chownk | Sitemap",
  description: "Sitemap",
  keywords: "",
};

export const PrivacyPolicyMetaTags = {
  title: "Chownk | Privacy Policy",
  description: "Privacy Policy",
  keywords: "",
};

export const DisclaimerMetaTags = {
  title: "Chownk | Disclaimer",
  description: "Disclaimer",
  keywords: "",
};

export const AboutUsMetaTags = {
  title:
    "Chownk | Get in touch with the best and top rated Immigration Consultants",
  description:
    "Find top-rated visa consultancy services at Chownk. Get expert visa consultancy services for overseas and abroad visas and simplify your immigration journey with us!",
  keywords: "",
  url: "",
};

export const TermsAndConditionMetaTags = {
  title: "Chownk | Terms and Conditions",
  description: "Terms and Conditions",
  keywords: "",
};

export const ContactMetaTags = {
  title: "Contact Us : Chownk",
  description:
    "Chat with us and Submit Our Contact Form or Call us , We will be happy to assist you",
  keywords: "",
};

export const agentProfileMetaTags = {
  title: `Company Name | Chownk`,
  description: "Company Name in Location , Overview",
  keywords:
    "Company name in Location , Business type , Company Name Overview , Reviews , Success Stories , Photos or Videos , Faqs",
};

export const agentHelpMetaTags = {
  title: "Partner FAQ - Support | Chownk",
  description:
    "Looking for assistance with profile building, application management, or fee guidance as a partner? Chownk offers full support to ensure a seamless experience. Get help today!",
  keywords: "",
};
export const customerHelpMetaTags = {
  title: "Customer FAQ - Support | Chownk",
  description:
    "Need assistance for Chownk services? Visit us for customer support, FAQs, and detailed guides to resolve your issues quickly. We're here to help!",
  keywords: "",
};

export const StudyVisaMetaTags = {
  title:
    "Expert Guidance for Study Abroad: Search For best Education Visa consulant with Chownk",
  description:
    "Explore expert guidance for studying abroad with Chownk. Find the best education visa consultants to support your journey.",
  keywords:
    "study visa,education visa,study abroad,student visa consultants,apply for student visa,study visa consultants",
};

export const IeltsMetaTags = {
  title:
    "Explore Best and Top-rated IELTS, TOEFL, and PTE Training Institutes Near You",
  description:
    "Discover top-rated IELTS, TOEFL, and PTE Training Institutes on our platform. Find the best training institutes nearby on Chownk for your language test preparation.",
  keywords: "ILETS,PTE,TOEFL,toefl test,language test",
};

export const touristVisaMetaTags = {
  title: "Find Trusted Tourist Visa Consultants For Travel Needs",
  description:
    "Find the best visa consultants for your travel needs on Chownk. Trustworthy assistance for hassle-free tourist visas. Explore our platform now.",
  keywords:
    "tourist visa,tourist visa consultants,agent for visa,best visa consultants,travel visas",
};

export const spouseVisaMetaTags = {
  title: "Find out Trusted and Top Spouse Visa Consultants Near You",
  description:
    "Find reliable consultants near you on Chownk and get expert assistance for spouse visa and dependent visa applications.",
  keywords:
    "spouse visa consultants,spouse visa consultants near me,spouse visa,spouse dependent visa",
};
export const prMetaTags = {
  title: "Get Expert Assistance for Permanent Resident Card & Visa Application",
  description:
    "Get expert assistance for your permanent resident card and visa application on Chownk. Get in touch with Best Consultants who Can get you Permanent Residency Abroad.",
  keywords:
    "permanent residence,green card application,permanent resident card,residence permit,permanent resident visa",
};
export const workVisaMetaTags = {
  title: "Find Reliable and Trusted Work Visa Consultants on Chownk",
  description:
    "Discover trusted consultants on Chownk for your work visa needs. Get expert assistance for skilled worker visas and work permit applications.",
  keywords:
    "work visa,work permit,skilled worker visa,work permit application,work visa consultants",
};
export const nearMeMetaTags = {
  title: "Connect with Top Immigration Consultants Near You",
  description:
    "Explore trusted and verified immigration consultants near you on Chownk. Get expert visa consultancy services for visitor visas for abroad.",
  keywords:
    "immigration consultants,best immigration consultants,find an immigration consultant,visitor visa consultant near me,visa consultancy services near me",
};

export const searchPageMetaTags = {
  title: "Search Results for Immigration Consultant on Chownk",
  description:
    "Find the best immigration consultants for various visa services",
  keywords: "search",
};

export const downloadAppMetaTags = {
  title: "Find best immigration consultants | Download the app | Chownk",
  description:
    "Download Chownk app for best immigration services and visa services. Trusted immigration agents, access visa services, and simplify your immigration process.",
  keywords:
    "Best immigration consultants, Visa services, Immigration agents, immigration platform, Education Marketplace, Overseas visa consultancy, immigration and visa consultants",
};
