import React, { useState } from "react";
import { Link } from "react-router-dom";

const PopularSearches = ({ city, nearbySearches, handleUserNavigate }) => {
  const [limit, setLimit] = useState(5);
  return (
    <div className="maybe mt-3">
      <h2>
        Popular Searches in <span>{city}</span>
      </h2>
      <ul>
        {nearbySearches?.length > 0 &&
          nearbySearches.slice(0, limit).map((el, i) => (
            <li key={el.id}>
              <Link
                to={`/${el.first}/${el.second}/${el.third}`}
                onClick={() =>
                  handleUserNavigate(
                    "navigate",
                    `/${el.first}/${el.second}/${el.third}`,
                    "Popular Searches"
                  )
                }
              >
                {el.name}
              </Link>
            </li>
          ))}
        {limit < nearbySearches.length && nearbySearches.length > 5 && (
          <li onClick={() => setLimit(nearbySearches.length)}>
            <Link className="view_all">View All</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default PopularSearches;
