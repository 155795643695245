import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { fetchReviewApi } from "../../../../services/api";
import NavTabs from "../../../Tools/NavTabs";
import Example from "../../../Tools/ReactBoostrap";
import ReviewReplyModel from "../../../Layouts/Model/reviewReplyModel";
import { reviewsData } from "../../../../services/AgentProfile";
import ReviewsBox from "../../../Tools/ReviewsBox";
import ReplyShareModel from "../../../Layouts/Model/replyShareModel";
// import ReportModel from "";
import { mediaUrl } from "../../../../services/network";
import ReportModel from "../../../Layouts/Model/reportModel";

const ReviewRatingSection = ({ view, limit, user_id, averageRating }) => {
  const [activeTab, setActiveTab] = React.useState("letest");
  const [show, setShow] = React.useState(false);
  const [reviewsDataa, setReviewsDataa] = React.useState({});
  const [shareShow, setShareShow] = React.useState(false);
  const [reportShow, setReportShow] = React.useState(false);
  const { company_detail } = useSelector((state) => state.afterSearch);
  const navbarItem = [
    { label: "Latest", name: "letest" },
    { label: "High", name: "high" },
    { label: "Low", name: "low" },
  ];
  React.useEffect(() => {
    user_id &&
      fetchReviewApi({ user_id })
        .then((response) => {
          if (response.data.status === "success") {
            setReviewsDataa({
              latest:
                response.data.data.length > 0
                  ? response.data.data.toReversed()
                  : [],
              high:
                response.data.data.length > 0
                  ? response.data.data
                      .slice()
                      .sort((a, b) => b.ratting - a.ratting)
                  : [],
              low:
                response.data.data.length > 0
                  ? response.data.data
                      .slice()
                      .sort((a, b) => a.ratting - b.ratting)
                  : [],
            });
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          console.log("error", e);
        });
  }, [user_id]);

  return (
    <div className="main_st">
      {!view && (
        <h5 className="mb-3">
          <i className="bi bi-stars"></i>
          {"Ratings and Reviews"}{" "}
          {reviewsData && reviewsData.length > 3 && (
            <Link
              to={`${`/${company_detail && company_detail.city}/${
                company_detail && company_detail.slug
              }/reviews`}`}
              className="vall"
            >
              {"View all"}
            </Link>
          )}
        </h5>
      )}
      <div className="rv">
        <span>{averageRating ? averageRating : 0}</span>
        <p className="mt-0 h4">{`${
          reviewsDataa.latest && reviewsDataa.latest.length
        } Ratings`}</p>
        <p>{"VERIFIED COMPREHENSIVE RATNG"}</p>
      </div>

      {averageRating !== 0 && (
        <NavTabs
          data={navbarItem}
          active={activeTab}
          setActiveTab={setActiveTab}
          style={"letest_high"}
        />
      )}

      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade ${activeTab === "letest" && "show active"}`}
          id="Letest-tab-pane"
          role="tabpanel"
          aria-labelledby="Letest-tab"
          tabIndex="0"
        >
          {reviewsDataa.latest &&
            reviewsDataa.latest.length > 0 &&
            reviewsDataa.latest.map((item, i) => {
              if (i <= limit) {
                return (
                  <React.Fragment key={i}>
                    <ReviewsBox
                      backgroundImage={mediaUrl + item.profile_image}
                      name={item.name}
                      rating={item.ratting}
                      time={moment(item.created_at).format("DD-MM-YYYY")}
                      description={item.review}
                      reply={true}
                      replyOnclick={() => setShow(!show)}
                      shareOnClick={() => setShareShow(!shareShow)}
                      reportOnClick={() => setReportShow(!reportShow)}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
        <div
          className={`tab-pane fade ${activeTab === "high" && "show active"}`}
          id="High-tab-pane"
          role="tabpanel"
          aria-labelledby="High-tab"
          tabIndex="0"
        >
          {reviewsDataa.high &&
            reviewsDataa.high.length > 0 &&
            reviewsDataa.high.map((item, i) => {
              if (i <= limit) {
                return (
                  <React.Fragment key={i}>
                    <ReviewsBox
                      backgroundImage={mediaUrl + item.profile_image}
                      name={item.name}
                      rating={item.ratting}
                      time={moment(item.created_at).format("DD-MM-YYYY")}
                      description={item.review}
                      reply={true}
                      replyOnclick={() => setShow(!show)}
                      shareOnClick={() => setShareShow(!shareShow)}
                      reportOnClick={() => setReportShow(!reportShow)}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
        <div
          className={`tab-pane fade ${activeTab === "low" && "show active"}`}
          id="Low-tab-pane"
          role="tabpanel"
          aria-labelledby="Low-tab"
          tabIndex="0"
        >
          {reviewsDataa.low &&
            reviewsDataa.low.length > 0 &&
            reviewsDataa.low.map((item, i) => {
              if (i <= limit) {
                return (
                  <React.Fragment key={i}>
                    <ReviewsBox
                      backgroundImage={mediaUrl + item.profile_image}
                      name={item.name}
                      rating={item.ratting}
                      time={moment(item.created_at).format("DD-MM-YYYY")}
                      description={item.review}
                      reply={true}
                      replyOnclick={() => setShow(!show)}
                      shareOnClick={() => setShareShow(!shareShow)}
                      reportOnClick={() => setReportShow(!reportShow)}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
      </div>
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={<ReviewReplyModel onHide={() => setShow(!show)} />}
        style={"replymodal"}
      />
      <Example
        show={shareShow}
        onHide={() => setShareShow(!shareShow)}
        component={<ReplyShareModel onHide={() => setShareShow(!shareShow)} />}
        style={"replymodal share"}
      />

      <Example
        show={reportShow}
        onHide={() => setReportShow(!reportShow)}
        component={
          <ReportModel
            reportShow={reportShow}
            id={company_detail.id}
            setReportShow={reportShow}
            onHide={() => setReportShow(!reportShow)}
          />
        }
        style={"pop_width CreateOffer reports_modal"}
      />
    </div>
  );
};

export default ReviewRatingSection;
