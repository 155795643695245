import { Helmet } from "react-helmet";


const Testing = () => {

  return (
    <>
      <Helmet>
      <title>Your Page Title</title>
      <meta name="description" content="Connect instantly with the best immigration consultants and complete your immigration process entirely online from CHOWNK" />
      <meta name="keywords" content="immigration agent,immigration consultants,education marketplace,educational institutes,pte institutes,find an immigration consultant,immigration consulting services,visa consultancy services" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Your Page Title" />
      <meta property="og:description" content="Your page description." />
      <meta property="og:image" content="https://apiofficefront.chownk.in/cropped-9675.png" />
      <meta property="og:url" content="https://dev.chownk.in/testing" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Your Page Title" />
      <meta name="twitter:description" content="Your page description." />
      <meta name="twitter:image" content="https://apiofficefront.chownk.in/cropped-9675.png" />
    </Helmet>
    <h1>HHHHHHHHHHH HHHHHHHH HHHHHHHHHHH HHHHHHH HHHHHHHHHH</h1>
    </>
  );
};
export default Testing;
