import { Link } from "react-router-dom";

const MembershipPlanBox = ({
  image,
  type,
  typeDescription,
  currentPlan,
  currency,
  pricePerMonth,
  currentActive,
  pricePerMonthDescription,
  items,
}) => {
  return (
    <div className="col-3 ">
      <div className="set" style={{ backgroundImage: `url(${image})` }}>
        <h3>{type}</h3>
        <h4>{typeDescription}</h4>
        <h5>
          {currency} <span>{pricePerMonth}</span>
        </h5>
        <div className="text-center">
          <Link className={`btn ${currentActive === type && "btnn"}`}>
            {currentPlan}
          </Link>
          {/* <a href="#" className="btn btnm">Currently Active</a> */}
        </div>

        <p>{pricePerMonthDescription}</p>

        <h6>{"USD 5"}</h6>
        <ul>
          {items.length > 0 &&
            items.map((item) => {
              return <li>{item}</li>;
            })}
          {/* <li>10</li>
                <li>0</li>
                <li>01</li>
                <li>05</li>
                <li>----</li>
                <li>----</li>
                <li>----</li>
                <li>----</li>
                <li>----</li> */}
        </ul>
      </div>
    </div>
  );
};
export default MembershipPlanBox;
