import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { claimBusinessApi } from "../services/api";

export const claimBusiness = createAsyncThunk(
  "claim_business",
  async (body, { rejectWithValue }) => {
    try {
      const response = await claimBusinessApi(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const claimAndReportSlice = createSlice({
  name: "businessClaim",
  initialState: {},
  extraReducers: (buider) => {
    buider
      .addCase(claimBusiness.pending, (state, action) => {})
      .addCase(claimBusiness.fulfilled, (state, action) => {})
      .addCase(claimBusiness.rejected, (state, action) => {});
  },
});

export default claimAndReportSlice.reducer;
