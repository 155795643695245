const TopBar = () => {
    return (<div className="stuser">
        <h6>Application ID: hsdf672</h6>
        <p>Status: ACTIVE</p>
        <div className="user">
            <span className="img" style={{ backgroundImage: "url(../../assets/images/user.png)" }} ></span>
            Lucciaa
            <p>Gray Matters</p>
        </div>
    </div>)
}
export default TopBar;