import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button";
import SimpleInput from "../SimpleInput";
import { homeSearchApi } from "../../../services/api";
import SearchSuggestionBox from "../searchSuggestionBox";
import { mediaUrl } from "../../../services/network";
import { searchBarData } from "../../../services/HomePageData";
import RecentSearchBox from "../RecentSearchBox";
import { popularSearchData } from "../../../data/data";
import { getRecentSearches } from "../../../Reducer/afterSearchSlice";

const Location = ({
  location,
  fetchedLocation,
  formDisplay,
  data,
  LocationShow,
  handleUserNavigate,
}) => {
  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState([]);
  const [dataa, setDataa] = useState([]);
  const [open, setOpen] = useState(false);
  const selector = useSelector((state) => state.auth?.userDetails);
  const searchRef = useRef(null);
  const { recentSearches } = useSelector((state) => state.afterSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const locations = useLocation();
  const queryParams = new URLSearchParams(locations.search);
  const [formSearchData, setFormSearchData] = useState(
    queryParams.get("search") && queryParams.get("search") !== ""
      ? queryParams.get("search")
      : ""
  );

  const text = [
    "Immigration Consultants",
    "IELTS-PTE-TOEFL Institutes",
    "Education Visa Consultants",
    "Top Rated Consultants",
  ];

  React.useEffect(() => {
    if (LocationShow) {
      setShow(LocationShow);
    }
  }, [LocationShow]);

  React.useEffect(() => {
    let timeout;

    if (textIndex < text.length) {
      if (currentIndex <= text[textIndex].length) {
        timeout = setTimeout(() => {
          setCurrentText(
            (prevText) => prevText + text[textIndex][currentIndex]
          );
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 200);
      } else {
        setCurrentText("");
        setCurrentIndex(0);
        setTextIndex((prev) => (prev === text.length ? 0 : prev + 1));
      }
    } else {
      setTextIndex(0);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, text, textIndex]);

  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(!open);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);

  const handleSearch = (e) => {
    setOpen(true);
    setFormSearchData(e.target.value);
    if (e.target.value.length === 0) {
      setShowData(searchBarData);
      setDataa([]);
    }
    if (e.target.value.length > 1 && selector?.locationStatus === false) {
      navigate("/no-service");
    }
    if (e.target.value.length > 1) {
      homeSearchApi({
        latitude: selector.latitude,
        longitude: selector.longitude,
        city: selector.city,
        text: e.target.value,
      })
        .then((response) => {
          if (response.data.status === "success") {
            setDataa(response.data.data);
          }
        })
        .catch((e) => {
          console.log("error-->faq", e);
        });
    }
  };

  const handleClearIconForSearchBar = () => {
    setFormSearchData("");
    setOpen(!open);
    setDataa([]);
  };

  return (
    <div className="hed_left">
      {formDisplay && (
        <>
          <form
            className="search_form"
            onClick={() => {
              dispatch(getRecentSearches());
              setOpen(!open);
            }}
            onSubmit={(event) => {
              event.preventDefault();
              if (handleUserNavigate) {
                handleUserNavigate(
                  "search",
                  `/search?search=${formSearchData}`,
                  "search"
                );
              }
              navigate(`/search?search=${formSearchData}`);
            }}
          >
            <Button
              inType={"button"}
              title={<i className="bi bi-search"></i>}
            />
            <SimpleInput
              inType={"text"}
              placeholder={`Search for '${currentText}'`}
              name={"search"}
              reff={searchRef}
              value={formSearchData}
              onChange={handleSearch}
            />
            {formSearchData.length > 0 && (
              <ImCross onClick={handleClearIconForSearchBar} />
            )}
          </form>
          {open && (
            <div ref={dropdownRef} className="ulshow checkClass">
              {dataa && dataa.length > 0 ? (
                <ul className="search_list">
                  {dataa.map((item) => {
                    return (
                      <SearchSuggestionBox
                        image={mediaUrl + item.company_logo}
                        name={item.company_name}
                        rating={item.rating}
                        location={item.permanent_address}
                        slug={item.slug}
                        averageRatting={item.averageRating.toFixed(1)}
                        rattingCount={item.rattingCount}
                        specialFun={() => setOpen(!open)}
                        city={item.city}
                        handleUserNavigate={handleUserNavigate}
                      />
                    );
                  })}
                </ul>
              ) : (
                <ul>
                  <RecentSearchBox
                    setSearch={setFormSearchData}
                    recentSearches={recentSearches}
                    popularSearches={popularSearchData}
                    handleUserNavigate={handleUserNavigate}
                  />
                </ul>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Location;
