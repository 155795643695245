import React from "react";
import { mediaUrl } from "../../../services/network";

const   MainLoader = () => {
  return (
    <div className="text-center main_loader">
      <img src={`${mediaUrl}spinner.gif`} />
    </div>
  );
};

export default MainLoader;
