import ProcessBar from "../ProcessBar";

const AnalyticsBox = ({
  parentStyle,
  data,
  imgSrc,
  title,
  description,
  prog,
}) => {
  return (
    <div className={parentStyle}>
      <h4>
        <img src={imgSrc} alt="Call_History" /> {title}
      </h4>
      <p className="Lorem ps-0">{description}</p>
      <table className="table table_gray" style={{ "min-width": "100%" }}>
        {data.length > 0 &&
          data.map((item) => {
            return (
              <tr>
                <td>
                  <h6>{item.title}</h6>
                  <p>{item.description}</p>
                </td>
                <td>
                  {prog && (
                    <ProcessBar
                      style={"progress-bar"}
                      areaLabel={item.areaLabel}
                      areaValueNow={item.areaValueNow}
                      areaValueMin={item.areaValueMin}
                      areaValueMax={item.areaValueMax}
                      parentInlineStyle={item.parentInlineStyle}
                      childInlineStyle={item.childInlineStyle}
                    />
                  )}
                </td>
                <td>{item.views}</td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default AnalyticsBox;
