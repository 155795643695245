import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Example from "../ReactBoostrap";
import StudentLogin from "../../Pages/Student/Login";
import StudentOtp from "../../Pages/Student/Otp";
import RegisterAvatarModel from "../../Pages/Student/RegisterAvatarModel";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeNumber,
  otpVerification,
  setAction,
} from "../../../Reducer/authSlice";

const LoginModel = ({ show, setShow }) => {
  const [otpShow, setOtpShow] = React.useState(false);
  const [registerOtp, setRegisterOtp] = React.useState(false);
  const normalSelector = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth?.token);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const applogin = useCallback(async () => {
    if (
      (!token || token === "" || token === null || token === undefined) &&
      query.get("phone") &&
      query.get("otp")
    ) {
      const body = {
        phone: query.get("phone"),
        otp: query.get("otp"),
        app_id: query.get("app_id"),
        app_version: query.get("app_version"),
        platform: query.get("platform"),
        longitude: 0,
        latitude: 0,
        ip_address: query.get("ip_address"),
        current_address: "",
        device_token: query.get("device_token"),
        device_id: query.get("device_id"),
        log: query.get("log"),
        app: true,
      };
      localStorage.setItem("dt", query.get("device_token"));
      await dispatch(otpVerification(body));
      setTimeout(() => {
        let dtss = { type: "loader" };
        window.ReactNativeWebView.postMessage(JSON.stringify(dtss));
      }, 700);
    }
  }, [dispatch, query, token]);

  useEffect(() => {
    applogin();
  }, [applogin]);

  const modelViewControll = () => {
    if (
      normalSelector.auth?.role === 1 &&
      normalSelector.auth?.status === "uncompleted"
    ) {
      setRegisterOtp(!registerOtp);
    }
    if (normalSelector.success && normalSelector.actionType === "header") {
      setOtpShow(true);
      setShow(false);
    } else if (
      normalSelector.otpSuccess &&
      normalSelector.actionType === "header"
    ) {
      switch (normalSelector.redireaction) {
        case "/home":
          setShow(false);
          setOtpShow(false);
          dispatch(setAction(""));
          if (normalSelector.auth?.role === 5) {
            if (normalSelector.auth.profile_percentage >= 75) {
              navigate("/partner-dashboard");
            } else {
              navigate("/verification");
            }
            // navigate("/partner-dashboard");
          } else if (normalSelector.auth?.role === 6) {
            navigate("/staff-dashboard");
          } else {
            // navigate("/");
          }
          break;
        case "/":
          setShow(false);
          setOtpShow(false);
          dispatch(setAction(""));
          if (normalSelector.auth?.role === 5) {
            navigate("/partner-dashboard");
          } else if (normalSelector.auth?.role === 6) {
            navigate("/staff-dashboard");
          } else {
            // navigate("/");
          }
          break;
        case "/profile_setup":
          if (normalSelector.auth?.role !== 5) {
            setShow(false);
            setOtpShow(false);
            setRegisterOtp(true);
          } else {
            setShow(false);
            setOtpShow(false);
            dispatch(setAction(""));
            navigate("/verification");
            // navigate("/partner-dashboard");
          }
          break;
        case "/login":
          toast.success(normalSelector.error, {
            position: toast.POSITION.TOP_LEFT,
          });
          break;
        default:
          setShow(false);
          setOtpShow(false);
          dispatch(setAction(""));
          if (normalSelector.auth?.role === 5) {
            navigate("/partner-dashboard");
          } else if (normalSelector.auth?.role === 6) {
            navigate("/staff-dashboard");
          } else {
            navigate("/");
          }
          break;
      }
    }
  };

  return (
    <>
      <Example
        show={show}
        onHide={() => setShow(!show)}
        component={
          <StudentLogin
            onHide={() => setShow(!show)}
            modelViewControll={modelViewControll}
          />
        }
        style={"loginmodal"}
      />
      <Example
        show={otpShow}
        onHide={() => {
          dispatch(changeNumber());
          setOtpShow(!otpShow);
        }}
        component={
          <StudentOtp
            onHide={() => setOtpShow(false)}
            setShowPhoneModel={() => setShow(!show)}
            modelViewControll={modelViewControll}
          />
        }
        style={"loginmodal"}
      />
      <Example
        show={registerOtp}
        onHide={() => setRegisterOtp(!registerOtp)}
        component={
          <RegisterAvatarModel onHide={() => setRegisterOtp(!registerOtp)} />
        }
        hideCloseBtn={true}
        style={"loginmodal avatar_popup"}
        backDrop={"static"}
      />
    </>
  );
};
export default LoginModel;
