import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SocketContext } from "../../../Context";
const OfferViewOnList = ({ data, right }) => {
  const { currentUser, setCurrentChat, socket } = useContext(SocketContext);
  const [expiry, setExpiry] = useState();
  const send = (id, status) => {
    var date = Math.round(new Date().getTime() / 1000);
    let dts = {
      first: false,
      sender_id: currentUser.user_id,
      receiver_id: currentUser.connection_user,
      type: "text",
      file_type: "",
      file: "",
      chat_id: id,
      update_status: status,
      sms_status: "sent",
      offer_status: "reject",
      message: "Your offer is declined",
      unix_time: date,
    };
    sendMessage(dts);
  };

  const sendMessage = (data) => {
    socket.emit("send_sms", data);
    setCurrentChat((prevData) => [...prevData, data]);
  };

  const getExpirey = useCallback(() => {
    const today = new Date();
    const expire = new Date(data.expiery_unix);
    const diff = expire.getTime() - today.getTime();
    const diff_in_days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    setExpiry(diff_in_days);
  }, [data]);

  useEffect(() => {
    getExpirey();
  }, [getExpirey]);

  return (
    <div className="txt College_dt">
      <h3>
        {" "}
        <img src="/assets/images/per2.png" alt="img" />{" "}
        {`You have ${right ? "Sent" : "Recieved"} a New Offer`}
      </h3>
      <h6>{"Visa Type"}</h6>
      <ul>
        <li>{data.visa_type}</li>
        {/* <li>{data.college_address}</li> */}
        {/* <li>
          <a href={data.college_website_url}>{data.college_website_url}</a>
        </li> */}
      </ul>
      <h6>{"Offer Details"}</h6>
      <ul>
        <li>title: {`${data.title}`}</li>

        <li>
          {"Description:"} {data.description}
        </li>
        <li>
          {"Expiry Date:"} {new Date(data.expiery_unix).toLocaleDateString()}
        </li>
        {/* <li>
          {"Fees:"} {`${data.college_fees}`}
        </li> */}
      </ul>

      {/* <p className="Note">
        <b>Note:</b> Course Fees Should be Paid to College's bank account.
      </p> */}

      <h5>{"Charges:"}</h5>
      {/* <p>{data.brief_services}</p> */}
      {/* {data.Items
        ? JSON.parse(data.Items).map((el) => (
            <p>{`${el.item_name} : ₹${el.item_price}`}</p>
          ))
        : ""} */}
      {right === true ? (
        <div className="row mt-2">
          <div className="col-6">
            <h2>{`₹${data.total}`}</h2>
          </div>
        </div>
      ) : (
        <div className="row mt-2 footb">
          <div className="col-4">
            <h3>{data.total}</h3>
            <p>{"Service fees"}</p>
          </div>
          {data.payment_status && data.payment_status === "accept" && (
            <p className="acpt">{"This Offer is already accepted"}</p>
          )}
          {(data.payment_status && data.payment_status === "reject") ||
            (data?.offer_status && data?.offer_status === "reject" && (
              <p className="dicline">
                <i className="bi bi-exclamation-circle-fill"></i>
                {"This Offer is already declined"}
              </p>
            ))}
          {data.offer_status && data.offer_status === "pending" && (
            <>
              <div className="col-4">
                <Link
                  target={expiry > 0 && "_blank"}
                  onClick={() => {
                    data.payment_status = "accept";
                    data.offer_status = "accept";
                  }}
                  to={expiry > 0 && `/ConfirmOffer/${data.id}`}
                  className="btn acept"
                >
                  {"Accept"}
                </Link>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  onClick={() => {
                    send(data.id, "reject");
                    data.offer_status = "reject";
                  }}
                  className="btn"
                  disabled={expiry < 0}
                >
                  Decline
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {/* {!data.offer_status && <><div className="col-4"><button type="button" onClick={() => send(data.id, 'reject')} className="btn">Decline</button></div>
                <div className="col-4"><Link to={`/ConfirmOffer/${data.id}`} className="btn acept">Accept</Link></div></>} */}
      <p className="text-end mt-2">
        {data.offer_status === "reject" ? (
          <i>{`*Offer Rejected`}</i>
        ) : data.offer_status === "accept" ? (
          <i>{`*Offer Accepted`}</i>
        ) : expiry > 0 ? (
          <i>{`*Offer will expire in ${expiry} ${
            expiry === 1 ? "day" : "days"
          }`}</i>
        ) : (
          <i>{`*Offer Expired`}</i>
        )}
      </p>
    </div>
  );
};
export default OfferViewOnList;
