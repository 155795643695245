import { Link } from "react-router-dom";

const LiItem = ({ path, label, onClick, img, listStyle }) => {
  return (
    <li className={listStyle ? listStyle : ""} onClick={onClick}>
      <Link to={path} aria-label={label}>
        {img}
        {label}
      </Link>
    </li>
  );
};

export default LiItem;
