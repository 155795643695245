import { Doughnut } from "react-chartjs-2";
import ProcessBar from "../../../Tools/ProcessBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect, useState } from "react";
import { usedetail } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const VisitorsSource = ({ detail }) => {
  const [values, setValues] = useState({
    search: 25,
    reffer: 25,
    direct: 25,
    boost: 25,
  });
  const datas =
    detail != null
      ? [detail.search, detail.direct, detail.reffer, detail.boost]
      : [25, 25, 25, 25];

  useEffect(() => {
    if (detail != null) {
      const sum = detail.search + detail.direct + detail.reffer + detail.boost;
      const search = (detail.search * 100) / sum;
      const direct = (detail.direct * 100) / sum;
      const reffer = (detail.reffer * 100) / sum;
      const boost = (detail.boost * 100) / sum;

      setValues({
        search: search,
        direct: direct,
        reffer: reffer,
        boost: boost,
      });
    }
  }, [detail]);
  const data = {
    labels: ["Search", "Direct", "Referral", "Boost"],
    datasets: [
      {
        data: datas,
        backgroundColor: [
          "rgb(54, 98, 193)",
          "rgb(29, 191, 115)",
          "rgb(225, 225, 225)",
          "rgb(240, 193, 125)",
        ],
      },
    ],

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2,
      },
    },
    text: "23%",
  };
  return (
    <div className="wap mb-3">
      <h4>
        <img src="../../assets/images/Staff_Members.png" alt="Call_History" />{" "}
        {"Visitors Source"}
      </h4>
      <p className="Lorem ps-0">
        Discover where your profile visitors are coming from with CHOWNK’s
        Visitors Source analytics. Understanding your visitor sources helps
        optimize your reach and connect with more clients seeking the best
        immigration consultants on CHOWNK.
      </p>
      <div className="row mt-4">
        <div className="col-5 text-center">
          {/* <img
            src="../../assets/images/Visitors.png"
            className="Visitors"
            alt="Visitors"
          /> */}
          <Doughnut
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div className="col-7">
          <div className="Searchchat">
            <div className="progress-stacked">
              <ProcessBar
                style={"progress-bar bg-light"}
                areaLabel={"Segment two"}
                areaValueNow={"15"}
                areaValueMin={"0"}
                areaValueMax={"100"}
                parentInlineStyle={{ width: `${values.reffer}%` }}
              />
              <ProcessBar
                style={"progress-bar bg-success"}
                areaLabel={"Segment five"}
                areaValueNow={"20"}
                areaValueMin={"0"}
                areaValueMax={"100"}
                parentInlineStyle={{ width: `${values.direct}%` }}
              />
              <ProcessBar
                style={"progress-bar"}
                areaLabel={"Segment four"}
                areaValueNow={"20"}
                areaValueMin={"0"}
                areaValueMax={"100"}
                parentInlineStyle={{ width: `${values.search}%` }}
              />
              <ProcessBar
                style={"progress-bar bg-warning"}
                areaLabel={"Segment three"}
                areaValueNow={"20"}
                areaValueMin={"0"}
                areaValueMax={"100"}
                parentInlineStyle={{ width: `${values.boost}%` }}
              />
            </div>
            {detail && detail != null ? (
              <table className="table" style={{ "min-width": "100%" }}>
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td>Referral</td>
                  <td>{detail.reffer}</td>
                </tr>
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td>Direct</td>
                  <td>{detail.direct}</td>
                </tr>
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td>Search</td>
                  <td>{detail.search}</td>
                </tr>

                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td>Boost</td>
                  <td>{detail.boost}</td>
                </tr>
              </table>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VisitorsSource;
