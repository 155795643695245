import React from "react";
import LiItem from "../../../Tools/LiItem";

const SocialMedia = ({ data, handleUserNavigate }) => {
  return (
    <div className="socle mt-4">
      <div className="row">
        <div className="col-6 col-xl-6">
          <ul className="p-0 m-0">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <LiItem
                      path={item.path}
                      label={<i className={`bi bi-${item.label}`}></i>}
                      onClick={() =>
                        handleUserNavigate(
                          "social",
                          item.path,
                          `Visit to ${item.label}`
                        )
                      }
                    />
                  </React.Fragment>
                );
              })}
          </ul>
        </div>
        <div className="col-6 text-end">
          <p className="m-0 p-0">{"©2024 Chownk.com"}</p>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
