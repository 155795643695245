import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleInput from "../SimpleInput";

const VideoFilter = ({ navpath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [videoStatus, setVideoStatus] = useState(
    queryParams.get("video") ? true : false
  );

  const handleChange = () => {
    if (videoStatus) {
      queryParams.delete("video");
    } else {
      queryParams.set("video", !videoStatus);
    }
    const newSearchString = queryParams.toString();
    navigate({ pathname: location.pathname, search: newSearchString });
  };

  return (
    <li className="dropdown">
      <button>
        Call
        <div className="form-check form-switch">
          <SimpleInput
            style={"form-check-input"}
            inType={"checkbox"}
            id={"flexSwitchCheckDefault"}
            value={videoStatus}
            checked={videoStatus ? true : false}
            onChange={() => {
              setVideoStatus(!videoStatus);
              handleChange();
            }}
          />
        </div>
      </button>
    </li>
  );
};

export default VideoFilter;
