import React from "react";
import StudentHeader from "../../../Layouts/Student/Header";
import TopHeader from "./topHeader";
import ListingNotifications from "../NotificationPage/listingNotification";
import StudentFooter from "../../../Layouts/Student/Footer";
import CallListing from "./callListing";

const CallHistory = () => {
  return (
    <div>
      <StudentHeader formDisplay={true} />
      {/* <TopHeader /> */}
      <CallListing />
      <StudentFooter />
    </div>
  );
};

export default CallHistory;
