import React, { useContext, useRef, useState } from "react";
import { mediaUrl } from "../../../services/network";
import { Player } from "video-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SocketContext } from "../../../Context";
import FindUser from "../../Tools/FindUser";
import Example from "../../Tools/ReactBoostrap";
import { Rating } from "react-simple-star-rating";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { saveCallDetail } from "../../../services/api";

const ViewProfileModel = ({ data, index }) => {
  const { company_detail } = useSelector((state) => state.afterSearch);
  const selector = useSelector((state) => state.auth);
  const [userModel, setUserModel] = useState(false);
  const {
    setCurrentUser,
    callTo,
    sendMessage,
    findChatFun,
    isPlaying,
    setIsPlaying,
    addFundsModal,
    setAddFundsModal,
    finUser,
  } = useContext(SocketContext);
  const [state, setState] = React.useState({
    index: index,
  });

  const loginModelHandle = (type) => {
    if (
      company_detail.id !== selector.auth.id &&
      company_detail.uid !== selector.auth.parent_id
    ) {
      if (type == "video") {
        if (
          company_detail.online !== true ||
          company_detail.online !== "true"
        ) {
          saveCall(company_detail.uid);
        }
        if (selector.funds >= selector.call_min) {
          let data = {
            connection_user: company_detail.uid,
            uid: company_detail.uid,
            name: company_detail.company_name,
          };
          setCurrentUser(data);
          finUser(company_detail.uid);
          setUserModel(true);
        } else {
          setAddFundsModal(!addFundsModal);
        }
      } else {
        var date = Math.round(new Date().getTime() / 1000);
        let data = {
          receiver_id: company_detail.uid,
          image: company_detail.company_logo,
          company_name: company_detail.company_name,
          online: company_detail.online !== "" ? true : false,
          status: company_detail.status,
          owner_name: company_detail.owner_name,
          slug: company_detail.slug,
          city: company_detail.city,
          userRole: "branch",
        };
        findChatFun(data);
      }
    }
  };
  const playerRef = useRef(data.map(() => React.createRef()));

  const handlePauseClick = () => {
    if (playerRef.current) {
      playerRef.current.map((ref) => ref.current?.actions?.pause());
    }
  };

  const saveCall = (id) => {
    try {
      const body = { to: id };
      saveCallDetail(body);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="modal-content">
      <div className="modal-body">
        <div id="carouselExample" className="carousel slide">
          <div className="row">
            <div className="col-sm-5">
              <div className="digit">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${
                      mediaUrl + company_detail?.company_logo
                    })`,
                  }}
                ></div>
                <p className="h4">{company_detail.company_name}</p>
                {company_detail.rattingCount > 0 && (
                  <p>
                    <Rating
                      initialValue={company_detail.averageRating}
                      readonly
                      allowFraction
                      size={20}
                    />
                    <span>({company_detail?.averageRating?.toFixed(1)})</span>
                  </p>
                )}
              </div>
              <div className="text-start pt-4">
                <div className="carousel-indicators">
                  {data &&
                    data.length > 0 &&
                    data.map((item, i) => {
                      return (
                        <>
                          <div
                            className={state.index === i && "active"}
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to={i}
                            aria-label="Slide 1"
                          >
                            <p>{item.title}</p>
                            <p>{item.description}</p>

                            <p className="my-4">
                              {isPlaying && isPlaying == true ? (
                                <Link
                                  className={`mb-2 btntimer ${
                                    company_detail.uid === selector?.auth?.uid
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <i className="bi bi-camera-video-fill"></i>{" "}
                                  {"Call"}
                                  <CountdownCircleTimer
                                    isPlaying={isPlaying}
                                    duration={10}
                                    colors="#A30000"
                                    size={36}
                                    initialRemainingTime={10}
                                    strokeWidth={4}
                                    trailStrokeWidth={4}
                                    children={({ remainingTime }) => {
                                      const minutes = Math.floor(
                                        remainingTime / 60
                                      );
                                      const seconds = remainingTime % 60;
                                      return `${seconds}`;
                                    }}
                                    onComplete={() => {
                                      setIsPlaying(false);
                                      return {
                                        shouldRepeat: false,
                                        delay: 1.5,
                                      };
                                    }}
                                  />
                                </Link>
                              ) : (
                                <Link
                                  onClick={() => loginModelHandle("video")}
                                  className={`mb-2 ${
                                    company_detail.uid === selector?.auth?.uid
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  {(company_detail.online == true ||
                                    company_detail.online == "true") &&
                                    company_detail.video_status == "active" && (
                                      <span></span>
                                    )}
                                  <i className="bi bi-camera-video-fill"></i>{" "}
                                  {"Call"}
                                </Link>
                              )}
                              <Link
                                className={`${
                                  company_detail.uid === selector?.auth?.uid
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => loginModelHandle("chat")}
                              >
                                {(company_detail.online == true ||
                                  company_detail.online == "true") && (
                                  <span></span>
                                )}
                                <i className="bi bi-chat-fill"></i> {"Message"}
                              </Link>
                            </p>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="carousel-inner" data-interval="false">
                {data &&
                  data.length > 0 &&
                  data.map((item, i) => {
                    if (item.media_type === "video") {
                      return (
                        <div
                          className={`carousel-item ${
                            state.index === i && "active"
                          }`}
                        >
                          <Player
                            playsInline
                            src={mediaUrl + item.image}
                            fluid={false}
                            key={i}
                            ref={playerRef.current[i]}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <>
                          <div
                            className={`carousel-item ${
                              state.index === i && "active"
                            }`}
                            style={{
                              backgroundImage: `url(${mediaUrl + item.image})`,
                            }}
                          ></div>
                        </>
                      );
                    }
                  })}
              </div>

              <button
                onClick={handlePauseClick}
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">{"Previous"}</span>
              </button>
              <button
                onClick={handlePauseClick}
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">{"Next"}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Example
        show={userModel}
        style={"connect ofline_div"}
        onHide={() => setUserModel(!userModel)}
        component={
          <FindUser
            show={userModel}
            data={company_detail}
            onHide={() => setUserModel(!userModel)}
          />
        }
      />
    </div>
  );
};
export default ViewProfileModel;
