import React from "react";

const TermsContent = () => {
  return (
    <section className="fees_main">
      <div className="container">
        <p>
          These terms and conditions of Use (hereinafter referred as “Terms of
          Usage”) describe and govern the User’s use of the content and services
          offered by Digittrix Infotech Private Ltd through www.chownk.com
          (hereinafter referred as “We” “Chownk” “us” “our” “chownk application”
          “Website”).
        </p>
        <h2>UPDATION</h2>
        <p>
          The Website may update/amend/modify these Terms of Usage from time to
          time. The User is responsible to check the Terms of Usage periodically
          to remain in compliance with these terms.
        </p>
        <h2>USER CONSENT</h2>
        <p>
          By accessing the Website and using it, you (“Member”, “You”, “Your”)
          indicate that you understand the terms and unconditionally &amp;
          expressly consent to the Terms of Usage of this Website. If you do not
          agree with the Terms of Usage, please do not click on the “I AGREE”
          button. The User is advised to read the Terms of Usage carefully
          before using or registering on the Website or accessing any material,
          information or services through the Website. Your use and continued
          usage of the Website (irrespective of the amendments made from time to
          time) shall signify your acceptance of the terms of usage and your
          agreement to be legally bound by the same.
        </p>
        <h2>GENERAL DESCRIPTION</h2>
        <p>
          The Website is an internet-based portal having its existence on World
          Wide Web, Application and other electronic medium and providesl
          content, reports, data, telephone, video and email consultations
          (hereinafter referred as “Content”). The Website is offering “Free
          Services” and “Paid Services” (Collectively referred as “Services”).
          Free Services are easily accessible without becoming a member however
          for accessing the personalised services and/or receive additional
          Content and get access to Paid Services, You are required to register
          as a member on the portal. By registering for Paid Services, a Member
          agrees to:
        </p>
        <ul>
          <li>
            To provide current, complete, and accurate information about himself
            as prompted to do so by the Website.
          </li>
          <li>
            To maintain and update the above information as required and
            submitted by you with the view to maintain the accuracy of the
            information being current and complete.
          </li>
        </ul>
        <h2>REGISTRATION AND ELIGIBILITY</h2>
        <ul>
          <li>
            The User of the Website must be a person who can form legally
            binding contracts under Indian Contract Act, 1872. A minor under the
            age of eighteen (18) in most jurisdiction, are not permitted to
            avail the services provided on the Website without a legal guardian
            in accordance with the applicable laws. The Website would not be
            held responsible for any misuse that may occur by virtue of any
            person including a minor using the services provided through the
            Website.
          </li>
          <li>
            For the User to avail the services, the User will be directed to
            Register as a Member on the Website or Mobile app whereby You (User)
            agree to provide update, current and accurate information while
            filling up the sign-in form. All information that you fill and
            provide to the Website and all updates thereto are referred to in
            these Terms of Usage as “Registration Data“.
          </li>
          <li>
            An account could be created by you through the Website ID (Your
            Phone Number) and password (OTP) or other log - in ID and password
            which can include a facebook, gmail or any other valid email ID. The
            User while creating an account hereby represents and warrants that
            all the information provided by the User is current, accurate and
            complete and that the User will maintain the accuracy and keep the
            information updated from time to time. Use of another User’s account
            information for availing the services is expressly prohibited. If in
            case it is found that the information so supplied on the Website is
            inaccurate, incomplete, untrue and not current, the Website has the
            right to suspend or terminate the User’s account and restrict/refuse
            the use of the Website by such User in future.
          </li>
          <li>
            The right to use this Website is personal to the User and is not
            transferable to any other person or entity. The User would be
            responsible for protecting the confidentiality of User’s passwords
            and other information required for the purposes of registration. The
            User would be fully responsible for all the activities that occur
            under the User’s account with the Website. The Website cannot and
            will not be liable for any loss or damage arising from the User’s
            failure to maintain secrecy and confidentiality. The User shall
            notify the Website immediately if they become aware of any
            unauthorized use of their Account(s) or breach of any security. The
            User must log out from its account at the end of the session.
          </li>
          <li>
            The User while availing any service shall be informed whether the
            service so rendered is personal to the Website or is available from
            a Third party. The Website shall have no control or monitoring on
            the information disseminated to any third party via the Website.
          </li>
          <li>
            The User agrees, understands and confirms that his/ her personal
            data including without limitation to details relating to debit card/
            credit card transmitted over the Internet may be susceptible to
            misuse, hacking, theft and/ or fraud and that the Website or the
            Payment Service Provider(s) have no control over such matters.
          </li>
          <li>
            The Website does not permit the use of the Services by any User
            under the following conditions: -
            <ol>
              <li>
                If the User is a resident of any jurisdiction that may prohibit
                the use of the Services rendered by the Website.
              </li>
              <li>
                If the User is a resident of any State/Country that prohibits by
                way of law, regulation, treaty or administrative act for
                entering into trade relations or/and
              </li>
              <li>Due to any religious practices.</li>
              <li>
                If the User has created multiple accounts using various mobile
                numbers. The User may not have more than one active account with
                the Website.
              </li>
              <ol></ol>
            </ol>
          </li>
        </ul>
        <h2>FEATURE “CALL WITH Consultant”</h2>
        <p>
          The Website is providing certain service which is available through
          the medium of telecommunication with the Consultant listed and
          enrolled with the Website. By agreeing to the present Terms of Usage,
          you are also giving your unconditional consent to the Website to
          arrange a call with you on your mobile number even though your number
          is on DND service provided by your mobile service provider.
        </p>
        <h2>WEBSITE CONTENT</h2>
        <ul>
          <li>
            The Website and any individual Websites which may be available
            through external hyperlinks with the Website are private property.
          </li>
          <li>
            All interaction on this Website inclusive of the guidance and advice
            received directly from the Licensed Provider must comply with these
            Terms of Usage.
          </li>
          <li>
            The User shall not post or transmit through this Website any
            material which violates or infringes in any way upon the rights of
            others, or any material which is unlawful, abusive, defamatory,
            invasive of privacy, vulgar, obscene, profane or otherwise
            objectionable, which encourages conduct that would constitute a
            criminal offence, give rise to civil liability or otherwise violate
            any law.
          </li>
          <li>
            The Website shall have a right to suspend or terminate access by
            such User or terminate the User’s registration and such User shall
            not gain access to the Website.
          </li>
          <li></li>
          <li>
            The Website reserves the right to terminate the access or to change
            or discontinue any aspect or feature of the Website including, but
            not limited to, content, graphics, deals, offers, settings, etc.
          </li>
          <li>
            Any information other the guidance and advice, received directly
            from the Third-Party Service Provider, the educational, graphics,
            research sources and other incidental information on the Site, the
            content, should not be considered as medical advice.
          </li>
          <li>
            The Website does not take guarantee regarding the medical advice, if
            provided, by the third-party service provider inclusive of
            registered consultants with the site. The User should always talk to
            an appropriately qualified health care professional for diagnosis
            and treatment including information regarding which medications or
            treatment may be appropriate for the User. None of the Content
            represents or warrants that any particular medication or treatment
            is safe, appropriate, or effective for you.
          </li>
          <p>
            The Website does not take guarantee of any untoward incident that
            may happen with the User after seeking the Service. The Website or
            the Service Provider providing the advice is not liable and does not
            guarantee any results as expected by the User and accessing the
            Website in such scenario is purely at the risk of the User.
          </p>
          <li>
            By using the Site, Application or Services, User hereby agrees that
            any legal remedy or liability that you seek to obtain for actions or
            omissions of other Members inclusive of the service provider
            registered with the Website or other third parties linked with the
            Website, shall be limited to claim against such particular party who
            may have caused any harm. You agree not to attempt to impose
            liability on or seek any legal remedy from the Website with respect
            to such actions or omissions.
          </li>
        </ul>
        <h2>USER ACCOUNT ACCESS</h2>
        <p>
          The Website shall have access to the account and the information
          created by the User for ensuring and maintaining the high-quality
          services provided by the Website and for addressing the need of the
          customer in the most effective manner. User hereby consents for the
          unconditional access of the account by the Website, its employees,
          agents and other appointed person in such regard. For the purpose of
          addressing the complaints (if any received) and any suspected abuse
          reported, the Website shall investigate on case-to-case basis from the
          records available. The User is directed to read the terms provided in
          the Privacy Policy as regards such records.
        </p>
        <p></p>
        <h2>PRIVACY POLICY</h2>
        <p>
          The User hereby consents, expresses and agrees that the User has read
          and fully understand the Privacy Policy of the Website. The User
          further consents that the terms and contents of such Privacy policy is
          acceptable to the User inclusive of any update/alteration/change made
          and duly displayed on the Website.
        </p>
        <h2>BREACH AND TERMINATION</h2>
        <ul>
          <li>
            The Website may, in whole or in part, without informing the User,
            modify, discontinue, change or alter the services ordered or the
            Account of the User registered with the Website. The Website may or
            may not issue notice or provide any reason for such action taken by
            the Website.
          </li>
          <li>
            Violation of any conditions mentioned in this Terms of Usage shall
            lead to immediate cancellation of the Registration of the User, if
            registered with the Website. The Website reserves right to terminate
            and initiate action immediately, if:
            <ol>
              <li>
                The Website is not able to verify and authenticate the
                Registration data or any other relevant information provided by
                the User.
              </li>
              <li>
                The Website believes that the actions of the User may cause
                legal liability for the Website, other Users or any service
                provider linked with the Website.
              </li>
              <li>
                The Website believes that the User has provided the Website with
                false and misleading Registration Data or there is interference
                with the other Users or the administration of the services, or
                have violated the privacy policy as listed by the Website.
              </li>
            </ol>
          </li>
          <li>
            For the Service Provider inclusive of the Consultant, You understand
            and agree that your relationship with the Website is limited to
            being a member and You act exclusively on your own behalf and for
            your own benefit. The Website may terminate and de-activate the
            Profile of such service provider for any violation of the present
            terms of usage and the Service Terms and Conditions agreed upon
            between the parties while registration of the data by such Service
            Provider.
          </li>
        </ul>
        <h2>DELIVERY, CANCELLATION AND REFUND</h2>
        <ul>
          <li>
            No refund shall be processed on the order of any reports under any
            circumstances if the order has reached the “processing” (Assigned to
            an Chownk) stage. The risk and liability of placing order in a haste
            and careless manner totally lies with the User and the Website is
            not responsible for any refund once the processing stage has
            started.
          </li>
          <li>
            No refund shall be processed once the Order has been placed and
            executed. However, if the User intends to cancel a successfully
            placed order before execution, the User is required to contact the
            customer care team within 1 (one) hour of making the payment,
            whereafter it is totally at the discretion of the Website whether to
            issue refund.
          </li>
          <li></li>
          <li>
            Any technical delay or glitch reported in the Website during the
            processing of the request which includes generating reports by the
            service provider i.e. Consultant shall not be eligible for claiming
            refund. The User agrees that the timelines are approximate and all
            essentials steps would be taken to adhere to the timelines as
            displayed.
          </li>
          <li>
            No refund shall be processed for the reason that in-correct
            information or data has been provided by You. The User agrees to be
            careful while providing any information to the Website and must
            re-check the information filled before clicking on “Submit”. The
            User can request for change in the in-correct information or data
            entered provided, the request for such change has been made with the
            customer care within 1 (one hour) of execution of the service
            rendered by the service provider.
          </li>
          <li>
            No refund shall be processed for return of any damaged product. The
            User undertakes and agrees that by ordering any product as displayed
            on the Website, the Registered User shall be fully responsible for
            any damaged caused to the product, post its delivery. For orders
            made via “Cash on Delivery” method of payment, the User shall be
            charged for the cost of the product as displayed by the Website and
            the shipping/custom/courier charges as applicable, if the product is
            returned.
          </li>
          <li>
            Refund on pro-rata basis may be considered for any delay in the
            activation of the subscription services and any damage that may be
            caused to the product while in transit shall be dealt by the Website
            and its agencies.
          </li>
          <li>
            You agree that the display picture for the products listed for
            purchase by the User are for reference purpose only and the Website
            will try to deliver the product ordered in an as-is condition as
            displayed on the Website.The User is advised to exercise discretion
            in such case and no refund shall be issued on such grounds.
          </li>
          <li>
            The services offered and the products sold are strictly not meant to
            replace any philosophical, emotional or medical treatment. The
            Website holds no responsibility or liability about the reality or
            reliability of the Consultant effects on the human physiology, by
            the gems represented and sold on the Website. The placing of order
            for buying such products or taking the services is solely on the
            discretion and will of the User and the Website does not have any
            responsibility upon the products sold. The User is advised to
            exercise discretion in such case and no refund shall be issued on
            such grounds.
          </li>
          <li>
            No refund shall be processed for providing a wrong contact number
            for the purpose of availing the “Call with Consultant” feature. The
            User once opted for this feature is advised to keep the Contact
            Number in full coverage area and must answer the call when received.
            No refund shall be processed for any call which gets connected.
          </li>
          <li>
            The refunds, if any, shall be processed after deduction of the
            transaction charges levied by the Bank and/or the Payment Gateway,
            to &amp; fro cost of the shipping and/or courier charges (With
            regard to purchase of a product listed on the Website), customs duty
            (if levied) and/or any other charges that may have been incurred by
            the Website during processing and/or delivering the service, as
            applicable.
          </li>
          <li>
            In case the Website or Payment gateway’s webpage, that is linked to
            the Website, is experiencing any server related issues like ‘slow
            down’ or ‘failure’ or ‘session timeout’, the User shall, before
            initiating the second payment, check whether his/her Bank Account
            has been debited or not and accordingly resort to one of the
            following options:
            <ol>
              <li>
                In case the Bank Account appears to be debited, ensure that you
                do not make the payment twice and immediately thereafter contact
                the Website via customer care to confirm payment.
              </li>
              <li>
                In case the Bank Account is not debited, the User may initiate a
                fresh transaction to make payment.
              </li>
            </ol>
          </li>
          <li>
            However, refund for multiple payment, if any, even after the above
            precaution against the same order shall be refunded in full without
            deduction of the transaction charges as mentioned above. The Website
            shall only retain the cost of one single order as intended to be
            placed by the User.
          </li>
          <li>
            If there are orders that the Website is unable to accept and must
            cancel, the Website at its sole discretion, reserves the right to
            refuse or cancel any order for any reason whatsoever. Some
            situations may result in the order being cancelled and include,
            without limitation, non-availability of the service, inaccuracy,
            error in pricing information or other problems as identified. If the
            User’s order is cancelled after charges being paid against the said
            service, the said amount paid for booking shall be refunded.
          </li>
        </ul>
        <h2>USER OBLIGATION</h2>
        <p>
          The User (inclusive of the consultant and the Member Customer) under
          an obligation not to violate the privacy policy, terms and conditions
          and any other terms as defined on the Website. The User represents
          that he is an individual and not a corporation or other legal business
          entity. The rights to use the Website’s services is personal to the
          User.The User shall while using the Website and engaged in any form of
          communication on any of the forums inclusive of the products listed on
          the Website shall not violate the terms and conditions which are
          inclusive of:-
        </p>
        <ul>
          <li>
            The User shall not Post, publish or transmit any messages that is
            false, misleading, defamatory, harmful, threatening, abusive,
            harassing, defamatory, invades another's privacy, offensive,
            promotes racism, hatred or harm against any individual or group or
            religion or caste, infringes another's rights including any
            intellectual property rights or copyright or trademark, violates or
            encourages any conduct that would violate any applicable law or
            regulation or would give rise to civil liability.
          </li>
          <li>
            The User shall not upload or post or otherwise make available any
            content that User do not have a right to make available, under any
            law or under contractual or fiduciary relationships.
          </li>
          <li>
            The User shall not upload or post or otherwise make available any
            content that infringes any patent, trademark, trade secret,
            copyright or other proprietary rights of any party. The User may,
            however, post excerpts of copyrighted material so long as they
            adhere to Fair Use guidelines.
          </li>
          <li>
            The User shall not collect screen names and email addresses of
            members who are registered on the Website for purposes of
            advertisement, solicitation or spam.
          </li>
          <li>
            The User shall not send unsolicited email, junk mail, spam, or chain
            letters, or promotions or advertisements for products or services.
          </li>
          <li>
            The User shall not upload or distribute files that contain viruses,
            corrupted files, or any other similar software or programs that may
            damage the operation of the Website or another’s computer.
          </li>
          <li>
            The User shall not engage in any activity that interferes with or
            disrupts access to the Website
          </li>
          <li>
            The User shall not attempt to gain unauthorized access to any
            portion or feature of the Website, any other systems or networks
            connected to the Website, to any of the services offered on or
            through the Website, by hacking, password mining or any other
            illegitimate means.
          </li>
          <li>
            The User shall not violate any applicable laws or regulations for
            the time being in force within or outside India. The use and
            continuous use of the Website is subject to but not limited to using
            the services for personal use.
          </li>
          <li>
            The User shall not resell or make any commercial use of the Services
            without the express written consent from the Website.
          </li>
          <li>
            The User shall not violate these Terms of Usage including but not
            limited to any applicable Additional terms of the Website contained
            herein or elsewhere.
          </li>
          <li>
            The User shall not Reverse engineer, modify, copy, distribute,
            transmit, display, perform, reproduce, publish, license, create
            derivative works from, transfer, or sell any information or software
            obtained from the Website.
          </li>
          <li>
            The User by becoming a Registered member of the Website agrees to
            the following situations, which list is not exhaustive and may
            include services incidental to the below mentioned: -
            <ol>
              <li>
                The User agrees to receive certain specific emails and SMS
                alongwith calls from the Website.
              </li>
              <li>
                The User agrees not to transmit via the Website any unlawful,
                harassing, libelous, abusive, threatening, harmful, vulgar,
                obscene or otherwise objectionable material of any kind or
                nature.
              </li>
              <li>
                The User not to transmit any material that encourages conduct
                that could constitute a criminal offense, give rise to civil
                liability, or otherwise violate any applicable local, state,
                national or international law or regulation. Attempts to gain
                unauthorized access to other computer systems are prohibited.
              </li>
              <li>
                The User shall not interfere with any other members' use or
                enjoyment of the Website or Services.
              </li>
              <li>
                The User is under an obligation to report any misuse or abuse of
                the Site. If you notice any abuse or misuse of the Site or
                anything which is in violation of this Agreement, you shall
                forthwith report such violation to Website by writing to
                Customer Care. On receipt of such complaint, Website may
                investigate such complaint and if necessary, may terminate the
                membership of the Member responsible for such violation abuse or
                misuse without any refund of the subscription fee.
              </li>
              <li>
                Any false complaint made by a Member shall make such Member
                liable for termination of his / her membership without any
                refund of the subscription fee.
              </li>
              <li>
                The Website reserves the right to withdraw its services to any
                customer who is found to be unreasonable or abusive during their
                conversation with the Service Provider inclusive of Consultant
                regardless of any reason.
              </li>
            </ol>
          </li>
        </ul>
        <p>
          While the Website shall take all steps to resolve any situation that
          is in violation of the above obligations arises, however if the
          situation is not controllable, the Website reserves its right to send
          a written warning henceforth. Such violations, if repeated by the
          User, shall lead to a total ban for transacting on the platform by
          such User. If any balance is present in the wallet of the User, the
          same shall be refunded subject to the other charges that may be
          applicable for such violations.
        </p>
        <h2>BANK ACCOUNT INFORMATION</h2>
        <p>
          The User is under an obligation to provide his banking information as
          and when required. For that purpose, the obligation of the User are:-
        </p>
        <ul>
          <li>
            The User agrees that the debit/credit card details provided by him/
            her for use of the aforesaid Service(s) must be correct and accurate
            and that the User shall not use a debit/ credit card, that is not
            lawfully owned by him/ her or the use of which is not authorized by
            the lawful owner thereof. The User further agrees and undertakes to
            provide correct and valid debit/credit card details.
          </li>
          <li>
            The User may pay the fees required, to the Website by using a
            debit/credit card or through online banking account. The User
            warrants, agrees and confirms that when he/ she initiates a payment
            transaction and/or issues an online payment instruction and provides
            his/ her card / bank details:
          </li>
          <ol>
            <li>
              The User is fully and lawfully entitled to use such credit / debit
              card, bank account for such transactions;
            </li>
            <li>
              The User is responsible to ensure that the card/ bank account
              details provided by him/ her are accurate;
            </li>
          </ol>

          <li>
            The User is responsible to ensure sufficient credit is available on
            the nominated card/ bank account at the time of making the payment
            to permit the payment of the dues payable or the bill(s) selected by
            the User inclusive of the applicable Fee.
          </li>
        </ul>

        <p>
          The User further agrees that if any part of these Terms of Usage are
          determined to be invalid or unenforceable pursuant to applicable law
          including, but not limited to, the warranty disclaimers and liability
          limitations set forth herein, then the invalid or unenforceable
          provision will be deemed superseded by a valid, enforceable provision
          that most closely matches the intent of the original provision and the
          remainder of these Terms of Usage shall continue in effect.
        </p>

        <h2>DISCLAIMER / LIMITATION OF LIABILITY / WARRANTY</h2>
        <p>
          The User expressly understands and agree that, to the maximum extent
          permitted by applicable law, the Website does not provide warranties
          for the service. Consultant counselling provided through the Website
          is based on cumulative or individual knowledge, experience and
          interpretations of consultants and as such, it may vary from one
          consultant to another.
        </p>
        <ul>
          <li>
            The Website is offering services through a diverse panel of
            Consultants duly verified by the Website and such Service Provider
            (Consultant) may from time to time make recommendations of using
            mantras, jantras, gemstones or other consultant remedies to be used
            by User. Such recommendations are being made in good faith by the
            consultants and the Website and its subsidiaries, affiliates,
            officers, employees, agents, partners, and licensors make no
            warranty that:
            <ol>
              <li>the service will meet your requirements</li>
              <li>
                the service will be uninterrupted, timely, secure or error -
                free
              </li>
              <li>
                the results that may be obtained from the use of the service
                will be accurate or reliable
              </li>
              <li>
                the quality of any products, services, information or other
                material purchased or obtained by you through the service will
                meet your expectations and{" "}
              </li>
              <li>
                any errors in the software will be corrected. You are required
                to make full disclosure about the emotional, mental and physical
                state of the person seeking advice from the panel of consultants
                of Website so that the consultants make an informed judgment
                about giving advice.
              </li>
            </ol>
          </li>

          <li>
            The Website, services and other materials are provided by the
            Website on an "as is" basis without warranty of any kind, express,
            implied, statutory or otherwise, including the implied warranties of
            title, non-infringement, merchantability or fitness for a particular
            purpose. without limiting the foregoing, the Website makes no
            warranty that (i) the Website or the services will meet your
            requirements or your use of the Website or the services will be
            uninterrupted, timely, secure or error-free; (ii) the results that
            may be obtained from the use of the Website, services or materials
            will be effective, accurate or reliable; (iii) the quality of the
            Website, services or other materials will meet your expectations; or
            that (iv) any errors or defects in the Website, services or other
            materials will be corrected. no advice or information, whether oral
            or written, obtained by the User from the Website or through or from
            use of the services shall create any warranty not expressly stated
            in the terms of use.
          </li>

          <li>
            To the maximum extent permitted by applicable law, the Website will
            have no liability related to User content arising under intellectual
            property rights, libel, privacy, publicity, obscenity or other laws.
            The Website also disclaims all liability with respect to the misuse,
            loss, modification or unavailability of any User content.
          </li>

          <li>
            The Website will not be liable for any loss that the User may incur
            as a consequence of unauthorized use of their account or account
            information in connection with the Website or any services or
            materials, either with or without the User’s knowledge. The Website
            has endeavored to ensure that all the information on the Website is
            correct, but the Website neither warrants nor makes any
            representations regarding the quality, accuracy or completeness of
            any data, information, product or service. The Website shall not be
            responsible for the delay or inability to use the Website or related
            functionalities, the provision of or failure to provide
            functionalities, or for any information, software, products,
            functionalities and related graphics obtained through the Website,
            or otherwise arising out of the use of the Website, whether based on
            contract, tort, negligence, strict liability or otherwise. further,
            the Website shall not be held responsible for non-availability of
            the Website during periodic maintenance operations or any unplanned
            suspension of access to the Website that may occur due to technical
            reasons or for any reason beyond the Website's control.
          </li>

          <li>
            The User understands and agrees that any material or data downloaded
            or otherwise obtained through the Website is done entirely at their
            own discretion and risk and they will be solely responsible for any
            damage to their computer systems or loss of data that results from
            the download of such material or data. The Website is not
            responsible for any typographical error leading to an invalid
            coupon. The Website accepts no liability for any errors or
            omissions, with respect to any information provided to you whether
            on behalf of itself or third parties.
          </li>

          <li>
            The Services provided by the Website are for entertainment purposes
            only and the Website on behalf of itself and its suppliers,
            disclaims all warranties of any kind, express or implied, including
            without limitation any warranty of merchantability, fitness for a
            particular purpose, title, non-infringement and it makes no warranty
            or representation regarding the results that may be obtained from
            the use of content or services, the accuracy or reliability of any
            content obtained through the Services, any goods or services
            purchased or obtained through the Website, and makes no warranty
            that the services will meet your requirements, be uninterrupted,
            timely, secure or error-free. No advice or information, whether oral
            or written, obtained by you from the Website shall create any
            warranty.
          </li>

          <li>
            The advisors/consultants/consultants are also members of the site
            and not employees of the Website or the company. However, the
            Website verifies the degrees, qualifications, credentials, and
            background of the advisors/consultants/consultants but does not
            refer, endorse, recommend, verify, evaluate or guarantee any advice,
            information or other services provided by the
            advisors/consultants/consultants or by the company, nor does it
            warrant the validity, accuracy, completeness, safety, legality,
            quality, or applicability of the content, anything said or written
            by, or any advice provided by the advisors/consultants/consultants.
          </li>

          <li>
            The website is not a suicide helpline platform. If you are
            considering or contemplating suicide or feel that you are a danger
            to yourself or to others, you may discontinue use of the services
            immediately at your discretion and please notify appropriate police
            or emergency medical personnel. If you are thinking about suicide,
            immediately call a suicide prevention helpline such as AASRA
            (91-22-27546669).
          </li>

          <li>
            The Website shall not be liable for any inaccuracy, error or delay
            in, or omission of (a) any data, information or message, or (b) the
            transmission or delivery of any such data, information or message;
            or (c) any loss or damage arising from or occasioned by any such
            inaccuracy, error, delay or omission, non-performance or
            interruption in any such data, information or message. Under no
            circumstances shall the Website and/or the payment service
            providers, its employees, directors, and its third party agents
            involved in processing, delivering or managing the services, be
            liable for any direct, indirect, incidental, special or
            consequential damages, or any damages whatsoever, including punitive
            or exemplary arising out of or in any way connected with the
            provision of or any inadequacy or deficiency in the provision of the
            services or resulting from unauthorized access or alteration of
            transmissions of data or arising from suspension or termination of
            the services.
          </li>

          <li>
            Notwithstanding anything to the contrary contained herein, Chownk
            liability to you for any cause whatsoever, and regardless of the
            form of the action, will at all times be limited to the amount paid,
            if any, by you to the Website, for the service during the term of
            membership.
          </li>
        </ul>
        <h2>INDEMNIFICATION</h2>
        <p>
          The User shall indemnify, defend and hold harmless the Website and its
          parent, subsidiaries, affiliates, officers, directors, employees,
          suppliers, consultants and agents from any and all third party claims,
          liability, damages and/or costs (including, but not limited to,
          attorney’s fees) arising from Your use of the Services, Your violation
          of the Privacy Policy or these Terms of Service, or Your violation of
          any third party's rights, including without limitation, infringement
          by You or any other user of Your account of any intellectual property
          or other right of any person or entity. These Terms of Service will
          inure to the benefit of Website’s successors, assigns, and licensees.
        </p>

        <h2>PROPRIETARY RIGHTS TO CONTENT</h2>
        <p>
          The User acknowledges that the Content, including but not limited to
          text, software, music, sound, photographs, video, graphics or other
          material contained in sponsor advertisements or distributed via email,
          commercially produced information presented to Member by the Website,
          its suppliers, and/or advertisers, is protected by copyrights,
          trademarks, service marks, patents and/or other proprietary rights and
          laws. The User is not permitted to copy, use, reproduce, distribute,
          perform, display, or create derivative works from the Content unless
          expressly authorized by the Website, its suppliers, or advertisers.
          Moreover, the content such as images, text, designs, etc on all of the
          portals of the Website are taken from various online portals such as
          Google Images. Digittrix Infotech Private Limited is not liable for
          any copyrights of that content or data.
        </p>

        <h2>NOTICES</h2>
        <p>
          Except as otherwise stated in these Terms of Service, all notices to a
          party shall be in writing and shall be made either via email or snail
          mail. Notice shall be deemed given 24 hours after an email is sent, or
          3 days after deposit in the snail mail, to Member at the address
          provided by Member in the Registration Data and to the Website at the
          address set forth below:
        </p>
        <p>
          “Spring House, C28C, Lower Ground, Sector - 8, Noida, Uttar Pradesh -
          201301, India”
        </p>

        <h2>GOVERNING LAW AND JURISDICTION</h2>
        <p>
          Any dispute, claim or controversy arising out of or relating to this
          Terms of Usage including the determination of the scope or
          applicability of this Terms of Usage to arbitrate, or your use of the
          Application or information to which it gives access, shall be
          determined by arbitration in India, before a sole arbitrator mutually
          appointed by Members and Website. Arbitration shall be conducted in
          accordance with the Arbitration and Conciliation Act, 1996. The seat
          of such arbitration shall be New Delhi. All proceedings of such
          arbitration, including, without limitation, any awards, shall be in
          the English language. The award shall be final and binding on the
          parties to the dispute.
        </p>
        <p>
          Notwithstanding the foregoing, either party has the right to seek any
          interim or preliminary relief from a court of competent jurisdiction
          in New Delhi in order to protect the rights of such party pending the
          completion of any arbitration hereunder, and both parties agree to
          submit to the exclusive jurisdiction of the courts of India and venue
          in New Delhi for any such proceeding. If either party files an action
          contrary to this provision, the other party may recover attorneys'
          fees and costs up to One Lakh Rupees INR.
        </p>
        <p>
          These Terms of Usage shall be governed by and construed in accordance
          with the laws of India without giving effect to any choice of law and
          principles that would require the application of the laws of a
          different state. If for any reason a court of competent jurisdiction
          finds any provision or portion of these Terms of Usage or Privacy
          Policy to be unenforceable or invalid, such provision shall be changed
          and interpreted so as to best accomplish the objectives of such
          unenforceable or invalid provision within the limits of applicable
          law, and the remainder of the Terms of Usage or Privacy Policy, as
          applicable, will continue in full force and effect. Headings are for
          reference purposes only and in no way define, limit, construe, or
          describe the scope or extent of such section. Any waiver of any
          provision of the Terms of Usage shall be effective only if in writing
          and signed by Chownk. These Terms of Usage constitute the entire
          agreement between the parties with respect to the subject matter
          hereof and supersedes and replaces all prior or contemporaneous
          understandings or agreements, written or oral, regarding such subject
          matter.
        </p>
        <p>
          These Terms of Usage and your use of the Services will be interpreted
          in accordance with the laws of India excluding its rules on conflicts
          of laws. The parties agree to submit any dispute arising under these
          Terms of Usage to the jurisdiction of a court located in New Delhi for
          any actions for which the parties retain the right to seek injunctive
          or other equitable relief in a court of competent jurisdiction to
          prevent the actual or threatened infringement, misappropriation or
          violation of a party's copyrights, trademarks, trade secrets, patents,
          or other intellectual property rights.
        </p>
      </div>
    </section>
  );
};

export default TermsContent;
