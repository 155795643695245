import React, { useEffect, useState } from "react";
import StudentHeader from "../../../../Layouts/Student/Header";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Banner from "./Banner";
import MainContent from "./MainContent";
import TableOfContent from "./TableOfContent";
import SimilarBlogs from "./SimilarBlogs";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimilarBlogs,
  getSingleBlog,
} from "../../../../../Reducer/blogSlice";
import StudentFooter from "../../../../Layouts/Student/Footer";
import Meta from "../../Meta";
import moment from "moment";
import {
  getSimilarBlogsApi,
  saveUserNavigate,
} from "../../../../../services/api";
import { blogsMediaUrl } from "../../../../../services/network";
import { toast } from "react-toastify";

const SingleBlog = () => {
  const [keywords, setKeyWords] = useState("");
  const [similarBlogs, setSimilarBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { singleBlog, success, submitting, error } = useSelector(
    (state) => state.blogs
  );
  const selector = useSelector((state) => state.auth);
  const guestId = localStorage.getItem("guestId");

  const pageSize = 12;

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleBlog(slug));
  }, [location]);

  useEffect(() => {
    if (error) {
      navigate("/blogs");
    } else {
      getKeyWords();
    }
  }, [singleBlog, error]);

  useEffect(() => {
    if (singleBlog.id) {
      getSimilarBlogsFun();
    }
  }, [singleBlog, page]);

  const getSimilarBlogsFun = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const body = {
        cat_id: singleBlog.cat_id,
        blog_id: singleBlog.id,
        pageSize: pageSize,
        page: page,
      };
      const response = await getSimilarBlogsApi(body);
      const blogs = response.data.data;
      if (blogs.length < pageSize) {
        setHasMore(false);
      }
      setSimilarBlogs((prev) => [...prev, ...blogs]);
    } catch (error) {
      toast.error("Error Fetching Similar Blogs");
    } finally {
      setLoading(false);
    }
  };

  const getKeyWords = async () => {
    try {
      const tags = JSON.parse(singleBlog?.meta_tags);
      const keywords = tags.map((el) => el.text).join(",");
      setKeyWords(keywords);
    } catch (error) {}
  };

  const handleUserNavigate = (type, hit, from, description) => {
    try {
      const body = {
        id: selector.auth ? selector.auth.id : guestId,
        actions: [
          {
            type: type,
            hit: hit,
            from: location.pathname,
            description: description,
            unix_time: moment(new Date()).unix(),
          },
        ],
      };
      saveUserNavigate(body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {success && !submitting && error == null && (
        <Meta
          title={singleBlog?.meta_title}
          description={singleBlog?.meta_description}
          keywords={keywords}
          ogImage={`${blogsMediaUrl}${singleBlog?.image}`}
          twitterImage={`${blogsMediaUrl}${singleBlog?.image}`}
          includeFaq={true}
          blogsfaqs={singleBlog?.faqs}
        />
      )}

      <StudentHeader
        logo={"../../../../assets/images/chownk-logo.png"}
        bellIcon={"../../../assets/images/hedic1.png"}
        messageIcon={"../../../assets/images/hedic2.png"}
        formDisplay={true}
      />
      <Banner
        backgroundImage={singleBlog?.image}
        title={singleBlog?.title}
        category={singleBlog?.cat_name}
        subCategory={singleBlog?.subcat_name}
        authorImage={singleBlog?.author_image}
        authorName={singleBlog?.author_name}
        publishDate={singleBlog?.unix_time}
        createdDate={singleBlog?.created_at}
        updateDate={singleBlog?.updated_at}
      />
      <section className="section_blogs section_blogs_in">
        <div className="container">
          <div className="row mb-5">
            <MainContent
              description={singleBlog?.description}
              faq={singleBlog?.faqs}
              tags={singleBlog?.tags}
            />

            <TableOfContent content={singleBlog?.description} />
          </div>
          <SimilarBlogs
            data={similarBlogs}
            handleUserNavigate={handleUserNavigate}
            page={page}
            setPage={setPage}
          />
        </div>
      </section>
      <StudentFooter />
    </>
  );
};

export default SingleBlog;
