import React from "react";

const ListingParaGraph = ({ para }) => {
  return (
    <div className="what">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: para }}></div>
      </div>
    </div>
  );
};

export default ListingParaGraph;
