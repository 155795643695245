import React from "react";
import { Link, useNavigate } from "react-router-dom";

import StudentHeader from "../../../Layouts/Student/Header";
import { useSelector } from "react-redux";
import StudentFooter from "../../../Layouts/Student/Footer";

const TryChanngingLocation = () => {
  const [locationShow, setLocationShow] = React.useState(false);
  const selector = useSelector((state) => state.auth);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  React.useEffect(() => {
    if (selector.userDetails.locationStatus) {
      navigate(`/${selector.userDetails.city}`);
    }
  }, [selector.userDetails.address]);
  return (
    <>
      <StudentHeader
        logo={"assets/images/chownk-logo.png"}
        bellIcon={"hedic1.png"}
        messageIcon={"hedic2.png"}
        setLocationShow={locationShow}
        formDisplay={true}
      />
      <section className="try_changing_location">
        <div className="container-fluid">
          <div className="try_changing">
            <h6>We are not available in {selector.userDetails?.address}</h6>
            <img
              src="assets/images/try_changing_location.png"
              alt="try_changing_location"
            />
            <h5>Location not servicable</h5>
            <p>
              Sorry! We are not available at this location please change your
              location.
            </p>
            <Link
              className="btn"
              onClick={() => {
                window.scrollBy(0, -500);
                setLocationShow(!locationShow);
              }}
            >
              Try Changing Location
            </Link>
          </div>
        </div>
      </section>
      <StudentFooter />
    </>
  );
};
export default TryChanngingLocation;
