import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import RouteWebPage from "./routes";
import ScrollToTop from "./scrollToTop";
import { setUser } from "./Reducer/authSlice";
import { RemoveTrailingSlash } from "./removeTrailingSlash";
import { SocketContext } from "./Context";
import MobileFooter from "./Components/Layouts/MobileFooter";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { useLocation } from "react-router-dom";
import LowercaseUrl from "./lowercaseurl";

function App() {
  const [studentAuth, setStudentAuth] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const normalSelector = useSelector((state) => state.auth);
  const normalToken = localStorage.getItem("token");
  const { setChatLimitFun, screenWidth, socket, setSocket } =
    useContext(SocketContext);
  const dt = localStorage.getItem("dt");

  React.useEffect(() => {
    setChatLimitFun(screenWidth);
    if (!normalSelector.token) {
      dispatch(setUser(JSON.parse(localStorage.getItem("user"))));
      setStudentAuth(normalToken);
    } else {
      dispatch(setUser(JSON.parse(localStorage.getItem("user"))));
      setStudentAuth(normalSelector.token);
    }
    try {
      serviceWorkerRegistration.register();
    } catch (e) {}
  }, [normalSelector.token]);

  return (
    <div className="App">
      <ScrollToTop />
      <LowercaseUrl />
      <RemoveTrailingSlash />
      <RouteWebPage
        normalToken={normalSelector.token}
        role={normalSelector.auth && normalSelector.auth.role}
      />
      {screenWidth <= 767 && dt && location.pathname !== "/messages" && normalToken && <MobileFooter />}
      <ToastContainer />
    </div>
  );
}
export default App;

// pm2 start "npm run start" --name "[YourAppName]"
