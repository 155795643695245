const ProfileSecion = () => {
  return (
    <>
      <div className="col-md-4 pe-4">
        <div
          className="img"
          style={{ backgroundImage: `url(assets/images/results1.png)` }}
        ></div>
      </div>
      <div className="col-md-8">
        <div className="row">
          <div className="col-lg-6">
            <div className="usr_dt">
              <h2>
                Study Buddies <i className="bi bi-patch-check-fill"></i>{" "}
                <span>
                  <i className="fa-solid fa-user-plus"></i> Follow
                </span>
              </h2>
              <p>
                <i className="bi bi-building"></i> #102, Park View Street,
                Australia
              </p>
              <p>
                <i className="bi bi-speedometer"></i> {"Total Time Covered:"}{" "}
                <b>1000min</b>
              </p>
              <h6 className="mb-2">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <span>(365)</span>
              </h6>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="d-flex float-end btns">
              <a href="#" className=" message">
                <i className="bi bi-chat-dots-fill"></i> Message
              </a>
              <a className="video_call text-center ms-2" href="#">
                Call{" "}
                <b>
                  $15<span>/min</span>
                </b>
              </a>
            </div>
          </div>
        </div>
        <p>
          Lorem Ipsum is simply dummy text for printing and typesetting
          industry. Lorem Ipsum has been industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic the leap into
          electronic typesetting... <a href="#">Read More</a>
        </p>
      </div>
    </>
  );
};
export default ProfileSecion;
