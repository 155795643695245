import React from "react";
import { Link } from "react-router-dom";

const NewHelpBox = ({
  parentStyle,
  childStyle,
  image,
  heading,
  subHeading,
  openHelpBox,
}) => {
  return (
    <div className={parentStyle}>
      <div className={childStyle}>
        <div className="img">
          <img
            src={image ? image : "../../assets/images/helpiconK_7.png"}
            alt="helpiconK_1"
          />
        </div>
        <h3>{heading}</h3>
        <p>{subHeading}</p>
        <Link onClick={openHelpBox} className="seeall">
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default NewHelpBox;
