import React, { useContext, useState } from "react";
import { SocketContext } from "../../../Context";
import UserHeader from "../../Pages/Chat/Chat/UserHeader";
import Chatting from "../../Pages/Chat/Chat/Chatting";

const SingleChatBox = () => {
  const { currentUser } = useContext(SocketContext);
  const [page, setPage] = useState(1);

  return (
    <div className="chtmodall singlechtmodal">
      <div className="smsBox ready_msg ms-2 position-relative">
        <UserHeader
          currentUser={currentUser}
          ulStyle={"call_video"}
          shortName={true}
        />
        <Chatting currentUser={currentUser} page={page} setPage={setPage} />
      </div>
    </div>
  );
};

export default SingleChatBox;
