import StudentFooter from "../../../Layouts/Student/Footer";
import StudentHeader from "../../../Layouts/Student/Header";
import ListingNotifications from "./listingNotification";
import TopHeader from "./topHeader";

const NotificationPage = () => {
  return (
    <>
      <StudentHeader formDisplay={true} />
      <TopHeader />
      <ListingNotifications />
      <StudentFooter />
    </>
  );
};

export default NotificationPage;
