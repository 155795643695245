import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const SearchLoader = ({ count }) => {
  const [total, setTotal] = useState(count ? Math.ceil(count) : 1);
  return (
    <div className="row">
      {total &&
        Array.from({ length: total }, (el, i) => (
          <div className="col-sm-6 col-lg-4 col-xxl-3">
            <div className="set mb-3">
              <div className="row">
                <div className="col-sm-12">
                  <Skeleton width={"100%"} height={"186px"} />
                </div>
                <div className="col-sm-12 albt mt-2">
                  <div className="row">
                    <div className="col-6">
                      <Link>
                        <Skeleton
                          className="total_results set message"
                          width={"100%"}
                        />
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link>
                        <Skeleton
                          className="total_results set message"
                          width={"100%"}
                        />
                      </Link>
                    </div>
                    <div className="col-sm-12">
                      <div className="usr">
                        <span>
                          <Skeleton className="total_results set imgpro" />
                        </span>
                        <h6>
                          <Skeleton className="testing" />
                        </h6>
                      </div>
                      <div className="mt-1">
                        <Skeleton className="mt-3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SearchLoader;
