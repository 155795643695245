import React from "react";
import { blogsMediaUrl } from "../../../../../services/network";
import { Link, useNavigate } from "react-router-dom";

const TopBlogs = ({ topBlogs, handleUserNavigate }) => {
  const navigate = useNavigate();
  const unixtoDate = (unix_date) => {
    let date = new Date(unix_date * 1000);
    let formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };
  return (
    <>
      {topBlogs && topBlogs.length > 0 && (
        <div id="carouselExample" class="carousel slide">
          <div class="carousel-inner">
            {topBlogs.map((el, i) => (
              <div class={`carousel-item ${i === 0 ? "active" : ""}`}>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="immg">
                      <img
                        src={`${blogsMediaUrl}${el.image}`}
                        class="w-100 mb-3"
                        alt={`${el.title}-Chownk`}
                        onClick={() => {
                          handleUserNavigate(
                            "navigate",
                            `/blogs/${el.slug}`,
                            "top blogs"
                          );
                          navigate(`/blogs/${el.slug}`);
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <h2
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/blogs/${el.slug}`)}
                    >
                      {el.title}
                    </h2>
                    <p>
                      {el.description}
                      <Link to={`/blogs/${el.slug}`}>Read More</Link>
                    </p>
                    <p class="Dec">
                      <i class="bi bi-calendar"></i> {unixtoDate(el.unix_time)}{" "}
                      {/* <i class="bi bi-eye ms-4"></i>10k Viewers */}
                    </p>
                    <p className="h5">
                      <span
                        style={{
                          backgroundImage: `url(${blogsMediaUrl}${el.author_image})`,
                        }}
                      ></span>{" "}
                      {el.author_name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <i class="bi bi-arrow-left"></i>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      )}
    </>
  );
};

export default TopBlogs;
