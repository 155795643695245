const HeadingAndImage = ({
  parentStyle,
  childStyle,
  backgroundImage,
  heading,
  paragraph,
  imageSrc,
  imageStyle,
}) => {
  return (
    <div className={parentStyle}>
      <div className={childStyle} style={{ backgroundImage: backgroundImage }}>
        <h2>{heading}</h2>
        <p>{paragraph}</p>
        <img src={imageSrc} className={imageStyle} alt="..." />
      </div>
    </div>
  );
};
export default HeadingAndImage;
