import { useSelector } from "react-redux";
import { mediaUrl } from "../../../../services/network";
import { Link } from "react-router-dom";

const CallHistory = ({ callHistoryData }) => {
  const { auth } = useSelector((state) => state.auth);

  return (
    <div className="col-xxl-5 mb-3">
      <div className="wap wap3">
        {callHistoryData.length > 0 ? (
          <>
            {" "}
            <h4>
              <img src="assets/images/Call_History.png" alt="Call_History" />{" "}
              Call History <Link to={"/call-history"}>See all</Link>{" "}
            </h4>
            {callHistoryData.slice(0, 7).map((item, index) => {
              return (
                <div className="st" key={index}>
                  <div
                    className="img"
                    style={{
                      backgroundImage:
                        auth.uid == item.user_id
                          ? `url(${mediaUrl + item.attendant_image})`
                          : `url(${mediaUrl}${item.dailler_image})`,
                    }}
                  ></div>
                  <h3>
                    {auth.uid == item.user_id
                      ? item.attendant_name
                      : item.dailler_name}
                  </h3>
                  <p>
                    {auth.uid == item.user_id
                      ? "Outgoing Call"
                      : "Incoming Call"}
                  </p>
                  <h6 className="min">
                    {item.duration > 60
                      ? `${(item.duration / 60).toFixed(2)} Mins`
                      : `${item.duration} Sec`}
                  </h6>
                </div>
              );
            })}
          </>
        ) : (
          <div className="transactionsHistory text-center">
            <img
              src="../../../assets/images/Call_History1.png"
              alt="deadline1"
            />
            <p>
              Your <a href="#">Call History</a> will appear here
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallHistory;
