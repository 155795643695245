import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { backEndUrl, mediaUrl } from "../../../../services/network";
import { getOfferDetail, paymentZone } from "../../../../services/api";
import StudentHeader from "../../../Layouts/Student/Header";
import StudentFooter from "../../../Layouts/Student/Footer";
import { Roptions } from "../../../../data/data";
import { SocketContext } from "../../../../Context";

function ConfirmOffer() {
  const [offer, setoffer] = useState(null);
  const [thankYou, setThankyou] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const platformFee = 100;
  const percentage = 18;
  const { completeOfferPayment, socket } = useContext(SocketContext);
  // const gst = ((percentage / 100) * platformFee).toFixed(2);

  const getCases = async () => {
    let data = await getOfferDetail({ id: id });
    setoffer(data.data.data);
  };

  useEffect(() => {
    getCases();
    if (thankYou) {
      completeOfferPayment();
      setTimeout(() => {
        setThankyou(!thankYou);
        const dt = localStorage.getItem("dt");
        if (dt) {
          navigate("/messages");
        } else {
          window.opener = null;
          window.open("", "_self");
          window.close();
        }
      }, 3000);
    }
    if (socket) {
      socket.on(`addMoneyStatus`, (data) => {
        if (data.status) {
          setTimeout(() => {
            setThankyou(!thankYou);
            navigate("/messages");
          }, 200);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off(`addMoneyStatus`);
      }
    };
  }, [thankYou, socket]);

  const [Razorpay, isLoaded] = useRazorpay();
  const handlePayment = () => {
    const dt = localStorage.getItem("dt");
    if (dt) {
      const amount = offer.total + platformFee;
      Roptions.amount = Number(amount);
      let repo = {
        chatId: id,
        senderId: offer.sender_id,
        receiver: offer.receiver_id,
        options: Roptions,
        token: dt,
        type: "directPay",
        amount:
          offer.application_fees + offer.consultation_charges + platformFee,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(repo));
    } else {
      let options = {
        key: Roptions.key,
        amount: Math.round(
          offer && offer.total ? (offer.total + platformFee) * 100 : 100 * 100
        ),
        name: offer && offer.name ? offer.name : "",
        description: "Purchase Description",
        image: "/your_logo.png",
        handler: function (response) {
          if (response.razorpay_payment_id) {
            update(response.razorpay_payment_id);
          }
        },
        prefill: {
          name: offer && offer.name ? offer.name : "",
          email: "harshil@razorpay.com",
        },
        notes: {
          address: "Hello World",
        },
        theme: {
          color: "#1DBF73",
        },
      };
      let rzp = new Razorpay(options);
      rzp.open();
    }
  };

  const update = async (paymentId) => {
    var date = Math.round(new Date().getTime() / 1000);
    let data = {
      paymentId: paymentId,
      chatId: id,
      senderId: offer.sender_id,
      receiver: offer.receiver_id,
      date: date,
    };
    let repo = await paymentZone(data);
    if (repo.data.status) {
      setTimeout(() => {
        setThankyou(!thankYou);
      }, 200);
    }
  };

  console.log(offer, "offer");
  return (
    <>
      {!thankYou && (
        <StudentHeader
          logo={"../../assets/images/chownk-logo.png"}
          bellIcon={"hedic1.png"}
          messageIcon={"hedic2.png"}
        />
      )}
      {thankYou && (
        <div className="thank_you">
          <img src="../../assets/images/thank_you.gif" className="w-100" />
        </div>
      )}
      {!thankYou && (
        <section className="confirm_offer">
          {offer && offer.id ? (
            <div className="container">
              <div className="confirm_offerin">
                <h3>
                  <img src="../assets/images/per2.png" alt="per2" /> Confirm
                  Offer
                </h3>
                <div className="row">
                  <div className="col-lg-7 col-xl-8">
                    <div className="set">
                      <h4>Visa type</h4>
                      <p>{offer.visa_type}</p>
                      {/* <p>{offer.college_address}</p> */}
                      {/* <p>
                        <a href={offer.college_website_url}>
                          {offer.college_website_url}
                        </a>
                      </p> */}
                    </div>
                    <div className="set">
                      <h4>{"Offer Details"}</h4>
                      <p>
                        <b>{`${offer.title}`}</b>
                      </p>
                      <p>{offer.description}</p>
                      {/* <p>Duration: {offer.course_duration}</p> */}
                      {/* <p>Fees: {`${offer.college_fees}`}</p> */}
                      {/* <p className="note">
                        Note: Course Fees Should be Paid to College's bank
                        account.
                      </p> */}
                    </div>
                    {/* <h4>Additional Message:</h4>
                    <p>{offer.brief_services}</p> */}
                  </div>
                  <div className="col-lg-5 col-xl-4">
                    <div className="side">
                      <h4>Amount to be Paid</h4>
                      {/* <h6>
                        Application Fees:<span>₹{offer.application_fees}</span>
                      </h6>
                      <p>APPLICATION FEES EXCLUDING OTHER CHARGES.</p> */}
                      {/* {console.log("backEndUrl + '/'+offer.profile_image", backEndUrl + '/'+offer.profile_image)} */}
                      <div className="usr">
                        <span
                          className="img"
                          style={{
                            backgroundImage: `url(${mediaUrl}${offer.profile_image})`,
                          }}
                        ></span>
                        <h6>{offer.name}</h6>
                        <p>{offer.company_name}</p>
                      </div>
                      <h6>
                        Total Charges <span>₹{offer.total}</span>
                      </h6>
                      <h6>
                        Platform Fees: <span>{`₹${platformFee}`}</span>
                      </h6>
                      {/* <h6>
                        GST Tax: <span>₹{gst}</span>
                      </h6> */}
                      <h5>
                        Total Amount: <span>₹{offer.total + platformFee}</span>
                      </h5>
                    </div>
                    <a
                      href="#"
                      onClick={() => handlePayment()}
                      className="btn_Pay"
                    >
                      Confirm &amp; Pay
                    </a>
                    <div className="ssl text-center">
                      <h6>
                        <img src="../assets/images/lock.png" alt="per2" /> SSL
                        Secure Payment
                      </h6>
                      <p>You will be charged ₹{offer.total + platformFee}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      )}
      {!thankYou && <StudentFooter />}
    </>
  );
}

export default ConfirmOffer;
