import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Player } from "video-react";
import { mediaUrl } from "../../../services/network";
import ReviewRatingSection from "../../Pages/Agent/ViewAll/reviewSection";
import FaqSection from "../FaqSection";
import Button from "../Button";
import Example from "../ReactBoostrap";
import NewSuccessStories from "../../Layouts/Model/newSuccessStories";
import { useSelector } from "react-redux";

const ViewAllComponent = ({
  data,
  sectionType,
  setShow,
  setIndex,
  user_id,
  averageRatting,
  updateProfileFun,
  showEdit,
}) => {
  const playerRef = useRef(
    sectionType === "success-stories" && data?.map(() => React.createRef())
  );
  const [state, setState] = React.useState({
    count: data && data.length,
    limit: 4,
    show: false,
  });
  const [stories, setStories] = useState(data);
  const [addNewStory, setAddNewStory] = useState(false);
  const [index, setIndexx] = useState("");
  const selector = useSelector((state) => state.auth);

  useEffect(() => {
    if (sectionType === "success-stories") {
      setStories(data);
    }
  }, [data]);

  return (
    <>
      <div className="main_st">
        {sectionType === "success-stories" && (
          <>
            {stories && stories.length > 0 && (
              <>
                <div className="row">
                  {stories.map((item, i) => {
                    if (i < state.limit) {
                      if (item.media_type === "video") {
                        return (
                          <>
                            <div
                              style={{ cursor: "pointer" }}
                              className={`${
                                i < 2
                                  ? "col-sm-6 mb-2"
                                  : "col-6 col-sm-4 col-lg-3 mb-2"
                              }`}
                            >
                              {showEdit && (
                                <span
                                  className="editstories"
                                  onClick={() => {
                                    setIndexx(i);
                                    setAddNewStory(!addNewStory);
                                  }}
                                >
                                  <i class="bi bi-pencil-square"></i>
                                </span>
                              )}

                              <div
                                className={`imagevideo1 ${i > 1 && "sp"}`}
                                onClick={() => {
                                  setShow(true);
                                  if (playerRef.current) {
                                    playerRef.current.map((ref) =>
                                      ref.current?.actions?.pause()
                                    );
                                  }
                                  setIndex(i);
                                }}
                              >
                                <Button
                                  style={"upanmadal"}
                                  title={<i className="bi bi-play-fill"></i>}
                                />
                                {/* <button className="upanmadal"><i className="bi bi-play-fill"></i></button> */}
                                <Player
                                  playsInline
                                  src={mediaUrl + item.image}
                                  fluid={false}
                                  ref={
                                    playerRef.current && playerRef.current[i]
                                  }
                                />
                                <p>{item.title}</p>
                              </div>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div
                              style={{ cursor: "pointer" }}
                              className={`${
                                i < 2
                                  ? "col-sm-6 mb-2"
                                  : "col-6 col-sm-4 col-lg-3 mb-2"
                              }`}
                            >
                              {showEdit && (
                                <span
                                  className="editstories"
                                  onClick={() => {
                                    setIndexx(i);
                                    setAddNewStory(!addNewStory);
                                  }}
                                >
                                  <i class="bi bi-pencil-square"></i>
                                </span>
                              )}

                              <div
                                className={`imagevideo1 ${i > 1 && "sp"}`}
                                style={{
                                  backgroundImage: `url(${
                                    mediaUrl + item.image
                                  })`,
                                }}
                                onClick={() => {
                                  setShow(true);
                                  setIndex(i);
                                }}
                              >
                                <p>{item.title}</p>
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  })}
                </div>
              </>
            )}
          </>
        )}

        {sectionType === "reviews" && (
          <ReviewRatingSection
            view={true}
            limit={state.limit}
            user_id={user_id}
            averageRating={
              averageRatting !== undefined ? averageRatting.toFixed(1) : ""
            }
          />
        )}
        {sectionType === "faq" && (
          <FaqSection
            data={data && data}
            showFaq={state.limit}
            updateProfileFun={updateProfileFun}
            showEdit={showEdit}
          />
        )}

        {state.count >= 5 && !state.show && (
          <div
            className="col-sm-12 text-center"
            onClick={() => setState({ ...state, limit: Infinity, show: true })}
          >
            <Link className="view_all">{"View All"}</Link>
          </div>
        )}
      </div>
      <Example
        show={addNewStory}
        onHide={() => setAddNewStory(!addNewStory)}
        component={
          <NewSuccessStories
            onHide={() => setAddNewStory(!addNewStory)}
            collection={stories}
            setCollection={setStories}
            updateProfileFun={updateProfileFun}
            index={index}
            setIndex={setIndexx}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
    </>
  );
};
export default ViewAllComponent;
