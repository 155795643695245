import React from "react";
import SingleProfileOption from "./options";
import {
  singleProfileAdditionalService,
  singleProfileExamsOffersData,
  singleProfileReviewsData,
} from "../../../../services/SingleProfileDetailReviews";
import ReviewsBox from "../../../Tools/ReviewsBox";
import ExamOfferBox from "../../../Tools/ExamOfferBox";
import AdditionalServiceBox from "../../../Tools/AdditionalServiceBox";

const ReviewsOptions = () => {
  const [activeTab, setActiveTab] = React.useState("reviews");

  return (
    <div className="col-md-8">
      <SingleProfileOption active={activeTab} setActiveTab={setActiveTab} />
      <div className="tab-content mt-4" id="myTabContent">
        <div
          className={`tab-pane fade ${activeTab == "reviews" && "show active"}`}
          id="tab1-tab-pane"
          role="tabpanel"
          aria-labelledby="tab1-tab"
          tabIndex="0"
        >
          {singleProfileReviewsData.length > 0 &&
            singleProfileReviewsData.map((item) => {
              return (
                <ReviewsBox
                  backgroundImage={item.user_image}
                  name={item.name}
                  description={item.description}
                  rating={item.rating}
                  time={item.created_at}
                />
              );
            })}
        </div>
        <div
          className={`tab-pane fade ${
            activeTab == "exams_offers" && "show active"
          }`}
          id="tab2-tab-pane"
          role="tabpanel"
          aria-labelledby="tab2-tab"
          tabIndex="0"
        >
          <div className="row">
            {singleProfileExamsOffersData.length > 0 &&
              singleProfileExamsOffersData.map((item) => {
                return (
                  <ExamOfferBox
                    backgroundImage={item.image}
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            activeTab == "additional_service" && "show active"
          }`}
          id="tab3-tab-pane"
          role="tabpanel"
          aria-labelledby="tab3-tab"
          tabIndex="0"
        >
          {singleProfileAdditionalService.length > 0 &&
            singleProfileAdditionalService.map((item) => {
              return (
                <AdditionalServiceBox
                  title={item.title}
                  description={item.description}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default ReviewsOptions;
