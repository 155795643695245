import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchReviewApi } from "../../../../services/api";
import { toast } from "react-toastify";
import moment from "moment";
import { mediaUrl } from "../../../../services/network";
import ReviewsBox from "../../../Tools/ReviewsBox";
import NavTabs from "../../../Tools/NavTabs";
import Example from "../../../Tools/ReactBoostrap";
import ReportModel from "../../../Layouts/Model/reportModel";

const RatingReview = ({
  rating,
  ratingCount,
  loading,
  companyOwner,
  selector,
  handleUserNavigate,
}) => {
  const [activeTab, setActiveTab] = useState("letest");
  const [reviewData, setReviewData] = useState({});
  const [reportShow, setReportShow] = useState(false);
  const navbarItem = [
    { label: "Latest", name: "letest" },
    { label: "High", name: "high" },
    { label: "Low", name: "low" },
  ];
  const limit = 2;

  useEffect(() => {
    if (companyOwner) {
      fetchReviewApi({ user_id: companyOwner })
        .then((response) => {
          if (response.data.status === "success") {
            setReviewData({
              latest:
                response.data.data.length > 0
                  ? response.data.data.toReversed()
                  : [],
              high:
                response.data.data.length > 0
                  ? response.data.data
                      .slice()
                      .sort((a, b) => b.ratting - a.ratting)
                  : [],
              low:
                response.data.data.length > 0
                  ? response.data.data
                      .slice()
                      .sort((a, b) => a.ratting - b.ratting)
                  : [],
            });
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  }, [companyOwner]);

  return (
    <>
      {ratingCount > 0 && (
        <div class="set" id="reviewsSection">
          <div className="d-flex justify-content-between">
            <h2>Ratings and Reviews </h2>
            {ratingCount > 3 && (
              <Link
                class="viewall"
                to={"reviews"}
                replace={true}
                onClick={() => {
                  if (handleUserNavigate) {
                    handleUserNavigate(
                      "navigate",
                      "/reviews",
                      "view all reviews"
                    );
                  }
                }}
              >
                <i class="bi bi-eye"></i> View all
              </Link>
            )}
          </div>

          <div class="ret">
            <span class="rt">{rating.toFixed(1)}</span>
            <h3>
              {ratingCount} {ratingCount > 1 ? "Ratings" : "Rating"}
            </h3>
            <p>VERIFIED COMPREHENSIVE RATING</p>
          </div>
        </div>
      )}
      {ratingCount > 1 && (
        <NavTabs
          data={navbarItem}
          active={activeTab}
          setActiveTab={setActiveTab}
          style={"letest_high"}
        />
      )}
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade ${activeTab === "letest" && "show active"}`}
          id="Letest-tab-pane"
          role="tabpanel"
          aria-labelledby="Letest-tab"
          tabIndex="0"
        >
          {reviewData.latest &&
            reviewData.latest.length > 0 &&
            reviewData.latest.map((item, i) => {
              if (i <= limit) {
                return (
                  <React.Fragment key={i}>
                    <ReviewsBox
                      backgroundImage={mediaUrl + item.profile_image}
                      name={item.name}
                      rating={item.ratting}
                      time={moment(item.created_at).format("DD MMM YYYY")}
                      description={item.review}
                      reply={true}
                      // replyOnclick={() => setShow(!show)}
                      // shareOnClick={() => setShareShow(!shareShow)}
                      reportOnClick={() => setReportShow(!reportShow)}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
        <div
          className={`tab-pane fade ${activeTab === "high" && "show active"}`}
          id="High-tab-pane"
          role="tabpanel"
          aria-labelledby="High-tab"
          tabIndex="0"
        >
          {reviewData.high &&
            reviewData.high.length > 0 &&
            reviewData.high.map((item, i) => {
              if (i <= limit) {
                return (
                  <React.Fragment key={i}>
                    <ReviewsBox
                      backgroundImage={mediaUrl + item.profile_image}
                      name={item.name}
                      rating={item.ratting}
                      time={moment(item.created_at).format("DD-MM-YYYY")}
                      description={item.review}
                      reply={true}
                      // replyOnclick={() => setShow(!show)}
                      // shareOnClick={() => setShareShow(!shareShow)}
                      reportOnClick={() => setReportShow(!reportShow)}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
        <div
          className={`tab-pane fade ${activeTab === "low" && "show active"}`}
          id="Low-tab-pane"
          role="tabpanel"
          aria-labelledby="Low-tab"
          tabIndex="0"
        >
          {reviewData.low &&
            reviewData.low.length > 0 &&
            reviewData.low.map((item, i) => {
              if (i <= limit) {
                return (
                  <React.Fragment key={i}>
                    <ReviewsBox
                      backgroundImage={mediaUrl + item.profile_image}
                      name={item.name}
                      rating={item.ratting}
                      time={moment(item.created_at).format("DD MMM YYYY")}
                      description={item.review}
                      reply={true}
                      // replyOnclick={() => setShow(!show)}
                      // shareOnClick={() => setShareShow(!shareShow)}
                      reportOnClick={() => setReportShow(!reportShow)}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
      </div>
      <Example
        show={reportShow}
        onHide={() => setReportShow(!reportShow)}
        component={
          <ReportModel
            reportShow={reportShow}
            id={companyOwner}
            setReportShow={setReportShow}
            onHide={() => setReportShow(!reportShow)}
          />
        }
        style={"pop_width CreateOffer reports_modal"}
      />
    </>
  );
};

export default RatingReview;
