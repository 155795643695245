import React from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import OTPTimer from "../../Tools/Otptimer";
import Button from "../../Tools/Button";
import {
  changeNumberOtpVerifyApi,
  changeNumberSendOtpApi,
} from "../../../services/api";
import {
  clearToken,
  clearUser,
  setOldPhoneOtp,
  setPhoneNumber,
  setOtp,
} from "../../../Reducer/authSlice";
const ChangeMobileNumberOtp = ({ duration, body, onHide, phoneOnHide }) => {
  const [otpp, setOtpp] = React.useState("");
  const [secondOtp, setSecondOtp] = React.useState("");
  const { otp, oldPhoneOtp, oldPhoneNumber, phoneNumber } = useSelector(
    (state) => state.auth
  );
  const { phone } = useSelector((state) => state.auth.auth);

  const dispatch = useDispatch();
  const otpDuration = duration; // Adjust the OTP timer duration as needed
  const handleOTPTimerTimeout = () => {
    // You can perform any action you want when the timer expires here.
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const body = {
      oldPhone: oldPhoneNumber,
      phone: phoneNumber,
      otp_old_num: otpp,
      otp_new_num: secondOtp,
    };
    changeNumberOtpVerifyApi(body)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          onHide();
          phoneOnHide();
          setTimeout(() => {
            dispatch(clearUser());
            dispatch(clearToken());
            // window.location.href = "/";
          }, 5000);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };
  const onResendFun = () => {
    const bodyy = {
      oldPhone: oldPhoneNumber,
      phone: phone,
    };
    changeNumberSendOtpApi(bodyy)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          dispatch(setOldPhoneOtp(response.data.first));
          dispatch(setOtp(response.data.second));
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="modal-content">
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={onHide}
      ></button>
      <div className="modal-body p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="lest_login text-center">
              <img
                src="../../../assets/images/chownk-logo.png"
                alt="CHOWNK logo"
              />
              <h3 className="text-center">{"OTP Verification"}</h3>
              <h6>
                {"An OTP has been sent to your"}
                <br></br>
                {"phone number"}{" "}
                <Link onClick={() => onHide()}>{"Change"}</Link>
              </h6>
              <form className="text-start" onSubmit={onSubmit}>
                <div className="form-group form-groupotp text-center">
                  <label className="d-block text-start">
                    {"Enter your Old Number OTP"}
                  </label>
                  <OTPInput
                    className={"form-control"}
                    inputStyle={{ width: "40px" }}
                    value={otpp}
                    onChange={setOtpp}
                    numInputs={4}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="form-group form-groupotp text-center">
                  <label className="d-block text-start">
                    {"Enter your New Phone number"}
                  </label>
                  <OTPInput
                    className={"form-control"}
                    inputStyle={{ width: "40px" }}
                    value={secondOtp}
                    onChange={setSecondOtp}
                    numInputs={4}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <p>
                  <input type="checkbox" className="form-check-input" />
                  {" I agree to "}
                  <a href="#">{"Terms & Conditions"}</a>
                </p>
                <p>
                  <input type="checkbox" className="form-check-input" />
                  {" Send updates on "}
                  <a href="#">{"Whatsapp"}</a>
                </p>
                <h5 className="mt-3 text-center">
                  {"Resend OTP in"}{" "}
                  {otpDuration !== 0 && (
                    <OTPTimer
                      duration={otpDuration}
                      onTimeout={handleOTPTimerTimeout}
                      onResend={onResendFun}
                    />
                  )}
                </h5>

                <Button
                  inType={"submit"}
                  style={"btn mt-2 mt-lg-5"}
                  title={"Continue"}
                  disabled={
                    otpp.length === 4 && secondOtp.length === 4 ? false : true
                  }
                  inlineStyle={
                    otpp.length === 4 && secondOtp.length === 4
                      ? { backgroundColor: "#1DBF73", color: "white" }
                      : {}
                  }
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default ChangeMobileNumberOtp;
