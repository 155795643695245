import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Example from "../ReactBoostrap";
import EditAgentProfileFaqModel from "../../Layouts/Model/editagentProfileFaqModel";

const FaqSection = ({
  data,
  setActiveTab,
  showFaq,
  viewAllButtonSHow,
  updateProfileFun,
  showEdit,
}) => {
  const { company_detail } = useSelector((state) => state.afterSearch);
  const [editFaq, setEditFaq] = useState(false);
  const [faqData, setFaqData] = useState(data);
  const [editIndex, setEditIndex] = useState("");

  useEffect(() => {
    setFaqData(data);
  }, [data]);

  const deleteFaqFun = (index) => {
    const filterFaq = faqData.filter((el, i) => i !== index);
    let formData = new FormData();
    formData.append("faq", JSON.stringify(filterFaq));
    updateProfileFun(formData);
    setFaqData(filterFaq);
  };

  return (
    <>
      <div className="main_st faqs">
        <h5 className="mb-3">
          <i className="bi bi-stars"></i>
          {"Faq"}{" "}
          {viewAllButtonSHow && data && data.length > 2 && (
            <Link
              to={`${`/${company_detail && company_detail.city}/${
                company_detail && company_detail.slug
              }/faq`}`}
              className="vall"
            >
              {"View all"}
            </Link>
          )}
        </h5>
        {faqData &&
          faqData.length > 0 &&
          faqData.map((item, i) => {
            if (showFaq && i <= showFaq) {
              return (
                <div className="mb-3" key={i}>
                  <h5>
                    {item.question}
                    {showEdit && (
                      <>
                        <Link
                          onClick={() => {
                            setEditIndex(i);
                            setEditFaq(!editFaq);
                          }}
                        >
                          <i class="bi bi-pencil-square"></i>
                        </Link>

                        <Link onClick={() => deleteFaqFun(i)}>
                          <i class="bi bi-trash"></i>
                        </Link>
                      </>
                    )}
                  </h5>
                  <p>{item.answer}</p>
                </div>
              );
            } else if (!showFaq) {
              return (
                <div className="mb-3" key={i}>
                  <h5>{item.question}</h5>
                  <p>{item.answer}</p>
                </div>
              );
            }
          })}
      </div>
      <Example
        show={editFaq}
        onHide={() => setEditFaq(!editFaq)}
        component={
          <EditAgentProfileFaqModel
            data={faqData}
            onHide={() => setEditFaq(!editFaq)}
            setFaqData={setFaqData}
            index={editIndex}
            updateProfileFun={updateProfileFun}
          />
        }
        style={"pop_width CreateOffer faqModel"}
      />
    </>
  );
};

export default FaqSection;
