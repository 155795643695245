import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAfterSearchResult } from "../../../Reducer/afterSearchSlice";
import $ from "jquery";
import { ratingFilterData } from "../../../data/data";
import SpecializationFilter from "../SpecializationFilter";
import RatingFilter from "../RatingFilter";
import OnlineFilter from "../OnlineFilter";
import { getSpecializationFilterApi } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import SearchCity from "../../Pages/Student/AfterSearchResult/searchCity";
import VideoFilter from "../VideoFilter";
import TotalResult from "../../Pages/Student/AfterSearchResult/totalResult";

const EducationConsultantFilterOption = ({
  title,
  description,
  city,
  stype,
  categories,
  totalRows,
}) => {
  const location = useLocation();
  const { meta } = useSelector((state) => state.afterSearch);
  const queryParams = new URLSearchParams(location.search);
  const [specializationData, setSpecializedData] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [ratingfilters, setRatingFilters] = useState([]);

  const navigate = useNavigate();
  const [searching, setSearching] = useState(queryParams.get("search"));

  const [selectedTags, setSelectedTags] = useState({
    category: "",
    availableOn: "",
    specialized: [],
    rating: [],
    city: "",
  });

  const [navpath, setNavPath] = useState("/search");
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname !== "/search" && stype !== "") {
      if (selectedTags.specialized.includes(stype)) {
        const filter = selectedTags.specialized.filter((el) => el !== stype);
        setSelectedTags((prev) => ({
          ...prev,
          specialized: filter,
        }));
      } else {
        setSelectedTags((prev) => ({
          ...prev,
          specialized: [stype],
        }));
      }
      setNavPath("/search");
    }
    setSearching(queryParams.get("search"));
  }, [navpath, location.search]);

  const handleStatus = () => {
    const obj = {
      city: city,
      categories: categories,
      online: !onlineStatus,
      ratting: ratingfilters,
    };
    dispatch(getAfterSearchResult(obj));
  };

  useEffect(() => {
    const getSpecialization = async () => {
      const Specialization = await getSpecializationFilterApi();
      setSpecializedData(Specialization);
    };
    getSpecialization();
  }, []);

  const removeSpecialized = (item) => {
    setSelectedTags((prev) => ({
      ...prev,
      specialized:
        selectedTags.specialized.includes(item) &&
        selectedTags.specialized.filter((el) => el !== item),
    }));
    if (selectedTags.specialized.filter((el) => el !== item).length === 0) {
      queryParams.delete("specialization[]");
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
    } else {
      queryParams.set(
        "specialization[]",
        selectedTags.specialized.filter((el) => el !== item)
      );
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
    }
  };

  const removeRating = async (item) => {
    setSelectedTags((prev) => ({
      ...prev,
      rating:
        selectedTags.rating.includes(item) &&
        selectedTags.rating.filter((el) => el !== item),
    }));
    if (selectedTags.rating.filter((el) => el !== item).length === 0) {
      queryParams.delete("ratting[]");
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
    } else {
      queryParams.set(
        "ratting[]",
        selectedTags.rating.filter((el) => el !== item)
      );
      const newSearchString = queryParams.toString();
      navigate({ pathname: navpath, search: newSearchString });
    }
  };

  const removeCategory = () => {
    setSelectedTags((prev) => ({
      ...prev,
      category: "",
    }));
    queryParams.delete("categories");
    const newSearchString = queryParams.toString();
    navigate({ pathname: navpath, search: newSearchString });
  };

  const availableRemoveFunction = (item) => {
    setSelectedTags((prev) => ({
      ...prev,
      availableOn: "",
    }));
    queryParams.delete("available");
    const newSearchString = queryParams.toString();
    navigate({ pathname: navpath, search: newSearchString });
  };

  const removeCity = () => {
    setSelectedTags((prev) => ({ ...prev, city: "" }));
    queryParams.delete("city");
    queryParams.delete("lat");
    queryParams.delete("long");
    const newSearchString = queryParams.toString();
    navigate({ pathname: navpath, search: newSearchString });
  };

  const applyFilters = () => {
    if (selectedTags.specialized && selectedTags.specialized.length > 0) {
      queryParams.set("specialization[]", selectedTags.specialized);
    }
    if (selectedTags.rating && selectedTags.rating.length > 0) {
      queryParams.set("ratting[]", selectedTags.rating);
    }
    const newSearchString = queryParams.toString();
    navigate({ pathname: "/search", search: newSearchString });
    $(".filtr_sidebar").removeClass("main");
  };
  return (
    <section className="search_banner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>
              <i className="fa-solid fa-user-tie"></i>
              {searching && searching !== ""
                ? " Result for " + searching
                : meta !== null && meta.main_title
                ? " " + meta.main_title
                : " " + title}
            </h1>
            <p>
              {meta !== null && meta.main_description
                ? meta.main_description
                : description}
            </p>
          </div>
        </div>
        <i
          className="bi bi-filter-left d-block d-md-none"
          onClick={() => {
            $(".filtr_sidebar").addClass("main");
          }}
        >
          <span>Filter</span>{" "}
        </i>
        <div className="filtr_sidebar">
          <i
            className="bi bi-x-lg d-block d-md-none"
            onClick={() => {
              $(".filtr_sidebar").removeClass("main");
            }}
          ></i>
          <div className="row">
            <div className="col-xl-8">
              <ul className="d-flex p-0">
                <SpecializationFilter
                  filterData={specializationData[0]}
                  setItem={setSelectedTags}
                  selectedItems={selectedTags}
                  heading={
                    specializationData[0]?.name
                      ? specializationData[0]?.name
                      : "Visa Type"
                  }
                  navpath={navpath}
                  image={"../../../../assets/images/apdp1.svg"}
                  image2={"../../../../assets/images/apdp1W.svg"}
                />
                <SpecializationFilter
                  filterData={specializationData[1]}
                  setItem={setSelectedTags}
                  selectedItems={selectedTags}
                  heading={
                    specializationData[1]?.name
                      ? specializationData[1]?.name
                      : "Country to Visit"
                  }
                  navpath={navpath}
                  image={"../../../../assets/images/apdp2.svg"}
                  image2={"../../../../assets/images/apdp2W.svg"}
                />
                <SpecializationFilter
                  filterData={specializationData[2]}
                  setItem={setSelectedTags}
                  selectedItems={selectedTags}
                  heading={
                    specializationData[2]?.name
                      ? specializationData[2]?.name
                      : "Exam Types"
                  }
                  navpath={navpath}
                  image={"../../../../assets/images/apdp3.svg"}
                  image2={"../../../../assets/images/apdp3W.svg"}
                />
                <RatingFilter
                  filterData={ratingFilterData}
                  setItem={setSelectedTags}
                  selectedItems={selectedTags}
                  navpath={navpath}
                />
                <SearchCity
                  setItem={setSelectedTags}
                  selectedItems={selectedTags}
                  navpath={navpath}
                />
              </ul>
            </div>
            <div className="col-xl-4">
              <ul className="ultoggle float-end">
                <OnlineFilter
                  city={city}
                  categories={categories}
                  onlineStatus={onlineStatus}
                  setOnlineStatus={setOnlineStatus}
                  onChange={handleStatus}
                  navpath={navpath}
                />
                <VideoFilter />
              </ul>
            </div>
            <div className="d-block d-md-none">
              <div className="row ">
                <div className="col-6">
                  <button
                    className="btn"
                    onClick={() => {
                      $(".filtr_sidebar").removeClass("main");
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn apply" onClick={applyFilters}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          {selectedTags && selectedTags.category && (
            <span>
              {selectedTags.category}{" "}
              <i className="bi bi-x-circle-fill" onClick={removeCategory}></i>
            </span>
          )}

          {selectedTags &&
            selectedTags.specialized.length > 0 &&
            selectedTags.specialized.map((i, index) => {
              return (
                <span key={index}>
                  {i}{" "}
                  <i
                    className="bi bi-x-circle-fill"
                    onClick={() => removeSpecialized(i)}
                  ></i>
                </span>
              );
            })}

          {selectedTags && selectedTags.availableOn && (
            <span>
              {selectedTags.availableOn}{" "}
              <i
                className="bi bi-x-circle-fill"
                onClick={() =>
                  availableRemoveFunction(selectedTags.availableOn)
                }
              ></i>
            </span>
          )}

          {selectedTags &&
            selectedTags.rating.length > 0 &&
            selectedTags.rating.map((item, i) => {
              return (
                <span key={i}>
                  {item + " " + "Star"}{" "}
                  <i
                    className="bi bi-x-circle-fill"
                    onClick={() => removeRating(item)}
                  ></i>
                </span>
              );
            })}
          {selectedTags && selectedTags.city && (
            <span>
              {selectedTags.city}
              <i
                className="bi bi-x-circle-fill"
                onClick={() => {
                  removeCity();
                }}
              ></i>
            </span>
          )}
        </p>
      </div>
    </section>
  );
};
export default EducationConsultantFilterOption;
