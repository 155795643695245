const TodaySales = ({
  salesPrice,
  salesPrice2,
  profit,
  imageStyle,
  imageSrc,
  label,
}) => {
  return (
    <div className="col-sm-6 mb-3">
      <div className="wap px-5 ">
        <div className="row">
          <div className="col-8">
            <h6>{label}</h6>
            <h2>
              {Number(salesPrice).toFixed(2)}% <span>{profit}</span>
            </h2>
            <h6
              className={
                imageSrc === "../../assets/images/leticon1.png"
                  ? "mont"
                  : "mont2"
              }
            >
              {salesPrice2} <i class="bi bi-eye-fill"></i>
            </h6>
          </div>
          {imageSrc !== "" && (
            <div className="col-4 text-end">
              <div className={imageStyle}>
                <img src={imageSrc} alt="logo" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodaySales;
