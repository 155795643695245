import React from "react";
import AskExpertButton from "../../../Tools/AskExpertButton";
import { useNavigate } from "react-router-dom";

const AskExpertHeader = () => {
  const navigate = useNavigate();
  return (
    <header className="main_header ask_header">
      <div className="container">
        <div className="row">
          <div className="col-2 py-1" onClick={() => navigate("/")}>
            <img
              className="logo"
              src="assets/images/blue_logo.png"
              alt="logo"
            />
          </div>
          <div className="col-10 text-end">
            <AskExpertButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default AskExpertHeader;
