import React from "react";
import { TbLoader } from "react-icons/tb";

const BoostEligibleModal = ({ onHide, boost, setBoost }) => {
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          <img
            src="../../assets/images/boost_profile.png"
            alt="boost_profile"
          />{" "}
          Eligibility Check for Boosting Profile
        </h5>
      </div>
      <div className="modal-body">
        <h6 className="my-3">
          <TbLoader /> We’re checking to see if you’re eligible for this
          program.
        </h6>

        <p>
          {" "}
          <i class="bi bi-check-square-fill"></i> No Account holds in the last
          90 days
        </p>
        <p>
          {" "}
          <i class="bi bi-check-square-fill"></i> Atleast 90% Profile Completed
        </p>
        <p>
          <i class="bi bi-check-square-fill"></i> Minimum of 5 Reviews with at
          least 4.8 star rating in last 90 days
        </p>
      </div>
      <div className="modal-footer b-none mt-4">
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setBoost(!boost);
            onHide();
          }}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default BoostEligibleModal;
