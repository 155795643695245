import React, { useEffect, useState } from "react";
import SimpleInput from "../../../Tools/SimpleInput";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../data/imagefun";
import { mediaUrl } from "../../../../services/network";

const BannerTab = ({
  currentStep,
  errors,
  register,
  setValue,
  banner_image,
  getValues,
  user,
  dltBanner,
  setDltBanner,
}) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [companyLogo, setCompanyLogo] = useState(
    banner_image ? mediaUrl + banner_image : ""
  );
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 50,
    aspect: 855 / 300,
  });
  const [zoom, setZoom] = useState(null);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: 855,
    height: 300,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (user.banner_image) {
      const banner_image = getValues("banner_image");
      if (banner_image) {
        setCompanyLogo(`${mediaUrl}${banner_image}`);
      }
    }
  }, [currentStep]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedImage,
        croppedAreaPixels,
        0
      );
      handleCompanyLogo(croppedImage);
      setSelectedImage("");
    } catch (e) {
      console.error(e);
    }
  };

  const handleCompanyLogo = (image) => {
    setValue("banner_image", image);
    setCompanyLogo(URL.createObjectURL(image));
  };

  console.log(banner_image, "banner_image");
  return (
    <div
      className="tab"
      style={{ display: currentStep === 4 ? "block" : "none" }}
    >
      <div className="form-group">
        <label>
          4. Choose Banner Image
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Why it is important</span> ?
            </a>
            <div className="dropdown-menu">
              <p>It will be Main Banner Image of your Single Profile</p>
              <a
                href="../../assets/images/select3.jpg"
                data-fancybox
                className="mt-0"
              >
                <img
                  src="../../assets/images/select3.jpg"
                  class="ag-photo-gallery_img"
                  alt=""
                />
              </a>
            </div>
          </div>
        </label>
        <p>Upload Banner for your profile</p>
        {!companyLogo && (
          <>
            <div className="upload">
              <SimpleInput
                inType={"file"}
                name={"banner_image"}
                style={"form-control"}
                onChange={handleChange}
                accept={"image/*"}
              />
              <img src="../../assets/images/uploadimg.png" alt="uploadimg" />
              <h6>Drop your files here!</h6>
              <p className="mb-0">or click</p>
            </div>
            <p>
              <b>Allowed file formats:</b> JPG, PNG Maximum file size: 10MB
            </p>
          </>
        )}

        {selectedImage && (
          <div className="select_image">
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={855 / 300}
              onCropChange={setCrop}
              initialCroppedAreaPercentages={croppedAreaPixels}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            >
              <img src={selectedImage} />
            </Cropper>
            <button
              type="button"
              className="cancel_btn"
              onClick={() => setSelectedImage("")}
            >
              Cancel
            </button>
            <button onClick={showCroppedImage} type="button">
              Submit
            </button>
          </div>
        )}
        {companyLogo && (
          <>
            <div className="file">
              <img src={companyLogo} alt="" className="w-100" />
              <i
                className="bi bi-x"
                onClick={() => {
                  setCompanyLogo("");
                  setSelectedImage("");
                  setValue("banner_image", "");
                  if (banner_image) {
                    setDltBanner(banner_image);
                  }
                }}
              ></i>
            </div>
            <p>
              <b>File :</b> {imageName}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default BannerTab;
