import {
  AccountSettingOptionData,
  ChildAccountSettingOptionData,
} from "../../../../data/data";
import NavItem from "../../../Tools/Navitem";

const AccountSettingOption = ({
  setActiveTab,
  activeTab,
  userData,
  showModel,
}) => {
  return (
    <>
      {" "}
      <h2>
        <img src="../../assets/images/stng.png" alt="logo" />{" "}
        {"Account Settings"}
      </h2>
      <div className="pro_ul">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {userData.parent_id == userData.uid
            ? AccountSettingOptionData.length > 0 &&
              AccountSettingOptionData.map((item) => {
                return (
                  <NavItem
                    id={item.id}
                    title={item.title}
                    imageSrc={item.imageSrc}
                    insideButton={"image"}
                    onClick={() => {
                      if (userData?.status === "active") {
                        setActiveTab(item.name);
                      } else if (
                        item.name === "profile_setting" ||
                        item.name === "kyc_documents"
                      ) {
                        setActiveTab(item.name);
                      } else {
                        showModel();
                      }
                    }}
                    active={activeTab == item.name && "active"}
                  />
                );
              })
            : ChildAccountSettingOptionData.length > 0 &&
              ChildAccountSettingOptionData.map((item) => {
                return (
                  <NavItem
                    id={item.id}
                    title={item.title}
                    imageSrc={item.imageSrc}
                    insideButton={"image"}
                    onClick={() => {
                      if (userData?.status === "active") {
                        setActiveTab(item.name);
                      } else if (
                        item.name === "profile_setting" ||
                        item.name === "kyc_documents"
                      ) {
                        setActiveTab(item.name);
                      } else {
                        showModel();
                      }
                    }}
                    active={activeTab == item.name && "active"}
                  />
                );
              })}
        </ul>
      </div>
    </>
  );
};
export default AccountSettingOption;
