import React from "react";

const FormOne = ({ page, setPage, onChange, data }) => {
  return (
    <div className="form-page">
      <h2>
        TELL US WHY YOU NEED <b>EXPERT</b> HELP ?
      </h2>
      <p>
        It is a long established fact that a reader will be distracted by the
        readable
      </p>
      <ul>
        <li>
          <span className="option">
            STUDY ABROAD VISA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="TELL US WHY YOU NEED EXPERT HELP ?"
                className="quality"
                value="STUDY ABROAD VISA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "TELL US WHY YOU NEED EXPERT HELP ?" &&
                    el.ans === "STUDY ABROAD VISA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            START A BUSINESS ABROAD VISA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="TELL US WHY YOU NEED EXPERT HELP ?"
                className="quality"
                value="START A BUSINESS ABROAD VISA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "TELL US WHY YOU NEED EXPERT HELP ?" &&
                    el.ans === "START A BUSINESS ABROAD VISA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            TRAVEL VISA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="TELL US WHY YOU NEED EXPERT HELP ?"
                className="quality"
                value="TRAVEL VISA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "TELL US WHY YOU NEED EXPERT HELP ?" &&
                    el.ans === "TRAVEL VISA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            SPOUSE VISA
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="TELL US WHY YOU NEED EXPERT HELP ?"
                className="quality"
                value="SPOUSE VISA"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "TELL US WHY YOU NEED EXPERT HELP ?" &&
                    el.ans === "SPOUSE VISA"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>

        <li>
          <span className="option">
            OTHERS
            <span className="checkcontainer">
              <input
                type="checkbox"
                name="TELL US WHY YOU NEED EXPERT HELP ?"
                className="quality"
                value="OTHERS"
                onChange={onChange}
                checked={data.some(
                  (el) =>
                    el.ques === "TELL US WHY YOU NEED EXPERT HELP ?" &&
                    el.ans === "OTHERS"
                )}
              />
              <span className="radiobtn"></span>
            </span>
          </span>
        </li>
      </ul>
      <button className="btn" onClick={() => setPage(page + 1)}>
        {" "}
        Next{" "}
      </button>
    </div>
  );
};

export default FormOne;
