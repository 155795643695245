import { Link } from "react-router-dom";

const QuickLink = ({
  parentStyle,
  childStyle,
  heading,
  data,
  handleUserNavigate,
}) => {
  return (
    <div className={parentStyle}>
      <p>{heading}</p>
      <ul className={childStyle}>
        {data.length > 0 &&
          data.map((item) => {
            return (
              <li>
                <Link
                  to={item.path}
                  onClick={() => {
                    if (handleUserNavigate) {
                      handleUserNavigate(
                        "navigate",
                        item.path,
                        `navigate to ${item.path}`
                      );
                    }
                  }}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default QuickLink;
